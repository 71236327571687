import { Component, Inject, OnInit, ViewChild, Optional } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-attachfile-dialog',
  templateUrl: './attachfile-dialog.component.html',
  styleUrls: ['./attachfile-dialog.component.scss']
})

export class AttachfileDialogComponent implements OnInit {

  displayedColumns: string[] = ['name', 'size', 'action'];
  dataSource: any = [];

  @ViewChild('fileInput') fileInput: any;
  myFiles: string[] = [];
  chooseFileForm: FormGroup;
  filesizevalidation: boolean = false;
  filelengthError: boolean;
  fileFormatError:boolean = false;
  Extn:any;
  InboxAttachmentAllowedExt =["txt","jpeg","jpg","gif","png","xls","doc","docx","xlsx","ppt","pptx","rtf","pdf","zip"]
  constructor(public dialogRef: MatDialogRef<AttachfileDialogComponent>, private fb: FormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) { }


  ngOnInit(): void {
    console.log(this.data);
    this.chooseFileForm = this.fb.group({
      questions: this.fb.array([
        this.fb.group({
          Attachment: ['']
        })
      ])
    })

  }

  getValidity(i: any) {
    return (<FormArray>this.chooseFileForm.get('Attachment')).controls[i];
  }


  get questions() {
    const questionsArray = this.chooseFileForm.controls.questions as FormArray;
    return questionsArray;
  }

  onNoClick(): void {
    this.dialogRef.close(true);
  }


  fileCount: number = 3;
  getFileDetails(e: any) {
    console.log(e.target.files);
    for (var i = 0; i < e.target.files.length; i++) {
      let fileName=e.target.files[i].name;
      let fileExtn=fileName.split('.');
      let extn=fileExtn[fileExtn.length -1];
      this.Extn=fileName;
      let extnValid=this.InboxAttachmentAllowedExt.indexOf(extn);
      if(extnValid != -1){
      if (e.target.files[i] && e.target.files[i].size < 5000000) {
        this.filesizevalidation = false;
        this.fileFormatError = false;
        this.myFiles.push(e.target.files[i]);
        console.log(this.myFiles)
      } else {
        this.filesizevalidation = true;
        this.filelengthError = false;
        this.fileFormatError = false;
      }
    }else{
      this.fileFormatError = true;
    }
    }
    this.filelengthError = false;
    // this.dataSource = new MatTableDataSource(this.myFiles);

    if(this.data){   // for draft attached files logic handled here 
      this.fileCount = 3 - this.data;
      if (this.myFiles.length > this.fileCount) {
        this.filelengthError = true;
      } else { this.filelengthError = false }
    } else {
      if (this.myFiles.length > this.fileCount) {
        this.filelengthError = true;
      } else { this.filelengthError = false }
    }

  }

  attachFiles() {
    this.dialogRef.close(this.myFiles);
  }

  remove(i: any) {
    console.log(i)
    this.myFiles.splice(i, 1);
    // this.dataSource = new MatTableDataSource(this.myFiles);
    if (this.myFiles.length == this.fileCount) {
      this.filelengthError = false;
    }
    this.chooseFileForm.reset();
  }

}
