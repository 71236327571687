import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MailBoxService } from 'src/app/data/service/mail-box.service';
import { InBoxService } from 'src/app/data/service/inbox.service';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MovetoinboxDialogComponent } from '../movetoinbox-dialog/movetoinbox-dialog.component';
import { HttpClient } from '@angular/common/http';
import { TokenService } from 'src/app/data/service/token.service';
import { ToasterService } from 'src/app/data/service/toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { pipe } from 'rxjs';
import { Pipe, PipeTransform } from '@angular/core';
import { AnyRecord } from 'dns';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CommonConstants } from 'src/app/core/constants/commonConstants';
import { DashboardServiceData } from 'src/app/core/constants/app-enums';
import { CommonService } from 'src/app/data/service/common.service';


@Component({
  selector: 'app-drafts',
  templateUrl: './drafts.component.html',
  styleUrls: ['./drafts.component.scss']
})
export class DraftsComponent implements OnInit {
  public info = true;
  // public refresh = true;
  public buttonCount = 5;
  public type = 'numeric';
  public pageSizes = true;
  public previousNext = true;
  public position = 'bottom';
  public gridView: any = [];
  public pageSize = 10;
  public skip = 0;
  public allowUnsort = true;
  public multiple = false;
  public DraftMessages: any = [];
  searchText: any;
  subscription: Subscription;
  public searchkeyvalue:any;
  openSidebar:boolean=true;
  selected='compose';
  checkboxForm:FormGroup;
 public openedArchiveDialouge: boolean = false;
  selectedMailType: any;
  public draftsDataDetails: any= [];
  public draftInboxMessageId: any;
  public allComplete:boolean =false;
  page: number = 0;
  pagesize: number = 20;
  userId:any;
  public profileObject:any=[];
  searchTexts:any;
  pageNumber: number=0;
  public draftMessage: boolean = false;
  public SelectedMail: any;
  selectedcheckbox: any = [];
  static draftscomponent: any;
  public nomessages:any;
  public title:any = "thank you every one";
  public searchemptymsg:boolean=false;
  isLoading = true;
  isSearch: any = false;
  isScrolled: boolean = false;
  isMobile: boolean = false;

  imgSrc4: string = "../../../../assets/images/icons-text/Group 5076.svg";
  listInboxId: any = [];

  constructor(private router:Router,
    public http:HttpClient,
    public mailboxServie: MailBoxService,
    private breakpointObserver: BreakpointObserver,
    public deletedialog: MatDialog,
    private _snackBar: MatSnackBar,
    public inBoxService: InBoxService,
    public tokenService:TokenService,
    public toaster:ToasterService,
    public translate:TranslateService,
    public fb:FormBuilder,
    public commonService: CommonService) { 
      this.subscription = this.mailboxServie.onMessage().subscribe(message => {
        if (message) {
          this.searchkeyvalue=message.text
          this.searchText=this.searchkeyvalue
          // this.searchemptymsg =true;
        } else {
            // clear messages when empty message received
            this.searchkeyvalue = "";
            this.searchText = '';
            this.searchemptymsg =false;
        }
        this.draftMessage = false;
        this.SelectedMail = false;
        this.refresh();
    });
    }
  ngOnInit(): void {
    this.breakpointObserver
    .observe(['(max-width: 768px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    });
    this.searchText = '';
    this.userId = this.tokenService.userId();
    this.profileObject= this.tokenService.userProfile();
    this.draftsDataDetails = [];
    this.getDraftMessage();
    this.checkboxForm = this.fb.group({
      checkboxChecked:[''],
      selectedCheckbox:['']
    })
  }

  activationCode(): void {
    this.router.navigate(['/myaccount/profile']);
  }

  // hideinbox():void {

  //   if( this.mailboxServie.selectedMailBoxType.includes("Compose") ||
  //   this.mailboxServie.selectedMailBoxType.includes("Inbox") ||
  //   this.mailboxServie.selectedMailBoxType.includes("Drafts") ||
  //   this.mailboxServie.selectedMailBoxType.includes("Sent") ||
  //   this.mailboxServie.selectedMailBoxType.includes("Archive") ||
  //   this.mailboxServie.selectedMailBoxType.includes("Groups") ||
  //   this.mailboxServie.selectedMailBoxType.includes("Detail") ||
  //   this.mailboxServie.selectedMailBoxType.includes("DraftDetail")) 
  //   {
  //     this.mailboxServie.selectedMailBoxType = '';
  //   }
  // }

  openCompose()
  {
    let serviceData = this.commonService.getServiceDetail(DashboardServiceData.SendMessage);
    if (serviceData && serviceData?.result?.toLowerCase() == CommonConstants.success) {
      this.router.navigate(['/pages/navigate-routes/Compose']);
    }
    else {
      this.commonService.openServiceDialog(serviceData);
    }
  }
  
  // opensettings()
  // {
  //   this.router.navigate(['/pages/navigate-routes/Settings']);
  // }

  /* get draft messages */
 /* it needs to do dynamic this.draftInboxMessageId = 1547; */
  getDraftMessage() {
    this.nomessages=false;
    this.isLoading = true;
   // this.clearSearchResults();
    this.inBoxService.getDraftSearchMessage(this.userId,'UpdateTimeStamp DESC',this.page, this.pagesize,true,this.searchText).subscribe((res:any) => {
      // if (res){
      //   this.draftsDataDetails.push(...res);
      //   console.log(res)
      // }

      // if (res==0){
      //   this.nomessages=true;
      // }

      //   for(var i=0; i<res.length ; i++){
    //     let datediff=this.calculateDateDiff(res[i].InsertOn);
    //     let diffResult= datediff[0] != 0 ? datediff[0]+" year" : datediff[1] != 0 ? datediff[1]+ " month" : datediff[2] != 0 ? datediff[2]+ " day" : datediff[3] != 0 ? datediff[3] + " hour" : datediff[4] != 0 ? datediff[4] + " min" : datediff[5] + " sec";
    //     res[i].InsertOn= diffResult;
    // }
      // this.draftsDataDetails=res;
      this.searchemptymsg =false;
     // this.draftsDataDetails.push(...res);
      if (this.searchTexts?.length || this.searchkeyvalue?.length) {
       // this.draftsDataDetails=[];
       if(this.isScrolled){
        this.draftsDataDetails.push(...res);
       }else{
        this.draftsDataDetails=[];
        this.draftsDataDetails = res;
       }
      } else {
        this.draftsDataDetails.push(...res);
      }
      console.log("draftdata",this.draftsDataDetails)
      this.isLoading = false;
      if (this.draftsDataDetails.length==0){
        this.nomessages=true;
        this.searchemptymsg =true;
      }else{
        this.searchemptymsg =false;
      }
    }, (err: any) => {
      this.isLoading = false;
    })
    
    // if (this.draftsDataDetails.length==0){
    //   this.nomessages=true;
    // }
    
  }
  calculateDateDiff =(dateOfReceived: any) => {
    
    var startDate = new Date(dateOfReceived);
    // user not pass endingDate then set current date as end date.
    let endingDate = new Date();
    
    let endDate = new Date(endingDate);
    // chack start date and end date and base on condication alter date.
    if (startDate > endDate) {
      var swap = startDate;
      startDate = endDate;
      endDate = swap;
    }
    // This is for leap year.
    const startYear = startDate.getFullYear();
    const february =
      (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0
        ? 29
        : 28;
    const daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    let yearDiff = endDate.getFullYear() - startYear;
    let monthDiff = endDate.getMonth() - startDate.getMonth();
    if (monthDiff < 0) {
      yearDiff--;
      monthDiff += 12;
    }
    let dayDiff = endDate.getDate() - startDate.getDate();
    let hourDiff = endDate.getHours() - startDate.getHours();
    let minuteDiff = endDate.getMinutes() - startDate.getMinutes();
    let secondDiff = endDate.getSeconds() - startDate.getSeconds();
    if (dayDiff < 0) {
      if (monthDiff > 0) {
        monthDiff--;
      } else {
        yearDiff--;
        monthDiff = 11;
      }
      dayDiff += daysInMonth[startDate.getMonth()];
    }

    return ([
      yearDiff,monthDiff,dayDiff,hourDiff,minuteDiff,secondDiff
    ]);
  
}

  // When scroll down the screen (infinite scroll)
  onScroll()
  {
    console.log("Scrolled");
    this.pageNumber=this.pageNumber+1;
    this.page = this.pageNumber * this.pagesize;
    this.isScrolled = true;
    this.getDraftMessage();
  }

   // selected draft move to archive 
   draftmovetoArchive(draftId:any){
    console.log(draftId)
    let data: any = {
      "DraftMessagID": draftId,
    }
    const dialogRef = this.deletedialog.open(MovetoinboxDialogComponent, {
      data: {
        alldata: data,
        id: 'You want to delete the message'
      }
    });
    //Call move to inbox API based on the confirm Dialod Result
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.inBoxService.deleteDraftMessage(draftId).subscribe(data => {
          if (data) {
            console.log("dialogdata",data);
            this.toaster.success(
            this.translate.instant('Draft deleted successfully'));
            this.draftsDataDetails =[];
            this.page=0;
            this.pageNumber=0;
            this.getDraftMessage();
            this.draftMessage=false;
            this.SelectedMail=false;
            this.inBoxService.getCount(this.userId).subscribe((count: any[]) => {
              this.mailboxServie.mailstype[1].count = count[1];
            })
          }

        })
      }
      console.log("dialog",dialogResult)
      
    })
  }

  //reloadCurrentRoute
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
        console.log(currentUrl);
    });
  }

  // Delete all drafts
  deleteAllDrafts(){
    const dialogRef = this.deletedialog.open(MovetoinboxDialogComponent, {
      data: {
        id: 'You want to delete all messages'
      }
    });
    //Call for delete drafts API based on the confirm Dialod Result
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        //20-12-222
        //4523 changed to one call 
        // for (let i = 0; i < this.draftsDataDetails.length; i++) {
        this.inBoxService.deleteAllDraftMessage().subscribe((res:any) => {
          console.log(res)
          if (res) {

            this.toaster.success(
              this.translate.instant('Draft(s) Deleted successfully'));
            
            this.draftsDataDetails =[];
            this.page=0;
            this.pageNumber=0;
            this.getDraftMessage();
            this.inBoxService.getCount(this.userId).subscribe((count: any[]) => {
              this.mailboxServie.mailstype[1].count = count[1];
            })
            this.reloadCurrentRoute();
          }
        })
      //}
      }
    })
  }



  changeMailbox(event:any){
    this.mailboxServie.selectedMailBoxType=event.value;
  }
  loadComponent(value:any){
    console.log(value);
    this.router.navigate(['/mailbox/',value]);
  }
  /* here get the draft message Id */
  // setSelectedMailType(mailType:any, InboxDraftMessageID: string,draftsDataDetails:any) {
  //   console.log(mailType)
  //   this.mailboxServie.selectedMailBoxType=mailType;
  //   this.mailboxServie.messageId = InboxDraftMessageID;
  // }
  // setSelectedMailType(InboxDraftMessageID:string,index:number = 0) {
    setSelectedMailType(InboxDraftMessageID: string,index:number) {
    this.draftMessage=true;
    this.SelectedMail= index;

    // this.mailboxServie.selectedMailBoxType=mailType;
    this.mailboxServie.messageId = InboxDraftMessageID;
    
    // this.mailboxServie.draftsDataDetails  = InboxDraftMessageID;
    this.mailboxServie.index = index;
  
    // this.getDraftMessage();
  }

  setSelectedMobileMailType(mailType:any, InboxDraftMessageID: string,draftsDataDetails:any) {
      console.log(mailType)
      this.mailboxServie.selectedMailBoxType=mailType;
      this.mailboxServie.messageId = InboxDraftMessageID;
    }
    
  // search value
  Searchkey(searchkeyvalue: any): void {
    this.isSearch = true
    console.log(searchkeyvalue, "searchvalue")
    this.mailboxServie.sendMessage(searchkeyvalue);
  }

  refresh() {
    this.draftsDataDetails=[];
    this.page=0;
    this.pageNumber=0;
    this.searchemptymsg =false;
    this.getDraftMessage();

    // window.location.reload();
  }

  clearSearchData() {
    this.searchTexts = "";
    this.draftsDataDetails = [];
    this.page=0;
    this.pageNumber=0;
    this.searchText='';
    this.draftMessage=false;
    this.SelectedMail=false;
    // setTimeout(() => {
      this.getDraftMessage();
    // }, 1000)
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  isIndeterminate() {
    return (this.selectedcheckbox.length > 0 && !this.isChecked());
  };

  isChecked() {
    return this.selectedcheckbox.length === this.draftsDataDetails.length;
  };

  toggleAll(event: MatCheckboxChange) {
    console.log('---------- Toggle All ---------------')
    if (event.checked) {
      this.draftsDataDetails.forEach((row: any) => {
        this.selectedcheckbox.push(row)
      });
    } else {
      this.selectedcheckbox.length = 0;
    }
  }

  toggle(item: any, event: MatCheckboxChange) {
    if (event.checked) {
      this.selectedcheckbox.push(item);
    } else {
      const index = this.selectedcheckbox.indexOf(item);
      if (index >= 0) {
        //  this.selectedcheckbox.splice(index, 1);
        this.selectedcheckbox.forEach((element: any, index: any) => {
          console.log(element);
          if (element.InboxDraftMessageID == item.InboxDraftMessageID) {
            this.selectedcheckbox.splice(index, 1);
          }
        });
      }
    }
    console.log(item + "<>", event.checked);
  }

  exists(item: any) {
    return this.selectedcheckbox.indexOf(item) > -1;
  }


  archiveAll() {

    this.imgSrc4 = "../../../../assets/images/icons-text/Group 5076.svg";
    console.log("archiveall");
    this.listInboxId = [];
    //this.toggleAll(true);
    this.selectedcheckbox.filter((value: any) => {
      this.listInboxId.push(value.SentMessageId);
    });
    // const listId = this.selectedcheckbox.length > 9 ? 'All' : this.listInboxId;
    const listId = this.listInboxId;
    console.log(listId);
    this.inBoxService.moveToArchive(this.userId, listId, true).subscribe((res: any) => {
      if (res == 1) {
        console.log(res);
        this.draftsDataDetails = [];
        console.log('=================== Archieve All ===================');
        this.getDraftMessage();
        this.checkboxForm.controls['checkboxChecked']?.setValue(false);
        this.checkboxForm.controls['selectedCheckbox']?.setValue(false);
        this.selectedcheckbox.length = 0;

        // this.SelectedMail = false;
        // this.draf = false;
      }
      this.selectedcheckbox = [];
    }, (err: any) => {
      console.log(err)
      this.toaster.error(err.error.Message)
    })

    /*this.imgSrc4 = "../../../../assets/images/icons-text/Group 5076.svg";
    console.log("archiveall");
    this.listInboxId = [];
    //this.toggleAll(true);
    this.selectedcheckbox.filter((value: any) => {
      this.listInboxId.push(value.SentMessageId);
  */


  }
}
