<!-- <p-dialog width="100%" height="100%" header="Video Call" [(visible)]="displayMaximizable" [modal]="false"
  [maximizable]="false" [draggable]="true" [resizable]="true" [blockScroll]="false"
  (onShow)="showDialogMaximized(dialDetail)" #dialDetail> -->
<!-- <div class="mat-container"> -->
<div #screen id="screen">
  <div id="publisherDiv" class="publishingDiv" [ngClass]="{'display-minimize': !minMaxState}" #publisherDiv>
    <div id="screen-preview"></div>
  </div>




  <div style="z-index: 100; pointer-events: none;">
    <div id="subscriberHost" [ngClass]="{'subscribe-minimize': !minMaxState}">
      <!-- menu section -->
      <ng-template #subscriberHost></ng-template>
    </div>
  </div>
  <div *ngIf="minimized && !outPatient" class="minimize-videocall mobiledevice"><img title="Minimize"
      src="assets/Videoimages/arrow-expand.svg" (click)="minimize()"></div> 
  <!-- <div *ngIf="maximized && !outPatient" class="minimize-videocall mobiledevice"><img title="Maximize" 
      src="assets/Videoimages/arrow-expand.svg" (click)="maximize()"  style="background: black;"></div>  -->
  <div *ngIf="maximized && !outPatient" class="minimize-videocall mobiledevice"><img title="Maximize" 
    src="assets/Videoimages/min_arrow.svg" (click)="maximize()"></div>

      
  <!-- <div class="close-videocall"><img title="Close" src="assets/Videoimages/mic.svg" (click)="closepdialog()"></div> -->

  <div class="patient-boxarea patient-boxarea-inside" *ngIf="isDoctor && !ispatientjoined && !isErrorPopup">
    <div *ngIf="minimized"> 
      <div class="mat-toolbar mat-primary topbar relative hide-print mat-toolbar-single-row">
        <img src='assets/images/MMH-mobile-logo.png'>
        <div *ngIf="minimized && !outPatient" class="minimize-videocall mobiledevice"><img title="Minimize"
            src="assets/Videoimages/arrow-expand.svg" (click)="minimize()"></div> 
      </div>      
    </div>

    
    
    <div class="" *ngIf="!ispatientjoined && enableVideoConsultaiton == true">
      <div  class="d-flex justify-content-center">
        <img class="userprofie" style="border-radius: 50%;" onerror="this.src='assets/images/nophoto_unregistered.svg'" [src]="profilePictureURL">
      </div>
      <div class="patient-name-textt">
        <!-- <span>Patient Name</span> -->
        <span>{{receiverName}}</span>
      </div>
      <div class="patient-name-and-status" *ngIf="!ispatientjoined">
        <span class="patient-status-p" *ngIf="IsWaitingRoomStatus">WAITING</span>
        <span class="patient-status-p-offline" *ngIf="!IsWaitingRoomStatus">OFFLINE</span>
      </div>
      <!-- <div class="grid-btns-two" style="display: flex !important"> -->
     
          <div *ngIf="IsWaitingRoomStatus" class="grid-btns-two" >
            <button mat-button class="desktop-view-1024 btn-primary-pill" (click)="isadmit()">ADMIT</button>
            <button mat-button class="mobile-view-1024 btn-primary-pill" (click)="isadmit()">ADMIT</button>
            <button mat-button class="btn-secondary-pill-gray" (click)="isdeny()">DENY</button>
          </div>
          <div *ngIf="!IsWaitingRoomStatus" class="grid-btns-two" style="display: flex !important">
            <button mat-button class="desktop-view-1024 btn-primary-pill" (click)="sendemailNotifcation()">SEND EMAIL REMINDER</button>
            <button mat-button class="mobile-view-1024 btn-primary-pill" (click)="sendemailNotifcation()">SEND EMAIL REMINDER</button>
          </div>
       
    </div>
    <!-- </div> -->
  </div>

  

  <div class="videoIconsContainer" *ngIf="minMaxState && !isErrorPopup">
    <div class="Video-elements">
      <div class="doctor-details">
        <ul>
          <li>
            <img [src]="senderProfilePic" alt="user" class="profile-pic img-fluid displayproviderimage mobile-dev-profile">
          </li>
          <li class="docdetailssection">
            <span class="docName">{{senderName}}</span>
            <p style="color: black;margin: 0px; width:69px">{{display}}</p>
          </li>
        </ul>
      </div>


      <img *ngIf="isDoctor" title="{{'Invite patient'}}" src="assets/Videoimages/invite-patient.svg"
        alt="settings" (click)="onClickAddPatient()">
    </div>

    <div class="Video-elements" *ngIf="!isErrorPopup">
      <img *ngIf="audioStatus" title="Mute" src="assets/Videoimages/mic.svg" (click)="audioOff()">
      <img *ngIf="!audioStatus" title="Unmute" (click)="audioOn()" src="assets/Videoimages/mute.svg">
      <img *ngIf="videoStatus" title="Video Off" (click)="videoOff()" src="assets/Videoimages/video-on.svg">
      <img *ngIf="!videoStatus" title="Video On" (click)="videoOn()" src="assets/Videoimages/video-of.svg">

      <img *ngIf="shareStatus" class="mobiledevice sharescreen" title="Stop Share" (click)="StopSharing()"
        src="assets/Videoimages/screen_share.svg">
      <img *ngIf="!shareStatus" class="mobiledevice sharescreen" title="Share Now" (click)="Sharing()"
        src="assets/Videoimages/screen_share.svg">
      <img *ngIf="videoStatus" title="Rotate Camera" class="hidingWeb rotateimage" (click)="rotateCamera()"
        src="assets/Videoimages/flip-camera.svg">
      <img title="Settings" src="assets/Videoimages/settings.svg" class="mobiledevice" (click)="onClickSettings()">

      <img *ngIf="headSet" title="Speaker Off" [matMenuTriggerFor]="devicesettingForMobile" class="hidingWeb"
        (click)="getDeviceListForMobile()" src="assets/Videoimages/volume_on.svg">

      <img *ngIf="!headSet" title="Speaker On" [matMenuTriggerFor]="devicesettingForMobile" class="hidingWeb"
        (click)="getDeviceListForMobile()" src="assets/Videoimages/volume_off.svg">

      <img title="Close" src="assets/Videoimages/Close.svg" (click)="endCall()">

    </div>
    <div class="Video-elements">
      <!-- <img title="Menu" src="assets/Videoimages/menu.svg" class="webdevice"> -->
    </div>
  </div>
  <!-- for minimized p-dialog starts-->
  <div class="videoIconsContainer-minimize" *ngIf="!minMaxState">
    <!-- <div class="Video-elements">
      <div class="doctor-details">
        <ul>
          <li class="docdetailssection">
            <p style="color: white;margin: 0px;">{{display}}</p>
          </li>

        </ul>
      </div>
    </div> -->

    <div class="Video-elements">
      <img *ngIf="audioStatus" title="Mute" src="assets/Videoimages/mic.svg" (click)="audioOff()">
      <img *ngIf="!audioStatus" title="Unmute" (click)="audioOn()" src="assets/Videoimages/mute.svg">
      <!-- <img *ngIf="videoStatus" title="Video Off" (click)="videoOff()" src="assets/Videoimages/video-on.svg">
      <img *ngIf="!videoStatus" title="Video On" (click)="videoOn()" src="assets/Videoimages/video-of.svg"> -->

      <!-- <img *ngIf="shareStatus" class="mobiledevice" title="Stop Share" (click)="StopSharing()"
        src="assets/Videoimages/screen_share.svg">
      <img *ngIf="!shareStatus" class="mobiledevice" title="Share Now" (click)="Sharing()"
        src="assets/Videoimages/screen_share.svg"> -->

      <img title="Close" src="assets/Videoimages/Close.svg" (click)="endCall()">

    </div>
    <!-- <div class="Video-elements">
      <img title="Menu" src="assets/Videoimages/menu.svg" class="webdevice">
    </div> -->
  </div>
  <!-- for minimized p-dialog ends-->

  <div class="error-container" *ngIf="isErrorPopup">

    <div *ngIf="minimized"> 
      <div class="mat-toolbar mat-primary topbar relative hide-print mat-toolbar-single-row">
        <img src='assets/images/MMH-mobile-logo.png' alt="logo">
        <div  class="minimize-videocall mobiledevice"><i class="icon-cent-close-popular close" (click)="closeErrPopup()"></i></div> 
      </div>      
    </div>

    <div class="title">Information</div>
    <div class="info-text">
      Oops! It seems your current browser configuration is not supporting joining the video consultation.
    </div>

    <div class="align-item-space">
      <div>
        <div>
          <div class="qr-text">Scan this QR code with your phone camera </div>
          <div class="qr-code"><qrcode [qrdata]="qrURL" [width]="256" [errorCorrectionLevel]="'M'"></qrcode></div>
        </div>
      </div>
      <div>
        <div>
          <div class="copy-url-title">Copy URL and open in a local browser</div>
          <div class="copy-url" (click)="copyTxt()"><i class="icon-cent-content-copy-text copy" ></i> Copy URL Link</div>
          <div class="copy-info">Please open the URL in a local browser to seamlessly connect to the video consultation.</div>
        </div>
      </div>
    </div>


  </div>

</div>


<!-- <mat-menu #callendedmenu="matMenu" class="mat-menu-callsection" xPosition="before" yPosition="above">
  <h2 class="exitcall" style="color: #000000;
    font-weight: 600;
    text-align: center;
    margin-bottom: 39px;
    margin-top: 16px;">Exit Call</h2>
  <div class="d-flex align-items-center dialogoption">
    <button class="endbtn">No</button>
    <button class="startbtn" (click)="close()">Yes</button>
  </div>
</mat-menu> -->


<kendo-dialog title="Exit Call" [width]="320" *ngIf="isVideoCallEnd" class="j-c-cen">
  <p>Are you sure you want to exit the call?</p>
  <kendo-dialog-actions class="btn-container">
    <button class="btn-secondary-rect m-r-10" (click)="isVideoCallEnd = false">No</button>
    <button class="btn-primary-rect" (click)="close()">Yes</button>
  </kendo-dialog-actions>

</kendo-dialog>

<kendo-dialog title="Confirm" [width]="320" *ngIf="showDenayConfirm" class="j-c-cen">
  <p>Do you want to deny the call? </p>
  <kendo-dialog-actions class="btn-container">
    <button class="btn-secondary-rect m-r-10" (click)="showDenayConfirm = false">No</button>
    <button class="btn-primary-rect" (click)="confirmDeny()">Yes</button>
  </kendo-dialog-actions>

</kendo-dialog>

<!-- <mat-menu #speakers="matMenu">
  <button (click)="changeMicroPhone(data)" *ngFor="let data of mics" mat-menu-item>
      {{data.label}}
  </button>
  </mat-menu> -->

<kendo-dialog title="Device settings" [width]="320" *ngIf="onSettings" (close)="onSettings = false" class="j-c-cen">
  <div class="settingsMenu">
    <mat-form-field appearance="outline" class="icon-right" (click)="$event.stopPropagation();">
      <mat-select placeholder="{{'Internal Microphone'}}" [(ngModel)]="selectedAudioDevice"
        (click)="$event.stopPropagation();">
        <mat-option *ngFor="let data of mics" [value]="data.label" (click)="changeMicroPhone(data)">
          {{data.label}}
        </mat-option>
      </mat-select>
      <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
    </mat-form-field>
    <button mat-button>
      <img src="assets/Videoimages/microphone-outline.svg">
    </button>
  </div>
  <div class="settingsMenu">
    <mat-form-field appearance="outline" class="icon-right" (click)="$event.stopPropagation();">
      <mat-select placeholder="{{'Internal Sound'}}" [(ngModel)]="currentAudioOutputDevice"
        (click)="$event.stopPropagation();">
        <mat-option *ngFor="let data of speakers" [value]="data.label" (click)="changeSpeaker(data)">
          {{data.label}}
        </mat-option>
      </mat-select>
      <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
    </mat-form-field>
    <button mat-button>
      <img src="assets/Videoimages/volume-high.svg">
    </button>
  </div>
  <div class="settingsMenu">
    <mat-form-field appearance="outline" class="icon-right" (click)="$event.stopPropagation();">
      <mat-select placeholder="{{'Internal Camera'}}" [(ngModel)]="selectedVideoCamera"
        (click)="$event.stopPropagation();">
        <mat-option *ngFor="let data of videoInputs" [value]="data.label" (click)="changeVideoDevice(data)">
          {{data.label}}
        </mat-option>
      </mat-select>
      <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
    </mat-form-field>

    <button mat-button>
      <img src="assets/Videoimages/video_Outline.svg">
    </button>
  </div>
  <kendo-dialog-actions class="btn-container">
    <button class="btn-secondary-rect m-r-10" (click)="onSettings = false">Cancel</button>
    <button class="btn-primary-rect" (click)="onSettingsClose()">ok</button>
  </kendo-dialog-actions>
</kendo-dialog>



<mat-drawer class="slide-tray" *ngIf="openInviteDialog" [opened]="openInviteDialog" mode="side" position="end">
  <div class="slide-tray-container">
    <div class="slide-tray-header">
      <h5>{{'Patient queue' }}
        <mat-icon mattooltip="Close" matSuffix (click)="onClickCancel()">close</mat-icon>
      </h5>
    </div>
    <mat-tab-group class="m-tab-header-xs-20 padd-top20" [(selectedIndex)]="selectedTabIndex"
      (selectedIndexChange)="selectedTabIndexChange($event)">
      <mat-tab label="Patient Queue">
        <div class="slide-tray-content ">
          <div class="list-content">
            <mat-list *ngIf="patientQueueData.length > 0">
              <mat-list-item *ngFor="let list of patientQueueData">
                <div mat-list-icon>
                  <img src="https://v2webprdfeature.mmh-demo.com/Sitecontent/Photos/nophoto_male.gif?1672130821402"
                    alt="" class="profile-pic img-fluid displayproviderimage">
                </div>
                <div mat-line>
                  <span> {{list.InviteTo}} </span>
                </div>
                <div mat-line>
                  <p mat-line class="name-green">
                    {{ 'IN QUEUE' }}
                  </p>

                </div>
                <div class="db-btn" mat-list style="margin-top: -10px">
                  <button mat-icon-button class="ms-auto" (click)="onClickVideoButton(list)">
                    <i class="icon-cent-camera-medical"></i>
                  </button>
                </div>
                <hr>
              </mat-list-item>
            </mat-list>
            <p *ngIf="patientQueueData.length == 0">No patients are in queue</p>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="External Participant">
        <div class="slide-tray-content">
          <form autocomplete="off" [formGroup]="messaging">
            <div fxLayout="row">
              <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" class="login-content">
                <mat-form-field appearance="outline"
                  [ngClass]="{
            'has-success': messaging.controls['patientName'].valid && messaging.controls['patientName'].touched || messaging.controls['patientName'].dirty}"
                  class="m-r-5">
                  <mat-label class="required">{{'Participant name'}}</mat-label>
                  <input formControlName="patientName" type="text" matInput
                    placeholder="{{'Participant name'}}" (keypress)="onParticipantText()">
                </mat-form-field>
                <mat-error
                  *ngIf="(messaging.controls['patientName'].hasError('required') && messaging.controls['patientName'].touched) || !showError"
                  class="text-danger support-text  error-name">
                  {{'Participant name is required'}}</mat-error>
                <mat-error
                  *ngIf="messaging.controls['patientName'].hasError('minlength') && messaging.controls['patientName'].touched"
                  class="text-danger support-text  error-name">
                  {{'Participant name should be atleast 3 characters long!'}}
                </mat-error>
                <section class="checkbox-section padd" [formGroup]="messaging">
                  <mat-checkbox class="checkbox-margin" formControlName="sms"
                    (change)="eventCheck($event)">{{'SMS'}}</mat-checkbox>
                  <mat-checkbox class="checkbox-margin emailcheckbox" formControlName="email"
                    (change)="eventCheckEmail($event)">
                    {{'Email'}}</mat-checkbox>

                  <mat-form-field appearance="outline"
                    [ngClass]="{
            'has-success': messaging.controls['emailAddress'].valid && messaging.controls['emailAddress'].touched || messaging.controls['emailAddress'].dirty}"
                    class="m-r-5" *ngIf="messaging.value.email">
                    <mat-label class="required">{{'Email address'}}</mat-label>
                    <input matInput formControlName="emailAddress" placeholder="{{'Email address'}}"
                      (keypress)="onEmailText()">
                  </mat-form-field>
                  <mat-error
                    *ngIf="messaging.controls['emailAddress'].hasError('email') && !messaging.controls['emailAddress'].hasError('required')&& messaging.controls['emailAddress'].touched || showEmailError"
                    class="text-danger support-text error-name">
                    {{'Please enter valid email address'}}
                  </mat-error>
                  <mat-error
                    *ngIf="messaging.controls['emailAddress'].hasError('required') && messaging.controls['emailAddress'].touched"
                    class="text-danger support-text  error-name">
                    {{'Participant email is required'}}</mat-error>
                  <div fxLayout="row" fxLayoutGap="20px" class="login-content">
                    <mat-form-field fxFlex="30" appearance="outline" class="has-success m-r-5"
                      *ngIf="messaging.value.sms">
                      <mat-label>{{'Code'}}</mat-label>
                      <mat-select placeholder="{{'Select country'}}"
                        [formControl]="messaging.controls['SelectCountry']"
                        (selectionChange)="optionSelect($event.value)">
                        <mat-option *ngFor="let data of selectCountry" [value]="data.code">
                          {{data.code}}<em class="flag-icon flag-icon-{{data.icon}}" style="margin-left:10px;"></em>
                        </mat-option>
                      </mat-select>
                      <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
                    </mat-form-field>
                    <mat-form-field appearance="outline" fxFlex="66"
                      [ngClass]="{
            'has-success': messaging.controls['phoneNumber'].valid && messaging.controls['phoneNumber'].touched || messaging.controls['phoneNumber'].dirty}"
                      class="m-r-5" *ngIf="messaging.value.sms">
                      <mat-label class="required">{{'Mobile number'}}</mat-label>
                      <input matInput formControlName="phoneNumber" type="number"
                        onkeypress="if(this.value.length==10) return false;" [(ngModel)]="phoneNumber"
                        placeholder="{{'Mobile number'}}">
                    </mat-form-field>
                  </div>
                  <div fxLayout="row">
                    <mat-error
                      *ngIf="messaging.controls['phoneNumber'].hasError('required') && messaging.controls['phoneNumber'].touched"
                      class="text-danger support-text error-name">
                      {{'Mobile number is required'}}</mat-error>
                    <mat-error
                      *ngIf="messaging.controls['phoneNumber'].hasError('minlength') && messaging.controls['phoneNumber'].touched || showMinLenPhone"
                      class="text-danger support-text  error-name">
                      {{'Mobile number should be atleast 8 digit'}}
                    </mat-error>
                    <mat-error *ngIf="showCodeIsRequired" class="text-danger support-text error-name">
                      {{'Country code is required'}}</mat-error>
                  </div>

                  <mat-form-field appearance="outline"
                    [ngClass]="{'has-success': messaging.controls['comment'].valid && messaging.controls['comment'].touched || messaging.controls['comment'].dirty}">
                    <textarea matInput [maxlength]="maxChars" rows="5" [(ngModel)]="role" formControlName="comment"
                      style="resize:none"></textarea>
                  </mat-form-field>
                  <mat-error *ngIf="showErrorComment" class="text-danger support-text error-name">
                    {{'Comment is required'}}</mat-error>
                  <p class="text-end">{{role.length}}/{{maxChars}}</p>
                </section>
              </div>
            </div>

          </form>
        </div>

      </mat-tab>

  </mat-tab-group>
  <div class="slide-tray-footer" *ngIf="selectedTabIndex == 1">
    <div class="footer-buttons">
      <button *ngIf="messaging.valid" mat-button class="btn-primary-pill m-r-14" (click)="onClickSend()">Send
        message</button>
      <button *ngIf="!messaging.valid" mat-button class="btn-primary-pill m-r-14" disabled>Send message</button>
      <button mat-button class="btn-secondary-pill-gray" style="cursor: pointer;"
        (click)="onClickCancel()">Cancel</button>
    </div>
  </div>
  </div>



</mat-drawer>

<!-- <mat-drawer class="slide-tray" *ngIf="openInvitWaitingQueue" [opened]="openInvitWaitingQueue" mode="side" position="end">

</mat-drawer> -->



<mat-menu #devicesettingForMobile="matMenu" class="mat-menu-devicesettings">
  <div class="settingsMenu">
    <mat-form-field appearance="outline" class="icon-right" (click)="$event.stopPropagation();">
      <mat-select placeholder="{{'Internal Microphone'}}" [(ngModel)]="selectedAudioDevice"
        (click)="$event.stopPropagation();">
        <mat-option *ngFor="let data of mics" [value]="data.label" (click)="changeMicroPhone(data)">
          {{data.label}}
        </mat-option>
      </mat-select>
      <mat-icon matSuffix class="arrow-down">keyboard_arrow_down</mat-icon>
    </mat-form-field>
    <button class="m-t-45" mat-button disabled>
      <img src="assets/Videoimages/volume-high.svg">
    </button>
  </div>
  <button mat-raised-button class="btn-speakerOff" (click)="speakerMute()">{{speakerText}}</button>
</mat-menu>

<!-- <kendo-dialog title="{{'Information'}}" *ngIf="isErrorPopup" (close)="isErrorPopup = false" [minWidth]="250"
    [width]="500" class="res-sm hide-close-icon">
    <p> {{erroMessage}} </p>
    <p><strong>{{'NOTE: '}} </strong>{{'Can you please restart your browser or allow audio or video access to your browser or please close the application which is using your audio and video.'}}</p>
    <kendo-dialog-actions>
        <button (click)="closePopup()" class="btn-error-popup">{{'Close'}}</button>
    </kendo-dialog-actions>
</kendo-dialog> -->

<div class="dialog-footer">

</div>
<!-- </div> -->
<!-- </p-dialog> -->



