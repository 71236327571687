import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, observable, Observable } from 'rxjs';
import { TokenService } from 'src/app/data/service/token.service';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { applicationHttpClientCreator } from 'src/app/data/interface/IRequestOptions';
import { URLConstants } from '../constants/urlConstants';
import { EncryptDecrypt } from '../helper/encrypt-decrypt';
import { AppService } from 'src/app/data/service/app.service';
import { AuthenticationService } from 'src/app/data/service/authentication.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  isRefreshingToken: boolean = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public constructor(private tokenService: TokenService,
    private router: Router,
    private http: HttpClient,
    private appService: AppService,
    private authenticationService: AuthenticationService) {
    console.log('auth interceptor');
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler) {
    let clonedRequest: any;

    if (req.url.includes(environment.discoverHealthApi)) {

      if (this.tokenService.token() != null) {
        clonedRequest = req.clone({
          headers: req.headers
            .set('Authorization', 'Bearer ' + sessionStorage.getItem('WebPressToken'))
            .set('Access-Control-Allow-Origin', '*')
            
          // Add more headers as needed
        });
      }
      console.log(clonedRequest);
      return next.handle(clonedRequest);
    }

    

    //Alex token 
    if(req.url.indexOf(environment.AlexAPIUrl)>-1){
      clonedRequest = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + this.getAlexToken()) });
    }else 
    if (this.tokenService.token() != null) {
      clonedRequest = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + this.getToken()) });
    }
    else {
      clonedRequest = req.clone({});
    }
    return next.handle(clonedRequest);
    // console.log("TimeInterceptor: ", new Date().getTime());
    // return next.handle(clonedRequest).pipe(
    //   catchError(error => {
    //     // checks if a url is to an admin api or not
    //     if (error.status === 401) {
    //       // if (!this.isRefreshingToken) {
    //       //   this.authenticationService.logout();
    //       //   if(sessionStorage.getItem("isBetaUser")=="true"){
    //       //    this.router.navigateByUrl(environment.v1URL);
    //       //   }else{
    //       //   this.router.navigate(['/authentication/login']);
    //       //   }
    //       //   //return next.handle(req);
    //       // }
    //       return next.handle(req);
    //     }
    //     else if (error.status === 400) {
    //       //redirect custom page
    //       return next.handle(req);
    //     }
    //     else {
    //       return next.handle(req);
    //     }
    // }));
  }

  public getToken(): string {
    let tokenValue: string = '';
    tokenValue = this.tokenService.token();
    return tokenValue;
  }
  private getAlexToken(): string {
    let tokenValue: string = '';
    tokenValue = this.tokenService.alexToken();
    return tokenValue;
  }  
}
