export class URLConstants {

  public static Login: string = "/api/Account/SignIn";
  public static Registration: string = "/api/Account/Register";
  public static getUserRoles: string = "/api/Account/GetUserRoles?email=";
  public static selfRegistration = "/api/Account/SelfRegistration";
  public static ActivatePatient = "/api/Account/ActivatePatient";

  public static smsConfirmationLink = '/api/Account/SMSConfirmationByLink?sqString=';
  public static smsConfirmation = '/api/Account/SMSConfirmation?smscode=';
  public static GenerateAndSendOPTPatAct = '/api/Account/GenerateAndSendOPTPatAct?Useremail=';

  public static userActivation: string = "/api/Account/UserActivation";
  public static registrationResendEMail: string = "/api/Account/ResendEmail?useremail=";
  public static viewProfile: string = "/api/Account/GetUserProfile";
  public static autoRedirectUser: string = '/api/Account/UserAutoRedirect';
  public static ssoAutoRedirectUser: string='/api/SingleSignOnController/UserSSORedirect';
  public static UserSSORedirectVC: string='/api/SingleSignOnController/UserSSORedirectVC';
  public static GenerateSSOlinkVC: string='/api/SingleSignOnController/GenerateSSOlinkVC';
  public static ethnicities: string = "/api/Account/GetEthnicity";
  public static changePassword: string = '/api/Account/ChangePassword';
  public static getUserProfile = '/api/Account/GetUserProfile';
  public static forgotPassword = '/api/Account/ForgotPassword?emailAddress=';
  public static isValidBTBView = '/api/Account/IsValidBTBView';
  public static getProviderDashboardAppointmentStatus ='/api/Appointment/GetProviderDashboardAppointmentStatus?userId=';
  public static getProviderFutureAppointmentStatus = '/api/Appointment/GetProviderFutureAppointmentStatus';
  public static SaveAlexRequestResponse = '/api/Alex/SaveAlexRequestResponse';
  public static AlexNewAppointmentBookingRequest = '/api/Alex/AlexNewAppointmentBookingRequest';
  public static checkSelfArrival = '/api/Alex/CheckAlexSelfArrival';
  // public static forgotPassword = '/api/Account/ForgotPassword?emailAddress=';
  public static forgotUserPassword = '/api/Account/ForgotUserPassword?emailAddress=';
  // public static userVerificationForPasswordReset = '/api/Account/UserVerificationForRestPassword?q=';
  public static userVerificationForPasswordReset = '/api/Account/ResetUserPassword?validate=';
  // public static resetNewPassword = '/api/Account/ResetNewPassword';
  public static resetNewPassword = '/api/Account/ResetUserPassword';
  public static changeEmail = '/api/Account/ChangeEmail';
  public static ConfirmUsernameChange = '/api/Account/ConfirmUsernameChange?Validate=';
  public static changeEmailAddress = '/api/Account/ChangeEmailAddress';
  public static getAddress = '/api/Account/GetAddress';
  public static updateUserProfile = '/api/Account/UpdateUserProfile';
  public static AddUserProfile = '/api/UserProfile/AddUserProfile';
  public static muUpdateUserProfile = '/api/UserProfile/UpdateUserProfile';
  public static muAddUserProfile = '/api/UserProfile/AddUserProfile';
  public static uploadProfilePhoto = '/api/Account/UploadProfilePhoto';
  public static uploadProfilePhotos = '/api/UserProfile/AddPatientProfilePhoto';
  public static updateProfilePhoto = '/api/Account/UpdatePhoto';
  public static titles = '/api/Account/GetUserTitles';
  public static getRegisteredHealthCenters = '/api/Practice/GetAllRegisteredHealthCentersByUserId';
  public static getUserProfileRegisteredHealthCenters = '/api/UserProfile/GetAllRegisteredHealthCentersByUserId';
  public static verifyPatientActivation = '/api/Account/VerifyActivation';
  public static getMedicAlertRelationShips = '/api/Medicalert/Relationships';
  public static otpSignin = '/api/Account/SignInWithOTP';
  public static verifyOTP = '/api/Account/VerificationOTPValidate';
  public static isUserRegistered = '/api/Account/GetuserExists';
  public static isSPCRegistered = '/api/Account/GetSPCExists?spc=';
  public static isRegNoRegistered = '/api/Account/GetRegNoExists?regNo=';
  public static getGetEmergencyContacts = '/api/Medicalert/GetEmergencyContact?PUserId=';
  public static getUserProfileGetEmergencyContacts = '/api/UserProfile/GetEmergencyContact?puserId=';
  public static deleteEmergencyContact = '/api/Medicalert/DeleteEmergencyContact';
  public static addEmergencyContact = '/api/Medicalert/AddEmergencyContact';
  public static updateEmergencyContact = '/api/Medicalert/UpdateEmergencyContact';
  public static eCAddressInsert = '/api/Medicalert/UserProfileECAddressInsert';
  public static generateMedicAlertSSOQS = '/api/Medicalert/GenerateMedicAlertSSOQS';
  public static extractMedicAlertSSOQS = '/api/SingleSignOnController/ExtractMedicAlertSSOQS';
  public static getPatientSubscriptions = '/api/Account/GetPatientSubscriptions?UserId=';
  public static getUserProfilePatientSubscriptions = '/api/Account/GetPatientSubscriptions?pUserId=';
  public static getAddressList = '/api/Account/GetAddressList';
  public static GetMedicationsList = '/api/Account/GetMedicationsList';
  public static coverages = '/api/Account/GetUserCoverages';

  public static EditHospitalDetails ='/api/Account/EditHospitalDetails';

  public static educations = '/api/Account/GetUserEducation';
  public static getLookupClinicalTrial = '/api/Account/GetLookupClinicalTrial';
  public static getLookupTrialStatus = '/api/Account/GetLookupTrialStatus';
  public static getLookupTrialGroup = '/api/Account/GetLookupTrialGroup';
  public static getLookupLanguages = '/api/Account/GetLookupLanguages';
  public static getTempUserId = '/api/Account/GetTempUserId';
  public static AddClinicalDetails = '/api/Account/AddClinicalDetails';
  public static UpdateClinicalDetails = '/api/Account/UpdateClinicalDetails';
  public static getClinicalDetails = "/api/Account/GetClinicalDetails?profileId=";
  public static getAllStaffDetails = "/api/Account/GetStaffDetails?practiceManagerId=";
  public static deleteClinicalDetails = '/api/Account/DeleteClinicalDetails';
  public static addClinicalTrailOtherDetails = '/api/Account/AddClinicalTrailOtherDetails';
  public static getRegularGPDetails = "/api/Account/GetRegularGPDetails?patientId=";
  public static AddRegularGP = '/api/Account/AddRegularGPDetails';
  public static EditRegularGP = '/api/Account/EditRegularGPDetails';

  public static hospitals = '/api/Account/GetHospitals';

  public static GetHospitalDetails = '/api/Account/GetHospitalDetails?TempUserId=';
  public static DeleteHospitalDetails ='/api/Account/DeleteHospitalDetails';
  public static AddNewPatient = '/api/Account/AddPatient';
  public static getPatientDetailsByEmail = '/api/Account/GetPatientDetailsByEmail?email=';



  public static getUserResidencyStatus = '/api/Account/GetUserResidencyStatus';
  public static getPostalAddressList = '/api/Account/GetPostalAddressList';
  public static getPracticeTypeList = '/api/PracticeDirectory/GetPracticeTypeList';
  public static specialities = '/api/Account/GetSpecialities';

  public static verifyMySubscriptions = '/api/Account/VerifyMySubscriptions?UserId=';
  public static saveTwoFactor = '/api/Account/SaveTwoFactorSettings';
  public static getTwoFactorAuthDetails = '/api/Account/GetTwoFactorAuthenticationDetails?userID=';
  public static generateCodeForTwoStepUserAuthenticate = '/api/Account/TwoStepUserAuthenticateMMHV1?Useremail=';
  public static checkVerificationCode = '/api/Account/VerificationCode?verificationCode=';
  public static getV2UserExists = "/api/Account/GetV2UserExists";
  public static saveV2User="/api/Account/SaveV2User";
  public static etherapyStaffRegistration = '/api/Account/ETherapyStaffRegistration?Validate=';
  public static activateBTBStaffRegistration = '/api/Account/ActivateBTBStaffRegistration';
  public static getPatientRoleByUserId = '/api/Account/GetPatientRolesByUserId?UserId=';
  public static savePatientAccountDeletePreference = '/api/Account/SavePatientAccountDeletePreference?UserId=';
  public static activatePatientAccount = '/api/Account/ActivatePatientAccount?validate=';
  public static getInboxMessages = '/api/Inbox/GetAllReceivedMessage?UserID=';
  public static deleteToTrash = '/api/Inbox/DeleteToTrash?InboxId=';
  public static getDraftMessages = '/api/Inbox/GetDraftMessage?InboxDraftMessageID=';
  public static getAllArchiveMessages = '/api/Inbox/GetAllTrashMessage?UserId=';
  public static getSentMessages = '/api/Inbox/GetAllSentMessage?UserId=';
  public static getAllGroupSentMessage = '/api/Inbox/GetAllGroupSentMessage?UserId='
  public static deleteAllDraftMessage = '/api/Inbox/DeleteAllDraftMessage';
  public static deleteGroupMessage = '/api/Inbox/DeleteGroupMessage?GroupMessageID=';
  public static getUnreadGroupMessages = '/api/Inbox/GetUnreadGroupMessages?UserId=';
  public static getGroupMessageRecipient = '/api/Inbox/GetUnreadGroupMessages';
  public static getAllUnreadMessage = '/api/Inbox/GetAllUnreadMessage?UserId=';
  public static getGroupMessages = '/api/Inbox/GetGroupMessages?UserId=';
  public static getGroupMessageDetail = '/api/Inbox/GetGroupMessageDetail?MessageID=';
  public static groupMessageUpdateReadStatus = '/api/Inbox/GroupMessageUpdateReadStatus?GroupMessageID=';
  public static messageDetails = '/api/Inbox/GetMessageDetails?UserID=';
  // public static getDraftMessageCount = '/api/Inbox/GetDraftMessageCount?UserID=';
  public static getGroupMessageUnreadCount = '/api/Inbox/GroupMessageUnreadCount?UserID=';
  public static InActivateLocation = '/api/PracticeDirectory/InActivateLocation';

  //manage users

  public static SuspendPatient = '/api/Account/SuspendPatient';

  public static SuspendStaff = '/api/Account/SuspendStaff';
  
  // Inbox Urls
  public static getAllSearchedReceivedMessage = '/api/Inbox/GetAllSearchedReceivedMessage?UserID=';
  // public static mailMarkAsRead = '/api/Inbox/MailMarkAsRead?InboxId=';
  public static mailMarkAsRead = '/api/Inbox/MailMarkAsReadbyUserID?UserID=';
  public static mailMarkedasUnread = '/api/Inbox/MailMarkAsUnRead?InboxId=';
  public static mailMarkAsUnreadUserId = '/api/Inbox/MailMarkAsUnReadbyUserID?UserID=';
  public static inboxCount = '/api/Inbox/GetUnReadMessageCountByUser?UserId=';
  public static archiveToTrash = '/api/Inbox/DeleteToTrashs?UserID=';
  public static getAllReceivedMessageCount = '/api/Inbox/GetAllReceivedMessageCount?UserId=';
  public static getDraftMessageCount = '/api/Inbox/GetDraftMessageCount?UserID=';
  public static GetSelectedMailDetailsbyMailId = '/api/Inbox/GetSelectedMailDetailsbyMailId?UserId=';
  public static GetClinicalStaffExtComm = '/api/Inbox/GetClinicalStaffForExtComm?PracticeManagerID=';

  //Compose Urls
  public static getHealthCenterDetails = '/api/Inbox/GetAllPracticeByUser?UserId=';
  public static getPracticeLocations = '/api/Inbox/GetPracticeLocation?PracticeManagerId=';
  public static getServiceType = '/api/Inbox/GetServiceTypebyprtcIdandlocIdandusrId?PracticeAdminId=';
  public static getAllRolesByPractice = '/api/Inbox/GetAllRolesByPractice?PracticeId=';
  public static getAllStaff = '/api/Inbox/GetAllStaff?PracticeManagerID=';
  public static getPatientList = '/api/Inbox/GetPatientListByLocality?nameType=';
  public static getAllUsersList = '/api/Appointment/getAllPracticeByUserClinical?userId=';
  public static toUserAutosuggestList = '/api/Inbox/GeAutoSuggestPatientList';
  public static isRRP = '/api/Inbox/IsRRP?messagetypeid=';
  public static rrpMedicationList = '/api/Inbox/GetPatientMedicationListForRRPnonhybrid?userid=';
  public static getServiceTypeByLocation = '/api/Inbox/GetServiceTypebyMsgtypeIdandlocidanduserId?MessageTypeId=';
  public static getPatientPracticeForExtComm = '/api/Inbox/GetPatientPracticeForExtComm?LoggedInUserID=';
  public static isPracticeExtCommunicationActive = '/api/Practice/IsPracticeExtCommunicationActive';
  public static GetAllPracticeUserNotetoGP = '/api/Inbox/GetAllPracticeByUserNotetoGP?pUserId=';
  public static GetPatientPracticeExtComm = '/api/Inbox/GetPatientPracticeForExtComm?LoggedInUserID=';

  //Common Urls For send as mesaage,compose new mail and save as draft
  public static composeNewMail = '/api/Inbox/ComposeNewMail';
  public static saveDraftMessage = '/api/Inbox/SaveDraftMessage';
  public static addInboxMessageAdditionalInformation = '/api/Inbox/AddInboxMessageAdditionalInformation';
  //Sent Message Urls
  public static getSentMessage = '/api/Inbox/GetSentMessage?UserID=';
  public static getSelectedSentMailData = '/api/Inbox/GetSelectedMailDetails?IsSentItem='
  public static getSelectedsentMail = '/api/Inbox/GetMessageDetails?UserID=';
  // Archive Message Urls
  public static getArchiveMessagesCount = '/api/Inbox/GetTrashMessageCount?UserID=';
  public static getArchiveMessages = '/api/Inbox/GetTrashMessage?UserID=';
  // public static mailRestoreFromTrash = '/api/Inbox/MailRestoreFromTrash?TrashId=';
  public static mailRestoreFromTrash = '/api/Inbox/MailRestoreFromTrash';
  //Draft Message Urls
  public static getDraftSearchMessage = '/api/Inbox/GetDraftSearchMessage?UserID=';
  public static deleteDraftMessage = '/api/Inbox/DeleteDraftMessage?DraftMessagID=';
  public static getDraftSelectedMail = '/api/Inbox/GetSelectedMail?MailId=';
  //Health Center from and touser details Urls
  public static getDraftPracticeDetails = '/api/Inbox/GetPracticeDetails?practiceid=';
  // Upload Files Url
  public static uploadFiles = '/api/Inbox/UploadFiles?UserId=';
  // Download Files Url
  public static downloadFile = '/api/Inbox/DownloadFile?filename=';
  public static downloadFile_Post = '/api/Inbox/DownloadFile';
  public static downloadAttachment = '/api/PatientReferral/DownloadAttachment?filename=';
  public static downloadAttachments = '/api/PatientReports/DownloadAttachment?filename=';


    // View Users Information
    public  static getTotalUsersCount = '/api/UserProfile/GetTotalUsersCount';

    public  static getUsersStatus = '/api/UserProfile/GetUsersStats';


  // View Profile Access Information
  public static getAccessedUsers = '/api/Account/GetWhoAccessed?UserId=';
  public static getUserProfileAccessedUsers = '/api/UserProfile/GetWhoAccessed?puserId=';
  public static getLasttenloginUsers = '/api/Account/GetLast10Logins?UserId=';
  public static getUserProfileLasttenloginUsers = '/api/UserProfile/GetLast10Logins?pUserId=';


  public static getUnReadMessageCountByUser = '/api/Inbox/GetUnReadMessageCountByUser?UserId=';
  public static composeSendMessage = '/api/Inbox/SendMessage?From=';
  public static userprofile = '/api/Account/GetUserProfile/';
  public static getAllSentMessagePagination = '/api/Inbox/GetAllSentMessagePagination?UserID=';


  //#region Practice Directory

  public static getPracticeAndLocations = '/api/PracticeDirectory/GetPracticeAndLocations?userid=';
  public static getPracticeDetails = '/api/PracticeDirectory/GetPracticeDetails?practiceId=';
  public static getPracticeBAdminRole = '/api/PracticeDirectory/GetPracticeByPracticeAdminRole?userid=';
  public static updateContactDetails = '/api/PracticeDirectory/UpdatePractice';
  public static getPracticeLocationsByUserId = '/api/PracticeDirectory/GetPracticeAndLocationsPracticeMasterIdByUserId?userid=';

  public static getPracticeMasterDetails = '/api/PracticeDirectory/GetPracticeMasterDetails?PracticeId=';



  //contact detais
  public static getRegionList = '/api/PracticeDirectory/GetRegionList';
  public static getContactDetails = '/api/PracticeDirectory/GetPractice?practiceId=';
  public static getPracticeContactDetails = '/api/PracticeDirectory/GetPracticeContactDetails?practiceMasterId=';
  public static getSuburbAddress = '/api/PracticeDirectory//GetStreetAddress?suburb=';
  public static uploadPracticePhoto = '/api/PracticeDirectory/UploadPracticePhoto';
  public static savePracticePhotos = '/api/PracticeDirectory/SavePhoto';
  public static getAddressDetailsByAddressID= '/api/Account/GetAddressDetailsByAddressID?addressID='

  //opening hours Tab
  public static getOpeningHours = '/api/PracticeDirectory/GetContactHours?practiceId='
  public static updateOpeningHours = '/api/PracticeDirectory/UpdateContactHours'


  //facilities tab
  public static getFacilities = '/api/PracticeDirectory/GetFacilities';
  public static getServices = '/api/PracticeDirectory/GetServices';
  public static getFacility = '/api/PracticeDirectory/GetFacility?practiceId=';
  public static getService = '/api/PracticeDirectory/GetPracticeService?practiceId=';
  public static updateFacility = '/api/PracticeDirectory/UpdateFacility?userId=';
  public static updatePracticeService = '/api/PracticeDirectory/UpdatePracticeSerivce?userId=';

  //service tab
  public static getServiceDetails = '/api/PracticeDirectory/GetService?practiceId=';
  public static addPDService = '/api/PracticeDirectory/CreateService';
  public static updatePDService = '/api/PracticeDirectory/UpdateService';

  //documents tab
  public static getPDDocuments = '/api/PracticeDirectory/GetDocuments?practiceId=';
  public static addPDDocument = '/api/PracticeDirectory/InsertDocument';
  public static downloadPDDocument = '/api/PracticeDirectory/DocumentDownload';
  public static uploadPDDocument = '/api/PracticeDirectory/UploadDocuments';
  public static deletePDDocument = '/api/PracticeDirectory/DeleteDocument/';

  //staff tab
  public static getStaffLists = '/api/PracticeDirectory/GetStaffLists?practiceMasterId=';
  public static getStaffInterests = '/api/PracticeDirectory/GetStaffInterests?staffId=';
  public static getStaffKnownLang = '/api/PracticeDirectory/GetStaffKnownLang?staffId=';
  public static getStaffLocations = '/api/PracticeDirectory/GetStaffLocations?practiceMasterId=';

  public static getStaffDetailsByUserId = '/api/PracticeDirectory/GetStaffDetailsByUserId?userId=';
  public static getStaffDetailsStaffId = '/api/PracticeDirectory/GetStaffDetailsByStaffId/';
  public static getStaffDetailsByEmail = '/api/PracticeDirectory/GetStaffDetailsByEmail?email=';
  public static associateStaffPractice = '/api/Account/AssociateStaffPractice';
  public static insertStaff = '/api/PracticeDirectory/InsertStaff';
  public static EnrolStaff = '/api/Practice/EnrolStaff';
  public static UpdateStaffDetails = '/api/Practice/UpdateStaffDetails';

  public static updateStaff = '/api/PracticeDirectory/UpdateStaff';
  public static getServiceTemplates = '/api/PracticeDirectory/GetServiceTemplates';

  //Previous and future appointments
  public static futureAppointments = '/api/Appointment/GetFutureAppointmentStatus?patientId=';
  public static pastAppointments = '/api/Appointment/GetPastAppointmentStatus?patientId=';

  //DashBoard
  public static DashBoardfutureAppointments = '/api/Appointment/GetFutureAppointmentDashBoardStatus?patientId=';

  // #region Video Invitation for patient
  public static videoInvitation = '/api/Inbox/GetFutureVideoConsultationInvitation?UserID=';
  public static healthCenter = '/api/Appointment/GetPracticeByStaffRole?userId=';
  public static providersList = '/api/Inbox/GetStaffList?PracticeManagerID=';
  public static getReminderTimeForVideoConsultByOD = '/api/Appointment/GetReminderTimeForVideoConsultByOD?PracticeID=';
  public static getProviderExistOnVideoConsult="/api/Appointment/GetProviderExistOnVideoConsult?RoomName=";
  public static videoConsultation = '/api/Inbox/GetVideoConsultationInvitation?PMSProviderID=';
  public static getVidyoToken = '/api/VideoController/GenerateToken';
  //#endregion

  // #region Payments
  public static processAppointmentPayment = '/api/Appointment/ProcessAppointmentPayment';
  public static newAppointmentSubmit = '/api/Appointment/NewAppointmentBookingRequest';
  public static UpdatePatientPAContactNumberForAppointment = '/api/Appointment/UpdatePatientPAContactNumberForAppointment';
  public static apointmentPaymentResponse = '/api/Appointment/AppointmentPaymentResponse?op=';
  public static SendRRPRequest = '/api/RequestRepeatPrescription/SendRRPRequest';
  public static RRPPaymentResponse = '/api/RequestRepeatPrescription/RRPPaymentResponse?op=';
  public static RRPPaymentResponseAccount = '/api/Visitor/RRPPaymentResponse';
  public static apointmentPaymentResponseAccount = "/api/Visitor/AppointmentPaymentResponse";
  public static  userPaymentResponseView="/api/Visitor/UserPaymentResponseView?type="
  public static  userabandontransaction="/api/Visitor/SavePaymentAbandonStatus?type=";
  public static checkPaymentResponse='/api/PxPayment/CheckPaymentResult?txnId=';
  public static errorPaymentResponse='/api/PxPayment/ErrorPaymentResult?txnId=';
  public static refundPayment='/api/PxPayment/RefundPayment?txnId=';
  //#endregion

  // #region  Scheduler Calendar
  public static getCalendarUserSettings = '/api/Calendar/GetCalendarUserSettings?userId=';
  public static updateCalendarSettings = '/api/Calendar/UpdateCalendarSettings?userId=';
  public static submitCategoriesSettings = '/api/Calendar/SubmitCategoriesSettings?userId=';
  public static getCalendarCategories = '/api/Calendar/GetCalendarCategories?userId=';
  public static getCalendarUserId = '/api/Calendar/GetCalendarUserId?userId=';
  public static getCalendarEvents = '/api/Calendar/GetCalendarEvents?userId=';
  public static deleteEventFromCalendar = '/api/Calendar/DeleteEventFromCalender?EventID=';
  public static addEventToCalendar = '/api/Calendar/AddEventToCalendar?UserID=';
  public static updateEventToCalendar = '/api/Calendar/UpdateEventToCalendar';
  public static addCategory = '/api/Calendar/CreateCategory?userId=';
  public static deleteCategory = '/api/Calendar/Deletecategory?categoryID=';
  //#endregion

  public static GetUserAndMenu = '/api/Account/GetUserRolesandMenu?userId=';



  public static getFees = '/api/PracticeDirectory/GetFees?practiceId=';
  public static getFeesDetails = '/api/PracticeDirectory/GetFeesDetails?feesId=';
  public static createFee = '/api/PracticeDirectory/CreateFees';
  public static updateFee = '/api/PracticeDirectory/UpdateFee';
  public static deleteFee = '/api/PracticeDirectory/DeleteFee?practiceId=';
  public static getRegisteredPatientFees = '/api/PracticeDirectory/GetPaymentFeeStructureForRegisteredPatient';
  public static getCasualPatientFees = '/api/PracticeDirectory/GetPaymentFeeStructureForCasualPatient';
  public static saveOnlineFeeForRegisteredPatients = '/api/PracticeDirectory/SaveFeeStructureForRegisteredPatients';
  public static saveOnlineFeeForCasualPatients = '/api/PracticeDirectory/SaveFeeStructureForCasualPatients';

  //#endregion Practice Directory

  // public static getPracticeAndLocations='/api/PracticeDirectory/GetPracticeAndLocations';

  public static getCenters = '/api/Appointment/GetPracticeLocationsForAppointments?practiceId=99994FC6-EAB0-4E8F-A3A6-9C49B90DA10C&loggedInUserID=77B2300F-C95D-495D-95E7-C2477D56DE0E';
  public static getDoctors = '/api/Appointment/GetLocationStaff?locCode=M&practiceId=99994FC6-EAB0-4E8F-A3A6-9C49B90DA10C&loggedInUserID=77B2300F-C95D-495D-95E7-C2477D56DE0E';
  //public static getAvailableAppointmentDates = '/api/Dashboard/GetSelectedProviderSlots?spc=SRINI&locCode=M&practiceId=99994FC6-EAB0-4E8F-A3A6-9C49B90DA10C&loggedInUserID=77B2300F-C95D-495D-95E7-C2477D56DE0E';
  //public static getAvailableSlotsByDate = '/api/Dashboard/GetSelectedDateSlotsDt?appDate=2021-06-16&practiceId=99994FC6-EAB0-4E8F-A3A6-9C49B90DA10C&providerCode=SRINI';
  //public static getAvailableSlotsByDate = '/api/Dashboard/GetSelectedDateSlotsDt?appDate=2021-06-16&practiceId=99994FC6-EAB0-4E8F-A3A6-9C49B90DA10C&providerCode=SRINI';

  /* provider group messages */
  public static getGroupMessageReceipientCount = '/api/Inbox/GetGroupMessageRecipientCount?PracticeManagerId=';
  public static getFromDetails = '/api/Inbox/GetPracticeWithPracticeAdminAndReception?UserID=';
  public static getPatientDefaultProviders = '/api/Inbox/GetGroupMessageStaffList?PracticeManagerId=';
  public static getToDetails = '/api/Inbox/GetGroupMessageRecipients';
  public static sendGroupMessage = '/api/Inbox/SendGroupMessageProvider';
  public static GetAllGroupSentMessage = '/api/Inbox/GetAllGroupSentMessage?UserID=';
  public static SetHealthSummaryPMSCount = '/api/LabResults/SetHealthSummaryPMSCount';
  public static getUserSettings = '/api/Inbox/GetUserSettings?UserId=';
  public static updateUserSettings = '/api/Inbox/UpdateUserSettings?UserId=';


  //recall settings
  public static recallSettings = '/api/RecallSettings/UpdateRecallReminderSettings?PracticeID=';


  //#region setup services
  public static getSetUpServiceType = '/api/Inbox/GetServiceType?practiceadmin=';
  public static getSetUpServiceTypeByStatus = '/api/Inbox/GetServiceTypebyStatus?practiceadmin=';
  public static getSetUpHealthCenters = '/api/Practice/GetPraciceByUserPracticeAdmin?userId=';
  public static getLookupServiceExcludeRRP = '/api/Inbox/GetLookupServiceExcludeRRP';
  public static getStaffRoles = '/api/Inbox/GetStaffRoles';
  public static getRecipient = '/api/Inbox/GetRecipient?MessageTypeId=';
  public static getServiceTypeByBillable = '/api/Inbox/getServiceTypeByBillable?MessageTypeId=';
  public static getAllStaffDefaults = '/api/Inbox/GetAllStaffDefaults?PracticeManagerID=';
  public static getAllRRPStaffDefaults = '/api/Inbox/GetAllStaffNoDefaults?PracticeManagerID=';

  public static GetPracticeLocationsbypracticeManagerId = '/api/Practice/GetPracticeLocationsbypracticeManagerId?practiceManagerId=';
  public static updateMessageStatus = '/api/Inbox/UpdateMessageTypeStatus?MessageTypeID=';
  public static addSetUpService = '/api/Inbox/InsertMessageType';
  public static updateSetUpService = '/api/Inbox/UpdateMessageType';
  public static getLookupMedRoles = '/api/Inbox/GetLookupMedRoles?RoleType=';
  public static checkServiceName = '/api/Inbox/CheckServiceName?ServiceTypeID=';
  public static checkServiceTypeCode = '/api/Inbox/CheckServiceTypeCode?ServiceTypeID=';
  public static getBookedAppointmentInfobySPC = '/api/Appointment/GetBookedAppointmentInfobySPC?PracticeID=';
  public static GetStaffRoleRemoved = '/api/Inbox/GetStaffRoleRemoved?RecipentID=';
  //#endregion

  //#region MyProfile
  public static staffPracticeCount = '/api/PracticeDirectory/StaffPracticeCount';
  //#endregion
  // Patient registration Template
  public static getpatientRegHealthCenter = '/api/PatientRegistrationTemplate/GetPracticeByStaffRole?UserId=';
  public static getMergeSymbols = '/api/PatientRegistrationTemplate/GetMergeSymbols?Category=';
  public static getPatientRegTemplate = '/api/PatientRegistrationTemplate/GetPatientRegistrationTemplate?PracticeManagerID=';
  public static submitAndPublishPatientTemplate = '/api/PatientRegistrationTemplate/SubmitandPublishPatientRegTemplate';
  public static submitAndPreviewTemplatePdf = '/api/PatientRegistrationTemplate/SubmitandPreviewPatientRegTemplate';
  public static getPraciceByUserPracticeAdmin = '/api/Practice/GetPraciceByUserPracticeAdmin?UserId=';

  //apointment settings
  public static getPracticeByStaffRole = '/api/Appointment/GetPracticeByStaffRole?userId=';
  public static getTurnOffAppAuditCount = '/api/Practice/GetApptTurnOffAuditCount?loggedInUserID=';
  public static getTurnOffAppAudit = '/api/Practice/GetApptTurnOffAuditPaging';
  public static getTurnOffOnlineAppointments = '/api/Practice/GetPracticeSettingForApptTurnOff?practiceManagerId=';
  public static saveTurnOffAppointments = '/api/Appointment/UpdatePracticeAppointmentTurnoff?practiceId=';
  public static getAppointmentSettings = '/api/Appointment/GetPracticeSettings?practiceID=';
  public static saveAppointmentSettings = '/api/Practice/UpdatePracticeAppointmentSettings';
  public static getBlockProvidersList = '/api/Practice/GetAppointmentProvidersByPracticeLocation?PracticeManagerId=';
  public static saveBlockedApptForProvider = '/api/Practice/TurnOffAppointmentforProviders';
  public static getBlockedAppointmentSlots = "/api/Practice/GetBlockedSlotsByPractice?PracticeManagerId=";
  public static blockedApptValidateDate = '/api/Practice/BlockedApptValidateDate?PracticeManagerId=';
  public static changeBlockedStatus = '/api/Practice/ChangeBlockedApptStatus?PracticeManagerId=';
  public static getRuleEProvidersByPracticeManagerId = '/api/Appointment/GetAppointmentRuleEProviders?practiceId=';
  public static saveRuleEForProviders ='/api/Appointment/SaveAppointmentRuleEproviders?practiceId=';
  public static getSelfArrivalMins = '/api/Appointment/GetLookupSelfArrivalMins';
  public static getAppointmentLocationPaymentOption = '/api/Appointment/GetAppointmentLocationPaymentOption?practiceManagerId=';

  // Appointment Messages
  public static appmsgHealthCenter = '/api/Practice/GetPracticeByStaffRole?userId=';
  public static submitAppointmentMessages = '/api/Practice/UpdatePracticeAppointmentMessageSettings';
  public static practiceLocationsByManagerId = '/api/Practice/GetPracticeLocationsbypracticeManagerId?practiceManagerId=';
  public static getPracticeLocSPCByPracticeManagerId  = '/api/Practice/GetPracticeLocSPCByPracticeManagerId?practiceManagerId=';
  public static updateLocationPreScreening = '/api/Practice/UpdateLocationPreScreening';
  public static GetStaffForOnlineAppReport = '/api/Practice/GetStaffForOnlineAppReport?practiceManagerID=';
  public static GetStaffForOnlineappointmentdayoff = '/api/Practice/GetStaffForOnlineappointmentdayoff?practiceManagerID=';
  public static GetPracticeLocationMerchant = '/api/Practice/GetPracticeLocationsforMerchantAccount?practiceManagerId=';
  public static GetPracticeCopyLocationMerchant = '/api/PxPayment/GetPracticePxPayCredentialsCopyFromLocation?PracticeManagerId=';


  //Online Payment Settings
  public static getOnlinePaymentSettings = '/api/Practice/GetPracticeOPSettings?practiceID=';
  public static saveOnlinePaymentSettings = '/api/Practice/UpdatePracticeOnilinePaymentSettings';

  //Video Consultation Settings
  public static saveVideoConsultationSettings = '/api/Practice/UpdatePracticeVirtualConsultSettings';
  public static getServiceCategory = '/api/Practice/GetLookupServiceForOnDemandVideoConsult';
  public static saveOnDemandVCSettings = '/api/Practice/UpdatePracticeODVideoConsultSettings';
  public static saveSmsInviteVCSettings = '/api/Practice/UpdatePracticeSMSVideoConsultSettings';

  // Merchant Account Setup
  public static getMerchantAccountDetails = '/api/PxPayment/GetPracticePxPayCredentials?PracticeManagerId=';
  public static getMerchantAccountLocationDetails = '/api/PxPayment/GetPracticePxPayCredentialsLocationWise?PracticeManagerId=';
  public static validateAccount = '/api/PxPayment/ValidateAccount';
  public static validateLocationAccount = '/api/PxPayment/ValidateAccountLocationWise';
  public static validateAndSaveAccount = '/api/PxPayment/SavePayExPracticeSettings';
  public static validateAndSaveMerchantAccount = '/api/PxPayment/SavePayExMerchantPracticeSettings';

  // My Payments (Payment report)
  public static getPatientHealthCenter = '/api/Appointment/GetPatientHealthCenters?patientuserid=';
  public static getPatientLocations = '/api/Appointment/GetPracticeLocations?practiceManagerId=';
  public static GetPatientHealthCentersWithLocations ="/api/Appointment/GetPatientHealthCentersWithLocations";
  public static getPaymentReports = '/api/Inbox/GetOnlinePaymentReportByPatient?patientUserID=';
  public static getProviderPaymentReports = '/api/Inbox/GETOnlinePaymentReportByProvider?PracticeID=';
  public static getPatientReportsByTransId = '/api/Inbox/GetOnlineTransactionDetailsByMMHTransRef?MMHPayTxnID=';
  public static providerPdfExport = '/api/PxPayment/ExportOnlinePaymentsToPdf';
  public static patientReceiptPdfExport = '/api/PxPayment/ExportOnlinePaymentsPDFbyPatient?MMHPayTxnID=';
  public static patientExcelReport = '/api/PxPayment/ExportOnlinePaymentsToExcel';

  //Phone Appointment Settings
  public static getPhnApptRules = '/api/Practice/GetPhoneAppointmentRulesList';
  public static getFormattedMobileNumber = '/api/Account/GetFormattedMobileNumber';
  public static savePhoneAppointmentSettings = '/api/Practice/UpdateLocationPhoneOnlyAppointment';

  // Appointments
  public static getHealthCenters = '/api/Appointment/GetPatientHealthCenters?patientuserid=';
  public static getLicenceBitMapCodeByPractice = '/api/Appointment/GetLicenceBitMapCodeByPractice?practiceManagerId=';
  public static isValidLicence = '/api/Appointment/IsValidLicence?bitMapCodes=';
  public static getPracticeLocationsForAppointments = '/api/Appointment/GetPracticeLocationsForAppointments?practiceId='; 
  public static GetPracticeLocationsForAppointments_EasyBooking = '/api/Appointment/GetPracticeLocationsForAppointments_EasyBooking?practiceId=';
  public static getPreScreeningAlertByPractice = '/api/Appointment/GetPreScreeningAlertByPractice?practiceId=*******&locationCode=';
  public static getPatientSuspendDetails = '/api/Appointment/GetPatientsuspenddetails?userid=';
  public static getStaffsuspenddetails = '/api/Appointment/GetStaffsuspenddetails?userid=';
  public static getPreScreeningAlert = '/api/Appointment/GetPreScreeningAlertByPractice?practiceId=';
  //public static getPracticeProvidersForAppointments = '/api/Appointment/GetLocationStaffByConsultationType?locCode=';
  public static getPracticeProvidersForAppointments = '/api/Appointment/GetLocationStaff?locCode=';
  public static getdoctordetails = '/api/InsertAppointmentSlotPeriodsMaster/GetDoctorDetails?locCode=';
  public static getAvailableAppointmentDates = '/api/Appointment/GetSelectedProviderSlots?spc=';
  public static getAvailableSlotsByDate = '/api/Appointment/GetSelectedDateSlotsDt?appDate=';
  public static getAppointmentFee = '/api/Appointment/CalculateAppointmentFeeByOnlinePayment?practiceManagerID=';
  public static getAvailableSlotsByDateAndConsultTypeFilter = '/api/Appointment/GetSelectedDateSlotsDtbyfilterconsulttype?selectedDate=';
  public static cancelAppointmentByLoggedInuser = '/api/Appointment/CancelAppointmentbyLoggedUser';
  public static getPatientDefaultGP = '/api/Appointment/GetPatientDefaultGP?patientuserId=';
  public static getPracticeVCSettings = '/api/Practice/GetPracticeVCSettings?practiceID=';
  public static getCountandConfigValues = '/api/Appointment/GetCountandConfigValues?practiceId=';
  public static getAppointmentReasons = '/api/Appointment/GetReasonForVisit?practiceId=';
  public static GetPracticeSettingForApptTurnOff = '/api/Appointment/GetPracticeSettingForApptTurnOff?practiceManagerId='
 public static getNextAvailableAppointment = '/api/Appointment/NextAvailableAppointments'

  // Home User Settings
  public static getHomeUserSettings = '/api/Settings/GetUserSettings?PuserId=';
  public static updateHomeUserSettings = '/api/Settings/UpdateSettings';


  // Setup Online Practice terms and conditions
  public static getPracticeTerms = '/api/Practice/GetPracticeTerms?practiceManagerID=';
  public static getUpdatedUserDetails = '/api/Practice/GetTermsConditionsLastUpdatedUserDetails?PracticeManagerID=';
  public static practiceTermsSaveAsDraft = '/api/Practice/UpdatePracticeTerms';
  public static enableOrDisableTCs = '/api/Practice/EnableOrDisableTermsAndConditions';
  public static getTermsAndConditionsAudit = "/api/Practice/GetTermsAndConditionsAudit?practiceID=";
  public static getAuditCount = '/api/Practice/GetTermsAndConditionsAuditCount/';


  //#region practice-search-patients
  public static getAllPracticeByUserClinical = '/api/Appointment/getAllPracticeByUserClinical?userId=';
  public static getAllCitiesFromNewZealand = '/api/SearchPatients/GetAllCitiesFromNewZealand';
  public static getSuburbsByCity = '/api/SearchPatients/GetSuburbsByCity?cityName=';
  public static getAutoSuggestPatientList = '/api/SearchPatients/GetAutoSuggestPatientList?userId=';
  public static getManageUserPatientList = '/api/SearchPatients/GetManageUserPatientList?PracticeManagerId=';

  public static searchPatient = '/api/SearchPatients/SearchPatient';
  public static getRecentlyAccessed = '/api/SearchPatients/GetRecentlyAccessed';
  public static getCriticalPatient = '/api/SearchPatients/GetCriticalPatients';
  public static addClinicalAuditEntry = '/api/SearchPatients/AddClinicalAuditEntry';
  public static getJournalEntriesPatientByProvider = '/api/ProviderJournal/GetJournalEntriesPatientByProvider/';
  public static isValidViewerForPatientInfo = '/api/SearchPatients/IsValidViewerForPatientInfo';
  public static getPracticeSettingsCareplan = '/api/Practice/GetPracticeSettingsCareplan?patientID=';
  //#endregion

  //#region beating-the-blues-search
  public static getBTBPracticeName = '/api/BeatingtheBlues/GetBTBPracticeName?userId=';
  public static getBTBSearchDetails = '/api/BeatingtheBlues/GetBTBSearchDetails';
  public static getBTBAutoSuggestPatientList = '/api/BeatingtheBlues/GetAutoSuggestPatientList';
  public static getBTBRecentlyAccessed = '/api/BeatingtheBlues/GetLastBTBAccessed?UserId=';
  public static getAllBTBPracticeByUserClinical = "/api/BeatingtheBlues/GetAllPracticeByUserClinical";
  public static getB2BLastAccessedPatientSearchResults = "/api/BeatingtheBlues/GetB2BLastAccessedPatientSearchResults";
  public static exportBTBPrescriptionPDF = "/api/BeatingtheBlues/ExportBTBPrescriptionPDF?sActivationCode=";
  public static getBtbProfileDetailsByUser = "/api/BeatingtheBlues/GetProfileDetailsByUser";
  public static getPatientReportUrl = '/api/BeatingtheBlues/GetPatientReportURL';
  public static getBTBLiveProgressReportPaging = "/api/BeatingtheBlues/GetBTBLiveProgressReportPaging";
  public static getUsageReportGraph = "/api/BeatingtheBlues/GetUsageReportGraph";
  public static getBTBInactiveUserListPaging = "/api/BeatingtheBlues/GetBTBInactiveUserListPaging";
  public static signoffBTBLiveProgressReport = "/api/BeatingtheBlues/Signoff?patientid=";
  public static isStaffRegisteredBtb = "/api/BeatingtheBlues/IsStaffRegisteredBtb";
  public static isMedtechMT32Practice = "/api/BeatingtheBlues/IsMedtechMT32Practice";

  public static getBTBReportGetProviderCount = '/api/BeatingtheBlues/GetBTBReportGetProviderCount';
  public static getBTBReportGetProviderCountByAgeGroup = '/api/BeatingtheBlues/GetBTBReportGetProviderCountByAgeGroup';
  public static savePatientRegisteredInBTB = '/api/BeatingtheBlues/SavePatientRegisteredInBTB';
  public static addNotes = '/api/BeatingtheBlues/AddNotes';
  public static getNotesList = '/api/BeatingtheBlues/GetNotesList';
  public static getBtbWhoAccessed = '/api/BeatingtheBlues/GetBtbWhoAccessed';
  public static getNZStreetNameCBO = '/api/BeatingtheBlues/GetNZStreetNameCBO';
  public static exportGridToPDF = '/api/BeatingtheBlues/ExportGridToPDF';
  public static exportGridToExcel = '/api/BeatingtheBlues/ExportGridToExcel';
  public static addAHCareClinicalAuditEntry = '/api/BeatingtheBlues/AddAHCareClinicalAuditEntry';
  public static getAllProfessionsList = '/api/BTBOnBoarding/GetAllProfessionsList';
  public static selfBTBPatientRegistration = '/api/BTBOnBoarding/SelfBTBPatientRegistration';
  public static selfBTBProviderRegistration = '/api/BTBOnBoarding/SelfBTBProviderRegistration';
  public static existingPatientSubscribeToBTB = '/api/BeatingtheBlues/ExistingPatientSubscribeToBTB';
  public static existingStaffSubscribeToBTB = '/api/BeatingtheBlues/ExistingStaffSubscribeToBTB';

  //public static =''
  //public static =''

  //#endregion


  // Lab Results Summary
  public static exportHealthSummaryPdf = '/api/LabResults/HealthSummaryPDF/';
  public static getPatientHealthSummary = '/api/LabResults/GetPatientHealthSummary?userId=';

  //#region  Prescriptions
  public static getPrescriptionsUE = '/api/LabResults/GetPrescriptionsListUEPaging';
  public static getPrescriptionsPMS = '/api/LabResults/GetPrescriptionsListPMSPaging';
  public static addNewPrescription = '/api/LabResults/AddNewMedication';
  public static updatePrescription = '/api/LabResults/EditMedicationDetails';
  public static deletePrescription = '/api/LabResults/RemoveUserEnteredMedication/';
  //#endregion

  //#region Allergies
  public static getAllergiesUE = '/api/LabResults/GetAllergiesListUEPaging';
  public static getAllergiesPMS = '/api/LabResults/GetAllergiesListPMSPaging';
  public static addNewAllergy = '/api/LabResults/AddNewAllerergy';
  public static updateNewAllergy = '/api/LabResults/EditMedicalWarningsDetails';
  public static deleteAllergyRecord = '/api/LabResults/DeleteMedicalWarning';
  //#endregion

  public static addNewLabRecords= '/api/LabResults/AddNewLabRecords';
  public static getComboAllergyTypes = '/api/ProviderLabReports/GetComboAllergyTypes';
  //#region Immunisations

  public static getImmunisationsUE = '/api/LabResults/GetImmunisationsListUEPaging';
  public static getImmunisationsPMS = '/api/LabResults/GetImmunisationsListPMSPaging';
  public static addNewImmunisation = '/api/LabResults/AddNewImmunisation';
  public static updateImmunisation = '/api/LabResults/UpdateUserImmunisation';
  public static deleteImmunisation = '/api/LabResults/DeleteUserImmunisation';
  //#endregion

//#region Covid Immunisations
  public static getUECovidImmunisationListPaging = '/api/LabResults/GetUECovidImmunisationListPaging';
  public static getUECovidImmuListPaging = '/api/ProviderLabReports/GetUECovidImmuListPaging';
  public static addNewImmunisationCovid = '/api/LabResults/AddNewImmunisationCovid';
  public static getAllCovidVaccination = '/api/LabResults/GetAllCovidVaccinations';
  public static updateUserImmunisationCovid = '/api/LabResults/UpdateUserImmunisationCovid';
  public static deleteUserImmunisationCovid = '/api/LabResults/DeleteUserImmunisationCovid';
  public static getUEImmuCountCovid = '/api/LabResults/GetUEImmuCountCovid?userId=';
  public static GetUEImmunisationCountCovid = '/api/ProviderLabReports/GetUEImmunisationCountCovid?puserId=';



 //#endregion

  //#region Classifications
  public static getClassificationUE = '/api/LabResults/GetUEClassificationsListPaging';
  public static getClassificationPMS = '/api/LabResults/GetPMSClassificationsListPaging';
  public static addNewClassification = '/api/LabResults/AddNewClassification';
  public static updateClassification = '/api/LabResults/UpdateUserClassification';
  public static deleteClassification = '/api/LabResults/DeleteUserClassification/';

  //#endregion

  //#region Lab results
  public static getLabResults = '/api/LabResults/GetLabResultsListPaging';
  public static labResultsTabsWiseCount = '/api/LabResults/SetHealthSummaryPMSCount';
  public static updateLabResults = '/api/LabResults/UpdateUserLabResults';
  public static deleteLabResults = '/api/LabResults/RemoveUserEnteredLabresults';
  //#endregion

  //#region  Clinician Notes
  public static getClinicalNotesUE = '/api/LabResults/GetUEVisitsListPaging';
  public static getClinicalNotesPMS = '/api/LabResults/GetPMSVisitsListPaging';
  public static addNewClinicianNotes = '/api/LabResults/AddNewVisit';
  public static updateClinicianNotes = '/api/ClinicalNotes/UpdateClinicalNotes';
  public static deleteClinicianNotes = '/api/LabResults/DeleteUserVisit?VisitId=';
  public static addNewClinicalNotes = '/api/ClinicalNotes/AddClinicalNote';
  //#endregion

  //#region Recalls
  public static recallRemainders = '/api/LabResults/GetRecallReminderMessageByuser?userId=';
  public static recallsPMS = '/api/LabResults/GetPMSRecallsList';
  public static addNewRecalls = '/api/LabResults/AddNewRecalls';
   public static updateRecalls = '/api/LabResults/UpdateUserRecalls';
  public static deleteRecalls = '/api/LabResults/DeleteUserRecalls';

  //#endregion

  //#region Lab results UE Count Calls
  public static getPrescriptionsUECount = '/api/LabResults/GetUEPrescriptionCount?userId=';
  public static getAllergiesUECount = '/api/LabResults/GetUEAllergiesCount?userId=';
  public static getImmunisationsUECount = '/api/LabResults/GetUEImmunisationsCount?userId=';
  // public static getUEImmuCountCovid = '/api/LabResults/GetUEImmuCountCovid?UserId=';
  public static getClassificationsUECount = '/api/LabResults/GetUEClassificationsCount?userId=';
  public static getClinicianNotesUECount = '/api/LabResults/GetUEVisitsListCount/';

  //#endregion

  //#region RRP settings
  public static getRRPServiceByPractice = '/api/Practice/GetRRPServiceByPractice?practiceManagerID=';
  public static getServiceCostbyMessageID = '/api/Inbox/GetServiceCostbyMessageID?messageTypeID=';
  public static getRRPPaymentMode = '/api/Inbox/GetRRPPaymentMode?practicemanagerID=';
  public static updateMessageTypebyMultifee = '/api/Inbox/UpdateMessageTypebyMultifee';
  public static getSiteDetails = '/api/Practice/GetSiteDetails?practiceLocationID=';
  public static rRPSaveScriptSettings = '/api/RequestRepeatPrescription/RRPSaveScriptSettings';
  public static updatePracticeRRPFeeSettings = '/api/Appointment/UpdatePracticeRRPFeeSettings?PracticeId=';
  public static getRRPPracticeLocationDetails = '/api/RequestRepeatPrescription/GetRRPScriptFeeSettings?PracticeID=';
  public static getRRPEditScriptFeeSettings = '/api/RequestRepeatPrescription/GetRRPEditScriptFeeSettings?PracticeID=';
  public static saveRRPFeeSettings = '/api/RequestRepeatPrescription/SaveRRPFeeSettings';
  public static getRRPPractice = '/api/Inbox/GetRRPPractice?UserID=';
  public static getRRPLocations = '/api/Inbox/GetRRPLocations?PracticeManagerID=';
  public static getRRPServices = '/api/Inbox/GetRRPServices?PracticeManagerID=';
  public static getRRPServiceDetails = '/api/Inbox/GetRRPServiceDetails?MessageTypeID=';
  public static getClinicalUser = '/api/Inbox/GetClinicalUser?PracticeManagerID=';
  public static getPatientMedicationListForRRP = '/api/Inbox/GetPatientMedicationListForRRP?UserID=';
  public static getScriptPickupMethodbyMessageTypeID = '/api/Inbox/GetScriptPickupMethodbyMessageTypeID?MessageTypeID=';
  // public static getScriptUrgency = '/api/Inbox/GetScriptUrgencybyMessageTypeID?MessageTypeID=';
  public static getRRPStatus = '/api/Inbox/GetRRPStatus?UserID=';
  public static GetRRPStatusPagination = '/api/Inbox/GetRRPStatusPagination';
  public static getPharmacyCitiesByUserId = '/api/RequestRepeatPrescription/GetPharmacyCitiesByUserid?UserId=';
  public static getPharmacyCities = '/api/RequestRepeatPrescription/GetPharmacyCities';
  public static getPharmacySuburbTown = '/api/RequestRepeatPrescription/GetPharmacySuburbTown?cityID=';
  public static getPharmacyName = '/api/RequestRepeatPrescription/GetPharmacyName?cityID=';
  public static GetPharmacyByNameAddress ='/api/RequestRepeatPrescription/GetPharmacyByNameAddress?UserSearch=';
  public static getPharmacyDetails = '/api/RequestRepeatPrescription/GetPharmacyDetails?ID=';
  public static getFavouriteDeliveryPharmacyCitiesByUserid = '/api/RequestRepeatPrescription/GetFavouriteDeliveryPharmacyCitiesByUserid?userId=';
  public static addPharmacyFavouriteByUserId = '/api/RequestRepeatPrescription/AddPharmacyFavouriteByUserId';
  public static addDeliveryPharmacyFavouriteByUserId = '/api/RequestRepeatPrescription/AddDeliveryPharmacyFavouriteByUserId';
  public static addDeliveryAddressFavouriteByUserId = '/api/RequestRepeatPrescription/AddDeliveryAddressFavouriteByUserId';
  public static deliveryPharmacyCity = '/api/RequestRepeatPrescription/DeliveryPharmacyCity';
  public static getDeliveryPharmacyName = '/api/RequestRepeatPrescription/GetDeliveryPharmacyName?cityID=';
  public static getFavouriteDeliveryAddressbyUserId = '/api/RequestRepeatPrescription/GetFavouriteDeliveryAddressbyUserId?userId=';
  public static getScriptUrgency = '/api/RequestRepeatPrescription/GetScriptUrgency?messageTypeID=';
  public static getAllServiceCost = '/api/Inbox/GetAllServiceCost?MessageTypeID='
  public static getDeliveryPharmacyDetailsByUserid = '/api/RequestRepeatPrescription/GetDeliveryPharmacyDetailsByUserid?userId=';
  public static GetDeliveryPharmacyDetails = '/api/RequestRepeatPrescription/GetDeliveryPharmacyDetails?ID=';
  public static updatePharmacyContactDetails = '/api/Inbox/UpdatePharmacyContactDetails?UserID=';
  public static locationWiseCost='/api/Inbox/GetAllServiceCostLocationWise?MessageTypeID=';
  public static getRRPLoacationPaymentOption='/api/Inbox/getRRPLocationPaymentOption?MessageTypeID=';
  // Usage Reports
  public static getUsageReport1 = '/api/UsageReports/UsageReports';
  public static reportsUrl = '/api/UsageReports/GetPdfReport';
  public static excelUrl = '/api/UsageReports/GetExcelReport';
  public static getExcelOnlineAppointment = '/api/UsageReports/GetExcelReportOnlineAppointments';
  public static getStaff = '/api/Practice/GetStaff?PracticeManagerId=';
  public static OnlineAppointmentAndRepeatPrescriptionReportURL = '/api/UsageReports/UsageReportsOnlieAppointments';
  public static OnlineAppointmentPdfGenerationURL = '/api/UsageReports/GetPdfReportOnlineAppointments';
  public static getPatinetRegisterCount = '/api/UsageReports/GetURPatientRegisteredByCount';
  public static getPatientRegister = '/api/UsageReports/GetMMHPRandMMHDPReport';
  public static getPatinetRegisterExcelGeneration = '/api/UsageReports/GetExcelMMHPRandMMHDPReport';

  public static getPatinetRegisterPdfGeneration = '/api/UsageReports/GetPdfReportForMMHPRandMMHDP';
  public static getOnlineAppointmentStaff = '/api/UsageReports/GetOnlineAppointmentStaffs?PracticeManaerId=';
  public static getUsageReportByMMHLA = "/api/UsageReports/GetUsageReportByMMHLA";
  public static GetPatientRegisteredStaff = '/api/UsageReports/GetPatientRegisteredStaff?PracticeManaerId=';
  public static GetPatientDefaultStaff = '/api/UsageReports/GetDefaultProviderStaff?PracticeManaerId=';
  public static getPatientDefaultStaffCount = '/api/UsageReports/GetURPatientDefaultProviderCount';
  //#endregion


  //#region Health Indicators
  public static getAllScreening = '/api/HealthIndicator/GetAllScreeningNew?PatientuserID=';
  public static getActiveScreening = '/api/HealthIndicator/GetActiveScreening?PatientuserID=';
  public static getScreeningGroup = '/api/HealthIndicator/GetScreeningGroup?PatientuserID=';
  public static getScreeningMaxPeriod = '/api/HealthIndicator/GetScreeningMaxPeriod?screeningGroupID=';
  public static getScreeningDataForXML = '/api/HealthIndicator/GetScreeningDataForXML?userID=';
  public static getScreeningDataForJSON = '/api/HealthIndicator/GetScreeningDataForJSON?PatientuserID=';
  public static updatePatientGroupFavourite = '/api/HealthIndicator/UpdatePatientGroupFavourite?PatientuserID=';
  public static updateStatus = '/api/HealthIndicator/UpdateStatus?PatientuserID=';
  public static updateSharing = '/api/HealthIndicator/UpdateSharing?PatientuserID=';
  public static getAllReminderTypes = '/api/HealthIndicator/GetAllReminderTypes';
  public static updateReminderRequest = '/api/HealthIndicator/CreateUpdateReminderRequest';
  public static getScreeningData = '/api/HealthIndicator/GetScreeningDatabyPaging?PatientuserID=';
  public static getScreeningDataCount = '/api/HealthIndicator/GetScreeningDataCount?PatientuserID=';
  public static getComments = '/api/HealthIndicator/GetComments?sNOMEDCode=';
  public static getAlchoholTypes = '/api/HealthIndicator/GetAlchoholTypes';
  public static saveIndicatorValue = '/api/HealthIndicator/SaveIndicatorValue';
  public static deleteIndicatorData = '/api/HealthIndicator/DeleteData?iD=';
  public static updateIndicatorValue = '/api/HealthIndicator/UpdateScreeningData';
  public static getAllScreeningByProvider = '/api/HealthIndicator/GetAllScreeningByProvider?PatientuserID=';


  //#endregion


  public static getIndicatorName = '/api/Goals/GetIndicatorName?inputString=';
  public static updateGoalStatus = '/api/Goals/UpdateGoalStatus?userId=';
  public static updateGoalSharing = '/api/Goals/UpdateGoalSharingbyuserid';
  public static deleteGoal = '/api/Goals/DeleteGoal?userId=';

  //#region reminder settings
  public static createNewReminder = '/api/Goals/CreateNewReminder';
  public static updateReminder = '/api/Goals/UpdateReminder';
  public static getFrequencyHour = '/api/Goals/GetFrequencyHour?reminderInfo=';
  public static getTimeToSendHour = '/api/Goals/GetTimetoSendHour?reminderInfo=';
  public static getTimeToSendReminder = '/api/Goals/GetTimeToSendReminder?timeToSendHour=';
  public static getreminderType = '/api/Goals/GetAllReminderTypes';
  public static updateGoalReminder = '/api/Goals/UpdateGoalReminder';
  public static getReminderId = '/api/Goals/GetReminderID?moduleTableId=';
  //#endregion

  //#region Goal Tracking
  public static isGoalNameExist = '/api/Goals/IsGoalExist?userId=';
  public static goalTypeList = '/api/Goals/GetAllGoalTypes';
  public static getunitTypes = '/api/Goals/GetAllUnitTypes';
  public static getDefaultUnits = '/api/Goals/GetAllDefaultUnits';
  public static getAllHealthIndicators = '/api/Goals/GetAllHealthIndicators';
  public static getAllGoalsByUser = '/api/Goals/GetAllGoalsByUser?userId=';
  public static getIndicatorIDByName = '/api/Goals/GetIndicatorsIDByName?indicatorName=';
  public static addNewHealthIndicators = '/api/Goals/AddNewHealthIndicator';
  public static addNewUserGoal = '/api/Goals/AddNewUserGoal';
  public static reminderSave = '/api/Goals/GetTaskupdateRecord';
  public static taskReminderSave = '/api/Goals/InsertTaskReminder';
  public static recordUpdateReminder = '/api/Goals/RecordUpdateReminder';
  public static addTask = '/api/Goals/InsertActivity';
  //#endregion
  //#region add New Value
  public static addIndicatorValue = '/api/Goals/AddIndicatorValue';
  public static addNewValue = '/api/Goals/AddCurrentValue';
  public static updateAutoTaskStatus = '/api/Goals/UpdateAutoTaskStatus?activityID=';
  public static getSharedGoalsByUser = '/api/Goals/GetSharedGoalsByUser?PuserId=';
  //#endregion

  //#region Add New Task
  public static getAllTasks = '/api/Goals/GetAllActivitiesByGoalandUser?PatientuserId=';
  public static addNewTask = '/api/Goals/AddNewActivity';
  public static isTaskExist = '/api/Goals/IsTaskExist?userID=';
  public static deleteActivityTask = '/api/Goals/DeleteActivity?userGoalActivityId=';
  public static getCalendar = '/api/Goals/GetCalendarUserId?userId=';
  public static deleteEventFromCalendarTask = '/api/Goals/DeleteEventFromCalender?subject=';
  public static deleteSchedulerEventFromCalender = '/api/Goals/DeleteSchedulerEventFromCalender?eventDate=';
  public static addEventToCalendarTask = '/api/Goals/AddEventToCalendar';

  //#region Goal Analysis (Graphs)
  public static selectedActivityValues = '/api/Goals/GetSelectedActivityValues?goalID=';
  public static getActivityDataBySepecifiedDate = '/api/Goals/GetActivityDataBySepecifiedDate?goalID=';
  public static getDateRange = '/api/Goals/GetDateRange?defaultReports=';
  public static getSelectedActivityValues = '/api/Goals/GetSelectedActivityValues?goalID=';
  public static updateCurrentGoalValuebyuserid = '/api/Goals/UpdateCurrentGoalValuebyuserid';
  public static deleteActivityRecordbyuserid = '/api/Goals/DeleteActivityRecordbyuserid?transactionID=';
  public static exportToExcel = '/api/Goals/GetSelectedActivityValuesExportToExcel?GoalID=';

  //#endregion

  //#region View Journal
  public static getJournalCategories = '/api/Journal/JournalCategories';
  public static getJournalEntries = '/api/Journal/GetJournalEntriesByUser/';
  public static addJournalEntry = '/api/Journal/AddJournalEntry';
  public static getEventDetailsforEdit = '/api/Journal/GetEventDetailsforEdit/';
  public static updateJournalEntry = '/api/Journal/UpdateJournalEntry';
  public static deleteJournalEvent = '/api/Journal/DeleteJournalEvent/';
  public static getJournalEntriesCount = '/api/Journal/GetJournalEntriesByUserCount/';
  //#endregion

  // #region Notice Board
  public static getNoticeboardEntries = '/api/Practice/GetContentPagingForUser?sortExpression=""';
  public static getNoticeBoardEntriesCount = '/api/Practice/GetAllContentForUser?userId=';
  public static checkExistingDaaBasedOnContentID = '/api/Practice/GetRecordsFromPracticeContent?contentheaderid=';
  public static saveContentType = '/api/Practice/SaveContentType?contentType=';
  public static saveContentHeader = '/api/Practice/SaveContentHeader?contentTypeId=';
  public static getContentType = '/api/Practice/GetPracticeContentTypeRecords?practiceId=';
  public static getContentHeaderByContentID = '/api/Practice/GetRecordsByContentTypeId?contentTypeId=';
  public static updateNoticeBoard = '/api/Practice/UpdatePracticeContentByContentHeaderId';
  public static insertNoticeBoard = '/api/Practice/InsertPracticeContentRecord';
  public static getContentRecordByContentId = '/api/Practice/GetRecordsOnSelected?contentId=';
  public static deletePracticeContent = '/api/Practice/DeletePracticeContent?contentId=';
  public static loadPatientNoticeBoard = '/api/MyHealthCentre/GetPracticeNoticeBoardDetails?practiceId=';
  public static updatePracticeContentRecord = '/api/Practice/UpdatePracticeContentRecord?contentId=';
  //#endreion

  //#region MyHealth-Insurance
  public static GetInsuranceDetails = "/api/Insurance/GetInsuranceDetails";
  public static saveInsuranceDetails = '/api/Insurance/SaveInsuranceDetails';
  public static updateInsuranceDetails = '/api/Insurance/UpdateInsuranceDetails';
  public static saveAndGetInsuranceDetails = '/api/Insurance/SaveAndGetInsuranceDetails';
  public static deleteInsuranceDetails = '/api/Insurance/DeleteInsuranceDetails';
  public static deleteAndGetInsuranceDetails = '/api/Insurance/DeleteAndGetInsuranceDetails';
  public static insuranceUploadFile = '/api/Insurance/UploadFile';
  public static insuranceGetFile = '/api/Insurance/GetFile?filename=';
  public static SendEmail = "/api/Insurance/SendEmail"

  //#endregion

  //#region medic alert
  public static medicAlertAccountActivation = '/api/Medicalert/MMHAccountAcitvation';
  public static medicAlertNumberId = '/api/Medicalert/GetMedicAlertMemberID?UserId=';
  public static getAllergiesByPatient = '/api/Medicalert/GetAllergiesByPatient?MemberID=';
  public static getMedicalConditionsByPatient = '/api/Medicalert/GetMedicalConditionsByPatient?MemberID=';
  public static getMedicationsByPatient = '/api/Medicalert/GetMedicationsByPatient?MemberID=';
  public static getTransactionsByMember = '/api/Medicalert/GetTransactionsByMember?MemberID=';
  public static getEmergencyContactsByMember = '/api/Medicalert/GetEmergencyContactsByMember?MemberID=';
  public static getDoctorContactsByMember = '/api/Medicalert/GetDoctorContactsByMember?MemberID=';
  public static getIncidentReportByMember = '/api/Medicalert/GetIncidentReportByMember?MemberID=';
  public static getMedicAlertMemberInfo = '/api/Medicalert/GetMedicAlertMemberInfo?PUserId=';
  public static getUserProfileMedicAlertMemberInfo = '/api/UserProfile/GetMedicAlertMemberInfo?pUserId=';
  public static getAdvanceDirectiveDocuments = '/api/Medicalert/GetAdvanceDirectiveDocuments?memberID=';
  public static getADVorEPOAFileDownload = '/api/Medicalert/ADVorEPOAFileDownload?fileName=';
  public static getMedicAlertPDFDownload = '/api/Medicalert/MedicAlertPDFDownload?UserId=';
  //#endregion

  //#region Health Documents
  public static getHealthDocumentList = '/api/HealthDocuments/GetHealthDocumentsList';
  public static saveAttachDocument = '/api/HealthDocuments/SaveAttachedDocumentDetails';
  // public static editAttachDocument = '/api/HealthDocuments/GetHealthDocumentDetail?DocuemtntId=';
  public static editAttachDocument = '/api/HealthDocuments/GetHealthDocumentDetail/';
  public static getHealthDocumentsCount = '/api/HealthDocuments/GetHealthDocumentsListCount/';
  //#endregion

  //#region Health Documents
  public static getSelectActivityType = '/api/Inbox/GetServiceTypebyprtcIdandlocidandsttus?PracticeAdminId=';
  public static getTransactions = '/api/Inbox/GetTransactions';
  public static getUsageQueryExcelUrl = '/api/UsageQuery/ExportUsageQueryPaymentsToExcel';
  public static getHealthAssesmentExcelUrl = '/api/HealthAssesment/ExportUsageQueryPaymentsToExcel';
  //#endregion

  //#getstafflist
  public static staffList = '/api/StaffList/GetStaffList?PracticeManagerID=';
  public static getStaffListHealthCenters = '/api/Practice/GetPraciceByUserPracticeAdmin?userId=';
  public static exportStaffListPdf = '/api/StaffList/GetStaffListPdf/'
  public static GetSPCCodeByUserId = '/api/StaffList/GetStaffCodeByUserId?UserId='
  //#endregion

  //#region Advance Care Plan
  public static getBasicACPDetails = '/api/AHCare/GetACPPatientDetails?userId=';
  public static getHealthCenter = '/api/AHCare/GetPatientACPHealthCenters?PatientUserID=';
  public static getSaveAuditAdvanceCare = '/api/AHCare/SaveAndAuditAdvanceCarePlan';
  public static getAdvanceData = '/api/AHCare/GetUserStatusTypeAndDetails'
  public static getPatientSignature = '/api/AHCare/GetPatientSignature?patientguid=';
  public static getInactivePatientSignature = '/api/AHCare/InactivePatientSignature?PatientGuid=';
  public static updateStageStatus = '/api/AHCare/UpdateStageCompletionStatus?ACPID='
  public static getAcpAudit = '/api/AHCare/GetACPAudit?ACPId=';
  public static getViewAuditPDF = '/api/AHCare/ViewAuditPDF';
  public static getPrintACPPDF = '/api/AHCare/PrintACPPDF';
  public static getExistRole = '/api/Account/IsUserExistsInRole?userEmail='
  public static UpdateACPAliveStatus = '/api/AHCare/UpdateACPAliveStatus?loggedInUserGuidId=';

  //#endregion

  // Send a message Serach Patients
  public static getCommonPractice = '/api/Inbox/GetCommonPractice?UserId=';

  //#region beatingTheBlues
  public static getValidForMigration = '/api/BeatingtheBlues/GetValidForMigration?userId=';
  public static migrateUserToV2 = '/api/BeatingtheBlues/MigrateUserToV2?userId=';
  public static getBTBURL = '/api/BeatingtheBlues/GetBTBURL?patientuserID=';
  public static updateStaffSettings = "/api/BeatingtheBlues/UpdateStaffSettings";
  public static patientSearchResults = "api/BeatingtheBlues/GetB2BLastAccessedPatientSearchResults";
  public static getStaffSetting = "/api/BeatingtheBlues/GetStaffSettings?StaffUserID=";
  public static getPatientData = '/api/BeatingtheBlues/GetPatientData?userId=';



  //#region Provider Lab Results
  //#region Prescriptions
  public static getProviderPrescriptions = '/api/ProviderLabReports/GetPrescriptionsListPaging';
  public static getProviderPrescriptionsCount = '/api/ProviderLabReports/GetPrescriptionsListCount?PuserId=';
  //#endregion
  //#region Allergies
  public static getProviderAllergies = '/api/ProviderLabReports/GetAllergiesListPaging';
  public static getProviderAllergiesCount = '/api/ProviderLabReports/GetAllergiesListCount?PuserId=';
  //#endregion
  //#region Immunisations
  public static getProviderImmunisations = '/api/ProviderLabReports/GetImmunisationsListPaging';
  public static getProviderImmunisationsCount = '/api/ProviderLabReports/GetImmunisationsListCount?Puserid=';
  //#endregion
  //#region Classifications
  public static getProviderClassifications = '/api/ProviderLabReports/GetDiagnosisListPaging';
  public static getProviderClassificationsCount = '/api/ProviderLabReports/GetDiagnosisListCount?PuserId=';
  //#endregion
  //#region Lab Results
  public static getProviderLabResults = '/api/ProviderLabReports/GetLabResultsListPaging';
  public static getProviderLabResultsCount = '/api/ProviderLabReports/GetLabResultsListCount?PuserId=';
  //#endregion
  //#region Clinician Notes
  public static getProviderUEVisists = '/api/ProviderLabReports/GetUEVisitsListPaging';
  public static getClinicalNotes = '/api/ClinicalNotes/GetAllClinicalNotes';
  public static getAllClinicalNotesCount = '/api/ClinicalNotes/GetAllClinicalNotesCount?PuserId=';
  public static getProviderPMSVisists = '/api/ProviderLabReports/GetPMSVisitsListPaging';
  public static getProviderUEVisistsCount = '/api/ProviderLabReports/GetUEVisitsListCount?PuserId=';
  public static getProviderPMSVisistsCount = '/api/ProviderLabReports/GetPMSVisitsListCount?PuserId=';
  public static updateClinicalNotes = '/api/ClinicalNotes/UpdateClinicalNotes?clinicalnoteId=';
  //#endregion

  //#region Clinician Notes
  public static getProviderRecallsList = '/api/ProviderLabReports/GetRecallsListPaging';
  public static getProviderRecallsListCount = '/api/ProviderLabReports/GetRecallsListCount?PuserId=';
  //#endregion

  public static getProviderAllPMSCount = '/api/ProviderLabReports/PatientPMSEntriesCount?profileID=';
  //#endregion

  //#region myHealthCenter
  public static getMyHealthCenterInfoDetails = '/api/PracticeDirectory/GetPracticeDetails?practiceId=';
  public static getMyHealthCenterDocument = '/api/PracticeDirectory/DocumentDownload';
  public static getMyHealthCenters = '/api/MyHealthCentre/GetMyHealthCentersbyUid';
  public static getMyHealthCentersfees = '/api/PracticeDirectory/GetFeesStruct';
  //#endregion

  //#region Link To Health centres
  // public static getLinkToHealthPracticeLocations = '/api/LinkToHealthCentres/GetPracticeLocations?locationName=';
  // public static getLinkToHealthStaffLocations = '/api/LinkToHealthCentres/GetStaffLocations?locationName=';

  public static getLinkToHealthPracticeLocations = '/api/LinkToHealthCentres/GetPracticeLocations';
  public static getLinkToHealthStaffLocations = '/api/LinkToHealthCentres/GetStaffLocations?locationName=';

  public static addPractice = '/api/LinkToHealthCentres/AddPractice?userID=';
  public static getPracticeID = '/api/LinkToHealthCentres/GetPracticeID?practiceManagerID='
  //#endregion

  //#region Dashboard
  public static getDashboardFutureAppointments = '/api/Appointment/GetFutureAppointmentDashBoardStatus?patientId=';
  //public static getDashboardUpcomingMessages = '/api/Inbox/GetAllUnreadDashBoardMessage?UserId=';
  public static getDashboardUpcomingMessages = '/api/Inbox/GetDBSNewMessage';
  public static getDashboardHealthCentres = '/api/Practice/GetAllDashBoardRegisteredHealthCentersByUserId';
  public static getDashboardRRP = '/api/Inbox/GetRRPDashBoardStatus?UserID=';
  //public static getDashboardHealthIndicators ='/api/Goals/GetAllLastRecordedDashBoardHealthIndicator?userId=';
  public static getDashboardHealthIndicators = '/api/HealthIndicator/GetDashBoardHealthIndicators?userID=';
  public static getDashboardHealthRecords = '/api/LabResults/GetDashboardPatientHealthSummary?userId=';
  public static getAccessedWidgets = '/api/Account/GetAccessedWidgets';
  public static isLocalityCareCordinator = '/api/HelpDesk/IsLocalityCareCordinator?StaffID=';
  public static isFacilityUser = '/api/HelpDesk/IsFacilityUser?StaffID=';
  public static getDashboardServices = '/api/Account/GetPatientDashboardServices';
  //#endregion

  //#region encrypt
  public static getDecrypt = '/api/Account/EncryptandDecrypt?userInput=';
  //End Encrypt

  // #region Support Group
  public static getSupportGroupDetails='/api/SupportGroups/GetOrganisationForPatient';
  //End Support Group

  //Search Gp
  public static clinicSearch = '/api/PracticeDirectory/ClinicSearch';
  public static clinicSearchCount = '/api/PracticeDirectory/ClinicSearchCount';
  public static practiceSearch = '/api/PracticeDirectory/PracticeSearch';
  public static practiceSearchCount = '/api/PracticeDirectory/PracticeSearchCount';
  public static staffSearch = '/api/PracticeDirectory/StaffSearch';
  public static staffSearchCount = '/api/PracticeDirectory/StaffSearchCount';
  public static getAdvancedSearch = '/api/PracticeDirectory/GetAdvancedSearch';

  //Medic Alert Provider Side
  public static pMARelationships = '/api/ProviderMedicalert/Relationships';
  public static getMedicAlertMemberID = '/api/ProviderMedicalert/GetMedicAlertMemberID?UserId=';
  public static addAuditReason = '/api/ProviderMedicalert/AddAuditReason?auditReason=';
  public static getAllAuditDetails = '/api/ProviderMedicalert/GetAllAuditDetails';
  public static onSuggestOptions = '/api/ProviderMedicalert/OnSuggestOptions?sText=';
  public static addIncidentNote = '/api/ProviderMedicalert/AddIncidentNote?incidentNote=';
  public static getNewMemberEnroledDetails = '/api/ProviderMedicalert/GetNewMemberEnroledDetails';
  public static updateMAMembershipID = '/api/ProviderMedicalert/UpdateMAMembershipID';
  public static getAllMAReferralEnrolments = '/api/ProviderMedicalert/GetAllMAReferralEnrolments';
  public static downloadReferral = '/api/ProviderMedicalert/DownloadReferral?EnrolmentID=';
  public static GetMAEnrolementDetailsByColumn = '/api/ProviderMedicalert/GetMAEnrolementDetailsByColumn';
  public static getNewMedicAlertEnrolments = '/api/ProviderMedicalert/GetNewMedicAlertEnrolments';
  public static updateEmailCheck = '/api/ProviderMedicalert/UpdateEmailCheck';
  public static updateEmailUptUserNames = '/api/ProviderMedicalert/UpdateEmailUptUserNames';
  public static pMAGetAllCitiesFromNewZealand = '/api/ProviderMedicalert/GetAllCitiesFromNewZealand';
  public static getSuburbs_byCity = '/api/ProviderMedicalert/GetSuburbs_byCity';
  public static patientBannerDisplay = '/api/ProviderMedicalert/PatientBannerDisplay';
  public static exportSearch = '/api/ProviderMedicalert/ExportSearch?FromDate=';
  public static exportToCSV = '/api/ProviderMedicalert/ExportToCSV';
  public static activateAccount = '/api/Account/ActivateAccount';
  public static practiceTermsAndConditions = '/api/Account/GetPracticeTerms';
  public static accountActivationConfirmation = '/api/Account/AccountActivationConfirmation';
  public static confirmActivation = '/api/Account/ActivationConfirmation';
  public static accountActivationConfirmationDetails = '/api/Account/AccountActivationConfirmation';
  public static forgotActivationCode = '/api/Account/ForgotActivationCode?email=';
  public static activationContact = '/api/Account/ActivationSupportContactUs';

  public static getRegisterMember = '/api/ProviderMedicalert/GetRegisterMember';
  public static searchMember = '/api/ProviderMedicalert/SearchMember?Searchtype=';

  public static getMedicalRecordsByPatientID = '/api/ProviderMedicalert/GetMedicalRecordsByPatient?memberID=';
  public static advanceSearchMember = '/api/ProviderMedicalert/GetMedicAlertSearchResults';
  public static getProvMedicAlertMemberID = '/api/ProviderMedicalert/GetMedicAlertMemberID?UserId=';
  //B2B Trainer
  public static dhbList = '/api/BeatingtheBlues/GetDHBList';
  public static phoList = '/api/BeatingtheBlues/GetLookupPHOByDHB?DHBID=';
  public static practiceList = '/api/BeatingtheBlues/GetPracticeByPho?PHOID=';
  public static addPracticeProviders = '/api/BeatingtheBlues/AddProviderToPractice';
  public static getPracticeByLicencedModule = '/api/BeatingtheBlues/GetPracticeByLicencedModule?licencedModulesCode=';
  public static getStaffForBtbActivate = '/api/BeatingtheBlues/GetStaffForBtbActivate?practiceManagerID=';
  public static activateSelectedProviders = '/api/BeatingtheBlues/ActivateSelectedProviders';
  public static providerList = '/api/Practice/GetProviders?practiceManagerId=';
  public static getOrgDetails = '/api/Practice/GetOrgDetails?practiceManagerID=';
  public static createPractice = '/api/BeatingtheBlues/CreatePractice';
  public static getOrganisationType = '/api/BeatingtheBlues/GetOrganisationType';
  public static resendBTBInvitation = '/api/BeatingtheBlues/ResendBTBInvitation';
  public static trainerResendBTBInvitation = '/api/BeatingtheBlues/TrainerMenuResendBTBInvitation'
  //Linked Accounts
  public static getLinkedAccountAuditCount = '/api/LinkedAccounts/GetLinkedAccountAuditCount';
  public static getLinkedAccountAuditPaging = '/api/LinkedAccounts/GetLinkedAccountAuditPaging';
  public static getLinkedAccountListByLoggedInUser = '/api/LinkedAccounts/GetLinkedAccountListByLoggedInUser';
  public static updateAccessConfirmation = '/api/LinkedAccounts/UpdateAccessConfirmation';
  public static updateUnlink = '/api/LinkedAccounts/UpdateUnlink';
  public static updateSeparateAccount = '/api/LinkedAccounts/UpdateSeparateAccount';
  public static changeEmailAddressLinkedAccount = '/api/LinkedAccounts/ChangeEmailAddress';
  public static updateARNotificationStatus = '/api/LinkedAccounts/UpdateARNotificationStatus';
  public static getLinkedParentAccountListByLoggedInUser = '/api/LinkedAccounts/GetLinkedParentAccountListByLoggedInUser?LoggedInEmail=';
  public static contactUS = '/api/Account/ContactUs';
  public static getPracticeLASettingsByPracticeID = '/api/LinkedAccounts/GetPracticeLASettingsByPracticeID?PracticeManagerId=';
  public static updatePracticeLASettings = '/api/LinkedAccounts/UpdatePracticeLASettings';
  public static childLinkedAccountSignIn = '/api/LinkedAccounts/ChildLinkedAccountSignIn';
  public static parentLinkedAccountSignIn = '/api/LinkedAccounts/ParentLinkedAccountSignIn?PatientEmail=';
  public static viewChildDetailsAudit = '/api/LinkedAccounts/ViewChildDetailsAudit';
  public static lnkViewParentDetailsAudit = '/api/LinkedAccounts/LnkViewParentDetailsAudit';
  public static activationConfirmationLnkValidate = '/api/Account/ActivationConfirmationLnkValidate?Validate=';
  public static getLinkedParentAccountListForRemoveByLoggedInUser = '/api/LinkedAccounts/GetLinkedParentAccountListForRemoveByLoggedInUser?LoggedInEmail=';
  public static saveLinkedAccountConfirmation = '/api/Account/SaveLinkedAccountConfirmation';
  //#region SEHR

  // search
  public static getAHGroupByUser = '/api/SEHR/GetAHGroupByUser?staffid=';
  public static getAHGroupPracticeByUser = '/api/SEHR/GetAHGroupPracticeByUser?UserId=';
  public static getSEHRPatientList = '/api/SEHR/GetPatientList?suggestedText=';
  public static getSEHRSearchResults = '/api/SEHR/GetSEHRSearchResults';
  public static getSEHRSearchRsultCount = '/api/SEHR/GetSEHRSearchResultsCount';
  public static getAHCareLastAccessed = '/api/SEHR/GetAHCareLastAccessed?Clinical_User=';
  public static getOptOutSehrPatient = '/api/SEHR/OptOutSEHRPatient?patientId=';
  // summary
  public static addSharedNotes = '/api/SEHR/AddSharedNotes';
  public static getPatientAllHESCount = '/api/SEHR/GetPatientAllHESCountByProfileIDORUserID?StaffId=';

  // Manage SEHR Group
  public static getManageSEHRGetGroupName = '/api/SEHR/ManageSEHRGetGroupName?UserId=';
  public static getManageSEHRGroupPracticeList = '/api/SEHR/ManageSEHRGroupPracticeList?AfterHoursGroupId=';
  public static getManageSEHRPracticeGrpbannerinfo = '/api/SEHR/GetManageSEHRPracticeGrpbannerinfo?PracticeManagerId=';
  public static manageSEHRPracticeGrpCaresettingupdate = '/api/SEHR/ManageSEHRPracticeGrpCaresettingupdate';
  public static manageSEHRPracticeGrpenableanddisable ='/api/SEHR/ManageSEHRPracticeGrpenableanddisable';
  public static getManageSEHRPracticeGrpbannercaresetting ='/api/SEHR/GetManageSEHRPracticeGrpbannercaresetting?AfterHourGroupId=';

  // Manage SEHR Group Settings
  public static getSEHRGroupSettings = '/api/SEHR/GetSEHRGroupSettings?AfterHourGroupId=';
  public static updateSEHRGroupSettings = '/api/SEHR/UpdateSEHRGroupSettingsHelathInfos';
  public static IsGroupHESFilterSettingEnabled = '/api/SEHR/IsGroupHESFilterSettingEnabled?groupId=';
  public static GetSEHRHESOptionsByCareSetting = '/api/SEHR/GetSEHRHESOptionsByCareSetting?CareSettingID=';
  public static getSEHRConsentSetting = '/api/SEHR/SEHRApplyConsentSetting?GroupId=';
  public static getConsentSEHRPatient = '/api/SEHR/ConsentSEHRPatient';

  // Manage SEHR StaffList
  public static createNewStaffAccount = '/api/SEHR/CreateNewStaffAccount';
  public static SEHRStaffRegistration='/api/Account/SEHRStaffRegistration';


  // share
  public static getShareRequestedDetails = '/api/SEHR/GetShareRequestedDetails?StatusCode=ALL';
  public static getDisplayMoreInfoDetails = '/api/SharedCare/DisplayMoreInfoDetails?RAUniqueID=';
  public static getCheckRequestAccessPermission = '/api/SharedCare/CheckRequestAccessPermission';

  // Document Library
  public static getPrescriptionsList = '/api/SEHR/GetPrescriptionsList';
  public static getImmunisationsList = '/api/SEHR/GetImmunisationsList';
  public static getLabResultsList = '/api/SEHR/GetLabResultsList';
  public static getDiagnosisList = '/api/SEHR/GetDiagnosisList';
  public static getAllergiesList = '/api/SEHR/GetAllergiesList';
  public static getGPNotesList = '/api/SEHR/GetPMSVisitsList';
  public static getSharedNotesList = '/api/SEHR/GetSharedNotesList';
  public static getRecallsList = '/api/SEHR/GetRecallsList';
  public static getReferralList = '/api/SEHR/GetReferralList';


  //#endregion

  // Document Library
  public static getDocumentpracticeList = '/api/DocumentLibrary/GetPractice?UserID=';
  // public static getDocumentCategory = '/api/DocumentLibrary/GetDocCategory?PracticeManagerID=';
  public static getSearchDocuments = '/api/DocumentLibrary/SearchDocs?PracticeManagerID=';
  public static updateDocDetails = '/api/DocumentLibrary/UpdateDocDetails';
  public static updateDocCategoryStatus = '/api/DocumentLibrary/UpdateDocCategoryStatus?DocCategoryID=';
  public static addDocumentCategory = '/api/DocumentLibrary/InsertDocCategory';
  public static downloadDocFile = '/api/DocumentLibrary/DownloadFile?filename=';
  public static getDocuments = '/api/DocumentLibrary/GetDocuments';
  public static addDocument = '/api/DocumentLibrary/InsertDocuments';
  public static insertNewVersion = '/api/DocumentLibrary/InsertNewVersion';
  public static getCategories = '/api/DocumentLibrary/GetDocCategory?PracticeManagerID=';
  public static getDocumentLibrarySearchCount = '/api/DocumentLibrary/DocumentLibSearchCount';
  public static getDocumentLibraryPageSearch = '/api/DocumentLibrary/DocumentLibPagingSearch';
  public static getDocumentLibraryCategoryCount = '/api/DocumentLibrary/DocumentCategorySearchCount?PracticeManagerID=';
  public static getDocumentLibraryCategoryPaging = '/api/DocumentLibrary/DocumentCategoryPagingSearch'
  //#endregion

  public static getFunder = '/api/Inbox/GetFunder?PracticeManagerID=';
  public static InsertFunder = '/api/Inbox/InsertFunder';
  public static UpdateFunder = '/api/Inbox/UpdateFunder';

  // register-staff
  public static registerPatient = '/api/Account/RegisterPatient';
  public static registerPatientSMSConfirmation = '/api/Account/SMSConfirmation';
  //#endregion

  //Technical Support
  public static contactUs = '/api/Account/Contacts';
  public static contact = '/api/Account/ContactUs?email=';
  //#endregion

  // Active care plan
  public static allUserCarePlans = '/api/ActivecarePlan/GetAllUserCarePlans';
  public static getUserDetails = '/api/ActivecarePlan/GetUserDetails?patientuserId=';
  public static carePlanDetails = '/api/ActivecarePlan/GetCarePlanDetails';
  public static carePlanGoalsByUser = '/api/ActivecarePlan/GetCarePlanGoalsByUser';
  public static getTaskByUserCarePlan = '/api/ActivecarePlan/GetTasksByUserCarePlan';
  public static getTaskByUserCarePlanCount = '/api/ActivecarePlan/GetTasksByUserCarePlanCount?CarePlanID=';
  public static getTeamList = '/api/ActivecarePlan/GetTeamList?UserCarePlanID=';
  public static getPrescriptionsListCount = '/api/ActivecarePlan/GetPrescriptionsListCount';
  public static getAllergiesListCount = '/api/ActivecarePlan/GetAllergiesListCount';
  public static getImmunisationsListCount = '/api/ActivecarePlan/GetImmunisationsListCount';
  public static getDiagnosisListCount = '/api/ActivecarePlan/GetDiagnosisListCount';
  public static getLabResultsListCount = '/api/ActivecarePlan/GetLabResultsListCount';
  public static prescriptionsList = '/api/ActivecarePlan/GetPrescriptionsList';
  public static allergiesList = '/api/ActivecarePlan/GetAllergiesList';
  public static immunisationsList = '/api/ActivecarePlan/GetImmunisationsList';
  public static diagnosisList = '/api/ActivecarePlan/GetDiagnosisList';
  public static LabResultsList = '/api/ActivecarePlan/GetLabResultsList';
  public static getResourcesByCareplan = '/api/ActivecarePlan/GetResourcesByCareplan?CarePlanID=';
  public static sharednotesByUserPaging = '/api/ActivecarePlan/GetRASharednotesByUserPaging';
  public static addProgressNotes = '/api/ActivecarePlan/AddSharedNotes';
  public static getRequestCareTeamList = '/api/SharedCare/GetRequestCareTeamList?PatientUserId=';
  public static getTaskAssignTocarePlan = '/api/SharedCare/TaskReAssign';
  public static getAllListOfTaskNotes = '/api/SharedCare/GetTaskNotes?TaskId=';
  public static getTaskReminder = '/api/SharedCare/AddNewTaskReminder';
  public static getRecentActivityUsers = '/api/ActivecarePlan/GetCarePlanActivityUsers?careplanid=';
  public static getRecentActivityByUserPaging = '/api/ActivecarePlan/GetCarePlanRecentActivityByUserPaging';
  public static getCarePlanRecentActivityCount = "/api/ActivecarePlan/GetCarePlanRecentActivityCount";
  public static getCarePlanCoordinatorList = '/api/SharedCare/GetCarePlanCoordinatorList?ID=';
  public static getCarePlanPdfType = '/api/SharedCare/GetPDFFromHandler?sID=';
  public static getPatientShowSummary = '/api/ActivecarePlan/GetPatientShowSummaryPdf?PatientId=';
  public static getRASharednotesByUserCount = '/api/ActivecarePlan/GetRASharednotesByUserCount'
  public static getHIDataforCarePlan = '/api/ActivecarePlan/GetHIDataForCareplan/';
  public static activeScreening = '/api/ActivecarePlan/GetActiveScreening';
  public static getActiveScreeningForCareplan = '/api/ActivecarePlan/GetActiveScreeningForCareplan?careplanid=';
  public static activeScreeningDataForChart = '/api/ActivecarePlan/ActiveScreeningDataForChart?indicatorids=';
  public static getGoalResourceByGoalID = '/api/SharedCare/GetGoalResourceByGoalID?Goalid=';
  //#endregion

  //#easybooking
  public static easyBookingSearch = '/api/EasyBooking/HealthProviders?searchTerm=';
  public static gethealthCenterStaffs = '/api/EasyBooking/Index';
  public static getEasyBookingServices = '/api/EasyBooking/Services?id=';
  public static getEasyBookingStaffDetails = '/api/EasyBooking/GetStaffDetails?StaffId='
  public static checkUserInPMS = '/api/EasyBooking/IsUserValid';
  public static bookApptForExistingUser = '/api/EasyBooking/BookApptForExistingUser';
  public static bookApptForNewUser = '/api/EasyBooking/BookApptForNewUser';
  public static getPaymentInfo = '/api/EasyBooking/GetOnlinePaymentSettingInfo';
  public static checkUserEmailForApptBooking = '/api/EasyBooking/CheckUserEmailForApptBooking';
  public static getAppointmentSettingsEB = '/api/EasyBooking/GetPracticeSettings?practiceID=';
  public static getPreScreeningAlertEB = '/api/EasyBooking/GetPreScreeningAlertByPracticeEasybooking?practiceId=';
  public static getPracticeProvidersEB = '/api/EasyBooking/GetLocationStaff?locCode=';
  public static getAvailableAppointmentDatesEB = '/api/EasyBooking/GetSelectedProviderSlots?spc=';
  public static getAvailableSlotsByDateAndConsultTypeFilterEB = '/api/EasyBooking/GetSelectedDateSlotsDtbyfilterconsulttype?selectedDate=';
  public static getPracticeVCSettingsEB = '/api/EasyBooking/GetPracticeVCSettings?practiceID=';
  public static bookApptForExistingUserCd = '/api/EasyBooking/BookApptForExistingUserCd';
  public static bookApptForNewUserCd = '/api/EasyBooking/BookApptForNewUserCD';
  public static easybookingDownloadDocument = '/api/EasyBooking/DocumentDownload';
  public static easybookingConfirmation = '/api/Account/Confirmation?Validate=';
  public static easybookingPaymentResponse = '/api/EasyBooking/AppointmentPaymentResponse?Op=';
  //#endregion

  //patient usage reports
  public static getReportRights = '/api/PatientPortalReports/GetUserReports';
  public static nationalSummaryByGender = '/api/NationalUsageSummaryPHO/NationalSummaryByGender';
  public static getNationalUsageSummary = '/api/NationalUsageSummaryPHO/GetNationalUsageSummary';
  public static getMMHNationalCount = '/api/MMHNationalReportByPHO/GetMMHNationalCount?ReportId=';
  public static getMMHNationalCountByGender = '/api/MMHNationalReportByPHO/GetMMHNationalCountByGender?ReportId=';
  public static getMMHNationalCountByAgeGroup = '/api/MMHNationalReportByPHO/GetMMHNationalCountByAgeGroup?ReportId=';
  public static getMMHDHBCountByPHO = '/api/MMHNationalReportByPHO/GetMMHDHBCountByPHO?ReportId=';

  public static getMMHNationalPracticeCount = '/api/MMHNationalReportByPractice/GetMMHNationalCount?ReportId=';
  public static getMMHNationalPracticeCountByGender = '/api/MMHNationalReportByPHO/GetMMHNationalCountByGender?ReportId=';
  public static getMMHNationalPracticeCountByAgeGroup = '/api/MMHNationalReportByPractice/GetMMHNationalCountByAgeGroup?ReportId=';
  public static getMMHNationalCountByPractice = '/api/MMHNationalReportByPractice/GetMMHNationalCountByPractice?ReportId=';

  public static getMMHDHBCount = '/api/MidCentralDHBReportByPHO/GetMMHDHBCount';
  public static getMMHDHBCountByGender = '/api/MidCentralDHBReportByPHO/GetMMHDHBCountByGender';
  public static getMMHDHBCountByAgeGroup = '/api/MidCentralDHBReportByPHO/GetMMHDHBCountByAgeGroup';
  public static getMidCentralMHDHBCountByPHO = '/api/MidCentralDHBReportByPHO/GetMMHDHBCountByPHO';

  public static midCentralDHBReportByPractice = '/api/MidCentralDHBReportByPractice/GetMMHDHBCount';
  public static getMidCentralDHBReportByGender = '/api/MidCentralDHBReportByPractice/GetMMHDHBCountByGender';
  public static getMidCentralDHBReportByAgeGroup = '/api/MidCentralDHBReportByPractice/GetMMHDHBCountByAgeGroup';
  public static getMidCentralDBHReportByPractice = '/api/MidCentralDHBReportByPractice/MidCentralDBHReportByPractice';

  public static getNationalCount = '/api/MMHNationalReportByDHB/GetMMHNationalCount?ReportId=';
  public static getNationalCountByGender = '/api/MMHNationalReportByDHB/GetMMHNationalCountByGender?ReportId=';
  public static getNationalCountByAgeGroup = '/api/MMHNationalReportByDHB/GetMMHNationalCountByAgeGroup?ReportId=';
  public static getNationalReportByDHBEthnicity = '/api/MMHNationalReportByDHB/GetMMHNationalReportByDHBEthnicity?ReportId=';
  public static getNationalCountByDHB = '/api/MMHNationalReportByDHB/GetMMHNationalCountByDHB?ReportId=';

  public static getMMHPHOCount = '/api/AucklandPHOLtdReportByPractice/GetMMHPHOCount';
  public static getMMHPHOCountByGender = '/api/AucklandPHOLtdReportByPractice/GetMMHPHOCountByGender';
  public static getMMHPHOCountByAgeGroup = '/api/AucklandPHOLtdReportByPractice/GetMMHPHOCountByAgeGroup';
  public static getAucklandPHOReport = '/api/AucklandPHOLtdReportByPractice/GetAucklandPHOReport';

  public static aucklandPHOLtdReportByPracticeDownloadPdf = '/api/AucklandPHOLtdReportByPractice/AucklandPHOLtdReportByPracticeDownloadPdf';
  public static aucklandPHOLtdReportByPracticeDownloadExcel = '/api/AucklandPHOLtdReportByPractice/AucklandPHOLtdReportByPracticeDownloadExcel';
  public static midCentralDHBReportByPHODownloadPdf = '/api/MidCentralDHBReportByPHO/MidCentralDHBReportByPHODownloadPdf';
  public static midCentralDHBReportByPHODownloadExcel = '/api/MidCentralDHBReportByPHO/MidCentralDHBReportByPHODownloadExcel';
  public static midCentralDHBReportByPracticeDownloadPdf = '/api/MidCentralDHBReportByPractice/MidCentralDHBReportByPracticeDownloadPdf';
  public static midCentralDHBReportByPracticeDownloadExcel = '/api/MidCentralDHBReportByPractice/MidCentralDHBReportByPracticeDownloadExcel';
  public static nationalReportByDHBDownloadPdf = '/api/MMHNationalReportByDHB/NationalReportByDHBDownloadPdf?ReportId=';
  public static mMHNationalReportByDHBDownloadExcel = '/api/MMHNationalReportByDHB/MMHNationalReportByDHBDownloadExcel?ReportId=';
  public static nationalReportByPHODownloadPdf = '/api/MMHNationalReportByPHO/NationalReportByPHODownloadPdf?ReportId=';
  public static mMHNationalReportByPHODownloadExcel = '/api/MMHNationalReportByPHO/MMHNationalReportByPHODownloadExcel?ReportId=';
  public static nationalReportByPracticeDownloadPdf = '/api/MMHNationalReportByPractice/NationalReportByPracticeDownloadPdf?ReportId=';
  public static mMHNationalReportByPracticeDownloadExcel = '/api/MMHNationalReportByPractice/MMHNationalReportByPracticeDownloadExcel?ReportId=';
  //#endregion

  public static getPatientHESSummary = "/api/SEHR/GetPatientHESSummary";
  public static getCareHealthCentres = "/api/SharedCare/GetCareHealthCentres";
  public static getCareTeamPaging = "/api/SharedCare/GetCareTeamPaging";
  public static getCareTeamCount = "/api/SharedCare/GetCareTeamCount";
  public static getPatientExpiryDate = "/api/SharedCare/GetPatientExpiryDate?patientId=";
  public static removeRenewCareTeambyUser = "/api/SharedCare/RemoveRenewCareTeambyUser";



  // Preview care plan
  public static getCarePlanTemplateByID ='/api/Integratedcare/GetCarePlanTemplateByID';
  public static getGoalTemplateByCarePlanID='/api/SharedCare/GetGoalTemplateByCarePlanID?CarePlanID=';
  public static getTaskResourceInfo ='/api/Integratedcare/GetTaskResourceInfo?TaskId=';
  public static getGoalResourceInfo='/api/Integratedcare/GetGoalResourceInfo?Goalid=';
  public static getCarePlanTaskTemplateByGoalID='/api/SharedCare/GetCarePlanTaskTemplateByGoalID?GoalID=';
  public static getMMHSCNHealthIndicator='/api/SharedCare/GetMMHSCNHealthIndicator?CarePlanID=';
  public static getCarePlanSCNHealthIndicator='/api/SharedCare/GetCarePlanSCNHealthIndicator?CarePlanID=';
  public static getResourceTemplateByTypeID='/api/Integratedcare/GetResourceTemplateByTypeID';
  public static DynamicSelect ='/api/SharedCare/DynamicSelect?sTableName=';

  //#sharedcare
  public static gerLastAccessed = '/api/SharedCare/GetRALastAccessed';
  public static getProfileDetailsByUser = '/api/SharedCare/GetProfileDetailsByUser?shcpatientuserId=';
  public static getProfileDetailsByUserInfo = '/api/Sharedcare/GetProfileDetailsByUserInfo'
  public static getServiceListCount = '/api/SharedCare/GetCountForServiceReqAccessByUser?patientId=';
  public static getServiceDataList = '/api/SharedCare/GetListForServiceReqAccessByUser';
  public static addProgressNotesShared = '/api/SharedCare/ShareNotesTask';
  public static getProgressNotesListCount = '/api/SharedCare/GetSharedNoteCount?patientId=';
  public static getProgressNotesDataList = '/api/SharedCare/GetSharedNoteListPaging';
  public static getGpNoteListCount = '/api/SharedCare/GetVisitsCount?scpatientuserId=';
  public static getGpNotesDataList = '/api/SharedCare/GetVisitsList';
  public static getAddAlert = '/api/SharedCare/AddSharedNotes';
  public static getAddAlertSharedNotes = '/api/SharedCare/SaveUserNotes';
  public static getAlertListCount = '/api/SharedCare/GetAlertsDataCount?patientID=';
  public static getAlertDataList = '/api/SharedCare/GetAlertsData?patientID=';
  public static getUpdateAlersStatus = '/api/SharedCare/UpdateProvNotesIsActive?ID=';
  public static getPrescriptionListCount = '/api/SharedCare/GetMedicationCount?scpatientuserId=';
  public static getPrescriptionDataList = '/api/SharedCare/GetMedicationList?startRowIndex=';
  public static getAllergieListCount = '/api/SharedCare/GetMedicalWarningCount?scpatientuserId=';
  public static getAllergiesDataList = '/api/SharedCare/GetMedicalWarningList?startRowIndex=';
  public static getImmunisationListCount = '/api/SharedCare/GetImmunisationCount?scpatientuserId=';
  public static getImmunisationList = '/api/SharedCare/GetImmunisationList?startRowIndex=';
  public static getClassificationListCount = '/api/SharedCare/GetDiagnosisListCount?scpatientuserId=';
  public static getClassificationDataList = '/api/SharedCare/GetDiagnosisList?startRowIndex=';
  public static getLabResultListCount = '/api/SharedCare/GetLabresultCount?scpatientuserId=';
  public static getLabResultList = '/api/SharedCare/GetLabResultList?startRowIndex=';
  public static getRAHealthDocumentsListCount = '/api/SharedCare/GetRAHealthDocumentsListCount?PatientID=';
  public static getRAHealthDocumentsList = '/api/SharedCare/GetRAHealthDocumentsList?sortExpression=';
  public static getInactiveHealthDocumentList = '/api/SharedCare/DeleteHealthDocument?iDocuemntID=';
  public static getDownloadHealthDocuments = '/api/SharedCare/DownloadFile?fileName=';
  public static saveHealthDocument = '/api/SharedCare/SaveHealthDocument';
  public static getTasksList = '/api/SharedCare/GetTasksList?startRowIndex=';
  public static getTasksCount = '/api/SharedCare/GetTasksCount?scpatientuserId=';
  public static getAddStaffTask = '/api/SharedCare/NewStaffTask';
  public static getSaveECoachingProgressNotes = '/api/SharedCare/SaveECoachingProgressNotes';
  public static getUpdateStaffTask = '/api/SharedCare/InsertTaskNotes';
  public static updateStaffTaskDetails = '/api/SharedCare/UpdateStaffTaskDetails';
  public static getCareCoordinatiorList = '/api/SharedCare/CareCoordinatorListDropdown?patientID=';
  public static getUserRolesCareTeam = '/api/SharedCare/GetUserRoles?user=';
  public static getCareTeamStaffTask = '/api/SharedCare/CareTeamListDropdown?patientID=';
  public static getCareTeamListByUserCount = '/api/SharedCare/GetCareTeamListByUserCount?scpatientuserId=';
  public static getCareTeamUsersList = '/api/SharedCare/GetCareTeamUsersList?startRowIndex=';
  public static getRemoveRenewCareTeam = '/api/SharedCare/RemoveRenewCareTeambyUser';
  public static getCareTeamLocalityList = '/api/SharedCare/GetLocalitylist?scUserID=';
  public static getCareTeamStaffList = '/api/SharedCare/GetStaffListbyLocality?PracticeID=';
  public static getAddProvidertoCareTeam = '/api/SharedCare/AddProvidertoCareTeam';
  public static getOrganisationList = '/api/SharedCare/GetOrganisationlist?scUserID='
  public static getAddFaciltitytoCareTeam = '/api/SharedCare/AddFaciltitytoCareTeam';
  public static getNonInviteClinicalStaff = '/api/SharedCare/GetNonInvitedClinicalStaff?PracticeManagerId=';
  public static getRelationShipDropdown = '/api/SharedCare/GetRelationshipList';
  public static getSendEmailInvitation = '/api/SharedCare/SendEmailInvitaton';
  public static getOrgInvitation = '/api/SharedCare/GetAllPraciceClinicalRoleByDHBID?scUserID=';
  public static getAuthenticateCarePlan = '/api/SharedCare/IsAuthenticatedOverloaded?PatientUserId=';
  public static getPdfFromHandler ='/api/SharedCare/GetPDFFromHandler?sID=';

  public static getCarePlanCoordinator ='/api/SharedCare/GetCarePlanCoordinatorList?ID='
  public static getAllUsersCarePlan = '/api/SharedCare/GetRAAllUserCarePlans';
  public static getAllExistingGoal = '/api/SharedCare/GetRAAllGoalsFromLibrary?ID=';
  public static createNewGoal = '/api/Integratedcare/CreateGoal';
  public static getCarePlanGoalByUser = '/api/SharedCare/GetRACarePlanGoalsByUser';
  public static getAddGoalFromLibrary='/api/SharedCare/AddGoalFromLibrary';
  public static getAddCustomiseGoal='/api/SharedCare/AddCustomiseGoal';
  public static getAssignNewCarePlanCoordinator='/api/SharedCare/AssignNewCarePlanCoordinator';
  public static getCarePlanTasksByUser = '/api/SharedCare/GetRATasksByUserCarePlan';
  public static getCarePlanTasksByUserCount = '/api/SharedCare/GetRATasksByUserCarePlanCount?CarePlanID=';
  public static getUpdateGoalTaskStatus = '/api/SharedCare/UpdateTaskStatus';
  public static addAssignTask = '/api/SharedCare/AddingProgressNotesToGoalTask';
  public static getAccessAuditListCount = '/api/SharedCare/GetRecentAccessByUserCount?scpatientuserId=';
  public static getAccessAuditDataList = '/api/SharedCare/GetRecentAccessUsersList?startRowIndex=';
  public static sendSMSMessage = '/api/SharedCare/SendMessageToPatient?PatientGuid=';
  public static sendEmailInvite = '/api/SharedCare/SendEmailToRegisterPatient?PatientGuid=';
  public static getSmsSentDataList = '/api/SharedCare/GetSMSSentList?SenderID=';
  public static getFavoriteMoobileNumber = '/api/SharedCare/SavePatientFavouriteMobileNumber?PatientGuid=';
  public static getPatientFavouriteMobileNumber = '/api/SharedCare/GetPatientFavouriteMobileNumber?PatientGuid=';
  public static getReferralDetailsDataList = '/api/SharedCare/GetPatientShareReferralDetails?scLogeedInUserId=';
  public static getReferralAccept = '/api/SharedCare/GetMT32GrantedSEHRRequestAccessData?RequestAccessID=';
  public static getPatientListByLocality = "/api/SharedCare/GetPatientListByLocality";
  public static getFacilityForUser = "/api/SharedCare/GetFacilityForUser?scpatientuserId=";
  public static getSearchResultsByLocality = "/api/SharedCare/GetSearchResultsByLocality"
  public static getSearchResultsCountByLocality = "/api/SharedCare/GetSearchResultsCountByLocality";
  public static getShareCareRequestedDetails = "/api/SEHR/GetShareRequestedDetails?StatusCode=NEWREF";
  public static getNewReferralMoreInfoDetails = "/api/SharedCare/SharedCareNewReferral?NewReferralId=";
  public static displayMoreInfoDetails = "/api/SharedCare/DisplayMoreInfoDetails?RAUniqueID=";
  public static getSPRReqAccessDetails = "/api/SharedCare/GetSPRReqAccessDetails?RAUniqueID=";
  public static rejectAccessForPatientOnWeb = "/api/SharedCare/RejectAccessForPatientOnWeb";
  public static getReferralAcceptedPDFType = '/api/SharedCare/GetPDFFromHandler?sID=';
  public static getShowSummaryPDF = '/api/SharedCare/GetShowSummaryPDF?PatientId=';
  public static getGoalTaskById = '/api/SharedCare/GetCarePlanTaskTemplateByGoalID?GoalID=';
  public static getTaskNotesContactDetails = '/api/SharedCare/GetTaskNotesContactDetails?TaskId=';
  public static myTasksGetTaskNotes = '/api/SharedCare/MyTasksGetTaskNotes?TaskID=';

  // shared care
  public static getSearchRequetsHealthCenterList = '/api/SharedCare/GetSearchrequestsHealthCentrelist'
  public static getRequestAcceptedPDFType = '/api/SharedCare/GetPDFFromHandler?sID=';
  public static getSearchRequestsPaging = '/api/SharedCare/GetSearchRequestsPaging'
  public static getSearchRequestsCount = '/api/SharedCare/GetSearchRequestsCount'
  public static getRequestMoreInfoDetails = '/api/SharedCare/GetRequestDisplayStatusDetails?RequestAccessID=';
  public static GetGoalTemplateByCarePlanID = '/api/SharedCare/GetGoalTemplateByCarePlanID?CarePlanID=';
  public static GetCarePlanTaskTemplateByGoalID = '/api/SharedCare/GetCarePlanTaskTemplateByGoalID?GoalID=';
  public static GetMMHSCNHealthIndicator = '/api/SharedCare/GetMMHSCNHealthIndicator?CarePlanID=';
  public static GetCarePlanSCNHealthIndicator = '/api/SharedCare/GetCarePlanSCNHealthIndicator?CarePlanID=';
  public static GetRAAllGoalsFromLibrary = '/api/SharedCare/GetRAAllGoalsFromLibrary?';
  public static AddGoalsToCarePlan = '/api/Integratedcare/AddGoalsToCarePlan';
  public static DeleteFromCarePlanGoal = '/api/Integratedcare/DeleteFromCarePlanGoal?iGoalId=';
  public static GetRAAllTasksFromLibrary = '/api/SharedCare/GetRAAllTasksFromLibrary?ID=';
  public static AddUserTasksToCarePlanWithNotes = '/api/Integratedcare/AddUserTasksToCarePlanWithNotes';
  public static DeleteFromCarePlanTask = '/api/Integratedcare/DeleteFromCarePlanTask?iTaskId=';
  public static InsertToCarePlanSCNHealthIndicator = '/api/Integratedcare/InsertToCarePlanSCNHealthIndicator';
  public static DeleteUserHealthIndicatorFromTemplate = '/api/Integratedcare/DeleteFromCarePlanHealthIndicator/';
  public static GetResourceTemplateByTypeID = '/api/Integratedcare/GetResourceTemplateByTypeID';
  public static InsertToCarePlanResourceTemplate = '/api/Integratedcare/InsertToCarePlanResourceTemplate';
  public static UpdateCarePlanResourceName = '/api/Integratedcare/UpdateCarePlanResourceName';
  public static DeleteFromCarePlanResource = '/api/Integratedcare/DeleteFromCarePlanResource/';
  public static PublishCarePlanTemplate = '/api/Integratedcare/PublishCarePlanTemplate?iCarePlanID=';
  public static AddTasksToCarePlanForEdit = '/api/Integratedcare/AddTasksToCarePlanForEdit';
  public static UpdateCarePlanTemplate = '/api/SharedCare/UpdateCarePlan';
  public static getCarePlans = '/api/SharedCare/GetCarePlans?ProviderUserID=';


  // My Patient Details in Share care
  public static getMyPatientListCount = '/api/SharedCare/GetMyPatientListCount?&TYPE=';
  public static getMyPatientDataList = '/api/SharedCare/GetMyPatientList?CURRENTPAGE=';

  //
  public static getPracticeForSharedCareAdmin='/api/Integratedcare/GetPracticeForSharedCareAdmin';
  public static getInsertToCarePlan='/api/SharedCare/InsertToCarePlan';
  public static getUpdateCarePlan='/api/Integratedcare/UpdateCarePlanTemplate';
  public static getUserCarePlanByIDUpdate='/api/SharedCare/GetUserCarePlanByID?Id=';
  public static getRAAllGoalsFromLibrary='/api/SharedCare/GetRAAllGoalsFromLibrary?ID=';
  public static getRAAllTasksFromLibrary='/api/SharedCare/GetRAAllTasksFromLibrary?ID=';
  public static getCreateGoal='/api/Integratedcare/CreateGoal';
  public static getUserGoalByCarePlanID='/api/Integratedcare/GetUserGoalByCarePlanID?CarePlanID=';
  public static getAddUserGoalsToCarePlanWithNotes='/api/Integratedcare/AddUserGoalsToCarePlanWithNotes';
  public static getAddUserTasksToCarePlanWithNotes='/api/Integratedcare/AddUserTasksToCarePlanWithNotes';
  public static getCreateTask='/api/Integratedcare/CreateTask';
  public static getUserTaskByGoalID='/api/Integratedcare/GetUserTaskByGoalID?GoalID=';
  public static deleteRemoveGlobalGoal='/api/SharedCare/DeleteUserGoalFromCarePlan?GoalId=';
  public static RemoveGlobalTask='/api/SharedCare/DeleteUserTaskFromCarePlan?TaskId='
  public static addExtingGoals='/api/SharedCare/AddGoals';
  public static addExtingTask='/api/SharedCare/AddTasks';


  //#endregion

  // sehr access audit
  public static getSHERWhoAccessedPaging = '/api/SEHR/GetSHERWhoAccessedPaging';
  public static getAHGroupByloggedinUser = '/api/SEHR/GetAHGroupByloggedinUser?UserID=';
  public static getSHERWhoAccessedCount = '/api/SEHR/GetSHERWhoAccessedCount';
  public static accessAuditExcel = '/api/SEHR/AccessAuditExcel';

  //#region referral
  //MoleMapReferral
  public static getSearchMoleMapReferrals = '/api/MoleMapReferral/GetSearchMoleMapReferrals';
  public static updateMoleMapReferrals = '/api/MoleMapReferral/UpdateMoleMapReferralAcknowledgement';
  public static getMoleMapPatients = '/api/MoleMapReferral/GetPatientNameList?sPatientName=';
  public static getMoleMapProviders = "/api/MoleMapReferral/GetProviderNameList?sProviderName=";
  public static getMoleMapReferralsCount = '/api/MoleMapReferral/GetSearchCountForMoleMapReferrals';
  public static moleMapReferralPdf = '/api/MoleMapReferral/GeneratePracticeMoleMapReferralDownloadPdf?iReferralID=';

  //QuitlineReferral
  public static getSearchQuitlineReferrals = '/api/QuitlineReferrals/GetSearchBayAudiologyReferrals';
  public static updateQuitlineReferrals = '/api/QuitlineReferrals/UpdateQuitGroupReferralAcknowledgement';
  public static getQuitlinePatients = '/api/QuitlineReferrals/GetPatientNameList?sPatientName=';
  public static getQuitlineProviders = "/api/QuitlineReferrals/GetProviderNameList?sProviderName=";
  public static getQuitlineReferralsCount = '/api/QuitlineReferrals/GetSearchCountForQuitGroupReferrals';
  public static quitlinereferralPdf = '/api/QuitlineReferrals/GenerateQuitGroupReferralPdf?iQuitGroupReferralID=';

  //BayAudiologyReferral
  public static getSearchBayAudiologyReferrals = '/api/AudiologyReferrals/GetSearchBayAudiologyReferrals';
  public static updateSearchBayAudiologyReferrals = '/api/AudiologyReferrals/UpdateBayAudiologyReferralAcknowledgement';
  public static getBayAudiologyPatients = '/api/AudiologyReferrals/GetPatientNameList?sPatientName=';
  public static getBayAudiologyProviders = '/api/AudiologyReferrals/GetProviderNameList?sProviderName=';
  public static getBayAudiologyReferralsCount = '/api/AudiologyReferrals/GetSearchCountForBayAudiologyReferrals';
  public static bayReferralExcel = '/api/AudiologyReferrals/AudiologyExportExcel';

  //communityreferrals
  public static getSearchCommunityNursingReferrals = '/api/CommunityNursing/GetSearchEReferral';
  public static getCommunityNursingPatients = '/api/CommunityNursing/GetPatientNameList?sPatientName=';
  public static getCommunityNursingProviders = "/api/CommunityNursing/GetProviderNameList?sProviderName=";
  public static updateCommunityNursingReferrals = '/api/CommunityNursing/UpdateEReferralAcknowledgement';
  public static getCommunityNursingReferralsCount = '/api/CommunityNursing/GetSearchCountForEReferralDownload';

  //DilworthhearingReferral
  public static getSearchDilworthReferrals = '/api/DilworthHearingReferal/GetSearchDilworthHearingReferrals';
  public static updateDilworthReferrals = '/api/DilworthHearingReferal/UpdateDilworthHearingReferralAcknowledgement';
  public static getDilworthPatients = '/api/DilworthHearingReferal/GetPatientNameList?sPatientName=';
  public static getDilworthProviders = "/api/DilworthHearingReferal/GetProviderNameList?sProviderName=";
  public static getDilworthReferralsCount = '/api/DilworthHearingReferal/GetSearchCountForDilworthHearingReferrals';
  public static dilworthReferralPdf = '/api/DilworthHearingReferal/GeneratePracticeDilworthHearingReferralPdf?iReferralID=';
  public static dilworthReferralExcel = '/api/DilworthHearingReferal/GetSearchDilworthHearingReferralsExcel?bIsAcknowledged=';

  //#endregion

  //Search Referral
  public static getSerachReferralHealthCentre = '/api/SharedCare/GetSearchReferralsHealthCentrelist';
  public static getSerachReferralPaging = '/api/SharedCare/GetSearchReferralPaging';
  public static getSerachReferralPagingCount = '/api/SharedCare/GetSearchReferralCount';
  public static getReferralMoreInfoDetails = '/api/SharedCare/DisplayMoreInfoDetails?RAUniqueID=';
  public static getStaffForExtComm = '/api/SharedCare/GetStaffForExtComm?PracticeManagerID=';
  public static getClinicalStaffForExtComm = '/api/Inbox/GetClinicalStaffForExtComm?PracticeManagerID=';

  //View Access Audit
  public static getViewAccessActivityTypes = '/api/SharedCare/GetActivityType';
  public static getViewAccessAuditPagingCount = '/api/SharedCare/GetAccessAuditCount';
  public static getViewAccessAuditPaging = '/api/SharedCare/GetAccessAuditPaging';

  //Request Access
  public static getRequestAccessFavouritePractice = '/api/SharedCare/GetFavoritesPracticeByLoggedInUser';
  public static getStaffList = '/api/SharedCare/GetStaffList?PracticeId=';
  public static getPracticeAutoComplete = '/api/SharedCare/MMHWebHandlerPractice';
  public static getAddressAutoComplete = '/api/SharedCare/MMHWebHandlerAddress';
  public static getFacilityForUSer = '/api/SharedCare/GetFacilityForUser';
  public static getClinician = '/api/SharedCare/GetRAStaffList';
  public static getStaffDetails = '/api/SharedCare/GetStaffDetails';
  public static getFacilityList = '/api/SharedCare/GetRequestAccessFaclitylist';
  public static submitShareRequest = '/api/SharedCare/SubmitShareRequest';
  public static addPraticeFavourite = '/api/SharedCare/AddPracticetoFavourite?OrganisationId=';
  public static getReqAccessPracticeDetails = '/api/Practice/GetPracticeDetails?practiceManagerId=';

  //Care Plan Setup
  public static getPracticeCarePlanTemplate = '/api/Integratedcare/GetPracticeCarePlanTemplateBySearch';
  public static getCarePlanAuthors = '/api/SharedCare/GetAuthorList';
  public static getCarePlanId = '/api/SharedCare/CopyTemplateToNewCarePlanWithNotes';
  public static GetUserSCNMMHHealthIndicator = '/api/SharedCare/GetUserSCNMMHHealthIndicator?CarePlanId=';
  public static InsertUserSCNHealthIndicatorToCarePlan = '/api/SharedCare/InsertUserSCNHealthIndicatorToCarePlan';
  public static GetUserSCNCarePlanHealthIndicator = '/api/SharedCare/GetUserSCNCarePlanHealthIndicator?CarePlanId=';
  public static DeleteUserHealthIndicatorFromCarePlan = '/api/SharedCare/DeleteUserHealthIndicatorFromCarePlan?CarePlanHealthIndicatorId=';

  public static getResourceList = '/api/SharedCare/GetUserResourceByTypeID?CarePlanId=';
  public static SharedCareCreateResource = '/api/Integratedcare/InsertUserResourceForCarePlan';
  public static SharedCareUpdateResource = '/api/Integratedcare/UpdateUserResourceNameForCarePlan';
  public static SharedCareDeleteResource = '/api/Integratedcare/DeleteUserResourceFromCarePlan?ResourceId=';
  public static SharedCareActivateResource = '/api/SharedCare/CarePlanActivate';

  // Shared Care Sms inbox
  public static getSMSSentListByFacility = '/api/SharedCare/GetSMSSentListByFacility';
  public static getProviderByFacilityID = '/api/SharedCare/GetProviderByFacilityID?FacilityId=';
  public static getFacilityForUsers = '/api/SharedCare/GetFacilityForUser';
  public static saveChatMessages ='/api/SharedCare/SaveChatMessages?PatientGuid=';

  // my task
  public static getUnreadNewMessagescount ='/api/SharedCare/GetUnreadNewMessagescount';
  public static getDBSNewMessage ='/api/SharedCare/GetDBSNewMessage?Sortexpression=';
  public static GetDBSQuickReplyGetDetails='/api/SharedCare/GetDBSQuickReplyGetDetails?MailId=';
  public static getCurrentUsersTask ='/api/SharedCare/GetCurrentUsersTask';
  public static getCurrentUserFacilityTask ='/api/SharedCare/GetCurrentUserFacilityTask';
  public static getCurrentUserAllTasks = '/api/SharedCare/SharedCareAllTasks';
  public static getCarePlanCurrentUsersTask ='/api/SharedCare/GetCarePlanCurrentUsersTask';
  public static getFacilityUsers ='/api/SharedCare/GetFacilityUsers?PracticeId=';
  public static updateFacilityTaskToUser='/api/SharedCare/UpdateFacilityTaskToUser?TaskID=';
  public static getAccesed = '/api/SearchPatients/GetRecentlyAccessed';
  public static getInvitations ='/api/SharedCare/GetInvitations?Filter=';
  public static getPendingInvitations ='/api/SharedCare/GetPendingInvitations?Email=';
  public static approveDeclineInvitation ='/api/SharedCare/ApproveDeclineInvitation?InvitationID=';
  public static getLocalityUsers = '/api/SharedCare/GetLocalityUsers?PatientUserID=';

  //Shared care System admin
  public static getAdminLocalityData = '/api/SharedCareAdmin/GetFacilityDetailsByLoggedInUser?FacilityType=';
  public static getLocalityAdmins = '/api/SharedCareAdmin/GetStaffListLocalityByPracticeIDandContactTypeBitmapCode?PracticeID=';
  public static getAdminLocalityUsers = '/api/SharedCareAdmin/GetLocalityStaffListByLoggedInUserAndStatus?Status=';
  public static getAdminLocalities = '/api/SharedCareAdmin/GetLocalitylist';
  public static submitAdminRolesAndStatus = '/api/SharedCareAdmin/SubmitRolesAndStatus';
  public static createLocalityUser = '/api/SharedCareAdmin/CreateLocatilityORFacilityUser';
  public static saveExistingLocalityUser = '/api/SharedCareAdmin/SaveExistingUser';
  public static getAdminExistingUserDetails = '/api/SharedCareAdmin/GetExistingUserDetails?Email=';
  public static getAdminFacilties = '/api/SharedCareAdmin/GetFacilitylist?LocatityId=';
  public static getAdminFacilityUsers = '/api/SharedCareAdmin/GetStaffListByLoggedInUserAndStatus?Status=';
  public static getAdminFacilityDetails = '/api/SharedCareAdmin/GetPracticeDetailsEditFacility?PracticeID=';
  public static updateAdminFacility = '/api/SharedCareAdmin/UpdateFaclity';
  public static setupFacility = '/api/SharedCareAdmin/AddNewFaclity';
  public static getEmailIdForchoosePassword = '/api/Account/ChoosePassword';

  //Integrated care Task Library
  public static getLocality = '/api/Integratedcare/GetPracticeForSharedCareAdmin';
  public static getSerachTasks = '/api/Integratedcare/SearchTask';
  public static getTaskDetailsByID ='/api/Integratedcare/GetGlobalTaskTemplateDetails?TaskID=';
  public static getManageResources = '/api/Integratedcare/ViewResources';
  public static getScreeningList = '/api/Integratedcare/GetScreeningList';
  public static updateGobalTask = '/api/Integratedcare/UpdateGlobalTask';
  public static createGobalTask = "/api/Integratedcare/CreateGlobalTask";
  //public static createResource = "/api/Integratedcare/CreateResource";



  // Integratedcare care Plan
  public static carePlanSupport = '/api/Integratedcare/GetCarePlans';
  public static searchGoal='/api/Integratedcare/SearchGoal';
  public static createGlobalGoal='/api/Integratedcare/CreateGlobalGoal';
  public static createGlobalTask='/api/Integratedcare/CreateGlobalTask';

  // IntegratedCare Care Team Inivitations
  public static getInvitationsDetails ='/api/Integratedcare/GetInvitations?Filter=';
  public static getUpdateStatus = '/api/Integratedcare/UpdateStatus';
  public static getBannerDetails = '/api/Integratedcare/GetUserDetails?patientuserId=';
  public static getInvitationByUser = '/api/Integratedcare/GetInvitationsbyUserId?CarePlanUserID=';
  public static geInvitationRelationShipDropdown = '/api/Integratedcare/GetRelationshipList';
  public static getInvitaitonCarePlan = '/api/Integratedcare/GetCarePlan?CarePlanUserID=';
  public static getProviderClinicalList = '/api/Integratedcare/GetAllStaffClinical?PracticeManagerId=';
  public static getOrgnClinicalList = '/api/Integratedcare/GetAllPraciceClinical?scUserID=';
  public static getIsInvitationExists = '/api/Integratedcare/isInvitationExists';
  public static getIntegratedSendEmailInvitation = '/api/Integratedcare/SendEmailInvitaton';
  public static getSendMMHUserInvitaton = '/api/Integratedcare/SendMMHUserInvitaton';
  public static getProfileDetails = '/api/Integratedcare/GetProfileDetails?patientGuid=';
  public static registerSubmitDetails = '/api/Integratedcare/Submit';
  public static registerComplete = '/api/Integratedcare/Register';
  public static registerNowDetails = '/api/Integratedcare/RegisterNowDetails?ID=';
 // public static getInvitationById = '/api/Integratedcare/GetInivtationById?InvitationID=';
  public static getRegisterAddress = '/api/Integratedcare/GetAddress';

  // public static careTeam = '/api/Integratedcare/CreateGlobalGoal';
  public static searchTask='/api/Integratedcare/SearchTask';
  public static createResource='/api/Integratedcare/CreateResource';
  public static removeGlobalGoal='/api/Integratedcare/RemoveGlobalGoal?GoalID=';
  public static removeGlobalTask='/api/Integratedcare/RemoveGlobalTask?TaskID=';
  public static deleteResource='/api/Integratedcare/DeleteResource?ResourceID=';
  public static getResourceInfo='/api/Integratedcare/GetResourceInfoEditGlobalGoal?ResourceID=';
  public static getTaskInfo='/api/Integratedcare/GetTaskInfoEditGlobalGoal?GoalID=';
  public static viewResources='/api/Integratedcare/ViewResources';
  public static updateResource='/api/Integratedcare/UpdateResources';

   // IntegratedCare Upload File Document
   public static getPracticHealthCenters = '/api/Integratedcare/GetPractice';
   public static getDocumentCategory = '/api/Integratedcare/GetDocCategory?PracticeManagerID=';
   public static searchDocments = '/api/Integratedcare/SearchDocs?CategoryID='
   public static addNewUploadFileDocument = '/api/Integratedcare/InsertDocuments';
   public static updateUploadFileDocument = '/api/Integratedcare/UpdateDocDetails';
   public static downloadFileInDocLibrary = '/api/Integratedcare/DownloadFile?filename=';
   public static uploadDocCategorystatus = '/api/Integratedcare/UpdateDocCategoryStatus?DocCategoryID=';
   public static uploadFileInsertNewVersion = '/api/Integratedcare/InsertNewVersion';
   public static addNewDocCategory = '/api/Integratedcare/InsertDocCategory';

   public static isGoalExists='/api/Integratedcare/isGoalExists';
   public static updateGlobalGoal = '/api/Integratedcare/UpdateGlobalGoal';
   public static getGoalDetailsByID = '/api/Integratedcare/GetGlobalGoalTemplateDetails?GoalID=';
   public static addGlobalTasktoGoal = '/api/Integratedcare/AddGlobalTasktoGoal';

  //  sehr staff-list
  public static getStaffClinicalDetails = '/api/SEHR/GetStaffClinicalDetails?PracticeManagerId=';
  public static getStaffNONClinicalDetails='/api/SEHR/GetStaffNONClinicalDetails?PracticeManagerId=';
  public static getStaffDetailsSehr= '/api/SEHR/GetStaffDetails?StaffUserId=';
  public static getStaffbannerCareSettings='/api/SEHR/GetStaffbannerCareSettings?ahpracticeid=';
  public static manageSEHRAfterHoursStaffenableanddisable= '/api/SEHR/ManageSEHRAfterHoursStaffenableanddisable';
  public static DisplayStaffDetails = '/api/SEHR/DisplayStaffDetails?StaffId=';
  public static GetStaffAvailabilityInGroup = '/api/SEHR/GetStaffAvailabilityInGroup?StaffId=';
  public static UpdateAddToGroupAccess = '/api/SEHR/UpdateAddToGroupAccess?AssignStaff='
  // sehr patientSummary
  public static getShowSummaryUserDetails='/api/SEHR/GetShowSummaryUserDetails?PatientUserId=';
  public static getUserEnrollStatus = '/api/SEHR/GetUserEnrollStatusForPractice?PatientuserID='
  public static getSEHROptOutPatientSummary = '/api/SEHR/SEHROptOutPatientSummary?PatientUserId=';

  //Beta users- Opt out option
 //Beta users- Opt out option
  public static updateOptOut: string = "/api/Account/UpdateUserOptOutOption?userSessionId=";
  public static getBetaUserDetails: string = "/api/Account/GetBetaUserDetails?userSessionId=";
  public static getBetaDashboardCount: string = "/api/Account/GetBetaDashboardCount?userSessionId=";
  public static updateBetaDashboardCount: string = "/api/Account/UpdateBetaDashboardCount?userSessionId=";
  public static saveUserSiteFeedback: string = "/api/Account/SaveUserSiteFeedback";
  public static getIPAdress: string = "/api/Account/GetIpAddress";

  //Sesion Idle & expriration
  public static getSessionExtension:string = "/api/Account/CloseBrowserSession";
  public static getRefreshToken:string = "/api/Account/RefreshToken";
  // Vonage video call -- token
  public static getVonageToken = '/api/Vonage/GetVonageToken';
  public static sendVCNotificationEmail = '/api/Vonage/SendVCNotificationEmail?PatientUserID='

  // Integrated care - setup care plan
  public static addTasksToCarePlan='/api/Integratedcare/AddTasksToCarePlan';
  public static createTask='/api/Integratedcare/CreateTask';
  public static deleteGoalOfCareplan='/api/Integratedcare/DeleteFromCarePlanGoal?iGoalId=';
  public static deleteTaskOfCareplan='/api/Integratedcare/DeleteFromCarePlanTask?iTaskId=';
  public static insertToCarePlanTemplate='/api/Integratedcare/InsertToCarePlanTemplate';
  public static CopyToNewCarePlan='/api/Integratedcare/CopyTemplateFromExistingToNewCarePlan';
  public static updateCarePlanTemplate='/api/Integratedcare/UpdateCarePlanTemplate';
  public static addTasksToCarePlanForEdit='/api/Integratedcare/AddTasksToCarePlanForEdit';
  public static updateCarePlan='/api/SharedCare/UpdateCarePlan';
  public static getSharedCarePlanTemplateByID ='/api/SharedCare/GetCarePlanTemplateByID';
  public static viewActiveResources ='/api/Integratedcare/ViewActiveResources';

  // integrated care plan copy template
  public static InsertToCarePlanTemplate = '/api/Integratedcare/InsertToCarePlanTemplate';
  public static CopyTemplateFromExistingToNewCarePlan = '/api/Integratedcare/CopyTemplateFromExistingToNewCarePlan';
  public static GetGoalsByUserId = '/api/SharedCare/GetGoalsByUserId';

  public static CopyGetResourceTemplateByTypeID = '/api/Integratedcare/GetResourceTemplateByTypeID';
  public static CopyInsertToCarePlanResourceTemplate = '/api/Integratedcare/InsertToCarePlanResourceTemplate';
  public static CopyUpdateCarePlanResourceName = '/api/Integratedcare/UpdateCarePlanResourceName';
  public static CopyDeleteFromCarePlanResource = '/api/Integratedcare/DeleteFromCarePlanResource/';

  public static CopyInsertToCarePlanSCNHealthIndicator = '/api/Integratedcare/InsertToCarePlanSCNHealthIndicator';
  public static CopyDeleteUserHealthIndicatorFromTemplate = '/api/Integratedcare/DeleteFromCarePlanHealthIndicator/';
  public static CopyGetMMHSCNHealthIndicator = '/api/SharedCare/GetMMHSCNHealthIndicator?CarePlanID=';
  public static CopyGetCarePlanSCNHealthIndicator = '/api/SharedCare/GetCarePlanSCNHealthIndicator?CarePlanID=';
  public static copyGetGoalTemplateByCarePlanID = '/api/SharedCare/GetGoalTemplateByCarePlanID?CarePlanID=';
  public static CopyAddGoalsToCarePlan = '/api/Integratedcare/AddGoalsToCarePlan';
  public static CopyDeleteFromCarePlanGoal = '/api/Integratedcare/DeleteFromCarePlanGoal?iGoalId=';
  public static CopyGetCarePlanTaskTemplateByGoalID = '/api/SharedCare/GetCarePlanTaskTemplateByGoalID?GoalID=';
  public static CopyDeleteFromCarePlanTask = '/api/Integratedcare/DeleteFromCarePlanTask?iTaskId=';
  public static CopyGetRAAllTasksFromLibrary = '/api/SharedCare/GetRAAllTasksFromLibrary?ID=';
  public static viewResourcesSetupCare='/api/Integratedcare/ViewActiveResources';

  // Report Admin
  public static getReportTypes = '/api/PatientPortalReports/GetReportTypes';
  public static getHelpDeskDHBList = '/api/HelpDesk/GetDHBList';
  public static getPHOList = '/api/PatientPortalReports/GetPHOList';
  public static getPPReportUserNotAssigned = '/api/PatientPortalReports/GetReportUserNotAssiged';
  public static getPPReportUser = '/api/PatientPortalReports/GetReportUser';
  public static assignPPRRights = '/api/PatientPortalReports/InsertUserRights';
  public static unAssignPPRRights = '/api/PatientPortalReports/RemoveUserRights';
  public static addRoleToUser = '/api/Account/AddRoletoUser';

  // shared care register patient
  public static getAllPracticeByUserLoggedInUser = '/api/Account/GetAllPracticeByUserLoggedInUser?userId=';
  public static sharedCarePatientRegistration ='/api/Account/SharedCarePatientRegistration';
  public static sharedCarePatientRegistrationSMSConfirmation ='/api/Account/SharedCarePatientRegistrationSMSConfirmation';
  // new usage report
  public static GetMMHDHBCount ='/api/DHBreportby/GetMMHDHBCount?ReportId=';
  public static GetMMHDHBCountByGender ='/api/DHBreportby/GetMMHDHBCountByGender?ReportId=';
  public static GetMMHDHBCountByAgeGroup ='/api/DHBreportby/GetMMHDHBCountByAgeGroup?ReportId=';
  public static getMMHDHBCountByPracticeEthnicity = '/api/DHBReportBy/GetMMHDBHCountByPracticeEthnicity?DHBID=';
  public static MidCentralDBHReportByPractice ='/api/DHBReportBy/DBHReportByPractice?OrganizationId=';
  public static GetMMHDHBCountByPHO ='/api/DHBReportBy/GetMMHDHBCountByPHO?OrganizationId=';

  public static GetMMHPHOCount ='/api/PHOLtdreportbyPractice/GetMMHPHOCount?PHOId=';
  public static GetMMHPHOCountByGender ='/api/PHOLtdreportbyPractice/GetMMHPHOCountByGender?PHOId=';
  public static GetMMHPHOCountByAgeGroup ='/api/PHOLtdreportbyPractice/GetMMHPHOCountByAgeGroup?PHOId=';
  public static GetMMHPHOCountByEthnicity ='/api/PHOLtdreportbyPractice/GetMMHPHOCountByPracticeEthnicity?PHOId=';
  public static GetMMHPHOCountByPractice ='/api/PHOLtdreportbyPractice/GetMMHPHOCountByPractice?PHOId=';

  public static DHBReportByPHODownloadExcel ='/api/DHBreportby/DHBReportByPHODownloadExcel?ReportId=';
  public static DHBreportbyPHODownloadPdf ='/api/DHBreportby/DHBreportbyPHODownloadPdf?ReportId=';
  public static DHBReportByPracticeDownloadExcel ='/api/DHBReportBy/DHBReportByPracticeDownloadExcel?ReportId=';
  public static DHBreportbyPraticeDownloadPdf ='/api/DHBReportBy/DHBreportbyPraticeDownloadPdf?ReportId=';
  public static PHOReportByPracticeDownloadPdf ='/api/PHOLtdreportbyPractice/PHOReportByPracticeDownloadPdf?ReportId=';
  public static PHOReportByPracticeDownloadExcel ='/api/PHOLtdreportbyPractice/PHOReportByPracticeDownloadExcel?ReportId=';
  public static userActivationConfirm = '/api/Account/ActivationConfirmationLnk';
  public static reachServe = '/api/Reach/ReachServe';
  public static GetAllPatientDetailsByUser: string = "/api/Inbox/GetAllPatientDetailsByUser?UserId=";


  //signal r Api calls start
  public static insertConnectionId = '/api/JoinWaitingRoom/InsertSignalrRConnection';
  public static getConnectionId = '/api/JoinWaitingRoom/GetConnectionID';
  //signal r Api calls end


  //join meeting url starts
  public static validateVCUser = '/api/JoinWaitingRoom/ValidateVcUser';
  public static validateTinyCode = '/api/JoinWaitingRoom/ValidateTinyCode?sq=';

  public static getUserIdByPraticeId = '/api/JoinWaitingRoom/GetUserIdByPraticeId';
  public static getPatientUserId = '/api/JoinWaitingRoom/GetPatientUserIdByRequestSlotId';
  public static getVideoInvitation = '/api/JoinWaitingRoom/GetFutureVideoConsultationInvitation?UserID=';
  public static setOnlineStatus = '/api/JoinWaitingRoom/InsertIsonlineStatus';
  public static getOnlineStatus = '/api/JoinWaitingRoom/GetIsonlineStatus';
  public static getUserDetailsFromSecureCode = '/api/JoinWaitingRoom/GetProviderDetailsByMeetingID?SecureCode=';
  public static setIsWaitingRoom = '/api/JoinWaitingRoom/InsertIsWaitingRoomStatus';
  public static getMusicMaster = '/api/JoinWaitingRoom/GetWaitingRoomMusic';
  public static GetVideoConsultationInvitation = '/api/JoinWaitingRoom/GetVideoConsultationInvitation';
  public static getVCSettingsForPatient = '/api/JoinWaitingRoom/GetPracticeVCSettings';
  public static sendInviteParticipantLink = '/api/JoinWaitingRoom/InviteParticipantLink';
  public static setCompletedStatus = '/api/JoinWaitingRoom/SetCompletedStatus';
  public static getCompletedStatus = '/api/JoinWaitingRoom/GetCompletedStatus?MeetingID=';
  public static getMeetingInfo = '/api/JoinWaitingRoom/GetMeetingInfo?MeetingID=';
  public static getVideoConsultationQueuePatients = '/api/JoinWaitingRoom/GetVideoConsultationQueuePatients';
  public static insertExternalRoomGuid = '/api/JoinWaitingRoom/InsertExternalRoomGuid';
  public static getExternalRoomGuid = '/api/JoinWaitingRoom/GetExternalRoomGuid?MeetingID=';
  public static getQueueStatus = '/api/JoinWaitingRoom/GetQueueStatus';
  public static insertProviderName = '/api/JoinWaitingRoom/InsertProviderNameByMeetingId';
  public static getRingtone = '/api/JoinWaitingRoom/GetVideoCallRingtone';
    // Health Diary Settings
  public static getHealthDirySettings = '/api/Practice/UpdateHealthDiarySettings';
  public static getCommonReasonForCancellation = '/api/JoinWaitingRoom/GetLookUpReasonForCancellation';
    //join meeting url end
  public static GetfutureAppointmentstatus = '/api/JoinWaitingRoom/Get_FutureAppointmentStatus';
  public static UpdateCancellationStatusByPatient = '/api/JoinWaitingRoom/UpdateCancellationStatusByPatient';
  public static getAppoimentRemainderSettings = '/api/JoinWaitingRoom/GetProviderAppointmentSettings?PracticeId=';
  public static insertProviderAppointmentSettings = '/api/JoinWaitingRoom/InsertProviderAppointmentSettings';
  public static saveAppointmentPatientArrival = '/api/Appointment/SaveAppointmentPatientArrival';
  //join meeting url end


  //SMS GROUP start
  public static getLookupSMSModule = '/api/Practice/GetLookupSMSModule';
  public static getLookupSMSModuleByDisplayInUI = '/api/Practice/GetLookupSMSModuleByDisplayInUI';
  public static insertUpdateSMSPracticeLocation = '/api/Practice/InsertUpdateSMSPracticeLocation';
  public static getSMSPracticeLocation = '/api/Practice/GetSMSPracticeLocation';
  public static saveSMSGroupMessage = '/api/SMSGroupMessage/SaveSMSGroupMessage';
  public static smsGroupMessageRecipientGetCount = '/api/Inbox/SMSGroupMessageRecipientGetCount';
  public static updateUserProfileSMSOptOut = '/api/Account/UpdateUserProfileSMSOptOut';
  public static getAllSMSGroupSentMessage = '/api/Inbox/GetAllSMSGroupSentMessage';
  public static getSMSCreditandCostDetails = '/api/Practice/GetSMSCreditandCostDetails';
  public static cancelSMSGroupMessage = '/api/SMSGroupMessage/CancelSMSGroupMessage';
  //SMS GROUP end

  // SMS Credit Facility
  public static addTopUpSMSCreditFacility = '/api/SMSCreditFacility/AddTopUpSMSCreditFacility';
  public static getSMSCreditFacilityBalance = '/api/SMSCreditFacility/GetSMSCreditFacilityBalance';
  public static getSMSCreditFacilityBalancePractice = '/api/SMSCreditFacility/GetSMSCreditFacilityBalanceByPractice';
  public static getSMSPaymentReports='/api/SMSCreditFacility/GetSMSPaymentReports';
  public static getSMSUserSettings = '/api/SMSCreditFacility/GetSMSCreditFacilitySetting';
  public static postSMSCreditFacilitySettings = '/api/SMSCreditFacility/SaveSMSCreditFacilitySetting';
  public static SMSVideoInvite = '/api/SMSCreditFacility/SMSVideoInvite';
  //Top Up
  public static sendSMSPaymentRequest = '/api/SMSCreditFacility/SendSMSPaymentRequest';
  public static userSMSPaymentResponseView="/api/SMSPayment/UserPaymentResponseView?type=";
  public static smsPaymentResponse= '/api/SMSPayment/SMSPaymentResponse';
  //CreditCard
  public static deleteCreditCard='/api/SMSCreditFacility/DeleteCreditCard';
  public static getCreditCardDetails = '/api/SMSCreditFacility/GetCreditCardDetails';
  public static sendCreditCardRequest = '/api/SMSCreditFacility/SendCreditCardRequest';
  public static creditCardPaymentResponseView="/api/SMSPayment/CreditCardPaymentResponseView?type=";
  public static creditCardSaveResponse= '/api/SMSPayment/CreditCardSaveResponse';
  //Auto-top-up
  public static testSMSAutoTopUp ='/api/SMSCreditFacility/TestSMSAutoTopUp';
  public static getSMSAutoTopUp  ='/api/SMSCreditFacility/GetSMSAutoTopUp';
  public static saveSMSAutoTopUp ='/api/SMSCreditFacility/SaveSMSAutoTopUp'
  //Sms-Dashboard
  public static getTopBannerMessage ='/api/SMSCreditFacility/GetTopBannerMessage'
//Sms Transaction
  public static getTransactionDetails='/api/SMSCreditFacility/GetTransactionDetails?startDateDay='
  //Sms Usage
public static getSMSUsage='/api/SMSCreditFacility/GetSMSUsage'
 public static  getLookUpFunctions='/api/SMSCreditFacility/GetLookUpFunctions';
 public static  getSMSUsageHistory = '/api/SMSCreditFacility/GetSMSUsageHistory?typeId=';
 public static  getSMSUtilizationByTimePeriod = '/api/SMSCreditFacility/GetSMSUtilizationByTimePeriod';
 //SMS Cost
 public static  getSMSCost = '/api/SMSCreditFacility/GetSMSCost';
 //SMS Payment
 public static  getSMSTransactionInvoice = '/api/PxPayment/ExportOnlinePaymentsPDFSMS';
 public static  getSMSTransactionInvoicePDF = '/api/PxPayment/ExportOnlinePaymentsPDFSMSTrans';
 public static  getSMSTransactionInvoiceExcel = '/api/PxPayment/ExportOnlinePaymentsSMSToExcel';
 public static  getSMSPaymentEnabledStatus= '/api/SMSCreditFacility/SMSGetOfflinePaymentEnabled';
 public static  setSMSPaymentEnabledStatus= '/api/SMSCreditFacility/SMSInsertOfflinePaymentEnabled';
 public static  sendSMSOfflinePayment= '/api/SMSCreditFacility/SendSMSOfflinePaymentRequest';
 public static  getSMSTransactionBillAccountPDF = '/api/PxPayment/ExportBillToAccount';
 public static  getSMSTransactionYearMonth = '/api/PxPayment/SelectYearMonth?PracticeID=';

    /*******Added by Chakraveer for Alex API Integration */
    public static token = '/api/connect/token';
    public static GetWebToken= '/api/AuthServer/GetWebToken';
    public static getVCSettingsAlex = '/api/JoinWaitingRoom/GetPracticeVCSettingsALEX';

      /*******Alex api mmh */
    public static  GetAppointmentSlotsByProviderHpiId = 'appointment/GetAppointmentSlotsByProviderHpiId';
    public static BookAppointmentWithProvider= 'appointment/BookAppointmentWithProvider';
    public static GetPractitionerDetailsByHpiId= 'patients/GetPractitionerDetailsByHpiId';
    public static GetPatientDetailsByNhiId= 'patients/GetPatientDetailsByNhiId';
    public static GetCurrrentAppointmentDetailsBySlotId= 'appointment/GetCurrrentAppointmentDetailsBySlotId';
    public static GetCurrrentAppointmentDetailsByAppointmentId= 'appointment/GetCurrrentAppointmentDetailsByAppointmentId';
    public static GetCurrrentAppointmentDetailsBySlotIdAndPatientNhiID= 'appointment/GetCurrrentAppointmentDetailsBySlotIdAndPatientNhiID';
    public static GetCurrrentAppointmentDetailsByStartEndDateAndProviderHpiId= 'appointment/GetCurrrentAppointmentDetailsByStartEndDateAndProviderHpiId';
    public static GetCurrrentAppointmentDetailsByStartEndDateAndPatiendNhiId= 'appointment/GetCurrrentAppointmentDetailsByStartEndDateAndPatiendNhiId';
    public static GetCurrrentAppointmentDetailsByStartEndDatePatiendNhiIdAndBookingStatus= 'appointment/GetCurrrentAppointmentDetailsByStartEndDatePatiendNhiIdAndBookingStatus';
    public static UpdateAppointmentStatusByAppointmentId= 'appointment/UpdateAppointmentStatusByAppointmentId';
    public static GetPractitionerDetailsByNZMC= 'patients/GetPractitionerDetailsByNZMC';
    public static GetPractitionerDetailsById= 'patients/GetPractitionerDetailsById';
    public static GetPatientDetailsWithoutNhiId= 'patients/GetPatientDetailsWithoutNhiId';
    public static GetPatientDetailsById= 'patients/GetPatientDetailsById';
    public static GetAllPractitionersByFacilityId= 'practitioner/GetAllPractitionersByFacilityId' ;


    /*******************Alex API */


    /************Alex-Appointment*************/

    public static GetPracticeFacilityID= '/api/Alex/GetPracticeFacilityID' ;
    public static GetStaffHPID= '/api/Alex/GetStaffHPID' ;
    public static GetPracticeLocationsbypracticeManagerIdAlex= '/api/Practice/GetPracticeLocationsbypracticeManagerId?practiceManagerId=' ;

    /************************** */

     /************ IPS Start *************/
    public static summary = '/api/IPS/IPSummary/';
    public static exportIPSHealthSummaryPdf = '/api/IPS/HealthSummaryPDF/';
     /************ IPS End *************/

     /******** Recall Remainder Start*********/
     public static getRecallRemainder = '/api/RecallSettings/GetRecallReminderMessage/';
     public static acnowledgementRecallRemainder = '/api/RecallSettings/SaveUserRecallReminderAcknowledgement';
     /******** Recall Remainder End**********/

     // MHA
    public static mhaSelfRegistration = "/api/MhaAccount/MHASelfRegistration";
    public static mhaLogin: string = "/api/MhaAccount/MHASignIn";
    public static mhaMHASignUpValidation = "/api/MhaAccount/MHASignUpValidation";
    public static mhaMHASignInValidation = "/api/MhaAccount/MHASignInValidation";
    public static smsConfirmationMHA = '/api/MhaAccount/SMSConfirmationMHA?smscode=';

    // activate health center
    public static getActivateNextHealtCentre = '/api/Practice/ActivateNextHealtCentre';
    // optout user from the service
    public static updateOptoutService = '/api/Account/UpdateUserOptoutWithType';

    //Out of Office
    public static saveOutOfOffice = '/api/Practice/SaveOutOfOfficeSettingDetails';
    public static getOutOfOfficeSettings = '/api/Practice/GetOutOfOfficeSettingDetails';
       // alternative Practice
    public static getAlternativeProvider= '/api/Practice/GetAlternativeProvidersByPracticeManagerId';
    public static getAlternativeProviderFormValue = '/api/Practice/GetAlternativeProviderById';
    public static UpdateAlternativeProvider = '/api/Practice/SaveAlternativeAppointmentProvider';

    // System Notification
    public static getSystemNotifications= "/api/Account/GetSystemNotifications?userId="
    public static saveSystemNotifications= "/api/Account/SaveSystemNotificationPreference?userId="

    // Connected Request and Services My Profile
    public static getConnectedApps = "/api/MMHConnect/GetConnectApps"
    public static GetAppScopesByClientId= "/api/MMHConnect/GetAppScopesByClientId?clientId="
    public static stopSharingForSSO = "/api/MMHConnect/StopSharingForSSO/"
    public static updateAppScope = "/api/MMHConnect/UpdateAppScopeByClientAndUser"

    //Appointment Reason
    public static getAppointmentReasonsProvider = '/api/Appointment/GetReasonForVisitForMMHPMS?PracticeId='
    public static saveAppointmentReason = '/api/Appointment/SaveReasonForVisit?PracticeId='
    public static updateAppointmentReasons = '/api/Appointment/UpdateAppointmentReasons'
    public static deleteAppointmentReasons = '/api/Appointment/DeleteReasonForVisit'

    //BroadcastListMeta
    public static getBroadcastist = '/api/SMSGroupMessage/BroadcastListMeta'
    public static deleteBroadCastListItem = '/api/SMSGroupMessage/BroadcastListMeta'
    //uploadCSV
    public static uploadCSVfileforMessaging = '/api/SMSGroupMessage/BroadcastList/ImportCSV'

    //mappingColumns
    public static mappingDetails = '/api/SMSGroupMessage/BroadcastList/MappingDetails'

    // SMS Regestraion
    public static getTinycodebyPatDetails = '/api/Account/GetTinycodebyPatDetails?TinyCode='
    public static postSmsActivation = '/api/Account/SMSActivationConfirmation'
    public static sendSMSEmailOTP = '/api/Account/sendSMSEmailOTP'
    public static updateISOptOutStatus = '/api/Account/UpdateISOptOutStatus'
    public static SMSVerifyActivationConfirmation = '/api/Account/SMSVerifyActivationConfirmation'
    public static SMSInviteReasonAndFeedback = '/api/Account/SMSInviteReasonAndFeedback'


    //selectComposeFor
    public static getComposeFor = '/api/SMSGroupMessage/BroadcastList/ComposeFor'

    //dispatchSmsMessage
    public static dispatchSmsMessage = '/api/SMSGroupMessage/BroadcastList/Dispatch'

    //downloadBroadcasrListFile
    public static downloadBroadcasrListFile = '/api/SMSGroupMessage/BroadcastListMeta/File'

    //cancelCSVGroupMessaging
    public static cancelCSVGroupMessaging = '/api/SMSGroupMessage/BroadcastList/Cancel'

    //downloadDeliveryReports
    public static downloadDeliveryReport = '/api/SMSGroupMessage/BroadcastList/DeliveryReport'

    //switchPracticeUser
    public static switchPracticeUser = '/api/SMSGroupMessage/BroadcastList/SwitchPractice'

    //CloseAccount
    public static closeAccount = '/api/Account/CloseAccount'

    //verifyTinyCode
    public static verifyTinyCode = '/api/Account/VerifyCancellationTinyCode'

    //Complete Email Verification
    public static completeEmailVerification = '/api/Account/ConfirmEmail?sqString='
    public static checkSelfRegDob = '/api/Account/CheckSelfRegDob'


    // Patient Reports  GetLabReportsType  GetMedicationsTypeList

    public static getPatientReports = '/api/PatientReports/GetPatientReports'

    public static getLabReportsType = '/api/PatientReports/GetLabReportsType'

    public static getMedicationsTypeList = '/api/PatientReports/GetMedicationsTypeList'

     public static getVaccineTypeList=   '/api/PatientReports/GetVaccineTypeList'

     public static getPatientReportsCount = '/api/PatientReports/GetPatientReportsCount?PuserId=';

    public static AddPatientReports='/api/PatientReports/AddPatientReports'



        // Vistis and Referrals
        public static getPatientVisits = '/api/PatientVisits/GetPatientVisits';
        public static getPatientVisitsCount = '/api/PatientVisits/GetPatientVisitsCount?PuserId=';

        public static GetPMSVisitsListPaging = '/api/ProviderLabReports/GetPMSVisitsListPaging';
        public static savePatientVisits = '/api/PatientVisits/SavePatientVisits';
         public static updateVisits = '/api/PatientVisits/UpdateUserVisits';
         public static deleteVisits = '/api/PatientVisits/RemoveUserEnteredVisits/';


       //Referrals

        public static getPatientReferrals = '/api/PatientReferral/GetPatientReferrals';
        public static getPatientReferralsCount = '/api/PatientReferral/GetPatientReferralsCount?PuserId=';
        public static UpdatePatientReferral = '/api/PatientReferral/UpdatePatientReferral';
        public static GetReferralListPaging = '/api/ProviderLabReports/GetReferralListPaging';
        public static savePatientReferrals = '/api/PatientReferral/SavePatientReferral';
        public static checkPracticeSorce = '/api/Practice/CheckPracticeSource';
         public static deletePatientReferral = '/api/PatientReferral/RemoveUserEnteredReferral/';

        // AdPractice
        public static addNewPractice = '/api/Practice/CreateNewPractice';
        public static UpdateNewPractice = '/api/Practice/UpdateNewPractice';
        public static AddPractice = '/api/Practice/AddPractice';
        public static CreateNewLocation = '/api/Practice/CreateNewLocation';
        public static updatePracticeNewLocation = '/api/Practice/UpdateNewLocation';
        public static getEmployeeData = '/api/HealthAssesment/GetEmployeeData';
        //HRA
        public static HRARegistration ='/api/Account/HRARegistration';
        public static HRASignin ='/api/Account/SignInHRA';

        public static getCustomForm = '/api/HealthAssesment/GetCustomForm?pUserId=';
        public static saveCustomFormData = '/api/HealthAssesment/SaveCustomFormData';
        public static getHraSummary = '/api/HealthAssesment/GetHraSummary?patientId=';
        public static getGraphData = '/api/HealthAssesment/GetGraphData?patientId=';

        public static AddRegularGPDetails ='/api/Account/AddRegularGPDetails';
        public static GetPatientById = '/api/HealthAssesment/GetPatientById1?employeeId=';
 
        public static getHealthCentersEB = '/api/Appointment/GetPatientHealthCentersEB?practiceID=';

  public static getOrganizationCount = '/api/HealthAssesment/GetOrganizationCount';
  public static OrganisationSummary ='/api/HealthAssesment/OrganisationSummary';
  public static OrganisationGraph ='/api/HealthAssesment/OrganisationGraph';
  public static generatePrescription='/api/ProviderLabReports/GeneratePrescription';
 
  public static updatePracticeLocation = '/api/Practice/UpdateNewLocation';  
public static GetReasonForVisit = '/api/Appointment/GetReasonForVisit?practiceId=';
public static addAppointmentSlot = '/api/InsertAppointmentSlotPeriodsMaster/Createappointementslot';
public static getOrganization = '/api/HealthAssesment/GetOrganization?UserId=';
public static downloadHRAAttachment = '/api/HealthAssesment/GenerateReport?currentUserId=';
public static CompletePrescription = '/api/ClinicalNotes/CompletePrescription?@clinicalnoteId=';
public static UpdateCompletePrescrption = '/api/ClinicalNotes/UpdateCompletePrescrption?clinicalnoteId=';

//  public static getPatientHealthCenter = '/api/Appointment/GetPatientHealthCenters?patientuserid=';
public static getReasonForVisit = '/api/Appointment/GetReasonForVisit?practiceId=';

  public static checkEmailRegistered = '/api/Practice/CheckEmailRegistered?email=';
  public static hraCheckBoxClicked = '/api/HealthAssesment/HRACheckBoxClicked?employeeId=';
  public static uploadChart = '/api/HealthAssesment/UploadChart';
  public static GetLocationDetails = '/api/Appointment/GetLocationDetails?locCode=';

  // public static getPatientHealthCenter = '/api/Appointment/GetPatientHealthCenters?patientuserid=';
public static AppointmentStatus = '/api/Appointment/RequestedAppSlotId?RequestedAppSlotId=';

public static getsearchshowslot = '/api/Appointment/searchShowSlots?PracticeID=';
  public static updateSlotsDayOff = '/api/Appointment/updateSlotsDayOff';
  public static deleteSlotsDayOff = '/api/Appointment/deleteSlotsDayOff';
  public static getTotalWebsiteCount ='/api/UserProfile/GetTotalWebsiteCount?fromDate=';
 
public static CheckAppAlreadyExist='/api/InsertAppointmentSlotPeriodsMaster/CheckAppAlreadyExist';
public static getPracticeEasyBookingStatus = '/api/Practice/GetPracticeEasyBookingStatus?practiceID='

;
}
