import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuItems } from './data/interface/menu-items/menu-items';
import { HorizontalMenuItems } from './data/interface/menu-items/horizontal-menu-items';
import {
  AccordionAnchorDirective,
  AccordionLinkDirective,
  AccordionDirective
} from './directive/accordion';
import { ReadMoreComponent } from './component/read-more/read-more.component';
import { MaterialModule } from '../material-module';
import { DownloadFileComponent } from './component/download-file/download-file.component';
import { FileUploadComponent } from './component/file-upload/file-upload.component';
import { ImageUploadComponent } from './component/image-upload/image-upload.component';
import { TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';

import { HttpClient } from '@angular/common/http';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SignatureUploadComponent } from './component/signature-upload/signature-upload.component';
import { FileDownloadComponent } from './component/file-download/file-download.component';
import { FileUploadWithoutExtComponent } from './component/file-upload-without-ext/file-upload-without-ext.component';
import { FooterComponent } from './component/footer/footer.component';
import { MmhV2VideocallComponent } from './component/vonage/mmh-v2-videocall.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { RouterModule } from '@angular/router';
import { AlertDialogueLoginComponent } from './component/alert-dialogue-login/alert-dialogue-login.component';
import { OnlyNumberDirective } from './directive/accordion/only-number.directive';
import { OnlyAlphabetDirective } from './directive/accordion/only-alphabet.directive';
import { PasswordStrengthComponent } from './component/password-strength/password-strength.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DateConvertPipe } from './pipe/date-convert.pipe';
import { MaxLengthDirective } from './directive/max-length';
import { MobileNumberDirective } from './directive/accordion/mobile-number.directive';
import { LandlineNumberDirective } from './directive/accordion/landline-number.directive';
import { RestrictFirstCharDirective } from './directive/restrict-first-char.directive';
import { SpecialCharacterFilterDirective } from './directive/accordion/special-character-filter.directive';
import { SliceArrayPipe } from './pipe/slice-array.pipe';
import { SpecialCharacterRemoverDirective } from './directive/special-character-remover.directive';
import { DisableAutoFillDirective } from './directive/disable-auto-fill.directive';
import { DateTimeFormatPipe } from './pipe/date-time-format.pipe';
import { QRCodeModule } from 'angularx-qrcode';
import { DecimalDirective } from './directive/accordion/decimal.directive';
import { LocalDatePipe } from './pipe/local-date.pipe';
// import { BookAppointmentComponent } from '../modules/pages-appointment/book-appointment/book-appointment.component';
export function HttpLoaderFactory(http: HttpClient): any {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
// import { ReadMoreComponent } from './component/read-more/read-more.component';
//import { ToasterComponent } from './toaster/toaster.component';

@NgModule({
  imports:[CommonModule,MaterialModule,
    ImageCropperModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    DialogModule,
    ButtonsModule,
    RouterModule,
    FlexLayoutModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    QRCodeModule,
  ],
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    OnlyNumberDirective,
    OnlyAlphabetDirective,
    MaxLengthDirective,
    RestrictFirstCharDirective,
    ReadMoreComponent,
    DownloadFileComponent,
    FileUploadComponent,
    ImageUploadComponent,
    SignatureUploadComponent,
    FileDownloadComponent,
    FileUploadWithoutExtComponent,
    FooterComponent,
    MmhV2VideocallComponent,
    AlertDialogueLoginComponent,
    PasswordStrengthComponent,
    DateConvertPipe,
    MobileNumberDirective,
    LandlineNumberDirective,
    SpecialCharacterFilterDirective,
    SliceArrayPipe,
    SpecialCharacterRemoverDirective,
    DisableAutoFillDirective,
    DateTimeFormatPipe,
    DecimalDirective,
    LocalDatePipe,
   // BookAppointmentComponent

    //  ToasterComponent
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    OnlyNumberDirective,
    OnlyAlphabetDirective,
    MaxLengthDirective,
    RestrictFirstCharDirective,
    ReadMoreComponent,
    DownloadFileComponent,
    FileUploadComponent,
    ImageUploadComponent,
    SignatureUploadComponent,
    FileDownloadComponent,
    FileUploadWithoutExtComponent,
    FooterComponent,
    MmhV2VideocallComponent,
    PasswordStrengthComponent,
    DateConvertPipe,
    MobileNumberDirective,
    LandlineNumberDirective,
    SpecialCharacterFilterDirective,
    SliceArrayPipe,
    SpecialCharacterRemoverDirective,
    DisableAutoFillDirective,
    DateTimeFormatPipe,
    LocalDatePipe,
    //BookAppointmentComponent,
    // ToasterComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [MenuItems, HorizontalMenuItems]
})
export class MMHV2SharedModule { }
