<app-toaster></app-toaster>
<router-outlet>
    <!-- <div class="np-loader" *ngIf="showLoader==true">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div> -->
    <!-- alex loader -->
    <!-- <div class="np-loader" *ngIf="showAlexLoader==true">
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    </div> -->
    <div class="loader-container" *ngIf="showLoader==true">
        <img class="loader" src="assets/images/MMH.gif" alt="Loading...">
    </div>
    <!-- alex loader -->
    <div class="loader-container" *ngIf="showAlexLoader==true">
        <img class="loader" src="assets/images/MMH.gif" alt="Loading...">
    </div>


    <div class="session-dialog">
        <kendo-dialog title="Your session is about to expire!" [minWidth]="250" [width]="550" *ngIf="opened"
            (close)="close($event)" class="res-sm hide-close-icon">
            {{idleState}}
            <!-- you will be logged off in <strong>33</strong> seconds. -->
            <p>Do you want to continue your session?</p>

            <kendo-dialog-actions class="btn-container">
                <!-- <button (click)="logout()">Logout</button> -->
                <button class="btn-secondary-rect m-r-10" (click)="logout()">No, Logoff</button>
                <button class="btn-primary-rect" (click)="stay()">Yes, Keep Working</button>

            </kendo-dialog-actions>

        </kendo-dialog>
    </div>
    <div class="session-dialog">
        <kendo-dialog title="An unexpected error occured" [minWidth]="250" [width]="550" *ngIf="isModelStateError"
            (close)="logout()" class="res-sm hide-close-icon">

            <!-- you will be logged off in <strong>33</strong> seconds. -->
            <p>Sorry for the inconvenience, please login again.</p>

            <kendo-dialog-actions class="btn-container">
                <!-- <button (click)="logout()">Logout</button> -->
                <button class="btn-secondary-rect m-r-10" (click)="logout()">OK</button>
                <!-- <button class="btn-primary-rect" (click)="stay()">Yes, Keep Working</button> -->

            </kendo-dialog-actions>

        </kendo-dialog>
    </div>
</router-outlet>