import { Injectable } from '@angular/core';
import { EncryptDecrypt } from 'src/app/core/helper/encrypt-decrypt';
import { AppService } from './app.service';
import { applicationHttpClientCreator } from 'src/app/data/interface/IRequestOptions';
import { HttpClient } from '@angular/common/http';
import { URLConstants } from 'src/app/core/constants/urlConstants';
import { ToasterService } from 'src/app/data/service/toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AdbutlerService } from './adbutler.service';
import { DashboardServiceData } from 'src/app/core/constants/app-enums';
import { TokenService } from './token.service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class LoginService {

  isPatient: boolean = false;
  openRegUser: boolean = false;
  isSpecificRolesFound: boolean = false;
  services: any[] = [];
  defaultPracticeId: any;
  isOnlyBTB: boolean = false;
  staticData: any;

  constructor(
    private router: Router,
    private appService: AppService,
    private http: HttpClient,
    private translate: TranslateService,
    private toaster: ToasterService,
    private adbutlerService: AdbutlerService,
    private tokenService: TokenService) { 
      this.loadStaticData();
    }

    loadStaticData() {
      this.http.get('assets/staticData.json').subscribe((data: any) =>{
        this.staticData = data;
       });
    }

  afterLogin() {
    sessionStorage.removeItem('Services');
    let signInResponse: any = sessionStorage.getItem('TwoFactorAuth');
    signInResponse = JSON.parse(signInResponse);
    // sessionStorage.removeItem('TwoFactorAuth');
    console.log('FUserId', EncryptDecrypt.encryptUsingAES256(signInResponse.profile.UserId));

    sessionStorage.setItem('userId', EncryptDecrypt.encryptUsingAES256(signInResponse.profile.UserId));
    sessionStorage.setItem('currentUser', signInResponse.access_token);
    sessionStorage.setItem('userProfile', EncryptDecrypt.encryptUsingAES256(JSON.stringify(signInResponse.profile)));
    console.log('signInResponse.userRoles');
    console.log(signInResponse.userRoles);
    sessionStorage.setItem('userRoles', EncryptDecrypt.encryptUsingAES256(signInResponse.userRoles));
    sessionStorage.setItem('loginType', EncryptDecrypt.encryptUsingAES256(signInResponse?.loginType));
    sessionStorage.setItem('expiryTime',signInResponse?.expires_in || signInResponse?.userSessionTime);
    sessionStorage.setItem('defaultPracticeId',signInResponse?.defaultPracticeId);
    //GoogleMapAPI Key
    sessionStorage.setItem('googleMapAPI', signInResponse?.googleMapAPIKey);
    // Second  Practice Account Activation
    const userActivationStatus = signInResponse?.userActivationStatus ? EncryptDecrypt.encryptUsingAES256(JSON.stringify(signInResponse?.userActivationStatus)) : '';
    const practiceTerms = (signInResponse?.userActivationStatus && signInResponse?.userActivationStatus.length) ? signInResponse?.userActivationStatus[0].IsNewRegistered: '';
    sessionStorage.setItem('userActivationStatus', userActivationStatus);
    sessionStorage.setItem('practiceTerms', practiceTerms);

    this.appService.setUserLoggedIn(true, signInResponse?.userSessionTime);
    this.appService.setUserTimedOut(true, signInResponse?.userSessionTime);
    let notlinkedAccountDetails = JSON.stringify(signInResponse?.linkedDetailsNotConfirmed);
    if (notlinkedAccountDetails) {
      sessionStorage.setItem('notlinkedAccountDetails', notlinkedAccountDetails);
    }
    if (signInResponse?.userRoles) {
      let patientid = signInResponse.userRoles.findIndex((x: any) => x.toLowerCase() == 'patient');
      if (patientid != -1) {
        this.isPatient = true;
      }
      else {
        this.isPatient = false;
      }

      let openRegRole = signInResponse.userRoles.findIndex((x: any) => x.toLowerCase() == 'openreg');
      if (openRegRole != -1) {
        this.openRegUser = true;
      }
      else {
        this.openRegUser = false;
      }

      let isRoleFound = signInResponse.userRoles.findIndex((x: any) => x.toLowerCase() == 'patient' || x.toLowerCase() == 'mamember' || x.toLowerCase() == 'openreg' || x.toLowerCase() == 'cbtuser' ||  x.toLowerCase() == 'easybooking');
      if (isRoleFound != -1) {
        this.isSpecificRolesFound = true;
      }
      else {
        this.isSpecificRolesFound = false;
      }
      if(signInResponse.userRoles.length == 2) {
        this.isOnlyBTB = signInResponse.userRoles.findIndex((x: any) => x.toLowerCase() === 'cbtprovider' || x.toLowerCase() === 'cbtuser') !== -1;
        this.isPatient = signInResponse.userRoles.findIndex((x: any) => x.toLowerCase() === 'cbtuser') !== -1;
      } 
      

    }

    let returnURL = sessionStorage.getItem('returnURL');
    // sessionStorage.removeItem('returnURL');
    //// ------LOAD DASHBAORD SERVICE MESSAGES --------------
    this.defaultPracticeId = this.tokenService.getDefaultPracticeId();
    //this.navigateToView CURRENTLY IS INSIDE loadDashboardServices;
    // TO MAKE SURE SERVICES ARE LOADED BEFORE DASHBOARD
    this.loadDashboardServices(signInResponse);
    ///-------------------------------------------------------
    //check weather user connected to health center, if yes navigate to dashboard,else go connect health center

    this.adbutlerService.setDelayAd(true);
  }

  loadDashboardServices(signInResponse:any) {
    let hcURL = '/pages/home/' + btoa('1');
    this.services = [
      {
        labelName: 'Book an Appointment',
        icon: 'icon-cent-calendar-plus-date',
        routeUrl: '/appointment/myappointments/bookappointment',
        message: '',
        result: '',
        serviceType: DashboardServiceData.BookAppointment,
        isViewEnable: false,
        isConnectToCentre: false,
        hcURL: hcURL
      },
      {
        labelName: 'New Repeat Prescription',
        icon: 'icon-cent-prescription',
        routeUrl: '/rrp/repeat-prescriptions/new',
        message: '',
        result: '',
        serviceType: DashboardServiceData.RRP,
        isViewEnable: false,
        isConnectToCentre: false,
        hcURL: hcURL
      },
      {
        labelName: 'Health Records',
        icon: 'icon-cent-results-medical',
        routeUrl: '/myhealth/health-records/Summary',
        message: '',
        result: '',
        serviceType: DashboardServiceData.HealthRecords,
        isViewEnable: false,
        isConnectToCentre: false,
        hcURL: hcURL
      },
      {
        labelName: 'Lab Results',
        icon: 'icon-cent-lab',
        routeUrl: '/myhealth/health-records/LabResults',
        message: '',
        result: '',
        serviceType: DashboardServiceData.LabResults,
        isViewEnable: false,
        isConnectToCentre: false,
        hcURL: hcURL
      },
      {
        labelName: 'Messages',
        icon: 'icon-cent-email-outline-messaging',
        routeUrl: '/pages/navigate-routes/Inbox',
        message: '',
        result: '',
        serviceType: DashboardServiceData.ViewMessage,
        isViewEnable: false,
        isConnectToCentre: false,
        hcURL: hcURL
      },
      {
        labelName: 'My Health Centers',
        icon: 'icon-cent-practice-medical',
        routeUrl: '/pages/home/',
        message: '',
        result: '',
        serviceType: DashboardServiceData.HealthCenters,
        isViewEnable: false,
        isConnectToCentre: false,
        hcURL: hcURL
      },
      {
        labelName: 'Send Message',
        icon: 'icon-cent-email-outline-messaging',
        routeUrl: '/pages/navigate-routes/Compose',
        message: '',
        result: '',
        serviceType: DashboardServiceData.SendMessage,
        isViewEnable: false,
        isConnectToCentre: false,
        hcURL: hcURL
      }
    ];

    let index = this.services.findIndex((x: any) => x.serviceType == DashboardServiceData.HealthCenters);
    if (index != -1 && this.defaultPracticeId && this.defaultPracticeId != '') {
      console.log("Login Srvice Return URl", this.defaultPracticeId," this.defaultPracticeId",index,"index" );
      this.services[index].routeUrl = '/myhealth/my-health-centres/' + this.defaultPracticeId;
      console.log( this.services[index].routeUrl,"----");


    }
    this.getDashboardServices(signInResponse);
  }

  getDashboardServices(signInResponse:any) {
    applicationHttpClientCreator(this.http).Get(`${URLConstants.getDashboardServices}`, {
    }).subscribe((data: any) => {
      console.table(data)
      if (data && data?.length > 0) {
        for (let i = 0; i < data.length; i++) {
          data[i].Id = EncryptDecrypt.decryptUsingAES256(data[i].Id);
          data[i].ServiceName = EncryptDecrypt.decryptUsingAES256(data[i].ServiceName);
          data[i].Result = EncryptDecrypt.decryptUsingAES256(data[i].Result);
          data[i].IsViewEnable = EncryptDecrypt.decryptUsingAES256(data[i].IsViewEnable);
          data[i].IsViewEnable = data[i].IsViewEnable?.toLowerCase() == 'true';
          data[i].IsConnectToCentre = EncryptDecrypt.decryptUsingAES256(data[i].IsConnectToCentre);
          data[i].IsConnectToCentre = data[i].IsConnectToCentre?.toLowerCase() == 'true';
        }
      }

      for (let i = 0; i < this.services.length; i++) {
        let index = data?.findIndex((x: any) => x.ServiceName == this.services[i].serviceType);
        if (index != -1) {
          this.services[i].message = data[index].Message;
          this.services[i].result = data[index].Result;
          this.services[i].isViewEnable = data[index].IsViewEnable;
          this.services[i].isConnectToCentre = data[index].IsConnectToCentre;
        }
      }

      let encryptedData = EncryptDecrypt.encryptUsingAES256(JSON.stringify(this.services));
      sessionStorage.setItem('Services', encryptedData);
      this.navigateToView(signInResponse); //check weather user connected to health center, if yes navigate to dashboard,else go connect health center
    }, (err) => {
      this.toaster.error(err.error.message || err.error.Message)
    });
  }

  //check weather user connected to health center, if yes navigate to dashboard,else go connect health center
  navigateToView(signInResponse:any)
  {
    let returnURL = sessionStorage.getItem('returnURL');
    applicationHttpClientCreator(this.http).Get(`${URLConstants.getRegisteredHealthCenters}/${signInResponse.profile.UserId}`, {
    }).subscribe((data: any) => {
      if(returnURL === '/myhealth/medic-alert-members') {
        this.router.navigateByUrl(returnURL);
      } else {
        if ((data && data.length > 0) || this.isSpecificRolesFound) {
          if (signInResponse.loginType != 'Direct') {
            window.location.href = '/dashboards/dashboard';
          }
          else {
            if (returnURL)
              this.router.navigateByUrl(returnURL);
            else {
             const isBTB = sessionStorage.getItem('isBTB');
             if((isBTB?.toLowerCase() == 'true' && this.isOnlyBTB) || this.isOnlyBTB || isBTB?.toLowerCase() == 'true') {
              const isPatientRoleArray = this.staticData?.patientRole?.split(',');
              const isProviderRoleArray = this.staticData?.providerRole?.split(',');
              const isPatientRole = signInResponse.userRoles.findIndex((x: any) => 
                    isPatientRoleArray.includes(x.toLowerCase())
              ) !== -1;
              const isProviderRole = signInResponse.userRoles.findIndex((x: any) => 
                   isProviderRoleArray.includes(x.toLowerCase())
              ) !== -1;
              
              // const url = isPatientRole ? '/beating-the-blues/patient-home' : isProviderRole ? '/beating-the-blues/dashboard' : '/dashboards/dashboard';
              let url;
              if (isPatientRole) {
                  url = '/beating-the-blues/patient-home';
              } else if (isProviderRole) {
                  url = '/beating-the-blues/dashboard';
              } else {
                  url = '/dashboards/dashboard';
              }
              this.router.navigate([url]);
             } else {
              this.router.navigate(['/dashboards/dashboard']);
             }
            }
              
          }
        }
        else {
          if (signInResponse.loginType != 'Direct') {
            window.location.href = '/pages/home';
          }
          else {
            if (returnURL){
              this.router.navigateByUrl(returnURL);
            }

            else
              this.router.navigate(['/pages/home']);
          }
        }
      }

      sessionStorage.removeItem('TwoFactorAuth');
    }, (err) => {
      console.log(err);
    });

    // this.toaster.success(this.translate.instant('Login Successful'));
    if (signInResponse.loginType == 'Direct') {
      this.toaster.success(this.translate.instant('Login Successful'));
    }
  }

  getSaveLinkedAccountConfirmation() {
    return this.http.post(environment.apiBaseURL + URLConstants.saveLinkedAccountConfirmation,'');
  }
}
