<!-- <form [formGroup]="fileUploadForm"> -->
  <div class="m-b-10 show-file" [ngClass]="{'disabled' : isDisabled()}">
     <i class="icon-cent-attachment-files"></i> ADD Files
    <!-- <input [disabled]="isDisabled()" (click)="fileInput.value = null" (pan)="setIsTouch()"formControlName="uploadDocument" class="hide-file" type="file" (change)="onFileChange($event)" placeholder="file" accept="{{fileTypes}}" /> -->
    <input #fileInput [disabled]="isDisabled()" (click)="fileInput.value = null" value="" formControlName="uploadDocument" class="hide-file" type="file" placeholder="file" accept="{{fileTypes}}" (change)="onFileChange($event)" (pan)="setIsTouch()"/>
</div>

<div class="file-type-size">
    <b>Note:</b>  Please attach image with extension of any one of the following: <b>png</b> or  <b>jpg</b> or  <b>jpeg</b> or  <b>tif</b>  or <b>gif.</b>
</div>
<!-- ** BEGIN: INFORMATION MESSAGE AREA ** -->
<div *ngIf="arrFiles.length > 0" class="m-t-10">
  <div *ngFor="let file of arrFiles; let k =index" class="d-flex align-items-center">
      <span>{{file.fileName}} -</span>
      <button class="btn-circle-icon-42 cursor-pointer" (click) = "deleteFile(file, k)">
        <i class="icon-cent-delete-delete color-525252"></i>
      </button>
      <br />
  </div>
</div>
<!-- </form> -->

