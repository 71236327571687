import { HttpClient } from '@angular/common/http';
import { Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { URLConstants } from 'src/app/core/constants/urlConstants';
import { applicationHttpClientCreator } from 'src/app/data/interface/IRequestOptions';

@Component({
  selector: 'app-payment-failure-response',
  templateUrl: './payment-failure-response.component.html',
  styleUrls: ['./payment-failure-response.component.scss']
})
export class PaymentFailureResponseComponent implements OnInit {

  @Input() txnId: any;
  paymentRespose: any;

  @HostListener('document:payment-success', ['$event'])
  paymentComplete(event: any){
    this.completeCallback(event);
  }

  @HostListener('document:payment-error', ['$event'])
  paymentError(event: any){
    this.errorCallback(event);
  }
  
  @HostListener('document:payment-cancel', ['$event'])
  paymentCancel(event: any){
    this.cancelCallback();
  }

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient
  ) {      
    if (!this.txnId) {
      route.params.subscribe((val: any) => {
        this.txnId = val?.txnId;
      })
    }
   }

  ngOnInit(): void {
    console.log(this.txnId);
    this.errorPaymentResponse();
  }

  completeCallback(response: any): void {
    console.log('Payment completed');
  }

  errorCallback(error: any): void {
    console.log('Payment Error');
    console.log(error);
  }
  
  cancelCallback(): void {
    console.log('Payment cancelled');
  }

  
  errorPaymentResponse() {
    let url = URLConstants.checkPaymentResponse + this.txnId;
    applicationHttpClientCreator(this.http).Get(url, {
    }).subscribe((data: any) => {
      console.log(data);
      this.paymentRespose = data;
    }, (err: any) => {
      console.log(err);
    });
  }

}
