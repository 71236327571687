import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertType } from '../../../core/constants/app-enums';


@Component({
  selector: 'app-alert-dialogue-login',
  templateUrl: './alert-dialogue-login.component.html',
  styleUrls: ['./alert-dialogue-login.component.scss']
})
export class AlertDialogueLoginComponent implements OnInit {

  public enumAlertType = AlertType;
  constructor(
    public dialogRef: MatDialogRef<AlertDialogueLoginComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private router: Router
  ) { }

  ngOnInit(): void {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onClick(e : any){
 // this.router.navigate['/authentication/forgot'];
  console.log("click worked",e);
  console.log(e.srcElement.localName);
  if(e.srcElement.localName == "a"){
      this.router.navigate(['/authentication/forgot']);
      this.dialogRef.close();
  }
 }
 

}
