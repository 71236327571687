import { Injectable, SecurityContext } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { EncryptDecrypt } from 'src/app/core/helper/encrypt-decrypt';
import { ServiceDialogComponent } from 'src/app/shared/component/service-dialog/service-dialog.component';
import { DomSanitizer } from '@angular/platform-browser';
import { TokenService } from './token.service';
import { CommonConstants } from 'src/app/core/constants/commonConstants';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs'
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  confirmDialog: any;
  configJson: any;

  public configJsonUpdate: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(public dialog: MatDialog, private translate: TranslateService, public tokenService: TokenService, private sanitizer : DomSanitizer, public router: Router, private http:HttpClient) { this.getConfig(); }

  public getDecryptedValue(encryptedValue: any) {
    let decryptedValue = null;
    try {
      if (encryptedValue) {
        encryptedValue = encryptedValue.replace(/ /g, "%20");
        encryptedValue = encryptedValue.replace(/%20/g, "+");
        encryptedValue = decodeURIComponent(encryptedValue);
        decryptedValue = EncryptDecrypt.decryptUsingAES256(encryptedValue);
      }
    } catch (error) {
      console.log(error);
    }
    return decryptedValue;
  }

  getConfig() {
    this.http.get('assets/config.json').subscribe((data: any) =>{
      this.configJson= data;
      this.configJsonUpdate.next(data);
      console.log('configjosn', this.configJson);
    })
  }

  public getServiceDetail(serviceName: any) {
    let serviceData;
    let userRoleArr: any = this.tokenService.getUserRoles();
    let userRoleList = userRoleArr.split(',');
    let isProvider = false;
    let patientid = userRoleList.findIndex((x: any) => x.toLowerCase() == 'patient');
    if (patientid == -1) {
      let isRoleFound = userRoleList.findIndex((x: any) => x.toLowerCase() == 'mamember' || x.toLowerCase() == 'openreg' || x.toLowerCase() == 'cbtuser' ||  x.toLowerCase() == 'easybooking');
      if (isRoleFound == -1) {
        isProvider = true;
      }
    }
    if (!isProvider) {
        try {
            let encryptedData = sessionStorage.getItem('Services');
            if (encryptedData) {
              let decryptedData = JSON.parse(EncryptDecrypt.decryptUsingAES256(encryptedData));
              serviceData = decryptedData?.find((x: any) => x.serviceType == serviceName);
            }
        } catch (error) {
            console.log(error);
        }
    }  else {
        serviceData = {
            result: CommonConstants.Success_Provider
        }
    }
    return serviceData;
  }

  public openServiceDialog(serviceDetail: any) {
    if(serviceDetail?.message!='')
    {
      serviceDetail.message = this.sanitizer.sanitize(SecurityContext.HTML,  this.sanitizer.bypassSecurityTrustHtml(serviceDetail?.message));
    }

    this.confirmDialog = {
      title: 'Information',
      serviceDetail: serviceDetail,
      yesButtonText: this.translate.instant('LinkHealthCentre'),
      noButtonText: 'Cancel'
    }
    const dialogRef = this.dialog.open(ServiceDialogComponent, {
      data: this.confirmDialog,
      disableClose : true
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === true) {
      }
    });
  }

  closeServiceDialog() {
    this.dialog.closeAll();
  }

  reloadCurrentRoute(url: string) {
    let currentUrl = url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
        console.log(currentUrl);
    });
  }

  sanitizeHtml(rawHtml: string) {
    const htmlContent = this.sanitizer.bypassSecurityTrustHtml(rawHtml);
    return htmlContent;
  }
}
