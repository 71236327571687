import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss']
})
export class ReadMoreComponent implements OnInit {
  public isCollapsed:boolean = true;
  constructor() { }

  ngOnInit(): void {
  }

  readMore(){
    this.isCollapsed = false
  }
  readLess(){
    this.isCollapsed = true;
  }
}
