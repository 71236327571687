

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { URLConstants } from 'src/app/core/constants/urlConstants';
import { applicationHttpClientCreator } from 'src/app/data/interface/IRequestOptions';
import { ToasterService } from 'src/app/data/service/toaster.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-file-download',
  templateUrl: './file-download.component.html',
  styleUrls: ['./file-download.component.scss']
})
export class FileDownloadComponent implements OnInit {
  pdfURL: string;
  patientPaymentReports: any;

  constructor(public http: HttpClient, public toaster: ToasterService,private translate:TranslateService) { }
  
  @Input() url: string;
  @Input() pdfName: string;
  @Input() isPatient: boolean;
  @Input() btnText: string;  
  @Input() postObject: any;
  isPaymentButtonEnable=false;

  ngOnInit(): void {
   
  }

  onDownLoadFileForPatient = () =>
  {
    alert('downloading....');
    const headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/octet-stream',
    });
    let encodefilename = btoa(this.btnText)
    const requestOptions = { headers: headerOptions, responseType: 'blob' as 'blob' };
    this.postObject = {FileName: encodefilename}
    this.http.post(`${environment.apiBaseURL}${this.url}`, this.postObject, requestOptions).pipe(map((data: any) => {
      const blob = new Blob([data], {
        type: 'application/octet-stream'
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${this.pdfName}`;
      link.click();
      window.URL.revokeObjectURL(link.href);

    })
    ).subscribe(
      (data) => {
        console.log(data);
        this.toaster.success(this.translate.instant('File downloaded successfully'));
      },
      (err) => {
        this.toaster.warning(err.error.Message || err.error.message);
      },
    );

  }


}

