<div class="py-10 panel">

    <mat-dialog-content>
      <span class="title py-10"> {{ "Link new health centre" | translate }}</span>
      <div fxLayout="column" class="py-10">
        <div class="py-10">{{data.practiceName}} is using Manage My Health portal to provide services to their patients and encourages you to utilize it for convenient access to services and improved health management.</div>
        <div class="py-10"><span class="bold">Health Centre:</span> {{data.practiceName}}</div>
        <!-- <div class="py-10"  [innerHTML]="data?.practiceDetails ? data?.practiceDetails : ''" ><span class="bold">Address:</span></div> -->
        <div class="bold py-10" >Address: <span class="py-10 not-bold" [innerHTML]="data?.practiceDetails ? data?.practiceDetails : ''"></span> </div>
        <div class="py-10">To establish a connection between your Manage My Health account and {{data.practiceName}}, please consent to the terms and conditions and click the 'Link to Health Centre' button.</div>
        <div class="py-10">
          <mat-checkbox [(ngModel)]="accept" class="text-danger"><span class="bold f-15">I have read and accept the  </span>
        </mat-checkbox><span class="terms" (click)="openTearmsAndConditions()">Terms and Condtions</span>
      </div>
      </div>
      <mat-dialog-actions fxLayout="row" fxLayoutAlign="end end">
        <button mat-button class="btn-secondary-rect mb-10" (click)="btnClick('remindMeLater')"> {{ "REMIND ME LATER" | translate }}</button>
        <button mat-flat-button mat-dialog-close class="btn-primary-rect mb-10" (click)="btnClick('linkWithHealthCentre')" [disabled]="!accept" [ngClass]="{'btn-disabled': !accept}" > {{ "LINK TO HEALTH CENTRE" | translate }}</button>
      </mat-dialog-actions>
    </mat-dialog-content>

</div>



<kendo-dialog title="Terms & Conditions" *ngIf="showTerms" (close)="showTerms = false" [minWidth]="150" width="70%"
  class="res-sm p-line-20">
  <div class="terms-and-conditions" [innerHTML]="data.practiceTerms"></div>
  <kendo-dialog-actions>
    <button kendoButton (click)="showTerms = false" class="btn-secondary-rect" >
      Ok
    </button>
  </kendo-dialog-actions>
</kendo-dialog>
