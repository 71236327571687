import { URLConstants } from '../../core/constants/urlConstants';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { RequestService } from 'src/app/core/helper/request-service';
import { applicationHttpClientCreator } from 'src/app/data/interface/IRequestOptions';

@Injectable()
export class AlexIntegrationService {
    
    constructor(private _httpService: HttpClient, private _request: RequestService) { }

    public GetPracticeFacilityID(params: any) {
        return applicationHttpClientCreator(this._httpService).Post(`${URLConstants.GetPracticeFacilityID}`
        + '?practiceId=' + `${params.practiceId}`
          + '&locationCode=' + `${params.locationCode}` 
        , params);
    }

    public GetPatientHealthCentersWithLocations(params: any) {
      return applicationHttpClientCreator(this._httpService).Get(`${URLConstants.GetPatientHealthCentersWithLocations}`
      + '?patientuserid=' + `${params.patientuserid}`,  
      params );
    }  
    public SaveAlexRequestResponse(params: any) {
      return applicationHttpClientCreator(this._httpService).Get(`${URLConstants.SaveAlexRequestResponse}`
      + '?patientuserid=' + `${params.patientuserid}`,  
      params );
    }  
    
}