import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appRestrictFirstChar]'
})
export class RestrictFirstCharDirective {

  constructor() {}
  @HostListener('input', ['$event']) onInput(event: InputEvent) {
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    
    if (inputValue) {
      const firstChar = inputValue[0];
      if (firstChar.trim() === '' || firstChar === '0') {
        // If the first character is whitespace or '0', prevent the change
        inputElement.value = inputValue.substring(1); // Remove the first character
        event.preventDefault();
      }
    }
  }

}
