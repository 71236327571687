<div class="hide-print align-content">
<!-- <button *ngIf="isBeatUser" mat-icon-button class="m-r-20" (click)="clickOptOut()">
  Opt Out
</button> -->
<!-- <span class="desktop-view m-r-20 optOut" *ngIf="isBeatUser" (click)="clickOptOut()">Opt-Out from new UI version</span> -->
<!-- <span class="desktop-view">Developer Version</span> -->
<!-- <div fxLayout="row" fxLayoutAlign="stretch" *ngIf="isSMSBalanceVisible">
  <input class="top-menu" placeholder="SMS bal: {{currentSMSbalance}}"  readonly>
</div> -->
<button [matMenuTriggerFor]="flags" mat-icon-button class="m-r-5" matTooltip="Select language">
  <i class="flag-icon flag-icon-{{ selectedLanguage.icon }}"> </i>
</button>
<mat-menu #flags="matMenu" class="mymegamenu" >
    <button mat-menu-item *ngFor="let lang of languages" (click)="changeLanguage(lang)">
    <i class="flag-icon flag-icon-{{ lang.icon }} m-r-10"></i>
    <span>{{ lang.language }}</span>
  </button>
</mat-menu>

<button *ngIf="showBookApptIcon" mat-icon-button (click)="openDialog()" matTooltip="Book appointment">
  <img class="profile-pic bookAppointmentIcon" src="assets/images/Book appointments.svg"/>
</button>

<!-- <button [matMenuTriggerFor]="help" mat-icon-button class="m-r-5">

    <img src="../../../../assets/images/help.png">
    <div class="notify">
        <span class="heartbit"></span>
        <span class="point"></span>
    </div>
</button> -->
<mat-menu #help="matMenu" class="mymegamenu">
    <div class="mailbox">
        <ul>
            <li>
                <div class="drop-title text-muted">Help</div>
            </li>
            <li>
                <div class="message-center">
                    <perfect-scrollbar [config]="config">
                        <!-- Message -->
                        <a href="#" *ngFor="let notification of notifications">
                            <div class="round {{ notification.round }}">
                                <i class="{{ notification.icon }}"></i>
                            </div>
                            <div class="mail-content">
                                <h5>{{ notification.title }}</h5>
                                <span class="mail-desc text-muted">{{
                  notification.subject
                  }}</span>
                                <span class="time text-muted">{{ notification.time }}</span>
                            </div>
                        </a>
                    </perfect-scrollbar>
                </div>
            </li>
        </ul>
    </div>
</mat-menu>
<ng-container *ngIf="accessWidgets?.WGINBX == true">
  <button *ngIf="showMessageIcon" mat-icon-button (click)="matDrawerOpen()" class="m-r-5" matTooltip="Inbox messages">
    <!-- <mat-icon>email</mat-icon> -->
    <!-- <i class="icon-vector"></i> -->
    <img src="assets/images/messages.svg" />
    <div class="notify">
      <!-- class="heartbit" -->
      <span *ngIf="isProvider">({{mailboxServie.mailstype[0]?.count}})</span>
      <span *ngIf="!isProvider">({{mailboxServie.mailstype[0]?.count + mailboxServie.mailstype[4]?.count}})</span>
      <span class="point"></span>
    </div>
  </button>
</ng-container>


<!-- <mat-drawer-container> -->
<!-- <div class="page-content"> -->
  <!-- <mat-drawer #drawer class="slide-tray content-panel messages k-slide-tray" mode="side" position="end">
    <div>
      <div class="" *ngIf="
        mailboxServie.selectedMailBoxType === 'Detail' ||
        mailboxServie.selectedMailBoxType === 'DraftDetail' ||
        mailboxServie.selectedMailBoxType === 'ArchiveDetail'
      ">
            <div class="slide-tray-header">
                <h5>Messaging</h5>
                <div class="images">
                    <button mat-button (click)="setSelectedMailType('Compose')" class="messaging-btns">
            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M16.7351 16V2H2.73511V16H16.7351ZM16.7351 0C17.2655 0 17.7742 0.210714 18.1493 0.585786C18.5244 0.960859 18.7351 1.46957 18.7351 2V16C18.7351 17.11 17.8351 18 16.7351 18H2.73511C2.20467 18 1.69597 17.7893 1.32089 17.4142C0.945821 17.0391 0.735107 16.5304 0.735107 16V2C0.735107 1.46957 0.945821 0.960859 1.32089 0.585786C1.69597 0.210714 2.20467 0 2.73511 0H16.7351ZM14.4351 6.35L13.4351 7.35L11.3851 5.3L12.3851 4.3C12.5951 4.08 12.9451 4.08 13.1551 4.3L14.4351 5.58C14.6551 5.79 14.6551 6.14 14.4351 6.35ZM4.73511 11.94L10.7951 5.88L12.8551 7.94L6.79511 14H4.73511V11.94Z"
                fill="#121212" fill-opacity="0.38" style="mix-blend-mode: multiply" />
              <path
                d="M16.7351 16V2H2.73511V16H16.7351ZM16.7351 0C17.2655 0 17.7742 0.210714 18.1493 0.585786C18.5244 0.960859 18.7351 1.46957 18.7351 2V16C18.7351 17.11 17.8351 18 16.7351 18H2.73511C2.20467 18 1.69597 17.7893 1.32089 17.4142C0.945821 17.0391 0.735107 16.5304 0.735107 16V2C0.735107 1.46957 0.945821 0.960859 1.32089 0.585786C1.69597 0.210714 2.20467 0 2.73511 0H16.7351ZM14.4351 6.35L13.4351 7.35L11.3851 5.3L12.3851 4.3C12.5951 4.08 12.9451 4.08 13.1551 4.3L14.4351 5.58C14.6551 5.79 14.6551 6.14 14.4351 6.35ZM4.73511 11.94L10.7951 5.88L12.8551 7.94L6.79511 14H4.73511V11.94Z"
                fill="black" fill-opacity="0.38" style="mix-blend-mode: multiply" />
            </svg>
          </button>

                    <button mat-button [matMenuTriggerFor]="menuheader" class="messaging-btns">
            <svg width="5" height="16" viewBox="0 0 5 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2.36755 4C3.46755 4 4.36755 3.1 4.36755 2C4.36755 0.9 3.46755 0 2.36755 0C1.26755 0 0.367554 0.9 0.367554 2C0.367554 3.1 1.26755 4 2.36755 4ZM2.36755 6C1.26755 6 0.367554 6.9 0.367554 8C0.367554 9.1 1.26755 10 2.36755 10C3.46755 10 4.36755 9.1 4.36755 8C4.36755 6.9 3.46755 6 2.36755 6ZM2.36755 12C1.26755 12 0.367554 12.9 0.367554 14C0.367554 15.1 1.26755 16 2.36755 16C3.46755 16 4.36755 15.1 4.36755 14C4.36755 12.9 3.46755 12 2.36755 12Z"
                fill="#121212" fill-opacity="0.38" style="mix-blend-mode: multiply" />
              <path
                d="M2.36755 4C3.46755 4 4.36755 3.1 4.36755 2C4.36755 0.9 3.46755 0 2.36755 0C1.26755 0 0.367554 0.9 0.367554 2C0.367554 3.1 1.26755 4 2.36755 4ZM2.36755 6C1.26755 6 0.367554 6.9 0.367554 8C0.367554 9.1 1.26755 10 2.36755 10C3.46755 10 4.36755 9.1 4.36755 8C4.36755 6.9 3.46755 6 2.36755 6ZM2.36755 12C1.26755 12 0.367554 12.9 0.367554 14C0.367554 15.1 1.26755 16 2.36755 16C3.46755 16 4.36755 15.1 4.36755 14C4.36755 12.9 3.46755 12 2.36755 12Z"
                fill="black" fill-opacity="0.38" style="mix-blend-mode: multiply" />
            </svg>
          </button>
                    <mat-menu #menuheader="matMenu" class="menu-screen">

                        <button mat-menu-item (click)="changeAction(); drawer.toggle()">
              <mat-icon>settings</mat-icon>
              <span>Settings</span>
            </button>
                    </mat-menu>

                    <button mat-button (click)="close(); drawer.toggle()" class="messaging-btns">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                fill="#525252" />
            </svg>
          </button>

                </div>
            </div>

            <div class="" *ngIf="mailboxServie.selectedMailBoxType === 'Detail'">
              <router-outlet name="detail"></router-outlet>
          </div>

            <div class="" *ngIf="mailboxServie.selectedMailBoxType === 'ArchiveDetail'">
              <router-outlet name="archivedetail"></router-outlet>
          </div>

          <div class="" *ngIf="mailboxServie.selectedMailBoxType === 'DraftDetail'">
              <router-outlet name="draftdetail"></router-outlet>
          </div>
        </div>
        <div class="mat-overlay-bg"></div>
        </div>
      </mat-drawer> -->
<!-- </div> -->

<!-- ============================================================== -->
<!-- Notification - style you can find in header.scss -->
<!-- ============================================================== -->
<button (click)="notification.toggle()" mat-icon-button class="m-r-5" *ngIf="isVisible">
  <!-- <mat-icon>message</mat-icon> -->
  <img src="assets/images/notification.svg" />
  <div class="notify">
    <span class="heartbit"></span>
    <span class="point"></span>
  </div>
</button>
<mat-drawer #notification class="slide-tray content-panel k-slide-tray" mode="side" position="end">
    <div class="slide-tray-header">
        <h5>Notifications</h5>
        <div class="images">
            <button mat-button class="messaging-btns" matTooltip="Compose">
        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16.7351 16V2H2.73511V16H16.7351ZM16.7351 0C17.2655 0 17.7742 0.210714 18.1493 0.585786C18.5244 0.960859 18.7351 1.46957 18.7351 2V16C18.7351 17.11 17.8351 18 16.7351 18H2.73511C2.20467 18 1.69597 17.7893 1.32089 17.4142C0.945821 17.0391 0.735107 16.5304 0.735107 16V2C0.735107 1.46957 0.945821 0.960859 1.32089 0.585786C1.69597 0.210714 2.20467 0 2.73511 0H16.7351ZM14.4351 6.35L13.4351 7.35L11.3851 5.3L12.3851 4.3C12.5951 4.08 12.9451 4.08 13.1551 4.3L14.4351 5.58C14.6551 5.79 14.6551 6.14 14.4351 6.35ZM4.73511 11.94L10.7951 5.88L12.8551 7.94L6.79511 14H4.73511V11.94Z"
            fill="#121212" fill-opacity="0.38" style="mix-blend-mode: multiply" />
          <path
            d="M16.7351 16V2H2.73511V16H16.7351ZM16.7351 0C17.2655 0 17.7742 0.210714 18.1493 0.585786C18.5244 0.960859 18.7351 1.46957 18.7351 2V16C18.7351 17.11 17.8351 18 16.7351 18H2.73511C2.20467 18 1.69597 17.7893 1.32089 17.4142C0.945821 17.0391 0.735107 16.5304 0.735107 16V2C0.735107 1.46957 0.945821 0.960859 1.32089 0.585786C1.69597 0.210714 2.20467 0 2.73511 0H16.7351ZM14.4351 6.35L13.4351 7.35L11.3851 5.3L12.3851 4.3C12.5951 4.08 12.9451 4.08 13.1551 4.3L14.4351 5.58C14.6551 5.79 14.6551 6.14 14.4351 6.35ZM4.73511 11.94L10.7951 5.88L12.8551 7.94L6.79511 14H4.73511V11.94Z"
            fill="black" fill-opacity="0.38" style="mix-blend-mode: multiply" />
        </svg>
      </button>

            <button mat-button [matMenuTriggerFor]="menuheader" matTooltip="More" class="messaging-btns">
        <svg width="5" height="16" viewBox="0 0 5 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2.36755 4C3.46755 4 4.36755 3.1 4.36755 2C4.36755 0.9 3.46755 0 2.36755 0C1.26755 0 0.367554 0.9 0.367554 2C0.367554 3.1 1.26755 4 2.36755 4ZM2.36755 6C1.26755 6 0.367554 6.9 0.367554 8C0.367554 9.1 1.26755 10 2.36755 10C3.46755 10 4.36755 9.1 4.36755 8C4.36755 6.9 3.46755 6 2.36755 6ZM2.36755 12C1.26755 12 0.367554 12.9 0.367554 14C0.367554 15.1 1.26755 16 2.36755 16C3.46755 16 4.36755 15.1 4.36755 14C4.36755 12.9 3.46755 12 2.36755 12Z"
            fill="#121212" fill-opacity="0.38" style="mix-blend-mode: multiply" />
          <path
            d="M2.36755 4C3.46755 4 4.36755 3.1 4.36755 2C4.36755 0.9 3.46755 0 2.36755 0C1.26755 0 0.367554 0.9 0.367554 2C0.367554 3.1 1.26755 4 2.36755 4ZM2.36755 6C1.26755 6 0.367554 6.9 0.367554 8C0.367554 9.1 1.26755 10 2.36755 10C3.46755 10 4.36755 9.1 4.36755 8C4.36755 6.9 3.46755 6 2.36755 6ZM2.36755 12C1.26755 12 0.367554 12.9 0.367554 14C0.367554 15.1 1.26755 16 2.36755 16C3.46755 16 4.36755 15.1 4.36755 14C4.36755 12.9 3.46755 12 2.36755 12Z"
            fill="black" fill-opacity="0.38" style="mix-blend-mode: multiply" />
        </svg>
      </button>

            <mat-menu #menuheader="matMenu">
                <button mat-menu-item>
          <mat-icon>archive</mat-icon>
          <span>Advanced Search</span>
        </button>
                <button mat-menu-item disabled>
          <mat-icon>visibility</mat-icon>
          <span>Lorem ipsum</span>
        </button>
            </mat-menu>

            <button mat-button (click)="notification.toggle()" class="messaging-btns" matTooltip="Close">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
            fill="#525252" />
        </svg>
      </button>

            <!-- <app-compose></app-compose> -->
        </div>
    </div>

    <!-- *ngIf="mailboxServie.selectedMailBoxType==='Inbox'||mailboxServie.selectedMailBoxType==='Sent'||mailboxServie.selectedMailBoxType==='Drafts'||mailboxServie.selectedMailBoxType==='Archive'||mailboxServie.selectedMailBoxType==='Group Messages'" -->

    <div class="notification-panel-content">
        <div class="notify-none">
            <img src="assets/images/notifications.svg" />

            <div class="no-notify-content">
                <h5 class="font-d-6">You have no notifications</h5>
                <p class="font-b-1 m-t-10">
                    As soon as we have something to report, you’ll find it here.
                </p>
            </div>
        </div>

        <div class="notification-content-wrp">
            <div class="notification-group">
                <h6 class="notif-title">Today</h6>

                <div class="notify-inner-wrp">
                    <div class="notify-new">
                        <div class="notify-avathar">
                            <i class="icon-cent-account-circle-account"></i>
                        </div>
                        <div class="content-new">
                            You’ve successfully activated your account and accessed to your Medical Records.
                        </div>
                        <div class="notify-info">
                            <span>New</span>
                        </div>
                    </div>

                    <div class="notify-list-items">
                        <ul>
                            <li>
                                <div class="notify-list-item">
                                    <div class="notify-li-avathar">
                                        <i class="icon-cent-account-circle-account"></i>
                                    </div>

                                    <div class="notify-list-item-content">
                                        Prescription is ready to be collected from Zoom Pharmacy.
                                        <a href="#" class="notify-link-text">View</a>
                                    </div>

                                    <div class="notify-view-icon read">
                                        <i class="icon-cent-eye-sort"></i>
                                    </div>
                                </div>
                            </li>

                            <li>
                                <div class="notify-list-item">
                                    <div class="notify-li-avathar">
                                        <img src="https://media.istockphoto.com/photos/head-shot-woman-doctor-talking-online-with-patient-video-call-picture-id1214631460?k=20&m=1214631460&s=612x612&w=0&h=6AmQcmRUVg-j8hP6sg8mxMC5sgQ7dSRBA46Padbfjko=" />
                                    </div>

                                    <div class="notify-list-item-content">
                                        Hi Sophia, a quick reminder that I would like to see you in 1 week.
                                        <a href="#" class="notify-link-text">Check my Appointment</a>
                                    </div>

                                    <div class="notify-view-icon">
                                        <i class="icon-cent-eye-sort"></i>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="notify-break"></div>

            <div class="notification-group">
                <h6 class="notif-title">Yesterday</h6>

                <div class="notify-inner-wrp">
                    <div class="notify-list-items">
                        <ul>
                            <li>
                                <div class="notify-list-item">
                                    <div class="notify-li-avathar">
                                        <i class="icon-cent-account-circle-account"></i>
                                    </div>

                                    <div class="notify-list-item-content">
                                        Prescription is ready to be collected from Zoom Pharmacy.
                                        <a href="#" class="notify-link-text">View</a>
                                    </div>

                                    <div class="notify-view-icon">
                                        <i class="icon-cent-eye-sort"></i>
                                    </div>
                                </div>
                            </li>

                            <li>
                                <div class="notify-list-item">
                                    <div class="notify-li-avathar">
                                        <img src="https://media.istockphoto.com/photos/head-shot-woman-doctor-talking-online-with-patient-video-call-picture-id1214631460?k=20&m=1214631460&s=612x612&w=0&h=6AmQcmRUVg-j8hP6sg8mxMC5sgQ7dSRBA46Padbfjko=" />
                                    </div>

                                    <div class="notify-list-item-content">
                                        Hi Sophia, a quick reminder that I would like to see you in 1 week.
                                        <a href="#" class="notify-link-text">Check my Appointment</a>
                                    </div>

                                    <div class="notify-view-icon">
                                        <i class="icon-cent-eye-sort"></i>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="mat-overlay-bg"></div>
</mat-drawer>
<!-- <mat-menu #notification="matMenu" class="mymegamenu">
  <div class="mailbox">
    <ul>
      <li>
        <div class="drop-title text-muted">Notifications</div>
      </li>
      <li>
        <div class="message-center">
          <perfect-scrollbar [config]="config">

            <a href="#" *ngFor="let notification of notifications">
              <div class="round {{notification.round}}">
                <i class="{{notification.icon}}"></i>
              </div>
              <div class="mail-content">
                <h5>{{notification.title}}</h5>
                <span class="mail-desc text-muted">{{notification.subject}}</span>
                <span class="time text-muted">{{notification.time}}</span>
              </div>
            </a>

          </perfect-scrollbar>
        </div>
      </li>

    </ul>
  </div>
</mat-menu> -->

<!-- ============================================================== -->
<!-- Calender icon -->
<!-- ============================================================== -->
<!-- <button mat-icon-button (click)="calenderDrawerOpen()" class="m-r-5" matTooltip="Calender">
  <img src="assets/images/calendar.svg" />
</button> -->

<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->

<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->

<button [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5" matTooltip="Profile" *ngIf="userId!=null">
    <img class="profile-pic img-fluid" [src]="profileImgURL !=='' ? profileImgURL  : 'assets/images/nophoto_unknown.gif'"  onError="this.src='assets/images/nophoto_unknown.gif'">
</button>

<mat-menu #profile="matMenu" class="mymegamenu user-details">
  <div (click)="hideinbox()">
    <p class="p-l-20 m-b-0 font-16">{{ profileName?.PrefferedName }}</p>
    <p class="p-l-20 m-t-5 font-14">{{ profileName?.UserNameDis }}</p>
    <hr />
    <button mat-menu-item [matMenuTriggerFor]="isLinkedAccChild ? userprofilechild : userprofile">
    <img src="assets/images/profile.svg" /> Profile
  </button>
    <button *ngIf="isPatient" mat-menu-item (click)="navigateEmergencyContacts('Ecview')">
    <img src="assets/images/family-members.svg" />Emergency Contacts
  </button>
    <!-- <hr />
    <p class="text-center">
        <a (click)="navigateEmergencyContacts('Ecadd')">ADD Family Members</a>
    </p>
    <hr />
    <button mat-menu-item>
    <img src="assets/images/practical.svg" />Practice
  </button>
    <button mat-menu-item><img src="assets/images/billing.svg" />Billing</button>-->
    <button mat-menu-item [routerLink]="['/myaccount/two-step-verification']">
    <img src="assets/images/security.svg" />Security
  </button>
    <!-- <button mat-menu-item [routerLink]="['/pages/navigate-routes/Settings']" >
    <img src="assets/images/settings.svg" />Settings
  </button> -->

    <hr />
    <button mat-menu-item (click)="logout()" *ngIf="userId!=null">
    <img src="assets/images/signout.svg" /> Sign Out
  </button>
</div>
</mat-menu>



<mat-menu #userprofile="matMenu" class="sub-menu-list-header">
  <div (click)="hideinbox()">
    <button mat-menu-item [routerLink]="['/myaccount/change-password']">
    Change Password
  </button>
    <button mat-menu-item [routerLink]="['/myaccount/change-email']" >
    Change Email Address
  </button>
    <button mat-menu-item [routerLink]="['/myaccount/change-address']" >
    Change Address
  </button>
    <button mat-menu-item [routerLink]="['/myaccount/change-phone-number']">
    Change Phone Number
  </button>
    <button *ngIf="!isProvider" mat-menu-item [routerLink]="['/payment/my-payment']">
      My Payments
    </button>
    <!-- <button *ngIf="isProvider" mat-menu-item [routerLink]="['payment/payment-report']">
     Payment Report
    </button> -->
    <button mat-menu-item [routerLink]="['/myaccount/profile']">
    Update Profile
  </button>


  

    <button mat-menu-item [routerLink]="['/myaccount/calendar']">Calendar</button>
    <!-- <button mat-menu-item [routerLink]="['/pages/organisation']">Manage organisation</button> -->
     
    <button *ngIf="isPatient" mat-menu-item [routerLink]="['/myaccount/close-account']">
      Close Account
    </button>
  </div>
</mat-menu>


<mat-menu #userprofilechild="matMenu" class="sub-menu-list-header">
  <div (click)="hideinbox()">
  <button mat-menu-item [routerLink]="['/myaccount/profile']">
      <!-- View Profile -->
      Update Profile
  </button>
  <button mat-menu-item [routerLink]="['/myaccount/change-address']">
      Change Address
  </button>
  <button mat-menu-item [routerLink]="['/myaccount/change-phone-number']">
      Change Phone Number
  </button>
  <button *ngIf="!isProvider" mat-menu-item [routerLink]="['/payment/my-payment']">
    My Payments
  </button>
  <!-- <button *ngIf="isProvider" mat-menu-item [routerLink]="['payment/payment-report']">
   Payment Report
  </button> -->
  <button mat-menu-item [routerLink]="['/myaccount/calendar']">Calendar</button>
</div>
</mat-menu>
<!-- ============================================================== -->
<!-- sign-out -->
<!-- ============================================================== -->


<button mat-icon-button class="desktop-view sign-out-btnalign" *ngIf="userId!=null" (click)="logout()" matTooltip="Sign out">
  <span>SIGN OUT</span>
    <!-- <mat-icon>exit_to_app</mat-icon> -->
    <!-- <img src="assets/images/signout.svg" /> -->
</button>

<button mat-icon-button class="m-r-5 mobile-view" *ngIf="userId!=null" (click)="logout()" matTooltip="Sign out">
    <mat-icon>exit_to_app</mat-icon>
    <!-- <img src="assets/images/signout.svg" /> -->
</button>


<!---Feedback -->
<!-- <div class="btn-feedback">
    <a (click)="openFeedBackForm()">feedback</a>
</div> -->

<!--Kendo Dialog Box-->
<!-- <div class="feedback-dialog" *ngIf="opened==true" >
    <kendo-dialog title="Please enter your feedback" *ngIf="opened==true" (close)="opened=false" [minWidth]="250" [width]="450">
        <p>
            This form should only be used to discuss matters of a technical nature. If you have any questions relating to the use of this website or experience any technical issues, please contact us using the form below.
        </p>
        <p>If your question or enquiry is related to your health or your health records, please contact your doctor or medical centre directly.</p>
        <mat-form-field appearance="outline" class="has-success">
            <mat-label>Select Category</mat-label>
            <mat-select [(ngModel)]="selCategory">
                <mat-option value="Website feedback">Website feedback</mat-option>
                <mat-option value="Suggestions">Suggestions</mat-option>
            </mat-select>
            <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
        </mat-form-field>
        <mat-form-field appearance="outline" class="has-success">
            <mat-label>Feedback</mat-label>
            <textarea [(ngModel)]="userValue" matInput></textarea>
        </mat-form-field>

        <kendo-dialog-actions>
            <button mat-button class="btn btn-secondary" (click)="opened=false">Close</button>
            <button mat-button class="btn btn-primary" [disabled]="userValue==''" (click)="submitFeedBackForm()" primary="true">Send Feedback</button>
        </kendo-dialog-actions>
    </kendo-dialog>
</div> -->

<p-dialog *ngIf="displayMaximizable" [closeOnEscape]="false" header="Video Call" [(visible)]="displayMaximizable" [modal]="false"
[style]="{'width': '20vw', 'height': '30vh', 'bottom': '3px', 'right': '4px', 'position': 'absolute'}"
[maximizable]="true" [draggable]="false" [resizable]="false" styleClass="dialogvideo"
(onShow)="showDialogMaximized(dialDetail)" #dialDetail>
    <div class="mat-container">
        <app-mmh-v2-videocall [dialog]="dialDetail"></app-mmh-v2-videocall>
        <div class="dialog-footer">
        </div>
    </div>
</p-dialog>

<button (click)="onClickMe()" class="btn-audioPlay" style="display: none;"></button>
<p-dialog *ngIf="isCalling" [closeOnEscape]="false" header="Calling" [position]="'bottom-right'" styleClass="pdialogClass"
  [(visible)]="isCalling" [modal]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="space-evenly center">
    <div fxFlex="25" fxFlex.gt-lg="10" fxFlex.lt-lg="25" fxFlex.lt-md="10" fxFlex.xs="15" fxFlex.gt-md="25"
      fxLayoutAlign="space-around center" class="image">
      <img class="profile-pic-calleralert" src="{{providerProfilePicture}}" alt=" ">
    </div>
    <div fxFlex="55" fxFlex.gt-lg="55" fxFlex.lt-lg="30" fxFlex.lt-md="50" fxFlex.xs="25" fxFlex.gt-md="55"
      fxLayout="column" class="name-ringing">
      <div fxFlex="12.5" fxFlex.gt-lg="27.5" fxFlex.lt-lg="15" fxFlex.lt-md="25" fxFlex.xs="12.5" fxFlex.gt-md="27.5"
        class="providername"> {{providerName}}
      </div>
      <div fxFlex="12.5" fxFlex.gt-lg="27.5" fxFlex.lt-lg="15" fxFlex.lt-md="25" fxFlex.xs="12.5" fxFlex.gt-md="27.5"
        class="ringing-text">
        {{'Ringing...'|translate}}
      </div>
    </div>
    <div fxFlex="15" fxFlex.gt-lg="10" fxFlex.lt-lg="25" fxFlex.lt-md="10" fxFlex.xs="15" fxFlex.gt-md="20"
      class="accept-decline decline-call" fxLayoutAlign="space-around center">
      <img title="{{'Decline'|translate}}" (click)="sendDeclinedNotification(' declined your call.')"
        src="assets/Videoimages/decline-call.svg" alt="declinecall">
    </div>
    <div fxFlex="15" fxFlex.gt-lg="10" fxFlex.lt-lg="25" fxFlex.lt-md="10" fxFlex.xs="15" fxFlex.gt-md="25"
      class="accept-decline" fxLayoutAlign="space-around center">
      <img title="{{'Accept'|translate}}" (click)="onClickAccept()" class="accept-call"
        src="assets/Videoimages/accept-call.svg" alt="acceptall">
    </div>

  </div>

  <audio *ngIf="playAudio" controls autoplay loop style="display: none;">
    <source [src]="ringTone" type="audio/mpeg">
  </audio>
</p-dialog>

</div>


