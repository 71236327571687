import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'localDate',
})
export class LocalDatePipe implements PipeTransform {
  transform(date: any, format?: string): any {
    format = format ? format : 'd/MM/yyyy h:mm a';
    let returnDate: any = new Date();
    if (date) {
      console.log('UTC Date ===========> ' + date);
      returnDate = moment(moment.utc(date)).tz('Australia/Sydney');
      if (format) {
        returnDate = returnDate.format(format);
      }
    }
    return returnDate;
  }
}
