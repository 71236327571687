<!-- ------------------------------ header desktop view --------------------------------- -->
<div class="desktop-view-1024 m-b-20">
  <div class="filter-by-index page-header header-buttons m-b-sm-0 p-lt-xs-20">
      <h1>{{'Group Messages'| translate}}</h1>
      <div class="icon-container justify-content-end">
        <mat-form-field appearance="outline" class="search-text-icon searchbox-group has-success">
          <mat-label>{{'Search Messages'|translate}}</mat-label>
          <input matInput placeholder="{{'Search Messages'|translate}}" [(ngModel)]="searchTexts"
              name="searchTexts" (change)="Searchkey(searchTexts)" style="height:26px !important">
          <button mat-button  mat-icon-button *ngIf="searchTexts">
            <i class="icon-cent-search-popular icon-subfld" matSuffix></i>
        </button>
         <mat-icon class="icon-subfld"  *ngIf="searchTexts" class="clear-icon-close"
              (click)="clearSearchData(searchTexts)" >close</mat-icon>
      </mat-form-field>
          <button mat-button (click)="openCompose()" class="btn-primary-rect m-l-15" style="top:2px">
            <i class="icon-cent-pencil-box-outline-text icon-color-grid"></i>
            <span class="m-r-10">{{'Compose'|translate}}</span>
        </button>
        <!-- <span><img src="../../../../assets/images/icons-text/settings.svg" (click)="opensettings()" style="position: relative;top:0px;left:8px;" matTooltip="Message settings"></span> -->
      </div>
    </div>
</div>

<!-- ------------------------------header mobile-view----------------------------------->
<div class="mobile-view-1024">
  <div class="filter-by-index page-header header-buttons m-b-sm-0 p-lt-xs-20">
    <h1>{{'Group Messages'| translate}}</h1>
  <div class="icon-container">
      <button (click)="openCompose()" mat-button class="mobile-view-1024 txt-color"><i class="icon-cent-pencil-box-outline-text"></i>COMPOSE</button>
  </div>
</div>
<div class="icon-container">
  <mat-form-field appearance="outline" class="search-text-icon p-lt-xs-20 searchbox-mobile-group has-success">
      <mat-label>{{'Search Messages'|translate}}</mat-label>
      <input matInput placeholder="{{'Search Messages'|translate}}" [(ngModel)]="searchTexts"
          name="searchTexts" (change)="Searchkey(searchTexts)" style="height:26px !important">
      <button mat-button *ngIf="searchTexts" matSuffix mat-icon-button aria-label="Clear" class="clear-icon-close"
          (click)="clearSearchData(searchTexts)">
          <mat-icon class="icon-subfld1">close</mat-icon>
      </button>
      <i class="icon-cent-search-popular icon-subfld1 search-icon-close-mobile" *ngIf="searchTexts" matSuffix></i>
  </mat-form-field>
</div>
</div>

<!-- ------------------------------Messages list view------------------------------------>

<div class="bg-white" *ngIf="mailboxServie.groupMessages.length == 0 && !isLoading">
  <div fxLayout="column wrap" fxLayoutAlign="center center" class="no-msg">
      <div class="text-center">
          <img src="assets/images/message123.svg" alt="mail-box">
          <p>Sorry! No messages found.</p>
      </div>
  </div>
</div>

<div fxlayout="row wrap"  class="bg-white group-flexbox" *ngIf="mailboxServie.groupMessages.length > 0">
  <div fxflex.lg="40" fxflex.gt-lg="30" fxflex.sm="100" fxflex="100" class="b-r flexbox1-group">

    <!-- <div *ngIf="nomessages" class="text-center d-md-none d-flex align-items-center justify-content-center detail-part movetodetail nomessage-top">
      <div class="desktop-view-1024">
          <div class="connect-health-centre text-center">
              <img src="assets/images/Appointments.svg" alt="" />
              <h3>Start talking with doctors</h3>
              <button mat-button class="btn-primary-pill-l-icon" routerLink="/pages/navigate-routes/Compose" [disabled]="healthCenterList?.length == 0">
                  <i class="icon-cent-pencil-box-outline-text icon-color-grid"></i>
                  ComposeMessage</button>
          </div>
      </div>
    </div> -->

    <div class="emailInbox k-slide-tray"  *ngIf="mailboxServie.groupMessages.length > 0">
    <div class="list-wrapper">
      <form [formGroup]="checkboxForm">
        <div class="mails-list-wrapper group-listview-desktop" infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()"
          [scrollWindow]="false">
          <div class="mails-list-wrapper">
          <div class="mail-list-content">
            <div class="messages">
              <div class="emailInbox">
          <mat-card class="m-0" *ngFor="let i=index;let group of mailboxServie.groupMessages">
            <div [ngClass]="{'mail-selected': SelectedMail === i}">
            <mat-card-content class="mat-card-spacer drafts-avatar">
            <mat-card-header (click)="setSelectedMailType(group.ID,i)">
              <div mat-card-avatar class="mat-card-avatar drafts-avatar m-t-6">
                <img [src]="profileObject.PhotoURL !=='' ? profileObject.PhotoURL  : 'assets/images/nophoto_unknown.gif'"
                onError="this.src='assets/images/nophoto_unregistered.svg'" alt="" class="img-fluid img-circle">
              </div>
              <div class="desktop-view-1024">
                <mat-card-title *ngIf='group.isread' class="header-text header-sub header-width">{{group.PracticeName}} </mat-card-title>
                <mat-card-title *ngIf="!group.isread" class="header-text-unread header-sub header-width" >{{group.PracticeName}} </mat-card-title>
                <mat-card-title class="m-t-8">
                  <span *ngIf="group.isread" class="header-text-sub">{{group.Subject}}</span>
                  <span
                      *ngIf="!group.isread" class="header-text-read-sub"><b>{{group.Subject}}</b></span>
              </mat-card-title>
              </div>

              <div class="mobile-view-1024">
                <mat-card-title *ngIf='group.isread' class="header-text text-width" >{{group.PracticeName}} </mat-card-title>
                <mat-card-title *ngIf="!group.isread" class="header-text-unread text-width">{{group.PracticeName}} </mat-card-title>
                <mat-card-title class="m-t-8 subject-width">
                  <span *ngIf="group.isread"  class="header-text-sub">{{group.Subject}}</span>
                  <span
                      *ngIf="!group.isread" class="header-text-read-sub"><b>{{group.Subject}}</b></span>
              </mat-card-title>
              </div>
              <p class="date-time m-t-2">{{group.InsertOn | date: 'dd/MM/yyyy  h:mm a'}}</p>
              <button class="date-time m-t-27" (click)="$event.stopPropagation()" matTooltip="Delete message" mat-icon-button (click)="deleteGroupMessage(group.ID)">
                <mat-icon>delete_outline</mat-icon>
              </button>
            </mat-card-header>

          </mat-card-content>
        </div>
          </mat-card>
          </div>
          </div>
          </div>
        </div>


      </div>
      </form>
    </div>
    </div>

  </div>


  <div fxflex.lg="60" fxflex.gt-lg="70" fxflex.sm="100" fxflex="100"
    class="desktop-view-1024 d-flex align-items-center justify-content-center detail-part movetodetail flexbox2-group" >
    <div  *ngIf="groupmessage==false && mailboxServie.groupMessages.length>0" class="desktop-view-1024 text-center d-md-none no-selected-msg messageImage">
      <!-- <mat-icon  role="img" class="mat-icon notranslate display-5 m-r-15 material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">mail_outline</mat-icon> -->
      <img src="assets/images/message123.svg" alt="mail-box">
      <p>Select an item to read</p>
      
    </div>

  <!-- <div *ngIf="mailboxServie.groupMessages.length==0" class="desktop-view-1024 text-center d-md-none no-msg">
    <img src="../../../../assets/images/message123.svg" alt="mail-box">
    <p>Sorry! No new messages found.</p>
  </div> -->


  <!------------------------ Selected Group Message Details Desktop-view --------------------------->
  <div *ngIf="groupmessage == true " class="desktop-view-1024 w-100">
    <perfect-scrollbar class="perfect-scrollbar-group"   [config]="config">
  <!-- <div *ngIf="selectedMessageDetails" > -->
    <div class="email-view-content" style="height: calc(100vh - 240px);">
      <div class="mail-content-wrapper">
        <div class="mail-info-sub">
            <div class="grid-normal-tbl">
              <div class="tbl-row">
                  <div class="tbl-th">{{'From'|translate}}:</div>
                  <div class="tbl-td">{{sentselectedMessagesDetails[0]?.FromName}}</div>
              </div>
              <div class="tbl-row">
                  <div class="tbl-th">{{'Date'|translate}}:</div>
                  <div class="tbl-td">{{sentselectedMessagesDetails[0]?.Date | date: 'dd MMM yyyy hh:mm:ss a'}}</div>
              </div>
              <div class="tbl-row">
                  <div class="tbl-th">{{'Subject'|translate}}:</div>
                  <div class="tbl-td">{{sentselectedMessagesDetails[0]?.Subject}}</div>
              </div>
          </div>
            <div>

              <div class="attachments-header">
                <div *ngIf="filesData && filesData.length >=1;then file else nofile">here is ignored</div>
                <ng-template #file>
                  <div class="attachments-list" *ngFor="let data of filesData">
                    <mat-icon class="type-icon" style="color: black;">attach_file</mat-icon>
                        <span class="attachments-list" (click)="downloadFiles(data?.FileName,data?.OriginalFileName)">
                            {{data?.OriginalFileName}}
                        </span>
                  </div>
                </ng-template>
                <ng-template #nofile>
                  <div class="attachments-list" *ngIf="filesData">
                    <mat-icon class="type-icon" style="color: black;">attach_file</mat-icon>
                      <span class="attachments-list" (click)="downloadFiles(filesData?.FileName,filesData?.OriginalFileName)">
                          {{filesData?.OriginalFileName}}
                      </span>
                  </div>
                </ng-template>
              </div>

        <div class="filter-by-index page-header header-buttons m-b-sm-0 p-lt-xs-20" style="padding:0px;">
          <div class="from-details"></div>
        <div class="icon-container" fxLayout="row" >
          <button (click)="deleteGroupMessage(mailboxServie.messageId)" matTooltip="Delete message" mat-button class="btn-primary-rect m-l-15">
            <mat-icon>delete_outline</mat-icon>
            <span class="m-l-5">{{'DELETE'|translate}}</span>
          </button>
      </div>
      </div>


        <div class="mail-spacer-line"></div>
        <div class="subject-email-content w-100 desktop-email-content ">
          <div [innerHTML]="sentselectedMessagesDetails[0]?.MessageBody"></div>
        </div>
      </div>
    </div>
  </div>
  </div>
  </perfect-scrollbar>
  </div>


  <!------------------------ Selected Group Message Details Mobile-view --------------------------->
  <div *ngIf="selectedMessageDetails && !displayNewWindow" style="width:100% !important;right:0 !important;z-index: 5 !important;" class="mobile-view-1640 reply-message-content group-mobile full-width">
      <div class="view-mail-header close-btn-align">
        <button mat-button class="view-mail-close" (click)="close()">

          <span class="cls-txt">{{'Close'|translate}}</span>
                <!-- <i class="icon-cent-chevron-right-chevrons arrow-rightmail"></i> -->
                <i class="icon-cent-close-cancel"></i>
        </button>
      </div>
<div style="overflow-y: scroll; height: 100%; padding-bottom: 100px;">
  <div class="email-view-content">
    <div class="mail-content-wrapper">
      <div class="mail-info-sub">
        <div class="grid-normal-tbl">
          <div class="tbl-row">
              <div class="tbl-th">{{'From'|translate}}:</div>
              <div class="tbl-td">{{sentselectedMessagesDetails[0]?.FromName}}</div>
          </div>
          <div class="tbl-row">
              <div class="tbl-th">{{'Date'|translate}}:</div>
              <div class="tbl-td">{{sentselectedMessagesDetails[0]?.Date | date: 'dd MMM yyyy hh:mm:ss a'}}</div>
          </div>
          <div class="tbl-row">
              <div class="tbl-th">{{'Subject'|translate}}:</div>
              <div class="tbl-td">{{sentselectedMessagesDetails[0]?.Subject}}</div>
          </div>
        </div>
      </div>
      <div class="attachments-header">
        <div *ngIf="filesData && filesData.length >=1;then file else nofile">here is ignored</div>
        <ng-template #file>
          <div class="attachments-list" *ngFor="let data of filesData">
            <mat-icon class="type-icon"  style="color: black;">attach_file</mat-icon>
                <span class="attachments-list" (click)="downloadFiles(data?.FileName,data?.OriginalFileName)">
                    {{data?.OriginalFileName}}
                </span>
          </div>
        </ng-template>
        <ng-template #nofile>
          <div class="attachments-list" *ngIf="filesData">
            <mat-icon class="type-icon"  style="color: black;">attach_file</mat-icon>
              <span class="attachments-list" (click)="downloadFiles(filesData?.FileName,filesData?.OriginalFileName)">
                  {{filesData?.OriginalFileName}}
              </span>
          </div>
        </ng-template>
      </div>

      <div class="filter-by-index page-header header-buttons m-b-sm-0 p-lt-xs-20" style="padding:0px;">
        <div class="from-details"></div>
        <div class="icon-container">
          <a (click)="deleteGroupMessage(mailboxServie.messageId)"  matTooltip="Delete">
            <img src= 'assets/images/Group 5043 (1).svg' alt="Delete">
          </a>
        </div>
      </div>
      <div class="mail-spacer-line"></div>
      <div style="max-height: calc(100vh - 320px); min-height: calc(50vh - 120px); padding: 3px;">
        <div class="subject-email-content">
          <div [innerHTML]="sentselectedMessagesDetails[0]?.MessageBody"></div>
        </div>
      </div>
    </div>
  </div>
</div>
    </div>
  </div>
</div>
<!-- <div class="mb-flo-wrp compose-btn-float mobile-view-1024">
  <button mat-button class="btn-circle" matTooltip="Attach Document" aria-label="Attach Document" (click)="openCompose()"> -->
      <!-- <i class="icon-cent-add-plus-add icon-white"></i> -->
      <!-- <i class="icon-cent-pencil-box-outline-text icon-color-grid color-bl-icon"></i>
  </button>
</div>
<div class="mb-flo-wrp settings-btn-float mobile-view-1024">
<span><img src="../../../../assets/images/icons-text/hoversettings.svg" (click)="opensettings()"></span>
</div> -->
