import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-appointment-terms-and-conditions',
  templateUrl: './appointment-terms-and-conditions.component.html',
  styleUrls: ['./appointment-terms-and-conditions.component.scss']
})
export class AppointmentTermsAndConditionsComponent implements OnInit {
  @ViewChild('iframeElement') iframeElement: ElementRef<HTMLIFrameElement>;
  formattedContent: any;
   constructor(public dialogRef: MatDialogRef<AppointmentTermsAndConditionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private router: Router) {
      console.log("Terms Dialog", data);
      this.formattedContent = data.terms.replace(/\n/g, '<br>');
     }

  ngOnInit(): void {
  }

  onClose(): void {
    this.dialogRef.close();
  }

  adjustIframeHeight() {
    // Calculate the content height and set the iframe height accordingly
    const iframe: HTMLIFrameElement = this.iframeElement.nativeElement;
    if (iframe.contentDocument) {
      const contentBody = iframe.contentDocument.body;
      contentBody.style.overflow = 'hidden';
      const contentHeight = iframe.contentDocument.body.scrollHeight+30;
      iframe.style.height = contentHeight + 'px';
    }
  }

  onConfirm() {
    this.dialogRef.close(true);
  }
  onReject() {
    this.dialogRef.close(false);
  }
}
