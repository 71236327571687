<div class="desktop-view filter-by-index page-header m-b-sm-0">
  <h1 class="view-info">
    <i class="icon-cent-chevron-left-arrows color-dark cursor-pointer" (click)="backToInbox()"></i>
    {{ "Compose Email" | translate }}
    <i class="icon-cent-inform-alerts cursor-pointer" (click)="showHelpIcon = !showHelpIcon"></i>
  </h1>
</div>

<div class="mobile-view page-header header-buttons m-b-20 m-t-20 m-l-10 m-b-sm-0 mobile-view">
  <h1 class="d-flex align-items-center">
    <i class="icon-cent-chevron-left-arrows color-dark cursor-pointer" (click)="backToInbox()">
    </i>
    {{ "Compose Email" | translate }}
    <i class="icon-cent-inform-alerts" (click)="showHelpIcon = !showHelpIcon"></i>
  </h1>
</div>


<div class="alert alert-info m-15">
  <b>{{'Information'|translate}}</b>
  <p>
    By utilizing this service, you have the ability to send secure messages directly to your clinician, which allows for
    easy communication and exchange of information through Manage My Health. However, it is important to note that your
    health centre may have specific terms and conditions regarding message recipients, which may limit who you can send
    messages to.
  </p>
</div>


<div class="desktop-view-1024 text-center d-md-none nomessage-top" style="padding-top:40px;" *ngIf="nomessages">
  <div class="connect-health-centre text-center">
    <img src="assets/images/Appointments.svg" alt="" />
    <h3>Start talking with doctors</h3>
    <p>
      Connect a Health Centre to book <br /> and view your first appointment.
    </p>
    <button mat-button class="btn-primary-pill-l-icon" [routerLink]="['/pages/home']" (click)="hideinbox()"
      [disabled]="healthCenterList?.length >=1">

      <i class="icon-cent-lan-connect-network"></i>Connect a Health Centre
      <!-- <i class="icon-cent-pencil-box-outline-text icon-color-grid"></i>Compose Message -->
    </button>
    <p class="m-b-0">
      <a (click)="activationCode()" (click)="hideinbox()">I have an activation code</a>
    </p>
  </div>
</div>

<div class="mobile-view-1024 text-center" style="padding-top:40px;" *ngIf="nomessages">
  <div class="mobile-view connect-health-centre text-center">
    <img src="assets/images/Appointments.svg" alt="" />
    <h3>Start talking with doctors</h3>
    <p>
      Connect a Health Centre to book <br /> and view your first appointment.
    </p>
    <button mat-button class="btn-primary-pill-l-icon" [routerLink]="['/pages/home']" (click)="hideinbox()"
      [disabled]="healthCenterList?.length >=1">

      <i class="icon-cent-lan-connect-network"></i>Connect a Health Centre
      <!-- <i class="icon-cent-pencil-box-outline-text icon-color-grid"></i>Compose Message -->
    </button>
    <p class="m-b-0">
      <a (click)="activationCode()" (click)="hideinbox()">I have an activation code</a>
    </p>
  </div>
</div>

<div *ngIf="!nomessages">
  <div class="slide-tray-content send-message" *ngIf="isLoadComplete">
    <form [formGroup]="composeForm">
      <mat-card class="radius-10 h-overflow">
       
        <mat-card-content>
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.gt-sm="20px">


            <div fxFlex.gt-sm="50" fxFlex="100">
              <!-- Health Center -->
              <mat-form-field appearance="outline" class="icon-right" *ngIf="healthCenterList.length >= 1"
                [ngClass]="{ 'has-success': f.healthCenter.valid }">
                <mat-label> {{ "Health Centre" | translate }}</mat-label>
                <mat-select formControlName="healthCenter" (selectionChange)="getLocations()">
                  <mat-option *ngFor="let health of healthCenterList" [value]="health.PracticeManagerId">
                    <!-- (click)="getLocations(health)" -->
                    <img src="assets/images/Practice-center.png" alt="" />
                    {{ health.BusinessName }}
                  </mat-option>
                </mat-select>
                <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
              </mat-form-field>
            </div>


            <div fxFlex.gt-sm="50" fxFlex="100">
              <!-- Location -->
              <mat-form-field appearance="outline" class="icon-right" *ngIf="locations.length > 0"
                [ngClass]="{ 'has-success': f.location.valid }" class="icon-right">
                <mat-label> {{ "Location" | translate }}</mat-label>
                <mat-select #locationMatSelect formControlName="location" (selectionChange)="getServiceTypes()">
                  <mat-option *ngFor="let location of locations" [value]="location.LocationId">
                    <!-- (click)="getServiceTypes(location.LocationId)" -->
                    {{ location.LocationName }}
                  </mat-option>
                </mat-select>
                <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
              </mat-form-field>
            </div>
          </div>

          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.gt-sm="20px">


            <div fxFlex.gt-sm="50" fxFlex="100">
              <!-- Service Name -->
              <mat-form-field appearance="outline" class="icon-right" *ngIf="serviceTypes.length > 0"
                [ngClass]="{ 'has-success': f.serviceName.valid }">
                <mat-label> {{ "Service Name" | translate }}</mat-label>
                <!-- (click)="checkingIsRRP(service)" -->
                <mat-select formControlName="serviceName" #serviceName (selectionChange)="checkingIsRRP($event)">
                  <mat-option *ngFor="let service of serviceTypes" [value]="service.MessageTypeID">
                    <!-- (click)="subjectBinding(service)" -->
                    <!-- (change)="checkingIsRRP(service.MessageTypeID)" -->
                    {{ service.MessageCode }}
                  </mat-option>
                </mat-select>
                <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
              </mat-form-field>

              <div class="alert alert-info m-t-10 m-b-10" *ngIf="serviceTypes.length == 0">
                <mat-label> {{ "Service Name" | translate }}</mat-label>
                <p>
                  <span style="display:flex;margin-top: 5px;">
                    <mat-icon>warning</mat-icon>
                    {{"Your health centre is not configured for this service.please contact your health centre."|
                    translate}}
                  </span>
                </p>
              </div>

            </div>


            <div fxFlex.gt-sm="50" fxFlex="100">
              <div *ngIf="
                serviceTypelocationDetails[0]?.RoleId ==
                '00000000-0000-0000-0000-000000000000'
            ">
                <!-- Role -->
                <mat-form-field appearance="outline" class="icon-right" *ngIf="Roles.length > 0"
                  [ngClass]="{ 'has-success': f.role.valid }">
                  <mat-label style="vertical-align: middle;"> {{ "Role" | translate }}</mat-label>
                  <mat-select formControlName="role" (selectionChange)="getToUserDetails('change')" [disabled]="serviceTypes.length == 0">
                    <mat-option *ngFor="let role of Roles" [value]="role.RoleId">
                      <!-- (click)="getToUserDetails(role.RoleId,'change')" -->
                      {{ role.Description }}
                    </mat-option>
                  </mat-select>
                  <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                </mat-form-field>

              </div>
            </div>
          </div>

          <!--To User-->
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.gt-sm="20px"
            *ngIf="serviceTypelocationDetails[0]?.recipientid == '00000000-0000-0000-0000-000000000000'">
            <div fxFlex.gt-sm="50" fxFlex="100">
              <mat-form-field appearance="outline" class="icon-right" [ngClass]="{ 'has-success': f.toUser.valid }"
                *ngIf="selectedRole[0]?.RoleName != 'Patient'">
                <mat-label class="required"> {{ "To" | translate }}</mat-label>
                <mat-select formControlName="toUser" (selectionChange)="checkProviderAvailability()" [disabled]="serviceTypes.length == 0">
                  <!-- <mat-option>--Select--</mat-option> -->
                  <!-- (click)="checkProviderAvailability(to)" -->
                  <mat-option *ngFor="let to of toUser" [value]="to.UserID">
                    {{ to.UserName }}
                  </mat-option>
                </mat-select>
                <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
              </mat-form-field>
            </div>
            <div fxFlex.gt-sm="50" fxFlex="100">
              <div class="note-area"><b>{{'Note : ' | translate}}</b>{{'ComposeDoctorNote' | translate}}</div>
            </div>
          </div>


          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.gt-sm="20px"
            *ngIf="serviceTypelocationDetails[0]?.RoleId!=='00000000-0000-0000-0000-000000000000'&&serviceTypelocationDetails[0]?.isExtCommService != 1&&selectedRole[0]?.RoleName != 'Patient'">
            <div fxFlex.gt-sm="50" fxFlex="100">
              <mat-form-field appearance="outline" class="icon-right disabled-value"
                [ngClass]="{ 'has-success': f.toUser.valid}"
                *ngIf="serviceTypelocationDetails[0]?.recipientid!=='00000000-0000-0000-0000-000000000000'" >
                <mat-label class="required"> {{'To'|translate}}</mat-label>
                <input matInput type="text" readonly formControlName="toUserDefault" placeholder="Subject">
                <mat-icon class="error" *ngIf="f.toUser.invalid && f.toUser.touched">
                  info_outline</mat-icon>

              </mat-form-field>
              <!-- <mat-form-field appearance="outline" class="icon-right" [ngClass]="{ 'has-success': f.toUser.valid}"
                *ngIf="serviceTypelocationDetails[0]?.recipientid=='00000000-0000-0000-0000-000000000000'">
                <mat-label class="required"> {{'To'|translate}}</mat-label>
                <mat-select formControlName="toUser">
                    <mat-option value="">--Select--</mat-option>
                </mat-select>
                <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
            </mat-form-field> -->
            </div>
          </div>
          <div *ngIf="selectedRole[0]?.RoleName == 'Patient'">
            <div fxLayout="row wrap" fxLayoutAlign="center center">
              <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" class="auto-suggest"
                [ngClass]="{ 'has-value': f.toUser.valid}">
                <mat-label class="auto-complete-lable required">
                  {{"To"|translate}}</mat-label>
                <kendo-autocomplete [(ngModel)]="UserSearch" [data]="patientList" [filterable]="true"
                  (keyup.backspace)="backspaceCheck($event)" (valueChange)=selectedName($event) formControlName="toUser"
                  (filterChange)="handleFilter($event)" [valueField]="'names'"
                  [placeholder]="'Enter Patient first name'">
                </kendo-autocomplete>
              </div>
            </div>
          </div>

          <!-- Subject -->
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.gt-sm="20px">
            <div fxFlex.gt-sm="100" fxFlex="100">
              <mat-form-field appearance="outline" class="has-success">
                <mat-label class="required">
                  {{ "Subject" | translate }}</mat-label>
                <input matInput type="text" formControlName="subject" placeholder="Subject" />
                <mat-icon class="error" *ngIf="f.subject.invalid && f.subject.touched">
                  info_outline</mat-icon>
              </mat-form-field>
              <mat-error *ngIf="f.subject.hasError('required') && f.subject.touched"
                class="text-danger support-text error-name">{{ "Required" | translate }}</mat-error>
            </div>
          </div>
          <!-- <div> -->
          <!-- Terms and conditions checkbox -->
          <!-- <mat-checkbox class="agree-checkbox" color="warn" formControlName="termsconditions">
              {{ "I agree to the" | translate
                }}<span class="checkbox-link help-info">
                  {{ "terms & conditions" | translate }}
                  <div class="help-tooltip-container compose-tc-postion">
                    <h3>{{ "Terms & Conditions" | translate }}</h3>
                    <div class="tooltip-content">
                      <p class="hover-index">
                        {{selectedServiceTypeDataterms}}
                      </p>
                    </div>
                  </div>
                </span>
              </mat-checkbox> -->

          <div class="checkbox-inflex">
            <!-- <mat-checkbox formControlName="termsconditions" ></mat-checkbox> -->
            <mat-checkbox formControlName="termsconditions" (click)="acceptCheckBox($event)"></mat-checkbox>
            <span> {{ "I agree to the" | translate }} <a class="terms" (click)="showTerms = true">{{ "terms &
                conditions" | translate }}
              </a>
            </span>
          </div>

          <!-- </span> -->

          <mat-error *ngIf="
              f.termsconditions.hasError('required') &&
              f.termsconditions.touched
            " class="m-t-10 text-danger support-text error-name">{{ "privacy" | translate }}</mat-error>
          <!-- </div> -->

          <!-- Message Body (Kendo Editor) -->
          <div class="kendo-msg-editor">
            <mat-label class="required">
              <b>{{ "Write your message" | translate }}</b>
            </mat-label>

            <div class="save-indicator auto-drft-align fonts-16">{{ saveIndicator }}</div>
            <!-- <span *ngIf="!alive">error</span> -->

            <kendo-editor #kendoEditor formControlName="message" [iframe]="false" (valueChange)="valueChange($event)"
              class="kendo-editor-box line-height-kendo-editor" (paste)="handlePaste($event)"></kendo-editor>

            <mat-error *ngIf="f.message.hasError('required') && f.message.touched"
              class="text-danger support-text error-name">{{ "Required" | translate }}</mat-error>
          </div>

          <!-- Attach Files -->
          <!-- <div class="m-b-20 m-t-20 attach-file">
            <button mat-button class="btn-primary-rect" *ngIf="addedFiles.length < 3" (click)="attachFileDialog()">
              <mat-icon class="icon-line-height">attachment</mat-icon> {{ "Attach Files" | translate }}
            </button>
            <div class="documents">
              <p *ngFor="let file of addedFiles; let i = index">
                {{ file?.name }}
                <mat-icon (click)="remove(i)">delete</mat-icon>
              </p>
              <mat-error *ngIf="addedFiles.length > 3">{{
                "filelength validation" | translate
                }}</mat-error>
            </div>
          </div> -->

          <div class="m-b-20 m-t-10 attach-file">
            <button mat-button class="btn-primary-rect" *ngIf="addedFiles.length < 3" (click)="attachFileDialog()">
              <mat-icon class="icon-line-height">attachment</mat-icon> {{ isBPPms ? "Attach Files*" : "Attach Files"| translate }}
            </button>
            <div class="filter-by-index page-header m-b-0 m-b-sm-0 compose-atch-file"
              style="padding: 0px;cursor: context-menu;" *ngFor="let file of addedFiles; let i = index">
              <p>{{ file?.name }}</p>
              <div class="icon-container m-l-10" style="margin-top: 8px;">
                <mat-icon (click)="remove(i)">delete_outline</mat-icon>
              </div>
              <!-- <mat-error *ngIf="addedFiles.length > 3">{{ "filelength validation" | translate }}</mat-error> -->
            </div>
            <div>
              <mat-error *ngIf="addedFiles.length > 3">{{ "filelength validation" | translate }}</mat-error>
            </div>
          </div>
          <!------------------- Buttons for save as draft and send as message ------------------------>
          <!-- <div class="slide-tray-footer desktop-view">
            <div class="footer-buttons  m-t-20">
              <button mat-button class="btn-secondary-pill-gray m-r-10 desktop-footer-btn"
                [disabled]="addedFiles.length > 3" (click)="sendMesssage('Drafts')">
                <span  class="icon-text-footer-align"><mat-icon class="m-r-10 icon-line-height">save</mat-icon>
                  {{ "Save as Draft" | translate }}</span>
              </button>
              <button mat-button class="btn-primary-pill" (click)="sendMesssage('Inbox')"
                [disabled]="composeForm.invalid || addedFiles.length > 3">
                <span class="icon-text-footer-align"><mat-icon class="icon-line-height m-r-10">send</mat-icon> {{ "Send message" | translate }}</span>
              </button>
            </div>
          </div>

          <div class="email-view-footer mobile-view mbl-foot-btn">
            <div class="footer-buttons ftr-sm-2-row-btns">

                <button mat-button class="btn-secondary-pill-gray m-r-10"  [disabled]="addedFiles.length > 3" (click)="sendMesssage('Drafts')">
                    <span class="icon-text-footer-align"><mat-icon class="m-r-10">save</mat-icon> {{'Save as Draft'|translate}}</span></button>

                <button mat-button class="btn-primary-pill" [disabled]="composeForm.invalid || addedFiles.length > 3" (click)="sendMesssage('Inbox')" type="submit">
                  <span class="icon-text-footer-align" ><mat-icon class="m-r-10">send</mat-icon>
                    {{'Send message'|translate}}</span></button>
            </div>
        </div> -->
          <div class="email-view-footers m-t-20 desktop-view-1024" style="padding:0px;">
            <div class="footer-button m-t-20">
              <button mat-button class="btn-secondary-pill-gray m-r-10" style="white-space: initial;line-height: 18px;"
                [disabled]="addedFiles.length > 3" (click)="closeSelectedMail()" (click)="sendMesssage('Drafts')">
                {{'Save as Draft'|translate}}</button>

              <button mat-button (click)="closeSelectedMail()" class="btn-primary-pill" (click)="sendMesssage('Inbox')"
                [disabled]="composeForm.invalid || addedFiles.length > 3 || messageBoxValue.length==0 || staffOutOfOffice || disableSubmit"
                routerLink="/pages/navigate-routes/Inbox">
                {{'Send message'|translate}}</button>

              <div class="fee-details"
                *ngIf="serviceTypelocationDetails[0]?.IsBillable && serviceTypelocationDetails[0]?.BillableCost">Fee :
                ${{serviceTypelocationDetails[0]?.BillableCost?.toFixed(2)}} (Incl. GST)</div>
            </div>
          </div>

          <div class="email-view-footer mobile-view-1024 mbl-foot-btn">
            <div class="footer-buttons ftr-sm-2-row-btns"
              style="display: flex;align-items: center;justify-content: center;">
              <button mat-button class="btn-secondary-pill-gray m-r-10" [disabled]="addedFiles.length > 3"
                (click)="sendMesssage('Drafts')">
                <span class="icon-text-footer-align">
                  {{'Save as Draft'|translate}}</span></button>

              <button mat-button class="btn-primary-pill" (click)="sendMesssage('Inbox')"
                [disabled]="composeForm.invalid || addedFiles.length > 3 || messageBoxValue.length==0 || staffOutOfOffice || disableSubmit"
                routerLink="/pages/navigate-routes/Inbox">
                <span class="icon-text-footer-align">
                  {{'Send message'|translate}}</span></button>
              <div class="fee-details"
                *ngIf="serviceTypelocationDetails[0]?.IsBillable && serviceTypelocationDetails[0]?.BillableCost">Fee :
                ${{serviceTypelocationDetails[0]?.BillableCost?.toFixed(2)}} (Incl. GST)</div>
            </div>
          </div>

        </mat-card-content>
      </mat-card>
    </form>
  </div>
</div>
<!--------------------------------------------- Help Icon Dialog ----------------------------------------->
<kendo-dialog title="More Info - Compose Email" *ngIf="showHelpIcon" (close)="showHelpIcon = false" [minWidth]="250"
  [width]="500" class="res-sm p-line-20">
  <p class="terms-and-conditions">
    <span *ngIf="selectedServiceTypeData[0]?.Cost == 0.0"><b>Cost </b>: Charges may apply</span>
  </p>
  <p>
    <span *ngIf="selectedServiceTypeData[0]?.Cost > 0.0"><b>Cost :</b> $ {{ selectedServiceTypeData[0]?.Cost }}
      (incl.
      GST); {{ selectedServiceTypeData[0]?.PaymentOption }}</span>
  </p>
  <p class="terms-and-conditions">

    <b>Service Name </b>: {{ selectedServiceTypeDataMessageDescription }}
  </p>
  <p class="terms-and-conditions">
    <b>Terms and Conditions </b>: {{
    selectedServiceTypeDataterms
    }}
  </p>
  <kendo-dialog-actions>
    <button kendoButton (click)="showHelpIcon = false" class="btn-secondary-rect">
      {{ "Close" | translate }}
    </button>
  </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog *ngIf="showTerms" (close)="showTerms = false" [minWidth]="250" [width]="500"
  class="res-sm p-line-20">
  <div class="p-t-30">
    <div *ngIf="showInformation">
      <b class="font-20">Information</b>
      <p>
        {{serviceTypelocationDetails[0]?.MessageDescription}}
      </p>
      <p>Service Cost: ${{serviceTypelocationDetails[0]?.BillableCost?.toFixed(2)}}</p>
      <p>Payment Method: Pay at Health Center</p>
    </div>
    <b class="font-20">Terms & Conditions</b>
    <p class="terms-and-conditions">
      {{selectedServiceTypeDataterms}}
    </p>
  </div>
  <kendo-dialog-actions>
    <button kendoButton (click)="agreeTerms()" class="btn-primary-rect">
      I AGREE
    </button>
    <button kendoButton (click)="cancelTerms()" class="btn-secondary-rect">
      CANCEL
    </button>
    <!-- <button kendoButton (click)="showTerms = false" class="btn-secondary-rect">
      Ok
    </button> -->
  </kendo-dialog-actions>
</kendo-dialog>

<!-- <kendo-dialog title="Information" *ngIf="showInformation" (close)="showInformation = false" [minWidth]="250"
  [width]="500" class="res-sm p-line-20">

  <p class="terms-and-conditions">
    Please note that the service you have chosen incurs a fee of
    ${{serviceTypelocationDetails[0]?.BillableCost?.toFixed(2)}}. If you agree to proceed with the chargeable service,
    please acknowledge your acceptance.
  </p>
  <kendo-dialog-actions>
    <button mat-button (click)="acknowledge()" class="btn-primary-rect">
      ACKNOWLEDGE
    </button>
  </kendo-dialog-actions>
</kendo-dialog> -->
