import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { number } from 'ngx-custom-validators/src/app/number/validator';
import { URLConstants } from 'src/app/core/constants/urlConstants';
import { FileToUpload } from 'src/app/shared/component/file-upload/file-upload.component';
// import { FileToUpload } from 'src/app/shared/component/fileupload/fileupload.component';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  private uploaded_files_url: string = 'https://jsonplaceholder.typicode.com/';
  // Move to archive call for inbox and sent box
  constructor(private http: HttpClient) {}

  uploadFile(theFile: FileToUpload): Observable<any> {
    let fileObject = {
      DocumentId: 100,
      DocuemtntName: 'doc1',
      DocumentValidtill: '2021-09-02T11:47:55.646Z',
      Description: 'desc',
      FileName: 'fname',
      Originalfilename: 'orgname',
      DocumentTypeId: 1,
      SharetoDoctor: true,
      SharetoCareteam: true,
      CreatedUserName: 'hari',
      IsActive: true,
      UserProfileId: 33456,
      PatientId: '00000000-0000-0000-0000-000000000000',
      CreatedBy: '00000000-0000-0000-0000-000000000000',
      Source: 'test',
      UserId: '00000000-0000-0000-0000-000000000000',
      files: theFile,
    };

    const API_URL =
      'https://mmhv2.api.covalense.net:8080/api/HealthDocuments/SaveAttachedDocumentDetails';
    return this.http.post<FileToUpload>(API_URL, fileObject);
  }

  getLibrarys() {
    console.log('GoalsService > getGoals called');
    let headers = new HttpHeaders();
    headers.append('Content-Type', 'application/json');
    let req = new HttpRequest('GET', `${this.uploaded_files_url}posts`, {
      headers: headers,
      responseType: 'json',
      reportProgress: false,
    });
    return this.http.request(req);
  }
}
