import { Directive, ElementRef, HostListener, Optional, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { URLConstants } from 'src/app/core/constants/urlConstants';
import { applicationHttpClientCreator } from 'src/app/data/interface/IRequestOptions';

@Directive({
  selector: '[appMobileNumber]'
})
export class MobileNumberDirective {



  textbox: HTMLInputElement;

  constructor(
    private http: HttpClient,
    private el: ElementRef,
    private renderer: Renderer2,
    @Optional() private ngControl: NgControl | null,
  ) { }

  ngAfterViewInit() {
    this.textbox =
      this.el.nativeElement.tagName === "INPUT"
        ? this.el.nativeElement
        : this.el.nativeElement.querySelector("input");
  }

  @HostListener("blur", ["$event"]) onBlur(event: any) {
    setTimeout(() => {


    event.stopPropagation();
    let initVal = this.textbox.value //.replace(/-|\s/g, '').trim();
    const countryCode = "(+64)"
    if(initVal == countryCode){
      initVal=""
      this.renderer.setProperty(this.textbox, "value", "");
    }
    else if (initVal != "" && initVal != countryCode) {
      let phoneno= initVal.replace( /[\(|\)]/g,'')
      applicationHttpClientCreator(this.http).Get(`${URLConstants.getFormattedMobileNumber}?inputNumber=`+encodeURIComponent(phoneno), {
      }).subscribe((data: any) => {
        if(data==""){
          // this.renderer.setProperty(this.textbox, "invalid", true);
          this.ngControl?.control!.setErrors({
            invalid:true
          })
        }
        else{
          this.ngControl?.control!.setErrors(null)
          initVal = data;
          initVal=initVal.replace(/\+64/g,'(+64)')
          this.renderer.setProperty(this.textbox, "value", initVal);
        }
      }, (err: any) => {
        console.log(err);
      });
    }
    if (this.ngControl) {
      this.ngControl.control!.setValue(initVal, { emitEvent: false });
    } else {

      this.renderer.setProperty(this.textbox, "value", initVal);
    }
  }, 1000);
  }
  @HostListener("focus", ["$event"]) onFocus(event: any) {
    let initVal = this.textbox.value
    const countryCode = "(+64)"
    this.ngControl?.control!.markAsUntouched({onlySelf:true})
    if (initVal.length == 0) {
      initVal = countryCode
    }
    if (this.ngControl) {
      this.ngControl.control!.setValue(initVal, { emitEvent: false });
    } else {

      this.renderer.setProperty(this.textbox, "value", initVal);
    }
  }
}
