import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng-lts/dialog';
import { OverlayPanelModule } from 'primeng-lts/overlaypanel';
import { PanelMenuModule } from 'primeng-lts/panelmenu';
import { SidebarModule } from 'primeng-lts/sidebar';
import {ChartModule } from 'primeng-lts/chart';
import {AccordionModule} from 'primeng-lts/accordion';
import {FileUploadModule} from 'primeng-lts/fileupload';

const primengModules = [
  DialogModule,
  ChartModule,
  OverlayPanelModule,
  PanelMenuModule,
  SidebarModule,
  AccordionModule,
  FileUploadModule
];


@NgModule({
  declarations: [],
  exports: primengModules,
  imports: [
    CommonModule,
    primengModules
  ]
})
export class PrimengModule { }
