import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject  } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  private userLoggedIn = new Subject<boolean>();
  private userTimedIn = new Subject<boolean>();
  private idlePopUpStatus = new Subject<boolean>();
  private VonageSesionStatus = new Subject<boolean>();

  private messageSource = new BehaviorSubject('default message');
  currentMessage = this.messageSource.asObservable();

  private customSubject = new Subject<any>();
  customObservable = this.customSubject.asObservable();

  private customModelStateSubject = new Subject<any>();
  customModelStateObservable = this.customModelStateSubject.asObservable();

  private customPreScreenClosedSubject = new Subject<any>();
  customPreScreenClosedObservable = this.customPreScreenClosedSubject.asObservable();

  private stopTimeoutIdle = new Subject<any>();
  private listenToStopIdleTimeout = this.stopTimeoutIdle.asObservable();

  private exitVimeoFullscreenEvent = new Subject<any>();
  private listenToExitVimeoFullscreen = this.exitVimeoFullscreenEvent.asObservable();

  private browserBackOrForwardBtnClick: boolean;

  initiateExitVimeoFullScreen(exitFullscreen: boolean) {
    this.exitVimeoFullscreenEvent.next(exitFullscreen);
  }

  listenToExitVimeoFullscreenEvent() {
    return this.listenToExitVimeoFullscreen;
  }

  initiateStopIdleTimeout(stop: boolean) {
    this.stopTimeoutIdle.next(stop);
  }

  listenToStopIdleTimeoutEvent() {
   return this.listenToStopIdleTimeout;
  }

  // Service message commands
  callVonagePopupClosedMethod(value:boolean) {
    this.customSubject.next(value);
  }
  // Service for Model state error exceptions.
  callModelErrorStateMethod(value:boolean) {
    this.customModelStateSubject.next(value);
  }

  //Prescreening --closed the dialog
  callPrescreeningClosedMethod(value:boolean) {
    this.customPreScreenClosedSubject.next(value);
  }
  constructor() {
    this.userLoggedIn.next(false);    
    this.userTimedIn.next(false);
    this.idlePopUpStatus.next(false); 
    this.VonageSesionStatus.next(true); 
  }

  changeMessage(message: string) {
    this.messageSource.next(message)
  }

  setUserLoggedIn(userLoggedIn: boolean, time: number) {
    this.userLoggedIn.next(userLoggedIn);
  }

  getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }

  setUserTimedOut(userTimedIn: boolean, time: number)
  {
    this.userTimedIn.next(userTimedIn);
  }

  setUserTimedIn(): Observable<boolean> {
    return this.userTimedIn.asObservable();
  }

  //Idle popup status
  setIdlePopUpStatus(idlePopUpStatus: boolean, time: number) {
    this.idlePopUpStatus.next(idlePopUpStatus);
  }

  getIdlePopUpStatus(): Observable<boolean> {
    return this.idlePopUpStatus.asObservable();
  }

  //Vonage Session timed out
  setVonageSesionStatus(sessionStatus: boolean) {
    this.VonageSesionStatus.next(sessionStatus);
  }

  getVonageSesionStatus(): Observable<boolean> {
    return this.VonageSesionStatus.asObservable();
  }

  setBackClicked(clicked: boolean) {
    this.browserBackOrForwardBtnClick = clicked;
  }

  getBackClicked() {
    return this.browserBackOrForwardBtnClick;
  }

}
