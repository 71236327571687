import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
/**
 * loader service
 * toggle loader gif in website
 */
export class LoaderService {
  error(Message: any) {
    throw new Error('Method not implemented.');
  }
  success(arg0: any) {
    throw new Error('Method not implemented.');
  }
  public status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public Alexstatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public scrollBs : BehaviorSubject<string> = new BehaviorSubject<string>('');
  show() {
    this.status.next(true);
  }

  hide() {
    this.status.next(false);
  }
  Alexshow() {
    this.Alexstatus.next(true);
  }

  Alexhide() {
    this.Alexstatus.next(false);
  }
  scrollPage(data : string){
    this.scrollBs.next(data);
  }
}
