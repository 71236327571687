import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FileUploadService } from 'src/app/data/service/file-upload.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  @Input() type: number; // 0 is single, 1 is multiple
  @Input() maxSize = 5242880;
  @Input() fileTypes = 'gif,jpg,png,jpeg,tif,pdf,doc,docx';
  // @Input() arrFilesFromParent: [];
  @Output() uploadFile = new EventEmitter();
  @Output() isTouched = new EventEmitter();
  @Input() showFiles: boolean = true;
  @Input() disabled: boolean = false;

  theFile: any = null;
  messages: string[] = [];
  theFiles: any[] = [];
  arrFiles: FileToUpload[] = [];
  size = '';
  isValid: boolean = true;
  validateMsg: string = '';

  // fileUploadForm: FormGroup;
  constructor(public fileUploadService: FileUploadService, public fb: FormBuilder) { }

  ngOnChanges = () => {
    alert('Show');
  }

  ngOnInit(): void {

    // this.fileUploadForm = this.fb.group({
    //   uploadDocument: ['', Validators.required],
    // });
    if (this.maxSize > 0) {
      this.size = (this.maxSize / 1048576).toFixed(2);
    }
  }

  setIsTouch = () => {
    //this.theFile = null;
    this.isTouched.emit(true);
  }

  isDisabled = () => {
    if (this.disabled || (this.type === 0 && this.arrFiles.length > 0)) {
      return true;
    }
    return false;
  }
  deleteFile = (file: any, index: number) => {
    //delete this.arrFiles[index];
    this.arrFiles.splice(index, 1);
    this.uploadFile.emit(this.arrFiles);
  }

  isFileExtentionValid = (fileName: string) => {
    if (fileName !== '') {
      let extenstion = fileName.substring(fileName.indexOf('.') + 1, fileName.length)
      if (this.fileTypes.toLowerCase().indexOf(extenstion.toLowerCase()) !== -1) {
        this.isValid = true;
        return true;
      } else {
        this.validateMsg = fileName + '.' + 'File extension is not allowed for upload';
        this.isValid = false;
        return false;
      }
    } else {
      this.validateMsg = 'File name should not be empty';
      this.isValid = false;
      return false;
    }
  }


  public reset(){
    this.arrFiles = [];
  }

  onFileChange = (event: any) => {
    this.isTouched.emit(true);
    this.theFile = null;

    if (event.target.files && event.target.files.length > 0) {
      // Don't allow file sizes over 1MB
      if (this.isFileExtentionValid(event.target.files[0].name)) {
        if (event.target.files[0].size < this.maxSize) {
          // Set theFile property
          this.theFile = event.target.files[0];
          this.isValid = true;
          // this.theFiles.push(this.theFile);
          this.readAndUploadFile(this.theFile);
        }
        else {
          // Display error message
          this.isValid = false;
          this.validateMsg = "File: " + event.target.files[0].name + " is too large to upload.";
        }
      }
    }

  }

  private readAndUploadFile = (theFile: any) => {
    console.log('readAndUploadFile > ',theFile);
    const file = new FileToUpload();

    // Set File Information
    let fileName_short = theFile.name.length > 36 ? theFile.name.slice(36) : theFile.name;
    file.fileName = fileName_short;
    file.fileSize = theFile.size;
    file.fileType = theFile.type;



    // Use FileReader() object to get file to upload
    // NOTE: FileReader only works with newer browsers
    const reader = new FileReader();

    // Setup onload event for reader
    reader.onloadend = () => {
      // Store base64 encoded representation of file
      file.fileAsBase64 = reader.result ? reader.result.toString() : '';
      this.arrFiles.push(file);
      this.uploadFile.emit(this.arrFiles);
    }

    // Read the file
    reader.readAsDataURL(theFile);
    console.log('Result Files', this.arrFiles);
  }
  resetAll(): void {
    //console.log('1');
    this.arrFiles = [];
    this.isValid = true;
    this.validateMsg = '';

  }
}

export class FileToUpload {
  fileName: string = "";
  fileSize: number = 0;
  fileType: string = "";
  fileAsBase64: string = "";
  fileAsByteArray: string = "";
  fileOrder: number = 0;
}
