<div class="content-panel p-0 dialog-box text-header-alerts dialog-box-minWidth">
  <div class="alert-header-content">
    <h3 class="font-900">{{data.title}}</h3>
  </div>

  <div class="alert-data" [innerHTML]="data?.serviceDetail?.message"></div>

  <div class="dialog-footer actions-reduce-bts">
    <div fxLayout="row wrap" class="footer-content">

      <a class="dialog-link m-r-15" [routerLink]="data?.serviceDetail?.routeUrl"
        *ngIf="data?.serviceDetail?.isViewEnable" (click)="closeClick()">
        {{data?.serviceDetail?.labelName}}
      </a>

      <button mat-button class="btn btn-secondary btn-text-36 nobtn" (click)="closeClick()">
        {{data.noButtonText}}
      </button>

      <button mat-button class="btn btn-primary btn-primary-36" *ngIf="data?.serviceDetail?.isConnectToCentre"
        [routerLink]="data?.serviceDetail?.hcURL" (click)="closeClick()">
        {{data.yesButtonText}}
      </button>
    </div>
  </div>
</div>