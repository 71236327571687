import { Directive, HostListener, Inject } from '@angular/core';

import { AccordionLinkDirective } from './accordionlink.directive';

@Directive({
  selector: '[appAccordionToggle]'
})
export class AccordionAnchorDirective {
  protected navlink: AccordionLinkDirective;
  private isScrollings: boolean = false;
  constructor(@Inject(AccordionLinkDirective) navlink: AccordionLinkDirective) {
    this.navlink = navlink;

  }


  @HostListener('click', ['$event'])
  onClick(e: any): void {
    const isIphone = /iPhone|iPad/i.test(navigator.userAgent);

    if (!isIphone) {
      this.navlink.toggle();
    }
  }

  @HostListener('touchstart', ['$event'])
  onTouchStart(e: any): void {
    this.isScrollings = false;
  }

  @HostListener('touchmove', ['$event'])
  onTouchMove(e: any): void {
    this.isScrollings = true;
  }

  @HostListener('touchend', ['$event'])
  onTouchEnd(e: any): void {
    const isIphone = /iPhone|iPad/i.test(navigator.userAgent);

    if (isIphone) {
    if (!this.isScrollings) {
      this.navlink.toggle();
    }
    this.isScrollings = false;
  }
  }
}
