<!-- ------------------------------ header desktop-view --------------------------------- -->
<div class="desktop-view-1024 m-b-20">
    <div class="filter-by-index page-header header-buttons m-b-sm-0 p-lt-xs-20">
        <h1>{{'Archive' | translate}}</h1>
        <div class="icon-container justify-content-end">
            <mat-form-field appearance="outline" class="search-text-icon m-l-15 searchbox-archive has-success">
                <mat-label>{{'Search Messages'|translate}}</mat-label>
                <input matInput placeholder="{{'Search Messages'|translate}}" [(ngModel)]="searchTexts" name="searchTexts" (change)="Searchkey(searchTexts)" style="height: 26px !important;">
                <button mat-button mat-icon-button *ngIf="searchTexts">
                        <i class="icon-cent-search-popular icon-subfld" matSuffix></i>
                    </button>
                <mat-icon class="icon-subfld" *ngIf="searchTexts" class="clear-icon-close" (click)="clearSearchData(searchTexts)">close</mat-icon>
            </mat-form-field>
            <button mat-button (click)="openCompose()" class="btn-primary-rect m-l-15" style="top:2px">
                <i class="icon-cent-pencil-box-outline-text icon-color-grid"></i>
                <span class="m-r-10">{{'Compose'|translate}}</span>
            </button>
            <!-- <span><img src="../../../../assets/images/icons-text/settings.svg" (click)="opensettings()" style="position: relative;top:2px;left:8px;" matTooltip="Message settings"></span> -->
        </div>
    </div>
</div>

<!-- ------------------------------ header mobile-view --------------------------------- -->
<div class="mobile-view-1024">
    <div class="filter-by-index page-header header-buttons m-b-sm-0 p-lt-xs-20">
        <h1>{{'Archive' | translate}}</h1>
        <div class="icon-container">
            <button (click)="openCompose()" mat-button class="mobile-view-1024 txt-color"><i class="icon-cent-pencil-box-outline-text"></i>COMPOSE</button>
        </div>
    </div>
    <div class="icon-container">
        <mat-form-field appearance="outline" class="search-text-icon p-lt-xs-20 searchbox-mobile-archive has-success">
            <mat-label>{{'Search Messages'|translate}}</mat-label>
            <input matInput placeholder="{{'Search Messages'|translate}}" [(ngModel)]="searchTexts" name="searchTexts" (change)="Searchkey(searchTexts)" style="height:26px !important">
            <button mat-button *ngIf="searchTexts" matSuffix mat-icon-button aria-label="Clear" class="clear-icon-close" (click)="clearSearchData(searchTexts)">
            <mat-icon class="icon-subfld1">close</mat-icon>
        </button>
            <i class="icon-cent-search-popular icon-subfld1 search-icon-close-mobile" *ngIf="searchTexts" matSuffix></i>
        </mat-form-field>
    </div>
</div>
<!-- ------------------------------ message-list --------------------------------- -->

<div class="bg-white" *ngIf="mailboxServie.archiveData.length == 0 && !isLoading">
    <div fxLayout="column wrap" fxLayoutAlign="center center" class="no-msg">
        <div class="text-center">
            <img src="assets/images/message123.svg" alt="mail-box">
            <p>Sorry! No messages found.</p>
        </div>
    </div>
</div>

<div fxlayout="row wrap" class="bg-white archive-flexbox" *ngIf="mailboxServie.archiveData.length > 0">
    <div fxflex.lg="40" fxflex.gt-lg="30" fxflex.sm="100" fxflex="100" class="b-r flexbox1-archive">
        <!-- <div *ngIf="nomessages" class="text-center d-md-none nomessage-top">
            <div>
                <div class="connect-health-centre text-center">
                    <img src="assets/images/Appointments.svg" alt="" />
                    <h3>Start talking with doctors</h3>
                    <button mat-button class="btn-primary-pill-l-icon" routerLink="/pages/navigate-routes/Compose"
                        [disabled]="healthCenterList?.length == 0">
                        <i class="icon-cent-pencil-box-outline-text icon-color-grid"></i>Compose Message</button>
                </div>
            </div>
        </div> -->

        <div class="emailInbox relative ps ps--active-y k-slide-tray" *ngIf="mailboxServie.archiveData.length > 0">
            <form [formGroup]="checkboxForm">

                <div class="m-t-20 m-b-10 mail-icons">
                    <mat-checkbox class="select-all" matTooltip="Select all message" aria-label="Select All" formControlName="checkboxChecked" [checked]="isChecked()" [indeterminate]="isIndeterminate()" (change)="$event ? toggleAll($event) : null">
                    </mat-checkbox>
                    <span *ngIf="selectedcheckbox.length==0">
                        <img src="assets/images/icons-text/Group 5069.svg" class="m-l-15">
                    </span>
                    <span *ngIf="selectedcheckbox.length>0">
                        <!-- <mat-icon matTooltip="move to inbox" (click)="movetoInboxAll()">move_to_inbox</mat-icon> -->
                        <a (click)="movetoInboxAll()" matTooltip="Move to inbox"
                        class="m-l-15"><img [src]="imgSrc4"
                            (mouseover)="imgSrc4 = 'assets/images/icons-text/Group 5071.svg'"
                            (mouseout)="imgSrc4 = 'assets/images/icons-text/Group 5079 (1).svg'" alt="Inbox">
                    </a>
                    </span>

                </div>

                <div class="mails-list-wrapper archive-listview-desktop" infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()" [scrollWindow]="false">
                    <div class="mail-list-content">
                        <div class="messages">
                            <div class="emailInbox">
                                <mat-card class="m-0" *ngFor="let i=index;let archive of mailboxServie.archiveData">
                                    <div [ngClass]="{'mail-selected': SelectedMail === i}">
                                        <mat-card-content class="mat-card-spacer">
                                            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? toggle(archive, $event) : null" [checked]="exists(archive)" [disabled]="archive.disabled" [labelPosition]="archive.labelPosition" name="checkBoxToggle" ngDefaultControl [ngModelOptions]="{standalone: true}"
                                                matTooltip="Select message" class="m-t-6">
                                            </mat-checkbox>
                                            <mat-card-header (click)="setSelectedMailType(archive.InboxId,i)">
                                                <div mat-card-avatar class="mat-card-avatar  m-t-6">
                                                    <!-- <img [src]="profileObject.PhotoURL !=='' ? profileObject.PhotoURL  : 'assets/images/nophoto_unknown.gif'" alt="" class="img-fluid img-circle"> -->
                                                    <img class="img-fluid img-circle" [src]="archive.PhotoURL !=='' ? archive.PhotoURL  : 'assets/images/nophoto_unregistered.svg'" onError="this.src='assets/images/nophoto_unregistered.svg'">
                                                </div>
                                                <div>
                                                    <div class="desktop-view-1024">
                                                        <mat-card-title class="header-sub header-width">{{archive.FromName}}</mat-card-title>
                                                        <mat-card-title class="text-sub m-t-8">
                                                            {{archive.Subject}}
                                                        </mat-card-title>
                                                        <mat-card-title class="text-sub m-t-8">
                                                            <label class="font-weight-bold">Service Type</label> : {{archive.MessageCode}}
                                                        </mat-card-title>
                                                    </div>
                                                    <div class="mobile-view-1024">
                                                        <mat-card-title class="text-width">{{archive.FromName}}</mat-card-title>
                                                        <mat-card-title class="text-sub m-t-8 subject-width">
                                                            {{archive.Subject}}
                                                        </mat-card-title>
                                                        <mat-card-title class="text-sub m-t-8 subject-width">
                                                            <label class="font-weight-bold">Service Type</label> : {{archive.MessageCode}}
                                                        </mat-card-title>
                                                    </div>
                                                </div>
                                                <p class="date-time m-t-2">{{archive.SentAndReceiveDate | date: 'dd/MM/yyyy h:mm a'}}</p>
                                                <button class="date-time m-t-27" (click)="$event.stopPropagation()" (click)="moveToInboxSingleItem(archive);movetoInboxDialog(archive.InboxId)" matTooltip="Move to inbox" mat-icon-button>
                                                        <img src="assets/images/Group 50477.svg">
                                                    </button>
                                            </mat-card-header>
                                        </mat-card-content>
                                    </div>
                                </mat-card>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="desktop-view-1024">
                        <div *ngIf="(searchemptymsg  || !mailboxServie.archiveData.length > 0) " class="desktop-view-1024 text-center d-md-none nomessage-top">
                            <div>
                                <div class="connect-health-centre text-center">
                                    <img src="assets/images/Appointments.svg" alt="" />
                                    <h3>There are no records available.</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                   <div class="mobile-view-1024">
                    <div *ngIf="(searchemptymsg  || !mailboxServie.archiveData.length > 0) " class="mobile-view-1024 text-center d-md-none nomessage-top">
                        <div>
                            <div class="connect-health-centre text-center">
                                <img src="assets/images/Appointments.svg" alt="" />
                                <h3>There are no records available.</h3>
                            </div>
                        </div>
                    </div>
                   </div> -->

                </div>


            </form>
        </div>
    </div>

    <!-- ------------------------------ reply-message desktop-view --------------------------------- -->
    <div fxflex.lg="60" fxflex.gt-lg="70" fxflex.sm="100" fxflex="100" class="desktop-view-1024 align-items-center justify-content-center detail-part movetodetail flexbox2-archive" style="overflow:auto;" [ngClass]="ismailSelected == false ? 'notvisible' : 'visible'">

        <div *ngIf="archiveSelectedDetails==false && mailboxServie.archiveData.length>0" class="desktop-view-1024 text-center d-md-none no-selected-msg">
            <img src="assets/images/message123.svg" alt="mail-box">
            <p>Select an item to read</p>

        </div>

        <!-- <div *ngIf="mailboxServie.archiveData.length==0" class="desktop-view-1024 text-center d-md-none no-msg">
            <img src="../../../../assets/images/message123.svg" alt="mail-box">
            <p>Sorry! No new messages found.</p>
        </div> -->


        <!-- Reply Message Data -->
        <div *ngIf="archiveSelectedDetails==true && showDetail==false" class="desktop-view-1024 w-100">
            <perfect-scrollbar class="perfect-scrollbar-archive" [config]="config">
                <div class="email-view-content">
                    <div class="mail-content-wrapper">
                        <div class="mail-info-sub">
                            <!-- <table class="table">
                                <tbody>
                                    <tr class="row from-details">
                                        <td class="col-2 text-left">{{'From'|translate}}</td>
                                        <td class="col text-left">:</td>
                                        <td class="col-6 text-left">{{selectedMessagesDetails[0]?.FromName}}</td>
                                    </tr>
                                    <tr class="row from-details">
                                        <td class="col-2 text-left">{{'To'|translate}}</td>
                                        <td class="col text-left">:</td>
                                        <td class="col-6 text-left">{{selectedMessagesDetails[0]?.ToName}}</td>
                                    </tr>
                                    <tr class="row from-details">
                                        <td class="col-2 text-left">{{'Service Type'|translate}}</td>
                                        <td class="col text-left">:</td>
                                        <td class="col-6 text-left">{{selectedMessagesDetails[0]?.MessageCode}}</td>
                                    </tr>
                                    <tr class="row from-details">
                                        <td class="col-2 text-left">{{'Date'|translate}}</td>
                                        <td class="col text-left">:</td>
                                        <td class="col6 text-left">{{selectedMessagesDetails[0]?.date | date: 'dd MMM
                                            yyyy hh:mm a'}}</td>
                                    </tr>
                                    <tr class="row from-details">
                                        <td class="col-2 text-left">{{'RE'|translate}}</td>
                                        <td class="col text-left">:</td>
                                        <td class="col-6 text-left">{{selectedMessagesDetails[0]?.Subject}}</td>
                                    </tr>
                                </tbody>
                            </table> -->

                            <div class="grid-normal-tbl">
                                <div class="tbl-row">
                                    <div class="tbl-th">{{'From'|translate}}:</div>
                                    <div class="tbl-td">{{selectedMessagesDetails[0]?.FromName}}</div>
                                </div>
                                <div class="tbl-row">
                                    <div class="tbl-th">{{'To'|translate}}:</div>
                                    <div class="tbl-td">{{selectedMessagesDetails[0]?.ToName}}</div>
                                </div>
                                <div class="tbl-row">
                                    <div class="tbl-th">{{'Service Type'|translate}}:</div>
                                    <div class="tbl-td">{{selectedMessagesDetails[0]?.MessageCode}}</div>
                                </div>
                                <div class="tbl-row">
                                    <div class="tbl-th">{{'Date'|translate}}:</div>
                                    <div class="tbl-td">{{selectedMessagesDetails[0]?.date | date: 'dd MMM yyyy hh:mm a'}}</div>
                                </div>
                                <div class="tbl-row">
                                    <div class="tbl-th">{{'Subject'|translate}}:</div>
                                    <div class="tbl-td">{{selectedMessagesDetails[0]?.Subject}}</div>
                                </div>
                            </div>
                        </div>

                        <div class="filter-by-index page-header header-buttons m-b-sm-0 m-t-20" style="padding: 0px">

                            <div class="attachments-header">
                                <div *ngIf="filesData && filesData.length >=1;then file else nofile">here is ignored</div>
                                <ng-template #file>
                                    <div class="attachments-list" *ngFor="let data of filesData">
                                        <mat-icon class="type-icon">attach_file</mat-icon>
                                        <app-download-file [url]="downloadURL" [btnText]="data?.OriginalFileName" [pdfName]="data?.FileName" [isPostDownload]="isPostDownload" class="m-t-5"></app-download-file>
                                    </div>
                                </ng-template>
                                <ng-template #nofile>
                                    <div class="attachments-list" *ngIf="filesData">
                                        <mat-icon class="type-icon">attach_file</mat-icon>
                                        <app-download-file [url]="downloadURL" [btnText]="filesData?.OriginalFileName" [pdfName]="filesData?.FileName" [isPostDownload]="isPostDownload" class="m-t-5">
                                        </app-download-file>
                                    </div>
                                </ng-template>
                            </div>
                            <div class="icon-container svg-btn-matmenu">
                                <!---<button mat-button (click)="replyMessageShow('ArchiveDetail')" matTooltip="Reply" class="btn-primary-rect m-l-15">
                                    <mat-icon> drive_file_rename_outline</mat-icon>
                                    <span class="m-l-10 m-r-5">{{'Reply'|translate}}</span>
                                </button> --->
                                <a (click)="moveToInboxSingle(selectedMessagesDetails[0]);movetoInboxDialog(selectedMessagesDetails[0]?.InboxMessageId)" matTooltip="Move to inbox" class="m-l-10"><img [src]="imgSrc2" (mouseover)="imgSrc2 = 'assets/images/Group 5045.svg'" (mouseout)="imgSrc2 = 'assets/images/Group 5012 (1).svg'" alt="Inbox">
                                </a>
                            </div>
                        </div>
                        <div class="mail-spacer-line"></div>
                        <div class="subject-email-content">
                            <div [innerHTML]="selectedMessagesDetails[0]?.MessageBody"></div>
                        </div>
                    </div>
                </div>
            </perfect-scrollbar>
        </div>

        <!-- ------------------------------ message-detail desktop-view --------------------------------- -->
        <div *ngIf="archiveSelectedDetails!==false && showDetail==true" class="desktop-view-1024 w-100">
            <app-archivedetail id="SelectedMail" [ngClass]="SelectedMail ? 'active' : ''" (onCloseClick)="SelectedMail = false"></app-archivedetail>
        </div>

        <!-- ------------------------------ reply-message mobile-view --------------------------------- -->

        <div *ngIf="archiveSelectedDetails" class="mobile-view-1024 reply-message-content" style="width:100% !important;right:0 !important;z-index: 5;">
            <div class="view-mail-header close-btn-align">
                <button mat-button class="view-mail-close" (click)="close()">
                    <span class="cls-txt">{{'Close'|translate}}</span>
                    <!-- <i class="icon-cent-chevron-right-chevrons arrow-rightmail"></i> -->
                    <i class="icon-cent-close-cancel"></i>
                </button>
            </div>
            <div class="email-view-content archive-listview-mobile">

                <div class="mail-content-wrapper">
                    <div class="mail-info-sub">
                        <div class="grid-normal-tbl">
                            <div class="tbl-row">
                                <div class="tbl-th">{{'From'|translate}}:</div>
                                <div class="tbl-td">{{selectedMessagesDetails[0]?.FromName}}</div>
                            </div>
                            <div class="tbl-row">
                                <div class="tbl-th">{{'To'|translate}}:</div>
                                <div class="tbl-td">{{selectedMessagesDetails[0]?.ToName}}</div>
                            </div>
                            <div class="tbl-row">
                                <div class="tbl-th">{{'Service Type'|translate}}:</div>
                                <div class="tbl-td">{{selectedMessagesDetails[0]?.MessageCode}}</div>
                            </div>
                            <div class="tbl-row">
                                <div class="tbl-th">{{'Date'|translate}}:</div>
                                <div class="tbl-td">{{selectedMessagesDetails[0]?.date | date: 'dd MMM yyyy hh:mm a'}}</div>
                            </div>
                            <div class="tbl-row">
                                <div class="tbl-th">{{'Subject'|translate}}:</div>
                                <div class="tbl-td">{{selectedMessagesDetails[0]?.Subject}}</div>
                            </div>
                        </div>

                    </div>

                    <div class="filter-by-index page-header header-buttons m-b-sm-0 m-t-20" style="padding: 0px">
                        <div class="attachments-header">
                            <div *ngIf="filesData && filesData.length >=1;then file else nofile">here is ignored</div>
                            <ng-template #file>
                                <div class="attachments-list" *ngFor="let data of filesData">
                                    <mat-icon class="type-icon">attach_file</mat-icon>
                                    <app-download-file [url]="downloadURL" [btnText]="data?.OriginalFileName" [pdfName]="data?.FileName" [isPostDownload]="isPostDownload" class="m-t-5"></app-download-file>
                                </div>
                            </ng-template>
                            <ng-template #nofile>
                                <div class="attachments-list" *ngIf="filesData">
                                    <mat-icon class="type-icon">attach_file</mat-icon>
                                    <app-download-file [url]="downloadURL" [btnText]="filesData?.OriginalFileName" [pdfName]="filesData?.FileName" [isPostDownload]="isPostDownload" class="m-t-5">
                                    </app-download-file>
                                </div>
                            </ng-template>
                        </div>
                        <div class="icon-container">

                            <a (click)="moveToInboxSingle(selectedMessagesDetails[0]);movetoInboxDialog(selectedMessagesDetails[0]?.InboxMessageId)" matTooltip="Move to inbox" class="m-l-10">
                                <img [src]="imgSrc2" (mouseover)="imgSrc2 = 'assets/images/Group 5013.svg'" (mouseout)="imgSrc2 = 'assets/images/Group 5012.svg'" alt="Inbox">
                            </a>
                        </div>
                    </div>

                    <div class="mail-spacer-line"></div>
                    <div class="subject-email-content">
                        <div [innerHTML]="selectedMessagesDetails[0]?.MessageBody"></div>
                    </div>
                </div>
            </div>
            <!---<div class="email-view-footers">
                <button mat-button class="btn-primary-pill-l-icon" type="submit"
                    (click)="replyMessageNavigate('ArchiveDetail')">
                    <i class="icon-cent-pen-business"></i> {{'Reply Message'|translate}}</button>
            </div>-->
        </div>

    </div>
</div>
<!-- <div class="mb-flo-wrp compose-btn-float mobile-view-1024">
    <button mat-button class="btn-circle" matTooltip="Attach Document" aria-label="Attach Document" (click)="openCompose()"> -->
<!-- <i class="icon-cent-add-plus-add icon-white"></i> -->
<!-- <i class="icon-cent-pencil-box-outline-text icon-color-grid color-bl-icon"></i>
    </button>
</div>
<div class="mb-flo-wrp settings-btn-float mobile-view-1024">
    <span><img src="../../../../assets/images/icons-text/hoversettings.svg" (click)="opensettings()"></span>
</div> -->