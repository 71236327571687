import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DiscoverHealthHttpClientService } from 'src/app/data/service/discover-health-request.service';


@Injectable({
    providedIn: 'root'
})
export class DiscoverHealthTokenResolver implements Resolve<any> {
    loading = false;

    async resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        if (!sessionStorage.getItem('WebPressToken')) {
            const res = await this.discoverHealthHttpClient.getWebPressToken().toPromise();
            const response = JSON.parse(res);
            sessionStorage.setItem('WebPressToken', response.jwt_token);
        }
        return true;
    }

    constructor(
        private discoverHealthHttpClient: DiscoverHealthHttpClientService
    ) {

    }
}
