import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
//import { FileToUpload } from '../file-upload/file-upload.component';
import { FileUploadService } from 'src/app/data/service/file-upload.service';
@Component({
  selector: 'app-signature-upload',
  templateUrl: './signature-upload.component.html',
  styleUrls: ['./signature-upload.component.scss']
})
export class SignatureUploadComponent implements OnInit {

  @Input() type: number; // 0 is single, 1 is multiple
  @Input() maxSize = 1048576;
  @Input() fileTypes = 'gif,jpg,png,jpeg,tif';
  @Output() uploadFile = new EventEmitter();
  @Output() isTouched = new EventEmitter();
  @Output() extErrorMsg = new EventEmitter();

  file = new FileToUpload();
  theFile: any = null;
  messages: string[] = [];
  theFiles: any[] = [];
  arrFiles: FileToUpload[] = [];
  size = '';
  Extn:any;
  acpAttachmentAllowedExt =["gif","jpg","png","jpeg","tif"];
  showValidExt: boolean = false;

  constructor(public fileUploadService: FileUploadService, public fb: FormBuilder) { }

  ngOnInit(): void {
    if (this.maxSize > 0) {
      this.size = (this.maxSize / 1048576).toFixed(2);
    }
  }
  setIsTouch = () => {
    this.isTouched.emit(true);
  }
  isDisabled = () => {
    if (this.type === 0 && this.arrFiles.length > 0) {
      return true;
    }
    return false;
  }
  deleteFile = (file: any, index: number) => {
    //delete this.arrFiles[index];
    this.arrFiles.splice(index, 1);
    this.uploadFile.emit(this.arrFiles);
  }
  onFileChange = (event: any) => {
    this.theFile = null;

    if (event.target.files && event.target.files.length > 0) {
      let fileName = event.target.files[0].name;
      let fileExtn = fileName.split('.');
      let extn = fileExtn[fileExtn.length - 1];
      this.Extn = fileName;
      let extnValid = this.acpAttachmentAllowedExt.indexOf(extn);
      if (extnValid != -1) {
        // Don't allow file sizes over 1MB
        if (event.target.files[0].size < this.maxSize) {
          // Set theFile property
          this.theFile = event.target.files[0];

          // this.theFiles.push(this.theFile);
          this.readAndUploadFile(this.theFile);
          this.uploadFile.emit(this.arrFiles);
        }
        else {
          // Display error message
          this.messages.push("File: " + event.target.files[0].name + " is too large to upload.");
        }
         this.showValidExt = false;
         this.extErrorMsg.emit(this.showValidExt);
      } else {
        this.showValidExt = true;
        this.extErrorMsg.emit(this.showValidExt);
      }
    }
  }
  
  private readAndUploadFile = (theFile: any) => {
    const file = new FileToUpload();

    // Set File Information
    file.fileName = theFile.name;
    file.fileSize = theFile.size;
    file.fileType = theFile.type;

    // Use FileReader() object to get file to upload
    // NOTE: FileReader only works with newer browsers
    const reader = new FileReader();

    // Setup onload event for reader
    reader.onload = () => {
      // Store base64 encoded representation of file
      file.fileAsBase64 = reader.result ? reader.result.toString() : '';
      this.uploadFile.emit(this.arrFiles);
    }

    // Read the file
    reader.readAsDataURL(theFile);
    this.arrFiles.push(file);
    console.log('Result Files', this.arrFiles);
  }

  resetAll(): void {
    //console.log('1');
    this.arrFiles = [];
    this.uploadFile.emit(this.arrFiles);
  }

}
export class FileToUpload {
  fileName: string = "";
  fileSize: number = 0;
  fileType: string = "";
  fileAsBase64: string = "";
  fileAsByteArray: string = "";
}
