import { Injectable } from '@angular/core';
import { SmsSettingsComponent } from 'src/app/modules/pages-sms-credit-facility/sms-settings/sms-settings.component';
import { SmsAutoTopUpComponent } from 'src/app/modules/pages-sms-credit-facility/sms-auto-top-up/sms-auto-top-up.component';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { TokenService } from './token.service';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog/dialog';
import { FormBuilder, FormControl } from '@angular/forms';
import { URLConstants } from 'src/app/core/constants/urlConstants';
import { applicationHttpClientCreator } from '../interface/IRequestOptions';
import { environment } from 'src/environments/environment';
import { promise } from 'protractor';
import { IRRPPayment } from '../interface/Ipayment';
import { ToasterService } from './toaster.service';
@Injectable({
  providedIn: 'root',
})
export class SmsCreditFacilityService {
  //#region Variables

  public userId: any;
  profileObject: {};
  public healthCenters: any = [];
  public formControl: FormControl;
  practiceId: any;
  //currentSMSbalance: any;
  totalSmsCost: any;
  noOfSmsValue: any;
  encPracticeId: any;
  remainderBal: any;
  reminderEnabled: any = true;
  //creditCardEnable: any;
  //practiceId: number;

  //#endregion

  //#region Constructor

  constructor(
    public http: HttpClient,
    public tokenService: TokenService,
    public toaster: ToasterService
  ) {}

  //#endregion

  //#region Events

  getPracticeId = () => {
    this.userId = this.tokenService.userId();

    if(this.sessionPracticeId() && this.sessionPracticeId() !=null && this.sessionPracticeId() != undefined)
    {
      //console.log("*****************************practiceId in getPracticeId****************",+this.sessionPracticeId());
      //return +this.sessionPracticeId();
      this.practiceId = +this.sessionPracticeId()
      return new Promise<number>((resolve, reject) => {
        return resolve(+this.sessionPracticeId());
      });
    }
    else
    {
      return new Promise<number>((resolve, reject) => {
        applicationHttpClientCreator(this.http)
          .Get(`${URLConstants.getPraciceByUserPracticeAdmin}${this.userId}`, {})
          .subscribe(
            (data: any) => {
              const practiceId: number = data[0].PracticeId;
              sessionStorage.setItem('practiceId', practiceId.toString());
              this.practiceId = practiceId; // settting here
              //console.log("*****************************practiceId in getPracticeId****************",practiceId);
              return resolve(practiceId);
            },
            (err: any) => {
              console.error(err.error.Message || err.error.message);
            }
          );
      });
  }
  };


  sessionPracticeId() {
    let practiceId: any;
      if (sessionStorage.getItem('practiceId')) {
        practiceId = sessionStorage.getItem('practiceId');
      return practiceId;
    }   else return null;
  }


  getsmsCreditfacilitybalance = (practiceId: any) => {
    return new Promise((resolve, reject) => {
      applicationHttpClientCreator(this.http)
        .Get(`${URLConstants.getSMSCreditFacilityBalance}`, {})
        .subscribe(
          (data: any) => {
            return resolve(data);
          },
          (err: any) => {
            this.toaster.error(err.error.message || err.error.Message);
          }
        );
    });

  };
  //Service Function to Load Initial Data
  public getSettingsData = () => {
    return new Promise((resolve, reject) => {
      applicationHttpClientCreator(this.http)
        .Get(`${URLConstants.getSMSUserSettings}`, {})
        .subscribe(
          (data: any) => {
            return resolve(data);
          },
          (err: any) => {
            console.error(err.error.Message || err.error.message);
          }
        );
    });
  };

  public postSMSSettingsData = (data: any) => {
    return new Promise((resolve, reject) => {
      applicationHttpClientCreator(this.http)
        .Post(`${URLConstants.postSMSCreditFacilitySettings}`, {
          ReminderEnabled: data.ReminderEnabled,
          SMSReminderBalance: data.SMSReminderBalance,
          SettingRoles: data.SettingRoles,
          encPracticeId: data.encPracticeId
        })
        .subscribe(
          (data) => {
            console.log(data);
            return resolve(data);
          },
          (err) => {
            console.log(err);
          }
        );
    });
  };

  public topUpPayment = (data: any) => {
    return new Promise((resolve, reject) => {
      applicationHttpClientCreator(this.http)
        .Post(`${URLConstants.sendSMSPaymentRequest}`, {})
        .subscribe(
          (data) => {
            console.log(data);
            //this.currentSMSbalance = data;
            return resolve(data);
          },
          (err) => {
            console.log(err);
          }
        );
    });
  };

  // public currentSmsBalance: BehaviorSubject<any> =
  // new BehaviorSubject<boolean>(false);

  public enableSettingsRemainder: BehaviorSubject<any> =
    new BehaviorSubject<boolean>(false);
    public onYesEnableSettingsRemainder: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);
    public deleteCard: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);

  public creditCardEnable: BehaviorSubject<any> = new BehaviorSubject<any>(
    false
  );
  public paymentEnable: BehaviorSubject<any> = new BehaviorSubject<any>(
    false
  );

  public checkBoxValue: BehaviorSubject<any> = new BehaviorSubject<boolean>(
    false
  );

  public topBannerShow: BehaviorSubject<any> =
    new BehaviorSubject<boolean>(false);

    public topBannerHide: BehaviorSubject<any> =
    new BehaviorSubject<boolean>(false);

  setSmsData = (totalSmsCost: any) => {
    this.totalSmsCost = totalSmsCost;
  };

  public smsValueChange: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  public updateHealthCentre: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  updateHealthCentreObservable = this.updateHealthCentre.asObservable();

  updateHealthCentreData(newData: string) {
    this.updateHealthCentre.next(newData);
  }

  public remainderChange: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  remainderChangeObservable = this.remainderChange.asObservable();

  remainderData(newData: string) {
    this.remainderChange.next(newData);
  }

  setNoofSms = (noOfSmsValue: any) => {
    this.noOfSmsValue = noOfSmsValue;
  };

  getSmsData() {
    return this.totalSmsCost;
  }

  getNoofSms() {
    return this.noOfSmsValue;
  }


  setEncPracticeId = (encPracticeId: any) => {
    this.encPracticeId = encPracticeId;
  };

  getEncPracticeId() {
    return this.encPracticeId;
  }

  getSMSPaymentReports(startDateDay: any, startDateMonth: any ,startDateYear : any ,endDateDay: any ,endDateMonth: any ,endDateYear: any)
  {
    return this.http.get(environment.apiBaseURL + URLConstants.getTransactionDetails  + startDateDay + '&startDateMonth=' + startDateMonth+ '&startDateYear=' + startDateYear+ '&endDateDay=' + endDateDay + '&endDateMonth=' + endDateMonth+ '&endDateYear=' + endDateYear + '&encPracticeId=' + this.encPracticeId );
  }

  getFrontReports(sday: any, smonth: any ,syear : any ,eday: any ,emonth: any ,eyear: any)
  {
    return this.http.get(environment.apiBaseURL + URLConstants.getTransactionDetails + sday + '&startDateMonth=' + smonth+ '&startDateYear=' + syear+ '&endDateDay=' + eday + '&endDateMonth=' + emonth+ '&endDateYear=' + emonth + '&encPracticeId=' + this.encPracticeId );
  }

  getSMSUsageHistory(typeId: any, functionId: any, encPracticeId: string)
  {
    return this.http.get(environment.apiBaseURL + URLConstants.getSMSUsageHistory  + typeId + '&functionId=' +functionId + '&encPracticeId=' + encPracticeId);
  }

  downloadInvoice(MMHPayTxnID: any)
  {
    return this.http.post(environment.apiBaseURL + URLConstants.getSMSTransactionInvoice  + '?MMHPayTxnID=' + MMHPayTxnID, '');
  }

  getFromDetails(userId : string) {
    return this.http.get(environment.apiBaseURL + URLConstants.getFromDetails + userId)
  }

  getSMSCreditFacilityBalance(practiceId: any) {
    return this.http.get(environment.apiBaseURL + URLConstants.getSMSCreditFacilityBalancePractice + '?encPracticeId=' + practiceId)
  }

  getTopBannerMessage(practiceId: any) {
    return this.http.get(environment.apiBaseURL + URLConstants.getTopBannerMessage + '?encPracticeId=' + practiceId)
  }
  getSMSCost(practiceId: any) {
    return this.http.get(environment.apiBaseURL + URLConstants.getSMSCost + '?encPracticeId=' + practiceId)
  }

  getSMSAutoTopUp(practiceId: any) {
    return this.http.get(environment.apiBaseURL + URLConstants.getSMSAutoTopUp + '?encPracticeId=' + practiceId)
  }
  getSMSUserSettings(practiceId: any) {
    return this.http.get(environment.apiBaseURL + URLConstants.getSMSUserSettings + '?encPracticeId=' + practiceId)
  }
  getSMSUsage(practiceId: any) {
    return this.http.get(environment.apiBaseURL + URLConstants.getSMSUsage + '?encPracticeId=' + practiceId)
  }
  saveSMSAutoTopUp(payload: any) {
    return this.http.post(environment.apiBaseURL + URLConstants.saveSMSAutoTopUp, payload)
  }

  //#endregion
}
