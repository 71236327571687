import {Injectable} from '@angular/core'

@Injectable()
export class RequestService {             
    keyvals: any;    
    public ConstructGetRequest(params: any): any {      
	    this.keyvals=[];

        for (let key in params) {
            this.keyvals += key + '=' + params[key] + '&';
        }
        return this.keyvals.length > 0 ? this.keyvals.substr(0,this.keyvals.length -1): this.keyvals;
    }

    public ConstructRequest(params: any): any {      
	    this.keyvals=[];

        for (let key in params) {
            this.keyvals.push({key: key,value:params[key] });
        }
                                            
        return { RequestParams: this.keyvals };
    }

    public ConstructRequestData(params: any) : any {
        this.keyvals=[];

        for (let key in params) {
            this.keyvals.push({key: key,value:params[key] });
        }

        return { RequestData: this.keyvals };
    }

    public ConstructRequestParams(params: any) : any {
        this.keyvals=[];

        for (let key in params) {
            this.keyvals.push({key: key,value:params[key] });
        }

        return { RequestParams: this.keyvals };
    }
}
