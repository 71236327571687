import { Component, HostListener, OnInit, ViewChild, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, Data, ActivatedRoute, NavigationExtras, UrlTree } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToasterService } from 'src/app/data/service/toaster.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MovetoinboxDialogComponent } from '../movetoinbox-dialog/movetoinbox-dialog.component';
import { InBoxService } from 'src/app/data/service/inbox.service';
import { MailBoxService } from 'src/app/data/service/mail-box.service';
import { TokenService } from 'src/app/data/service/token.service';
import { URLConstants } from 'src/app/core/constants/urlConstants';
import { MatDrawer } from '@angular/material/sidenav';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { applicationHttpClientCreator } from 'src/app/data/interface/IRequestOptions';
import { HttpClient } from '@angular/common/http';
import { AlertType, DashboardServiceData } from 'src/app/core/constants/app-enums';
import { AlertDialogComponent } from 'src/app/shared/component/alert-dialog/alert-dialog.component';
 import { PerfectScrollbarComponent, PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonConstants } from 'src/app/core/constants/commonConstants';
import { CommonService } from 'src/app/data/service/common.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';


 const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};
// import { VerticalAppHeaderComponent } from '../vertical-header/vertical-header.component';
@Component({
  selector: 'app-mail-list',
  templateUrl: './mail-list.component.html',
  styleUrls: ['./mail-list.component.scss']
})
export class MailListComponent implements OnInit {
//   @HostListener('window:resize', ['$event'])
// onResize(event:any) {
//   this.innerWidth = window.innerWidth;
//   this.loaddata(this.innerWidth);
// alert(this.innerWidth);
// }
imgSrc1: string = "assets/images/Group 5014.svg";
imgSrc2: string = "assets/images/Group 5027.svg";
imgSrc3: string = "assets/images/Group 5026.svg";
imgSrc4: string = "assets/images/icons-text/Group 5033.svg";
imgSrc5: string = "assets/images/icons-text/Group 5034.svg";
imgSrc6: string = "assets/images/icons-text/Group 5068.svg";
imgSrc7: string = "assets/images/icons-text/Group 5080.svg";

  // @ViewChild('perfectScroll') perfectScroll: PerfectScrollbarComponent;
  @ViewChild('drawer', { static: false }) public drawer: MatDrawer;
  checkboxForm : FormGroup;
  public info = true;
  // public refresh = true;
  public buttonCount = 5;
  public type = 'numeric';
  public pageSizes = true;
  public previousNext = true;
  public position = 'bottom';
  public skip = 0;
  public allowUnsort = true;
  public multiple = false;
  // public dataItem :any;
  public messageinfo : any;
  public moreInfo: boolean = false;
  public InboxMessages: any = [];
  searchText: any;
  subscription: Subscription;
  public searchkeyvalue: any;
  openSidebar: boolean = true;
  selected = 'compose';
  public inboxId: string;
  public messageId: any;
  public openedArchiveDialouge: boolean = false;
  public userId: any;
  selectedMailType: any;
  public gridView: any = [];
  tooltipOffset = { left: 0, top: 0 };
  // checkboxForm: FormGroup;
  allComplete: boolean = false;
  page: number = 0;
  pagesize: number = 20;
  inboxCount: any
  selectedAction: any;
  isSelectAll = false;
  selectedcheckbox: any = [];
  selectedinboxid: any = [];
  public showdetails: boolean = false;
  public healthCenterList: any = [];
  public profileObject: any = [];
  public replyMessage: boolean = false;
  public selectedMessagesDetails: any = [];
  public getPracticeData: any = [];
  public xmltoJSON: any;
  public filesData: any = [];
  public inboxMessageId: any;
  searchTexts: any;
  searchtext = false;
  listInboxId: any = [];
  selectedServiceTypeData: any;
  alertDialog: any;
  pageNumber: number=0;
  downloadURL = URLConstants.downloadFile_Post;
  isPostDownload = true;
  public config: PerfectScrollbarConfigInterface = {};
  public ShowDetail :boolean = false;
  public SelectedMail:any;
  public NoNewMsg:boolean=false;
  public nomessages:any;
  public innerWidth: any;
  public title:any = "thank you every one";
  showPrintPage: boolean = false;
  public searchemptymsg:boolean=false;
  selectedId: any;
  patientid: any;
  isLoading = true;
  isSearch: boolean = false;
  isScrolled: boolean = false;
  paramValue: any = '';
  isMobile: boolean =false;
  ismailSelected: boolean = false;
  isPatient: boolean = false;
  constructor(private router: Router,
    public tokenService: TokenService,
    public mailboxService: MailBoxService,
    public deletedialog: MatDialog,
    public translate: TranslateService,
    public inboxService: InBoxService,
    public inBoxService: InBoxService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private toaster: ToasterService,
    public sanitizer: DomSanitizer,
    public commonService: CommonService,
    private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
    private http:HttpClient) {

    this.userId = this.tokenService.userId();

    // searchvalue through service call
    this.subscription = this.mailboxService.onMessage().subscribe(message => {
      if (message) {
        this.searchkeyvalue = message.text;
        this.searchText = this.searchkeyvalue;
        // this.searchemptymsg = true;
      } else {
        // clear messages when empty message received
        this.searchkeyvalue = "";
        this.searchText = '';
        this.searchemptymsg = false;
      }
      this.replyMessage = false;
      this.SelectedMail = false;
      this.refresh();
    });



    this.route.queryParamMap.subscribe(params => {
      this.paramValue = params.get('view');

    });

    this.breakpointObserver
      .observe(['(max-width: 768px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      });
  }
  ngOnInit(): void {
    let userRoleArr: any = this.tokenService.getUserRoles();
    let userRoleList = userRoleArr?.split(',');
    let patientid = userRoleList?.findIndex((x: any) => x.toLowerCase() == 'patient');

    if (patientid != -1) {
      this.isPatient = true;
    }
    this.profileObject = this.tokenService.userProfile();
    let roles: any = this.tokenService.getUserRoles();
    let userRoles = roles.split(',');
    this.patientid = userRoles.findIndex((x: any) => x.toLowerCase() == 'patient');
    console.log(this.patientid);
    this.mailboxService.gridView = [];
    this.checkboxForm = this.fb.group({
      checkboxChecked: [''],
      selectedCheckbox: ['']
    })
    this.searchText = '';
    this.selectedId = this.mailboxService.getParamsData();
    if (this.selectedId == undefined || this.selectedId == null || this.selectedId == '') {
      this.selectedId = sessionStorage.getItem('inboxSSOId');
    }
    //this.mailboxService.inboxMessageIdValue.subscribe(data => this.selectedId = data);
    this.getRecievedMessages();
    this.GetAllReceivedMessageCount();
    // this.toggleAll(true);
    this.SelectedMail = false;
    this.showPrintPage = false;
    // this.innerWidth = window.innerWidth;
    // this.loaddata(this.innerWidth);

    // Get Health Center Details
    console.log(this.userId)
    this.inboxService.getHealthCenterDetails(this.userId).subscribe((data: any) => {
      this.healthCenterList = data;
      if (this.healthCenterList.length == 0) {
        this.mailboxService.showHealthCenter = true;
      } else { this.mailboxService.showHealthCenter = false; }
      console.log(this.healthCenterList, "health center")
    })
  }

  CallbackmarkasReadorUnread(dataItem :any,index: number)
  {
    this.markasReadorUnread(dataItem.InboxMessageId,index);
    console.log("dataI",dataItem);
  }

  CallbackmovetoArchive(inboxMessageId:any)
  {
  this.movetoArchive(inboxMessageId);
    console.log("dataI",inboxMessageId);
  }

  /* get inbox messages */
  getRecievedMessages() {
    this.nomessages=false;
    this.isLoading = true;
    this.inBoxService.getAllSearchedReceivedMessage(this.userId, 'MessageReceivedOn desc', this.page, this.pagesize, this.isSelectAll, this.searchText, this.selectedId).subscribe((res: any) => {
      if (res && res.length > 0) {
        console.log("scorll", res.length, this.page);
          // for(var i=0; i<res.length ; i++){
          //     let datediff=this.calculateDateDiff(res[i].MessageReceivedOn);
          //     let diffResult= datediff[0] != 0 ? datediff[0]+" year" : datediff[1] != 0 ? datediff[1]+ " month" : datediff[2] != 0 ? datediff[2]+ " day" : datediff[3] != 0 ? datediff[3] + " hour" : datediff[4] != 0 ? datediff[4] + " min" : datediff[5] + " sec";
          //     res[i].MessageReceivedOn= diffResult;
          // }
        // this.mailboxService.gridView = res;
        // if(pushType==='append')
        // this.searchkeyvalue = message.text;
        // this.searchText = this.searchkeyvalue;
        if (this.searchTexts?.length || this.searchkeyvalue?.length) {
        //  this.mailboxService.gridView=[];
        if(this.isScrolled){
          this.mailboxService.gridView.push(...res);
        }else{
          this.mailboxService.gridView=[];
          this.mailboxService.gridView = res;
        }
        } else {
          this.mailboxService.gridView.push(...res);
        }
        // if(pushType==='replace')
        // {
        //   this.mailboxService.gridView = res;
        // }
        if(this.checkboxForm.value.checkboxChecked==true){
          // res.forEach((element:any) => {

          //   this.exists(element);
          // });
          this.toggleAll(true);
          // this.isChecked();
          // this.isIndeterminate();
        }
        // if(this.isSelectAll === true){
        //   this.setIsCheckedArray(true);
        // }
        console.log("inbox",this.mailboxService.gridView)
      this.InboxMessages = res;

      }
      this.isLoading = false;
      if (this.mailboxService.gridView.length == 0) {
        this.nomessages = true;
        this.searchemptymsg = true;
      } else {
        this.searchemptymsg = false;
        if (this.selectedId != undefined && this.selectedId != null && this.selectedId != '') {
          let index = this.mailboxService.gridView.findIndex((x: any) => x.InboxMessageId == this.selectedId);
          if (index != -1) {
            this.setSelectedMailType(this.selectedId, index);
          }
        }
      }
    }, (err: any) => {
      this.isLoading = false;
    })
  }

  // MessageMoreInfo = (dataItem: any) => {
  //   this.moreInfo = true;
  //   this.messageinfo = dataItem;
  // }

  calculateDateDiff =(dateOfReceived: any) => {

      var startDate = new Date(dateOfReceived);
      // user not pass endingDate then set current date as end date.
      let endingDate = new Date();

      let endDate = new Date(endingDate);
      // chack start date and end date and base on condication alter date.
      if (startDate > endDate) {
        var swap = startDate;
        startDate = endDate;
        endDate = swap;
      }
      // This is for leap year.
      const startYear = startDate.getFullYear();
      const february =
        (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0
          ? 29
          : 28;
      const daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

      let yearDiff = endDate.getFullYear() - startYear;
      let monthDiff = endDate.getMonth() - startDate.getMonth();
      if (monthDiff < 0) {
        yearDiff--;
        monthDiff += 12;
      }
      let dayDiff = endDate.getDate() - startDate.getDate();
      let hourDiff = endDate.getHours() - startDate.getHours();
      let minuteDiff = endDate.getMinutes() - startDate.getMinutes();
      let secondDiff = endDate.getSeconds() - startDate.getSeconds();
      if (dayDiff < 0) {
        if (monthDiff > 0) {
          monthDiff--;
        } else {
          yearDiff--;
          monthDiff = 11;
        }
        dayDiff += daysInMonth[startDate.getMonth()];
      }

      return ([
        yearDiff,monthDiff,dayDiff,hourDiff,minuteDiff,secondDiff
      ]);

  }
  openCompose()
  {
    if(this.isPatient){
      this.getPatientSuspendDetails();
    }else{
      this.getStaffSuspendDetails();
    }
  }

  activationCode(): void {
    this.router.navigate(['/myaccount/profile']);
  }

  // opensettings()
  // {
  //   this.router.navigate(['/pages/navigate-routes/Settings']);
  // }

  // hideinbox():void {

  //   if( this.mailboxService.selectedMailBoxType.includes("Compose") ||
  //   this.mailboxService.selectedMailBoxType.includes("Inbox") ||
  //   this.mailboxService.selectedMailBoxType.includes("Drafts") ||
  //   this.mailboxService.selectedMailBoxType.includes("Sent") ||
  //   this.mailboxService.selectedMailBoxType.includes("Archive") ||
  //   this.mailboxService.selectedMailBoxType.includes("Groups") ||
  //   this.mailboxService.selectedMailBoxType.includes("Detail") ||
  //   this.mailboxService.selectedMailBoxType.includes("DraftDetail"))
  //   {
  //     this.mailboxService.selectedMailBoxType = '';
  //   }
  // }

  // Print Data of message body
  printAnalysisData() {
    this.showPrintPage = true;
    this.imgSrc7="assets/images/icons-text/Group 5080.svg";
    setTimeout(() => {
      let printContents: any, popupWin: any;
      let printData: any;
      if (document.getElementById('sectionToPrint') !== null && document.getElementById('sectionToPrint') !== undefined) {
        printContents = document.getElementById('sectionToPrint');
        printData = printContents.innerHTML
      }
      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
      <html>
        <head>
          <title>ManageMyHealth: Empowering for Health & Wellness</title>
          <style>//........Cu
          stomized style.......
          </style>
        </head>
        <body onload="window.print(); window.close(

        )">
        <div>
        ${printData}
        </div>
        </body>
      </html>`
      );
      popupWin.document.close(
        this.showPrintPage = false
      );

    }, 500)
  }

  // When scroll down the screen
  onScroll() {
    console.log("Scrolled");
    // this.page = this.page + 10;
    this.pageNumber=this.pageNumber+1;
    this.page = (this.pageNumber) * this.pagesize;
    console.log("******************",this.page,this.pagesize)
    for (let i = 0; i < this.selectedcheckbox.length; i++) {
      this.selectedcheckbox[i].IsChecked = true;

    }
    this.isScrolled = true;
    this.getRecievedMessages();


  }

  GetAllReceivedMessageCount() {
    this.inboxService.inboxCount(this.userId).subscribe(data => {
      this.inboxCount = data;
      this.mailboxService.mailstype[0].count = this.inboxCount;
    })
  }

  isIndeterminate() {
    return (this.selectedcheckbox.length > 0 && !this.isChecked());
  };

  isChecked() {
    return this.selectedcheckbox.length === this.mailboxService.gridView.length;
  };


  toggle(item: any, event: MatCheckboxChange) {
    // this.isSelectAll = event.checked;
    console.log("Item : ",item);
    console.log("event.checked : ",event);

    if (item.IsChecked == true) {
         this.selectedcheckbox.push(item);
        //  console.log(" this.selectedcheckbox ", this.selectedcheckbox);
        console.log(this.selectedcheckbox?.length + ' - Find checkBox length in single selection')
        if(this.selectedcheckbox?.length >= 50){
          this.maxSelectAllMessageDialog();
        }
    } else {
      const index = this.selectedcheckbox.indexOf(item);
      if (index >= 0) {
        item.IsChecked=false;
        //  this.selectedcheckbox.splice(index, 1);
        this.selectedcheckbox.forEach((element: any, index: any) => {
          if (element.InboxMessageId == item.InboxMessageId) {
            this.selectedcheckbox.splice(index, 1);
          }
        });
      }
    }
    // console.log(item + "<>", event.checked);
  }

  exists(item: any) {
    return this.selectedcheckbox.indexOf(item) > -1;
  };

  toggleAll(event: any) {
    // this.isSelectAll = event.checked;
    // console.log(event,"#####################");
    if (event==true) {
      // this.mailboxService.gridView.forEach((row: any) => {
      //   this.selectedcheckbox.push(row);
      //   // row.IsChecked = true;
      //   // this.isSelectAll = true;
      // });
      this.setIsCheckedArray(true);


    } else {
      this.selectedcheckbox.length = 0;
      this.setIsCheckedArray(false);

    }
  }

  setIsCheckedArray=(checkedStatus:boolean)=>{
    console.log("setIsCheckedArray");

    if (checkedStatus) {
      this.selectedcheckbox=[];
      this.mailboxService.gridView.forEach((row: any) => {
        this.selectedcheckbox.push(row);
        row.IsChecked=true;
      });
      if(this.selectedcheckbox?.length >= 50){
            this.maxSelectAllMessageDialog();
      }
    }
    else{
      this.selectedcheckbox.length = 0;
      // this.selectedcheckbox=[];
      // this.mailboxService.gridView.forEach((row: any) => {
      //   this.selectedcheckbox.push(row);
      //   row.IsChecked=false;
      // });
    }
  }

  // Refresh
  refresh() {
    this.selectedId = null;
    this.mailboxService.gridView = [];
    this.selectedcheckbox.length = 0;
    this.page = 0;
    this.pageNumber=0;
    this.getRecievedMessages();
    this.searchemptymsg =false;
    // window.location.reload();
  }

  // All mails mark as read
  allMailsMarkAsRead() {
    this.imgSrc4= "assets/images/icons-text/Group 5033.svg";
    this.listInboxId = [];
    //  this.toggleAll(true);
    this.SelectedMail=false;
    this.selectedcheckbox.filter((value: any) => {
        this.listInboxId.push(value.InboxMessageId);
    });

    // const listId = this.selectedcheckbox.length > 9? 'All' : this.listInboxId;
    const listId = this.listInboxId;
    if(this.selectedcheckbox.length >= 50){
        this.maxSelectAllMessageDialog();
    }else{
      this.inboxService.mailMarkAsRead(this.userId, listId).subscribe((res: any) => {
      console.log(res);
      if (res) {
        for (let i = 0; i < this.selectedcheckbox.length; i++) {
          this.selectedcheckbox[i].IsRead = true;
          this.mailboxService.gridView = [];
          this.GetAllReceivedMessageCount();
          this.checkboxForm.controls['checkboxChecked']?.setValue(false);
          this.checkboxForm.controls['selectedCheckbox']?.setValue(false);
          this.selectedcheckbox.length = 0;
          this.selectedcheckbox=[];

          this.page = 0;
          this.pageNumber=0;
          this.getRecievedMessages();
        }
        this.mailboxService.gridView.forEach((row: any) => {
          this.selectedcheckbox.push(row);
          row.IsChecked=false;
        });
        this.selectedcheckbox.length=0;
        this.replyMessage=false;
      }
      this.selectedcheckbox = [];
    }, (err: any) => {
      console.log(err.message)
      this.toaster.error(err.error.Message)
    })
  }
    

    // for (let i = 0; i < this.selectedcheckbox.length; i++) {
    //   if (this.checkboxForm.value.checkboxChecked || this.checkboxForm.value.selectedCheckbox) {
    //     this.inboxService.mailMarkAsRead(this.userId, this.selectedcheckbox[i].InboxMessageId).subscribe((res: any) => {
    //       if (res) {
    //         for (let i = 0; i < this.selectedcheckbox.length; i++) {
    //           this.selectedcheckbox[i].IsRead = true;
    //           this.mailboxService.gridView = [];
    //           this.GetAllReceivedMessageCount();
    //           this.checkboxForm.controls['checkboxChecked']?.setValue(false);
    //           this.checkboxForm.controls['selectedCheckbox']?.setValue(false);
    //           this.selectedcheckbox.length = 0;
    //           this.selectedcheckbox=[];

    //           this.page = 0;
    //           this.getRecievedMessages();
    //         }
    //         this.mailboxService.gridView.forEach((row: any) => {
    //           this.selectedcheckbox.push(row);
    //           row.IsChecked=false;
    //         });
    //       }
    //     }, (err: any) => {
    //       console.log(err)
    //       this.toaster.error(err.error.Message)
    //     })
    //   }
    // }
  }

    // Maximum Selection Dialog on Select All Messages(50) - Starts
    maxSelectAllMessageDialog(){
    this.alertDialog = {
      title: 'Select All Message - Maximum 50',
      message: 'Current Selection: '+ this.selectedcheckbox?.length,
      alertType: AlertType.None,
      okButtonText: 'Close'
    };
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: this.alertDialog
    });
    }
    // Maximum Selection Dialog on Select All Messages(50) - Ends

  // All mails mark as unread
  allMailsMarkAsUnRead() {
    this.imgSrc5= "assets/images/icons-text/Group 5034.svg";
    console.log("markasunread");
    this.listInboxId = [];
    // this.toggleAll(true);

    this.selectedcheckbox.filter((value: any) => {
    this.listInboxId.push(value.InboxMessageId);
      // this.getRecievedMessages();
    });
    this.replyMessage=false;
    this.SelectedMail=false;
  //  const listId = this.selectedcheckbox.length > 9? 'All' : this.listInboxId;
   const listId = this.listInboxId;
   console.log(listId);
   if(this.selectedcheckbox.length >= 50){
    this.maxSelectAllMessageDialog();
   }else{
    this.inboxService.mailMarkAsUnReadUserId(this.userId, listId).subscribe((res: any) => {
      // this.selectedcheckbox[i].InboxMessageId
      if (res) {
        for (let i = 0; i < this.selectedcheckbox.length; i++) {
          this.selectedcheckbox[i].IsRead = false;
          this.mailboxService.gridView = [];
          this.GetAllReceivedMessageCount();
          this.checkboxForm.controls['checkboxChecked']?.setValue(false);
          this.checkboxForm.controls['selectedCheckbox']?.setValue(false);
          this.selectedcheckbox.length = 0;
          this.page = 0;
          this.pageNumber=0;
          this.getRecievedMessages();
          this.selectedcheckbox[i].IsChecked = false;
        }
        // this.mailboxService.gridView.forEach((row: any) => {
        //   this.selectedcheckbox.push(row);
        //   row.IsChecked=false;
        // });
      }
      this.selectedcheckbox.length=0;
      this.selectedcheckbox = [];
      // this.selectedcheckbox.IsChecked=false;
    }, (err: any) => {
      console.log(err)
      this.toaster.error(err.error.Message)
    })
   }


    

    // for (let i = 0; i < this.selectedcheckbox.length; i++) {
    //   if (this.checkboxForm.value.checkboxChecked || this.checkboxForm.value.selectedCheckbox) {
    //     this.inboxService.mailMarkAsUnread(this.selectedcheckbox[i].InboxMessageId).subscribe((res: any) => {
    //       // this.selectedcheckbox[i].InboxMessageId
    //       if (res) {
    //         for (let i = 0; i < this.selectedcheckbox.length; i++) {
    //           this.selectedcheckbox[i].IsRead = false;
    //           this.mailboxService.gridView = [];
    //           this.GetAllReceivedMessageCount();
    //           this.checkboxForm.controls['checkboxChecked']?.setValue(false);
    //           this.checkboxForm.controls['selectedCheckbox']?.setValue(false);
    //           this.selectedcheckbox.length = 0;
    //           this.page = 0;
    //           this.getRecievedMessages();
    //           this.selectedcheckbox[i].IsChecked = false;
    //         }
    //         // this.mailboxService.gridView.forEach((row: any) => {
    //         //   this.selectedcheckbox.push(row);
    //         //   row.IsChecked=false;
    //         // });
    //       }
    //     }, (err: any) => {
    //       console.log(err)
    //       this.toaster.error(err.error.Message)
    //     })
    //   }
    // }

  }

  // Selected record mark as read or mark as unread
  markasReadorUnread(InboxId: any, index: number) {
    this.inboxMessageId = InboxId;
    this.selectedAction = this.mailboxService.gridView[index].IsRead;
    console.log(this.selectedAction)
    this.replyMessage=false;
    this.SelectedMail=false;
    if (this.selectedAction == false) {
      this.inboxService.mailMarkAsRead(this.userId, InboxId).subscribe((res: any) => {
        if (res) {
          this.mailboxService.gridView[index].IsRead = true;
          if (this.mailboxService.gridView[index].IsRead == true) {
            // this.getRecievedMessages();
            this.GetAllReceivedMessageCount();
          }
          this.selectedcheckbox.length = 0;
          this.page = 0;
          this.pageNumber=0;
          this.mailboxService.gridView[index].IsChecked = false;
        }
      }, (err: any) => {
        console.log(err)
        this.toaster.error(err.error.Message)
      })
    }
    else if (this.selectedAction == true || this.mailboxService.gridView[index].IsRead == true) {
      console.log("unread")
      // InboxId
      this.inboxService.mailMarkAsUnread(InboxId).subscribe((res: any) => {
        if (res) {
          this.mailboxService.gridView[index].IsRead = false;
          // this.getRecievedMessages();
          this.GetAllReceivedMessageCount();
          this.selectedcheckbox.length = 0;
          this.page = 0;
          this.pageNumber=0;

        }
      }, (err: any) => {
        console.log(err)
        this.toaster.error(err.error.Message)
      })
    }

  }

  Inboxmovetodelete(inboxMessageId:any){
    console.log(inboxMessageId)
    let data: any = {
      "inboxId": inboxMessageId,
      "IsSentItem": false
    }
    const dialogRef = this.deletedialog.open(MovetoinboxDialogComponent, {
      data: {
        alldata: data,
        id: 'You want to delete the message'
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {

        this.inBoxService.deleteToTrash(inboxMessageId,false).subscribe(res => {
          console.log(res)
          if (res) {
            this.toaster.success(
              this.translate.instant('Deleted successfully'));
              this.mailboxService.gridView =[];
            this.page=0;
            this.pageNumber=0;
            this.getRecievedMessages();
            this.replyMessage=false;
            this.SelectedMail=false;
          }
        })
      }
    })
  }

  /* open archive confirm box */
  movetoArchive(inboxMessageId: any) {
    console.log('selected', this.selectedMessagesDetails)
    console.log(inboxMessageId)
    let data: any = {
      "userID": this.userId,
      "inboxId": inboxMessageId,
      "IsSentItem": false
    }
    const dialogRef = this.dialog.open(MovetoinboxDialogComponent, {
      data: {
        alldata: data,
        id: 'You want to move to archive.'
      }
    });
    //Call move to Archive API based on the confirm Dialod Result
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {

        this.inBoxService.moveToArchive(this.userId, inboxMessageId, false).subscribe(res => {
          console.log(res)
          if (res == 1) {
            this.toaster.success(
              this.translate.instant('Moved to archive list successfully'));
            this.page = 0;
            this.pageNumber=0;
            // Inbox Count
            this.inboxService.inboxCount(this.userId).subscribe(data => {
              this.inboxCount = data;
              this.mailboxService.mailstype[0].count=data;
              this.clearData();
              console.log(this.inboxCount)
            })
            this.mailboxService.gridView.forEach((row: any) => {
              this.selectedcheckbox.push(row);
              row.IsChecked=false;
            });


            this.replyMessage=false;
            this.SelectedMail=false;
            // this.setSelectedMailType(this.mailboxService.gridView.InboxMessageId);
          }
        })
      }
    })
  }


  // All mails move to archive
  archiveAll() {
    this.imgSrc6= "assets/images/icons-text/Group 5068.svg";
    console.log("archiveall");
    this.listInboxId = [];
    // this.toggleAll(true);
    this.selectedcheckbox.filter((value: any) => {
    this.listInboxId.push(value.InboxMessageId);
    });
    //const listId = this.selectedcheckbox.length > 9 ? 'All' : this.listInboxId;
    const listId = this.listInboxId;
    console.log('Archive List Logs')
    console.log(listId);
    if(this.selectedcheckbox.length >= 50){
      this.maxSelectAllMessageDialog();
     }else{
      this.inboxService.moveToArchive(this.userId, listId, false).subscribe((res: any) => {
        if (res == 1) {
          console.log(res)
          this.page = 0;
          this.pageNumber=0;
          this.inboxService.inboxCount(this.userId).subscribe(data => {
            this.inboxCount = data;
            this.mailboxService.mailstype[0].count=data;
            this.clearData();
            console.log(this.inboxCount)
          })
        }
        this.selectedcheckbox.length=0;
        this.selectedcheckbox = [];
        this.replyMessage=false;
      }, (err: any) => {
        console.log(err)
        this.toaster.error(err.error.Message)
      })
      // for (let i = 0; i < this.selectedcheckbox.length; i++) {
      //   if (this.checkboxForm.value.checkboxChecked || this.checkboxForm.value.selectedCheckbox) {
      //     this.inboxService.moveToArchive(this.userId, this.selectedcheckbox[i].InboxMessageId, false).subscribe((res: any) => {
      //       if (res == 1) {
      //         console.log(res)
      //         this.page = 0;
      //         this.inboxService.inboxCount(this.userId).subscribe(data => {
      //           this.inboxCount = data;
      //           this.clearData();
      //           console.log(this.inboxCount)
      //         })
      //       }
      //     }, (err: any) => {
      //       console.log(err)
      //       this.toaster.error(err.error.Message)
      //     })
      //   }
      // }
      this.toaster.success(
        this.translate.instant('Moved to archive list successfully'));
     }    
  }

  clearData() {
    this.mailboxService.gridView = [];
    this.getRecievedMessages();
    this.checkboxForm.controls['checkboxChecked']?.setValue(false);
    this.checkboxForm.controls['selectedCheckbox']?.setValue(false);
    this.selectedcheckbox.length = 0;
  }

  changeMailbox(event: any) {
    this.mailboxService.selectedMailBoxType = event.value;
  }
  loadComponent(value: any) {
    this.router.navigate(['/mailbox/', value]);
  }
  /* here get the inbox message Id */
  // setSelectedMailType(messageId: string, index: number) {
  setSelectedMailType(messageId: string, index: number = 0) {
    this.selectedId = null;
    this.SelectedMail= index;
    this.ShowDetail =false;
    this.replyMessage = true;
    // this.mailboxService.messageId = messageId;
    this.mailboxService.messageId = messageId;
    this.mailboxService.index = index;
    this.mailboxService.gridView[index].IsRead = true;
    // this.mailboxService.gridView = [];
    // this.page=0;
    // this.getRecievedMessages();
    sessionStorage.removeItem('inboxSSOId');
    this.getSelectedMailDetails();
    this.ismailSelected = true;
  }

  getSelectedMailDetails() {
    this.inBoxService.getSelectedMailDetails(this.userId, this.mailboxService.messageId).subscribe((result: any) => {
      console.log(result,"****************result *********************")
      if(result){
      this.selectedMessagesDetails = result;
      if (this.selectedMessagesDetails[0]?.MessageBody != null) {
        this.selectedMessagesDetails[0].MessageBody = this.selectedMessagesDetails[0].MessageBody ? this.sanitizer.bypassSecurityTrustHtml(this.inBoxService.replaceImgTags(this.selectedMessagesDetails[0]?.MessageBody)) : '';
      }
      if (this.selectedMessagesDetails[0].FromUserId != null) {
        this.inBoxService.getDraftPracticeDetails(this.userId, 1).subscribe((res:any) => {
          this.getPracticeData = res;
          this.inboxService.getServiceTypeByBillable(result[0]?.MessageTypeID).subscribe(res => {
            this.selectedServiceTypeData = res;
          });
        })
      }

      // selected mail mark as read
      let inboxid = this.selectedMessagesDetails.filter((x: any) => x.InboxMessageId == this.mailboxService.messageId)
      if (inboxid[0].IsRead == false) {
        this.inboxService.mailMarkAsRead(this.userId, this.mailboxService.messageId).subscribe((res: any) => {
          if (res) {
            inboxid[0].IsRead = true;
            // this.mailboxService.gridView = [];
            this.page=0;
            this.pageNumber=0;
            // this.getRecievedMessages();
            this.GetAllReceivedMessageCount();
          }
        })
      }
      this.xmltoJSON = JSON.parse(this.selectedMessagesDetails[0].attachment);
      console.log(this.xmltoJSON)
      // if (this.xmltoJSON.ROOT != "") {
        if (this.xmltoJSON != "") {
        let singleAttachment: any = [];
        singleAttachment = this.xmltoJSON.ROOT.Inbox_Attachments;
        console.log("singleAttachment : ",singleAttachment);

        this.filesData = singleAttachment;
        console.log(this.filesData);
        console.log("filesData.length : ",this.filesData?.length);

      }
    }
    if(this.paramValue  == 'reply') {
      setTimeout(() => {
        if(this.isMobile) {
          // mobile
          this.replyMessageNavigate('Detail');
        } else {
          this.replyMessageShow('Detail');
        }

      this.removeAllQueryParams();

      }, 1000);

    }
    })
  }

  removeAllQueryParams() {
    const navigationExtras: NavigationExtras = {
      queryParams: null,
      queryParamsHandling: ''
    };

    // Navigate to the same route without any query parameters
    this.router.navigate([], navigationExtras);
  }

  checkReplyMessage() {
    let canReply = true;
    console.log("-------------------------------------------------------------------------------------------------------------------------------------")
    if (this.patientid != -1) {
      if (this.selectedMessagesDetails?.[0]?.IsPatientSuspended) {
        canReply = false;
        this.openReplyDialog(`${this.selectedMessagesDetails[0]?.HealthCentreName} has placed your Manage My Health registration on hold. Please contact ${this.selectedMessagesDetails[0]?.HealthCentreName} for further information.`);
      }
      else if (this.selectedServiceTypeData?.[0]?.IsActive == '0') {
        canReply = false;
        this.openReplyDialog('Your Health Centre has deactivated this service. You have the option to choose a different service and continue, or contact your Health Centre for assistance.');
      }
      else if ( this.selectedServiceTypeData?.[0]?.RestrictReply == true  ) {
        canReply = false;
        this.openReplyDialog('NotConfigruredReply');
      }

    }else{
      if (this.selectedServiceTypeData?.[0]?.IsActive == '0') {
        canReply = false;
        this.openReplyDialog('Your Health Centre has deactivated this service. You have the option to choose a different service and continue, or contact your Health Centre for assistance.');
      }
    }
    return canReply;
  }

  replyMessageNavigate(mailType: any) {
    this.imgSrc1 = 'assets/images/Group 5014.svg';
    let serviceData = this.commonService.getServiceDetail(DashboardServiceData.SendMessage);
    if (serviceData && serviceData?.result?.toLowerCase() == CommonConstants.success) {
      if (this.checkReplyMessage()) {
        this.ShowDetail = true;
        this.mailboxService.selectedMailBoxType = mailType;
      }
  }
  else {
    this.commonService.openServiceDialog(serviceData);
  }
  }

  replyMessageShow(mailType: any) {
    this.imgSrc1 = 'assets/images/Group 5014.svg';
     let serviceData = this.commonService.getServiceDetail(DashboardServiceData.SendMessage);
      if (serviceData && serviceData?.result?.toLowerCase() == CommonConstants.success) {
        if (this.checkReplyMessage()) {
          this.ShowDetail = true;
          console.log(mailType)
        }
    }
    else {
      this.commonService.openServiceDialog(serviceData);
    }

  }

  openReplyDialog(message: string) {
    this.alertDialog = {
      title: 'Info',
      message: this.translate.instant(message),
      alertType: AlertType.None,
      okButtonText: this.translate.instant('OK')
    };
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: this.alertDialog
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result == true) {
        this.replyMessage = false;
        this.SelectedMail = false;
      }
    })
  }

  close() {
    this.replyMessage = false;
    this.SelectedMail = false;
    this.ismailSelected = false;
  }

  onToggle(event: any): void {
    this.showdetails = !this.showdetails;
    this.tooltipOffset = { left: event.pageX + 4, top: event.pageY + 4 };
  }
  hideTooltip() {
    this.showdetails = !this.showdetails;
  }

  // Download Files
  downloadFiles(filename: any) {
    console.log("downloadFiles filename: ",filename)
    let encodefilename = btoa(filename)
    console.log("downloadFiles : ",encodefilename)
    window.open(`${environment.apiBaseURL}${URLConstants.downloadFile}${encodefilename}`, '_blank');

  }

  // search value
  Searchkey(searchkeyvalue: any): void {
    console.log(searchkeyvalue, "searchvalue")
    this.isSearch = true;
    this.mailboxService.sendMessage(searchkeyvalue);
  }

  clearSearchData() {
    this.searchTexts = "";
    this.mailboxService.gridView = [];
    this.page=0;
    this.pageNumber=0;
    this.searchText='';
    this.selectedId = null;
    this.replyMessage = false;
    this.SelectedMail = false;
    // setTimeout(() => {
      this.getRecievedMessages();
    // }, 1000)
  }

  // loaddata(Data:any)
  // {
  //   switch(Data){
  //     case (Data >= 5000 && Data < 6000):
  //       this.pagesize=70;
  //       this.getRecievedMessages();
  //       break;

  //       case (Data >= 4000 && Data < 5000):
  //       this.pagesize=60;
  //       this.getRecievedMessages();
  //       break;

  //     case (Data >= 3000 && Data < 4000):
  //       this.pagesize=50;
  //       this.getRecievedMessages();
  //       break;

  //       case (Data >= 2000 && Data < 3000):
  //       this.pagesize=40;
  //       this.getRecievedMessages();
  //       break;

  //       case (Data >= 1000 && Data < 2000):
  //         this.pagesize=30;
  //         this.getRecievedMessages();
  //         break;

  //         case (Data >= 100 && Data < 1000):
  //         this.pagesize=20;
  //         this.getRecievedMessages();
  //         break;

  //       default:
  //         this.pagesize=10;
  //         this.getRecievedMessages();
  //         break;
  //   }
  // }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  getPatientSuspendDetails(){
    let canCompose = true;
    if(this.patientid != -1){
    applicationHttpClientCreator(this.http).Get(`${URLConstants.getPatientSuspendDetails}${this.userId}`, {
    }).subscribe((data: any) => {
      let suspendDetails = data.filter((d: any) => !d.IsSuspended);
      if (data && data.length && data[0].IsSuspended && suspendDetails.length == 0) {
        canCompose = false;
        this.openReplyDialog(`${data[0].BusinessName} has placed your Manage My Health registration on hold. Please contact ${data[0].BusinessName} for further information.`);
      }
      else{
        let serviceData = this.commonService.getServiceDetail(DashboardServiceData.SendMessage);
        if (serviceData && serviceData?.result?.toLowerCase() == CommonConstants.success) {
          this.router.navigate(['/pages/navigate-routes/Compose']);
        }
        else {
          this.commonService.openServiceDialog(serviceData);
        }
      }
     
    }, (err: any) => {
      console.log(err);
    });}
    else{
      let serviceData = this.commonService.getServiceDetail(DashboardServiceData.SendMessage);
        if (serviceData && serviceData?.result?.toLowerCase() == CommonConstants.success) {
          this.router.navigate(['/pages/navigate-routes/Compose']);
        }
        else {
          this.commonService.openServiceDialog(serviceData);
        }
    }
    return canCompose;
  }

  getStaffSuspendDetails(){
    let canCompose = true;
    if(!this.isPatient){
    applicationHttpClientCreator(this.http).Get(`${URLConstants.getStaffsuspenddetails}${this.userId}`, {
    }).subscribe((data: any) => {
      if (data && data.length && data[0].IsSuspended) {
        canCompose = false;
        this.openReplyDialog(`${data[0].BusinessName} has placed your Manage My Health registration on hold. Please contact ${data[0].BusinessName} for further information.`);
      }
      else{
        let serviceData = this.commonService.getServiceDetail(DashboardServiceData.SendMessage);
        if (serviceData && serviceData?.result?.toLowerCase() == CommonConstants.success) {
          this.router.navigate(['/pages/navigate-routes/Compose']);
        }
        else {
          this.commonService.openServiceDialog(serviceData);
        }
      }
     
    }, (err: any) => {
      console.log(err);
    });}
    else{
      let serviceData = this.commonService.getServiceDetail(DashboardServiceData.SendMessage);
        if (serviceData && serviceData?.result?.toLowerCase() == CommonConstants.success) {
          this.router.navigate(['/pages/navigate-routes/Compose']);
        }
        else {
          this.commonService.openServiceDialog(serviceData);
        }
    }
    return canCompose;
  }
}



