import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'dateConvert',
})
export class DateConvertPipe implements PipeTransform {
  transform(date: any, format?: string): any {
    let returnDate = 'NA';
    if(date) {
      format = format || 'DD MMM YYYY';
      const parseZoneDate = moment.parseZone(date);
      returnDate = parseZoneDate.format(format);
    }
    return returnDate;

  }
}
