 <div class="slide-tray-content send-message">
    <perfect-scrollbar class="heights" [config]="config">
        <div class="mobile-view page-header header-buttons m-b-20 m-t-20 m-l-20 m-b-sm-0 mobile-view">
            <h1 class="d-flex align-items-center" (click)="backToArchive()">
            <i class="icon-cent-chevron-left-arrows color-dark cursor-pointer" (click)="backToArchive()">
            </i>
            {{'Back'|translate}}
            </h1>
        </div> 
        <div class="email-view-content" *ngIf="showMailMsg==true">
            <div class="mail-content-wrapper">
                <form [formGroup]="detailForm">

                    <div class="mail-info-sub">
                        <div class="mail-info-sub">

                            <div class="grid-normal-tbl">
                                <div class="tbl-row">
                                    <div class="tbl-th">{{'From'|translate}}:</div>
                                    <div class="tbl-td">{{selectedMessagesDetails[0]?.FromName}}</div>
                                </div>
                                <div class="tbl-row">
                                    <div class="tbl-th">{{'To'|translate}}:</div>
                                    <div class="tbl-td">{{selectedMessagesDetails[0]?.ToName}}</div>
                                </div>
                                <div class="tbl-row">
                                    <div class="tbl-th">{{'Service Type'|translate}}:</div>
                                    <div class="tbl-td">{{selectedMessagesDetails[0]?.MessageCode}}</div>
                                </div>
                                <div class="tbl-row">
                                    <div class="tbl-th">{{'Date'|translate}}:</div>
                                    <div class="tbl-td">{{selectedMessagesDetails[0]?.date | date: 'dd MMM yyyy hh:mm a'}}</div>
                                </div>
                            </div>
                        </div>

                        <div class="filter-by-index page-header m-b-sm-0" style="padding: 0px;">
                            <div class="flexbox1-detail">
                                <div fxLayout="row" fxLayout.lt-md="row" fxLayoutGap="0px" fxLayoutGap.gt-md="80px">

                                    <div fxFlex="100" fxFlex.gt-md="100">
                                        <mat-form-field appearance="outline"
                                            [ngClass]="{ 'has-success': f.Subject.valid}">
                                            <!-- <mat-label>{{'Subject'|translate}}</mat-label> -->
                                            <input matInput type="text" formControlName="Subject" placeholder="Subject">
                                            <mat-icon class="error" *ngIf="f.Subject.invalid && f.Subject.touched">
                                                info_outline</mat-icon>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <mat-error *ngIf="f.Subject.hasError('required') && f.Subject.touched"
                                    class="text-danger support-text  error-name">{{'Required'|translate}}</mat-error>
                            </div>
                                
                        </div>

                        <!-- <div class="m-b-20 m-t-10 attach-file">
                        <button mat-button class="btn-primary-rect" *ngIf="addedFiles.length < 3" (click)="attachFileDialog()">
                            <mat-icon class="icon-line-height">attachment</mat-icon> {{ "Attach Files" | translate }}
                        </button>
                        </div>

                        <div class="documents">
                            <p *ngFor="let file of addedFiles; let i=index">{{file?.name}}
                                <mat-icon (click)="remove(i)">delete
                                </mat-icon>
                            </p>
                        </div> -->
                        
                        <div class="m-b-20 m-t-10 attach-file">
                            <button mat-button class="btn-primary-rect" *ngIf="addedFiles.length < 3" (click)="attachFileDialog()">    
                            <mat-icon class="icon-line-height">attachment</mat-icon> {{ "Attach Files" | translate }}    
                            </button>    
                            <div class="filter-by-index page-header m-b-sm-0" style="padding: 0px;" *ngFor="let file of addedFiles; let i = index">    
                                <p>{{ file?.name }}</p>    
                                    <div class="icon-container">    
                                    <mat-icon (click)="remove(i)">delete_outline</mat-icon>    
                                    </div>    
                                    <mat-error *ngIf="addedFiles.length > 3">{{ "filelength validation" | translate }}</mat-error>
                            </div>
                        </div>
                        
                        <div class="mail-spacer-line"></div>
                        <div class="email-content m-t-20">
                            <!-- <mat-form-field appearance="outline"> -->
                            <mat-label><b>{{'Write your message'|translate}}</b></mat-label>
                            <!-- <div class="save-indicator">{{saveIndicator}}</div> -->


                            <kendo-editor formControlName="bodymessage" class="kendo-editor-box"></kendo-editor>

                            <mat-error *ngIf="f.bodymessage.hasError('required') && f.bodymessage.touched"
                                class="text-danger support-text  error-name">{{'Required'|translate}}
                            </mat-error>

                        </div>
                    </div>

                    <!-------------------------------- footer ----------------------------------->
                    <div class="email-view-footers desktop-view m-t-20" style="padding:0px;">
                        <div class="footer-button m-t-20">
                            <button mat-button class="btn-secondary-pill-gray m-r-10" (click)="closeSelectedMail()" style="white-space: initial;line-height: 18px;" (click)="sendMesssage('Drafts')">
                                <span class="icon-text-footer-align"><mat-icon class="m-r-10">save</mat-icon> {{'Save as Draft'|translate}}</span></button>
    
                            <button mat-button (click)="closeSelectedMail()" class="btn-primary-pill" (click)="sendMesssage('Archive')" type="submit"> 
                                <span class="icon-text-footer-align"><mat-icon class="m-r-10">send</mat-icon>{{'Send message'|translate}}</span> </button>
                        </div>
                    </div>


                    <div class="mobile-view email-view-footer mbl-foot-btn">
                        <div class="footer-buttons ftr-sm-2-row-btns">
    
                            <button mat-button class="btn-secondary-pill-gray m-r-10" (click)="sendMesssage('Drafts')">
                                <span class="icon-text-footer-align"><mat-icon class="m-r-10">save</mat-icon> {{'Save as Draft'|translate}}</span></button>
    
                            <button mat-button class="btn-primary-pill" (click)="sendMesssage('Inbox')" type="submit"> 
                                <span class="icon-text-footer-align"><mat-icon class="m-r-10">send</mat-icon>{{'Send message'|translate}}</span></button>
                        </div>
                    </div>

                </form>

            </div>
        </div>
        <div *ngIf="showMailBox==true" class="d-flex align-items-center justify-content-center detail-part movetodetail selected-message">
    <div class="text-center d-md-none selected-message2">
        <img src="../../../../assets/images/message123.svg" alt="mail-box">
        <p>Select an item to read</p>
    </div> 
    </div>
    </perfect-scrollbar>
    </div>