<div class="connection-dialog content-panel p-0">
    <h3 class="m-t-0 text-center">
      {{'Are you sure?'|translate}}
    </h3>
    <p class="text-center">{{message}}</p>
    <div class="text-center">
      <button mat-button (click)="onDismiss()" class="btn-text-36 m-r-10">{{'no'|translate}}</button>
      <button mat-button (click)="onConfirm()" class="btn-primary-36">{{'YES'|translate}}</button>
      
    </div>
  </div>