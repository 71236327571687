import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CommonConstants } from 'src/app/core/constants/commonConstants';
import { Toast } from '../../../data/interface/toast.interface';
import { ToasterService } from '../../../data/service/toaster.service';
@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.css']
})
export class ToasterComponent implements OnInit {

  @Input() toast: Toast | undefined;
  @Input() i = 0;

  constructor(private toaster: ToasterService) { }

  toasts: Toast[] = [];
  ngOnInit(): void {

    this.toaster.toast$.subscribe(toast => {
      this.toasts = [toast, ...this.toasts];
      setTimeout(() => this.toasts.pop(), CommonConstants.toasterDelayTime);
    });
    this.remove(0);

  }
  remove(index: number): void {
    this.toasts = this.toasts.filter((v, i) => i !== index);
  }

}
