import { SmsRegistrationModule } from './modules/sms-registration/sms-registration.module';
import { LoginComponent } from './modules/pages-auth/login/login.component';
import { Routes } from '@angular/router';
import { FullComponent } from './layouts/full/full.component';
import { EmptyComponent } from './layouts/full/empty.component';
import { HomeComponent } from './modules/pages-public/home/home.component';
import { ContactUsComponent } from './modules/pages-public/contact-us/contact-us.component';
import { HowToRegisterComponent } from './modules/pages-public/how-to-register/how-to-register.component';
import { ComposeComponent } from './modules/pages-inbox/compose/compose.component';
import { MailListComponent } from './modules/pages-inbox/mail-list/mail-list.component';
import { DetailComponent } from './modules/pages-inbox/detail/detail.component';
import { SentComponent } from './modules/pages-inbox/sent/sent.component';
import { DraftsComponent } from './modules/pages-inbox/drafts/drafts.component';
import { GroupsComponent } from './modules/pages-inbox/groups/groups.component';
import { ArchiveComponent } from './modules/pages-inbox/archive/archive.component';
import { ArchivedetailComponent } from './modules/pages-inbox/archivedetail/archivedetail.component';
import { DraftdetailComponent } from './modules/pages-inbox/draftdetail/draftdetail.component';
import { NavigateRoutesComponent } from './modules/pages-feature/navigate-routes/navigate-routes.component';
import { AuthGuard } from './core/guard/auth.guard';
// import { MmhV2VideocallComponent } from './modules/pages-video-consultation/vonage/mmh-v2-videocall.component';
import { DiscoverHealthTokenResolver } from './modules/discover-health/resolver/discover-health-token.resolver';
import { DisabledBrowserBackForthNaviagtionGuard } from './core/guard/disable-browser-back-forth-navigation.guard';
import { SettingsComponent } from './modules/pages-inbox/settings/settings.component';
import { PatientMyPaymentsComponent } from './modules/pages-my-payment/payment/patient-my-payments.component';
import { PaymentFailureResponseComponent } from './modules/pages-my-payment/payment-failure-response/payment-failure-response.component';
import { PaymentResponseComponent } from './modules/pages-my-payment/payment-response/payment-response.component';

export const AppRoutes: Routes = [
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    { path: 'home', component: HomeComponent },
    {
        path: 'contact-us',
        component: ContactUsComponent,

    },
    {
        path: 'how-to-register',
        component: HowToRegisterComponent,

    },
    // {
    //     path:'payment',
    //     component:PatientMyPaymentsComponent
    // },
    //  {
    //     path:'payment/:EasyBooking',
    //      component:PatientMyPaymentsComponent
    //  },
    {
        path: 'payment-easy-booking/:EasyBooking',
        component: PatientMyPaymentsComponent
    },
    {
        path: 'payment-failure-response/:txnId',
        component: PaymentFailureResponseComponent
    },
    {
        path: 'payment-response/:txnId',
        component: PaymentResponseComponent
    },

    // {
    //     path: 'call/:roomId/:name',
    //     component: MmhV2VideocallComponent,

    // },
    {
        path: 'meeting',
        loadChildren: () => import('./modules/pages-join-meeting/pages-join-meeting.module').then(m => m.PagesJoinMeetingModule)
    },
    {
        path: 'btb',
        loadChildren: () => import('./modules/pages-btb/pages-btb.module').then(m => m.PagesBtbModule)
    },
    // this is for anonymous user
    // {
    //     path: 'naviagte-routes/:url',
    //     component:NavigateRoutesComponent
    // },
    {
        path: '',
        component: FullComponent,
        children: [
            {
                path: '',
                redirectTo: '/dashboards/dashboard1',
                pathMatch: 'full'
            },
            {
                path: 'dashboard',
                redirectTo: '/dashboards/dashboard1',
                pathMatch: 'full'
            },
            {
                path: 'my-appointment',
                redirectTo: '/my-appointment/patient-home/patient-home.component',
                pathMatch: 'full'
            },
            { path: '', component: ComposeComponent, outlet: 'compose' },
            { path: '', component: MailListComponent, outlet: 'list' },
            { path: '', component: DetailComponent, outlet: 'detail' },
            { path: '', component: SentComponent, outlet: 'sent' },
            { path: '', component: DraftsComponent, outlet: 'drafts' },
            { path: '', component: GroupsComponent, outlet: 'groups' },
            { path: '', component: ArchiveComponent, outlet: 'archive' },
            { path: '', component: ArchivedetailComponent, outlet: 'archivedetail' },
            { path: '', component: DraftdetailComponent, outlet: 'draftdetail' },
            { path: '', component: SettingsComponent, outlet: 'settings' },
            {
                path: 'dashboards',
                loadChildren: () => import('./modules/pages-dashboard/pages-dashboard.module').then(m => m.DashboardsModule)
            },
            {
                path: 'discover-health',
                loadChildren: () => import('./modules/discover-health/discover-health.module').then(m => m.DiscoverHealthModule),
                resolve: {
                    tokenResolver: DiscoverHealthTokenResolver
                }
            },

            {
                path: 'reach',
                loadChildren: () => import('./modules/ads-landing-page/ads-landing-page.module').then(m => m.AdsLandingPageModule)
            },

            {
                path: 'pages',
                loadChildren: () => import('./modules/pages-feature/pages-feature.module').then(m => m.PagesModule)
            },
            {
                path: 'appointment',
                loadChildren: () => import('./modules/pages-appointment/pages-appointment.module').then(m => m.MyAppointmentModule)
            },
            {
                path: 'myaccount',
                loadChildren: () => import('./modules/pages-my-account/pages-my-account.module').then(m => m.MyAccountModule)
            },
            {
                path: 'myhealth',
                loadChildren: () => import('./modules/pages-my-health/pages-my-health.module').then(m => m.MyHealthModule)
            },
            {
                path: 'mydiary',
                loadChildren: () => import('./modules/pages-my-health-diary/pages-my-health-diary.module').then(m => m.PagesMyHealthDiaryModule)
            },
            {
                path: 'productupdates',
                loadChildren: () => import('./modules/pages-product-updates/pages-product-updates.module').then(m => m.PagesProductUpdatesModule)
            },
            {
                path: 'sms',
                loadChildren: () => import('./modules/sms-credit-facility/sms-credit-facility.module').then(m => m.SmsCreditFacilityModule)
            },
            {
                path: 'payment',
                loadChildren: () => import('./modules/pages-my-payment/pages-my-payment.module').then(m => m.MyPaymentModule)
            },
            {
                path: 'rrp',
                loadChildren: () => import('./modules/pages-repeat-prescription/pages-repeat-prescription.module')
                    .then(m => m.RepeatPrescriptionModule)
            },
            {
                path: 'settings',
                loadChildren: () => import('./modules/pages-settings/pages-settings.module')
                    .then(m => m.SettingsModule)
            },
            {
                path: 'video',
                loadChildren: () => import('./modules/pages-video-consultation/pages-video-consultation.module')
                    .then(m => m.VideoConsultationModule)
            },
            {
                path: 'practice',
                loadChildren: () => import('./modules/pages-practice/pages-practice.module')
                    .then(m => m.PracticeModule)
            },
            {
                path: 'beating-the-blues',
                loadChildren: () => import('./modules/pages-beating-the-blues/pages-beating-the-blues.module')
                    .then(m => m.BeatingTheBluesModule)
            },
            {
                path: 'usage-menu',
                loadChildren: () => import('./modules/pages-usage-menu/pages-usage-menu.module')
                    .then(m => m.UsageMenuModule)
            },
            {
                path: 'medicalert',
                loadChildren: () => import('./modules/pages-medic-alert/pages-medic-alert.module')
                    .then(m => m.MedicAlertModule)
            },
            {
                path: 'sehr',
                loadChildren: () => import('./modules/pages-sehr/pages-sehr.module')
                    .then(m => m.SehrModule)
            },
            {
                path: 'shared-care',
                loadChildren: () => import('./modules/pages-shared-care/pages-shared-care.module')
                    .then(m => m.SharedCareModule)
            },
            {
                path: 'referrals',
                loadChildren: () => import('./modules/pages-referrals/pages-referrals.module')
                    .then(m => m.PagesReferralsModule)
            },
            {
                path: 'integrated-care',
                loadChildren: () => import('./modules/pages-integrated-care/pages-integrated-care.module')
                    .then(m => m.IntegratedCareModule)
            },
            {
                path: 'admin-reports',
                loadChildren: () => import('./modules/pages-admin-report/pages-admin-report.module')
                    .then(m => m.PagesAdminReportModule)
            },
            {
                path: 'meetingUser',
                loadChildren: () => import('./modules/pages-join-meeting/pages-join-meeting.module').then(m => m.PagesJoinMeetingModule)
            },
            {
                path: 'smsfacility',
                loadChildren: () => import('./modules/pages-sms-credit-facility/pages-sms-credit-facility.module')
                    .then(m => m.PagesSmsCreditFacilityModule)
            },
            {
                path: 'manage-user',
                loadChildren: () => import('./modules/pages-manage-user/pages-manage-user.module').then(m => m.PagesManageUserModule)

            },
            {
                path: 'customform',
                loadChildren: () => import('./modules/page-dynamic-forms/page-dynamic-forms.module').then(m => m.PageDynamicFormsModule)

            },
            {
                path: 'hra',
                loadChildren: () => import('./modules/pages-hra/pages-hra.module')
                    .then(m => m.PagesHraModule)
            },
        ],
        canActivate: [AuthGuard],
        runGuardsAndResolvers: 'always',
    },
    {
        path: '',
        component: EmptyComponent,
        children: [
            {
                path: 'authentication',
                loadChildren:
                    () => import('./modules/pages-auth/pages-auth.module').then(m => m.AuthenticationModule)
            }
        ]
    },
    {
        path: 'r/:token',
        loadChildren: () => import('./modules/sms-registration/sms-registration.module')
            .then(m => m.SmsRegistrationModule)
    },
    {
        path: 'c/:token',
        loadChildren: () => import('./modules/account-closure/account-closure.module')
            .then(m => m.AccountClosureModule)
    },
    {
        path: '**',
        redirectTo: 'authentication/404'
    }
];
