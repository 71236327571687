import { Directive, ElementRef, HostListener, Optional, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { URLConstants } from 'src/app/core/constants/urlConstants';
import { applicationHttpClientCreator } from 'src/app/data/interface/IRequestOptions';

@Directive({
  selector: '[appLandlineNumber]'
})
export class LandlineNumberDirective {



  textbox: HTMLInputElement;

  constructor(
    private http: HttpClient,
    private el: ElementRef,
    private renderer: Renderer2,
    @Optional() private ngControl: NgControl | null,
  ) { }

  ngAfterViewInit() {
    this.textbox =
      this.el.nativeElement.tagName === "INPUT"
        ? this.el.nativeElement
        : this.el.nativeElement.querySelector("input");
  }

  @HostListener("blur", ["$event"]) onBlur(event: any) {
    let initVal = this.textbox.value //.replace(/-|\s/g, '').trim();
    initVal = initVal.replace(/\s/g, '')   // Removing all the spaces
    initVal = initVal.replace(/^00/, '+')   // staring with 00 changing to +
    initVal = initVal.replace(/^\(00/, '(+')  // staring with (00  changing to (+
    const countryCode = "(+64)"
    if (initVal == countryCode) {
      initVal = ""
      this.renderer.setProperty(this.textbox, "value", "");
    }
    else  if (initVal != ""){
      if (initVal.match(/^\(\+[0-9]{1,3}\)/)) {
        let match = initVal.match(/^((\(\+[0-9]{1,3}\)|\+[0-9]{1,3}))/)
        let contryend = initVal.indexOf(')')
        console.log("country code end" + contryend);
      }
      else if (initVal.match(/^\+[0-9]{1,3}/)) {
        initVal = initVal.replace(/\+64/, '(+64)')
      }
      else {
        initVal = '(+64)' + initVal
      }
    }
    if (this.ngControl) {
      this.ngControl.control!.setValue(initVal, { emitEvent: false });
    } else {

      this.renderer.setProperty(this.textbox, "value", initVal);
    }
  }
  @HostListener("focus", ["$event"]) onFocus(event: any) {
    let initVal = this.textbox.value
    const countryCode = "(+64)"
    this.ngControl?.control!.markAsUntouched({onlySelf:true})    
    if (initVal.length == 0) {
      initVal = countryCode
    }
    if (this.ngControl) {
      this.ngControl.control!.setValue(initVal, { emitEvent: false });
    } else {

      this.renderer.setProperty(this.textbox, "value", initVal);
    }
  }
}
