<!-- <form [formGroup]="fileUploadForm"> -->
<div class="m-b-10 show-file" [ngClass]="{'disabled' : isDisabled()}">
    <i class="icon-cent-attachment-files"></i> ADD Files 
    <input *ngIf="!isDisabled()" #fileInput (click)="fileInput.value = null" value="" formControlName="uploadDocument" class="hide-file" type="file" placeholder="file" accept="{{fileTypes}}" (change)="onFileChange($event)" (pan)="setIsTouch()"/>
    <input *ngIf="isDisabled()" #fileInput [attr.disabled]="true" (click)="fileInput.value = null" value="" formControlName="uploadDocument" class="hide-file" type="file" placeholder="file" accept="{{fileTypes}}" (change)="onFileChange($event)" (pan)="setIsTouch()"/>
</div>
<div>
    <span *ngIf="isValid" class="font-14">
        (Maximum File Size-5MB), Supported File Type: ({{fileTypes}})
    </span>
    <span *ngIf="!isValid" class="font-14-12">
        {{ validateMsg }}
    </span>
</div>
<!-- ** BEGIN: INFORMATION MESSAGE AREA ** -->
<div *ngIf="arrFiles.length > 0 && showFiles" class="font-14 d-flex align-items-center">
    <div *ngFor="let file of arrFiles; let k =index" class="w-100 m-t-5 d-flex align-items-center justify-content-space-between">
        <span>{{file.fileName}}</span><button class="cursor-pointer delete-icon" (click) = "deleteFile(file, k)"><mat-icon >delete</mat-icon></button>
    </div>

</div>
<!-- </form> -->