import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class MicroserviceInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    if (environment.useMicroService) {
      /********** Appointment **********/
      /********** Appointment Configuration **********/
      /********** Appointment Configuration **********/
      if (request.url.includes('/api/InsertAppointmentSlotPeriodsMaster/') ||
        request.url.includes('/api/Appointment/updateSlotsDayOff') ||
         request.url.includes('/api/Appointment/deleteSlotsDayOff') || 
         request.url.includes('/api/Appointment/searchShowSlots')
        || request.url.includes('/api/Appointment/GetSelectedProviderSlots') ||
         request.url.includes('/api/Appointment/GetSelectedDateSlotsDtbyfilterconsulttype')
        || request.url.includes('/api/Appointment/UpdateAppointmentReasons') ||
         request.url.includes('/api/Appointment/GetReasonForVisitForMMHPMS')
        || request.url.includes('/api/Appointment/SaveReasonForVisit') ||
         request.url.includes('/api/JoinWaitingRoom/Get_FutureAppointmentStatus') ||     
        request.url.includes('/api/Appointment/GetFutureAppointmentStatus') ||
        request.url.includes('/api/JoinWaitingRoom/GetFutureVideoConsultationInvitation') ||   
        request.url.includes('/api/JoinWaitingRoom/InsertIsWaitingRoomStatus') ||
        request.url.includes('/api/JoinWaitingRoom/GetWaitingRoomMusic') ||
        request.url.includes('/api/JoinWaitingRoom/GetUserIdByPraticeId') ||    
        request.url.includes('/api/JoinWaitingRoom/GetQueueStatus') ||
        request.url.includes('/api/JoinWaitingRoom/GetIsonlineStatus') ||
        request.url.includes('/api/JoinWaitingRoom/GetConnectionID') ||      
        request.url.includes('/api/Appointment/GetFutureAppointmentStatus') ||
        request.url.includes('/api/JoinWaitingRoom/InsertSignalrRConnection') ||
        request.url.includes('/api/Appointment/CancelAppointmentbyLoggedUser') ||
        request.url.includes('/api/Appointment/GetFutureAppointmentDashBoardStatus') ||
        request.url.includes('/api/Appointment/GetProviderDashboardAppointmentStatus') ||
        request.url.includes('/api/Appointment/GetPastAppointmentStatus') ||
        request.url.includes('/api/Appointment/GetReasonForVisit') ||
        request.url.includes('/api/Appointment/AppointmentStatus') ||
        request.url.includes('/api/Appointment/RequestedAppSlotId') ||      
        request.url.includes('/api/Appointment/GetPracticeLocationsForAppointments') ||
        request.url.includes('/api/Appointment/GetPracticeLocationsForAppointments_EasyBooking') ||      
        request.url.includes('/api/Appointment/DeleteReasonForVisit') ||
        request.url.includes('/api/Appointment/DeleteAppointmentReason') ||
        request.url.includes('/api/Appointment/GetCountandConfigValues') ||
        request.url.includes('/api/Appointment/DeleteEventFromCalender') ||
        request.url.includes('/api/Appointment/CalculateAppointmentFeeByOnlinePayment') ||
        request.url.includes('/api/Appointment/GetLookupSelfArrivalMins') ||
        request.url.includes('/api/Appointment/GetBookedAppointmentInfobySPC') ||
        request.url.includes('/api/Appointment/UpdatePatientPAContactNumberForAppointment') ||
        request.url.includes('/api/JoinWaitingRoom/ValidateVcUser') ||
        request.url.includes('/api/JoinWaitingRoom/ValidateTinyCode') ||
        request.url.includes('/api/JoinWaitingRoom/ValidateSMSVCAppointmentDateUser') ||
        request.url.includes('/api/JoinWaitingRoom/GetPatientUserIdByRequestSlotId') ||
        request.url.includes('/api/JoinWaitingRoom/InsertIsonlineStatus') ||
        request.url.includes('/api/JoinWaitingRoom/GetProviderDetailsByMeetingID') ||
        request.url.includes('/api/JoinWaitingRoom/InsertIsWaitingRoomStatus') ||
        request.url.includes('/api/JoinWaitingRoom/GetVideoConsultationInvitation') ||
        request.url.includes('/api/JoinWaitingRoom/GetPracticeVCSettingsALEX') ||
        //request.url.includes('/api/JoinWaitingRoom/InviteParticipantLink') ||
        request.url.includes('/api/JoinWaitingRoom/SetCompletedStatus') ||
        request.url.includes('/api/JoinWaitingRoom/GetCompletedStatus') ||
        request.url.includes('/api/JoinWaitingRoom/SessionMonitoring') ||
        request.url.includes('/api/JoinWaitingRoom/GetMeetingInfo') ||
        request.url.includes('/api/JoinWaitingRoom/GetVideoConsultationQueuePatients') ||
        request.url.includes('/api/JoinWaitingRoom/InsertExternalRoomGuid') ||
        request.url.includes('/api/JoinWaitingRoom/GetExternalRoomGuid') ||
        request.url.includes('/api/JoinWaitingRoom/InsertProviderNameByMeetingId') ||
        request.url.includes('/api/JoinWaitingRoom/GetVideoCallRingtone') ||
        request.url.includes('/api/JoinWaitingRoom/GetLookUpReasonForCancellation') ||
        request.url.includes('/api/JoinWaitingRoom/UpdateCancellationStatusByPatient') ||
        request.url.includes('/api/JoinWaitingRoom/InsertProviderAppointmentSettings') ||
        request.url.includes('/api/Appointment/NewAppointmentBookingRequest') ||
        request.url.includes('/api/JoinWaitingRoom/GetProviderAppointmentSettings')
        //|| request.url.includes('/api/Appointment/GetLocationStaff')
      ) {
        // request.url.includes('/api/Appointment/NewAppointmentBookingRequest') || GetPatientHealthCentersWithLocations

        // Api not available in microservice
        let missedApis:any = [
          '/api/InsertAppointmentSlotPeriodsMaster/GetDoctorDetails',
        ];



        if (!missedApis.some((str:any) => request.url.includes(str))) {
          let modifiedUrl = request.url.replace(environment.apiBaseURL, environment.apiMonolithicBaseURL);
          modifiedUrl = modifiedUrl.replace('/api/InsertAppointmentSlotPeriodsMaster/', '/api/AptTemplate/');
          modifiedUrl = modifiedUrl.replace('/api/JoinWaitingRoom/Get_FutureAppointmentStatus', '/api/Appointment/Get_FutureAppointmentStatus');
          modifiedUrl = modifiedUrl.replace('/api/JoinWaitingRoom/GetFutureVideoConsultationInvitation', '/api/Appointment/GetFutureVideoConsultationInvitation');        
          modifiedUrl = modifiedUrl.replace('/api/Appointment/GetSelectedProviderSlots', '/api/AptTemplate/GetSelectedProviderSlots');
          modifiedUrl = modifiedUrl.replace('/api/Appointment/searchShowSlots', '/api/AptTemplate/searchShowSlots');
          modifiedUrl = modifiedUrl.replace('/api/Appointment/updateSlotsDayOff', '/api/AptTemplate/updateSlotsDayOff');
          modifiedUrl = modifiedUrl.replace('/api/Appointment/deleteSlotsDayOff', '/api/AptTemplate/deleteSlotsDayOff');
          modifiedUrl = modifiedUrl.replace('/api/Appointment/GetPracticeLocationsForAppointments_EasyBooking', '/api/Appointment/GetPracticeLocationsForAppointments');
          modifiedUrl = modifiedUrl.replace('/api/Appointment/NewAppointmentBookingRequest','/api/Appointment/NewAppointmentBookingRequest' );

          // video consultation changes
modifiedUrl = modifiedUrl.replace('/api/JoinWaitingRoom/InsertIsWaitingRoomStatus','/api/VideoConsultation/InsertIsWaitingRoomStatus')    
modifiedUrl = modifiedUrl.replace('/api/JoinWaitingRoom/GetWaitingRoomMusic','/api/VideoConsultation/GetWaitingRoomMusic')    
modifiedUrl = modifiedUrl.replace('/api/JoinWaitingRoom/GetUserIdByPraticeId','/api/VideoConsultation/GetUserIdByPraticeId')        
modifiedUrl = modifiedUrl.replace('/api/JoinWaitingRoom/GetQueueStatus','/api/VideoConsultation/GetQueueStatus')    
modifiedUrl = modifiedUrl.replace('/api/JoinWaitingRoom/GetIsonlineStatus','/api/VideoConsultation/GetIsonlineStatus')    
modifiedUrl = modifiedUrl.replace('/api/JoinWaitingRoom/GetConnectionID','/api/VideoConsultation/GetConnectionID')    
modifiedUrl = modifiedUrl.replace('/api/JoinWaitingRoom/ValidateVcUser','/api/VideoConsultation/ValidateVcUser')    
modifiedUrl = modifiedUrl.replace('/api/JoinWaitingRoom/ValidateTinyCode','/api/VideoConsultation/ValidateTinyCode')    
modifiedUrl = modifiedUrl.replace('/api/JoinWaitingRoom/ValidateSMSVCAppointmentDateUser','/api/VideoConsultation/ValidateSMSVCAppointmentDateUser')    
modifiedUrl = modifiedUrl.replace('/api/JoinWaitingRoom/GetPatientUserIdByRequestSlotId','/api/VideoConsultation/GetPatientUserIdByRequestSlotId')    
modifiedUrl = modifiedUrl.replace('/api/JoinWaitingRoom/InsertIsonlineStatus','/api/VideoConsultation/InsertIsonlineStatus')    
modifiedUrl = modifiedUrl.replace('/api/JoinWaitingRoom/GetProviderDetailsByMeetingID','/api/VideoConsultation/GetProviderDetailsByMeetingID')    
modifiedUrl = modifiedUrl.replace('/api/JoinWaitingRoom/InsertIsWaitingRoomStatus','/api/VideoConsultation/InsertIsWaitingRoomStatus')    
modifiedUrl = modifiedUrl.replace('/api/JoinWaitingRoom/GetVideoConsultationInvitation','/api/VideoConsultation/GetVideoConsultationInvitation')    
modifiedUrl = modifiedUrl.replace('/api/JoinWaitingRoom/GetPracticeVCSettingsALEX','/api/VideoConsultation/GetPracticeVCSettingsALEX')    
modifiedUrl = modifiedUrl.replace('/api/JoinWaitingRoom/InviteParticipantLink','/api/VideoConsultation/InviteParticipantLink')    
modifiedUrl = modifiedUrl.replace('/api/JoinWaitingRoom/SetCompletedStatus','/api/VideoConsultation/SetCompletedStatus')    
modifiedUrl = modifiedUrl.replace('/api/JoinWaitingRoom/GetCompletedStatus','/api/VideoConsultation/GetCompletedStatus')    
modifiedUrl = modifiedUrl.replace('/api/JoinWaitingRoom/SessionMonitoring','/api/VideoConsultation/SessionMonitoring')    
modifiedUrl = modifiedUrl.replace('/api/JoinWaitingRoom/GetMeetingInfo','/api/VideoConsultation/GetMeetingInfo')    
modifiedUrl = modifiedUrl.replace('/api/JoinWaitingRoom/GetVideoConsultationQueuePatients','/api/VideoConsultation/GetVideoConsultationQueuePatients')    
modifiedUrl = modifiedUrl.replace('/api/JoinWaitingRoom/InsertExternalRoomGuid','/api/VideoConsultation/InsertExternalRoomGuid')    
modifiedUrl = modifiedUrl.replace('/api/JoinWaitingRoom/GetExternalRoomGuid','/api/VideoConsultation/GetExternalRoomGuid')    
modifiedUrl = modifiedUrl.replace('/api/JoinWaitingRoom/InsertProviderNameByMeetingId','/api/VideoConsultation/InsertProviderNameByMeetingId')    
modifiedUrl = modifiedUrl.replace('/api/JoinWaitingRoom/GetVideoCallRingtone','/api/VideoConsultation/GetVideoCallRingtone')    
modifiedUrl = modifiedUrl.replace('/api/JoinWaitingRoom/GetLookUpReasonForCancellation','/api/VideoConsultation/GetLookUpReasonForCancellation')    
modifiedUrl = modifiedUrl.replace('/api/JoinWaitingRoom/UpdateCancellationStatusByPatient','/api/VideoConsultation/UpdateCancellationStatusByPatient')    
modifiedUrl = modifiedUrl.replace('/api/JoinWaitingRoom/InsertProviderAppointmentSettings','/api/VideoConsultation/InsertProviderAppointmentSettings')
modifiedUrl = modifiedUrl.replace('/api/JoinWaitingRoom/GetProviderAppointmentSettings','/api/VideoConsultation/GetProviderAppointmentSettings')
modifiedUrl = modifiedUrl.replace('/api/JoinWaitingRoom/InsertSignalrRConnection','/api/VideoConsultation/InsertSignalrRConnection')


          const modifiedRequest = request.clone({
            headers: request.headers.delete('Authorization').delete('Accept').append('Accept', 'application/json, text/plain, */*'),
            url: modifiedUrl
          });

          return next.handle(modifiedRequest);
        }

      }

      /********** Practice **********/
      // if (request.url.includes('/api/Account/SignIn')) {
      //   let modifiedUrl = request.url.replace(environment.apiBaseURL + '/api/Account/SignIn',
      //     environment.apiMonolithicBaseURL + '/api/Account/Signin');
      //   const modifiedRequest = request.clone({
      //     headers: request.headers.delete('Authorization'),
      //     url: modifiedUrl
      //   });
      //   return next.handle(modifiedRequest);
      // }


      /********** Practice **********/
      // if (request.url.includes('/api/PracticeDirectory/GetPracticeAndLocationsPracticeMasterIdByUserId')
      //   || request.url.includes('/api/Practice/CreateNewPractice')
      //   || request.url.includes('/api/Practice/UpdateNewPractice')
      //   || request.url.includes('/api/PracticeDirectory/GetPracticeContactDetails')
      //   // || request.url.includes('/api/PracticeDirectory/GetPracticeDetails')
      //   || request.url.includes('/api/Practice/CheckPracticeSource')) {

      //   let modifiedUrl = request.url.replace(environment.apiBaseURL, environment.apiMonolithicBaseURL);

      //   modifiedUrl = modifiedUrl.replace('/api/PracticeDirectory/GetPracticeAndLocationsPracticeMasterIdByUserId', '/api/Practice/GetPracticeAndLocationsPracticeMasterIdByUserId');
      //   modifiedUrl = modifiedUrl.replace('/api/Practice/CreateNewPractice', '/api/Practice/CreateNewPractice');
      //   modifiedUrl = modifiedUrl.replace('/api/Practice/UpdateNewPractice', '/api/Practice/UpdateNewPractice');
      //   modifiedUrl = modifiedUrl.replace('/api/PracticeDirectory/GetPracticeContactDetails', '/api/Practice/GetPracticeContactDetails');
      //   // modifiedUrl = modifiedUrl.replace('/api/PracticeDirectory/GetPracticeDetails', '/api/Practice/GetPracticeDetails');
      //   modifiedUrl = modifiedUrl.replace('/api/Practice/CheckPracticeSource', '/api/Practice/CheckPracticeSource');

      //   const modifiedRequest = request.clone({
      //     headers: request.headers.delete('Authorization'),
      //     url: modifiedUrl
      //   });
      //   return next.handle(modifiedRequest);
      // }

      /********** Practice Location **********/
      // if (request.url.includes('/api/Practice/CreateNewLocation')
      //   || request.url.includes('/api/Practice/UpdateNewLocation')
      //   || request.url.includes('/api/PracticeDirectory/GetContactHours')
      //   || request.url.includes('/api/PracticeDirectory/UpdateContactHours')
      //   // || request.url.includes('/api/PracticeDirectory/GetServices')
      //   || request.url.includes('/api/PracticeDirectory/CreateService')
      //   || request.url.includes('/api/PracticeDirectory/UpdateService')
      //   || request.url.includes('/api/PracticeDirectory/GetFacility')
      //   || request.url.includes('/api/PracticeDirectory/UpdateFacility')
      //   // || request.url.includes('/api/PracticeDirectory/GetPractice?practiceId=')
      // ) {
      //   let modifiedUrl = request.url.replace(environment.apiBaseURL, environment.apiMonolithicBaseURL);

      //   modifiedUrl = modifiedUrl.replace('/api/Practice/CreateNewLocation', '/api/Practice/CreateNewLocation');
      //   modifiedUrl = modifiedUrl.replace('/api/PracticeDirectory/GetContactHours', '/api/PracticeLocation/GetContactHours');
      //   modifiedUrl = modifiedUrl.replace('/api/PracticeDirectory/UpdateContactHours', '/api/PracticeLocation/UpdateContactHours');
      //   // modifiedUrl = modifiedUrl.replace('/api/PracticeDirectory/GetServices', '/api/LocationService/GetServices');
      //   modifiedUrl = modifiedUrl.replace('/api/PracticeDirectory/CreateService', '/api/LocationService/CreateService');
      //   modifiedUrl = modifiedUrl.replace('/api/PracticeDirectory/UpdateService', '/api/LocationService/UpdateService');
      //   modifiedUrl = modifiedUrl.replace('/api/PracticeDirectory/GetFacility', '/api/Facility/GetFacility');
      //   modifiedUrl = modifiedUrl.replace('/api/PracticeDirectory/UpdateFacility', '/api/Facility/UpdateFacility');
      //   modifiedUrl = modifiedUrl.replace('/api/Practice/UpdateNewLocation', '/api/Practice/UpdateNewLocation');
      //   // modifiedUrl = modifiedUrl.replace('/api/PracticeDirectory/GetPractice?practiceId=', '/api/Practice/GetPractice?practiceId=');

      //   const modifiedRequest = request.clone({
      //     headers: request.headers.delete('Authorization'),
      //     url: modifiedUrl
      //   });
      //   return next.handle(modifiedRequest);
      // }

      /********** Staff **********/
      // if (request.url.includes('/api/PracticeDirectory/InsertStaff')
      //   || request.url.includes('/api/Practice/EnrolStaff')
      //   || request.url.includes('/api/Account/ActivateAccount')
      //   || request.url.includes('/api/Account/ActivationConfirmation')
      //   || request.url.includes('/api/Account/AccountActivationConfirmation')
      //   || request.url.includes('/api/Account/Confirmation')
      //   || request.url.includes('/api/Account/GetUserRolesandMenu?userId=')
      // ) {
      //   let modifiedUrl = request.url.replace(environment.apiBaseURL, environment.apiMonolithicBaseURL);

      //   modifiedUrl = modifiedUrl.replace('/api/PracticeDirectory/InsertStaff', '/api/Staff/InsertStaff');
      //   modifiedUrl = modifiedUrl.replace('/api/Practice/EnrolStaff', '/api/Staff/EnrolStaff');
      //   modifiedUrl = modifiedUrl.replace('/api/Account/ActivateAccount', '/api/Staff/ActivateAccount');
      //   modifiedUrl = modifiedUrl.replace('/api/Account/ActivationConfirmation', '/api/Staff/ActivationConfirmation');
      //   modifiedUrl = modifiedUrl.replace('/api/Account/AccountActivationConfirmation', '/api/Staff/AccountActivationConfirmation');
      //   modifiedUrl = modifiedUrl.replace('/api/Account/Confirmation', '/api/Staff/Confirmation');
      //   modifiedUrl = modifiedUrl.replace('/api/Account/GetUserRolesandMenu?userId=', '/api/RolesAndMenus/GetUserRolesandMenu?userId=');

      //   const modifiedRequest = request.clone({
      //     headers: request.headers.delete('Authorization'),
      //     url: modifiedUrl
      //   });
      //   return next.handle(modifiedRequest);
      // }

      /********** Patient **********/
      // if (request.url.includes('/api/Account/AddPatient')
      //   || request.url.includes('/api/Account/SuspendPatient')
      // ) {
      //   let modifiedUrl = request.url.replace(environment.apiBaseURL, environment.apiMonolithicBaseURL);

      //   modifiedUrl = modifiedUrl.replace('/api/Account/AddPatient', '/api/Patient/AddPatient');
      //   modifiedUrl = modifiedUrl.replace('/api/Account/SuspendPatient', '/api/Patient/SuspendPatient');

      //   const modifiedRequest = request.clone({
      //     headers: request.headers.delete('Authorization'),
      //     url: modifiedUrl
      //   });
      //   return next.handle(modifiedRequest);
      // }

      /********** Practice Configuration **********/
      // if (request.url.includes('/api/Appointment/GetPracticeSettings')
      //   || request.url.includes('/api/Practice/GetPracticeSettingForApptTurnOff')
      //   || request.url.includes('/api/Practice/GetPracticeVCSettings')
      //   || request.url.includes('/api/Practice/UpdatePracticeAppointmentSettings')
      //   || request.url.includes('/api/Appointment/UpdatePracticeAppointmentTurnoff')
      //   || request.url.includes('api/Practice/GetApptTurnOffAuditCount')   
      //   || request.url.includes('api/Practice/GetApptTurnOffAuditPaging')     
      // ) {
      //   let modifiedUrl = request.url.replace(environment.apiBaseURL, environment.apiMonolithicBaseURL);

      //   modifiedUrl = modifiedUrl.replace('/api/Appointment/GetPracticeSettings', '/api/PracticeSettings/GetPracticeSettings');
      //   modifiedUrl = modifiedUrl.replace('/api/Appointment/UpdatePracticeAppointmentTurnoff', '/api/PracticeSettings/UpdatePracticeAppointmentTurnoff');
      //   modifiedUrl = modifiedUrl.replace('/api/Practice/GetPracticeSettingForApptTurnOff', '/api/PracticeSettings/GetPracticeSettingForApptTurnOff');
      //   modifiedUrl = modifiedUrl.replace('/api/Practice/GetPracticeVCSettings', '/api/PracticeSettings/GetPracticeVCSettings');
      //   modifiedUrl = modifiedUrl.replace('/api/Practice/UpdatePracticeAppointmentSettings', '/api/PracticeSettings/UpdateAppointmentSettings');

      //   const modifiedRequest = request.clone({
      //     headers: request.headers.delete('Authorization'),
      //     url: modifiedUrl
      //   });
      //   return next.handle(modifiedRequest);
      // }

      /********** SMS **********/
    //   if (request.url.includes('/api/SMSCreditFacility/GetTopBannerMessage') ||
    //   request.url.includes('/api/SMSCreditFacility/GetSMSUsage') ||
    //   request.url.includes('/api/SMSCreditFacility/GetCreditCardDetails') ||
    //   request.url.includes('/api/SMSCreditFacility/GetSMSAutoTopUp') ||
    //   request.url.includes('/api/SMSCreditFacility/GetSMSCreditFacilitySetting') ||
    //   request.url.includes('/api/SMSCreditFacility/SMSGetOfflinePaymentEnabled') ||
    //   request.url.includes('/api/SMSCreditFacility/GetSMSCreditFacilityBalanceByPractice') ||
    //   request.url.includes('/api/SMSCreditFacility/GetSMSCost') ||
    //   request.url.includes('/api/SMSCreditFacility/SendSMSOfflinePaymentRequest') ||
    //   request.url.includes('/api/SMSCreditFacility/GetTransactionDetails') ||
    //   request.url.includes('/api/SMSGroupMessage/SaveSMSGroupMessage') ||
    //   request.url.includes('/api/Inbox/GetAllSMSGroupSentMessage') ||
    //   request.url.includes('/api/SMSGroupMessage/CancelSMSGroupMessage') ||
    //   request.url.includes('/api/SMSGroupMessage/BroadcastList/MappingDetails') ||
    //   request.url.includes('/api/SMSGroupMessage/BroadcastList/ImportCSV') ||
    //   request.url.includes('/api/SMSGroupMessage/BroadcastList/Dispatch') ||
    //   request.url.includes('/api/SMSGroupMessage/BroadcastList/ComposeFor') ||
    //   request.url.includes('/api/SMSGroupMessage/BroadcastListMeta')
    //   ) {
    //     let modifiedUrl = request.url.replace(environment.apiBaseURL, environment.apiMonolithicBaseURL);
    //     modifiedUrl = modifiedUrl.replace('/api/Practice/InsertUpdateSMSPracticeLocation', '/api/SMSPracticelocationSettings/InsertUpdateSMSPracticeLocation');
    //     modifiedUrl = modifiedUrl.replace('/api/Practice/GetLookupSMSModuleByDisplayInUI', '/api/SMSGroupMessage/GetLookupSMSModuleByDisplayInUI');
    //     modifiedUrl = modifiedUrl.replace('/api/Inbox/GetGroupMessageRecipients', '/api/SMSGroupMessage/GetSMSGroupMessageRecipient');
    //     modifiedUrl = modifiedUrl.replace('/api/Practice/GetSMSCreditandCostDetails', '/api/SMSPracticelocationSettings/GetSMSCreditandCostDetails');
    //     modifiedUrl = modifiedUrl.replace('/api/Inbox/SMSGroupMessageRecipientGetCount', '/api/SMSGroupMessage/GetCount');

    //     const modifiedRequest = request.clone({
    //       headers: request.headers.delete('Authorization'),
    //       url: modifiedUrl
    //     });
    //     return next.handle(modifiedRequest);
      
    // }


    }



    return next.handle(request);
  }
}
