  <div class="content-panel p-0 dialog-box">
    <h3>{{data.title}}</h3>
    <div>
      <!-- <b>{{data.alertType}}!</b>
      <mat-icon matSuffix (click)="onNoClick()" class="close">close</mat-icon>
      -->
      <p class="font-16-n" [innerHTML]="data.message"></p>
    </div>
    <div class="dialog-footer header-buttons actions-reduce-bts">
      <div fxLayout="row wrap">

        <button mat-button [mat-dialog-close]="false" class="btn btn-secondary btn-text-36 m-t-20 m-r-10">
            {{data.noButtonText}}
        </button>

        <button mat-button [mat-dialog-close]="true" class="btn btn-primary btn-primary-36 m-t-20">
          {{data.yesButtonText}}
        </button>

      </div>
    </div>
  </div>
