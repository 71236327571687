import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-payment-refresh',
  templateUrl: './payment-refresh.component.html',
  styleUrls: ['./payment-refresh.component.scss']
})
export class PaymentRefreshComponent implements OnInit {

   constructor(public dialogRef: MatDialogRef<PaymentRefreshComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private router: Router) {
      console.log("Refresh- dialog", data);
     }

  ngOnInit(): void {
  }

  onClose(): void {
    this.dialogRef.close();
  }

}
