import { Component, OnInit, ViewChild } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { TranslateService } from '@ngx-translate/core';
import { MailBoxService } from 'src/app/data/service/mail-box.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { InBoxService } from 'src/app/data/service/inbox.service';
import { AuthenticationService } from 'src/app/data/service/authentication.service';
import { TokenService } from 'src/app/data/service/token.service';
import { MatDrawer } from '@angular/material/sidenav';
import { AlertType, DashboardServiceData } from 'src/app/core/constants/app-enums';
import { URLConstants } from 'src/app/core/constants/urlConstants';
import { HttpClient } from '@angular/common/http';
import { applicationHttpClientCreator } from 'src/app/data/interface/IRequestOptions';
import { ConfirmDialogComponent } from 'src/app/shared/component/confirm-dialog/confirm-dialog.component';
import { IConfirmDialog } from 'src/app/data/interface/Idialog';
import { environment } from 'src/environments/environment';
import { ToasterService } from 'src/app/data/service/toaster.service';
import { EncryptDecrypt } from 'src/app/core/helper/encrypt-decrypt';
import { SignalrService } from 'src/app/data/service/signalr.service';
import { JoinMeetingService } from 'src/app/data/service/join-meeting.service';
import { Dialog } from 'primeng-lts/dialog/dialog';
import { SmsCreditFacilityService } from 'src/app/data/service/smsCreditFacility.service';
import { CommonConstants } from 'src/app/core/constants/commonConstants';
import { CommonService } from 'src/app/data/service/common.service';
import { RecallRemainderDialogComponent } from './recall-remainder-dialog/recall-remainder-dialog.component';
import { MHAService } from 'src/app/data/service/mha.service';
import { LinkNewHealthCenterDialogComponent } from './link-new-health-center-dialog/link-new-health-center-dialog.component';
import { AlertDialogComponent } from 'src/app/shared/component/alert-dialog/alert-dialog.component';
//*************Alex Service******** */
import { AuthServer } from 'src/app/data/service/auth.server';
import { LinkedAccountRemainderComponent } from './linked-account-remainder/linked-account-remainder.component';
@Component({
  selector: 'app-vertical-header',
  templateUrl: './vertical-header.component.html',
  styleUrls: ['./vertical-header.component.scss'],
})
export class VerticalAppHeaderComponent implements OnInit {
  public userId: any;
  public filtertype: any;
  public draftcount: any;
  public inboxCount: any;
  public groupCount: any;
  public healthCenterData = true;
  public data: any;
  public profileName: any;
  public messagestype: any;
  public config: PerfectScrollbarConfigInterface = {};
  public searchtext = false;
  public messagedropdown = true;
  public searchTexts: any;
  isVisible = false;
  displayMaximizable = false;
  confirmDialog: IConfirmDialog;
  currentSMSbalance: number;
  isSMSBalanceVisible: boolean = false;
  userRoles: any;

  @ViewChild('drawer', { static: true }) private drawer: MatDrawer;
  notifications: Object[] = [
    {
      round: 'round-danger',
      icon: 'ti-link',
      title: 'Launch Admin',
      subject: 'Just see the my new admin!',
      time: '9:30 AM',
    },
    {
      round: 'round-success',
      icon: 'ti-calendar',
      title: 'Event today',
      subject: 'Just a reminder that you have event',
      time: '9:10 AM',
    },
    {
      round: 'round-info',
      icon: 'ti-settings',
      title: 'Settings',
      subject: 'You can customize this template as you want',
      time: '9:08 AM',
    },
    {
      round: 'round-primary',
      icon: 'ti-user',
      title: 'Pavan kumar',
      subject: 'Just see the my admin!',
      time: '9:00 AM',
    },
  ];

  public selectedLanguage: any = {
    language: 'English',
    code: 'en',
    type: 'US',
    icon: 'au',
  };
  public languages: any[] = [
    {
      language: 'English',
      code: 'en',
      type: 'US',
      icon: 'au',
    },
    // {
    //   language: 'Chinese',
    //   code: 'ch',
    //   icon: 'ch'
    // },
    // {
    //   language: 'Māori',
    //   code: 'ma',
    //   icon: 'nz',
    // },
    // {
    //   language: 'Français',
    //   code: 'fr',
    //   icon: 'fr'
    // },
    // {
    //   language: 'Deutsch',
    //   code: 'de',
    //   icon: 'de'
    // },
  ];
  public mailstype: any = []; // mailtypes
  public page = 0;
  public pageSize = 10;
  public searchText: any = '';
  public isSelectAll = false;
  public selectedMenuType = '';
  isProvider: boolean = false;
  isSpecificRolesFound: boolean = false;
  isEasyBookRoleFound: boolean = false;
  showBookApptIcon: boolean = false;
  showMessageIcon: boolean = false;
  selectedActionName: any;
  selctedLang: any;
  public healthCenterList: any[] = [];
  isBeatUser = false;
  public profileImgURL: any;
  isLinkedAccChild: boolean = false;
  totalUnreadCount: any;
  groupMessageCount: any;
  unReadGroupMessageCount: any;
  unReadInboxCount: any;
  roles: any;
  recallReminderId: any;
  isMobile: boolean;
  isPatient: boolean = false;
  isBtbProvider: boolean = false;
  NotShowTopMessage: boolean = false;
  // selCategory: string="Website feedback";
  // userValue: string;
  // opened: boolean;
  constructor(
    private translate: TranslateService,
    public mailboxServie: MailBoxService,
    public dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private router: Router,
    private mailBoxService: MailBoxService,
    public inboxService: InBoxService,
    private tokenService: TokenService,
    private mhaService: MHAService,
    private toaster: ToasterService,
    private http: HttpClient,
    public smsCreditFacilityService: SmsCreditFacilityService,
    private signalrService: SignalrService,
    public joinMeetingService: JoinMeetingService,
    private commonService: CommonService,
    private AlexauthServer: AuthServer
  ) {
    translate.setDefaultLang('en');

    // this.smsCreditFacilityService.smsValueChange.subscribe((data: any) => {
    //   if (data) {
    //     this.getCurrentBalanceSMS();
    //   }
    // });

    this.signalrService.message.subscribe((val: string) => {
      if (
        val.includes('patient joined the waiting room.') ||
        val.includes('patient left from the waiting room.')
      ) {
        this.showToaster(val);
      }
    });

    this.joinMeetingService.patientVideoCallStarted.subscribe(
      (val: boolean) => {
        if (val) {
            this.getProviderDetails();
        }
      }
    );

    this.joinMeetingService.videoCallStarted.subscribe((val: boolean) => {
      if (val) {
        this.displayMaximizable = true;
      }
    });

    this.joinMeetingService.patientLoggedInQueueCall.subscribe(
      (val: boolean) => {
        if (val) {
          this.getExternalRoomId();
        }
      }
    );

    this.signalrService.message.subscribe((val: string) => {
      if (val == 'Doctor leaves the call') {
        this.signalrService.sendMessage(
          this.providerGuid,
          1,
          'Patient not accept the call',
          0
        );
      }
    });

  }

  ngOnInit(): void {
   // this.loadRecallRemainder();
   this.loadLinkNewHealthCentre();
   this.loadLinkAccountPopUp();
    this.userId = this.tokenService.userId();
    this.interval();
    // this.getCurrentBalanceSMS();
    this.smsCreditFacilityService.topBannerShow.subscribe((data: any) => {
      if (data == true) {
        this.isSMSBalanceVisible = true;
      }
    });

    let userRoleArr: any = this.tokenService.getUserRoles();
    this.isPatient = this.tokenService.checkIsPatient();
    let userRoleList = userRoleArr.split(',');
    this.roles = userRoleArr;
    this.userRoles = userRoleList;
    this.isSpecificRolesFound = false;
    this.isProvider = false;
    this.isEasyBookRoleFound = false;
    let patientid = userRoleList.findIndex(
      (x: any) => x.toLowerCase() == 'patient'
    );
    if (patientid == -1) {
      let easyBookRole = userRoleList.findIndex(
        (x: any) => x.toLowerCase() == 'easybooking'
      );
      if (easyBookRole != -1) {
        this.isEasyBookRoleFound = true;
      } else {
        let isRoleFound = userRoleList.findIndex(
          (x: any) =>
            x.toLowerCase() == 'mamember' ||
            x.toLowerCase() == 'openreg' ||
            x.toLowerCase() == 'cbtuser'
        );
        if (isRoleFound != -1) {
          this.isSpecificRolesFound = true;
        } else {
          this.isProvider = true;
        }
      }
    }

    if (isNaN(this.mailboxServie.mailstype[4].count) && !this.isProvider)
      this.mailboxServie.mailstype[4].count = 0;
    if (isNaN(this.mailboxServie.mailstype[0].count))
      this.mailboxServie.mailstype[0].count = 0;

    if (this.isProvider) {
      this.signalrService.initiateSignalrConnection(this.userId, 1);
      this.showBookApptIcon = false;
      this.showMessageIcon = true;
    } else {
      if (this.isSpecificRolesFound) {
        this.showBookApptIcon = false;
        this.showMessageIcon = false;
      } else if (this.isEasyBookRoleFound) {
        this.showBookApptIcon = true;
        this.showMessageIcon = false;
      } else {
        this.showBookApptIcon = true;
        this.showMessageIcon = true;
      }
    }

    let loginType: any = sessionStorage.getItem('loginType');
    loginType = EncryptDecrypt.decryptUsingAES256(loginType);
    if (loginType == 'Child') {
      this.isLinkedAccChild = true;
    } else {
      this.isLinkedAccChild = false;
    }

    this.checkIsBetaUserOrNot(this.userId);
    this.profileName = this.tokenService.userProfile();

    let CarerEmail = sessionStorage.getItem('CarerEmail');
    if (CarerEmail && CarerEmail != '') {
      CarerEmail = EncryptDecrypt.decryptUsingAES256(CarerEmail);
    }

    let ChildAccountApproved = sessionStorage.getItem('ChildAccountApproved');
    if (ChildAccountApproved && ChildAccountApproved != '') {
      ChildAccountApproved =
        EncryptDecrypt.decryptUsingAES256(ChildAccountApproved);
    }

    if (
      CarerEmail != '' &&
      CarerEmail != null &&
      (ChildAccountApproved == 'false' || ChildAccountApproved == null)
    ) {
      this.profileName.UserNameDis = CarerEmail;
    } else {
      this.profileName.UserNameDis = this.profileName.Email;
    }

    this.profileImgURL =
      this.profileName?.PhotoURL == ''
        ? 'assets/images/nophoto_unknown.gif'
        : this.profileName?.PhotoURL ;
    this.mailInboxCount();

    this.selctedLang = sessionStorage.getItem('language')
      ? sessionStorage.getItem('language')
      : 'en';
    this.languages.forEach((items) => {
      if (items.code === this.selctedLang) {
        this.selectedLanguage = items;
      }
    });
    this.translate.use(this.selctedLang);
    if (this.drawer) {
      this.mailboxServie.setDrawer(this.drawer);
      this.getCount();
      this.mailboxServie.selectedMailBoxType = 'Inbox';
      // this.mailboxServie.mailBoxDrawer=this.drawer;
    }
    this.mailboxServie.isShowSearchBox = false;

    this.mailboxServie.drawerBs.subscribe((data) => {
      if (data) {
        this.openDrawer();
        this.setSelectedMailType('Compose');
      }
    });
    this.smsBalanceVisibility();
    let onlyCbtRole = this.tokenService.hasOnlyRmAndCbtProvider()
    this.NotShowTopMessage = !onlyCbtRole;

    this.getAccessedWidgets();
    }
   // accessWidgets?.WGINBX == true
   accessWidgets: any = { WGINBX: false };
   getAccessedWidgets = () => {
    applicationHttpClientCreator(this.http).Get(`${URLConstants.getAccessedWidgets}`, {
    }).subscribe((data: any) => {

      if (data && data.length > 0) {
        let result = {};
        for (var i = 0; i < data.length; i++) {
          result[data[i].WidgetCode] = true;
        }
        this.accessWidgets = result;
      }
    }, (err: any) => {
      this.toaster.error(err.error.message || err.error.Message)
    });
  }



//   getCurrentBalanceSMS() {
//     if(sessionStorage.getItem('userRoles')) {
//       this.smsCreditFacilityService
//       .getsmsCreditfacilitybalance()
//       .then((res: any) => {
//         this.currentSMSbalance = res;
//         // console.log(res);
//         // console.log(this.currentSMSbalance, '***********************');
//       });
//     }
// }

/** Show or hide sms balance */
smsBalanceVisibility = () => {
  this.getSMSSettingsData();

};

//** Get SMS Setting Data for SMS roles */
getSMSSettingsData() {
  // this.smsCreditFacilityService
  //   .getSettingsData()
  //   .then((settingData: any) => {
  //     this.compareUserSMSRoles(settingData);
  //   });
  }

  //**Compare user and sms setting roles for sms balance visibility  */
  compareUserSMSRoles(settingData: any) {
    if (settingData != null && settingData != undefined) {
      let smsSettingSelectedRoles = settingData?.SettingRoles?.split(',');
      //**Check if user roles inlcude sms facility roles and returns the roles that are included */
      const intersectUserSMSRoles = smsSettingSelectedRoles?.filter(
        (value: any) => this.userRoles.includes(value)
      );
      if (intersectUserSMSRoles && intersectUserSMSRoles != '') {
        this.isSMSBalanceVisible = true; // show sms balance
      } else {
        this.isSMSBalanceVisible = false; // hide sms balance
      }
    }
  }


  loadLinkNewHealthCentre() {
    let decriptUserActivationStatus: any = sessionStorage.getItem('userActivationStatus');
    decriptUserActivationStatus = decriptUserActivationStatus ? EncryptDecrypt.decryptUsingAES256(decriptUserActivationStatus) : '';
    const userActivationStatus = decriptUserActivationStatus ? JSON.parse(decriptUserActivationStatus) : [];
    if(userActivationStatus.length) {
      if(userActivationStatus[0].IsEnabledPracticeTerms && sessionStorage.getItem('practiceTerms')) {
        const payload = {
          activationCode: userActivationStatus[0].ActivationCode,
          isPracticeTermsEnabled: userActivationStatus[0].IsEnabledPracticeTerms,
          businessName: userActivationStatus[0].BusinessName,
          termsandConditions: true,
        }

        const practiceTermsAtb = userActivationStatus[0].PracticeTerms ? this.tokenService.decoder(userActivationStatus[0].PracticeTerms) : '';
        const practiceTerms = this.commonService.sanitizeHtml(practiceTermsAtb);
        const PracticeDetails = this.commonService.sanitizeHtml(userActivationStatus[0].PracticeDetails);
        //const practiceTerms = this.commonService.sanitizeHtml(userActivationStatus[0].PracticeTerms);
        const data = {
          practiceName: userActivationStatus[0].BusinessName,
          practiceDetails:PracticeDetails,
          practiceTerms: practiceTerms,
          payload: payload
        }
        const dialogRef = this.dialog.open(LinkNewHealthCenterDialogComponent, {
          data: data,
          disableClose: true,
          panelClass: 'my-dialog-class',

        });
        dialogRef.afterClosed().subscribe((result: any) => {
          result?.btn == 'linkWithHealthCentre' ? this.linkWithHealthCentre(result.payload) : '';
          sessionStorage.removeItem('practiceTerms');

        });
      }


    }

  }

  loadLinkAccountPopUp() {
    let notlinkedAccountDetails = sessionStorage.getItem('notlinkedAccountDetails');
    let details = notlinkedAccountDetails ? JSON.parse(notlinkedAccountDetails) : [];
    if(details.length) {
      const data = {
        accounts: details.map((detail : any) => ({
          practiceName: detail.BusinessName,
          patientName: detail.PatientName,
          relationship: detail.Relationship,
        })),
      };
          const dialogRef = this.dialog.open(LinkedAccountRemainderComponent, {
            data: data,
            disableClose: true,
            panelClass: 'responsive-dialog'
          });
          dialogRef.afterClosed().subscribe((result: any) => {
            sessionStorage.removeItem('notlinkedAccountDetails');
          });
    }
      }


  linkWithHealthCentre(payload: any) {
    //console.log(payload);
    this.inboxService.getActivateNextHealtCentre(payload).subscribe((data: any) => {
      if(data) {
        this.showAlertMessage('Success',data)
      }
    }, (error) => {
      this.showAlertMessage('Info',error.error.Message);
    });
  }


  showAlertMessage(title: any, message: any) {
    const alertDialog = {
      title: title,
      message: message,
      alertType: AlertType.None,
      okButtonText: this.translate.instant('CLOSE')
    };
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: alertDialog
    });
    dialogRef.afterClosed().subscribe((result: any) => {
         sessionStorage.removeItem('userActivationStatus');
        this.commonService.reloadCurrentRoute(this.router.url);
    });
  }

  loadRecallRemainder() {
    this.inboxService.getRecallRemainder().subscribe((data: any) => {
      if(data && data[0]) {
        const dialogRef = this.dialog.open(RecallRemainderDialogComponent, {
          data: data
        });
        dialogRef.afterClosed().subscribe((result: any) => {
          this.acnowledgementRecallRemainder(result);
        });
      }

    });
  }

  acnowledgementRecallRemainder(result: any) {
    this.inboxService.acnowledgementRecallRemainder(result).subscribe((data: any) => {
      // console.log(data);
    });
  }


  openDrawer(): void {
    //this.getCount();
    this.mailboxServie.selectedMailBoxType = 'Inbox';
    this.drawer.toggle();
  }
  // matDrawerOpen(drawer: MatDrawer): void {
  //   this.getCount();
  //   this.mailboxServie.selectedMailBoxType = 'Inbox';
  //   drawer.toggle();
  // }

  hideinbox(): void {
    this.mailboxServie.hideMailBox();
  }

  calenderDrawerOpen(): void {
    this.router.navigate(['/myaccount/calendar']);
  }

  matDrawerOpen(): void {
    let serviceData = this.commonService.getServiceDetail(DashboardServiceData.ViewMessage);
    if (serviceData && serviceData?.result?.toLowerCase() == CommonConstants.success) {
      this.router.navigate(['/pages/navigate-routes/Inbox']);
      this.getCount();
    }
    else {
      this.commonService.openServiceDialog(serviceData);
    }
    // this.mailboxServie.selectedMailBoxType = 'Inbox';
  }

  openDialog(): void {
    let serviceData = this.commonService.getServiceDetail(DashboardServiceData.BookAppointment);
    if (serviceData && serviceData?.result?.toLowerCase() == CommonConstants.success) {
      this.router.navigate(['/appointment/myappointments/bookappointment']);
      this.mailboxServie.hideMailBox();
    }
    else {
      this.commonService.openServiceDialog(serviceData);
    }
  }

  getCount(): void {
    this.inboxService.getCount(this.userId).subscribe((count: any[]) => {
      this.healthCenterList = count[0];
      this.mailboxServie.mailstype[1].count = count[1];
      this.mailboxServie.mailstype[3].count = count[2];
      if (!this.isProvider) {
        this.mailboxServie.mailstype[4].count = count[2];
        this.unReadGroupMessageCount = this.mailboxServie.mailstype[4].count =
          count[2];
      }
    });
  }

  mailInboxCount(): void {
    this.inboxService.inboxCount(this.userId).subscribe((count: any) => {
      this.mailboxServie.mailstype[0].count = count;
      this.unReadInboxCount = this.mailboxServie.mailstype[0].count = count;
    });
  }

  //back to home
  home(): void {
    this.messagedropdown = true;
    this.searchtext = false;
    this.mailboxServie.selectedMailBoxType = 'Inbox';
    this.mailboxServie.isShowSearchBox = false;
    this.messagestype = '';
  }
  // search value
  Searchkey(searchkeyvalue: any): void {
    this.mailboxServie.sendMessage(searchkeyvalue);
  }
  logout(): void {
    this.authenticationService.logout();
    //this.logoutMHA();
    // this.router.navigate(['/authentication/login']);
  }

  navigatetoInbox(type: any): void {
    if (type) {
      this.mailboxServie.showHealthCenter = false;
      this.mailboxServie.selectedMailBoxType = type;
    }
  }

  activationCode(): void {
    this.router.navigate(['/myaccount/profile']);
  }

  logoutMHA() {
    this.mhaService.logout()
  }

  close = () => {
    this.mailboxServie.selectedMailBoxType = 'Inbox';
  };

  changeLanguage(lang: any): void {
    sessionStorage.setItem('language', lang.code);
    this.translate.use(lang.code);
    this.selectedLanguage = lang;
  }

  setSelectedMailType(mailType: any): void {
    this.mailboxServie.showHealthCenter = false;
    this.mailboxServie.isShowSearchBox = true;
    if (mailType !== 'undefined') {
      this.searchtext = true;
      this.messagedropdown = false;
    }
    this.selectedActionName = '';
    this.filtertype = mailType;
    this.mailboxServie.selectedMailBoxType = mailType;
  }

  changeAction() {
    this.router.navigate(['/pages/navigate-routes/Settings']);
  }

  navigateEmergencyContacts(type: string) {
    this.hideinbox();

    if (type === 'Ecview') {
      this.router.navigate(['/myaccount/profile'], {
        queryParams: { editProfile: 'ViewEmergency' },
      });
      //window.location.href = window.location.origin + "/myaccount/profile?editProfile=" + ProfileTab.ViewEmergency;
    } else if (type === 'Ecadd') {
      this.router.navigate(['/myaccount/profile'], {
        queryParams: { editProfile: 'AddEmergency' },
      });
      //window.location.href = window.location.origin + "/myaccount/profile?editProfile=" + ProfileTab.AddEmergency;
    }
  }

  clearSearchData(searchkeyvalue: any) {
    this.searchTexts = '';
    this.mailboxServie.selectedMailBoxType = 'Inbox';
    this.mailboxServie.sendMessage(searchkeyvalue);
  }

  clickOptOut = () => {
    this.confirmDialog = {
      title: 'Opt-out confirmation',
      message:
        'Are you sure you want to opt-out from new version of Manage My Health™ ?',
      alertType: AlertType.None,
      yesButtonText: 'Yes',
      noButtonText: 'No',
    };
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: this.confirmDialog,
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === true) {
        let optStatus = false;
        let isdasboardCount = 0;

        applicationHttpClientCreator(this.http)
          .Post(
            `${URLConstants.updateOptOut}${this.userId}` +
              '&optInStatus=' +
              `${optStatus} ` +
              '&isdasboardCount=' +
              `${isdasboardCount} `,
            {}
          )
          .subscribe(
            (data: any) => {
              this.tokenService.clearsessionStorageValues();
              sessionStorage.removeItem('isBetaUser');
              // location.href = this.translate.instant('BetaUserRedirectURL') //environment.v1URL;
              location.href = environment.v1URL;
            },
            (err) => {
              console.log(err);
              //this.toaster.error(err.error.Message);
            }
          );
      }
    });
  };

  checkIsBetaUserOrNot = (userid: any) => {
    applicationHttpClientCreator(this.http)
      .Post(`${URLConstants.getBetaUserDetails}${userid}`, {})
      .subscribe(
        (data: any) => {
          sessionStorage.setItem('isBetaUser', 'true');
          this.isBeatUser = true;
          // if (data && data > 0) {
          //   sessionStorage.setItem('isBetaUser', "true");
          //   this.isBeatUser = true;
          // }
          // else {
          //   if (sessionStorage.getItem("isBetaUser") == "true") {
          //     this.isBeatUser = true;
          //   }
          //   else {
          //     this.isBeatUser = false;
          //   }
          // }
        },
        (err) => {
          console.log(err);
          //this.toaster.error(err.error.Message);
        }
      );
  };
  onClick() {
    this.profileName = this.tokenService.userProfile();
    this.profileName.UserNameDis = this.profileName.Email;
  }

  providerGuid: any;
  providerProfilePicture: any;
  vcData: any;
  getProviderDetails() {
    this.vcData = JSON.parse(sessionStorage.getItem('meetingData') || '[]');
    var json = {
      PraticeId: this.vcData.PracticeId,
      Spc: this.vcData.SPC,
    };
    this.joinMeetingService.getUserIdByPraticeId(json).subscribe((val: any) => {
      if (val.length > 0) {
        this.providerGuid = val[0].ProviderGuid;
        this.providerProfilePicture = val[0].ProfilePicture
          ? val[0].ProfilePicture
          : 'assets/Videoimages/nophoto_male.gif';
       this.isCallingPopup();
     
      }
    });
  }

  isCalling = false;
  callingTimeOut: any;
  callStarted = false;
  chatContainer: any;
  pauseElement: any;
  providerName: any;
  ringTone: string;
  playAudio = false;
  isCallingPopup(): void {
    this.joinMeetingService.turnOffWaitingRoomCamera.next(false);
    this.callStarted =
      sessionStorage.getItem('call-started') == 'true' ? true : false;
    this.pauseElement = document.getElementsByClassName('pause-track');
    if (this.pauseElement.length > 0) {
      const ele: HTMLElement = document.getElementsByClassName(
        'pause-track'
      )[0] as HTMLElement;
      setTimeout(() => {
        ele.click();
      }, 0);
    } else {
      setTimeout(() => {
        const ele: HTMLElement = document.getElementsByClassName(
          'pause-track'
        )[0] as HTMLElement;
        if (ele) {
          ele.click();
        }
      }, 4000);
    }
    let reJoinCall =
      sessionStorage.getItem('reJoinCall') == 'true' ? true : false;
    this.chatContainer = document.getElementById(
      'fc_frame'
    ) as HTMLElement;
    if (this.chatContainer) {
      this.chatContainer.style.display = 'none';
    }
    if (reJoinCall) {
        this.openDialogVideoCallV1();
    } else {
      if (!this.callStarted) {
        const payload = {
          MeetingID: this.vcData.RequestedAppSlotId,
        };
        this.joinMeetingService.getIsOnline(payload).subscribe((data: any) => {
          if (data.length > 0) {
            this.providerName = data[0].ProviderName;
            this.providerProfilePicture = data[0].ProfilePicture
              ? data[0].ProfilePicture
              : 'assets/Videoimages/nophoto_male.gif';
            this.providerGuid = data[0].ProviderUserId;
            //this.getRingtoneMusic();
            this.openDialogVideoCallV1();
            const ele: HTMLElement = document.getElementsByClassName(
              'btn-audioPlay'
            )[0] as HTMLElement;
            setTimeout(() => {
              ele.click();
            }, 0);
          }
        });
        sessionStorage.setItem('call_pop', 'true');
        // this.callingTimeOut = setTimeout(() => {
        //   this.isCalling = false;
        //   this.playAudio = false;
        //   this.sendDeclinedNotification(
        //     " didn't pick your call. Please call later."
        //   );
        // }, 50000);
      }
    }
  }

  sendDeclinedNotification(msg: any) {
    this.joinMeetingService.turnOffWaitingRoomCamera.next(true);
    if (this.callingTimeOut) {
      clearTimeout(this.callingTimeOut);
      this.callingTimeOut = null;
    }
    this.isCalling = false;
    this.playAudio = false;
    var reqSlotId = this.vcData.RequestedAppSlotId;
    // this.vcData.PatientUserId = this.vcData.PatientUserId ? this.vcData.PatientUserId : this.tokenService.userId();
    // var patientGuid = this.vcData.PatientUserId ? this.vcData.PatientUserId : sessionStorage.getItem('non-register-Guid');
    if (this.joinMeetingService.outPatientVideoCallFromQueue) {
      this.signalrService.sendMessage(
        this.providerGuid,
        1,
        'Invited patient did not accept your call',
        0
      );
    } else {
      this.signalrService.sendMessage(this.providerGuid, 1, reqSlotId + msg, 0);
    }
  }

  onClickAccept(): void {
    if (this.callingTimeOut) {
      clearTimeout(this.callingTimeOut);
      this.callingTimeOut = null;
    }
    this.isCalling = false;
    this.playAudio = false;
    this.openDialogVideoCall();
  }

  openDialogVideoCall(): void {
    if (this.callingTimeOut) {
      clearTimeout(this.callingTimeOut);
      this.callingTimeOut = null;
    }
    sessionStorage.removeItem('reJoinCall');
    sessionStorage.setItem('call-started', 'true');
    this.pauseElement = document.getElementsByClassName('pause-track');
    if (this.pauseElement.length > 0) {
      const ele: HTMLElement = document.getElementsByClassName(
        'pause-track'
      )[0] as HTMLElement;
      setTimeout(() => {
        ele.click();
      }, 0);
    }
    sessionStorage.setItem('message', 'patient-video-call');
    sessionStorage.setItem('patientInfo', JSON.stringify(this.vcData));
    if (this.joinMeetingService.outPatientVideoCallFromQueue) {
      sessionStorage.setItem('meetingid', this.externalRoomId);
    } else {
      sessionStorage.setItem('meetingid', this.vcData.RequestedAppSlotId);
    }
    this.displayMaximizable = true;
  }

  externalRoomId: any;
  getExternalRoomId() {
    this.vcData = JSON.parse(sessionStorage.getItem('meetingData') || '[]');
    this.joinMeetingService
      .getExternalRoomGuid(this.vcData.RequestedAppSlotId)
      .subscribe((data: any) => {
        if (data.length > 0) {
          this.joinMeetingService.outPatientVideoCallFromQueue = true;
          this.externalRoomId = data[0].ExternalRoomGuid;
          this.getProviderDetails();
        }
      });
  }

  interval(): any {
    return setInterval(async () => {}, 200);
  }

  showDialogMaximized(dialog: Dialog) {
    dialog.maximize();
  }

  onCloseVideoDialog() {
    this.displayMaximizable = false;
  }

  showToaster(val: any) {
    this.toaster.success(val, 'Information');
  }

  getRingtoneMusic(): void {
    this.joinMeetingService.getRingtone().subscribe((data: any) => {
      if (data.length > 0) {
        this.ringTone = data[0].link;
        this.playAudio = true;
        this.isCalling = true;
      }
    });
  }
  openDialogVideoCallV1(): void {
    this.vcData = JSON.parse(sessionStorage.getItem('meetingData') || '[]');
    sessionStorage.removeItem('reJoinCall');
    sessionStorage.setItem('call-started', 'true');
    sessionStorage.setItem('message', 'patient-video-call');
    sessionStorage.setItem('patientInfo', JSON.stringify(this.vcData));
    if (this.joinMeetingService.outPatientVideoCallFromQueue) {
      sessionStorage.setItem('meetingid', this.externalRoomId);
    } else {
      sessionStorage.setItem('meetingid', this.vcData.RequestedAppSlotId);
    }
    this.signalrService.sendMessage(
      this.providerGuid,
      1,
      'patient joined the video call',
      0
    );
    this.displayMaximizable = true;
  }
  onClickMe() {
    this.playAudio = true;
  }

  //   openFeedBackForm(){
  //     this.userValue="";
  //     this.selCategory="Website feedback";
  //     this.opened=true;
  //   }
  //   submitFeedBackForm(){
  //   this.userValue = this.userValue.replace("/'/g", "");
  //   this.userValue = this.userValue.replace("/>/g", "");
  //   this.userValue = this.userValue.replace("/</g", "");
  //   this.userValue = this.userValue.replace('/"/g', '');
  //   this.userValue = this.userValue.replace('/:/g', '');
  //    let xmlData= "<?xml version=\'1.0\' encoding=\'UTF-8\'?><FEEDBACK><CATEGORY>" + this.selCategory + "</CATEGORY><TEXT><![CDATA[" + this.userValue + "]]></TEXT></FEEDBACK>";
  //   applicationHttpClientCreator(this.http).Post(`${URLConstants.saveUserSiteFeedback}`, {
  //     Feedback:xmlData,
  //     URL :window.location.href,
  //     UserID:this.userId,
  //     Email:this.profileName?.Email

  //   }).subscribe((data: any) => {
  //     this.toaster.success(this.translate.instant("FeedBack Sent Successfully"));
  //      this.opened=false;
  //   })
  // }

//Alex Token
  GetWebToken() {
    if (sessionStorage.getItem('alexToken') == null) {
      this.AlexauthServer.GetToken({}).subscribe((token: any) => {
        sessionStorage.setItem('alexToken', EncryptDecrypt.encryptUsingAES256(token));

      });
    }
  }
}
