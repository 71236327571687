import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from 'src/app/data/service/authentication.service';
import { TokenService } from 'src/app/data/service/token.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    href: any;
    paymentUrl = "payment/success-info";
    paymentUrlFailure="/payment/failure-info";
    viewPageDetails = "/practice/view-page";
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private tokenService: TokenService
    ) { }
 //7-10-2022
 // Modified for payment/success-info / /payment/failure-infoto public
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.href = state.url;

        // const currentUser = this.authenticationService.currentUserValue;
        if (this.tokenService.userId() && this.tokenService.token()) {
            // if (currentUser) {
            // authorised so return true
            return true;
            // this.router.navigate('')/
        } else {
            if (this.href.includes(this.paymentUrl) || this.href.includes(this.paymentUrlFailure) || this.href.includes(this.viewPageDetails)) {
                return true;
            } else {
                // not logged in so redirect to login page with the return url
                this.router.navigate(['/authentication/login'], { queryParams: { returnUrl: state.url } });
                return false;
            }
        }


    }
    //Role-based authorization
    // Ref: https://medium.com/@maximzhukov_dev/angular-8-role-based-authorization-tutorial-f6278ff7d070
    // canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    //     if (!this.authService.isAuthorized()) {
    //         this.router.navigate(['login']);
    //         return false;
    //     }
    //     const roles = route.data.roles as Role[];
    //     if (roles && !roles.some(r => this.authService.hasRole(r))) {
    //         this.router.navigate(['error', 'not-found']);
    //         return false;
    //     }
    //     return true;
    // }
    // canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    //     if (!this.authService.isAuthorized()) {
    //         return false;
    //     }
    //     const roles = route.data && route.data.roles as Role[];
    //     if (roles && !roles.some(r => this.authService.hasRole(r))) {
    //         return false;
    //     }
    //     return true;
    // }
}