import {
  HttpBackend,
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
// Angular CLI configuration thing.
import { environment } from '../../../environments/environment';
import { LoaderService } from './loader.services';
import { TokenService } from './token.service';
export interface DiscoverHealthRequest {
  headers?: HttpHeaders;
  observe?: 'body';
  params?: HttpParams;
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
  body?: any;
}

@Injectable({
  providedIn: 'root',
})
export class DiscoverHealthHttpClientService implements HttpInterceptor {
  private api = environment.discoverHealthApi;
  public totalRequests = 0;
  // Extending the HttpClient through the Angular DI.
  public constructor(
    private tokenService: TokenService,
    private loaderService: LoaderService,
    private http: HttpClient
  ) {}
  /**
   * GET request
   * @param {string} endPoint it doesn't need / in front of the end point
   * @param {DiscoverHealthRequest} options options of the request like headers, body, etc.
   * @param {string} api use if there is needed to send request to different back-end than the default one.
   * @returns {Observable<T>}
   */
  public Get<T>(
    endPoint: string,
    options?: DiscoverHealthRequest
  ): Observable<T> {
    const headers = {
      'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Credentials': 'true', 'withCredentials' : 'true', 'Referrer-Policy':'no-referrer'// Add your custom header here
    };

    return this.http
      .get<T>(this.api + endPoint, { ...options, headers, observe: 'response' })
      .pipe(
        map((data) => {
          return {
            result: data?.body,
            totalCount: data?.headers?.get('x-wp-totalpages'),
          };
        }),
        catchError((err: any) => {
          if (err.statusCode === 401) {
            return this.Get(endPoint);
          }
          return of(err);
        })
      );
  }
  /**
   * POST request
   * @param {string} endPoint end point of the api
   * @param {Object} params body of the request.
   * @param {DiscoverHealthRequest} options options of the request like headers, body, etc.
   * @returns {Observable<T>}
   */
  public Post<T>(
    endPoint: string,
    params: Object,
    options?: DiscoverHealthRequest
  ): Observable<T> {
    return this.http.post<T>(this.api + endPoint, params, options).pipe(
      catchError((err: any) => {
        if (err.statusCode === 401) {
          return this.Get(endPoint);
        }
        return of(err);
      })
    );
  }
  /**
   * PUT request
   * @param {string} endPoint end point of the api
   * @param {Object} params body of the request.
   * @param {DiscoverHealthRequest} options options of the request like headers, body, etc.
   * @returns {Observable<T>}
   */
  public Put<T>(
    endPoint: string,
    params: Object,
    options?: DiscoverHealthRequest
  ): Observable<T> {
    return this.http.put<T>(this.api + endPoint, params, options).pipe(
      catchError((err: any) => {
        if (err.statusCode === 401) {
          return this.Get(endPoint);
        }
        return of(err);
      })
    );
  }
  /**
   * DELETE request
   * @param {string} endPoint end point of the api
   * @param {DiscoverHealthRequest} options options of the request like headers, body, etc.
   * @returns {Observable<T>}
   */
  public Delete<T>(
    endPoint: string,
    options?: DiscoverHealthRequest
  ): Observable<T> {
    return this.http.delete<T>(this.api + endPoint, options).pipe(
      catchError((err: any) => {
        if (err.statusCode === 401) {
          return this.Get(endPoint);
        }
        return of(err);
      })
    );
  }

  getWebPressToken(): Observable<any> {
    return this.http.post(
      environment.discoverHealthAspApi + 'GetWPToken',
      {},
      { headers: { Authorization: 'Bearer ' + this.getToken() } }
    );
  }

  public getToken(): string {
    let tokenValue: string = '';
    tokenValue = this.tokenService.token();
    return tokenValue;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.loaderService.show();
    this.totalRequests++;
    return next.handle(request).pipe(
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests < 0) {
          this.totalRequests = 0;
        }
        if (this.totalRequests === 0) {
          this.loaderService.hide();
        }
      })
    );
  }
}
