
import * as CryptoJS from 'crypto-js';
import { CommonConstants } from '../constants/commonConstants';

export  class EncryptDecrypt {
 
   static encrypted: any = "";
   static  decrypted: string;

    request: string;
    responce: string;
    static encryptUsingAES256(value:any) {
        let _key = CryptoJS.enc.Utf8.parse(CommonConstants.encryptionKey);
        let _iv = CryptoJS.enc.Utf8.parse(CommonConstants.encryptionKey);
        let encrypted = CryptoJS.AES.encrypt(
            CryptoJS.enc.Utf8.parse(value), _key, {
            keySize: 16,
            iv: _iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        this.encrypted = encrypted.toString();
        return this.encrypted;
    }
   static  decryptUsingAES256(value:any) {
        let _key = CryptoJS.enc.Utf8.parse(CommonConstants.encryptionKey);
        let _iv = CryptoJS.enc.Utf8.parse(CommonConstants.encryptionKey);

        this.decrypted = CryptoJS.AES.decrypt(
            value, _key, {
            keySize: 16,
            iv: _iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        }).toString(CryptoJS.enc.Utf8);
        return this.decrypted;
    }
}