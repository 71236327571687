<div class="slide-tray-content send-message">

    <perfect-scrollbar class="desktop-view-1024 perfect-scrollbar-draftdetail" [config]="config">
        <!-- <div class="mobile-view-1024 page-header header-buttons m-b-20 m-t-20 m-l-20 m-b-sm-0">
            <h1 class="d-flex align-items-center" (click)=" backToDraft()">
            <i class="icon-cent-chevron-left-arrows color-dark cursor-pointer" (click)=" backToDraft()">
            </i>
            {{'Back'|translate}}
            </h1>
        </div> -->
        <div class="email-view-content" style="padding-top: 30px;">
            <div class="mail-content-wrapper top-content-wrapper">
                <form [formGroup]="composeForm">
                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="0px" fxLayoutGap.gt-md="120px">
                        <div fxFlex="100" fxFlex.gt-md="75">
                            <mat-form-field appearance="outline" class="icon-right"
                                [ngClass]="{ 'has-success': f.healthCenter.valid}"
                                *ngIf="healthCenterList.length>1&&!draftselectedMessagesDetails[0]?.IsReply">
                                <mat-label> {{'Health Centre'|translate}}</mat-label>
                                <mat-select formControlName="healthCenter">
                                    <mat-option *ngFor="let health of healthCenterList"
                                        value="{{health.PracticeManagerId}}"
                                        (click)="getLocations(health.PracticeManagerId, true)">
                                        {{health.BusinessName}}
                                    </mat-option>
                                </mat-select>
                                <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                            </mat-form-field>

                            <!-- Location -->
                            <mat-form-field appearance="outline" class="icon-right"
                                *ngIf="locations.length>0&&!draftselectedMessagesDetails[0]?.IsReply"
                                [ngClass]="{ 'has-success': f.location.valid}" class="icon-right">
                                <mat-label> {{'Location'|translate}}</mat-label>
                                <!-- <input matInput type="text" placeholder="Location"> -->
                                <mat-select #locationMatSelect formControlName="location">
                                    <mat-option *ngFor="let location of locations" [value]="location.LocationId"
                                        (click)="getServiceTypes(location.LocationId, true)">
                                        {{location.LocationName}}
                                    </mat-option>
                                </mat-select>
                                <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                            </mat-form-field>

                            <!-- Service Name -->
                            <mat-form-field appearance="outline" class="icon-right"
                                *ngIf="serviceTypes.length>0&&!draftselectedMessagesDetails[0]?.IsReply"
                                [ngClass]="{ 'has-success': f.serviceName.valid}">
                                <mat-label> {{'Service Name'|translate}}</mat-label>
                                <mat-select formControlName="serviceName" (selectionChange)="checkingIsRRP($event)">
                                    <mat-option *ngFor="let service of serviceTypes" [value]="service.MessageTypeID">
                                        {{service.MessageCode}}
                                    </mat-option>
                                </mat-select>
                                <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                            </mat-form-field>

                            <div class="mail-info-sub space-info-role">
                                <div class="grid-normal-tbl">
                                    <div class="tbl-row" *ngIf="draftselectedMessagesDetails[0]?.IsReply">
                                        <div class="tbl-th">{{'To'|translate}}:</div>
                                        <div class="tbl-td">{{toUserName}}</div>
                                    </div>
                                    <div class="alert alert-info m-t-10 m-b-10"
                                        *ngIf="serviceTypes.length==0 &&!draftselectedMessagesDetails[0]?.IsReply">
                                        <mat-label> {{'Service name'|translate}}</mat-label>
                                        <p>
                                            <span style="display:flex;margin-top: 5px;"><mat-icon>warning</mat-icon>
                                                &nbsp; {{"Your health centre is not configured for this service.please
                                                contact your health centre."| translate}}</span>
                                        </p>
                                    </div>
                                    <div class="tbl-row" *ngIf="draftselectedMessagesDetails[0]?.IsReply">
                                        <div class="tbl-th">{{'Service Type'|translate}}:</div>
                                        <div class="tbl-td">{{toServiceName}}</div>
                                    </div>
                                </div>
                            </div>
                            <!-- Role -->
                            <ng-container *ngIf="
                            serviceTypelocationDetails[0]?.RoleId ==
                            '00000000-0000-0000-0000-000000000000'
                        ">
                            <mat-form-field appearance="outline" class="icon-right"
                                *ngIf="Roles.length>0&&!draftselectedMessagesDetails[0]?.IsReply&&draftselectedMessagesDetails[0]?.RoleID!='00000000-0000-0000-0000-000000000000'"
                                [ngClass]="{ 'has-success': f.role.valid}">
                                <mat-label class="required"> {{'Role'|translate}}</mat-label>
                                <mat-select formControlName="role">
                                    <mat-option *ngFor="let role of Roles" [value]="role.RoleId"
                                        (click)="getToUserDetails(role.RoleId,'change');">
                                        {{role.Description}}
                                    </mat-option>
                                </mat-select>
                                <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                            </mat-form-field>
                            </ng-container>
                            <!-- To User -->
                            <mat-form-field appearance="outline" class="icon-right"
                                *ngIf="!draftselectedMessagesDetails[0]?.IsReply &&  selectedRole[0]?.RoleName != 'Patient'"
                                [ngClass]="{ 'has-success': f.toUser.valid}">
                                <mat-label class="required"> {{'To'|translate}}</mat-label>
                                <mat-select formControlName="toUser"
                                    [readonly]="draftselectedMessagesDetails[0]?.RoleID=='00000000-0000-0000-0000-000000000000'">
                                    <mat-option *ngFor="let to of toUser" value="{{to.UserID}}"
                                        (click)="checkProviderAvailability(to)">
                                        {{to.UserName}}
                                    </mat-option>
                                </mat-select>
                                <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                            </mat-form-field>
                            <!-- <mat-form-field appearance="outline" class="icon-right"
                                *ngIf="toUser.length==0 && Roles[2]?.RoleName == 'Patient'"
                                [ngClass]="{ 'has-success': f.patientdata.valid}">
                                <mat-label> {{'To'|translate}}</mat-label>
                                <input type="text" placeholder="Enter Patient first name" aria-label="Number" matInput
                                    formControlName="patientdata" [matAutocomplete]="auto">
                                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                </mat-autocomplete>
                            </mat-form-field> -->

                            <div *ngIf="selectedRole[0]?.RoleName == 'Patient'">
                                <div fxLayout="row wrap" fxLayoutAlign="center center">
                                    <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" class="auto-suggest"
                                        [ngClass]="{ 'has-value': f.toUser.valid}">
                                        <mat-label class="auto-complete-lable required">
                                            {{"To"|translate}}</mat-label>
                                        <kendo-autocomplete [(ngModel)]="UserSearch" [data]="patientList"
                                            [filterable]="true" (keyup.backspace)="backspaceCheck($event)"
                                            (valueChange)=selectedName($event) formControlName="toUser"
                                            (filterChange)="handleFilter($event)" [valueField]="'names'"
                                            [placeholder]="'Enter Patient first name'">
                                        </kendo-autocomplete>
                                    </div>
                                </div>
                            </div>
                            <!-- Terms and conditions checkbox
        <mat-checkbox color="warn" formControlName="termsconditions" (click)="acceptCheckBox($event)">
            {{'I agree to the terms & conditions'|translate}}
        </mat-checkbox>
        <mat-error *ngIf="f.termsconditions.hasError('required') && f.termsconditions.touched" class="text-danger support-text  error-name m-t-10">{{'privacy'|translate}}</mat-error> -->

                            <!-- Subject -->
                            <mat-form-field appearance="outline" [ngClass]="{ 'has-success': f.subject.valid}">
                                <mat-label> {{'Subject'|translate}}</mat-label>
                                <input matInput type="text" formControlName="subject" placeholder="Subject">
                                <mat-icon class="error" *ngIf="f.subject.invalid && f.subject.touched">
                                    info_outline</mat-icon>
                            </mat-form-field>
                            <mat-error *ngIf="f.subject.hasError('required') && f.subject.touched"
                                class="text-danger support-text  error-name">{{'Required'|translate}}</mat-error>
                        </div>
                    </div>

                    <!-- <div class="mail-spacer-line"></div> -->
                    <div class="checkbox-inflex">
                        <!-- <mat-checkbox formControlName="termsconditions" ></mat-checkbox> -->
                        <mat-checkbox formControlName="termsconditions" (click)="acceptCheckBox($event)"></mat-checkbox>
                        <span> {{ "I agree to the" | translate }} <a class="terms" (click)="showTerms = true">{{ "terms
                                & conditions" | translate }}
                            </a>
                        </span>
                    </div>
                    <!-- <mat-checkbox color="warn" formControlName="termsconditions">
                                  {{'I agree to the terms & conditions'|translate}}
                              </mat-checkbox> -->
                    <mat-error *ngIf="f.termsconditions.hasError('required') && f.termsconditions.touched"
                        class="text-danger support-text  error-name m-t-10">{{'privacy'|translate}}</mat-error>

                    <!-- Message Body (Quill Editor) -->
                    <div class="email-content m-t-20">
                        <mat-label> <b>{{'Write your message'|translate}}</b></mat-label>
                        <div class="save-indicator auto-drft-align fonts-16">{{ saveIndicator }}</div>

                        <kendo-editor formControlName="message" [iframe]="false" (valueChange)="valueChange($event)"
                            class="kendo-editor-box" k-encoded="true">
                        </kendo-editor>

                        <mat-error *ngIf="f.message.hasError('required') && f.message.touched"
                            class="text-danger support-text m-t-5 error-name">{{'Required'|translate}}</mat-error>
                    </div>

                    <!-- Attach Files -->
                    <div class="m-b-10 m-t-20 attach-file">

                        <button mat-button class="btn-primary-rect" *ngIf="BtnHide" (click)="attachFileDialog()">
                            <mat-icon>attachment</mat-icon> {{'Attach Files'|translate}}
                        </button>


                        <!-- <div class="filter-by-index page-header m-b-sm-0" style="padding: 0px;">
                            <div *ngFor="let file of addedFiles; let i=index">
                            <p >{{file?.name}}</p>
                            <div class="icon-container">
                            <mat-icon (click)="remove(i)">delete_outline
                            </mat-icon>
                        </div>
                        </div>
                        </div> -->

                        <div class="filter-by-index page-header m-b-sm-0" style="padding: 0px; cursor:context-menu;"
                            *ngFor="let file of addedFiles; let i = index">
                            <p>{{ file?.name }}
                            <p>
                            <div class="icon-container">
                                <mat-icon (click)="remove(i)">delete_outline</mat-icon>
                            </div>
                        </div>
                    </div>
                    <div class="m-b-20">
                        <div *ngIf="filesData && filesData.length > 0 ">
                            <div class="filter-by-index page-header m-b-sm-0" style="padding: 0px;"
                                *ngFor="let data of filesData; let i=index">

                                <p>
                                    <span
                                        (click)="downloadFiles(data?.DestName['cdata-section'],data?.SourceName['cdata-section'])">{{data?.SourceName['cdata-section']}}</span>
                                </p>

                                <div class="icon-container">
                                    <mat-icon (click)="removeSaved(i)">delete_outline
                                    </mat-icon>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="filesData && filesData.hasOwnProperty('DestName')" class="m-b-20">
                            <div class="filter-by-index page-header m-b-sm-0" style="padding: 0px;">
                                <p class="hand-cursor">
                                    <span
                                        (click)="downloadFiles(filesData?.DestName['cdata-section'],filesData?.SourceName['cdata-section'])">
                                        {{filesData?.SourceName['cdata-section']}}
                                    </span>
                                </p>
                                <div class="icon-container" class="hand-cursor">
                                    <mat-icon (click)="removeSingleFile()">delete_outline
                                    </mat-icon>
                                </div>

                            </div>
                        </div>
                    </div>
                    <mat-error *ngIf="filelengthError" class="text-danger support-text  error-name">{{'filelength
                        validation' | translate}}</mat-error>


                    <!-- Terms and conditions checkbox -->
                    <!-- <mat-checkbox class="agree-checkbox" color="warn" formControlName="termsconditions">
                        {{ "I agree to the" | translate
                        }}<span class="checkbox-link help-info">
                        {{ "terms & conditions" | translate }}
                        <div class="help-tooltip-container compose-tc-postion">
                            <h3>{{ "Terms & Conditions" | translate }}</h3>
                            <div class="tooltip-content">
                            <p>
                                {{ serviceTypes[0]?.terms }}
                            </p>
                            </div>
                        </div>
                        </span>
                    </mat-checkbox> -->

                    <!-------------------- Buttons for save as draft and send as message--------------->
                    <div class="desktop-view-1024 email-view-footer1">
                        <div class="footer-buttons1">
                            <button mat-button class="btn-secondary-pill-gray m-r-10" (click)="closeSelectedMail()"
                                style="white-space: initial;line-height: 18px;" (click)="sendMesssage('Drafts')">
                                <span class="icon-text-footer-align"><mat-icon class="m-r-10">save</mat-icon> {{'Save as
                                    Draft'|translate}}</span></button>
                            <button mat-button class="btn-primary-pill" (click)="closeSelectedMail()"
                                (click)="sendMessageActive('Inbox')"
                                [disabled]="composeForm.invalid || disableSubmit || addedFiles.length >= 4 || messageBoxValue.length==0 || serviceTypes.length==0 &&!draftselectedMessagesDetails[0]?.IsReply ">
                                <span class="icon-text-footer-align"><mat-icon class="m-r-10">send</mat-icon>{{'Send
                                    message'|translate}}</span>
                            </button>
                            <div class="fee-details" *ngIf="billableCost">Fee : ${{billableCost?.toFixed(2)}} (Incl.
                                GST)</div>
                        </div>
                    </div>

                    <!-- <div class="mobile-view-1024 email-view-footer mbl-foot-btn">
                        <div class="footer-buttons ftr-sm-2-row-btns" style=" display: flex !important;justify-content: center;">
                            <button mat-button class="btn-secondary-pill-gray m-r-10" (click)="sendMesssage('Drafts')">
                                <span class="icon-text-footer-align"><mat-icon class="m-r-10">save</mat-icon> {{'Save as Draft'|translate}} </span></button>
                            <button mat-button class="btn-primary-pill" (click)="sendMesssage('Inbox')" type="submit">
                                <span class="icon-text-footer-align"><mat-icon class="m-r-10">send</mat-icon>{{'Send message'|translate}} </span>
                            </button>
                        </div>
                    </div> -->

                </form>
            </div>
        </div>

    </perfect-scrollbar>
    <perfect-scrollbar class="mobile-view-1024 grid-container" [config]="configDraft">
        <div class="page-header header-buttons m-b-20 m-t-20 m-l-20 m-b-sm-0 back-left-align">
            <h1 class="d-flex align-items-center" (click)=" backToDraft()">
                <i class="icon-cent-chevron-left-arrows color-dark cursor-pointer" (click)=" backToDraft()">
                </i>
                {{'Back'|translate}}
            </h1>
        </div>
        <div class="email-view-content">
            <div>
                <form [formGroup]="composeForm">
                    <mat-card class="radius-10 h-overflow">
                        <mat-card-content class="ipad-view-width">
                            <div class="page-header m-b-sm-0" style="padding: 0px;">
                                <div class="flexbox1-detail">
                                    <!-- <div fxLayout="row" fxLayout.lt-md="row" fxLayoutGap="0px" fxLayoutGap.gt-md="80px">
                        <div fxFlex="100" fxFlex.gt-md="100"> -->
                                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.gt-sm="20px">
                                        <div fxFlex.gt-sm="100" fxFlex="100">
                                            <mat-form-field appearance="outline" class="icon-right"
                                                [ngClass]="{ 'has-success': f.healthCenter.valid}"
                                                *ngIf="healthCenterList.length>1&&!draftselectedMessagesDetails[0]?.IsReply">
                                                <mat-label> {{'Health Centre'|translate}}</mat-label>
                                                <mat-select formControlName="healthCenter">
                                                    <mat-option *ngFor="let health of healthCenterList"
                                                        value="{{health.PracticeManagerId}}"
                                                        (click)="getLocations(health.PracticeManagerId, true)">
                                                        {{health.BusinessName}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <!-- Location -->
                                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.gt-sm="20px">
                                        <div fxFlex.gt-sm="100" fxFlex="100">
                                            <mat-form-field appearance="outline" class="icon-right"
                                                *ngIf="locations.length>0&&!draftselectedMessagesDetails[0]?.IsReply"
                                                [ngClass]="{ 'has-success': f.location.valid}" class="icon-right">
                                                <mat-label> {{'Location'|translate}}</mat-label>
                                                <!-- <input matInput type="text" placeholder="Location"> -->
                                                <mat-select formControlName="location">
                                                    <mat-option *ngFor="let location of locations"
                                                        [value]="location.LocationId"
                                                        (click)="getServiceTypes(location.LocationId, true)">
                                                        {{location.LocationName}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <!-- Service Name -->
                                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.gt-sm="20px">
                                        <div fxFlex.gt-sm="100" fxFlex="100">
                                            <mat-form-field appearance="outline" class="icon-right"
                                                *ngIf="serviceTypes.length>0&&!draftselectedMessagesDetails[0]?.IsReply"
                                                [ngClass]="{ 'has-success': f.serviceName.valid}">
                                                <mat-label> {{'Service Name'|translate}}</mat-label>
                                                <mat-select formControlName="serviceName">
                                                    <mat-option *ngFor="let service of serviceTypes"
                                                        [value]="service.MessageTypeID"
                                                        (click)="checkingIsRRP(service)">
                                                        {{service.MessageCode}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div class="mail-info-sub space-info-role">
                                        <div class="grid-normal-tbl">
                                            <div class="tbl-row" *ngIf="draftselectedMessagesDetails[0]?.IsReply">
                                                <div class="tbl-th">{{'To'|translate}}:</div>
                                                <div class="tbl-td">{{toUserName}}</div>
                                            </div>
                                            <div class="alert alert-info m-t-10 m-b-10"
                                                *ngIf="serviceTypes.length==0 &&!draftselectedMessagesDetails[0]?.IsReply">
                                                <mat-label> {{'Service name'|translate}}</mat-label>
                                                <p>
                                                    <span
                                                        style="display:flex;margin-top: 5px;"><mat-icon>warning</mat-icon>
                                                        &nbsp; {{"Your health centre is not configured for this
                                                        service.please contact your health centre."| translate}}</span>
                                                </p>
                                            </div>
                                            <div class="tbl-row" *ngIf="draftselectedMessagesDetails[0]?.IsReply">
                                                <div class="tbl-th">{{'Service Type'|translate}}:</div>
                                                <div class="tbl-td">{{toServiceName}}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Role -->
                                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.gt-sm="20px"   *ngIf="
                                    serviceTypelocationDetails[0]?.RoleId ==
                                    '00000000-0000-0000-0000-000000000000'
                                ">
                                        <div fxFlex.gt-sm="100" fxFlex="100">
                                            <mat-form-field appearance="outline" class="icon-right"
                                                *ngIf="Roles.length>0&&!draftselectedMessagesDetails[0]?.IsReply&&draftselectedMessagesDetails[0]?.RoleID!='00000000-0000-0000-0000-000000000000'"
                                                [ngClass]="{ 'has-success': f.role.valid}">
                                                <mat-label> {{'Role'|translate}}</mat-label>
                                                <mat-select formControlName="role">
                                                    <mat-option *ngFor="let role of Roles" [value]="role.RoleId"
                                                        (click)="getToUserDetails(role.RoleId,'change');">
                                                        {{role.Description}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <!-- To User -->
                                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.gt-sm="20px">
                                        <div fxFlex.gt-sm="100" fxFlex="100">
                                            <mat-form-field appearance="outline" class="icon-right"
                                                *ngIf="toUser.length>0&&!draftselectedMessagesDetails[0]?.IsReply &&  selectedRole[0]?.RoleName != 'Patient'"
                                                [ngClass]="{ 'has-success': f.toUser.valid}">
                                                <mat-label> {{'To'|translate}}</mat-label>
                                                <mat-select formControlName="toUser"
                                                    [readonly]="draftselectedMessagesDetails[0]?.RoleID=='00000000-0000-0000-0000-000000000000'">
                                                    <mat-option *ngFor="let to of toUser" value="{{to.UserID}}"
                                                        (click)="checkProviderAvailability(to)">
                                                        {{to.UserName}}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
                                            </mat-form-field>
                                            <div *ngIf="selectedRole[0]?.RoleName == 'Patient'">
                                                <div fxLayout="row wrap" fxLayoutAlign="center center">
                                                    <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100"
                                                        class="auto-suggest" [ngClass]="{ 'has-value': f.toUser.valid}">
                                                        <mat-label class="auto-complete-lable required">
                                                            {{"To"|translate}}</mat-label>
                                                        <kendo-autocomplete [(ngModel)]="UserSearch"
                                                            [data]="patientList" [filterable]="true"
                                                            (keyup.backspace)="backspaceCheck($event)"
                                                            (valueChange)=selectedName($event) formControlName="toUser"
                                                            (filterChange)="handleFilter($event)" [valueField]="'names'"
                                                            [placeholder]="'Enter Patient first name'">
                                                        </kendo-autocomplete>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <!-- Terms and conditions checkbox
                           <mat-checkbox color="warn" formControlName="termsconditions">
                        {{'I agree to the terms & conditions'|translate}}
                    </mat-checkbox>
                     <mat-error *ngIf="f.termsconditions.hasError('required') && f.termsconditions.touched" class="text-danger support-text  error-name m-t-10">{{'privacy'|translate}}</mat-error> -->

                                    <!-- Subject -->
                                    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap.gt-sm="20px">
                                        <div fxFlex.gt-sm="100" fxFlex="100">
                                            <mat-form-field appearance="outline"
                                                [ngClass]="{ 'has-success': f.subject.valid}">
                                                <mat-label> {{'Subject'|translate}}</mat-label>
                                                <input matInput type="text" formControlName="subject"
                                                    placeholder="Subject">
                                                <mat-icon class="error" *ngIf="f.subject.invalid && f.subject.touched">
                                                    info_outline</mat-icon>
                                            </mat-form-field>
                                            <mat-error *ngIf="f.subject.hasError('required') && f.subject.touched"
                                                class="text-danger support-text  error-name">{{'Required'|translate}}</mat-error>
                                        </div>
                                    </div>

                                    <!-- <div class="mail-spacer-line"></div> -->

                                    <!-- Terms and conditions checkbox -->
                                    <div class="checkbox-inflex">
                                        <mat-checkbox formControlName="termsconditions"></mat-checkbox>
                                        <span> {{ "I agree to the" | translate }} <a class="terms"
                                                (click)="showTerms = true">{{ "terms & conditions" | translate }}
                                            </a>
                                        </span>
                                    </div>
                                    <mat-error
                                        *ngIf="f.termsconditions.hasError('required') && f.termsconditions.touched"
                                        class="text-danger support-text  error-name m-t-10">{{'privacy'|translate}}</mat-error>

                                    <!-- Message Body (Quill Editor) -->
                                    <div class="email-content m-t-20">
                                        <mat-label> <b>{{'Write your message'|translate}}</b></mat-label>
                                        <div class="save-indicator auto-drft-align fonts-16">{{ saveIndicator }}</div>
                                        <kendo-editor formControlName="message" [iframe]="false"
                                            (valueChange)="valueChange($event)" class="kendo-editor-box"></kendo-editor>

                                        <mat-error *ngIf="f.message.hasError('required') && f.message.touched"
                                            class="text-danger support-text m-t-5 error-name">{{'Required'|translate}}</mat-error>
                                    </div>

                                    <!-- Attach Files -->
                                    <div class="m-b-10 m-t-20 attach-file" *ngIf="BtnHide">

                                        <button mat-button class="btn-primary-rect" (click)="attachFileDialog()">
                                            <mat-icon>attachment</mat-icon> {{'Attach Files'|translate}}
                                        </button>


                                        <!-- <div class="filter-by-index page-header m-b-sm-0" style="padding: 0px;">
                            <div *ngFor="let file of addedFiles; let i=index">
                            <p >{{file?.name}}</p>
                            <div class="icon-container">
                            <mat-icon (click)="remove(i)">delete_outline
                            </mat-icon>
                        </div>
                        </div>
                        </div> -->

                                        <div class="filter-by-index page-header m-b-sm-0" style="padding: 0px;"
                                            *ngFor="let file of addedFiles; let i = index">
                                            <p>{{ file?.name }}</p>
                                            <div class="icon-container">
                                                <mat-icon (click)="remove(i)">delete_outline</mat-icon>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="m-b-20">
                                        <div *ngIf="filesData && filesData.length >0">
                                            <div class="filter-by-index page-header m-b-sm-0" style="padding: 0px;"
                                                *ngFor="let data of filesData; let i=index">

                                                <p>
                                                    <span
                                                        (click)="downloadFiles(data?.DestName['cdata-section'],data?.SourceName['cdata-section'])">{{data?.SourceName['cdata-section']}}</span>
                                                </p>

                                                <div class="icon-container">
                                                    <mat-icon (click)="removeSaved(i)">delete_outline
                                                    </mat-icon>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="filesData && filesData.hasOwnProperty('DestName')" class="m-b-20">
                                            <div class="filter-by-index page-header m-b-sm-0" style="padding: 0px;">
                                                <p>
                                                    <span
                                                        (click)="downloadFiles(filesData?.DestName['cdata-section'],filesData?.SourceName['cdata-section'])">
                                                        {{filesData?.SourceName['cdata-section']}}
                                                    </span>
                                                </p>
                                                <div class="icon-container">
                                                    <mat-icon (click)="removeSingleFile()">delete_outline
                                                    </mat-icon>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <mat-error *ngIf="filelengthError"
                                        class="text-danger support-text  error-name">{{'filelength
                                        validation' | translate}}</mat-error>


                                    <!-- <mat-checkbox color="warn" formControlName="termsconditions">
                        {{'I agree to the terms & conditions'|translate}}
                    </mat-checkbox> -->


                                    <!-------------------- Buttons for save as draft and send as message--------------->
                                    <!-- <div class="desktop-view-1024 email-view-footer1">
                        <div class="footer-buttons1">
                            <button mat-button class="btn-secondary-pill-gray m-r-10" (click)="closeSelectedMail()"
                                style="white-space: initial;line-height: 18px;" (click)="sendMesssage('Drafts')">
                                <span class="icon-text-footer-align"><mat-icon class="m-r-10">save</mat-icon> {{'Save as Draft'|translate}}</span></button>
                            <button mat-button class="btn-primary-pill" (click)="closeSelectedMail()"
                                (click)="sendMesssage('Inbox')" [disabled]="composeForm.invalid || addedFiles.length > 3">
                                <span class="icon-text-footer-align"><mat-icon class="m-r-10">send</mat-icon>{{'Send message'|translate}}</span>
                            </button>
                        </div>
                    </div> -->

                                    <div>
                                        <div class="grid-btns-two">
                                            <button mat-button class="btn-secondary-pill-gray m-r-10"
                                                (click)="sendMesssage('Drafts')">
                                                {{'Save as Draft'|translate}}</button>
                                            <button mat-button class="btn-primary-pill"
                                                (click)="sendMessageActive('Inbox')"
                                                [disabled]="composeForm.invalid || disableSubmit || addedFiles.length > 3 || messageBoxValue.length==0 || serviceTypes.length==0 &&!draftselectedMessagesDetails[0]?.IsReply "
                                                type="submit">
                                                {{'Send message'|translate}}
                                            </button>

                                        </div>
                                        <div class="fee-details" *ngIf="billableCost">Fee :
                                            ${{billableCost?.toFixed(2)}} (Incl. GST)</div>
                                    </div>

                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </form>
            </div>
        </div>
    </perfect-scrollbar>

</div>

<kendo-dialog  *ngIf="showTerms" (close)="showTerms = false" [minWidth]="250" [width]="500"
    class="res-sm p-line-20">
    <div class="p-t-30">
        <div *ngIf="showInformation">
          <b class="font-20">Information</b>
          <p>
            {{serviceTypelocationDetails[0]?.MessageDescription}}
          </p>
          <p>Service Cost: ${{serviceTypelocationDetails[0]?.BillableCost?.toFixed(2)}}</p>
          <p>Payment Method: Pay at Health Center</p>
        </div>
        <b class="font-20">Terms & Conditions</b>
        <p class="terms-and-conditions">
          {{selectedServiceTypeDataterms}}
        </p>
    </div>
    <kendo-dialog-actions>
        <button kendoButton (click)="agreeTerms()" class="btn-primary-rect">
        I AGREE
        </button>
        <button kendoButton (click)="cancelTerms()" class="btn-secondary-rect">
        CANCEL
        </button>
        <!-- <button kendoButton (click)="showTerms = false" class="btn-secondary-rect">
            Ok
        </button> -->
    </kendo-dialog-actions>
</kendo-dialog>

<!-- <kendo-dialog title="Information" *ngIf="showInformation" (close)="showInformation = false" [minWidth]="250"
    [width]="500" class="res-sm p-line-20">

    <p class="terms-and-conditions">
        Please note that the service you have chosen incurs a fee of ${{billableCost?.toFixed(2)}}. If you agree to
        proceed with the chargeable service, please acknowledge your acceptance.
    </p>
    <kendo-dialog-actions>
        <button kendoButton (click)="acknowledge()" class="btn-primary-rect">
            ACKNOWLEDGE
        </button>
    </kendo-dialog-actions>
</kendo-dialog> -->