import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
//import { AdZonesElementIds } from 'src/app/core/constants/adbutler.const';
import { URLConstants } from 'src/app/core/constants/urlConstants';
import { EncryptDecrypt } from 'src/app/core/helper/encrypt-decrypt';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AdbutlerService {
  adButlerInstance: any;
  httpClient: HttpClient;
  delayAd:boolean=false;
  public appointmentStatus =false;
  userDetails:any;
  patientsPHODetails:any;
  age:number;
  gender:string = '';
  ethnicity:string = '';
  country:string = '';
  city:string = '';
  pho:string = '';
  gp:string = '';
  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {   
    this.userProfile()  
  }

  async registerAd(zoneId: number) {
    window['plc' + zoneId] = window['plc' + zoneId] || 0;
    return (await this.getAdsByZoneId(zoneId, window['plc' + zoneId])).subscribe();
  }
  userProfile(){
    let userProfile: any;
    let patientsDetails: any;
    if (sessionStorage.getItem('userProfile')) {
      userProfile = sessionStorage.getItem('userProfile');
      const parsedData =EncryptDecrypt.decryptUsingAES256(userProfile); 
      const data=JSON.parse(parsedData);
      this.userDetails = data
      this.age=this.userDetails.Age;
      this.gender=this.userDetails.Gender;
      this.ethnicity=this.userDetails.Ethnicity;
      this.country=this.userDetails.Country;
      this.city=this.userDetails.City;

      if(sessionStorage.getItem('patientsDetails')){
        patientsDetails = sessionStorage.getItem('patientsDetails');
        const patientsParsedData =EncryptDecrypt.decryptUsingAES256(patientsDetails); 
        const patientsData=JSON.parse(patientsParsedData);
        this.patientsPHODetails = patientsData;
        if(this.patientsPHODetails && this.patientsPHODetails.length){
          this.gp = this.patientsPHODetails[0].PracticeName;
          this.pho = this.patientsPHODetails[0].PHOName;
        }
      }

      let values = JSON.stringify({
        Age : this.age ? this.age : '',
        Gender:this.gender ? this.gender : '',
        Ethnicity : this.ethnicity ? this.ethnicity : '',
        GP: this.gp ? this.gp : '',
        PHO: this.pho ? this.pho : '',
        LocationCountry : this.country ? this.country : '',
        LocationCity : this.city ? this.city : ''
    })

    sessionStorage.setItem('adbutlerValues',values);
      // localStorage.setItem("alexpat",data.nhiNumber);
      console.log(this.userDetails,this.age,"USERDATA adbutler");

      return data;
    } else {
      return null;
    }
  }

  adbutlerIframeURl(zoneId:any){
    this.userProfile();
    let Age = this.age ? this.age : '';
    let Gender =  this.gender  ? this.gender  : '';
    let Ethnicity =  this.ethnicity ? this.ethnicity : '';
    let GP =  this.gp ? this.gp : '';
    let PHO =  this.pho ? this.pho : '';
    let LocationCountry =  this.country ? this.country : '';
    let LocationCity =  this.city  ? this.city  : '';
    let src = '';
    return src = `https://reach.managemyhealth.co.nz/adserve/;ID=${(environment.adButlerAccountId)};size=1092x90;setID=${(zoneId)};type=iframe;_abdk[Age]=${(Age)};_abdk[Ethnicity]=${(Ethnicity)};_abdk[GP]=${(GP)};_abdk[Gender]=${(Gender)};_abdk[LocationCity]=${(LocationCity)};_abdk[LocationCountry]=${(LocationCountry)};_abdk[PHO]=${(PHO)};_abdk[Article]= ${(sessionStorage.getItem('articleTitle'))}`;
  }
  async getAdsByZoneId(zoneId: number, place = 0) {
    // Get the user details
    this.userProfile();
    const params = {
      ID: EncryptDecrypt.encryptUsingAES256(environment.adButlerAccountId),
      setID: EncryptDecrypt.encryptUsingAES256(zoneId),
      type: 'json',
      place,
      rf: 1,
      _abdk_json:EncryptDecrypt.encryptUsingAES256(JSON.stringify({
        Age : this.age ? this.age : '',
        Gender:this.gender ? this.gender : '',
        Ethnicity : this.ethnicity ? this.ethnicity : '',
        GP: this.gp ? this.gp : '',
        PHO: this.pho ? this.pho : '',
        LocationCountry : this.country ? this.country : '',
        LocationCity : this.city ? this.city : '',
        Article:sessionStorage.getItem('articleTitle')
    }))
    };
    const headers = new HttpHeaders();   
    return await this.http.post(environment.apiBaseURL + URLConstants.reachServe, params)
    .pipe(
      filter((res: any) =>  res?.status?.toLowerCase() === 'success' ? true : true ),
      tap((res:any) => {   

        if(res?.status?.toLowerCase() == 'no_ads'){
          if(environment.repeatPresciptionswebVideo == zoneId){
            localStorage.setItem("videoStatus",'hide');
          }
          if(environment.dashBoardAdZoneId == zoneId){
            localStorage.setItem("dashBoardAdZoneId",'hide');
          }
          if(environment.dashboardCenterZoneID == zoneId){
            localStorage.setItem("dashboardCenterZoneID",'hide');
          }
          if(environment.dashboardtopMobileZoneID == zoneId){
            localStorage.setItem("dashboardtopMobileZoneID",'hide');
          }
          if(environment.appointmentCenterZoneID == zoneId){
            localStorage.setItem("appointmentCenterZoneID",'hide');
          }
          if(environment.appointmentMobileZoneID == zoneId){
            localStorage.setItem("appointmentMobileZoneID",'hide');
          }
          if(environment.labResultsCenterZoneId == zoneId){
            localStorage.setItem("labResultsCenterZoneId",'hide');
          }
          if(environment.labResultsMobileTopZoneId == zoneId){
            localStorage.setItem("labResultsMobileTopZoneId",'hide');
          }
          if(environment.dashBoardMobileAdZoneId_p1 == zoneId){
            localStorage.setItem("dashBoardMobileAdZoneId_p1",'hide');
          }
          if(environment.loginpageBottomZoneId == zoneId){
            localStorage.setItem("loginpageBottomZoneId",'hide');
          }
          if(environment.repeatPrescriptionsTabsVideo == zoneId){
            localStorage.setItem("videoStatusMobile",'hide');
          }
          if(environment.appointmentsTabVideoad == zoneId){
            localStorage.setItem("videoStatusappMobile",'hide');
          }
          if(environment.articlepage == zoneId){
            localStorage.setItem("articlepage",'hide');
          }
        }

        if(res?.status?.toLowerCase() === 'success'){
          if(environment.repeatPresciptionswebVideo == zoneId){
            localStorage.setItem("videoStatus",'nohide');
          }
          if(environment.repeatPrescriptionsTabsVideo == zoneId){
            localStorage.setItem("videoStatusMobile",'nohide');
          }
          if(environment.dashBoardAdZoneId == zoneId){
            localStorage.setItem("dashBoardAdZoneId",'nohide');
          }
          if(environment.dashboardCenterZoneID == zoneId){
            localStorage.setItem("dashboardCenterZoneID",'nohide');
          }
          if(environment.dashboardtopMobileZoneID == zoneId){
            localStorage.setItem("dashboardtopMobileZoneID",'nohide');
          }
          if(environment.appointmentCenterZoneID == zoneId){
            localStorage.setItem("appointmentCenterZoneID",'nohide');
          }
          if(environment.appointmentMobileZoneID == zoneId){
            localStorage.setItem("appointmentMobileZoneID",'nohide');
          }
          if(environment.labResultsCenterZoneId == zoneId){
            localStorage.setItem("labResultsCenterZoneId",'nohide');
          }
          if(environment.labResultsMobileTopZoneId == zoneId){
            localStorage.setItem("labResultsMobileTopZoneId",'nohide');
          }
          if(environment.dashBoardMobileAdZoneId_p1 == zoneId){
            localStorage.setItem("dashBoardMobileAdZoneId_p1",'nohide');
          }
          if(environment.loginpageBottomZoneId == zoneId){
            localStorage.setItem("loginpageBottomZoneId",'nohide');
          }
          if(environment.repeatPrescriptionsTabsVideo == zoneId){
            localStorage.setItem("videoStatusMobile",'nohide');
          }
          if(environment.appointmentsTabVideoad == zoneId){
            localStorage.setItem("videoStatusappMobile",'nohide');
          }
          if(environment.articlepage == zoneId){
            localStorage.setItem("articlepage",'nohide');
          }
          window['plc' + zoneId] = place++;
          const placementContainer = document.getElementById(
            this.getAdZonesElementIds(zoneId)
          ) as HTMLElement;
      
          if (placementContainer) {
            if (res?.placements?.placement_1) {
              const adDetails = res?.placements?.placement_1;
              if (adDetails.is_redirectable) {
                // placementContainer.innerHTML = `
                //         <div style="width: 100%; max-width: ${adDetails.width}px; height: ${adDetails.height}px">
                //             <a href="${adDetails.redirect_url}" target="_blank">
                //                 <img src="${adDetails.image_url}" />
                //             </a>
                //         </div>
                //         `;
                placementContainer.innerHTML =  this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(adDetails.body) )as any;
                
              } else {
                // placementContainer.innerHTML = `
                // <img src="${adDetails.image_url}" style="max-width:100%;height:auto;" />
                //         `;
                console.log(placementContainer,placementContainer.innerHTML,"krishna")
  
                placementContainer.innerHTML =  this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(adDetails.body) )as any;
              }   
                // max-width: 100vw;
                // height:auto;
                // width:auto;
                // max-height:100vh;
            }
          }
        }     
      })
    );
  }

  getDelayAd() :boolean
  {
    return this.delayAd;
  }

  setDelayAd(setDelayAd: boolean)
  {
    this.delayAd = setDelayAd;
  }

  getAdZonesElementIds(zoneId: number)
  {
    return "placement_"+zoneId;
  }
}
