<div class="content-panel">
    <mat-card>
        <mat-card-content>
            <h4>Contact Us</h4>
           <p>This form should only be used to discuss matters of a technical nature. If you have any questions relating to the use of this website or experience any technical issues, please contact us using the form below.</p>
            <p>If your question or enquiry is related to your health or your health records, please contact your doctor or medical centre directly.</p>
            <p>Our hours of technical support are Monday to Friday from 9:00am - 5:00pm. We are closed on weekends and New Zealand public holidays.</p>
            <div fxLayout="row wrap"  class="m-t-20">
                <div class="fxFlexOffset-responsive" fxFlexOffset="30" fxFlex.gt-sm="40" fxFlex.gt-xs="40" fxFlex="100">
                  <mat-form-field appearance="outline">
                    <mat-label> {{'Name'|translate}}</mat-label>
                    <input matInput placeholder="{{'Name'|translate}}" type="text"                     >
                  </mat-form-field>
                </div>
              </div>
              <div fxLayout="row wrap"  class="m-t-20">
                <div class="fxFlexOffset-responsive" fxFlexOffset="30" fxFlex.gt-sm="40" fxFlex.gt-xs="40" fxFlex="100">
                  <mat-form-field appearance="outline">
                    <mat-label> {{'Email'|translate}}</mat-label>
                    <input matInput placeholder="{{'Email'|translate}}" type="text"                     >
                  </mat-form-field>
                </div>
              </div>
              <div fxLayout="row wrap"  class="m-t-20">
                <div class="fxFlexOffset-responsive" fxFlexOffset="30" fxFlex.gt-sm="40" fxFlex.gt-xs="40" fxFlex="100">
                  <mat-form-field appearance="outline">
                    <mat-label> {{'Number'|translate}}</mat-label>
                    <input matInput placeholder="{{'Number'|translate}}" type="text"                     >
                  </mat-form-field>
                </div>
              </div>
              <div fxLayout="row wrap"  class="m-t-20">
                <div class="fxFlexOffset-responsive" fxFlexOffset="30" fxFlex.gt-sm="40" fxFlex.gt-xs="40" fxFlex="100">
                  <mat-form-field appearance="outline">
                    <mat-label> {{'Subject'|translate}}</mat-label>
                    <input matInput placeholder="{{'Subject'|translate}}" type="text"                     >
                  </mat-form-field>
                </div>
              </div>
              <div fxLayout="row wrap"  class="m-t-20">
                <div class="fxFlexOffset-responsive" fxFlexOffset="30" fxFlex.gt-sm="40" fxFlex.gt-xs="40" fxFlex="100">
                  <mat-form-field appearance="outline">
                    <mat-label> {{'Message'|translate}}</mat-label>
                    <textarea matNativeControl placeholder="{{'Message'|translate}}" rows="5" type="text"></textarea>
                  </mat-form-field>
                </div>
              </div>
              <div fxLayout="row wrap" class="m-t-10 middle">
                <div class="fxFlexOffset-responsive" fxFlexOffset="30" fxFlex.gt-sm="40" fxFlex.gt-xs="40" fxFlex="100">
                  <button mat-button class="btn btn-primary btn-block">{{'SEND EMAIL TO TECHNICAL SUPPORT'|translate}}</button>
                </div>
              </div>
        </mat-card-content>
    </mat-card>
</div>
