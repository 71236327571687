import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'utcDate',
})
export class UtcDatePipe implements PipeTransform {
  transform(date: any, format?: string): any {
    let returnDate: Date = new Date(new Date().getTime() + (new Date().getTimezoneOffset() * 60000));
    if(date) {
      returnDate = new Date(date.getTime() + (date.getTimezoneOffset() * 60000))
    }
    return returnDate;
  }
}
