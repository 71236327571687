<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div #mainContainer class="main-container header-component" [dir]="dir" [ngClass]="{'minisidebar': minisidebar, 'boxed': boxed, 'danger': danger, 'blue': blue, 'green': green, 'horizontal' : horizontal }">

    
    <!-- ============================================================== -->
    <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 0 : 0" [ngClass]="{'minisidebar': minisidebar, 'hideSideBar': tokenService.hideSideBarMenu==false}">
       
        
        <mat-sidenav-content  #pageContent class="page-wrapper"  [ngClass]="status ? 'minitoggle' : ''" >
            <div class="corner-left"></div>
            <div class="corner-right"></div>
            <app-breadcrumb></app-breadcrumb>



            <!-- ============================================================== -->
    <!-- Topbar - style you can find in header.scss -->
    <!-- ============================================================== -->
    <mat-toolbar color="primary" class="topbar relative hide-print" [ngClass]="{'events-none': disablePointerEvents}">
        <div class="w-100 fix-width-for-horizontal">
            <!-- ============================================================== -->
            <!-- Logo - style you can find in header.scss -->
            <!-- ============================================================== -->
            <div class="navbar-header">
                <!-- <a class="navbar-brand" [routerLink]="['dashboards/dashboard']"></a> -->
                <a class="navbar-brand" (click)="navToRoute()" style="cursor: pointer;" (click)="hideinbox()">
                    <!-- Logo icon -->
                    <b>
                        <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
                        <!-- Dark Logo icon -->
                        <img src="assets/images/MMHLogoMark-Icon.svg" alt="Manage My Health" class="dark-logo hide-print">
                        <!-- Light Logo icon --> <!--Logo when width is < 1024-->
                        <img src="assets/images/MMH-mobile-logo.svg" alt="Manage My Health" class="light-logo logo hide-1024 hide-print">
                        <!--Logo when width is > 1023-->
                        <img src="assets/images/MMH-logo.svg" alt="Manage My Health" class="light-logo logo hide-1023 hide-print">
                    </b>
                    <!--End Logo icon -->
                    <!-- Logo text -->

                </a>
            </div>
            <!-- ============================================================== -->
            <!-- sidebar toggle -->
            <!-- ============================================================== -->

            <button mat-icon-button (click)="snav.toggle()" class="sidebar-toggle hamburger hide-print" value="sidebarclosed">
                    <mat-icon>menu</mat-icon>
                </button>
            <!-- ============================================================== -->
            <!-- Search - style you can find in header.scss -->
            <!-- ============================================================== -->
            <!-- <button mat-icon-button class="srh-btn" (click)="showSearch = !showSearch"> -->
                <!-- Commented for Sprint 6 to Hide Search and Health Center -->
            <!-- <div class="header-search" *ngIf="!isPatient"> -->
                <!-- <mat-icon matprefix (click)="gotoSearch()">search</mat-icon> -->
                <!-- <img src="assets/images/searchIconDot.svg" alt="" (click)="gotoSearch()">
                <input type="text" #refSearchVal placeholder="Search..." (keydown.enter)="onSearchClick(refSearchVal.value)" onkeypress="return (event.charCode > 64 && event.charCode < 91) || (event.charCode > 96 && event.charCode < 123)">
                <mat-radio-group aria-label="Select an option" class="search-by" [(ngModel)]="searchOption">
                    <mat-radio-button value="GP"style="margin:7px" >GP</mat-radio-button>
                    <mat-radio-button value="HealthCentre">Health Centre</mat-radio-button>
                </mat-radio-group>
            </div> -->
            <!-- <div class="mobile-view service-provider">
                        <mat-form-field appearance="outline">
                            <input matInput 
                              [matAutocomplete]="auto">
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option *ngFor="let option of options" [value]="option">
                                    {{option}}
                                </mat-option>
                            </mat-autocomplete>
                            <mat-icon>search</mat-icon>
                          </mat-form-field>
                    </div> -->
            <!-- </button> -->
            <form class="app-search" [ngClass]="{'show-search': showSearch}">
                <input type="text" class="form-control" placeholder="Search &amp; enter">
                <a class="cl-srh-btn" (click)="showSearch = !showSearch">
                    <i class="ti-close"></i>
                </a>

            </form>
            <span fxFlex></span>
            <!-- ============================================================== -->
            <!-- app header component - style you can find in header.scss / header.component.ts-->
            <!-- ============================================================== -->
            <div *ngIf="!horizontal else horizontalheader">
                <app-vertical-header class="header-login-section"></app-vertical-header>
            </div>

            <ng-template #horizontalheader>
                <app-horizontal-header></app-horizontal-header>
            </ng-template>

            <!-- ============================================================== -->
            <!-- Right sidebar toggle - style you can find in rightsidebar.component.scss -->
            <!-- ============================================================== -->
            <!-- <button (click)="end.toggle()" mat-icon-button class="ml-xs overflow-visible">
                    <mat-icon>settings</mat-icon>
                </button> -->
        </div>

    </mat-toolbar>
    <!-- ============================================================== -->
    <!-- End Topbar - style you can find in pages.scss -->



     <!-- ============================================================== -->
        <!-- Sidebar - style you can find in sidebar.scss -->
        <!-- ============================================================== -->
        <mat-sidenav #snav id="snav" class="pl-xs hide-print" [mode]="mobileQuery.matches&&tokenService.hideSideBarMenu ? 'side' : !mobileQuery.matches&&tokenService.hideSideBarMenu?'over':''" fixedTopGap="0" [opened]="mobileQuery.matches&&tokenService.hideSideBarMenu"
            [disableClose]="mobileQuery.matches" (open)="sidebarOpened = true" (close)="sidebarOpened = false" class="left-navbar hide-print">

            <perfect-scrollbar *ngIf="!horizontal&&tokenService.hideSideBarMenu" class="scbar" [config]="config">
                <app-vertical-sidebar class="app-sidebar hide-print" [showClass]='status' (notify)="snav?.toggle()">
                </app-vertical-sidebar>
            </perfect-scrollbar>

            <ng-template #horizontalsidebar>
            </ng-template>
        </mat-sidenav>


        <!-- ============================================================== -->
        <!-- Sidebar - style you can find in sidebar.scss -->
        <!-- ============================================================== -->


        <!-- ============================================================== -->
        <!-- Right Sidebar - style you can find in rightsidebar.component.scss -->
        <!-- ============================================================== -->
        
          <!-- ================================================================================================================================================= -->
          <!-- Commented for Bug 4387: [Connect to Health Centre] [MR]  Search Health Centre  Page gets collapsed after searching Health Centre  -->
          <!-- ================================================================================================================================================= -->

        <!-- <mat-sidenav #end position="end" class="chat-panel" mode="over" opened="false">
            <div class="scroll">
                <mat-nav-list>

                    <h3 mat-subheader class="text-uppercase font-weight-bold">Settings</h3>
                    <mat-list-item *ngIf="!minisidebar">
                        <mat-slide-toggle color="warn" [(ngModel)]="horizontal">
                            Horizontal Layout</mat-slide-toggle>
                    </mat-list-item>
                    <mat-list-item>
                        <mat-slide-toggle color="warn" (change)="dir = (dir == 'rtl' ? 'ltr' : 'rtl')">RTL
                        </mat-slide-toggle>
                    </mat-list-item>
                    <mat-list-item>
                        <mat-slide-toggle color="warn" [(ngModel)]="boxed">Boxed Layout</mat-slide-toggle>
                    </mat-list-item>
                    <mat-list-item *ngIf="!horizontal">
                        <mat-slide-toggle [(ngModel)]="minisidebar">Mini Sidebar</mat-slide-toggle>
                    </mat-list-item>
                    <mat-divider></mat-divider>

                    <h3 mat-subheader class="text-uppercase font-weight-bold">Colors</h3>

                    <mat-list-item>
                        <mat-checkbox color="warn" [(ngModel)]="danger" (change)="green = blue = false" class="text-danger">Red
                        </mat-checkbox>
                    </mat-list-item>

                    <mat-list-item>
                        <mat-checkbox color="warn" [(ngModel)]="green" (change)="danger = blue = false" class="text-megna">Teal Green
                        </mat-checkbox>
                    </mat-list-item>

                    <mat-list-item>
                        <mat-checkbox color="warn" [(ngModel)]="blue" (change)="green = danger = false" class="text-info">Blue
                        </mat-checkbox>
                    </mat-list-item>

                    <mat-list-item>
                        <mat-checkbox color="warn" [(ngModel)]="dark" (change)="darkClick()">Dark
                        </mat-checkbox> -->
                        <!-- <mat-checkbox color="warn" [(ngModel)]="dark" (change)="green = blue = danger = false">Dark</mat-checkbox> -->
                    <!-- </mat-list-item>
                </mat-nav-list>
            </div>
        </mat-sidenav> -->

        <!-- ============================================================== -->
        <!-- Page container - style you can find in pages.scss -->
        <!-- ============================================================== -->


            <div class="page-content">
                <router-outlet>
                    <app-spinner></app-spinner>
                </router-outlet>
                
            <!-- <ng-template #other_content> -->
                <div *ngIf="
                mailboxServie.selectedMailBoxType === 'Inbox' ||
                mailboxServie.selectedMailBoxType === 'Sent' ||
                mailboxServie.selectedMailBoxType === 'Drafts' ||
                mailboxServie.selectedMailBoxType === 'Archive' ||
                mailboxServie.selectedMailBoxType === 'Group Messages' ||
                mailboxServie.selectedMailBoxType === 'Detail' ||
                mailboxServie.selectedMailBoxType === 'DraftDetail' ||
                mailboxServie.selectedMailBoxType === 'ArchiveDetail' ||
                mailboxServie.selectedMailBoxType === 'Settings'
            ">
                    <nav mat-tab-nav-bar style="margin-left: 20px; margin-right: 20px;">
                        <a mat-tab-link 
                        class="m-t-20 mat-tab-label"
                           *ngFor="let link of navLinks; let i=index"
                        [routerLink]="link.path"
                        routerLinkActive #rla="routerLinkActive"
                        [routerLinkActiveOptions]="{exact:true}"
                        [active]="rla.isActive">
                        {{link.label}}<span *ngIf="link.count>=0" >({{ mailboxServie.mailstype[i]?.count}})</span>
                        <!-- <span *ngIf="link.count==3" >{{ mailboxServie.mailstype[3]?.count}}</span> -->
                        </a>
                    </nav>
                </div>

                <div>
                    <div class="content-panel p-gt-xs-20" *ngIf="
                    mailboxServie.selectedMailBoxType === 'Compose' ||
                    mailboxServie.selectedMailBoxType === 'Inbox' ||
                    mailboxServie.selectedMailBoxType === 'Sent' ||
                    mailboxServie.selectedMailBoxType === 'Drafts' ||
                    mailboxServie.selectedMailBoxType === 'Archive' ||
                    mailboxServie.selectedMailBoxType === 'Group Messages' ||
                    mailboxServie.selectedMailBoxType === 'Detail' ||
                    mailboxServie.selectedMailBoxType === 'DraftDetail' ||
                    mailboxServie.selectedMailBoxType === 'ArchiveDetail' ||
                    mailboxServie.selectedMailBoxType === 'Settings'
                ">
                
    
                    <div>
              <div class="" *ngIf="mailboxServie.selectedMailBoxType === 'Compose'">
                        <router-outlet name="compose"></router-outlet>
                    </div>
                    <div class="" *ngIf="mailboxServie.selectedMailBoxType === 'Inbox'">
                        <router-outlet name="list"></router-outlet>
                    </div>
                    <div class="" *ngIf="mailboxServie.selectedMailBoxType === 'Detail'">
                        <router-outlet name="detail"></router-outlet>
                    </div>
                    <div class="" *ngIf="mailboxServie.selectedMailBoxType === 'ArchiveDetail'">
                        <router-outlet name="archivedetail"></router-outlet>
                    </div>
                    <div class="" *ngIf="mailboxServie.selectedMailBoxType === 'DraftDetail'">
                        <router-outlet name="draftdetail"></router-outlet>
                    </div>
                    <div class="" *ngIf="mailboxServie.selectedMailBoxType === 'Drafts'">
                        <router-outlet name="drafts"></router-outlet>
                    </div>
                    <div class="" *ngIf="mailboxServie.selectedMailBoxType === 'Sent'">
                        <router-outlet name="sent"></router-outlet>
                    </div>
                    <div class="" *ngIf="mailboxServie.selectedMailBoxType === 'Group Messages'">
                        <router-outlet name="groups"></router-outlet>
                    </div>
                    <div class="" *ngIf="mailboxServie.selectedMailBoxType === 'Archive'">
                        <router-outlet name="archive"></router-outlet>
                    </div>
                    <div class="" *ngIf="mailboxServie.selectedMailBoxType === 'Settings'">
                        <router-outlet name="settings"></router-outlet>
                    </div>
                </div>
            </div>
        </div>
        <app-footer *ngIf="showFooter" class="footer-full main-footer hide-print"></app-footer>
<!-- </ng-template> -->

                <!-- ============================================================== -->
                <!---OPEN: Feedback Form-->
                <!-- ============================================================== -->

                <div class="btn-feedback hide-print" [ngClass]="{'events-none': disablePointerEvents}" [ngStyle]="(service.sharedStyle$ | async)">
                    <a (click)="openFeedBackForm()">feedback</a>
                </div>

                <!-- ============================================================== -->
                <!---END: Feedback Form-->
                <!-- ============================================================== -->
            </div>
        </mat-sidenav-content>
        <!-- ============================================================== -->
        <!-- Page container - style you can find in pages.scss -->
        <!-- ============================================================== -->
    </mat-sidenav-container>


    <!--Kendo Dialog Box-->
    <div class="feedback-dialog" *ngIf="opened==true">
        <kendo-dialog title="Please enter your feedback" *ngIf="opened==true" (close)="opened=false" [minHeight]="250" [minWidth]="250" [width]="450" class="res-sm hide-close-icon"  >
            <p>
                This form should only be used to discuss matters of a technical nature. If you have any questions relating to the use of this website or experience any technical issues, please contact us using the form below.
            </p>
            <p>If your question or enquiry is related to your health or your health records, please contact your doctor or medical centre directly.</p>
            <mat-form-field appearance="outline" class="has-success">
                <mat-label>Select Category</mat-label>
                <mat-select [(ngModel)]="selCategory">
                    <mat-option value="Website feedback">Website feedback</mat-option>
                    <mat-option value="Suggestions">Suggestions</mat-option>
                </mat-select>
                <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
            </mat-form-field>
            <mat-form-field appearance="outline" class="has-success">
                <mat-label>Feedback</mat-label>
                <textarea [(ngModel)]="userValue" matInput></textarea>
            </mat-form-field>
            <mat-form-field appearance="outline" class="has-success">
                <mat-label> {{'Mobile Phone'|translate}}</mat-label>
                <input matInput placeholder="Mobile Phone" [(ngModel)]="mobilePhone" [dropSpecialCharacters]="false" mask="(00)(00) 000-0000" autocomplete="off">
            </mat-form-field>

            <kendo-dialog-actions class="btn-group-right">               
                <button mat-button class="btn-secondary-rect" (click)="opened=false">Close</button>
                <button mat-button class="btn-primary-rect" [disabled]="userValue==''" (click)="submitFeedBackForm()">Send Feedback</button>      
            </kendo-dialog-actions>
        </kendo-dialog>
    </div>

</div>