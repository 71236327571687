import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription, timer } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { URLConstants } from 'src/app/core/constants/urlConstants';
import { EncryptDecrypt } from 'src/app/core/helper/encrypt-decrypt';
import { environment } from 'src/environments/environment';
import { AppService } from './app.service';
import { TokenService } from './token.service';


@Injectable({
    providedIn: 'root'
})
export class TimeOutService {
    private _count = 0;
    private _serviceId: string = 'idleTimeoutSvc-' + Math.floor(Math.random() * 10000);
    public _timeoutMilliseconds = 15000;
    private timerSubscription: Subscription;
    private timer: Observable<number>;
    private _timer: Observable<number>;
    private resetOnTrigger: Boolean = false;
    private lastTime: number;
    private dateTimer: Observable<number>;
    private dateTimerSubscription: Subscription;
    private dateTimerInterval: number = 1000 * 60 * 5;
    private dateTimerTolerance: number = 1000 * 10;
    public timeoutExpired: Subject<number> = new Subject<number>();


    constructor(private http: HttpClient,
        private tokenService: TokenService,
        private appService: AppService) {
        console.log('Constructed idleTimeoutService ' + this._serviceId);


        this.timeoutExpired.subscribe(n => {
            console.log('timeoutExpired subject next.. ' + n.toString());
        });

    }


    private setSubscription() {
        this._timer = timer(this._timeoutMilliseconds);
        this.timerSubscription = this._timer.subscribe(n => {
            // alert('Token = refresh token')
            console.log(n, '-');

            this.refreshToken(n)
            this.timerComplete(n);
        });
    }

    // public refreshToken(n: any) {
    //     let userProfile = this.tokenService.userProfile();
    //     let expiryTime: any;
    //     if (sessionStorage.getItem('expiryTime')) {
    //         expiryTime = sessionStorage.getItem('expiryTime') ? sessionStorage.getItem('expiryTime') : '';
    //     }
    //     this.http.post(environment.apiBaseURL + URLConstants.getRefreshToken,
    //         {
    //             UserSessionId: this.tokenService.encryptedUserId(), //this.tokenService.userId(),
    //             UserEmail: EncryptDecrypt.encryptUsingAES256(userProfile?.Email), //userProfile?.Email,
    //             JwtTokenRefreshTime: expiryTime
    //         }).subscribe((result: any) => {
    //             console.log('Token Refreshed -- Is Updated : ', new Date())
    //             //alert('Toekn was refreshed' + result)
    //             this.stopTimer();
    //             this.timerComplete(n);
    //             sessionStorage.setItem('currentUser', result);
    //             this.appService.setUserLoggedIn(true, expiryTime);
    //             //this.appService.setUserTimedOut(true, expiryTime);
    //         },
    //             (err) => {
    //                 // console.log('Token-error by refreshToken');
    //                 // console.log('Refresh Token--', new Date());
    //                 // this.stopTimer();
    //                 // this.timerComplete(n);
    //                 // this.appService.setUserTimedOut(true, expiryTime);
    //             });
    // }

    public refreshToken(n: any) {
        let userProfile = this.tokenService.userProfile();
        let expiryTime: any;
        if (sessionStorage.getItem('expiryTime')) {
            expiryTime = sessionStorage.getItem('expiryTime') ? sessionStorage.getItem('expiryTime') : '';
        }
        this.http.post(environment.apiBaseURL + URLConstants.getRefreshToken,
            {
                UserSessionId: this.tokenService.encryptedUserId(), //this.tokenService.userId(),
                UserEmail: EncryptDecrypt.encryptUsingAES256(userProfile?.Email), //userProfile?.Email,
                JwtTokenRefreshTime: expiryTime
            }).subscribe((result: any) => {
                console.log('Refresh Token - ', new Date());
                this.stopTimer();
                this.timerComplete(n);
                sessionStorage.setItem('currentUser', result);
                this.appService.setUserTimedOut(true, expiryTime);
            },
            (err) => {
                    console.log('Token-error by refreshToken');

             });
    }

    public refreshTokenForVanageSession(n: any) {
        let userProfile = this.tokenService.userProfile();
        let expiryTime: any = 60

        this.http.post(environment.apiBaseURL + URLConstants.getRefreshToken,
            {
                UserSessionId: this.tokenService.encryptedUserId(), //this.tokenService.userId(),
                UserEmail: EncryptDecrypt.encryptUsingAES256(userProfile?.Email), //userProfile?.Email,
                JwtTokenRefreshTime: expiryTime
            }).subscribe((result: any) => {
                console.log('Token -- Is Updated from Vonage')
                //alert('Toekn was refreshed' + result)
                this.stopTimer();
                this.timerComplete(n);
                sessionStorage.setItem('currentUser', result);
                sessionStorage.setItem('expiryTime', expiryTime);
                //this.appService.setUserLoggedIn(true, expiryTime);
                this.appService.setUserTimedOut(true, expiryTime);
            },
                (err) => {
                    alert('error');
                    console.log('Token-error by refreshToken -- vonage');
                });
    }




    public startTimer() {
        if (this.timerSubscription) {
            this.stopTimer();
        }

        this.setSubscription();
    }


    public stopTimer() {
        if (this.timerSubscription) {
            this.timerSubscription.unsubscribe();
        }
    }


    public resetTimer() {
        this.startTimer();
    }


    public timerComplete(n: number) {
        this.timeoutExpired.next(++this._count);
        if (this.resetOnTrigger) {
            this.startTimer();
        }
    }
}