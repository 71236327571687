import { Component, Inject, OnInit } from '@angular/core';
import { RecallRemainderDialogComponent } from '../recall-remainder-dialog/recall-remainder-dialog.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/data/service/login.service';

@Component({
  selector: 'app-linked-account-remainder',
  templateUrl: './linked-account-remainder.component.html',
  styleUrls: ['./linked-account-remainder.component.scss']
})
export class LinkedAccountRemainderComponent implements OnInit {
  constructor(public router: Router  , public loginService : LoginService ,public dialogRef: MatDialogRef<RecallRemainderDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }
 

  accounts: any[];
  ngOnInit(): void {
    this.accounts = this.data.accounts;
  }
  


  getLinkedPracticeDetails(): string {
    if (this.accounts.length === 0) {
      return ''; 
    } else if (this.accounts.length === 1) {
      return this.accounts[0].practiceName; 
    } else {
      let linkedAccountsPracticeName: string[] = this.accounts.map(account => account.practiceName);
      const lastPracticeName = linkedAccountsPracticeName.pop();
      return linkedAccountsPracticeName.join(', ') + ', and ' + lastPracticeName;
    }
  }
  
  getLinkedPatientDetails(): string {
    if (this.accounts.length === 0) {
      return '';
    } else if (this.accounts.length === 1) {
      return this.accounts[0].patientName;
    } else if (this.accounts.length === 2) {
      return this.accounts.map(account => account.patientName).join(' and ');
    } else {
      let linkedAccountsPatientName: string[] = this.accounts.map(account => account.patientName);
      const lastPatientName = linkedAccountsPatientName.pop();
      return linkedAccountsPatientName.join(', ') + ', and ' + lastPatientName;
    }
  }
  
  
  
  onLinkNow(){
   // this.loginService.getSaveLinkedAccountConfirmation().subscribe((res: any) => {
      this.router.navigate(['/myaccount/linked-secondary-accounts']);
      this.dialogRef.close();
  // },(err: any) => {
  //   console.error(err.error.Message || err.error.message);
  // })
  }
  
  onClose() {
    sessionStorage.removeItem('notlinkedAccountDetails');
    this.dialogRef.close();
  }
}
