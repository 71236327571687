import { ToastType } from "../../data/interface/toast.type";

export class GlobalConstants {
    public static successType: ToastType = "success";
    public static errorType: ToastType = "error";
    public static infoType: ToastType = "info";
    public static warningType: ToastType = "warning";
    public static successIcon: string = "check";
    public static errorIcon: string = "cancel";
    public static infoIcon: string = "info_outline";
    public static warningIcon: string = "warning";
    public static successTitle: string = "Success!";
    public static errorTitle: string = "Error!";
    public static infoTitle: string = "Information";
    public static warningTitle: string = "Warning!";
    public static toasterDelayTime: number = 3000;
    public static userNotRegisteredErrorMessage = 'Enter email id which is registered into the Manage My Health application and click on submit';
}