<div class="py-10 panel">
    <mat-dialog-content>
      <span class="title py-10"> {{ "Linked Account Confirmation" | translate }}</span>
      <div fxLayout="column" class="py-10">
        <div class="py-10 content-class"> {{accounts[0].practiceName}} has linked {{getLinkedPatientDetails()}} to your Manage My Health account. To complete the account linkage, please click the button below.</div>
      </div>
      <mat-dialog-actions fxLayout="row" fxLayoutAlign="end end">
        <button mat-button class="btn-secondary-rect mb-10" (click)="onClose()" > {{ "REMIND ME LATER" | translate }}</button>
        <button mat-flat-button mat-dialog-close class="btn-primary-rect btn-custom mb-10" (click)="onLinkNow()" > {{ "COMPLETE ACCOUNT LINKAGE" | translate }}</button>
      </mat-dialog-actions>
    </mat-dialog-content>
</div>



