import { Injectable } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CommonService } from './common.service';
import { AlertDialogComponent } from 'src/app/shared/component/alert-dialog/alert-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { IAlertDialog } from '../interface/Idialog';
import { AlertType } from 'src/app/core/constants/app-enums';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
@Injectable({
  providedIn: 'root'
})
export class MailBoxService {
  selectedMailBoxType = '';
  messageId = "0";
  groupMessageId=0;
  sentMessageId=0;
  public isShowSearchBox=false;
  public inboxMsgCount:number;
  public index = -1;
  public showHealthCenter:boolean = true;
  public gridView: any = [];
  public userId: any;
  public sentData: any = [];
  public archiveData: any = [];
  public groupMessages: any = [];
  public loggedUserName: string;
  public draftMessageCount: number;
  public groupCount: number;
  customChangeDetector = new Subject<string>();
  customChangeDetector$ = this.customChangeDetector.asObservable();
  private subject = new Subject<any>();
  private subjectID = new BehaviorSubject<any>('');
  public mailstype:any=[];
  public drawerBs = new BehaviorSubject<boolean>(false);
  // public mailBoxDrawer=new Subject<MatDrawer>();
  // mailBoxDrawer$=this.mailBoxDrawer.asObservable();
  mailBoxDrawer:MatDrawer;
  draftsDataDetails: string;
  //sharing MessagID by Service
  // inboxMessageIdChange = new BehaviorSubject<any>('');
  // inboxMessageIdValue = this.inboxMessageIdChange.asObservable();
  alertDialog: IAlertDialog;
  
  sendMessage(message: string) {
      this.subject.next({ text: message });
  }
  onMessage(): Observable<any> {
      return this.subject.asObservable();
  }
  constructor(private commonService: CommonService, private translate: TranslateService
    ,private dialog: MatDialog) { 
    this.mailstype = [
      { value: 'Inbox', count: this.inboxMsgCount},
      { value: 'Drafts', count: this.draftMessageCount },
      { value: 'Sent', count: '' },
      { value: 'Archive', count: '' },
      { value: 'Group Messages', count: this.groupCount }
      
    ];
  }

  // drawerOpen(val : boolean){
  //   this.drawerBs.next(val);
  // }

  openMailBox(mailBoxType:string) {
    this.selectedMailBoxType = mailBoxType;
    // this.mailBoxDrawer.open();
  }
  setDrawer(drawer: MatDrawer) {
    this.mailBoxDrawer = drawer;
  }
//   inboxMessageId(messageId:any) {
//     this.inboxMessageIdChange.next(messageId);
// }

  hideMailBox() {
    if (this.selectedMailBoxType.includes("Compose") ||
      this.selectedMailBoxType.includes("Inbox") ||
      this.selectedMailBoxType.includes("Drafts") ||
      this.selectedMailBoxType.includes("Sent") ||
      this.selectedMailBoxType.includes("Archive") ||
      this.selectedMailBoxType.includes("Groups") ||
      this.selectedMailBoxType.includes("Detail") ||
      this.selectedMailBoxType.includes("DraftDetail") ||
      this.selectedMailBoxType.includes("Group Messages") ||
      this.selectedMailBoxType.includes("Settings")) {
      this.selectedMailBoxType = '';
    }
  }

  passMessageID(message: string) {
    this.subjectID.next(message);
  }
  
  getMessageID() {
    return this.subjectID.getValue();
  }

  getParamsData() {
    let decryptId = null;
    try {
      let encryptId = this.getMessageID();
      decryptId = this.commonService.getDecryptedValue(encryptId);
    } catch (error) {    }
    return decryptId;
  }
  
  showDialogWhenNoStaffAvailable(select:MatSelect){
    this.alertDialog = {
      title: 'Information',
      message: this.translate.instant('Currently, no staff is available at this location to receive messages. Please select another location to send your message.'),
      alertType: AlertType.None,
      okButtonText: this.translate.instant('OK')
    };
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: this.alertDialog
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      setTimeout(() => {      
        select.focus();
      }, 1000);
      if(result) {
        this.dialog.closeAll();
      }
    });
  }
}
