<!-- ============================================================== -->
<!-- sidebar -->
<!-- ============================================================== -->

<mat-nav-list class="side-nav-bar">
    <mat-list-item >
        <a class="active"  [routerLink]="[ ]" >
            <!-- <mat-icon>home</mat-icon> -->
            <img src="../../../../assets/images/home.png">
            <!-- <i class="icon-vector"></i> -->
            <span>Home</span>
            <span fxFlex></span>
        </a> 
    </mat-list-item>
    <mat-list-item>
        <a class="" [routerLink]="['/pages/appointment']">
            <!-- <mat-icon>home</mat-icon> -->
            <img src="../../../../assets/images/appointments.png">
            <span>My Appointments</span>
            <span fxFlex></span>
        </a> 
    </mat-list-item>
    <mat-list-item  >
        <a class=""  [routerLink]="[ ]">
            <!-- <mat-icon>home</mat-icon> -->
            <img src="../../../../assets/images/prescription.png">
            <span>My Prescriptions</span>
            <span fxFlex></span>
        </a> 
    </mat-list-item>
    <mat-list-item  >
        <a class=""  [routerLink]="[ ]">
            <img src="../../../../assets/images/health-centre.png">
            <span>My Health Centres</span>
            <span fxFlex></span>
        </a> 
    </mat-list-item>
    <mat-list-item  >
        <a class=""  [routerLink]="[{ outlets: { list: ['compose'] } }]">
            <!-- <mat-icon>message</mat-icon> -->
            <img src="../../../../assets/images/message.png">
            <span>My Messages</span>
            <span fxFlex></span>
        </a> 
    </mat-list-item>
    <mat-list-item  >
        <a class=""  [routerLink]="[ ]">
            <!-- <mat-icon>home</mat-icon> -->
            <img src="../../../../assets/images/health-record.png">
            <span>My Health Records</span>
            <span fxFlex></span>
        </a> 
    </mat-list-item>
</mat-nav-list>