<div class="filter-by-index page-header header-buttons m-b-sm-0">
    <h1>{{'Message Settings'| translate}}</h1>
</div>

<mat-card class="m-0 p-0 radius-10 h-overflow">
    <div class="p-24 p-lt-xs-20">
        <form [formGroup]="settingForm">
            <div class="setting">
                <div class="settings-mat-expansion">
                    <mat-accordion #accordion>
                        <!-- Commented For UserStory #7223 -->



                        <!-- <mat-expansion-panel [expanded]="sessionPanelOpenState" (opened)="sessionPanelOpenState = true"
                                      (closed)="sessionPanelOpenState = false">
                                      <mat-expansion-panel-header class="panel-head">
                                        <mat-panel-title>
                                          {{ "Session Settings" | translate }}
                                        </mat-panel-title>
                                        <mat-panel-description>
                                          -
                                          {{
                                            "Click here to Customise Session Time out Settings"
                                              | translate
                                          }}
                                        </mat-panel-description>
                                      </mat-expansion-panel-header>
                      
                                      <div class="grid-fr-fr">
                                        <div>{{ "Keep me Logged In for" | translate }}</div>
                                        <div>
                                          <mat-radio-group formControlName="sessionSettings" aria-label="Select an option" class="option"> -->
                        <!-- <mat-radio-button value="2">{{"2 Mins"}}</mat-radio-button>
                                                  <mat-radio-button value="3">{{"3 Mins"}}</mat-radio-button>
                                                  <mat-radio-button value="4">{{"4 Mins"}}</mat-radio-button> -->

                        <!--                                  <mat-radio-button value="6">{{"6 Mins"}}</mat-radio-button> -->
                        <!-- <mat-radio-button value="20">{{
                                              "20 Mins (Recommended setting)" | translate
                                            }}</mat-radio-button>
                                            <mat-radio-button value="60">{{
                                              "1 Hour" | translate
                                            }}</mat-radio-button>
                                            <mat-radio-button value="120">{{
                                              "2 Hour" | translate
                                            }}</mat-radio-button>
                                            <mat-radio-button value="240">{{
                                              "4 Hour" | translate
                                            }}</mat-radio-button>
                                            <mat-radio-button value="480">{{
                                              "8 Hours" | translate
                                            }}</mat-radio-button>
                                          </mat-radio-group>
                                        </div>
                                      </div>
                      
                                      <div class="grid-fr-fr" *ngIf="hideBroswerSettings">
                                        <div>
                                          {{ "Delete my session when I close browser" | translate }}
                                        </div>
                                        <div>
                                          <mat-radio-group formControlName="browserSettings" aria-label="Select an option" class="option">
                                            <mat-radio-button value="1">{{
                                              "Yes" | translate
                                            }}</mat-radio-button>
                                            <mat-radio-button value="2">{{
                                              "No" | translate
                                            }}</mat-radio-button>
                                          </mat-radio-group>
                                        </div>
                                      </div>
                                    </mat-expansion-panel> -->
                        <!-- </mat-accordion> -->
                        <!-- End of setting session -->

                        <!-- <mat-accordion> -->

                        <mat-expansion-panel [expanded]="emailPanelOpenState" (opened)="emailPanelOpenState = true"
                            (closed)="emailPanelOpenState = false">
                            <mat-expansion-panel-header class="panel-head">
                                <mat-panel-title>
                                    {{ "AlertSettings" | translate }}
                                </mat-panel-title>
                                <mat-panel-description>
                                    -
                                    {{
                                    "Click here to send alerts when new E-mails are received"
                                    | translate
                                    }}
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <!-- <mat-grid-list cols="2" rowHeight="80">
                                          <mat-grid-tile>
                                              {{"When I receive new messages send alerts to"|translate}}  {{ lblUserMailId }}
                                          </mat-grid-tile>
                                          <mat-grid-tile>
                                              <mat-radio-group formControlName="alertSetting" aria-label="Select an option" class="option">
                                                  <mat-radio-button value="1">{{"Yes (Recommended)"|translate}}</mat-radio-button>
                                                  <mat-radio-button value="2">{{"No"|translate}}</mat-radio-button>
                      
                                              </mat-radio-group>
                                          </mat-grid-tile>
                      
                                      </mat-grid-list> -->
                            <div class="grid-fr-fr alert-settings-align">
                                <div class="alert-text-align">
                                    {{
                                    "When I receive new messages send alerts to" | translate
                                    }}
                                    {{ userMailId }}
                                </div>

                                <div>
                                    <mat-radio-group formControlName="alertSetting" aria-label="Select an option"
                                        class="option">
                                        <mat-radio-button value="1">{{
                                            "Yes (Recommended)" | translate
                                            }}</mat-radio-button>
                                        <mat-radio-button value="2">{{
                                            "No" | translate
                                            }}</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>
                        </mat-expansion-panel>
                        <!-- </mat-accordion> -->
                        <!-- Alert Setting  -->

                        <!-- <mat-accordion> -->

                        <mat-expansion-panel [expanded]="signaturePanelOpenState"
                            (opened)="signaturePanelOpenState = true" (closed)="signaturePanelOpenState = false">
                            <mat-expansion-panel-header class="panel-head">
                                <mat-panel-title>
                                    {{ "SignatureSettings" | translate }}
                                </mat-panel-title>
                                <mat-panel-description>
                                    -
                                    {{
                                    "Click here to update the signature on your outgoing E-mails"
                                    | translate
                                    }}
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div fxFlex.gt-sm="100" fxFlex="100">
                                <!-- <mat-form-field appearance="outline">
                                <mat-label>Textarea</mat-label>
                                <textarea formControlName="signatureSetting" matInput placeholder=""></textarea>
                              </mat-form-field> -->

                                <kendo-editor #kendoEditor formControlName="signatureSetting" style="height: 140px" [iframe]="false" (paste)="handlePaste($event)"></kendo-editor>
                            </div>
                        </mat-expansion-panel>
                        <!-- </mat-accordion>
                        <mat-accordion> -->

                        <!-- End  Signature Settings  -->

                        <mat-expansion-panel [expanded]="outOfOfficePanelOpenState"
                            (opened)="outOfOfficePanelOpenState = true" (closed)="outOfOfficePanelOpenState = false">
                            <mat-expansion-panel-header class="panel-head">
                                <mat-panel-title>
                                    {{ "Out of Office Settings" | translate }}
                                </mat-panel-title>
                                <mat-panel-description>
                                    -
                                    {{
                                    "Click here to enable and add a message for your out of office E-mails"
                                    | translate
                                    }}
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div fxFlex.gt-sm="100" fxFlex="100">
                                <mat-checkbox formControlName="enableOutOfOfficeReply">Enable Out Of Office
                                    Reply</mat-checkbox>
                                <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="center none"
                                    fxLayoutGap="20px" fxLayoutGap.lt-sm="10px" class="m-t-20 m-b-10 date"
                                    *ngIf="settingForm.value.enableOutOfOfficeReply === true">
                                    <div fxFlex="100">
                                        <!-- <mat-label>Start date </mat-label> -->
                                        <!-- <mat-form-field appearance="outline" [ngClass]="{
                                                      'has-success': settingForm.controls['outOfOfficeFromDate'].valid}">
                                                      <mat-label>Start date</mat-label>
                                                      <input matInput [matDatepicker]="picker3" placeholder="dd-mm-yyyy" formControlName="outOfOfficeFromDate">
                                                      <mat-datepicker-toggle matSuffix [for]="picker3">
                                                          <mat-icon matDatepickerToggleIcon matSuffix class="m-r-10 m-t-5 dob-icon">
                                                              
                                                              event</mat-icon>
                                                      </mat-datepicker-toggle>
                      
                                                      <mat-datepicker #picker3></mat-datepicker>
                                                    
                                                  </mat-form-field> 
                                              (close)="$event.preventDefault()"
                                              -->
                                        <div class="datetime-fixed-axis">
                                            <kendo-datetimepicker [format]="'dd/MM/yyyy HH:mm:ss'"
                                                [readOnlyInput]="true" [(ngModel)]="outOfOfficeFromDate"
                                                [popupSettings]="{
                                                  appendTo: containerRef,
                                                  popupClass: 'testxxxx'
                                                }" [ngModelOptions]="{ standalone: true }" class="k-date-picker-cst">
                                            </kendo-datetimepicker>
                                            <div #container></div>
                                        </div>
                                    </div>

                                    <!-- <div fxFlex.gt-sm="6" fxFlex.gt-xs="47" fxFlex="0">
                      
                                              </div> -->
                                    <div fxFlex="100">
                                        <!-- <mat-form-field appearance="outline" [ngClass]="{
                                                      'has-success': settingForm.controls['outOfOfficeToDate'].valid}">
                                                      <mat-label>End date </mat-label>
                                                      <input matInput [matDatepicker]="picker4" [min]="settingForm.value.outOfOfficeFromDate" placeholder="dd-mm-yyyy" formControlName="outOfOfficeToDate">
                                                      <mat-datepicker-toggle matSuffix [for]="picker4">
                                                          <mat-icon matDatepickerToggleIcon matSuffix class="m-r-10 m-t-5 dob-icon">
                                                              event</mat-icon>
                                                      </mat-datepicker-toggle>
                                                      <mat-datepicker #picker4></mat-datepicker>
                                                  </mat-form-field> -->
                                        <div class="datetime-fixed-axis">
                                            <kendo-datetimepicker [format]="'dd/MM/yyyy HH:mm:ss'"
                                                [readOnlyInput]="true" [min]="outOfOfficeFromDate"
                                                [(ngModel)]="outOfOfficeToDate"
                                                [popupSettings]="{ appendTo: containerRef2 }"
                                                [ngModelOptions]="{ standalone: true }" class="k-date-picker-cst">
                                            </kendo-datetimepicker>
                                            <div #container2></div>
                                        </div>
                                    </div>
                                </div>

                                <!-- <mat-form-field appearance="outline">
                                <mat-label>Textarea</mat-label>
                                
                                <textarea formControlName="outOfOfficeText" matInput placeholder=""></textarea>
                              </mat-form-field> -->

                                <kendo-editor formControlName="outOfOfficeText" style="height: 400px"
                                    *ngIf="settingForm.value.enableOutOfOfficeReply === true">
                                </kendo-editor>
                            </div>
                        </mat-expansion-panel>
                        <!-- </mat-accordion> -->
                        <!-- End of office setting -->

                        <!-- <mat-accordion> -->
                        <mat-expansion-panel [expanded]="autoReplyPanelOpenState"
                            (opened)="autoReplyPanelOpenState = true" (closed)="autoReplyPanelOpenState = false">
                            <mat-expansion-panel-header class="panel-head">
                                <mat-panel-title>
                                    {{ "Automatic replies" | translate }}
                                </mat-panel-title>
                                <mat-panel-description>
                                    -
                                    {{
                                    "Click here to enable and add a message for Automatic E-mail replies"
                                    | translate
                                    }}
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div fxFlex.gt-sm="100" fxFlex="100">
                                <mat-checkbox formControlName="enableAutomaticReplies">Enable Auto
                                    reply</mat-checkbox>

                                <!--    
                              <mat-form-field appearance="outline" >
                                <mat-label>Textarea</mat-label>
                                <textarea formControlName="automaticReplyText" matInput placeholder=""></textarea>
                              </mat-form-field> -->

                                <kendo-editor formControlName="automaticReplyText" style="height: 140px"
                                    *ngIf="settingForm.value.enableAutomaticReplies === true"></kendo-editor>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>

            <div class="tab-actions tabs-inside m-t-20">
                <div class="grid-btns-two">
                    <button (click)="submitSettings()" mat-button class="btn-primary-pill">
                        {{ "Save" | translate }}
                    </button>
                    <button [routerLink]="['/dashboards/dashboard']" mat-button class="btn-secondary-pill-gray">
                        {{ "Cancel" | translate }}
                    </button>
                </div>
            </div>
        </form>
    </div>
</mat-card>