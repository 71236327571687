import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit
} from '@angular/core';
import { MenuItems } from '../../shared/data/interface/menu-items/menu-items';


import { PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { URLConstants } from 'src/app/core/constants/urlConstants';
import { applicationHttpClientCreator } from 'src/app/data/interface/IRequestOptions';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'src/app/data/service/toaster.service';

/** @title Responsive sidenav */
@Component({
	selector: 'app-empty-layout',
	templateUrl: 'empty.component.html',
	styleUrls: []
})
export class EmptyComponent implements OnDestroy {
	mobileQuery: MediaQueryList;

	dir = 'ltr';
	dark = false;
	minisidebar = false;
	boxed = false;
	horizontal = false;

	green = false;
	blue = false;
	danger = false;
	showHide = false;
	url = '';
	sidebarOpened = false;
	status = false;

	public showSearch = false;
	searchOption:any="GP";
	public config: PerfectScrollbarConfigInterface = {};
	// tslint:disable-next-line - Disables all
	private _mobileQueryListener: () => void;
	userValue: string;
	selCategory: string = "Website feedback";
	profileName: any;
	userId: string;

	constructor(
		public router: Router,
		changeDetectorRef: ChangeDetectorRef,
		media: MediaMatcher,
		public menuItems: MenuItems,
		private http: HttpClient,
		private translate: TranslateService,
		private toaster: ToasterService,
	) {
		this.mobileQuery = media.matchMedia('(min-width: 1023px)');
		this._mobileQueryListener = () => changeDetectorRef.detectChanges();
		// tslint:disable-next-line: deprecation
		this.mobileQuery.addListener(this._mobileQueryListener);
		// this is for dark theme
		// const body = document.getElementsByTagName('body')[0];
		// body.classList.toggle('dark');
		this.dark = false;
	}

	ngOnDestroy(): void {
		// tslint:disable-next-line: deprecation
		this.mobileQuery.removeListener(this._mobileQueryListener);
	}


	ngOnInit() {
		//const body = document.getElementsByTagName('body')[0];
		// body.classList.add('dark');
	}

	clickEvent(): void {
		this.status = !this.status;
	}
	onSearchClick(val:any){
		console.log("&&&&&&&&&&",val,this.searchOption);
		if(this.searchOption=='GP'){
			window.location.href = window.location.origin + "/pages/search-gp-hc" + '?s=B&x=G&wd=' + val + '&sm=providersearch';

		}else if(this.searchOption=='HealthCentre'){
			window.location.href = window.location.origin + "/pages/search-gp-hc" + '?s=B&x=P&wd=' + val;
		}
	}

	darkClick() {
		// const body = document.getElementsByTagName('body')[0];
		// this.dark = this.dark;
		const body = document.getElementsByTagName('body')[0];
		body?.classList.toggle('dark');
		// if (this.dark)
		// else
		// 	body.classList.remove('dark');
		// this.dark = this.dark;
		// body.classList.toggle('dark');
		// this.dark = this.dark;
	}
	options = ['GP', 'Health Centre']

	//feedback Kendo dialog
	public opened = false;

	public closeFeedback() {    
	this.opened = false;
	}

	public open() {
	this.opened = true;
	}
	openFeedBackForm() {
		this.userValue = "";
		this.selCategory = "Website feedback";
		this.opened = true;
	}
	submitFeedBackForm() {
		this.userValue = this.userValue.replace("/'/g", "");
		this.userValue = this.userValue.replace("/>/g", "");
		this.userValue = this.userValue.replace("/</g", "");
		this.userValue = this.userValue.replace('/"/g', '');
		this.userValue = this.userValue.replace('/:/g', '');
		let xmlData = "<?xml version=\'1.0\' encoding=\'UTF-8\'?><FEEDBACK><CATEGORY>" + this.selCategory + "</CATEGORY><TEXT><![CDATA[" + this.userValue + "]]></TEXT></FEEDBACK>";
		applicationHttpClientCreator(this.http).Post(`${URLConstants.saveUserSiteFeedback}`, {
			Feedback: xmlData,
			URL: window.location.href,
			UserID: this.userId,
			Email: this.profileName?.Email

		}).subscribe((data: any) => {
			this.toaster.success(this.translate.instant("FeedBack Sent Successfully"));
			this.opened = false;
		})
	}

}
