import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[maxLength]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: MaxLengthDirective,
      multi: true
    }
  ]
})
export class MaxLengthDirective implements Validator {
  @Input('maxLength') maxLength: any =0;

  validate(control: AbstractControl): { [key: string]: any } | null {
    const value = control.value;
    if (value && value.length > +this.maxLength) {
      return { 'maxLength': true };
    }
    return null;
  }
}
