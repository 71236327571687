import { DatePipe } from '@angular/common';
import { AnyAaaaRecord } from 'dns';
import * as moment from 'moment';;
import { IConsultationType } from "src/app/data/interface/IAppointmentDateSlot";
export class CommonConstants {
  public static defaultIdleTime = 5*60;
  public static defaultWatchInterval = CommonConstants.defaultIdleTime / 2;
  public static encryptionKey = 'blJP16Ep5HyliadG';
  public static toasterDelayTime = 7000;
  public static sessionDefaultTime = 20;
  public static appointmentMsgForSelf = 'Book New Appointment for You';
  public static appointmentMsgForAll = 'Book New Appointment';
  public static pageDefaultSize = 10;
  public static appointmentReasons = [
    { id: 1, name: 'A new issue', checked: false },
    { id: 2, name: 'An existing issue', checked: false },
    { id: 3, name: 'A prescription', checked: false },
    { id: 4, name: 'A medical certificate', checked: false },
    { id: 5, name: 'A WINZ form', checked: false },
    // { id: 6, name: 'Please specify the reason', checked: false }
  ];
  // public static phoneNumberPattern: RegExp =    /^((\(\+[0-9]{1,3}\)|\(00[0-9]{1,3}\)|\+[0-9]{1,3}|00[0-9]{1,3}){0,1}0{0,1}\d{7,10})$/;
  // public static landlineNumberPattern: RegExp = /^((\(\+[0-9]{1,3}\)|\(00[0-9]{1,3}\)|\+[0-9]{1,3}|00[0-9]{1,3}){0,1}0{0,1}\d{7,10})$/;
  public static phoneNumberPattern: RegExp = /^614\d{8}$/;  //   /^614\d{7}$/;
  public static landlineNumberPattern: RegExp =/^(61\d{8}|1800\d{6})$/;
  public static emailPattern: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;


  //Got this validation in net need to conform is it correct

  public static healthDocumentsFilters = [
    {
      id: 1,
      value: "All",
      text: "All"
    },
    {
      id: 1,
      value: "PATIENT",
      text: "My Documents"
    },
    {
      id: 1,
      value: "PRACTICE",
      text: "Health Facility Documents"
    },
    {
      id: 1,
      value: "INACTIVATE",
      text: "Inactive Documents"
    },

  ];






  public static appointmentConsultTypes: IConsultationType[] = [
   // {
    //   iconClass: 'icon-cent-cards-outline-badges',
    //   format: 'ALL',
    //   code: 'ALL',
       //createdby:'MMH'
     //},
    {
    iconClass: 'icon-visit',
    format: 'Visit',
    code: 'ALL',
    createdby:'MMH',
  },
  {
    iconClass: 'icon-video',
    format: 'Video',
    code: 'MMHVC',
    createdby:'MMHT',
  }
  ];
  public static consultTypeEasybooking: IConsultationType[] = [{
    iconClass: 'icon-visit',
    format: 'Visit',
    code: 'none',
  },
  {
    iconClass: 'icon-video',
    format: 'Video call',
    code: 'MMHVC',
  },
  {
    iconClass: 'icon-phone',
    format: 'Phone',
    code: 'MMHPA',
  }
  ];
  public static appointmentDateFormat = 'yyyy-MM-DD';
  public static appointmentDateTimeFormat = 'yyyy-MM-DD HH:mm:SS';
  public static appointmentForSelf = 'Myself';
  public static appointmentForOthers = 'Others';
  public static currencyType = '$';
  public static cancel = 'Cancel';
  public static close = 'Close';
  public static success = 'success';
  public static Success_Provider = 'Success';

  public static appointmentTurnOff = 'To turn-off online appointments, click Edit and tick the checkbox and Save';
  public static appointmentTurnOn = 'To turn-off online appointments, click Edit and tick the checkbox and Save';
  public static healthDocumentList = [
    { name: "Health Facility Documents", value: 'PRACTICE' },
    { name: "My Documents", value: 'PATIENT' },
    { name: "Inactive Documents", value: "INACTIVATE" }
  ]
  public static documentTypes: any = [
    {
      value: "1", text: "Advance Care Plan (ACP)"
    },
    {
      value: "6", text: "Clinical Investigations"
    },

    {
      value: "12", text: "Consent Form"
    },

    {
      value: "7", text: "Discharge Letter"
    },
    {
      value: "4", text: "Enduing Power of Attorney (EPOA)"
    },
    {
      value: "9", text: "Imaging"
    },
    {
      value: "5", text: "Medical Notes"
    },

    {
      value: "2", text: "Patient Health Assessment"
    },

    {
      value: "3", text: "Patient Care Plan"
    },

    {
      value: "8", text: "Photo"
    },
    {
      value: "11", text: "Referral"
    },
    {
      value: "10", text: "Special Authority"
    },
    {
      value: "13", text: "Other"
    }
  ];

  public static gender_Male = 'M';
  public static gender_FeMale = 'F';
  public static gender_UnKnown = 'U';

  /* Vidyo */
public static vidyo_host_name = 'prod.vidyo.io';
public static vidyo_invitation_route_url = '/appointment/future/video-invitation';
public static vidyo_consultation_route_url_provider = '/appointment/appointment-provider/video-consultation';
public static vidyo_chat_url = '/video/video-chat';

public static patientLogin = '/authentication/login';
public static patientFutureAppointmentRoute = '/appointment/future';
public static patientVideoInvitationRoute = '/appointment/future/video-invitation';
public static nonRegisterPatientRoute = '/meeting/join-meeting';
public static dashboardPatient = '/dashboards/dashboard';



 //vonage api url start
 public static vonageApiUrl = '/call/';
 //vonage api url end
  /* end */

  /* Usage Query*/
  public static defaultRoleId = '00000000-0000-0000-0000-000000000000';
  public static defaultUserId = '00000000-0000-0000-0000-000000000000';
  public static defaultFromDate = new Date('1973-01-01');
  public static defaultToDate = new Date('9999-12-01');
  public static sehrAuditSearchDefaultDate = new Date('1754-01-01');
  public static defaultAnyItem = 'Any';
  public static defaultAllLocationItem = 'All Locations';
  public static defaultDateDbDateFormat = 'YYYY-MM-DD HH:mm:ss';
  public static usageQueryImportExcelName = 'ServiceReport';
  public static usageQueryStatusArray = [
    { name: 'All', value: '0' },
    { name: 'Open', value: 'o' },
    { name: 'Closed and Billed', value: 'B' },
    { name: 'Closed Without Billing', value: 'C' },
    { name: 'Need More Info', value: 'I' }
  ];
  /* End Usage Query*/

 /* PaymentReport*/
 public static defaultAnyStaffItem ='Any';
/* End PaymentReport*/
/*View journal */
  public static fitnessCategories = [
    { id: 0, name: 'General' },
    { id: 1, name: 'Appointments' },
    { id: 4, name: 'Diet' },
    { id: 6, name: 'Medical' },
    { id: 7, name: 'Personal' },
    { id: 8, name: 'Fitness' }
  ]

  public static remindHours =[
    {id: '0', text: "Don't remind me"},
    {id: '1', text: '1 Hour'},
    {id: '2', text: '2 Hours'},
    {id: '3', text: '3 Hours'},
    {id: '6', text: '6 Hours'},
    {id: '12', text: '12 Hours'},
    {id: '24', text: '1 day'},
    {id: '48', text: '2 days'},
    {id: '72', text: '3 days'}
  ]
  public static arrTimeData: any = [
    { id: 24, value: "12:00 am" }, { id: 1, value: "1:00 am" }, { id: 2, value: "2:00 am" },
    { id: 3, value: "3:00 am" }, { id: 4, value: "4:00 am" }, { id: 5, value: "5:00 am" },
    { id: 6, value: "6:00 am" }, { id: 7, value: "7:00 am" }, { id: 8, value: "8:00 am" },
    { id: 9, value: "9:00 am" }, { id: 10, value: "10:00 am" }, { id: 11, value: "11:00 am" },
    { id: 12, value: "12:00 pm" }, { id: 13, value: "1:00 pm" }, { id: 14, value: "2:00 pm" },
    { id: 15, value: "3:00 pm" }, { id: 16, value: "4:00 pm" }, { id: 17, value: "5:00 pm" },
    { id: 18, value: "6:00 pm" }, { id: 19, value: "7:00 pm" }, { id: 20, value: "8:00 pm" },
    { id: 21, value: "9:00 pm" }, { id: 22, value: "10:00 pm" }, { id: 23, value: "11:00 pm" }];

  // public static arrPhoneCodes: any = [{ id: 1, phoneCode: '020' }, { id: 2, phoneCode: '021' }, { id: 3, phoneCode: '022' },
  // { id: 4, phoneCode: '027' }, { id: 5, phoneCode: '028' }, { id: 6, phoneCode: '029' }, { id: 6, phoneCode: '---' }, { id: 7, phoneCode: '09' },
  //   , { id: 8, phoneCode: '07' }, { id: 9, phoneCode: '06' }, { id: 10, phoneCode: '04' }, { id: 11, phoneCode: '03' }]

    public static arrPhoneCodes=  ['020', '021', '022', '027', '028', '029','--', '09', '07', '06','04','03' ]
    /* */
  public static getTimeArrayWithIntervals = () => {
    const locale = 'en'; // or whatever you want...
    const hours = [];

    moment.locale(locale);  // optional - can remove if you are only dealing with one locale

    for (let hour = 7; hour < 21; hour++) {
      hours.push(
        {
          id: (moment({ hour }).format('h:mm A')),
          value: (moment({ hour }).format('h:mm A'))
        });
      hours.push(
        {
          id: (moment({ hour, minute: 15 }).format('h:mm A')),
          value: (moment({ hour, minute: 15 }).format('h:mm A'))
        });
      hours.push(
        {
          id: (moment({ hour, minute: 30 }).format('h:mm A')),
          value: (moment({ hour, minute: 30 }).format('h:mm A'))
        });
      hours.push(
        {
          id: (moment({ hour, minute: 45 }).format('h:mm A')),
          value: (moment({ hour, minute: 45 }).format('h:mm A'))
        });
    }
    return hours;
  }

  public static getCompareDatesWithTime = (startDate: Date, endDate: Date, startTime: string, endTime: string) =>
  {
    if (startTime !== '' && endTime !== '')
    {
      const dtFrom = new Date(startDate.getFullYear() + '-' + startDate.getMonth() + '-' + startDate.getDate() + ' '  + startTime);
      const dtEnd = new Date(endDate.getFullYear() + '-' + endDate.getMonth() + '-' + endDate.getDate() + ' '  + endTime);
      if (dtFrom > dtEnd)
      {
        return true;
      }
      else{
        return false;
      }
    }
    return false;
  }


  public static convertMinutesToMilliSeconds(minutes:number)
  {
      return minutes*60*1000;
  }

  public static MY_DATE_FORMATS = {
    parse: {
      dateInput: 'DD/MM/YYYY',
    },
    display: {
      dateInput: 'DD/MM/YYYY',
      monthYearLabel: 'MMMM YYYY',
      dateA11yLabel: 'DD/MM/YYYY',
      monthYearA11yLabel: 'MMMM YYYY',
    }
  };

  /* Covid Immunisation */
  public static covidVaccines: any = [
    { id: '1', name: 'Comirnaty, COVID-19 mRNA (Pfizer-BioNTech)' },
    { id: '2', name: 'Astra Zeneca' },
    { id: '3', name: 'Jannsen' },
  ]

  public static getNZCurrentDateAndTime(withTime: boolean = true): Date {
    let nzDateStr = new DatePipe('en-Us').transform(new Date(), 'dd/MM/yyyy HH:mm:ss', 'GMT+13');
    let nzAry1 = nzDateStr?.split(' ') || [];
    let newzDt = nzAry1[0]?.split('/');
    let newzTime = nzAry1[1]?.split(':');
    if (withTime) {
      return new Date(parseInt(newzDt[2]), parseInt(newzDt[1]) - 1, parseInt(newzDt[0]),
        parseInt(newzTime[0]), parseInt(newzTime[1]), parseInt(newzTime[2]));
    }
    else {
      return new Date(parseInt(newzDt[2]), parseInt(newzDt[1]) - 1, parseInt(newzDt[0]));
    }
  }

  public static getNZCalendarMonthFromToDates(): [FromDate: Date, ToDate: Date] {
    let toDate = this.getNZCurrentDateAndTime(false);
    let month = toDate.getMonth() - 1;
    let fromDate = new Date(toDate.getFullYear(), month, toDate.getDate());
    return [fromDate, toDate];
  }
}

export class HealthIndicatorPatterns {
  public static readonly Alcohol: RegExp = /^(?!0+$)(?:\d{1,3}|1000)$/;
  public static readonly BloodSugar : RegExp = /^(?:\d{1,3}(?:\.\d{2})?|\d{1,3}(?:\.\d{1})?|\d{1,3})$/;
  public static readonly AnxietySeverity : RegExp= /^\d{1,2}$/;
  public static readonly Systolic: RegExp = /^\d{0,3}$/;
  public static readonly Diastolic: RegExp = /^\d{0,3}$/;
  public static readonly BMIHeight: RegExp = /^(?:\d{1,3}(?:\.\d{2})?|\d{1,3}(?:\.\d{1})?|\d{1,3})$/;
  public static readonly BMIWeight: RegExp = /^(?:\d{1,3}(?:\.\d{2})?|\d{1,3}(?:\.\d{1})?|\d{1,3})$/;
  public static readonly DepressionSeverity: RegExp = /^\d{1,2}$/;
  public static readonly DrinkingSurvey: RegExp = /^\d{1,2}$/;
  public static readonly HbA1c: RegExp = /^(?:\d{1,3}(?:\.\d{2})?|\d{1,3}(?:\.\d{1})?|\d{1,3})$/;
  public static readonly HDL: RegExp = /^(?:\d{1,3}(?:\.\d{2})?|\d{1,3}(?:\.\d{1})?|\d{1,3})$/;
  public static readonly Height: RegExp = /^(?:\d{1,3}(?:\.\d{2})?|\d{1,3}(?:\.\d{1})?|\d{1,3})$/;
  public static readonly LDL : RegExp= /^(?:\d{1,3}(?:\.\d{2})?|\d{1,3}(?:\.\d{1})?|\d{1,3})$/;
  public static readonly PeakFlow : RegExp= /^(?:\d{1,3}(?:\.\d{2})?|\d{1,3}(?:\.\d{1})?|\d{1,3})$/;
  public static readonly Smoking : RegExp= /^\d{1,3}$/;
  public static readonly TotalCholesterol: RegExp = /^(?:\d{1,3}(?:\.\d{2})?|\d{1,3}(?:\.\d{1})?|\d{1,3})$/;
  public static readonly Triglycerides: RegExp = /^(?:\d{1,3}(?:\.\d{2})?|\d{1,3}(?:\.\d{1})?|\d{1,3})$/;
  public static readonly WaistSize: RegExp = /^(?:\d{1,3}(?:\.\d{2})?|\d{1,3}(?:\.\d{1})?|\d{1,3})$/; 
  public static readonly Weight : RegExp = /^(?:\d{1,3}(?:\.\d{2})?|\d{1,3}(?:\.\d{1})?|\d{1,3})$/;
}


 enum ConsultationType {
  Video = 'MMHVP',
  Phone = 'MMHVK',
  Visit = 'MMKl',
  VisitNone = 'none'
}


