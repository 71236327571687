// Payment pages redirection from Windcame response
export enum PaymentFor {
  Appointmet = "1",
  RRP = "2",
  SMSCredit="3",
  CreditCard ="4"
}
// Alert Type Shared componets
export enum AlertType {
    Info = "Info",
    Success = "Success",
    Warning = "Warning",
    Error = "Error",
    None ="None" //hide the alert type
}
// #region Calendar Scheduler Settings
export enum WeekStart {
    Sunday = 0,
    Monday = 1,
    Saturday = 6,
}
export enum DefaultView {
    Day = 0,
    Week = 1,
    Month = 2,
    CustomView = 3,
    Settings = 5,
}
export enum CustomViewDayNumber {
    Next2days = 2,
    Next3days = 3,
    Next4days = 4,
    Next5days = 5,
    Next6days = 6,
    Next7days = 7,
}
export enum DifferanceIn {
    Days = 1,
    Hours = 2,
    Minutes = 3
}

export enum ConsultationTypeCode {
    Video = 'MMHVC',
    Phone = 'MMHPA',
    Visit = 'none'
}

export enum ProfileTab {
    Profile = 'Profile',
    EditProfile = 'EditProfile',
    ViewEmergency = 'ViewEmergency',
    AddEmergency = 'AddEmergency',
    MyHealthCenters='MyHealthCenters'
}

export enum ActiveCarePlanTab {
    Tasks = 'Tasks'
}

export enum HealthRecordsTab {
    Prescription='Prescription',
    Allergies = 'Allergies',
    Immunisations='Immunisations',
    Classifications='Classifications',
    LabResults='LabResults',
    ClinicalNotes='ClinicalNotes',
    ReCalls='ReCalls',
    Summary='Summary',

}

export enum SearchPatientData {
    ViewGoal = 'ViewGoals',
    ViewHealthIndicator = 'ViewHealthIndicator',
    ViewHealthSummary = 'ViewHealthSummary',
    ViewHealthSummarySEHR = 'ViewHealthSummarySEHR',
    ViewJournal='ViewJournal',
    SendMessage='SendMessage',
    CarePlan='CarePlan'
}

export enum MailBox {
    Inbox = 'Inbox',
    Compose = 'Compose',
    Draft = 'Drafts',
    Sent = 'Sent',
    Groups='Group Messages',
    Archive='Archive',
    Settings='Settings'

}

export enum SehrSearchPatient {
    AddSharedNotes = 'AddSharedNotes',
    HealthSummary = 'HealthSummary',
    ShowSummaryFrontPage="ShowSummaryFrontPage",
    HealthIndicators = 'HealthIndicators',
    AttachDocument = 'AttachDocument',
    ViewAdvanceCarePlan='ViewAdvanceCarePlan',
    OnlineClassifications='OnlineClassifications'

}
export enum HealthRecordFilter {
    MyEntriesValue = '1',
    EntriesFromHealthCentreValue = '2',
    MyEntriesText = 'myEntry',
    EntriesFromHealthCentreText = 'HC'
}

export enum SMSFacilityUserRoles {
    practiceadmin = 'practiceadmin',
    reception = 'reception',
    clinical = 'clinical'
}

export enum ConsultationType {
    Video = 'MMHVC',
    Phone = 'MMHPA',
    Visit = 'MMKl',
    VisitNone = 'none',
    ALL='ALL'
  }
export enum DashboardServiceData {
    BookAppointment = 'BookAppointment',
    RRP = 'RRP',
    HealthRecords = 'HealthRecords',
    LabResults = 'LabResults',
    SendMessage = 'SendMessage',
    HealthCenters = 'HealthCenters',
    ViewMessage = 'ViewMessage'
}

// MHA
export enum MHAErrorCode {
    BR3 = 'BR3', 
    BR4 = 'BR4',
    BR5 = 'BR5', 
    BR6 = 'BR6',
    BR7 = 'BR7', 
    BR8 = 'BR8',
    BR9 = 'BR9', 
    BR10 = 'BR10',
    BR11 = 'BR11', 
    BR12 = 'BR12',
    E2D ='E2D'
}

export enum MHAErrorTitle {
    MyHealthAccountnotfound = 'My Health Account not found', 
    MyHealthAccountmismatch = 'My Health Account mismatch',
    MyHealthAccountincomplete = 'My Health Account incomplete', 
    MyHealthAccountloginfailed = 'My Health Account login failed',
    MyHealthNHInumbermismatch = 'NHI number does not match',
    MyHealthAccountexists = 'My Health Account exists',    
    MyHealthAccountmatchfound = 'My Health Account match found'
}

export enum MHAErrorDescription {
    SignInBR3 = "My Health Account couldn't log you in. Please choose an alternative method to sign in to Manage My Health.  <br/><br/>Ref: SignInBR3", 
    SignInBR4 = 'Your "My Health Account" does not match a Manage My Health account. Please sign-up instead.  <br/><br/>Ref: SignInBR4', 
    SignInBR5 = 'Your "My Health Account" email matches to a different Manage My Health account. Please contact MMH to merge your accounts.  <br/><br/>Ref: SignInBR5',
    SignInBR6 = 'Your "My Health Account" email matches to a different Manage My Health account. Please contact MMH to merge your accounts.  <br/><br/>Ref: SignInBR6', 
    SignInBR7 = 'Your "My Health Account" NHI matches to a different Manage My Health account. Please contact MMH to merge your accounts.  <br/><br/>Ref: SignInBR7',
    SignInBR8 = "Please load additional proof of identity into your 'My Health Account' and then try again. <br/><br/>Ref: SignInBR8",
    SignInBR9 = 'Your "My Health Account" does not match your Manage My Health profile. Please align the data then try again.  <br/><br/>Ref: SignInBR9',
    SignInE2D = 'Your My Health Account NHI number does not match with the NHI number in your Manage My Health account.<br>Contact Manage My Health for further assistance.  <br/><br/>Ref: SignInE2D',
    SignUpBR3 = "My Health Account couldn't log you in. Please choose an alternative method to sign up to Manage My Health.  <br/><br/>Ref: SignUpBR3 ", 
    SignUpBR4 = 'Your "My Health Account" already matches a Manage My Health account. Please sign-in to that account  <br/><br/>Ref: SignUpBR4', 
    SignUpBR5 = 'Your "My Health Account" email address already matches a Manage My Health account. Please sign-in to that account.  <br/><br/>Ref: SignUpBR5',
    SignUpBR6 = 'Your "My Health Account" email address already matches a Manage My Health account. Please sign-in to that account. <br/><br/>Ref: SignUpBR6', 
    SignUpBR7 = 'Your "My Health Account" NHI already matches a Manage My Health account. Please sign-in to that account. <br/><br/>Ref: SignUpBR7',
    SignUpBR8 = "Please load additional proof of identity into your 'My Health Account' and then try again. <br/><br/>Ref: SignUpBR8",
    
}

export enum MHAConfidencelevel {
    CL3 = '3', 
    CL3N = '3N'
}
