import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class VonageClientService {
  public redirect: string;
  private token: string;
  private sessionId: string;
  constructor(private router: Router) { }

  setData = (token: string, sessionId: string) => {
    localStorage.removeItem("VonageToken");
    localStorage.removeItem("VonageSession");
    this.token = token.trim();
    this.sessionId = sessionId.trim();
    localStorage.setItem("VonageToken",this.token);
    localStorage.setItem("VonageSession",this.sessionId);
  }

  setDataForVonage = (token: string, sessionId: string) =>
  {
    sessionStorage.setItem("VonageToken",token);
    sessionStorage.setItem("VonageSession",sessionId);
  }

  getToken() {
    return this.token;
  }

  getSessionId() {
    return this.sessionId;
  }

  logout(){
    localStorage.removeItem("VonageToken");
    localStorage.removeItem("VonageSession");
   // window.close();
   // this.router.navigate([this.redirect]);
   
  }

}
