import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { CommonConstants } from 'src/app/core/constants/commonConstants';
import { URLConstants } from 'src/app/core/constants/urlConstants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class JoinMeetingService {
  constructor(private http: HttpClient, private router: Router) {}

  vcDataItem: any;
  patientDataItem: any;
  type: any;
  private messageSource: any = new BehaviorSubject({});
  currentMessage = this.messageSource.asObservable();
  public providerData: any;
  public reJoinCall: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public redirect: string;
  public audio = true;
  public video = true;
  public userLoggedIn = false;
  public videoCallStarted: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public patientVideoCallStarted: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public patientLoggedInQueueCall: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public turnOffWaitingRoomCamera: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);
  public reloadisprovider1Component: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public hideLogin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public showLogin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public navigatetoVideoInvite: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public outPatientVideoCallFromQueue = false;

  changeMessage(message: any): any {
    this.messageSource.next(message);
  }

  public validateUser(payLoad: any): Observable<any> {
    return this.http.post(
      environment.apiBaseURL + URLConstants.validateVCUser,
      payLoad
    );
  }

  public validateTinyCode(secureCode: string): Observable<any> {
    return this.http.get(
      environment.apiBaseURL + URLConstants.validateTinyCode + secureCode
    );
  }

  getUserIdByPraticeId(data: any): Observable<any> {
    return this.http.post(
      environment.apiBaseURL + URLConstants.getUserIdByPraticeId,
      data
    );
  }

  getPatientUserId(data: any): Observable<any> {
    return this.http.post(
      environment.apiBaseURL + URLConstants.getPatientUserId,
      data
    );
  }

  public getVideoInvitation(userId: any): Observable<any> {
    return this.http.get(
      environment.apiBaseURL + URLConstants.getVideoInvitation + userId
    );
  }

  public setIsOnline(payload: any): Observable<any> {
    return this.http.post(
      environment.apiBaseURL + URLConstants.setOnlineStatus,
      payload
    );
  }

  public getIsOnline(payload: any): Observable<any> {
    return this.http.post(
      environment.apiBaseURL + URLConstants.getOnlineStatus,
      payload
    );
  }

  public getUserDetailsFromSecureCode(secureCode: string): Observable<any> {
    return this.http.get(
      environment.apiBaseURL +
        URLConstants.getUserDetailsFromSecureCode +
        secureCode
    );
  }

  public setIsWaitingRoom(payload: any): Observable<any> {
    return this.http.post(
      environment.apiBaseURL + URLConstants.setIsWaitingRoom,
      payload
    );
  }

  public getMusicMaster(): Observable<any> {
    return this.http.get(environment.apiBaseURL + URLConstants.getMusicMaster);
  }

  public setCompletedStatus(payload: any): Observable<any> {
    return this.http.post(
      environment.apiBaseURL + URLConstants.setCompletedStatus,
      payload
    );
  }

  public getVideoConsultationQueuePatients(payload: any): Observable<any> {
    return this.http.post(
      environment.apiBaseURL + URLConstants.getVideoConsultationQueuePatients,
      payload
    );
  }

  public insertExternalRoomGuid(payload: any): Observable<any> {
    return this.http.post(
      environment.apiBaseURL + URLConstants.insertExternalRoomGuid,
      payload
    );
  }

  public getExternalRoomGuid(secureCode: string): Observable<any> {
    return this.http.get(
      environment.apiBaseURL + URLConstants.getExternalRoomGuid + secureCode
    );
  }

  public getCompletedStatus(meetingId: string): Observable<any> {
    return this.http.get(
      environment.apiBaseURL + URLConstants.getCompletedStatus + meetingId
    );
  }

  public getMeetingInfo(meetingId: string): Observable<any> {
    return this.http.get(
      environment.apiBaseURL + URLConstants.getMeetingInfo + meetingId
    );
  }

  public getRingtone(): Observable<any> {
    return this.http.get(environment.apiBaseURL + URLConstants.getRingtone);
  }

  public logOut() {
    
    sessionStorage.removeItem('non-register-Guid');
    sessionStorage.removeItem('call-ended-byPatient');
    sessionStorage.removeItem('call_pop');
    sessionStorage.removeItem('call-started');
    sessionStorage.removeItem('meetingid');
    sessionStorage.removeItem('patientInfo');
    sessionStorage.removeItem('message');
    sessionStorage.removeItem('reJoinCall');
    this.redirect= sessionStorage.getItem("redirect") || "";
    if (!this.redirect) {
      this.redirect = CommonConstants.patientLogin;
    }  
    this.router.navigate([this.redirect]);    
   sessionStorage.removeItem('meetingData');
  }

  public getVideoConsultationData(payload: any): Observable<any> {
    return this.http.post(
      environment.apiBaseURL + URLConstants.GetVideoConsultationInvitation,
      payload
    );
  }

  public getVCSettings(payLoad: any): Observable<any> {
    return this.http.post(
      environment.apiBaseURL + URLConstants.getVCSettingsForPatient,
      payLoad
    );
  }
  public getVCSettingsAlex(payLoad: any): Observable<any> {
    return this.http.post(
      environment.apiBaseURL + URLConstants.getVCSettingsAlex,
      payLoad
    );
  }
  setAudio(val: boolean): any {
    this.audio = val;
  }

  getAudio(): any {
    return this.audio;
  }

  setVideo(val: boolean): any {
    this.video = val;
  }

  getVideo(): any {
    return this.video;
  }

  public sendInviteParticipantLink(payLoad: any): Observable<any> {
    return this.http.post(
      environment.apiBaseURL + URLConstants.sendInviteParticipantLink,
      payLoad
    );
  }
  public getQueueStatus(payLoad: any): Observable<any> {
    return this.http.post(
      environment.apiBaseURL + URLConstants.getQueueStatus,
      payLoad
    );
  }

  public insertProviderName(payLoad: any): Observable<any> {
    return this.http.post(
      environment.apiBaseURL + URLConstants.insertProviderName,
      payLoad
    );
  }
  public getCommonReasonForCancellation(payLoad: any): Observable<any> {
    return this.http.get(
      environment.apiBaseURL + URLConstants.getCommonReasonForCancellation,
      payLoad
    );
  }
  public GetfutureAppointmentstatus(payload: any): Observable<any> {
    return this.http.post(
      environment.apiBaseURL + URLConstants.GetfutureAppointmentstatus,
      payload
    );
  }
  public UpdateCancellationStatusByPatient(payload: any): Observable<any> {
    return this.http.post(
      environment.apiBaseURL + URLConstants.UpdateCancellationStatusByPatient,
      payload
    );
  }

  generateSSOlinkVC(data: any): Observable<any> {
    return this.http.post(
      environment.apiBaseURL + URLConstants.GenerateSSOlinkVC +
      data, ''
    );
  }

  public SMSVideoInvite(payload: any): Observable<any> {
    return this.http.post(
      environment.apiBaseURL + URLConstants.SMSVideoInvite,
      payload
    );
  }

  getSMSCreditFacilityBalance(practiceId: any) {
    return this.http.get(environment.apiBaseURL + URLConstants.getSMSCreditFacilityBalancePractice + '?encPracticeId=' + practiceId)
  }

}
