// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,

  /********* Microservice Monolithic **********/
  //apiBaseURL: 'http://localhost:59707',
  //apiMonolithicBaseURL: 'http://localhost:5162',  //microservice url
  //appBaseUrl: 'http://localhost:4201/#',  
  //apiHealthSummaryURL:'http://localhost:5056', 
  useMicroService: true,

  apiBaseURL: 'http://192.168.3.232:82',  //v2 api
  appBaseUrl: 'http://192.168.3.232/#',   //frontend url
  profilePicUrl: 'http://192.168.3.232:82/Sitecontent/', 
  apiMonolithicBaseURL: 'http://192.168.3.232:85',
  v1URL:'http://192.168.3.232/#/authentication/login', 

  
  //apiBaseURL : 'https://mmhau-v2api.managemyhealth.com.au:81',
  //apiMonolithicBaseURL: 'https://mmhau-v2api.managemyhealth.com.au',
  //appBaseUrl: 'https://mmhau-v2api.managemyhealth.com.au:81/mmh-au-app/#',
 

  // Dev api Url
  //apiBaseURL:'https://mmhv2.api.covalense.net:8080',
  //in My Local
  // apiBaseURL: 'http://localhost:59707',
  //  appBaseUrl: 'http://localhost:4200/#',
  //apiBaseURL: 'https://mmhau-v2api.managemyhealth.com.au:81',
  // appBaseUrl: 'https://mmhau-v2api.managemyhealth.com.au:81/mmh-au',
  //   apiBaseURL: 'http://192.168.3.227:8181',
  // appBaseUrl: 'http://192.168.3.227:8181/mmh-au/#',
  //profilePicUrl: 'https://mmhau-v2api.managemyhealth.com.au:81//',
  // apiBaseURL: 'https://mmhau-v2api.managemyhealth.com.au:81',
  // appBaseUrl: 'https://mmhau-v2api.managemyhealth.com.au:81/mmh-au/#',
  //  apiBaseURL: 'http://192.168.3.227:81',
  //  appBaseUrl: 'http://192.168.3.227:8080/#',
  // apiBaseURL: 'https://localhost:44328/',
  // apiBaseURL: 'https://v2smsapi.mmh-demo.com',
  //apiBaseURL: 'https://betaapi.mmh-demo.com',
  //apiBaseURL: 'https://v2webapi.mmh-demo.com',
  //apiBaseURL:'https://v2apifeature.mmh-demo.com',
  //apiBaseURL: 'https://betaapi.managemyhealth.co.nz',
  // apiBaseURL:'https://mmhv2api.mmh-demo.com',
  //  apiBaseURL:'https://v2webprduatapi.mmh-demo.com',
  //UAT api Url
  //apiBaseURL:'http://mmhv2.images.covalense.net:8090',
  //v1URL:'http://192.168.3.227:8181/mmh-au/#/authentication/login',
 //v1URL:'http://localhost:4200/#/authentication/login',
  //v1URL:'http://192.168.3.227:82/mmh-au/#/authentication/login',
  //v1URL:'https://mmhau-v2api.managemyhealth.com.au:81/mmh-au/#/authentication/login',
  //v1URL:'https://portal.managemyhealth.co.nz/m/Main/login',
  vonageApiKey: '47411651',
  rayGunKey: 'OTAQ2gmJWKq7rHlT5XOYVw',
  // reCaptchaSiteKey:'6Le29akUAAAAAPzR3JIponfUGWTiA0zHKsLdvx6Y',
  reCaptchaSiteKey:'6LflMxcqAAAAANAAyhwS0whBxHSjzIATlWQX-S_a',
  logContentsOfXhrCalls:true,
  appVersion: '1.0.0',
  adButlerAccountId: 186314,
  discoverHealthApi: 'https://discovercms.mmh-demo.com/',
  discoverHealthAspApi: 'https://v2webprduatapi.mmh-demo.com/api/DiscoverHealth/',
  providerLoginURL:'https://dev.mmh-demo.com/m/Main/login',
  signalrHubUrl: "https://wss.managemyhealth.co.nz/signalrdemohub",
  outPatientUrl: 'https://v2webprduatapi.mmh-demo.com/meeting/out-patient/',
  healthDiaryURL: 'https://coviddiary.mmh-demo.com/covid-diary/covid-diary-entry?query=',
  AlexAPIUrl: "https://int-alex.mmh-demo.com/api/v1/",
  medicAlertURL: 'https://www.medicalert.co.nz/net/',
  supportPortalPdfUrl: 'https://v2webprdfeature.mmh-demo.com',
  parentId:[1,1489,4],
  //dashBoardAdZoneId:584953,
  //dashBoardMobileAdZoneId: 585982 ,
  isChatBotEnable : false,
  clientIdMHA: '48c8e1e6-9812-4f8d-a6b1-a4651c5ce792',
  authorityMHA: 'https://b2c-int.login.health.nz/mohintb2cdir.onmicrosoft.com/b2c_1a_moh_digitalidentity_signuporsignin',
  redirectUriMHA: 'http://localhost:4200/authentication/register/', 
  postLogoutRedirectUriMHA: 'http://localhost:4200/',
  knownAuthoritiesMHA: 'b2c-int.login.health.nz',
  userInfoUriMHA: 'https://b2c-int.login.health.nz/mohintb2cdir.onmicrosoft.com/b2c_1a_moh_digitalidentity_signuporsignin/openid/v2.0/userinfo'
   // Zoned Id's
   ,dashBoardAdZoneId:677329,
   dashboardCenterZoneID:677328,
   dashboardtopMobileZoneID:677325,
   appointmentCenterZoneID:677327,
   appointmentMobileZoneID:677323,
   labResultsCenterZoneId:677330,
   labResultsMobileTopZoneId:677326,
   CenterZoneID:609388,
   dashBoardMobileAdZoneId: 585982,
   dashBoardMobileAdZoneId_p1: 677324,
   loginpageBottomZoneId:677331,
    appointmentMobileInterstitialZoneID:678012,
    repeatPresciptionswebVideo:678017,
    repeatPrescriptionsTabsVideo:678015,
    appointmentsTabVideoad:678014,
    appointmentInterstitialWeb:678016,
    appointmentLandscapeInterstitial:678013,
    articlepage:701824
  // Zoned Id's -END
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
