import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { read } from 'fs';
import { URLConstants } from 'src/app/core/constants/urlConstants';
import { applicationHttpClientCreator } from 'src/app/data/interface/IRequestOptions';

@Component({
  selector: 'app-payment-response',
  templateUrl: './payment-response.component.html',
  styleUrls: ['./payment-response.component.scss']
})
export class PaymentResponseComponent implements OnInit {

  txnId: any;
  practiceId: any;
  locationId: any;
  resultIndicator: any;
  paymentRespose: any;
  from: any;
  backText: any;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router
  ) {
    this.route.queryParams.subscribe(params => {
      this.txnId = params['orderId'];
      this.practiceId = params['practiceId']; 
      this.locationId = params['locationId']; 
      this.from = params['from']; 
    });

    this.txnId = this.route.snapshot.paramMap.get('txnId');
    if(this.from == 'Repeat Prescriptions'){
      this.backText = 'Back to Repeat Prescriptions';
    }else{
      this.backText = 'Back to Booking';
    }
  }

  isSmsRequest : boolean = false;
  ngOnInit(): void {
    console.log('Order    Id : ' + this.txnId);
    console.log('Practice Id : ' + this.practiceId);
    console.log('Location Id : ' + this.locationId);
    if(this.locationId == 'SMS'){
      this.isSmsRequest = true
    }
    else{
      this.isSmsRequest = false;
    }
    this.checkPaymentResponse();
  }

  backButton() {
    if(this.from == 'Repeat Prescriptions'){
      this.router.navigate(['/rrp/repeat-prescriptions']);
    }else{
      this.router.navigate(['/appointment/future']);
    }
  }

  checkPaymentResponse() {
    let url = URLConstants.checkPaymentResponse + this.txnId;
    url += '&practiceId=' + this.practiceId;
    url += '&LocationCode=' + this.locationId;
    applicationHttpClientCreator(this.http).Get(url, {
    }).subscribe((data: any) => {
      console.log(data);
      this.paymentRespose = data;
    }, (err: any) => {
      console.log(err);
    });
  }

}
