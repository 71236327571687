<div mat-dialog-title class="dialog-title-bar">
  <h2>Payment</h2>
  <!-- <button mat-button mat-flat-button mat-dialog-close class="dialog-close-btn" (click)="onClose()">
    <span class="k-icon k-i-x"></span>
  </button> -->
</div>

<mat-dialog-content class="mat-typography mat-dialog-content-inputs">
  <!-- <p>{{data.terms}}</p>  -->
  <div class="alert-data" [innerHTML]="data.terms"></div>
</mat-dialog-content>

<mat-dialog-actions class="dialog-actions-btns">

  <button mat-button mat-dialog-close class="btn btn-primary-rect">Ok</button>

</mat-dialog-actions>