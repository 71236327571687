<!-- <form [formGroup]="fileUploadForm"> -->
    <!-- <div class="m-b-10 show-file ">
        <i class="icon-cent-attachment-files color-white"></i> Attach File
       <input class="hide-file" type="file" (change)="onFileChange($event)" placeholder="file"/>
       <i class="icon-cent-inform-alerts cursor-pointer"></i>
   </div> -->


   <div class="show-file-x">
    <mat-icon>attachment</mat-icon>Attach File
    <input #fileInput type="file"  placeholder="file"
      accept="txt,jpeg,jpg,gif,png,xls,doc,docx,xlsx,ppt,pptx,rtf,pdf,zip"
      (change)="onFileChange($event)" class="hide-file">
       <!-- <i class="icon-cent-inform-alerts cursor-pointer"></i> -->
  </div>

<div *ngIf="arrFiles.length > 0">
    <span *ngFor="let file of arrFiles; let k =index">
        {{file.fileName}}
        <!-- <button class="delete-icon" (click)="deleteFile(file, k)">
            <mat-icon>delete</mat-icon>
        </button> -->
        <br />
    </span>
</div>
