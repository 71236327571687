import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { GlobalConstants } from '../../core/constants/globalConstants';
import { Toast } from '../interface/toast.interface';
import { ToastIcon, ToastType } from '../interface/toast.type';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {
  subject: BehaviorSubject<Toast>;
  toast$: Observable<Toast>;

  constructor() {
    this.subject = new BehaviorSubject<Toast>({ type: 'empty', toastIcon: "", title: "", body: "", delay: 1000 });
    this.toast$ = this.subject.asObservable()
      .pipe(filter(toast => toast !== null));
  }

  show(type: ToastType, toastIcon: string, title: string, body: string, delay: number): void {
    this.subject.next({ type, toastIcon, title, body, delay });
  }
  success(message: string, title?: string) {
    let data = {
      type: GlobalConstants.successType,
      toastIcon: GlobalConstants.successIcon,
      body: message,
      title: title ? title : GlobalConstants.successTitle,
      delay: GlobalConstants.toasterDelayTime
    }
    this.subject.next(data);
  }
  error(message: string, title?: string) {
    let data = {
      type: GlobalConstants.errorType,
      toastIcon: GlobalConstants.errorIcon,
      body: message,
      title: title ? title : GlobalConstants.errorTitle,
      delay: GlobalConstants.toasterDelayTime
    }
    this.subject.next(data);
  }
  warning(message: string, title?: string) {
    let data = {
      type: GlobalConstants.warningType,
      toastIcon: GlobalConstants.warningIcon,
      body: message,
      title: title ? title : GlobalConstants.warningTitle,
      delay: GlobalConstants.toasterDelayTime
    }
    this.subject.next(data);
  }
  info(message: string, title?: string) {
    let data = {
      type: GlobalConstants.infoType,
      toastIcon: GlobalConstants.infoIcon,
      body: message,
      title: title ? title : GlobalConstants.infoTitle,
      delay: GlobalConstants.toasterDelayTime
    }
    this.subject.next(data);
  }
}
