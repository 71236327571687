import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { EncryptDecrypt } from 'src/app/core/helper/encrypt-decrypt';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
 // [x: string]: any;
  // subject: BehaviorSubject<Toast>;
  isProvider = false;
  clinicalandreceptionRoleFound: boolean = false;

  public updateMenu: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);
  updateMenuObservable = this.updateMenu.asObservable();

  public refreshToken: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);
  refreshTokenObservable = this.refreshToken.asObservable();

  updateMenuData(newData: boolean) {
    this.updateMenu.next(newData);
  }
  refreshTokenData(newData: boolean) {
    this.refreshToken.next(newData);
  }

  constructor() {
    this.getUserRoles();
  }
  public hideSideBarMenu:boolean=true;
  token(): string  {
    //let test: string = '';
    const tokenValue: any =  sessionStorage.getItem('currentUser')? sessionStorage.getItem('currentUser') : null;
    //test.push(tokenValue);
    return tokenValue;
  }
  alexToken(): string  {
    const tokenValue: any =  sessionStorage.getItem('alexToken')? EncryptDecrypt.decryptUsingAES256(sessionStorage.getItem('alexToken')) : null;
    //test.push(tokenValue);
    return tokenValue;
  }
  userId() {
    let currentUser: any;
      if (sessionStorage.getItem('userId')) {
       currentUser = EncryptDecrypt.decryptUsingAES256(sessionStorage.getItem('userId'));
      // const data = JSON.parse(currentUser);

      // console.log("*****************************UserID****************", currentUser)
      return currentUser;
    } else return null;
  }

  encryptedUserId() {
    let currentUser: any;
      if (sessionStorage.getItem('userId')) {
       currentUser = sessionStorage.getItem('userId');
      // const data = JSON.parse(currentUser);
      return currentUser;
    } else return null;
  }

  userProfile(){
    let userProfile: any;
    if (sessionStorage.getItem('userProfile')) {
      userProfile = sessionStorage.getItem('userProfile');
      const parsedData =EncryptDecrypt.decryptUsingAES256(userProfile);
      const data=JSON.parse(parsedData);
      console.log(data,"USERDATA");

      localStorage.setItem("alexpat",data.nhiNumber);
      return data;
    } else return null;
  }
  getUserRoles(){
    let userRoles: any;
    if (sessionStorage.getItem('userRoles')) {
      userRoles = sessionStorage.getItem('userRoles');
      const parsedData =EncryptDecrypt.decryptUsingAES256(userRoles);
     // const data=JSON.parse(parsedData);
      return parsedData;
    } else return null;
  }

  addRole(role: string) {
    const userRoles = sessionStorage.getItem('userRoles');
    let parsedData: any = EncryptDecrypt.decryptUsingAES256(userRoles);
    parsedData = parsedData + ',' + role;
    sessionStorage.setItem('userRoles',EncryptDecrypt.encryptUsingAES256(parsedData));
  }
  getDefaultPracticeId() {
    let practiceId = sessionStorage.getItem('defaultPracticeId');
    if (practiceId && practiceId != '') {
      return practiceId;
    } else return null;
  }
  clearsessionStorageValues() {
    // remove user from session storage and set current user to null
    sessionStorage.removeItem('currentUser');
    sessionStorage.removeItem('userProfile');
    sessionStorage.removeItem('patientsDetails');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('googleMapAPI');
    sessionStorage.removeItem('userRoles');
    sessionStorage.removeItem('expiryTime');
    sessionStorage.removeItem('loginType');
    sessionStorage.removeItem('returnURL');
    sessionStorage.removeItem('defaultPracticeId');
    sessionStorage.removeItem('Services');

    // linked account values
    sessionStorage.removeItem('CarerEmail');
    sessionStorage.removeItem('ChildAccountApproved');
    sessionStorage.removeItem('tabId');
    sessionStorage.removeItem('WebPressToken');

    // for video conference values
    sessionStorage.removeItem('call-started');
    sessionStorage.removeItem('meetingid');
    sessionStorage.removeItem('call_pop');
    sessionStorage.removeItem('patientInfo');
    sessionStorage.removeItem('message');
    sessionStorage.removeItem('reJoinCall');

    //Alex
    sessionStorage.removeItem('alexToken');
    // this.currentUserSubject.next(null);
  }

  hasOnlyRmAndReceptionRoles(): any {
    let userRoleArr: any = this.getUserRoles();
    let userRoleList = userRoleArr.split(',').map((role : any)=> role.toLowerCase());
    let rmRoleFound = userRoleList.includes('rm');
    let receptionRoleFound = userRoleList.includes('reception');
    return  receptionRoleFound;
  }

  hasOnlyRmAndCbtProvider(): any {
    let userRoleArr: any = this.getUserRoles();
    let userRoleList = userRoleArr.split(',').map((role : any)=> role.toLowerCase());
    let rmRoleFound = userRoleList.includes('rm');
    let btbprovider = userRoleList.includes('cbtprovider');
    return  rmRoleFound && btbprovider  && userRoleList.length === 2;
  }

  hasClinicalandReceptionRoles(): boolean {
    let userRoleArr: any = this.getUserRoles();
    let userRoleList = userRoleArr.split(',').map((role: any) => role.toLowerCase());
    return (
      (userRoleList.includes('clinical') || userRoleList.includes('practiceadmin')) ||
      (userRoleList.includes('clinical') && userRoleList.includes('practiceadmin') && userRoleList.includes('reception'))
    );
  }


  checkIsPatient() {
    let isPatient = false;
    let userRoleArr: any = this.getUserRoles();
    let userRoleList = userRoleArr.split(',');
    let patientid = userRoleList.findIndex((x: any) => x.toLowerCase() == 'patient');
    if (patientid != -1) {
      isPatient = true;
    }

    return isPatient;
  }
  checkIsBTBPatient() {
    let isPatient = false;
    let userRoleArr: any = this.getUserRoles();
    let userRoleList = userRoleArr.split(',');
    let patientid = userRoleList.findIndex((x: any) => x.toLowerCase() == 'cbtuser');
    if (patientid != -1) {
      isPatient = true;
    }

    return isPatient;
  }

  checkIsPatientRolesForAds() {
    let isPatient = false;
    let userRoleArr: any = this.getUserRoles();
    let userRoleList = userRoleArr.split(',');

    let patientid = userRoleList.findIndex((x: any) => x.toLowerCase() == 'patient' || x.toLowerCase() == 'mamember' || x.toLowerCase() == 'openreg' || x.toLowerCase() == 'cbtuser'
    ||  x.toLowerCase() == 'easybooking');
    if (patientid != -1) {
      isPatient = true;
    }

    return isPatient;
  }

  checkIsProvider() {
    let userRoleArr: any = this.getUserRoles();
    let userRoleList = userRoleArr.split(',');
    let patientid = userRoleList.findIndex((x: any) => x.toLowerCase() == 'patient');
    if (patientid == -1) {
      let isRoleFound = userRoleList.findIndex((x: any) => x.toLowerCase() == 'mamember' || x.toLowerCase() == 'openreg' || x.toLowerCase() == 'cbtuser');
      if (isRoleFound != -1) {

      }
      else {
        this.isProvider = true;
      }
    }
    return this.isProvider;
  }

  checkIsBTBUser() {
    let userRoleArr: any = this.getUserRoles();
    let userRoleList = userRoleArr.split(',');
    console.log(userRoleList);
    let roleDetails = userRoleList.findIndex((x: any) => x.toLowerCase() == 'cbtprovider' || x.toLowerCase() == 'cbtuser');
    return roleDetails !== -1;
  }


  openHealthCenterBasedOnRole() {
    let userRoleArr: any = this.getUserRoles();
    let userRoleList = userRoleArr.split(',');
    let patientid = userRoleList.findIndex((x: any) => x.toLowerCase() == 'patient');
    console.log(patientid);

    let returnUrl = '/pages/home';
    if (patientid != -1) {
      returnUrl = '/myaccount/profile';
    }
    sessionStorage.setItem('healthcenter', 'true');
    return returnUrl;
  }

  checkIsClinicalUser() {
    let isClinical = false;
    let userRoleArr: any = this.getUserRoles();
    let userRoleList = userRoleArr.split(',');
    let patientid = userRoleList.findIndex((x: any) => (x.toLowerCase() == 'clinical' || x.toLowerCase() == 'extclinical'));
    if (patientid != -1) {
      isClinical = true;
    }
    return isClinical;
  }

  removeHtmlTags(html: string): string {
    const parser = new DOMParser();
    const doc = parser?.parseFromString(html, 'text/html');
    return doc?.body?.textContent || '';
  }

  decoder(encriptText: any) {
    let decoderText = '';
    if(encriptText) {
      const atbText = (atob(encriptText));
      const decoder = new TextDecoder("utf-8");
      decoderText = decoder?.decode(new Uint8Array([...atbText].map(char => char?.charCodeAt(0))));
    }
    return decoderText;
  }


}


