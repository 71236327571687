import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RecallRemainderDialogComponent } from '../recall-remainder-dialog/recall-remainder-dialog.component';

@Component({
  selector: 'app-link-new-health-center-dialog',
  templateUrl: './link-new-health-center-dialog.component.html',
  styleUrls: ['./link-new-health-center-dialog.component.scss']
})
export class LinkNewHealthCenterDialogComponent implements OnInit {
  accept: any;
  showTerms: boolean;
  constructor(public dialogRef: MatDialogRef<RecallRemainderDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  btnClick(from: string) {
    let payload = {
      btn: 'linkWithHealthCentre',
      payload: {
        activationCode: this.data.payload.activationCode,
        isPracticeTermsEnabled: this.data.payload.isPracticeTermsEnabled,
        businessName: this.data.payload.businessName,
        termsandConditions: this.accept || false,
      }
    }
    if (from == 'remindMeLater') {
      payload = {
        btn: 'remindMeLater',
        payload: {
          activationCode: this.data.payload.activationCode,
          isPracticeTermsEnabled: this.data.payload.isPracticeTermsEnabled,
          businessName: this.data.payload.businessName,
          termsandConditions: this.accept || false,
        }
      }
    }

    this.onClose(payload);

  }

  onClose(payload: any) {
    this.dialogRef.close(payload);
  }

  openTearmsAndConditions() {
    console.log('Terms and conditon clicked');
    console.log(this.data)
    this.showTerms = true;
  }

}
