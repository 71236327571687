import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { map } from 'rxjs/operators';
import { URLConstants } from 'src/app/core/constants/urlConstants';
import { applicationHttpClientCreator } from 'src/app/data/interface/IRequestOptions';
import { ToasterService } from 'src/app/data/service/toaster.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-download-file',
  templateUrl: './download-file.component.html',
  styleUrls: ['./download-file.component.scss']
})
export class DownloadFileComponent implements OnInit {
  pdfURL: string;
  patientPaymentReports: any;
  btn_class : string = '';

  constructor(public http: HttpClient, public toaster: ToasterService,private translate:TranslateService) { }
  @Input() content?: string;
  @Input() guiId?: string;
  @Input() url: string;
  @Input() pdfName: string;
  @Input() isPatient: boolean;
  @Input() btnText: string;
  @Input() postObject: any;
  @Input() linkFile?: boolean;
  @Input() ppReport?: boolean=false;
  @Input() isPostDownload?: boolean= false;
  isPaymentButtonEnable=false;
  @Input() no_highlight : boolean = false;
  @Input() btn_primary_pill : boolean = false;
  @Input() no_button : boolean = false;
  @Input() isDisabled : boolean = false;

  ngOnInit(): void {

      if(this.btn_primary_pill) {
        this.btn_class = 'btn-primary-pill';
      } else if(this.no_highlight) {
        this.btn_class = 'btn-primary';
      } else if(this.btn_primary_pill && this.no_highlight) {
        this.btn_class = 'btn-primary-pill';
      } else if(this.no_button) {
        this.btn_class = 'btn-no';
      } else {
        this.btn_class = 'btn-primary-rect-l-icon';
      }



    if(this.ppReport){
      applicationHttpClientCreator(this.http).Get(`${URLConstants.getPatientReportsByTransId}${this.postObject}`, {
      }).subscribe((data: any) => {
        console.log('patient payments reports', data);
        this.patientPaymentReports=data;
        if(data){
        this.isPaymentButtonEnable=true;
        this.pdfName = "Receipt_" + this.patientPaymentReports[0].MMHPayTxnID+"_" + moment().format('YYYYMMDDHHmmss') + ".pdf"
        this.url = URLConstants.patientReceiptPdfExport + this.patientPaymentReports[0].MMHPayTxnID;
        }
      }, (err: any) => {
        this.toaster.error(err.error.Message);
      });
    }
  }

  onDownLoadFileForPatient = () => {
    const headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/octet-stream',
    });

    const requestOptions = { headers: headerOptions, responseType: 'blob' as 'blob' };
    if (this.postObject) {
      this.http.post(`${environment.apiBaseURL}${this.url}`, this.postObject, requestOptions).pipe(map((data: any) => {
        const blob = new Blob([data], {
          type: 'application/octet-stream'
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${this.pdfName}`;
        link.click();
        window.URL.revokeObjectURL(link.href);

      })
      ).subscribe(
        (data) => {
          console.log(data);
          this.toaster.success(this.translate.instant('File downloaded successfully'));
        },
        (err) => {
          this.toaster.warning(err.error.Message || err.error.message || err.message);
        },
      );
    } else {
      this.http.get(`${environment.apiBaseURL}${this.url}`, requestOptions).pipe(map((data: any) => {
        const blob = new Blob([data], {
          type: 'application/octet-stream'
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${this.pdfName}`;
        link.click();
        window.URL.revokeObjectURL(link.href);

      })
      ).subscribe(
        (data) => {
          console.log(data);
          this.toaster.success(this.translate.instant('File downloaded successfully'));
        },
        (err) => {
          this.toaster.warning(err.error.Message || err.error.message || err.message);
        },
      );
    }
  }

  onDownLoadFile = () => {
    let pdfContent: any = this.content ? this.content : '';
    if (pdfContent && pdfContent !== '') {
      if (pdfContent.indexOf('<html>') === -1) {
        pdfContent = '<html><head><title></title></head><body>' + pdfContent
          + '</body></html>';
        // pdfContent = '<html><head><title></title></head><body> <div style=\ "width: 100%; font-size: 8px;\">'
        //   + '<h2 style=\ "text-align: center;\"> <span style=\ "font-weight: bold;\">Manage My Health</span><sup><span style=\"font-weight: bold;\"> TM</span> </sup> </h2>'
        //   + '<p><br> </p>'
        //   + '<h2 style=\ "text-align: center;\"> <span style=\ "font-weight: bold;\">Empowering for Health and Wellness</span> </h2>'
        //   + '<p><br> <br> Thank you for registering for Manage My Health. <br> <br> Your activation code is <b>[PAT_ACTIVATIONCODE]</b><br> <br> </p>'
        //   + '</div></body></html>';
      }
      // else {
      //   // pdfContent = '<html><head><title></title></head><body> <div style=\ "width: 100%; font-size: 8px;\">'
      //   //   + '<h2 style=\ "text-align: center;\"> <span style=\ "font-weight: bold;\">Manage My Health</span><sup><span style=\"font-weight: bold;\"> TM</span> </sup> </h2>'
      //   //   + '<p><br> </p>'
      //   //   + '<h2 style=\ "text-align: center;\"> <span style=\ "font-weight: bold;\">Empowering for Health and Wellness</span> </h2>'
      //   //   + '<p><br> <br> Thank you for registering for Manage My Health. <br> <br> Your activation code is <b>[PAT_ACTIVATIONCODE]</b><br> <br> </p>'
      //   //   + '</div></body></html>';
      //   pdfContent =  pdfContent;
      // }
      pdfContent = '<sup>TM</sup></h2><h2><br></h2><h2><span>Empowering&#160;</span>for Health and Wellness</h2><h2><br><br><span>Thank you for registering for Manage My Health.</span><br><br><br><br><span>Please follow the instructions below to complete your registration:</span><br><br><b>If you are a first time user of Manage My Health:</b><span></span><br><ol><li>Visit http://www.managemyhealth.co.nz/start</li><li>Specify your email id,the email used while registering for Manage My Health at&#160;</li><li>Specify your activation code, type the characters in picture in below text box and click the Activate button</li><li>Choose a password and agree to Terms and Conditions and Click Complete Registration</li><li>Check your email and click the verification link to complete the registration process</li></ol><br><br><b>If you already have a Manage My Health account:</b><span></span><br><ol><li>Login to Manage My Health</li><li>Click on My Account</li><li>Click on My Health Centres</li><li>Enter your activation code and click Verifybutton</li><li></li></ol></h2>';
      console.log('PDF content', pdfContent);
      const requestData =
      {
        Content: pdfContent,
        PracticeId: this.guiId
      };

      const headerOptions = new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/octet-stream',
      });
      const requestOptions = { headers: headerOptions, responseType: 'blob' as 'blob' };

      this.http.post(`${environment.apiBaseURL}${this.url}`, requestData, requestOptions).pipe(map((data: any) => {
        const blob = new Blob([data], {
          type: 'application/octet-stream'
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${this.pdfName}`;
        link.click();
        window.URL.revokeObjectURL(link.href);

      })
      ).subscribe(
        (data) => {
          console.log(data);
          this.toaster.success(this.translate.instant('File downloaded successfully'));
        },
        (err) => {
          this.toaster.warning(err.error.Message || err.error.message || err.message);
        },
      );
    }

  }

  downloadPostCallFiles=()=>
  {
    const headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/octet-stream',
    });
    let encodefilename = btoa(this.pdfName)
    const requestOptions = { headers: headerOptions, responseType: 'blob' as 'blob' };
    this.postObject = {FileName: encodefilename}
    this.http.post(`${environment.apiBaseURL}${this.url}`, this.postObject, requestOptions).pipe(map((data: any) => {
      const blob = new Blob([data], {
        type: 'application/octet-stream'
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${this.btnText}`;
      link.click();
      window.URL.revokeObjectURL(link.href);

    })
    ).subscribe(
      (data) => {
        console.log(data);
        this.toaster.success(this.translate.instant('File downloaded successfully'));
      },
      (err) => {
        this.toaster.warning(err.error.Message || err.error.message || err.message || "File doesn't exist");
      },
    );
  }
}
