import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { PaymentFor } from 'src/app/core/constants/app-enums';
import { URLConstants } from 'src/app/core/constants/urlConstants';
import { environment } from 'src/environments/environment';
import { IAppointment } from '../interface/IAppointmentDateSlot';
import { IRRPPayment, IAppointmentPayment, IPaymentResponse, ISMSPayment } from '../interface/Ipayment';


@Injectable({
  providedIn: 'root'
})

export class PaymentService {

  appointmentPaymentObject: IAppointmentPayment;
  appointmentObject: IAppointment;
  private appointmentStatus = new BehaviorSubject<any>('');
  name = this.appointmentStatus.asObservable();

  private appointmentStatusType = new BehaviorSubject<any>('');
  type = this.appointmentStatusType.asObservable();

  sharedStyleSource = new ReplaySubject<any>(1);
  public sharedStyle$ = this.sharedStyleSource.asObservable();

  public iframeContent = new BehaviorSubject<boolean>(true);
  iframeSignout = this.iframeContent.asObservable();

  public dailogPaymentStatus = new BehaviorSubject<boolean>(false);
  dailogStatusOpen = this.dailogPaymentStatus.asObservable();
  constructor(private http: HttpClient) { }

  newStyle(value: any) {
    this.sharedStyleSource.next(value);
  }

  public ProcessAppointmentPayment(obj: IAppointmentPayment): Observable<any> {
    return this.http.post(environment.apiBaseURL + URLConstants.processAppointmentPayment, obj);
  }
  public SendRRPRequest(obj: IRRPPayment): Observable<any> {
    return this.http.post(environment.apiBaseURL + URLConstants.SendRRPRequest, obj);
  }

  public PaymentResponseVisitor(type: string, op: string, result: string): Observable<any> {
    if (type == PaymentFor.Appointmet.toString()) {
      return this.http.get(environment.apiBaseURL + URLConstants.apointmentPaymentResponseAccount + op + '&result=' + result,{});
    } else {
      return this.http.get(environment.apiBaseURL + URLConstants.RRPPaymentResponseAccount + op + '&result=' + result,{});
    }
  }
  public PaymentResponse(type: string, op: string, result: string): Observable<any> {
    if (type == PaymentFor.Appointmet.toString()) {
      return this.http.get(environment.apiBaseURL + URLConstants.apointmentPaymentResponse + op + '&result=' + result);
    } else {
      return this.http.get(environment.apiBaseURL + URLConstants.RRPPaymentResponse + op + '&result=' + result);
    }
  }

  public SubmitAppointmentPaymentRequest(obj: IAppointmentPayment, objAppointment: IAppointment)
  {
    return this.http.post(environment.apiBaseURL + URLConstants.newAppointmentSubmit,
      {
        appSelectedDate: obj.AppSelectedDate,
        fromTime: obj.FromTime,
        patientUserId: obj.PatientUserId,
        practiceId: obj.PracticeID,
        spcCode: obj.SpcCode,
        providerName: obj.ProviderName,
        slotDuration: obj.SlotDuration,
        locCode: obj.LocationCode,
        consultType: obj.ConsultationType === 'none' ? '' : obj.ConsultationType,
        appStatus: 'Pending',
        reasontovisit1: objAppointment.Notes,
        phone: obj.PAContactNumber,
        source: obj.source,
        otherfirstName: obj.otherfirstName,
          otherlastName:obj.otherlastName,
          OtherDOB:obj.OtherDOB,
          OtherMobileNo:obj.OtherMobileNo,
          OtherAdditionalNotes:obj.OtherAdditionalNotes,
          OtherEmail:obj.OtherEmail,
          GuestBookByFirstName:obj.GuestBookByFirstName,
          GuestBookByLastName:obj.GuestBookByLastName,
          reasontovisit:obj.reasontovisit,
          AppointmentModeformmhpms:obj.AppointmentModeformmhpms,
          ismmhpms:obj.ismmhpms,
          additionalassitence:obj.additionalassitence

      }
    );
  }

  public UpdatePatientPAContactNumberForAppointment(obj: IAppointmentPayment)
  {
    return this.http.post(environment.apiBaseURL + URLConstants.UpdatePatientPAContactNumberForAppointment,
      {
        appSeletedDate: obj.AppSelectedDate,
        patientUserid: obj.PatientUserId,
        practiceID: obj.PracticeID,
        sPC: obj.SpcCode,
        providerName: obj.ProviderName,
        locationCode: obj.LocationCode,
        consulationType: obj.ConsultationType,
        pAContactNumber: obj.PAContactNumber,
      }
    );
  }
  changeStatus(status: any,type:any) {
    this.appointmentStatus.next(status);
    this.appointmentStatusType.next(type);
  }
  signInChecking(iframeContent:any){
    this.iframeContent.next(iframeContent);
  }
  dailogStatus(open:any){
    this.dailogPaymentStatus.next(open);
  }

  public easyBookingPaymentRequest(obj: IAppointmentPayment): Observable<any> {
    return this.http.post(environment.apiBaseURL + URLConstants.bookApptForExistingUser, obj);
  }

  public sendSMSRequest(obj: ISMSPayment): Observable<any> {
    return this.http.post(
      environment.apiBaseURL + URLConstants.sendSMSPaymentRequest,
      obj
    );
  }

  public sendCreditCardRequest(obj: ISMSPayment): Observable<any> {
    return this.http.post(
      environment.apiBaseURL + URLConstants.sendCreditCardRequest,
      obj
    );
  }
}
