import { URLConstants } from './../../core/constants/urlConstants';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TokenService } from 'src/app/data/service/token.service';
import { shareReplay } from 'rxjs/operators';
import { applicationHttpClientCreator } from '../interface/IRequestOptions';
import { EncryptDecrypt } from 'src/app/core/helper/encrypt-decrypt';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class InBoxService {
  public cacheHealthCenterDet$?: Observable<Object>;
  //private cacheUnReadMessageCount$: Observable<Object>;
  public cacheUserAndMenu$?: Observable<Object>;
  CACHE_SIZE = 1;

  constructor(private http: HttpClient,private tokenservice:TokenService) { }
  getAllRecievedMessages(userId: string) {
    return this.http.get(environment.apiBaseURL + URLConstants.getInboxMessages + userId);
  }
  deleteToTrash(inboxId: string, isSentItem: boolean) {
    return this.http.post(environment.apiBaseURL + URLConstants.deleteToTrash + inboxId + '&IsSentItem=' + isSentItem, null)
  }

  // Move to archive call for inbox and sent box
  moveToArchive(userID: string, inboxId: string, isSentItem: boolean): Observable<any> {
    return this.http.post(environment.apiBaseURL + URLConstants.archiveToTrash + userID + '&InboxId=' + inboxId + '&IsSentItem=' + isSentItem, null)
  }

  // Inbox selected mail Details
  getSelectedMailDetails(userId:string,mailId: string) {
    let encryptedMailId = EncryptDecrypt.encryptUsingAES256(mailId);
    return this.http.get(environment.apiBaseURL + URLConstants.GetSelectedMailDetailsbyMailId + userId + '&MailId=' + encryptedMailId);
  }

  getSelectedsentMail(UserID: string, IsSentItem: boolean,IsTrash:boolean,RowID:number,MessageID:number) {
    return this.http.get(environment.apiBaseURL + URLConstants.getSelectedsentMail + UserID + '&IsSentItem=' + IsSentItem + '&IsTrash=' + IsTrash + '&RowID=' + RowID + '&MessageID=' + MessageID);
  }

  // messageDetails(UserID:string,IsSentItem:boolean,IsTrash:boolean,RowID:number,MessageID:number){
  //   return this.http.get(environment.apiBaseURL + URLConstants.getSelectedsentMail + UserID + '&IsSentItem=' + IsSentItem + '&IsTrash=' + IsTrash + '&RowID=' + RowID + '&MessageID=' + MessageID);
  // }


  // Sent Box & archive messages selected mail Details
  getSelectedsentMailDetails(UserID:string,isSentItem: boolean, isTrash:boolean, rowID:number,mailId: string) {
    let IsFromMT32:any
    if(IsFromMT32 ! =null) {
      return this.getSelectedSentMailData(isSentItem, mailId);
    }
    else {
      return this.http.get(environment.apiBaseURL + URLConstants.messageDetails + UserID + '&IsSentItem=' + isSentItem  + '&IsTrash=' + isTrash + '&RowID=' + rowID + '&MessageID=' + mailId);
    }
  }

  getSelectedSentMailData(isSentItem: boolean,mailId: string){
    let encryptedMailId = EncryptDecrypt.encryptUsingAES256(mailId);
    return this.http.get(environment.apiBaseURL + URLConstants.getSelectedSentMailData + isSentItem + '&MailId=' + encryptedMailId);
  }

  // Draft messages selected mail Details
  getDraftSelectedMail(mailId: string, isSentItem: boolean) {
      return this.http.get(environment.apiBaseURL + URLConstants.getDraftSelectedMail + mailId + '&IsSentItem=' + isSentItem);
  }

 //Get practice details of a draft when fromuserid not null
  getDraftPracticeDetails(UserID:string,MessageTypeID:number){
    return this.http.get(environment.apiBaseURL + URLConstants.getDraftPracticeDetails + UserID + '&MessageTypeID=' + MessageTypeID);
  }

  getDraftMessages(inboxDraftMessageID: string) {
    return this.http.get(environment.apiBaseURL + URLConstants.getDraftMessages + inboxDraftMessageID);
  }
  getAllArchiveMessages(userId: string) {
    return this.http.get(environment.apiBaseURL + URLConstants.getAllArchiveMessages + userId);
  }
  getSentMessages(userId: string) {
    return this.http.get(environment.apiBaseURL + URLConstants.getSentMessages + userId);
  }
  getAllGroupSentMessage(userId: string) {
    return this.http.get(environment.apiBaseURL + URLConstants.getAllGroupSentMessage + userId);
  }

  // Delete Draft message
  deleteDraftMessage(draftMessagID: string) {
    return this.http.delete(environment.apiBaseURL + URLConstants.deleteDraftMessage + draftMessagID);
  }

  deleteAllDraftMessage() {
    return this.http.post(environment.apiBaseURL + URLConstants.deleteAllDraftMessage, {});
  }
  deleteGroupMessage(groupMessageID: string, userID: string) {
    return this.http.post(environment.apiBaseURL + URLConstants.deleteGroupMessage + groupMessageID + '&UserID=' + userID, null);
  }
  getUnreadGroupMessages(userId: string) {
    return this.http.get(environment.apiBaseURL + URLConstants.getUnreadGroupMessages + userId)
  }
  getGroupMessageRecipient() {
    return this.http.get(environment.apiBaseURL + URLConstants.getGroupMessageRecipient);
  }
  GetAllUnreadMessage(userId: string) {
    return this.http.get(environment.apiBaseURL + URLConstants.getAllUnreadMessage + userId);
  }
  // GetUnReadMessageCountByUser(userId: string) {
  //   return this.http.post(environment.apiBaseURL + URLConstants.getUnReadMessageCountByUser + userId, null);
  // }
  GetAllReceivedMessageCount(userId: string) {
    return this.http.get(environment.apiBaseURL + URLConstants.getAllReceivedMessageCount + userId);
  }

  // Move to Inbox (Archive messages)
  MailRestoreFromTrash_old(trashId: string, isSentItem: boolean) {
    return this.http.post(environment.apiBaseURL + URLConstants.mailRestoreFromTrash + trashId + '&IsSentItem=' + isSentItem, null);
  }

  mailRestoreFromTrash(restoreObject: any) {
    return this.http.post(environment.apiBaseURL + URLConstants.mailRestoreFromTrash , restoreObject);
  }

  composeSendMessage(from: string, to: string, subject: string, body: string, messageCategoryID: number) {
    return this.http.post(environment.apiBaseURL + URLConstants.composeSendMessage + from + '&To=' + to + '&Subject=' + subject + '&Body=' + body + '&MessageCategoryID=' + messageCategoryID, null);
  }
  GetGroupMessages(userId: string, searchString: any, messageId: any) {
    let queryParams = userId;

    if (searchString != undefined && searchString != null && searchString != "") {
      queryParams += '&SearchString=' + searchString;
    }

    if (messageId != undefined && messageId != null && messageId != "") {
      let encryptedId = EncryptDecrypt.encryptUsingAES256(messageId);
      queryParams += '&messageId=' + encryptedId;
    }

    return this.http.get(environment.apiBaseURL + URLConstants.getGroupMessages + queryParams);
  }
  GetGroupMessageDetail(MessageID: string) {
    return this.http.get(environment.apiBaseURL + URLConstants.getGroupMessageDetail + MessageID);
  }
  GetGroupMessageUpdateReadStatus(groupMessageID: string, userID: string) {
    return this.http.get(environment.apiBaseURL + URLConstants.groupMessageUpdateReadStatus + groupMessageID + '&UserID='+userID);
  }
  getDraftMessageCount(userID: string) {
    return this.http.get(environment.apiBaseURL + URLConstants.getDraftMessageCount + userID);
  }
  groupMessageUnreadCount(userID: string) {
    return this.http.get(environment.apiBaseURL + URLConstants.getGroupMessageUnreadCount + userID);
  }



  getCount (userId:any): Observable<any[]>{
    const healthCenter = this.http.get(environment.apiBaseURL + URLConstants.getHealthCenterDetails + userId);
    const draftCount = this.http.get(environment.apiBaseURL + URLConstants.getDraftMessageCount + userId);
    const groupCount = this.http.get(environment.apiBaseURL + URLConstants.getGroupMessageUnreadCount + userId);
    return forkJoin([healthCenter,draftCount, groupCount]);
  }

  /* compose api urls */
  GetAllPracticeByUserNotetoGP(pUserId:string){
    return this.http.get(environment.apiBaseURL + URLConstants.GetAllPracticeUserNotetoGP + pUserId)
  }

  GetPatientPracticeForExtComm(LoggedInUserID:string){
    return this.http.get(environment.apiBaseURL + URLConstants.GetPatientPracticeExtComm + LoggedInUserID)
  }

  GetClinicalStaffExtComm(PracticeManagerID:string){
    return this.http.get(environment.apiBaseURL + URLConstants.GetClinicalStaffExtComm + PracticeManagerID);
  }
  getHealthCenterDetails(userId: string) {
    return this.http.get(environment.apiBaseURL + URLConstants.getHealthCenterDetails + userId);
  }
  GetPracticeLocations(PracticeManagerId: string) {
    // getPracticeLocations
    return this.http.get(environment.apiBaseURL + URLConstants.practiceLocationsByManagerId + PracticeManagerId);
  }
  GetServiceType(practiceAdminId: string, locationId: number, userId: string) {
    return this.http.get(environment.apiBaseURL + URLConstants.getServiceType + practiceAdminId + '&locationid=' + locationId + '&PUserId=' + userId);
  }
  GetAllRolesByPractice(PracticeId: string, UserId: string) {
    return this.http.get(environment.apiBaseURL + URLConstants.getAllRolesByPractice + PracticeId + '&UserId=' + UserId);
  }
  GetAllStaff(practiceManagerID: string, locationId: number, roleID: string) {
    return this.http.get(environment.apiBaseURL + URLConstants.getAllStaff + practiceManagerID + '&LocationId=' + locationId + '&RoleID=' + (roleID || "00000000-0000-0000-0000-000000000000"));
  }
  getAllUsersList(userId: string){
    return this.http.get(environment.apiBaseURL + URLConstants.getAllUsersList + userId);
  }
  // Compose new mail
  composeNewMail(sentMessageDeatils: any) {
    return this.http.post(environment.apiBaseURL + URLConstants.composeNewMail, sentMessageDeatils);
  }
  // Compose save draft message
  SaveDraftMessage(obj:any) {
    return this.http.post(environment.apiBaseURL + URLConstants.saveDraftMessage, obj);
  }

  addInboxMsgAdditionalInfo(additionalData: any) {
    return this.http.post(environment.apiBaseURL + URLConstants.addInboxMessageAdditionalInformation, additionalData);
  }

  getAllSearchedMessages(userId:string,SearchString	:string,sortExpression:string,StartRowIndex:number,MaximumRows:number) {
    return this.http.get(environment.apiBaseURL + URLConstants.getAllSearchedReceivedMessage + userId + '&SearchString	='+ SearchString	 + '&sortExpression='+ sortExpression + '&StartRowIndex='+ StartRowIndex + '&MaximumRows='+ MaximumRows)  ;
  }

  // Get all records for inbox
  getAllSearchedReceivedMessage(userId: string, sortExpression: string, StartRowIndex: number, MaximumRows: number, isSelected : boolean, searchString: string, messageId: any = '') {
    let queryParams = userId + '&sortExpression=' + sortExpression + '&StartRowIndex=' + StartRowIndex + '&MaximumRows=' + MaximumRows + '&isSelected=' + isSelected;

    if (searchString != undefined && searchString != null && searchString != "") {
      queryParams += '&SearchString=' + searchString;
    }

    if (messageId != undefined && messageId != null && messageId != "") {
      let encryptedId = EncryptDecrypt.encryptUsingAES256(messageId);
      queryParams += '&messageId=' + encryptedId;
    }

    return this.http.get(environment.apiBaseURL + URLConstants.getAllSearchedReceivedMessage + queryParams);
  }

  // Get all records for sent box
  getSentMessage(UserID: string, sortExpression: string, StartRowIndex: number, MaximumRows: number,isSelected : boolean, SearchString: string) {
    if (SearchString && SearchString != null && SearchString != "") {
      return this.http.get(environment.apiBaseURL + URLConstants.getSentMessage + UserID + '&sortExpression=' + sortExpression + '&StartRowIndex=' + StartRowIndex + '&MaximumRows=' + MaximumRows + '&isSelected=' + isSelected +'&SearchString	=' + SearchString);
    }
    else {
      return this.http.get(environment.apiBaseURL + URLConstants.getSentMessage + UserID + '&sortExpression=' + sortExpression + '&StartRowIndex=' + StartRowIndex + '&MaximumRows=' + MaximumRows + '&isSelected=' + isSelected);
    }
  }

  // Get all records for Archive message box
  getArchiveMessages(UserID: string, sortExpression: string, StartRowIndex: number, MaximumRows: number,isSelected : boolean, SearchString: string) {
    if (SearchString && SearchString != null && SearchString != "") {
      return this.http.get(environment.apiBaseURL + URLConstants.getArchiveMessages + UserID + '&sortExpression=' + sortExpression + '&StartRowIndex=' + StartRowIndex + '&MaximumRows=' + MaximumRows + '&isSelected=' + isSelected +'&SearchString	=' + SearchString);
    }
    else {
      return this.http.get(environment.apiBaseURL + URLConstants.getArchiveMessages + UserID + '&sortExpression=' + sortExpression + '&StartRowIndex=' + StartRowIndex + '&MaximumRows=' + MaximumRows + '&isSelected=' + isSelected);
    }
  }

  archiveCount(userId: string, SearchString: string) {
    let archiveCountURL = (SearchString && SearchString !== null && SearchString !== '') ? environment.apiBaseURL + URLConstants.getArchiveMessagesCount + userId +'&SearchString	=' + SearchString : environment.apiBaseURL + URLConstants.getArchiveMessagesCount + userId;
    return this.http.get(archiveCountURL);
  }

  // Get all records for Draft message box
  getDraftSearchMessage(UserID: string, sortExpression: string, StartRowIndex: number, MaximumRows: number,isSelected : boolean, SearchString: string) {
    if (SearchString && SearchString != null && SearchString != "") {
      return this.http.get(environment.apiBaseURL + URLConstants.getDraftSearchMessage + UserID + '&sortExpression=' + sortExpression + '&StartRowIndex=' + StartRowIndex + '&MaximumRows=' + MaximumRows +  '&isSelected=' + isSelected + '&SearchString	=' + SearchString);
    }
    else {
      return this.http.get(environment.apiBaseURL + URLConstants.getDraftSearchMessage + UserID + '&sortExpression=' + sortExpression + '&StartRowIndex=' + StartRowIndex + '&MaximumRows=' + MaximumRows + '&isSelected=' + isSelected);
    }
  }

    // mail  mark as read call for Inbox and sent box
    mailMarkAsRead(UserID :string,InboxId: any) {
      // return this.http.post<any>(`${environment.apiBaseURL}${URLConstants.mailMarkAsRead}${InboxId}`
      return this.http.post(environment.apiBaseURL + URLConstants.mailMarkAsRead + UserID +'&InboxId=' + InboxId, {});
    }

    // mail  mark as unread call for Inbox and sent box
    mailMarkAsUnread(inboxId: any) {
      return this.http.post<any>(environment.apiBaseURL + URLConstants.mailMarkedasUnread + inboxId, {
      })
    }

     // mail  mark as unread call with userid for Inbox and sent box
     mailMarkAsUnReadUserId(UserID :string,InboxId: any) {
      // return this.http.post<any>(`${environment.apiBaseURL}${URLConstants.mailMarkAsRead}${InboxId}`
      return this.http.post(environment.apiBaseURL + URLConstants.mailMarkAsUnreadUserId + UserID +'&InboxId=' + InboxId, {});
    }

    inboxCount(userId: string) {
      return this.http.post<any>(environment.apiBaseURL + URLConstants.inboxCount + userId, {
      })
    }

     // Upload Files (attachments)
     uploadFiles(filesdata:any){
       console.log(filesdata)
      let userId= this.tokenservice.userId()
      return this.http.post(environment.apiBaseURL + URLConstants.uploadFiles+ `${userId}`,filesdata);
     }

     // Download Files (attachments)
     downloadFiles(filenames:any){
      return this.http.get(environment.apiBaseURL + URLConstants.downloadFile + filenames);
     }

  userprofile(userId: string) {
    return this.http.get(environment.apiBaseURL + URLConstants.getUserProfile + '/' + userId);
  }

  // Patient registration template pdf
  generatePdfTemplate(formdata:any){
    console.log(formdata)
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/pdf')

    return this.http.post(environment.apiBaseURL + URLConstants.submitAndPreviewTemplatePdf,formdata, {headers:headers});
  }

  // Touser details while selecting a role patient
  getPatientListByLocality(nameType: string, staffID: string, FacilityID: string, suggestedText: string){
    if (suggestedText && suggestedText != null && suggestedText != "") {
    return this.http.get(environment.apiBaseURL + URLConstants.getPatientList + nameType + '&staffID=' + staffID + '&FacilityID=' + FacilityID + '&suggestedText=' + suggestedText);
    }
    else {
      return this.http.get(environment.apiBaseURL + URLConstants.getPatientList + nameType + '&staffID=' + staffID + '&FacilityID=' + FacilityID);
    }
  }

  //Group mesaages to recepient messages
  getGroupMessageReceipientCount(PracticeManagerId: string, RecipientGroupId: string, iFromAge: number, iToAge: number,sGenderPreference:string,StaffDetail:string){
    if (StaffDetail !=undefined && StaffDetail != null && StaffDetail != "") {
    return this.http.get(environment.apiBaseURL + URLConstants.getGroupMessageReceipientCount + PracticeManagerId + '&RecipientGroupId=' + RecipientGroupId + '&iFromAge=' + iFromAge + '&iToAge=' + iToAge + '&sGenderPreference=' + sGenderPreference + '&StaffDetail=' + StaffDetail);
    }
    else {
      return this.http.get(environment.apiBaseURL + URLConstants.getGroupMessageReceipientCount + PracticeManagerId + '&RecipientGroupId=' + RecipientGroupId + '&iFromAge=' + iFromAge + '&iToAge=' + iToAge + '&sGenderPreference=' + sGenderPreference);
    }
  }



  // pagination api's

  //  getRecievedMessagesPagination(UserID: string, page: number, pagesize: number) {
  //   return this.http.get(environment.apiBaseURL + URLConstants.getRecievedMessagesPagination + UserID + '&page='+ page + '&pagesize='+ pagesize);
  //
  //  getRecievedMessagesPagination(UserID: string, page: number, pagesize: number) {
  //   return this.http.get(environment.apiBaseURL + URLConstants.getRecievedMessagesPagination + UserID + '&page='+ page + '&pagesize='+ pagesize);
  // }

  //  getRecievedMessagesPagination(UserID: string, page: number, pagesize: number) {
  //   return this.http.get(environment.apiBaseURL + URLConstants.getRecievedMessagesPagination + UserID + '&page='+ page + '&pagesize='+ pagesize);
  // }

  getAllSentMessagePagination(UserId: string, page: number, pagesize: number) {
    return this.http.get(environment.apiBaseURL + URLConstants.getAllSentMessagePagination + UserId + '&page='+ page + '&pagesize='+ pagesize);
  }

  /* provider group messages api's */

  getFromDetails(userId : string) {
    return this.http.get(environment.apiBaseURL + URLConstants.getFromDetails + userId)
  }
  patientProvider(PracticeManagerId : string) {
    return this.http.get(environment.apiBaseURL + URLConstants.getPatientDefaultProviders + PracticeManagerId)
  }
  getToDetails() {
    return this.http.get(environment.apiBaseURL + URLConstants.getToDetails)
  }
  sendGroupMessageNew(sendMessage: any) {
    return this.http.post(environment.apiBaseURL + URLConstants.sendGroupMessage, sendMessage);
  }
  GetAllGroupSentMessage(userId: string) {
    return this.http.get(environment.apiBaseURL + URLConstants.GetAllGroupSentMessage + userId)
  }


  //get Payment reports for provider
   // Get all records for inbox
   getProviderPaymentReports(PracticeID: string, LocationCode: string, StartDate: string, EndTime: string, TransType : string,SearchValue: string) {
    if (SearchValue && SearchValue != null && SearchValue != "") {
      return this.http.get(environment.apiBaseURL + URLConstants.getProviderPaymentReports + PracticeID + '&LocationCode=' + LocationCode + '&StartDate=' + StartDate + '&EndTime=' + EndTime + '&TransType=' + TransType + '&SearchValue	=' + SearchValue);
    }
    else {
      return this.http.get(environment.apiBaseURL + URLConstants.getProviderPaymentReports + PracticeID + '&LocationCode=' + LocationCode + '&StartDate=' + StartDate + '&EndTime=' + EndTime + '&TransType=' + TransType);
    }
  }

  getCommonPractice(UserId: string,PatientId: string) {
    return this.http.get(environment.apiBaseURL + URLConstants.getCommonPractice + UserId + '&PatientId=' + PatientId);
  }

  // GetUnReadMessageCountByUser(userId: string) {
  //   if (!this.cacheUnReadMessageCount$) {
  //     this.cacheUnReadMessageCount$ =
  //       this.GetUnReadMessageCountByUserRequestAPI(userId).pipe(
  //         shareReplay(this.CACHE_SIZE)
  //       );
  //   }
  //   return this.cacheUnReadMessageCount$;
  // }

  getUnReadMessageCountByUserRequestAPI(userId: string) {
    return this.http.post(
      environment.apiBaseURL +
        URLConstants.getUnReadMessageCountByUser +
        userId,
      null
    );
  }

  //11-01-2023, Remove shareReplay as causing issues
  // getUserAndMenu(userId: string) {
  //   if (!this.cacheUserAndMenu$) {
  //     this.cacheUserAndMenu$ = this.getUserAndMenuAPI(userId).pipe(
  //       shareReplay(this.CACHE_SIZE)
  //     );
  //   }
  //   return this.cacheUserAndMenu$;
  // }

  getUserAndMenu(userId: string) {

      return this.http.get(
        environment.apiBaseURL + URLConstants.GetUserAndMenu + userId
      );


  }

  //11-01-2023, Remove shareReplay as causing issues
  // getHealthCenterDetails(userId: string) {
  //   if (!this.cacheHealthCenterDet$) {
  //     this.cacheHealthCenterDet$ = this.getHealthCenterDetailsRequestAPI(
  //       userId
  //     ).pipe(shareReplay(this.CACHE_SIZE));
  //   }
  //   return this.cacheHealthCenterDet$;
  // }

  // getHealthCenterDetails(userId: string) {
  //   return this.http.get(
  //     environment.apiBaseURL + URLConstants.getHealthCenterDetails + userId
  //   );
  // }

  getServiceTypeByBillable(messageTypeId: any) {
    let encryptedTypeId = EncryptDecrypt.encryptUsingAES256(messageTypeId);
    return this.http.get(environment.apiBaseURL + URLConstants.getServiceTypeByBillable + encryptedTypeId);
  }

  getRecallRemainder() {
    return this.http.post(environment.apiBaseURL + URLConstants.getRecallRemainder, null);
  }
  acnowledgementRecallRemainder(payload: any) {
    const params = new URLSearchParams(payload).toString();
    return this.http.post(environment.apiBaseURL + URLConstants.acnowledgementRecallRemainder+ '?' + params, '');
  }


  getActivateNextHealtCentre(payload: any) {
    const params = new URLSearchParams(payload).toString();
    return this.http.post(environment.apiBaseURL + URLConstants.getActivateNextHealtCentre + '?' + params, null);
  }

  checkCurrentDayAvilable(WorkDaysOutOfOffice: any) {
    // Current day
      const currentDay = moment().format('ddd');
      // Check if current day is available
      const isCurrentDayAvailable = WorkDaysOutOfOffice?.includes(`|${currentDay}|`);
      return isCurrentDayAvailable;
  }

  checkCurrentTimeAvailable(StartTimeOutOfOffice: any, EndTimeOutOfOffice: any) {
        // Current time
        const currentTime = moment().format('hh:mm A');
        // Check if current time is between StartTimeOutOfOffice and EndTimeOutOfOffice
        const isCurrentTimeInRange = moment(currentTime, 'hh:mm A').isBetween(moment(StartTimeOutOfOffice, 'hh:mm A'), moment(EndTimeOutOfOffice, 'hh:mm A'), null, '[]');

        return isCurrentTimeInRange;
  }

  convertDays(WorkDaysOutOfOffice: any) {
    // Custom sorting order for the days of the week
    const dayOrder = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

    // Extract day names from the WorkDaysOutOfOffice string
    const dayNames = WorkDaysOutOfOffice.split('|').filter((day: any) => day !== "");

    // Sort the day names based on the custom order
    dayNames.sort((a: any, b: any) => dayOrder.indexOf(a) - dayOrder.indexOf(b));

    // Check if the selected working days represent Monday to Friday
    if (
      dayNames.length === 5 &&
      dayNames[0] === 'Mon' &&
      dayNames[1] === 'Tue' &&
      dayNames[2] === 'Wed' &&
      dayNames[3] === 'Thu' &&
      dayNames[4] === 'Fri'
    ) {
      return "Monday to Friday";
    }

    // Function to join days with proper grammar
    function joinWithAnd(days: string[]) {
      if (days.length === 0) {
        return "";
      }
      if (days.length === 1) {
        return days[0];
      }
      if (days.length === 2) {
        return days.join(" and ");
      } else {
        return `${days.slice(0, -1).join(", ")} and ${days[days.length - 1]}`;
      }
    }

    // Generate the final result
    const finalResult = joinWithAnd(dayNames.map((day: any) => moment().day(day).format('dddd')));

    return finalResult;
  }


  replaceImgTags(html: string): string {
    // Use a regular expression to replace <IMG> with <img> globally
    return html.replace(/&lt;IMG/g, '<img');
  }

}

