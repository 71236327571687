import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appSpecialCharacterRemover]'
})
export class SpecialCharacterRemoverDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInput(event: any) {
    const inputElement = this.el.nativeElement;
    const originalValue = inputElement.value;
    const sanitizedValue = originalValue.replace(/[^a-zA-Z0-9]/g, '');

    if (originalValue !== sanitizedValue) {
      inputElement.value = sanitizedValue;
      inputElement.dispatchEvent(new Event('input'));
    }
  }
}
