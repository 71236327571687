<mat-nav-list appAccordion class="hide-print">

    <mat-list-item appAccordionLink *ngFor="let menuitem of menu" routerLinkActive="selected" group="{{menuitem.state}}"
        (click)="scrollToTop()" class="side-nav-bar" [class.btb-nav-bar]="menuitem.MainMenu == 'Beating the Blues'">

        <a class="menu-header" appAccordionToggle href="javascript:;"
            *ngIf="menuitem.Children.length > 1 || (menuitem.MainMenu == 'Setup Practice') || (menuitem.MainMenu == 'Health Tools') || (menuitem.MainMenu == 'Setup Modules') || (menuitem.MainMenu == 'Reporting') || (menuitem.MainMenu == 'Linked Accounts') || (menuitem.MainMenu == 'Patient Portal Reports')"
            routerLinkActive="selected"
             >
            <i *ngIf="menuitem.routeIcons != 'icon-cent-btb-logo'" class="{{menuitem.routeIcons ? menuitem.routeIcons : 'icon-home-outline' }}"></i>
            <img *ngIf="menuitem.routeIcons == 'icon-cent-btb-logo'" alt="" class="gray-out" src="../assets/images/Icons/btb-icon-logo.svg">
            <span>{{ menuitem.MainMenu | translate }} </span>
            <span fxFlex></span>

            <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
        </a>

        <a [routerLink]="[menuitem.Children[0].routeUrl]" class="test-anchor" routerLinkActive="selected"
            (touchstart)="onTouchStart()" (touchmove)="onTouchMove()"
            (touchend)="onTouchEnd(menuitem.Children[0].routeUrl)"
            (click)="handleClick(menuitem.Children[0].routeUrl, menuitem.Children[0].routeIcons)"
            *ngIf="menuitem.Children.length == 1 && (menuitem.MainMenu != 'Setup Practice') && (menuitem.MainMenu != 'Health Tools') && (menuitem.MainMenu != 'Setup Modules') && (menuitem.MainMenu != 'Reporting') && (menuitem.MainMenu != 'Linked Accounts') && (menuitem.MainMenu != 'Patient Portal Reports')"
            class="menu-header">
            <!-- <img src="{{menuitem.icon}}"> -->
            <i *ngIf="menuitem.Children[0].routeIcons != 'icon-cent-discover-health' && menuitem.Children[0].routeIcons != 'icon-cent-btb-logo'&& menuitem.Children[0].routeIcons != 'icon-cent-healthassessment'" class="{{menuitem.Children[0].routeIcons ? menuitem.Children[0].routeIcons : 'icon-home-outline' }}"></i>
            <img *ngIf="menuitem.Children[0].routeIcons == 'icon-cent-btb-logo'" alt="" class="gray-out" src="../assets/images/Icons/btb-icon-logo.svg">            
            <div class="box" *ngIf="menuitem.Children[0].routeIcons == 'icon-cent-discover-health'">
                <div class="badge">
                  <p class="text">new</p>
                </div>
                <img  class="gray-out" src="assets/images/Icons/new-discover-health.svg">
            </div>
            <img *ngIf="menuitem.Children[0].routeIcons == 'icon-cent-healthassessment'" alt="" class="gray-out" src="assets/images/Icons/HealthAssessment.svg">  
            <span>{{ menuitem.MainMenu | translate }}</span>
            <span fxFlex></span>
        </a>

        <mat-nav-list class="sub-item"
            *ngIf="menuitem.Children.length > 1 || (menuitem.MainMenu == 'Setup Practice') || (menuitem.MainMenu == 'Health Tools') || (menuitem.MainMenu == 'Reporting') || (menuitem.MainMenu == 'Setup Modules') || (menuitem.MainMenu == 'Linked Accounts') || (menuitem.MainMenu == 'Patient Portal Reports')">
            <mat-list-item *ngFor="let childitem of menuitem.Children; let j=index; let i = childitem"
                routerLinkActive="selected">
                <a [routerLink]="[childitem.routeUrl]"  class="test-anchor" routerLinkActive="selected"
                    (click)="itemSelect[i]=j"
                    (touchstart)="onTouchStartChild()" (touchmove)="onTouchMoveChild()"
                    (touchend)="onTouchEndChild(childitem.routeUrl)"
                    (click)="handleClick(childitem.routeUrl, childitem.routeIcons)"
                    *ngIf="childitem.IsV2FeatureEnabled==true">
                    <!-- <img src="{{childitem.iconUrl}}"> -->

                    <!-- <i class="({{childitem.routeIcons}} !=null||{{childitem.routeIcons}} !='') ? {{childitem.routeIcons}}  : icon-home-outline"></i> -->

                    <i *ngIf="childitem.routeIcons != 'icon-cent-referral'&& childitem.routeIcons != 'icon-cent-report'&& childitem.routeIcons != 'icon-cent-Clinician-Notes'&& 
                    childitem.routeIcons != 'icon-cent-Visits'&& childitem.routeIcons != 'icon-cent-addpatient'" class="{{childitem.routeIcons? childitem.routeIcons : 'icon-home-outline' }}"></i>
                    <img *ngIf="childitem.routeIcons == 'icon-cent-referral' && activeIcon == 'icon-cent-referral'" alt="" class="" src="assets/images/Icons/Referrals.svg">
                    <img *ngIf="childitem.routeIcons == 'icon-cent-referral' && activeIcon != 'icon-cent-referral'" alt="" class="" src="assets/images/Icons/Referrals-inactive.svg">
                    
                    <img *ngIf="childitem.routeIcons == 'icon-cent-report' && activeIcon == 'icon-cent-report'" alt="" class="" src="assets/images/Icons/lab-report.svg">
                    <img *ngIf="childitem.routeIcons == 'icon-cent-report' && activeIcon != 'icon-cent-report'" alt="" class="" src="assets/images/Icons/lab-report.svg">
                    
                    <img *ngIf="childitem.routeIcons == 'icon-cent-Clinician-Notes' && activeIcon == 'icon-cent-Clinician-Notes'" alt="" class="" src="assets/images/Icons/Clinical-Notes.svg">
                    <img *ngIf="childitem.routeIcons == 'icon-cent-Clinician-Notes' && activeIcon != 'icon-cent-Clinician-Notes'" alt="" class="" src="assets/images/Icons/Clinical-Notes-Inactive.svg">

                    <img *ngIf="childitem.routeIcons == 'icon-cent-Visits' && activeIcon == 'icon-cent-Visits'" alt="" class="" src="assets/images/Icons/Visits.svg">
                    <img *ngIf="childitem.routeIcons == 'icon-cent-Visits' && activeIcon != 'icon-cent-Visits'" alt="" class="" src="assets/images/Icons/Visits-Inactive.svg">

                    <img *ngIf="childitem.routeIcons == 'icon-cent-addpatient' && activeIcon == 'icon-cent-addpatient'" alt="" class="" src="assets/images/Icons/account-plus.svg">
                    <img *ngIf="childitem.routeIcons == 'icon-cent-addpatient' && activeIcon != 'icon-cent-addpatient'" alt="" class="" src="assets/images/Icons/account-plus-inactive.svg">
                    <span  [innerHTML]="childitem.SubMenu" translate> </span>
                    <span fxFlex></span>

                </a>
            </mat-list-item>
        </mat-nav-list>
    </mat-list-item>
</mat-nav-list>
<!-- #region Project Version-->
<!--Project version is displayed at the bottom of the sidebar view-->
<div *ngIf="userId!=null" class="hide-print">
    <mat-card>
        <mat-card-content>
            <div class="appVersion">
                <small>Version {{appVersion}}</small>
            </div>
            <div>
                <!-- <div style="bottom: 20px; position: fixed">  -->
                <div [id]="'placement_' + leftHandAdbutlerZoneIds?.mohMainZoneId"></div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
<!-- #endregion -->
