import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.scss']
})
export class PasswordStrengthComponent implements OnInit {
  @Input() Password: string;
  @Input() design: string = '2';

  isCapital: boolean = false;
  isSmall : boolean = false;
  isNumber : boolean = false;
  isSpecial : boolean = false;
  passwordStrength = 'Password';
  isSpaceChk: boolean = false;
  validPwd: boolean = false;

  constructor(private translate: TranslateService) {

  }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.checkPasswordStrength()
  }

  checkPasswordStrength(): void {
    const length = this.Password.length;
    this.isCapital = /[A-Z]/.test(this.Password);
    this.isSmall = /[a-z]/.test(this.Password);
    this.isNumber = /[0-9]/.test(this.Password);
    // this.isSpecial = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(this.Password);
    this.isSpecial = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~`]/.test(this.Password);
    this.isSpaceChk = /\s/g.test(this.Password);


    if (length < 5) {
      this.passwordStrength = 'Poor';
    } else if (length <= 8) {
      this.passwordStrength = 'Average';
    } else if (this.isCapital && this.isSmall && this.isNumber && this.isSpecial) {
      this.passwordStrength = 'Excellent';
    } else {
      this.passwordStrength = 'Password';
    }

    this.validPwd = (length >= 10 && this.isCapital &&  this.isSmall &&  this.isNumber &&  this.isSpecial &&  !this.isSpaceChk) ? true : false
  }




}
