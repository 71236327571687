import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-recall-remainder-dialog',
  templateUrl: './recall-remainder-dialog.component.html',
  styleUrls: ['./recall-remainder-dialog.component.scss']
})
export class RecallRemainderDialogComponent implements OnInit {

  constructor(public router: Router , public dialogRef: MatDialogRef<RecallRemainderDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
   }

  ngOnInit(): void {
    console.log(this.data);
  }


  btnClick(from: string) {

    const payload = {
      RecallReminderID: this.data[2],
      PatientNote: 'Read',
      AckFor: (from == 'appointment') ? 'BA' : 'OK'
    }
    this.onClose(payload);
    if(from == 'appointment') {
      this.router.navigate(['/appointment/myappointments/bookappointment']);
    }
  }


  onClose(payload: any) {
    this.dialogRef.close(payload);
  }
}
