import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { URLConstants } from 'src/app/core/constants/urlConstants';
import { applicationHttpClientCreator } from 'src/app/data/interface/IRequestOptions';
import { ToasterService } from 'src/app/data/service/toaster.service';
import { TokenService } from 'src/app/data/service/token.service';
import { IHomeSettings } from 'src/app/data/interface/IHomeSettings';
import * as moment from 'moment';
import { first } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/data/service/authentication.service';
import { DateAdapter } from '@angular/material/core';
import { AppService } from 'src/app/data/service/app.service';
import { CommonConstants } from 'src/app/core/constants/commonConstants';
import { PopupSettings } from '@progress/kendo-angular-dateinputs';
import { EncryptDecrypt } from 'src/app/core/helper/encrypt-decrypt';
import { EditorComponent } from '@progress/kendo-angular-editor';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  userSettings: IHomeSettings[] = [];
  sessionPanelOpenState = false;
  emailPanelOpenState = false;
  signaturePanelOpenState = false;
  outOfOfficePanelOpenState = false;
  autoReplyPanelOpenState = false;
  minDate = new Date();
  settingForm: FormGroup;
  profileData: any;
  userMailId: string;
  lblUserMailId: string;
  hideBroswerSettings: boolean = false;
  previousSessionTimedOut: number;
  outOfOfficeFromDate: Date;
  outOfOfficeToDate: Date;
  navLinks: any[];
  healthCenterList: any;
  userId: string;
  profileName: any;
  @ViewChild('container', { read: ViewContainerRef }) public containerRef: ViewContainerRef;
  @ViewChild('container2', { read: ViewContainerRef }) public containerRef2: ViewContainerRef;
  @ViewChild('kendoEditor') kendoEditor: EditorComponent;
  
  constructor(public translate: TranslateService, public fb: FormBuilder, private route: ActivatedRoute, 
    private http: HttpClient,
    public toaster: ToasterService,
    private router: Router,
    private tokenService: TokenService,
    private authenticationService: AuthenticationService,
    private dateAdapter: DateAdapter<Date>,
    private appService: AppService) {
    this.dateAdapter.setLocale('en-GB');
  }

  ngOnInit(): void {
    this.settingForm = this.fb.group({
      alertSetting: new FormControl(null),
      signatureSetting: new FormControl(null),
      enableOutOfOfficeReply: new FormControl(null),
      outOfOfficeFromDate: new FormControl(null),
      outOfOfficeToDate: new FormControl(null),
      outOfOfficeText: new FormControl(null),
      enableAutomaticReplies: new FormControl(null),
      automaticReplyText: new FormControl(null),
      sessionSettings: new FormControl('20'),
      browserSettings: new FormControl(null)
    });

    let CarerEmail = sessionStorage.getItem('CarerEmail');
    if (CarerEmail && CarerEmail != '') {
      CarerEmail = EncryptDecrypt.decryptUsingAES256(CarerEmail);
    }

    let ChildAccountApproved = sessionStorage.getItem('ChildAccountApproved');
    if (ChildAccountApproved && ChildAccountApproved != '') {
      ChildAccountApproved = EncryptDecrypt.decryptUsingAES256(ChildAccountApproved);
    }

    this.userId = this.tokenService.userId();
    this.authenticationService.getUserProfile(this.userId).subscribe((data: any) => {
      // console.log('subscribe ....', data);
      this.profileData = data;
      this.userMailId = this.profileData ? this.profileData.Email : '';

      if (CarerEmail != '' && CarerEmail != null && (ChildAccountApproved == 'false' || ChildAccountApproved == null)) {
        this.lblUserMailId = CarerEmail;
      }
      else {
        this.lblUserMailId = this.userMailId;
      }

      this.getUserSettings();
    });
  }

  getUserSettings = () => {
    applicationHttpClientCreator(this.http).Get(`${URLConstants.getHomeUserSettings}${this.userId}&email=` + this.userMailId, {
    }).subscribe((data: any) => {
      this.userSettings = data;
      console.log('Settings', this.userSettings);
      this.settingForm.patchValue({
        alertSetting: this.userSettings[0].OptForInboxMessageAlert === true ? '1' : '2',
        signatureSetting: this.userSettings[0].Signature,
        enableOutOfOfficeReply: this.userSettings[0].isOutOfOfficeEnabled,
        outOfOfficeFromDate: this.userSettings[0].OutOFOfficeFromDt,
        outOfOfficeToDate: this.userSettings[0].OutOfOfficeEndDt,
        outOfOfficeText: this.userSettings[0].OutOfOfficeMessage,
        enableAutomaticReplies: this.userSettings[0].isAutoReplyEnable,
        automaticReplyText: this.userSettings[0].AutoReplyMessage,
        sessionSettings: this.userSettings[0].JwtSessionValue && this.userSettings[0].JwtSessionValue != 0 ? JSON.stringify(this.userSettings[0].JwtSessionValue) : JSON.stringify(CommonConstants.sessionDefaultTime),
        browserSettings: this.userSettings[0].JWTTokenBrowserSession == true ? '1' : '2'
      });
      this.outOfOfficeFromDate = new Date(this.userSettings[0]?.OutOFOfficeFromDt);
      this.outOfOfficeToDate = new Date(this.userSettings[0]?.OutOfOfficeEndDt);
      //set previous session timedout
      this.previousSessionTimedOut = (this.userSettings[0].JwtSessionValue) ? this.userSettings[0].JwtSessionValue : CommonConstants.sessionDefaultTime;

    }, (err) => {
      console.log(err);
      return null;
    });
  }
  submitSettings = () => {
    const fromDate: string = (this.outOfOfficeFromDate
      && this.outOfOfficeFromDate != null) ?
      moment(new Date(this.outOfOfficeFromDate)).format("YYYY-MM-DD HH:mm:ss a").toString()
      : moment(new Date()).format("YYYY-MM-DD HH:mm:ss a").toString();
    const toDate: string = (this.outOfOfficeToDate
      && this.outOfOfficeToDate != null)
      ? moment(new Date(this.outOfOfficeToDate)).format("YYYY-MM-DD HH:mm:ss a").toString()
      : moment(new Date()).format("YYYY-MM-DD HH:mm:ss a").toString();
    // const userId = this.tokenService.userId();
    applicationHttpClientCreator(this.http).Post(URLConstants.updateHomeUserSettings, {
      UserId: this.userId,
      OptForInboxMessageAlert: this.settingForm.value.alertSetting === '1' ? true : false,
      Signature: this.settingForm.value.signatureSetting ? this.settingForm.value.signatureSetting : '',
      isOutOfOfficeEnabled: this.settingForm.value.enableOutOfOfficeReply ? this.settingForm.value.enableOutOfOfficeReply : false,
      OutOfOfficeMessage: this.settingForm.value.outOfOfficeText ? this.settingForm.value.outOfOfficeText : '',
      OutOFOfficeFromDt: fromDate,
      OutOFOfficeEndDt: toDate,
      isAutoReplyEnable: this.settingForm.value.enableAutomaticReplies ? this.settingForm.value.enableAutomaticReplies : false,
      AutoReplyMessage: this.settingForm.value.automaticReplyText ? this.settingForm.value.automaticReplyText : '',
      UserEmail: this.userMailId,
      JwtSessionValue: this.settingForm.value.sessionSettings ? this.settingForm.value.sessionSettings : JSON.stringify(CommonConstants.sessionDefaultTime),
      JWTTokenBrowserSession: this.settingForm.value.browserSettings === '1' ? true : false
    })
      .pipe(first())
      .subscribe(
        (data: any) => {
          if (data) {
            //set current session timedout settings
            let sessionTimedout = (this.settingForm.value.sessionSettings) ? parseInt(this.settingForm.value.sessionSettings) : CommonConstants.sessionDefaultTime;
            this.closeAllPanels();
            this.getUserSettings();
            this.toaster.success(this.translate.instant('User settings updated successfully'));
            //Check whether session timedout changed or not
            if (this.previousSessionTimedOut !== sessionTimedout) {
              //set new session timedout for session idle and refresh token
              sessionStorage.setItem('expiryTime', this.settingForm.value.sessionSettings);
              this.appService.setUserLoggedIn(true, this.settingForm.value.sessionSettings);
              this.appService.setUserTimedOut(true, this.settingForm.value.sessionSettings);
            }
          }
          else {
            this.toaster.warning(this.translate.instant('No changes for updating user settings'));
          }
        },
        (error: any) => {
          console.log(error);
          this.toaster.error(error.error.Message);
        });
  }

  cancelSettings = () => {
    this.closeAllPanels();
    this.getUserSettings();
  }

  closeAllPanels = () => {
    this.sessionPanelOpenState = false;
    this.emailPanelOpenState = false;
    this.signaturePanelOpenState = false;
    this.outOfOfficePanelOpenState = false;
    this.autoReplyPanelOpenState = false;
  }
  public popupSettings: PopupSettings = {
    appendTo: "component"
  };

  backToInbox = () => {
    this.router.navigate(['/pages/navigate-routes/Inbox']);
  }


  handlePaste(event: ClipboardEvent) {
    console.log('pasttttttttttttttttt')
    const clipboardData = event.clipboardData;
  
    if (clipboardData) {
      const items = Array.from(clipboardData.items);
  
      for (const item of items) {
        if (item.type.indexOf('image') !== -1) {
          const file = item.getAsFile();
 
          if (file) {
            // Check the size of the image
            if (file.size > 1 * 1024 * 1024) { // 1MB limit
              event.preventDefault();
              console.error('Image size exceeds 1MB');
              this.toaster.error('Image size exceeds 1MB');
              // Display an error message in your application
              return;
            }
  
            // Create an image element to check its dimensions
            const img = new Image();
  
            img.onload = () => {
              // Check the width and height of the image
              if (img.width < 200 || img.height < 100) { // Adjust the size limit as needed
                event.preventDefault();
                console.error('Image dimensions less or equel 200px width or 100px height. Image is  pasted.');
                // Display an error message in your application
              } else {
                // Resize the image to a maximum of 100x100 pixels (adjust as needed)
                const canvas = document.createElement('canvas');
                canvas.width = 200;
                canvas.height = 100;
                const ctx = canvas.getContext('2d');
                ctx?.drawImage(img, 0, 0, 200, 100);
  
                // Convert the resized image to a data URL
                const resizedImageDataUrl = canvas.toDataURL(file.type);
  
                // Clear the editor content before inserting the resized image
                // this.kendoEditor.value = '';
                
                // Insert the resized image into the editor
                this.kendoEditor.exec('insertImage', {
                  src: resizedImageDataUrl,
                  alt: 'Resized Image',
                });

                this.removeLargeImages();
              }
            };
  
            // Set the image source to the file URL
            img.src = URL.createObjectURL(file);
          }
        }
      }
    }
  }
  
  

removeLargeImages() {
  
  setTimeout(() => {
    // Parse the editor content to find and remove large images
  const parser = new DOMParser();
  const doc = parser.parseFromString(this.settingForm.value.signatureSetting, 'text/html');
     // Update the message field with the modified HTML
     const images = doc.querySelectorAll('img');

  images.forEach((img) => {
    this.checkImageSizeAndRemove(img);
  });
  setTimeout(() => {
    this.settingForm.patchValue({ signatureSetting: doc.body.innerHTML });
  }, 140);
  }, 400);

}

checkImageSizeAndRemove(img: HTMLImageElement) {
  const reader = new FileReader();
  const imageSrc = img.src;

  if (imageSrc && (imageSrc.startsWith('https://') || imageSrc.startsWith('http://'))) {
    img.remove();
  } else {
  reader.onload = (e) => {
    const dataURL = e.target?.result as string;
    // Create a new Image object to get the image dimensions
    const tempImage = new Image();
    tempImage.src = imageSrc;
    tempImage.onload = () => {
      const fileSize = this.getDataURLSize(dataURL); // Get size of data URL in bytes
      const imageWidth = tempImage.width; // Image width in pixels
      const imageHeight = tempImage.height; // Image height in pixels 
      if ((fileSize > 1000000 || fileSize == 0 || imageWidth > 200 || imageHeight > 100)) {
        // Image is larger than 1MB or has width/height greater than 100px, remove it
        img.remove();
      }
    };
  }; 

  reader.readAsDataURL(this.dataURLtoBlob(imageSrc));
}
}


getDataURLSize(dataURL: string): number {
  const base64String = dataURL.split(',')[1];
  const padding = (base64String.length % 4 === 0) ? 0 : (4 - base64String.length % 4);
  const fileSize = (base64String.length + padding) / 4 * 3;
  return fileSize;
}

dataURLtoBlob(dataURL: string): any {
  const parts = dataURL.split(',');
  const mime = parts?.[0]?.match(/:(.*?);/)?.[1];
  const b64Data = parts[1];
  if(b64Data) {
    const byteCharacters = atob(b64Data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mime });
  } else  {
    return new Blob();
  }


  
}

}
