<div class="content-panel p-0 dialog-box">
  <div class="p-l-20">
    <!--   <p>1.{{'photoUploadBrowse'| translate}} </p>
    <p>2.{{'photoUploadImage'| translate}}</p>
    <p>3.{{'squareBoxDisplay'| translate}}</p>
    <p>4.{{'photoCrop'| translate}}</p>
    <p>5.{{'photoSave'| translate}}</p> -->

  </div>
  <!-- <p>{{'noteTab'| translate}}</p> -->

  <div class="upload-choosefile m-b-10">



    <label class="label-button-primary" for="selectPhoto">Choose File</label>


    <input id="selectPhoto" class="file-upload-btn m-l-20 appearance-none" accept="{{fileTypes}}" placeholder="file"
      type="file" (change)="onSelectPhoto($event)" [ngStyle]="{display:croppedImage?'block':'none'}">
    <!-- <div *ngIf="!isValid">
      {{validateMsg}}
    </div> -->
   
    


    <!-- Image Crop -->

    <!-- <br />
      <br />
      <button (click)="rotateLeft()">Rotate left</button>
      <button (click)="rotateRight()">Rotate right</button>
      <button (click)="flipHorizontal()">Flip horizontal</button>
      <button (click)="flipVertical()">Flip vertical</button>
      <br />
      <br />
      <button (click)="toggleContainWithinAspectRatio()">{{containWithinAspectRatio?'Fill Aspect Ratio':'Contain Within Aspect
        Ratio'}}</button>
      <button (click)="resetImage()">Reset image</button>
      <br />
      <br /> -->
    <!-- <input [(ngModel)]="rotation" placeholder="Rotation" type="number" (keyup)="updateRotation()" /> <button
        (click)="zoomOut()">Zoom -</button> <button (click)="zoomIn()">Zoom +</button> -->

    <!-- Image Crop End -->


  </div>

  <div>
    <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
      [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="4 / 3" [resizeToWidth]="256"
      [cropperMinWidth]="128" [onlyScaleDown]="true" [roundCropper]="false" [canvasRotation]="canvasRotation"
      [transform]="transform" [alignImage]="'left'" [style.display]="showCropper ? null : 'none'" format="png"
      (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)"
      (loadImageFailed)="loadImageFailed()"></image-cropper>
  </div>
  <img [src]="croppedImage" [style.border]="croppedImage ? '1px solid black' : 'none'" />
</div>