import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MailBoxService } from 'src/app/data/service/mail-box.service';
import { InBoxService } from 'src/app/data/service/inbox.service';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MovetoinboxDialogComponent } from '../movetoinbox-dialog/movetoinbox-dialog.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ToasterService } from 'src/app/data/service/toaster.service';
import { TokenService } from 'src/app/data/service/token.service';
import { URLConstants } from 'src/app/core/constants/urlConstants';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { PerfectScrollbarComponent, PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { Alert } from 'selenium-webdriver';
import { DashboardServiceData } from 'src/app/core/constants/app-enums';
import { CommonConstants } from 'src/app/core/constants/commonConstants';
import { CommonService } from 'src/app/data/service/common.service';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};

@Component({
  selector: 'app-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.scss']
})
export class ArchiveComponent implements OnInit {
  imgSrc1: string = 'assets/images/Group 5014.svg';
  imgSrc2: string = "assets/images/Group 5012 (1).svg";
  imgSrc3: string = "assets/images/Group 4991.svg";
  imgSrc4: string = "assets/images/icons-text/Group 5079 (1).svg";

  openSidebar: boolean = true;
  searchText: any;
  checkboxForm: FormGroup;
  subscription: Subscription;
  public searchkeyvalue: any;
  selected = 'compose';
  searchTexts: any;
  public openedArchiveDialouge: boolean = false;
  selectedMailType: any;
  public archiveData: any = [];
  public userId: any;
  public messageId: string;
  allComplete: boolean = false;
  page: number = 0;
  pagesize: number = 20;
  public profileObject: any = [];
  selectedcheckbox: any = [];
  selectedArchiveItems: any = [];
  public inboxCount: any = [];
  public archiveSelectedDetails: boolean = false;
  public selectedMessagesDetails: any = [];
  public xmltoJSON: any;
  public filesData: any = [];
  public getPracticeData: any = [];
  listInboxId: any = [];
  selectInputValue: any = {};
  selectCheckEvent: boolean = false;
  pageNumber: number = 0;
  downloadURL = URLConstants.downloadFile_Post;
  isPostDownload = true;
  public config: PerfectScrollbarConfigInterface = {};
  public SelectedMail: any;
  public showDetail: boolean = false;
  public nomessages:any;
  public title:any = "thank you every one";
  public searchemptymsg:boolean=false;
  isLoading = true;
  isSearched: boolean = false;
  isScrolled: boolean = false;
  ismailSelected: boolean = false;


  constructor(private router: Router,
    public mailboxServie: MailBoxService,
    public deletedialog: MatDialog,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder,
    public tokenService: TokenService,
    private toaster: ToasterService,
    public translate: TranslateService,
    public http: HttpClient,
    public inBoxService: InBoxService,
    public commonService: CommonService) {
    this.subscription = this.mailboxServie.onMessage().subscribe(message => {
      if (message) {
        this.searchkeyvalue = message.text
        this.searchText = this.searchkeyvalue
        // this.searchemptymsg =true;
      } else {
        // clear messages when empty message received
        this.searchkeyvalue = "";
        this.searchText = '';
        this.searchemptymsg =false;
      }
      this.archiveSelectedDetails = false;
      this.SelectedMail = false;
      this.refresh();
    });
  }
  ngOnInit(): void {
    this.userId = this.tokenService.userId();
    this.profileObject = this.tokenService.userProfile();
    this.mailboxServie.archiveData = [];
    this.getArchiveMessageCount();
    this.getArchiveMessage();

    this.checkboxForm = this.fb.group({
      checkboxChecked: [''],
      selectedCheckbox: ['']
    })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  totalArchiveCount = 0;
  /* get archive messages */
  getArchiveMessageCount() {
    this.inBoxService.archiveCount(this.userId, '').subscribe((data: any) => {
      this.totalArchiveCount = data ? data : 0;
      console.log('ArchiveCount', this.totalArchiveCount)
    })
  }

  getArchiveMessage() {
    this.nomessages=false;
    this.isLoading = true;
    this.inBoxService.getArchiveMessages(this.userId, 'SentAndReceiveDate desc', this.page, this.pagesize, false, this.searchText).subscribe((data: any) => {
      // for (var i = 0; i < data.length; i++) {
      //   let datediff = this.calculateDateDiff(data[i].SentAndReceiveDate);
      //   let diffResult = datediff[0] != 0 ? datediff[0] + " year" : datediff[1] != 0 ? datediff[1] + " month" : datediff[2] != 0 ? datediff[2] + " day" : datediff[3] != 0 ? datediff[3] + " hour" : datediff[4] != 0 ? datediff[4] + " min" : datediff[5] + " sec";
      //   data[i].SentAndReceiveDate = diffResult;
      // }
      this.searchemptymsg =false;
      console.log(this.searchTexts);
      console.log(this.searchkeyvalue);
      
      if (this.searchTexts?.length || this.searchkeyvalue?.length) {
       // this.mailboxServie.archiveData = [];
       if (this.isScrolled) {
        this.mailboxServie.archiveData.push(...data);
       } else {
        this.mailboxServie.archiveData = [];
        this.mailboxServie.archiveData = data;
       }
       this.isSearched = false;
      } else {
        this.mailboxServie.archiveData.push(...data);
      }
      console.log(this.mailboxServie.archiveData);
      this.isLoading = false;
      if (this.mailboxServie.archiveData.length==0){
        this.nomessages=true;
        this.searchemptymsg =true;
      }else{
        this.searchemptymsg =false;
      }
    }, (err: any) => {
      this.isLoading = false;
    })

    // if (this.mailboxServie.archiveData.length==0){
    //   this.nomessages=true;
    // }
  }
  calculateDateDiff = (dateOfReceived: any) => {

    var startDate = new Date(dateOfReceived);
    // user not pass endingDate then set current date as end date.
    let endingDate = new Date();

    let endDate = new Date(endingDate);
    // chack start date and end date and base on condication alter date.
    if (startDate > endDate) {
      var swap = startDate;
      startDate = endDate;
      endDate = swap;
    }
    // This is for leap year.
    const startYear = startDate.getFullYear();
    const february =
      (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0
        ? 29
        : 28;
    const daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    let yearDiff = endDate.getFullYear() - startYear;
    let monthDiff = endDate.getMonth() - startDate.getMonth();
    if (monthDiff < 0) {
      yearDiff--;
      monthDiff += 12;
    }
    let dayDiff = endDate.getDate() - startDate.getDate();
    let hourDiff = endDate.getHours() - startDate.getHours();
    let minuteDiff = endDate.getMinutes() - startDate.getMinutes();
    let secondDiff = endDate.getSeconds() - startDate.getSeconds();
    if (dayDiff < 0) {
      if (monthDiff > 0) {
        monthDiff--;
      } else {
        yearDiff--;
        monthDiff = 11;
      }
      dayDiff += daysInMonth[startDate.getMonth()];
    }

    return ([
      yearDiff, monthDiff, dayDiff, hourDiff, minuteDiff, secondDiff
    ]);

  }
  // When scroll down the screen
  onScroll() {
    console.log("Scrolled");
    this.pageNumber = this.pageNumber + 1;
    this.page = this.pageNumber * this.pagesize;
    // this.pagesize = this.pagesize;
    this.isScrolled = true;
    this.getArchiveMessage();
  }

  activationCode(): void {
    this.router.navigate(['/myaccount/profile']);
  }

  openCompose()
  {
    let serviceData = this.commonService.getServiceDetail(DashboardServiceData.SendMessage);
    if (serviceData && serviceData?.result?.toLowerCase() == CommonConstants.success) {
      this.router.navigate(['/pages/navigate-routes/Compose']);
    }
    else {
      this.commonService.openServiceDialog(serviceData);
    }
  }
  
  // opensettings()
  // {
  //   this.router.navigate(['//pages/navigate-routes/Settings']);
  // }
  
  // hideinbox(): void {

  //   if (this.mailboxServie.selectedMailBoxType.includes("Compose") ||
  //     this.mailboxServie.selectedMailBoxType.includes("Inbox") ||
  //     this.mailboxServie.selectedMailBoxType.includes("Drafts") ||
  //     this.mailboxServie.selectedMailBoxType.includes("Sent") ||
  //     this.mailboxServie.selectedMailBoxType.includes("Archive") ||
  //     this.mailboxServie.selectedMailBoxType.includes("Groups") ||
  //     this.mailboxServie.selectedMailBoxType.includes("Detail") ||
  //     this.mailboxServie.selectedMailBoxType.includes("DraftDetail")) {
  //     this.mailboxServie.selectedMailBoxType = '';
  //   }
  // }

  isIndeterminate() {
    return (this.selectedcheckbox.length > 0 && !this.isChecked());
  };

  isChecked() {
    return this.selectedcheckbox.length === this.mailboxServie.archiveData.length;
  };

  archiveMoveToDelete(inboxMessageId: any) {
    console.log(inboxMessageId)
    let data: any = {
      "InboxMessageId": inboxMessageId,
    }
    const dialogRef = this.deletedialog.open(MovetoinboxDialogComponent, {
      data: {
        alldata: data,
        id: 'You want to delete the message'
      }
    });
    //Call move to inbox API based on the confirm Dialod Result
    // dialogRef.afterClosed().subscribe(dialogResult => {
    //   if (dialogResult) {
    //     this.inBoxService.deleteDraftMessage(SentMessageId).subscribe(data => {
    //       if (data) {
    //         this.toaster.success(
    //           this.translate.instant('Draft deleted successfully'));
    //         this.mailboxService.sentData =[];
    //         this.page=0;
    //         this.pageNumber=0;
    //         this.getSentMessages();
    //       }
    //     })
    //   }
    // })
  }

  moveToInboxSingle(item: any) {
    this.selectedcheckbox.push(item);
    this.getArchiveMessage();
    this.selectedArchiveItems.push({
      //InboxId: item.InboxId,

      InboxId: item.MessageId,
      IsSentItem: item.IsSentItem
    })
  }



  moveToInboxSingleItem(item: any) {
    this.selectedcheckbox.push(item);
    this.selectedArchiveItems.push({
      InboxId: item.InboxId,
      IsSentItem: item.IsSentItem
    })
  }

  toggle(item: any, event: MatCheckboxChange) {
    // this.selectCheckEvent = event.checked;
    if (event.checked) {
      this.selectedcheckbox.push(item);
      this.selectedArchiveItems.push({
        InboxId: item.InboxId,
        IsSentItem: item.IsSentItem
      })
    } else {
      const index = this.selectedcheckbox.indexOf(item);
      if (index >= 0) {
        this.selectedcheckbox.forEach((element: any, index: any) => {
          if (element.InboxId == item.InboxId) {
            this.selectedcheckbox.splice(index, 1);
          }
        });
      }
    }
    console.log(item + "<>", event.checked);
  }

  exists(item: any) {
    return this.selectedcheckbox.indexOf(item) > -1;
  };

  toggleAll(event: MatCheckboxChange) {
    this.selectCheckEvent = event.checked;
    if (event.checked) {
      this.mailboxServie.archiveData.forEach((row: any) => {
        this.selectedcheckbox.push(row)
        this.selectedArchiveItems.push({
          InboxId: row.InboxId,
          IsSentItem: row.IsSentItem
        });
      });
    } else {
      this.selectedcheckbox.length = 0;
    }
  }

  // refresh
  refresh() {
    this.mailboxServie.archiveData = [];
    this.selectedcheckbox.length = 0;
    this.page = 0;
    this.pageNumber = 0;
    this.searchemptymsg =false;
    this.getArchiveMessage();
  }

  //selecetd record move to inbox
  movetoInboxDialog(inboxMessageId: any) {
    let data: any = {
      "TrashId": inboxMessageId,
      "IsSentItem": false
    }
    const dialogRef = this.deletedialog.open(MovetoinboxDialogComponent, {
      data: {
        alldata: data,
        id: 'Do you want to move to inbox.'
      }
    });

    //Call move to inbox API based on the confirm Dialod Result
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.selectedcheckbox.filter((value: any) => {
          this.listInboxId.push(value.InboxId, value.IsSentItem);
          //sentItemValues.push(value.IsSentItem);
        });
        this.inBoxService.getSelectedsentMailDetails(this.userId, false, true, 0, inboxMessageId).subscribe((result: any) => {

          let isSelectedAll = this.checkboxForm.value.checkboxChecked === true ? true : false;

          let restoreObject = {
            userId: this.userId,
            isSelectAll: isSelectedAll,
            archivecount: 0,
            archiveItems: this.selectedArchiveItems
          };
          this.inBoxService.mailRestoreFromTrash(restoreObject).subscribe((res: any) => {
            console.log('Response: ', res)
            if (res == 1) {
              this.toaster.success(
                this.translate.instant('Moved to inbox successfully'));

              this.inBoxService.GetAllReceivedMessageCount(this.userId).subscribe(data => {
                this.inboxCount = data;
                console.log(this.inboxCount);
                this.mailboxServie.archiveData = [];
                this.checkboxForm.controls['checkboxChecked']?.setValue(false);
                this.checkboxForm.controls['selectedCheckbox']?.setValue(false);
                this.selectedcheckbox.length = 0;
                this.page = 0;
                this.pageNumber = 0;
                this.getArchiveMessageCount();
                this.getArchiveMessage();
                this.inBoxService.inboxCount(this.userId).subscribe((count: any) => {
                this.mailboxServie.mailstype[0].count = count;
                  });
              })
              this.archiveSelectedDetails = false;
              this.SelectedMail = false;
            }
          }, (err: any) => {
            console.log(err)
            // this.toaster.error(err.error.Message)
          })
          // this.inBoxService.MailRestoreFromTrash(this.userId, this.listInboxId).subscribe(data => {
          //   console.log(data)
          //   if (data == 1) {
          //     this.toaster.success(
          //       this.translate.instant('Moved to inbox successfully'));
          //     // this.mailboxServie.selectedMailBoxType = value;
          //     this.inBoxService.GetAllReceivedMessageCount(this.userId).subscribe(data => {
          //       this.inboxCount = data;
          //       // this.clearData();
          //       console.log(this.inboxCount)
          //       this.mailboxServie.archiveData = [];
          //       this.checkboxForm.controls['checkboxChecked']?.setValue(false);
          //       this.checkboxForm.controls['selectedCheckbox']?.setValue(false);
          //       this.selectedcheckbox.length = 0;
          //       this.page = 0;
          //       this.getArchiveMessage();
          //     })
          //     // selected mail mark as read
          //     if (this.mailboxServie.archiveData[0].IsRead == false) {
          //       this.inBoxService.mailMarkAsRead(this.userId, this.mailboxServie.messageId).subscribe((res: any) => {
          //         if (res) {
          //           this.mailboxServie.archiveData[0].IsRead = true;
          //         }
          //       }, (err: any) => {
          //         console.log(err)
          //         this.toaster.error(err.error.Message)
          //       })
          //     }
          //   }
          // })
        })
      }
    })
  }

  movetoInboxAll = () => {
    this.imgSrc4 = "../../../../assets/images/icons-text/Group 5079 (1).svg";
    let isSelectedAll = this.checkboxForm.value.checkboxChecked === true ? true : false;

    let restoreObject = {
      userId: this.userId,
      // isSelectAll: isSelectedAll,
      archivecount: this.totalArchiveCount,
      archiveItems: this.selectedArchiveItems
    };

    console.log('resotre item', JSON.stringify(restoreObject));


    this.inBoxService.mailRestoreFromTrash(restoreObject).subscribe((res: any) => {
      console.log('Response: ', res)
      if (res == 1) {

        this.toaster.success(
          this.translate.instant('Moved to inbox successfully'));

        this.inBoxService.GetAllReceivedMessageCount(this.userId).subscribe(data => {
          this.inboxCount = data;
          console.log(this.inboxCount);
          this.mailboxServie.archiveData = [];
          this.checkboxForm.controls['checkboxChecked']?.setValue(false);
          this.checkboxForm.controls['selectedCheckbox']?.setValue(false);
          this.selectedcheckbox.length = 0;
          this.page = 0;
          this.pageNumber = 0;
          this.getArchiveMessageCount();
          this.getArchiveMessage();
          this.inBoxService.inboxCount(this.userId).subscribe((count: any) => {
          this.mailboxServie.mailstype[0].count = count;
          });
          this.archiveSelectedDetails = false;
          this.SelectedMail = false;

        })
      }
    }, (err: any) => {
      console.log(err)
      // this.toaster.error(err.error.Message)
    })
  }


  // selected or all records move to inbox
  // movetoInboxAll_Old() {
  //   console.log("archiveall", this.selectedcheckbox);

  //   const checkedYesList = this.selectedcheckbox.filter((value: any) => {
  //     return value.IsChecked === true;
  //   });


  //   this.listInboxId = [];
  //   let sentItemValues: any = [];
  //   this.selectedcheckbox.filter((value: any) => {
  //     sentItemValues = {
  //       userid: this.userId,
  //       inboxId: value.InboxId,
  //       sentItem: value.IsSentItem,
  //       selectType: ''
  //     }
  //      this.listInboxId.push(sentItemValues);
  //   });

  //   // const listId = this.selectedcheckbox.length > 9 ? 'All' : this.listInboxId;
  //   // console.log(listId);

  //   for (let i = 0; i < this.listInboxId.length; i++) { 
  //     this.selectInputValue = {
  //       userid: this.listInboxId[i].userid, 
  //       inboxId: this.listInboxId[i].inboxId, 
  //       sentItem: this.listInboxId[i].sentItem,
  //       selectType: ''
  //     }
  //   }

  //   if(this.selectCheckEvent !== true) {

  //     this.inBoxService.MailRestoreFromTrashValue(this.listInboxId).subscribe((res: any) => {
  //       if (res == 1) {

  //         this.toaster.success(
  //           this.translate.instant('Moved to inbox successfully'));

  //         this.inBoxService.GetAllReceivedMessageCount(this.userId).subscribe(data => {
  //           this.inboxCount = data;
  //           console.log(this.inboxCount);
  //           this.mailboxServie.archiveData = [];
  //           this.checkboxForm.controls['checkboxChecked']?.setValue(false);
  //           this.checkboxForm.controls['selectedCheckbox']?.setValue(false);
  //           this.selectedcheckbox.length = 0;
  //           this.page = 0;
  //           this.getArchiveMessage();
  //         })
  //       }
  //     this.selectedcheckbox = [];
  //     }, (err: any) => {
  //       console.log(err)
  //       // this.toaster.error(err.error.Message)
  //     })
  //     this.selectInputValue.selectType = '';
  //   } else {
  //     this.selectInputValue.selectType = 'All';
  //     this.selectInputValue.inboxId = '';
  //     this.selectInputValue.sentItem = '';
  //      this.inBoxService.MailRestoreFromTrashValue(this.selectInputValue).subscribe((res: any) => {
  //     if (res == 1) {

  //       this.toaster.success(
  //         this.translate.instant('Moved to inbox successfully'));

  //       this.inBoxService.GetAllReceivedMessageCount(this.userId).subscribe(data => {
  //         this.inboxCount = data;
  //         console.log(this.inboxCount);
  //         this.mailboxServie.archiveData = [];
  //         this.checkboxForm.controls['checkboxChecked']?.setValue(false);
  //         this.checkboxForm.controls['selectedCheckbox']?.setValue(false);
  //         this.selectedcheckbox.length = 0;
  //         this.page = 0;
  //         this.getArchiveMessage();
  //       })
  //     }
  //   this.selectedcheckbox = [];
  //   }, (err: any) => {
  //     console.log(err)
  //     // this.toaster.error(err.error.Message)
  //   })
  //   }


  //   // for (let i = 0; i < this.selectedcheckbox.length; i++) {
  //   //   if (this.checkboxForm.value.checkboxChecked || this.checkboxForm.value.selectedCheckbox) {
  //   //     this.inBoxService.MailRestoreFromTrash(this.selectedcheckbox[i].InboxId, this.selectedcheckbox[i].IsSentItem == true ? true : false).subscribe((res: any) => {
  //   //       if (res == 1) {

  //   //         this.toaster.success(
  //   //           this.translate.instant('Moved to inbox successfully'));

  //   //         this.inBoxService.GetAllReceivedMessageCount(this.userId).subscribe(data => {
  //   //           this.inboxCount = data;
  //   //           console.log(this.inboxCount);
  //   //           this.mailboxServie.archiveData = [];
  //   //           this.checkboxForm.controls['checkboxChecked']?.setValue(false);
  //   //           this.checkboxForm.controls['selectedCheckbox']?.setValue(false);
  //   //           this.selectedcheckbox.length = 0;
  //   //           this.page = 0;
  //   //           this.getArchiveMessage();
  //   //         })
  //   //       }
  //   //     }, (err: any) => {
  //   //       console.log(err)
  //   //       // this.toaster.error(err.error.Message)
  //   //     })
  //   //   }
  //   // }
  // }

  changeMailbox(event: any) {
    this.mailboxServie.selectedMailBoxType = event.value;
  }

  loadComponent(value: any) {
    this.router.navigate(['/mailbox/', value]);
  }

  /* here get the archive messageId */
  setSelectedMailType(messageId: string, index: number) {
    // this.mailboxServie.selectedMailBoxType = mailType;
    this.SelectedMail = index;
    this.ismailSelected = true
    this.showDetail = false;
    this.archiveSelectedDetails = true;
    this.mailboxServie.messageId = messageId;
    this.mailboxServie.index = index;
    this.getSelectedArchiveMailDetails();
  }

  replyMessageShow(mailType: any) {
    this.imgSrc1 = '../../../../assets/images/Group 5014.svg';
    //this.mailboxServie.selectedMailBoxType = mailType;
    this.showDetail = true;
  }

  replyMessageNavigate(mailType: any) {
    this.mailboxServie.selectedMailBoxType = mailType;
  }

  // Get Selected mail Details
  getSelectedArchiveMailDetails() {
    console.log(this.mailboxServie.messageId)
    // this.mailboxServie.messageId = "97615";
    // this.inBoxService.getSelectedsentMailDetails(this.mailboxServie.messageId,false).subscribe((data:any) => {
    this.inBoxService.getSelectedsentMailDetails(this.userId, false, true, 0, this.mailboxServie.messageId).subscribe((data: any) => {
      console.log(data);
      this.selectedMessagesDetails = data;
      if (this.selectedMessagesDetails.length > 0) {
        if (this.selectedMessagesDetails[0].FromUserId !== null) {
          this.inBoxService.getDraftPracticeDetails(this.selectedMessagesDetails[0].FromUserId, Number(this.mailboxServie.messageId)).subscribe(result => {
            this.getPracticeData = result;
            console.log(this.getPracticeData)
          })
        }
        else if (this.selectedMessagesDetails[0].ToUserId !== null) {
          this.inBoxService.getDraftPracticeDetails(this.selectedMessagesDetails[0].ToUserId, Number(this.mailboxServie.messageId)).subscribe(result => {
            this.getPracticeData = result;
            console.log(this.getPracticeData)
          })
        }
        this.xmltoJSON = JSON.parse(this.selectedMessagesDetails[0].attachment);
        console.log(this.xmltoJSON)
        console.log(this.xmltoJSON.ROOT)
        // if (this.xmltoJSON.ROOT != "") {
          if (this.xmltoJSON != "") {
          let singleAttachment: any = [];
          singleAttachment = this.xmltoJSON.ROOT.Inbox_Attachments;
          this.filesData = singleAttachment;
          console.log(this.filesData);
        }
      }
    })

  }

  // Download Files
  downloadFiles(filename: any) {
    let encodefilename = btoa(filename)
    // window.open(`http://mmhv2.api.covalense.net:8080/api/Inbox/DownloadFile?filename=${encodefilename}`, '_blank');
    console.log("encodefilename : ", encodefilename);

    window.open(`${environment.apiBaseURL}${URLConstants.downloadFile}${encodefilename}`, '_blank');
  }

  close() {
    this.archiveSelectedDetails = false;
    this.SelectedMail = false;
    this.ismailSelected = false
  }

  // search value
  Searchkey(searchkeyvalue: any): void {
    console.log(searchkeyvalue, "searchvalue")
    this.isSearched = true;
    this.mailboxServie.sendMessage(searchkeyvalue);
  }

  clearSearchData() {
    this.searchTexts = "";
    this.mailboxServie.archiveData = [];
    this.page = 0;
    this.pageNumber = 0;
    this.searchText = '';
    this.archiveSelectedDetails = false;
    this.SelectedMail = false;
    this.getArchiveMessage();
  }
}





