import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


export interface DialogData {
  dataSource:any;
  id:any
}

@Component({
  selector: 'app-movetoinbox-dialog',
  templateUrl: './movetoinbox-dialog.component.html',
  styleUrls: ['./movetoinbox-dialog.component.scss']
})

export class MovetoinboxDialogComponent implements OnInit {
  message:any;

  constructor(
    public dialogRef: MatDialogRef<MovetoinboxDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
    this.message = this.data.id
  }

  
  onConfirm(): void {
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }
}
