<mat-card class="m-0 forgot-password content-panel">
  <mat-card-content>
    <div class="register-contentwrp">
      <div class="login-content">
        <div class="d-flex justify-content-space-between">
          <a [routerLink]="['/home']">
            <img src="assets/images/MMHLogoMark-Icon.png" alt="" class="logo hide-1023">
            <img src="assets/images/MMH-mobile-logo.png" alt="" class="logo hide-1024">
          </a>
        </div>
        <div *ngIf="invalidURL == true" class="forgotPasswordContent">
          <div class=" header-buttons m-b-20 m-b-sm-0 p-lt-xs-20">
            <h1 class="d-flex align-items-center">
              {{"Registration"|translate}}
            </h1>
            <p class="text-danger">
              {{Message}}
            </p>
           </div>
        </div>
        <div *ngIf="invalidURL == false" class="forgotPasswordContent">
          <div class="page-header header-buttons m-b-20 m-b-sm-0 p-lt-xs-20">
            <h1 class="d-flex align-items-center">
              {{"Registration"|translate}}
            </h1>
          </div>
          <div class="">
            <mat-horizontal-stepper #stepper>
              <mat-step class="p-20">
                <div class="box-organ">
                  <p>
                    Hi {{patientDetails.patientName}}, {{patientDetails.LocationName}} is using Manage My Health as its Patient Portal and invites you to
                    register. You will require a unique email address.
                  </p>
                  <p>
                    <!-- How can I verify this is trustworthy? -->
                    Please make sure that you are browsing the URL app.managemyhealth.co.nz
                  </p>
                </div>
                <div class="d-grid g-t-2c g-t-1c-768 g-cg-16" id="decline-accept">
                  <button mat-button class="btn btn-primary action-buttons d-block m-t-25" (click)="proceed()">PROCEED</button>
                  <button mat-button class="btn btn-secondary d-block m-t-25 action-buttons"
                    (click)="decline()">{{'DECLINE'|translate}}</button>
                </div>
              </mat-step>
              <div *ngIf="isProceed">
                <mat-step>
                  <form [formGroup]="registrationForm" (submit)="submitForm()">
                    <div class="p-10">
                      <div fxLayout="row wrap">
                        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                          <mat-form-field appearance="outline"
                            [ngClass]="{
                          'has-success': registrationForm.controls['email'].valid && registrationForm.controls['email'].touched || registrationForm.controls['email'].dirty}">
                            <mat-label class="required">{{'Email Address'|translate}}</mat-label>
                            <input matInput type="email" placeholder="{{'Email Address'|translate}}"
                              [formControl]="registrationForm.controls['email']" (keypress)="emailExist($event)" (blur)="emailExistAPIcall(s)">
                            <mat-icon class="error"
                              *ngIf="registrationForm.controls['email'].invalid && registrationForm.controls['email'].touched">
                              info_outline</mat-icon>

                          </mat-form-field>
                          <mat-error
                            *ngIf="registrationForm.controls['email'].hasError('email') && !registrationForm.controls['email'].hasError('required')&& registrationForm.controls['email'].touched"
                            class="text-danger support-text error-name">
                            {{'Valid Email'|translate}}
                          </mat-error>
                          <mat-error
                            *ngIf="registrationForm.controls['email'].hasError('required') && registrationForm.controls['email'].touched"
                            class="text-danger support-text error-name">
                            {{'Required'|translate}}
                          </mat-error>
                          <mat-label *ngIf="isUserRegistered">
                            <p>{{'It looks like you are already registered with Manage My Health™.'|translate}}</p>
                            <p style="padding-bottom: 340px;">Please <a [routerLink]="['/authentication/login']">Click here</a>
                              to
                              login to your account.</p>
                          </mat-label>

                        </div>
                      </div>
                      <div *ngIf="emailExistVal ==  false; else emailexistUI">
                        
                        <div fxLayout="row wrap" fxLayoutAlign="space-between none">
                          <div fxFlex.gt-sm="50" fxFlex.gt-xs="50" fxFlex="100" class="date dob">
                            <mat-form-field appearance="outline" class="date-icon-color"
                              [ngClass]="{
                          'has-success': registrationForm.controls['dob'].valid && registrationForm.controls['dob'].touched || registrationForm.controls['dob'].dirty}">
                              <mat-label class="required">{{ "Date of birth" | translate }}</mat-label>
                              <input readonly matInput [matDatepicker]="picker" placeholder="Date of birth" formControlName="dob"
                                [max]="maxBirthdayDate" [min]="minBirthdayDate" />
                              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                              <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                            <mat-error class="text-danger support-text error-name"
                              *ngIf="registrationForm.controls['dob'].invalid && registrationForm.controls['dob'].hasError('required') && registrationForm.controls['dob'].touched">
                              {{'Required'|translate}}</mat-error>
                            <mat-error class="text-danger support-text error-name"
                              *ngIf="registrationForm.controls['dob'].invalid && (!registrationForm.controls['dob'].hasError('required') && registrationForm.controls['dob'].touched)">
                              {{'Valid Dob'|translate}}</mat-error>
                          </div>
                          <!-- <div fxFlex.gt-sm="45" fxFlex.gt-xs="45" fxFlex="100">
                            <p>Please provide your D.O.B</p>
                          </div> -->
                        </div>
  
                        <div fxLayout="row wrap">
                          <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                            <mat-form-field appearance="outline"
                              [ngClass]="{
                            'has-success': registrationForm.controls['password'].valid && registrationForm.controls['password'].touched || registrationForm.controls['password'].dirty}">
                              <mat-label class="required">{{'Choose a password'|translate}}</mat-label>
                              <input matInput type="password" placeholder="{{'Choose a password'|translate}}"
                                [formControl]="registrationForm.controls['password']"
                                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d.*)(?=.*\W*)[a-zA-Z0-9\S]{10,30}$" maxlength="30"
                                [type]="hidePassword ? 'password' : 'text'" (keypress)="checkCase($event)"
                                (keyup)="falseCase(registrationForm.controls['password'])"
                                (input)="checkCase1(registrationForm.controls['password'])" (paste)="onPaste($event)">
                              <span class="eye">
                                <mat-icon matSuffix (click)="togglePasswordVisibility()">{{hidePassword ? 'visibility_off' :
                                  'visibility'}}</mat-icon>
                              </span>
                            </mat-form-field>
                            <mat-error
                              *ngIf="registrationForm.controls['password'].hasError('required') && registrationForm.controls['password'].touched"
                              class="text-danger support-text error-name">{{'Required'|translate}}</mat-error>
                          </div>
  
                          <div fxLayout="row" class="">
                            <app-password-strength design="2"
                              [Password]="registrationForm.controls['password'].value"></app-password-strength>
                          </div>
                          <!-- col full-->
                          <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                            <mat-form-field appearance="outline"
                              [ngClass]="{
                            'has-success': registrationForm.controls['confirmPassword'].valid && registrationForm.controls['confirmPassword'].touched || registrationForm.controls['confirmPassword'].dirty}">
                              <mat-label class="required">{{'Re-enter password'|translate}}</mat-label>
                              <input matInput placeholder="{{'Re-enter password'|translate}}" maxlength="30"
                                [formControl]="registrationForm.controls['confirmPassword']" type="password"
                                [type]="hideConfirmPassword ? 'password' : 'text'">
                              <span class="eye">
                                <mat-icon matSuffix (click)="hideConfirmPassword = !hideConfirmPassword">
                                  {{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                              </span>
                            </mat-form-field>
                            <mat-error
                              *ngIf="registrationForm.controls['confirmPassword'].hasError('required') && registrationForm.controls['confirmPassword'].touched"
                              class="text-danger support-text error-name">{{'Required'|translate}}</mat-error>
                            <mat-error
                              *ngIf="registrationForm.controls['confirmPassword'].errors?.equalTo && registrationForm.controls['confirmPassword'].touched"
                              class="text-danger support-text error-name">{{'Passwords Match'|translate}}</mat-error>
                          </div>
  
  
                          <div class="register-checkbox">
                            <mat-checkbox [formControl]="registrationForm.controls['readAccept']" [checked]="false">
                              I have read and accept the Manage My Health<br />
                              <a target="_blank" href="https://www.managemyhealth.co.nz/terms-of-use/" target="_blank"> Terms of
                                Use</a> and <a target="_blank" href="https://www.managemyhealth.co.nz/privacy-policy/">Privacy
                                Policy</a>
                            </mat-checkbox>
                            <mat-error
                              *ngIf="registrationForm.controls['readAccept'].hasError('required') && registrationForm.controls['readAccept'].touched"
                              class="text-danger support-text  error-name m-t-10">{{'privacy'|translate}}</mat-error>
                          </div>
                          <!-- <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                            <button mat-button (click)="onSubmitUserr($event)" matStepperNext
                              class="btn-primary-pill btn-block m-t-25" [disabled]="!registrationForm.valid">
                              {{'CREATE ACCOUNT'|translate}}
                            </button>
                          </div> -->
                        </div>
                        <div class="d-grid g-t-2c g-t-1c-768 g-cg-16" id="decline-accept">
                          <button mat-button type="submit" class="btn btn-primary action-buttons d-block m-t-25" 
                             [disabled]="!registrationForm.valid" >
                            NEXT
                          </button>
                          <!-- [disabled]="!registrationForm.valid"  -->
                          <button mat-button class="btn btn-secondary d-block m-t-25 action-buttons"
                            matStepperPrevious>{{'CANCEL'|translate}}</button>
                        </div>
                      </div>
                      <ng-template #emailexistUI>
                        <p>
                          It looks like you are already registered with Manage My Health™.
                        </p>
                        <p>
                          Please <a class="link" href="/authentication/login" >
                            Click here 
                          </a>to login to your account.
                        </p>
                      </ng-template>
                    </div>
                  </form>
                </mat-step>
                <mat-step>
                  <form [formGroup]="validationForm">
                    <div fxLayout="row wrap">
                      <b>
                        Please enter your verification code
                      </b>
                      <p>
                        You have been sent a verification code to the email address you have registered. Please enter to complete your registration.
                      </p>
                      <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-form-field appearance="outline"
                          [ngClass]="{
                        'has-success': validationForm.controls['validation'].valid && validationForm.controls['validation'].touched || validationForm.controls['validation'].dirty}">
                          <mat-label class="required">{{'Verification code'|translate}}</mat-label>
                          <input matInput type="text" placeholder="{{'Verification code'|translate}}"
                            [formControl]="validationForm.controls['validation']" (blur)="checkIsUserRegistered()">
                          <mat-icon class="error"
                            *ngIf="validationForm.controls['validation'].invalid && validationForm.controls['validation'].touched">
                            info_outline</mat-icon>

                        </mat-form-field>
                        <mat-error
                          *ngIf="validationForm.controls['validation'].hasError('required') && validationForm.controls['validation'].touched"
                          class="text-danger support-text error-name">
                          {{'Required'|translate}}
                        </mat-error>
                     </div>
                    </div>
                    <p> {{'Still didn’t get the OTP?'}} 
                      <span *ngIf="resendEnable" class="link-text" 
                      (click)="submitForm(stepper)">
                        {{"Resend verification code."}}
                      </span>
                      <span *ngIf="!resendEnable">
                        {{'Resend verification code in '+transform(counter)}}
                      </span>
                    </p>
                    <!-- <div class="link-text" (click)="createAccount()">
                     Resend verification code.
                    </div> -->
                    
                    <div class="d-grid g-t-2c g-t-1c-768 g-cg-16" id="decline-accept">
                      <button mat-button class="btn btn-primary action-buttons d-block m-t-25" (click)="verifyOTP()">NEXT</button>
                      <button mat-button class="btn btn-secondary d-block m-t-25 action-buttons"
                        matStepperPrevious>{{'CANCEL'|translate}}</button>
                    </div>
                  </form>
                </mat-step>
                <mat-step>
                  <div class="p-10">
                    <b>
                      Registration completed successfully
                    </b>

                    <p>
                      Your username is set as your email address.
                    </p>
                    <p>
                      The Manage My Health application is  available for iOS and Android devices downloadable from
                    </p>
                    <div class="d-flex justify-content-center g-t-2c g-t-1c-768 g-cg-16" >
                      <a href="https://play.google.com/store/apps/details?id=managemyhealth.co.nz">
                        <img src="assets/images/GooglePlay.png" alt="Playstore">
                      </a>
                      <a href="https://apps.apple.com/us/app/managemyhealth/id970773819">
                        <img src="assets/images/AppStore.png" alt="Appstore">
                      </a>
                      <!-- <button mat-button class="btn btn-primary action-buttons d-block m-t-25" matStepperNext>Continue</button> -->
                      <!-- <button mat-button class="btn btn-secondary d-block m-t-25 action-buttons"
                        matStepperPrevious>{{'CANCEL'|translate}}</button> -->
                    </div>
                  </div>
                </mat-step>
              </div>
              <div *ngIf="!isProceed">
                <mat-step>
                  <div class="p-10">
                    <p>
                      Please assist us to update our records by choosing ONE of the following options:
                    </p>
                    <p class="box-organ">
                      {{patientDetails.LocationName}} has your name recorded as {{patientDetails.patientName}}. Please confirm that this is you
                    </p>
                  </div>
                  <div class="d-grid g-t-2c g-t-1c-768 g-cg-16" id="decline-accept">
                    <button mat-button class="btn btn-primary action-buttons d-block m-t-25"  (click)="selfRegester(true)">YES THIS IS ME</button>
                    <button mat-button class="btn btn-secondary action-buttons d-block m-t-25"  (click)="selfRegester(false)">NO THIS IS NOT ME</button>
                    <button mat-button class="btn btn-secondary d-block m-t-25 action-buttons"
                      matStepperPrevious>{{'CANCEL'|translate}}</button>
                  </div>
                </mat-step>
                <mat-step>
                  <div class="p-10">
                    <div *ngIf="itsMe">
                      <p>
                        You have requested to opt-out from Market Surgery Medical Centre. Please take a moment to tell us why.
                      </p>
                      <b>
                        This is me, but...
                        <br/>
                      </b>
                      <mat-radio-group [(ngModel)]="smsreason" aria-label="Select an option" class="option d-flex flex-column ">
                        <mat-radio-button class="p-5" *ngFor="let reason of thisIsMe" [value]=reason>{{reason}}</mat-radio-button>
                    </mat-radio-group>
                    </div>
                    <div *ngIf="!itsMe">
                      <p>
                        Please assist us to update our records by choosing ONE of the following options:
                      </p>
                      <b>
                        This is not me, and...
                        <br/>
                      </b>
                      <mat-radio-group [(ngModel)]="smsreason" aria-label="Select an option" class="option d-flex flex-column ">
                        <mat-radio-button class="p-5" *ngFor="let reason of thisIsNotMe" [value]=reason>{{reason}}</mat-radio-button>
                      </mat-radio-group>
                      
                    </div>
                    <b>
                      Enter additional information here:
                    </b>
                    <div fxLayout="row wrap">
                      <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-form-field appearance="outline">
                          <!-- [ngClass]="{
                        'has-success': validationForm.controls['validation'].valid && validationForm.controls['validation'].touched || validationForm.controls['validation'].dirty}"> -->
                          <input matInput type="email"  [(ngModel)]="smsfeedback" placeholder="{{'Text'|translate}}">
                            <!-- [formControl]="validationForm.controls['validation']" > -->
                          <!-- <mat-icon class="error"
                            *ngIf="validationForm.controls['validation'].invalid && validationForm.controls['validation'].touched">
                            info_outline</mat-icon> -->

                        </mat-form-field>
                        <!-- <mat-error
                          *ngIf="validationForm.controls['validation'].hasError('required') && validationForm.controls['validation'].touched"
                          class="text-danger support-text error-name">
                          {{'Required'|translate}}
                        </mat-error> -->
                      </div>
                    </div>
                  </div>
                  <div class="d-grid g-t-2c g-t-1c-768 g-cg-16" id="decline-accept">
                    <button mat-button class="btn btn-primary action-buttons d-block m-t-25" (click)="sendFeedback()" >SUBMIT</button>
                    <button mat-button class="btn btn-secondary d-block m-t-25 action-buttons"
                      matStepperPrevious>{{'CANCEL'|translate}}</button>
                  </div>
                  <p>This information will be shared with your GP.</p>
                </mat-step>
                <mat-step>
                  <div class="p-10">
                    <p *ngIf="itsMe" class="box-organ">
                      Thank you for your feedback. You will be opted out of future message
                    </p>
                    <p *ngIf="!itsMe" class="box-organ">
                      Thank you for your feedback.
                    </p>
                  </div>
                  <div class="d-grid g-t-2c g-t-1c-768 g-cg-16" id="decline-accept">
                    <button mat-button class="btn btn-primary action-buttons d-block m-t-25" (click)="goToSignIn()"  >EXIT</button>
                  </div>
                </mat-step>
              </div>
            </mat-horizontal-stepper>
            <p class="c">Copyright © 2024 Manage My Health Ltd. All rights reserved.</p>
          </div>
        </div>
        
      </div>
      <div class="loginBg">
      </div>
    </div>
  </mat-card-content>
</mat-card>
