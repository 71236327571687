import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/data/service/authentication.service';
import { environment } from 'src/environments/environment';
import { LoadingComponent } from '@progress/kendo-angular-grid';
import { LoaderService } from 'src/app/data/service/loader.services';
import { ToasterService } from 'src/app/data/service/toaster.service';
import { TokenService } from 'src/app/data/service/token.service';



@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService, private router: Router,
        private loaderService:LoaderService,private toaster:ToasterService,
        private tokenService: TokenService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
         this.loaderService.hide()
       
         if(err.status === 422)
         {
           this.authenticationService.enableModelStateError();
         }
         else if(err.status === 500 && err?.error?.message ==="Invalid Model State:"){
                 this.toaster.warning("Please fill the mandatory details","Warning!")
         }
         else if (err.status === 401) {
            let returnURL = sessionStorage.getItem('returnURL');
             if (this.tokenService.token() != null && returnURL) {
                sessionStorage.removeItem('returnURL');
                this.router.navigate(['/dashboards/dashboard']);
             }
             else {
                 let str: string = '';
                 str += new Date() + '-';
                 str += request
                 localStorage.setItem('logout: 401 --Error', JSON.stringify(str));

                 // auto logout if 401 response returned from api

                 this.authenticationService.logout();
                 if (sessionStorage.getItem("isBetaUser") == "true") {
                     this.router.navigateByUrl(environment.v1URL);
                 } else {
                     this.router.navigate(['/authentication/login']);
                 }
             }
                //location.reload(true);
            }
          
            //  const error = err.error.Message || err.statusText;
            const error = err;
            return throwError(error);
        }))
    }
}