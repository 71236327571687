<span class="title"> {{ "Recall Remainder Message" | translate }}</span>
<mat-dialog-content>
  <div fxLayout="column" class="p-t-8 p-b-8">
    <div [innerHTML]="data && data[1]">
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end end">
  <button mat-button class="btn-primary-rect" (click)="btnClick('appointment')"> {{ "Book Appointment" | translate
    }}</button>
  <button mat-flat-button mat-dialog-close class="btn-secondary-rect" (click)="btnClick('ok')"> {{ "Ok" | translate
    }}</button>
</mat-dialog-actions>
