<div class="content-panel p-0 dialog-box">

  <h3>{{'Attach Files'|translate}}
    <!-- <mat-icon class="close" (click)="dialogRef.close()">cancel</mat-icon> -->
  </h3>
  <div>
    <p>{{'1.A maximum of 3 files can be attached per email.'|translate}}</p>
    <p> {{'2.Each file must not exceed'|translate}}&nbsp;<strong>{{'5MB'|translate}}</strong>.</p>
    <p> {{'3.Click '|translate}}"{{'Attach'|translate}}" {{' button when you finish attaching files.'|translate}}</p>
  </div>
  <form [formGroup]="chooseFileForm">
    <div formArrayName="questions" *ngFor="let x of questions.controls; let i = index">
      <div [formGroupName]="i" class="question-container">
        <div class="m-b-20 show-file">
          <mat-icon class="m-r-5">attachment</mat-icon>{{'ADD Files'|translate}}
          <input class="hide-file" type="file" placeholder="file" #fileInput multiple (change)="getFileDetails($event)"
            formControlName="Attachment" />
          <!-- <mat-error *ngIf="filesizevalidation">{{'filesize validation' | translate}}</mat-error> -->
          <!-- <mat-error *ngIf="filelengthError">{{'filelength validation' | translate}}</mat-error> -->
        </div>
        <div>
          <!-- <table mat-table [dataSource]="dataSource" class="table employee-list no-wrap">
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Name </th>
              <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <ng-container matColumnDef="size">
              <th mat-header-cell *matHeaderCellDef> Size </th>
              <td mat-cell *matCellDef="let element"> {{element.size/1024/1024 | number:'.5' }} MB </td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef> Action </th>
              <td mat-cell *matCellDef="let element;let i = index;" class="action-link">
                <a (click)="remove(i)" class="m-r-10 cursor-pointer">
                  <i class="fa fa-trash text-danger"></i>
                </a>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table> -->
          <div class="documents">
            <p *ngFor="let file of myFiles; let i=index" class="d-flex justify-content-space-between" style="cursor: context-menu;">
              <span class="file-name-txt">{{file?.name}}</span>
              <mat-icon (click)="remove(i)">delete_outline
              </mat-icon>
            </p>
            <mat-error *ngIf="filesizevalidation">{{'filesize validation' | translate}}</mat-error>
            <mat-error class="m-t-10" *ngIf="filelengthError">{{'filelength validation' | translate}}</mat-error>
            <mat-error class="m-t-10" *ngIf="fileFormatError">{{'fileformat validation' | translate}}</mat-error> <mat-error class="m-t-10" *ngIf="fileFormatError">{{Extn}}</mat-error>
          </div>
          <div class="dialog-footer">
            <div fxLayout="row wrap">
              <button *ngIf="!filesizevalidation && !filelengthError" mat-button (click)="attachFiles()"
                class="btn btn-primary-rect m-t-20 m-r-10">{{'Attach'|translate}} </button>
              <button mat-button class="btn btn-secondary-rect m-t-20" (click)="dialogRef.close()">
                {{'cancel'|translate}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

</div>