import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateTimeFormat'
})
export class DateTimeFormatPipe implements PipeTransform {

  transform(value: any, format: string = 'dd/MM/yy, h:mm a'): any {
    const datePipe: DatePipe = new DatePipe('en-US');
    return datePipe.transform(value, format);
  }

}
