import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { URLConstants } from 'src/app/core/constants/urlConstants';
import { LoaderService } from 'src/app/data/service/loader.services';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionType, InteractionStatus, PopupRequest, RedirectRequest, AuthenticationResult, AuthError, SilentRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { AlertType, MHAConfidencelevel, MHAErrorCode, MHAErrorDescription, MHAErrorTitle } from 'src/app/core/constants/app-enums';
import { IAlertDialog } from '../interface/Idialog';
import { AuthenticationService } from './authentication.service';
import { Idle } from '@ng-idle/core';
import { TimeOutService } from './timeOut.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from 'src/app/shared/component/alert-dialog/alert-dialog.component';
import { TranslateService } from '@ngx-translate/core';
interface Payload extends AuthenticationResult {
  idTokenClaims: {
    tfp?: string
  }
}
@Injectable({
  providedIn: 'root'
})

export class MHAService {
  showAlert = false;
  timeInterVal: any;
  timedOutInterval = 40;
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();
  payload: Payload;
  userInfo: string;
  failedResponse: EventMessage;
  mhaUserInfoRes: Object;
  formatDate: any;
  alertDialog: IAlertDialog;  
  mhaRequestCount: number = 0;
  private mhaData: any;
  constructor( private router: Router,
    private http: HttpClient,   
    public translate: TranslateService,
    public dialog: MatDialog,
    private timedOutService: TimeOutService,
    private idle: Idle,    
    private loaderService: LoaderService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private authenticationService: AuthenticationService) {

  }

//#region MHA Login

checkAndSetActiveAccount() {
  /**
   * If no active account set but there are accounts signed in, sets first account to active account
   * To use active account set here, subscribe to inProgress$ first in your component
   * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
   */
  let activeAccount = this.authService.instance.getActiveAccount();
  if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
    let accounts = this.authService.instance.getAllAccounts();    
    this.authService.instance.setActiveAccount(accounts[0]);
  }
}

loginWithPopup() {
  this.msalGuardConfig.interactionType = InteractionType.Popup;
  this.login();
}

loginWithRedirect() {
  this.msalGuardConfig.interactionType = InteractionType.Redirect;
  this.login();
}

login(userFlowRequest?: RedirectRequest | PopupRequest) {
  //this.mhaRequestCount = 0;
  if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginPopup({
        scopes: ["openid profile " + environment.clientIdMHA + " email"]
      })
      this.authService.loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest)
        .subscribe((response: AuthenticationResult) => {         
          this.authService.instance.setActiveAccount(response.account);          
        });
    } else {
      this.authService.loginPopup(userFlowRequest)
        .subscribe((response: AuthenticationResult) => {         
          this.authService.instance.setActiveAccount(response.account);
        });
    }
  } else {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
    } else {
      this.authService.loginRedirect(userFlowRequest);
    }
  }
}

logout() {
  if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
    this.authService.logoutRedirect();      
    //** Code below is causing pop up blocker so using above */

    // this.authService.logoutPopup({
    //   mainWindowRedirectUri: "/authentication/login"
    // });
  } else {
    this.authService.logoutRedirect();
  }
}

getUserInfo() : Observable<any> {
  //console.log('In register get info');
  return this.http.get(environment.userInfoUriMHA);
}

forceLogout() {
  this.showAlert = false;
  this.router.navigateByUrl('/authentication/login');
}

reset() {
  this.idle.watch();
  this.showAlert = false;
}


showMhaErrorMessage(title: any, errorMessage: any) {
  this.alertDialog = {
    title: this.translate.instant(title),
    message: this.translate.instant(errorMessage),
    alertType: AlertType.None,
    okButtonText: this.translate.instant('OK')
  };
  const dialogRef = this.dialog.open(AlertDialogComponent, {
    data: this.alertDialog
  });
  dialogRef.afterClosed().subscribe((result: any) => {
  });
}

public setMHAData(mhaData:any){
  this.mhaData = mhaData;
}
public getMHAData(){
  return this.mhaData;
}
//#endregion MHA Login
}