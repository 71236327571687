<footer class="footer-menu">
        <nav>
            <ul>
                <li>
                    <a href="{{commonURL.technicalSupportURL}}" target="_blank" >Support</a>
                </li>
                <li>
                    <a href="{{commonURL.fAQURL}}" target="_blank" >FAQ</a>
                </li>
                <li>
                    <a href="{{commonURL.termsofUsePlURL}}" target="_blank" >Terms of Use</a>
                </li>
                <!-- <li *ngIf="!isPatient">
                    <a href="{{commonURL.BTConditionsURL}}">Business Terms & Conditions</a>
                </li> -->
                <li>
                    <a href="{{commonURL.privacyPlURL}}" target="_blank" >Privacy Policy</a>
                </li>

                <!--This Link is Commented for the  Bug 7551: [Patient & Provider][Footer Content]Content Should show correct and it should open in new tab like v2 patient portal. -->
                <!-- <li>
                    <a href="{{commonURL.codeOfConductPlURL}}"  >Code Of Conduct</a>
                </li>
                <li>
                    <a href="{{commonURL.serviceSpecification}}"  >Service Specification</a>
                </li> -->
                <li>
                    <a href="{{commonURL.securityPlURL}}" target="_blank" >Security</a>
                </li>
            </ul>
        </nav>
        <p>© 2008 - {{currentYear}} Manage My Health Ltd. All rights reserved.</p>
</footer>