<div mat-dialog-title class="dialog-title-bar m-b-0">
  <h2 class="font-900">Terms & Conditions</h2>
  <!-- <button mat-button mat-flat-button mat-dialog-close class="dialog-close-btn" (click)="onClose()">
    <span class="k-icon k-i-x"></span>
  </button> -->
</div>

<mat-dialog-content class="mat-typography mat-dialog-content-inputs formatted-text">
  <!-- <p>{{data.terms}}</p>  -->
  <!-- <div class="alert-data" style="word-break: break-word;" [innerHTML]="data.terms"></div> -->
  <iframe #iframeElement title="test" style="word-break: break-word; height: 2400px;"class="custom-iframe formatted-text" [srcdoc]="formattedContent" (load)="adjustIframeHeight()"></iframe>
</mat-dialog-content>

<mat-dialog-actions class="dialog-actions-btns">

  <button *ngIf="!data.isAppointmentScreen" mat-button mat-dialog-close class="btn-primary-rect">OK</button>
  <button *ngIf="data.isAppointmentScreen" mat-button mat-dialog-close class="btn-primary-rect" (click)="onConfirm()">I AGREE</button>
  <button *ngIf="data.showCancel" mat-button mat-dialog-close class="btn-secondary-rect" (click)="onReject()">CANCEL</button>

</mat-dialog-actions>
