import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from '../interface/user';
import { TokenService } from 'src/app/data/service/token.service';
import { URLConstants } from 'src/app/core/constants/urlConstants';
import { Router } from '@angular/router';
import { TimeOutService } from './timeOut.service';
import { AppService } from './app.service';
import { ToasterService } from './toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { PaymentService } from './payment.service';

const LOGIN_PATH = '/api/Account/SignIn';
const REGISTER_PATH = '/api/Account/Register';
const ACTIVATEACCOUNT = '/api/Account/UserActivation';
const RESENDEMAIL = '/api/Account/ResendEmail?useremail=';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  iframeSignout: any = true;
  dailogPaymentStatus=false;
  public dontshowidlepopup = false;

  constructor(private http: HttpClient, private tokenservice: TokenService, private router: Router,
    private toaster: ToasterService, private translate: TranslateService,
    private timedOutService: TimeOutService, private service: PaymentService,
    private appService: AppService) {
    let currentUserData: any = this.tokenservice.token();
    this.currentUserSubject = new BehaviorSubject<User>(currentUserData);
    this.currentUser = this.currentUserSubject.asObservable();

  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  logout() {
    this.service.iframeSignout.subscribe(data => {
      this.iframeSignout = data;
    });
    if (this.iframeSignout) {
      // remove user from session storage and set current user to null
      this.tokenservice.clearsessionStorageValues();
      this.appService.setUserLoggedIn(false, 0);
      this.timedOutService.stopTimer();
      this.appService.setIdlePopUpStatus(false, 0);
      this.appService.callPrescreeningClosedMethod(true);
      this.appService.callVonagePopupClosedMethod(true);
      this.toaster.success(this.translate.instant('You\'\ ve successfully logged out of Manage My Health'));
      if (sessionStorage.getItem("isBetaUser") == "true") {
        sessionStorage.removeItem('isBetaUser');
        location.href = environment.v1URL;
      } else {
        this.router.navigate(['/authentication/login']);
      }
    }
    else{
      this.dailogPaymentStatus=true;
      this.service.dailogStatus(this.dailogPaymentStatus);
    }

  }

  enableModelStateError() {
    this.appService.callModelErrorStateMethod(true);
  }

  activateAccount(userId: any, activationCode: any) {
    return this.http.get<any>(`${environment.apiBaseURL}${ACTIVATEACCOUNT}/${userId}/${activationCode
      }`, {})
  }


  resendEmail(email: any) {
    return this.http.get<any>(`${environment.apiBaseURL}${RESENDEMAIL}${email}`, {
    })
  }

  ok(body?: any) {
    return of(new HttpResponse({ status: 200, body }))
  }
  error(message: any) {
    return throwError({ error: { message } });
  }
  getUserProfile(userId: any) {
    return this.http.get<any>(`${environment.apiBaseURL}${URLConstants.getUserProfile}/${userId}`, {
      headers: {
        "Authorization": "Bearer " + this.tokenservice.token(),
        "Content-Type": 'application/json'
      }
    })
  }
  forgotPassword(email: any) {
    return this.http.get<any>(`${environment.apiBaseURL}${URLConstants.forgotUserPassword}${email}`, {

    }
    )
  }
 
  userVerificationForPasswordReset(verificationLink: any) {
    // verificationLink=decodeURI(verificationLink)
    return this.http.get<any>(`${environment.apiBaseURL}${URLConstants.userVerificationForPasswordReset}` + verificationLink, {
      // return this.http.get<any>('http://apiv2.mmh.com:8080/api/Account/UserVerificationForRestPassword?q=NAU1aOVdFBrddiEJGXOZfdXbeyj5ZFXZXt5uha%252f%252bSeW1XahTzcYd5z7Jb9XwqZc%252bTXpe%252fDlK%252f4rNohW2rqMmV4OwXwO%252fRi%252fwqo64gDSNaRjxa1rSC56NFyh9DTtZQi5Q',{

    }
    )
  }
  optoutUserEmail(email: any,optouttype: any,captchaValue: any) {  

    return this.http.get<any>(`${environment.apiBaseURL}${URLConstants.updateOptoutService}?email=${email}&optouttype=${optouttype}&captchaValue=${captchaValue}`, {

    }
    )
  }

}