<div class="success content-panel">
    <div class="alert alert-error m-t-40">
      <b>Failure</b><br />
      <p>
        {{paymentRespose?.PaymentStatus}}
      </p>
      <!-- <p class="text-right">{{paymentRespose?.Ref}}</p> -->
    </div>
    <!-- <div class="left-button m-t-20 header-buttons">
      <button mat-button class="btn btn-primary" [routerLink]="['/appointment/future']">
        Back to Booking
      </button>
    </div> -->
    <mat-card class="m-t-40 m-0">
      <mat-card-content>
        <div fxLayout="row wrap" class="m-b-10" class="centre">
          <div fxFlex.gt-sm="25" fxFlex.gt-xs="25" fxFlex="100">
            <p><b>MMH Transcation Ref</b> </p>
          </div>
          <div fxFlex.gt-sm="25" fxFlex.gt-xs="25" fxFlex="100">
            <p>{{paymentRespose?.MMHRefNo}}</p>
          </div>
        </div>
        <div fxLayout="row wrap" class="m-b-10" class="centre">
          <div fxFlex.gt-sm="25" fxFlex.gt-xs="25" fxFlex="100">
            <p><b>Payment Status</b> </p>
          </div>
          <div fxFlex.gt-sm="25" fxFlex.gt-xs="25" fxFlex="100">
            <p>{{paymentRespose?.PaymentStatus}}</p>
          </div>
        </div>
        <div fxLayout="row wrap" class="m-b-10" class="centre">
          <div fxFlex.gt-sm="25" fxFlex.gt-xs="25" fxFlex="100">
            <p><b>Response Code</b> </p>
          </div>
          <div fxFlex.gt-sm="25" fxFlex.gt-xs="25" fxFlex="100">
            <p>{{paymentRespose?.TextResponse}}</p>
          </div>
        </div>
        <div fxLayout="row wrap" class="m-b-10" class="centre">
          <div fxFlex.gt-sm="25" fxFlex.gt-xs="25" fxFlex="100">
            <p><b>Reason</b> </p>
          </div>
          <div fxFlex.gt-sm="25" fxFlex.gt-xs="25" fxFlex="100">
            <p>{{paymentRespose?.ResponseText}}</p>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>