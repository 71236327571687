import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Options } from 'selenium-webdriver';
// Angular CLI configuration thing.
import { ToasterService } from 'src/app/data/service/toaster.service';
import { environment } from '../../../environments/environment';
export interface IRequestOptions {
  headers?: HttpHeaders;
  observe?: 'body';
  params?: HttpParams;
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
  body?: any;
}
export function AlexApplicationHttpClientCreator(http: HttpClient,ToasterService:ToasterService) {
  return new ApplicationHttpClient(http,ToasterService);
}
@Injectable()
export class ApplicationHttpClient {
  private api = environment.AlexAPIUrl;
  // Extending the HttpClient through the Angular DI.
  public constructor(public http: HttpClient,   
 
    private toaster: ToasterService) {
  }
  /**
   * GET request
   * @param {string} endPoint it doesn't need / in front of the end point
   * @param {IRequestOptions} options options of the request like headers, body, etc.
   * @param {string} api use if there is needed to send request to different back-end than the default one.
   * @returns {Observable<T>}
   */
  public Get<T>(endPoint: string, options?: IRequestOptions) {    
    return new Promise((resolve, reject) => {
      this.http.get<T>(this.api + endPoint, this.getHeaders(options))
      .subscribe((data: any) => { 
              //this.ionLoadinService.hideLoader();
                                                                                            
          resolve(data);
      }, (error: any) => {  
        if(error.status ===500){
          this.toaster.error("We have encountered an issue with appointment service and currently unavailable and we apologize for any inconvenience caused. Our team is actively working to resolve the problem and restore it as soon as possible. However, we encourage you contacting your health center directly via phone.")
        }
         if (error.status === 401) {
         }
         else if(error.status === 404){
           resolve(404);
         }else{
          reject(error);
         } 
          //this.handleError(error);
      });          
    });  
    //return this.http.get<T>(this.api + endPoint, this.getHeaders(options)).subscribe(data =>{
  
  
  }
  /**
   * POST request
   * @param {string} endPoint end point of the api
   * @param {Object} params body of the request.
   * @param {IRequestOptions} options options of the request like headers, body, etc.
   * @returns {Observable<T>}
   */
  public Post<T>(endPoint: string, params: Object, options?: IRequestOptions) {
    //return this.http.post<T>(this.api + endPoint, params, this.getHeaders(options));  
    return new Promise((resolve, reject) => {
      this.http.post<T>(this.api + endPoint, params, this.getHeaders(options))
      .subscribe((data: any) => { 
              //this.ionLoadinService.hideLoader();                                                                                      
          resolve(data);
      }, (error: any) => {  
         if (error.status === 401) {
         }
         else if(error.status === 404){
          resolve(404);
         }else{
          reject(error);
         }
          //this.handleError(error);
      });          
    }); 
  }
  /**
   * PUT request
   * @param {string} endPoint end point of the api
   * @param {Object} params body of the request.
   * @param {IRequestOptions} options options of the request like headers, body, etc.
   * @returns {Observable<T>}
   */
  public Put<T>(endPoint: string, params: Object, options?: IRequestOptions) {
    //return this.http.put<T>(this.api + endPoint, params, this.getHeaders(options));    
    return new Promise((resolve, reject) => {
      this.http.put<T>(this.api + endPoint, params, this.getHeaders(options))
      .subscribe((data: any) => { 
              //this.ionLoadinService.hideLoader();                                                                                      
          resolve(data);
      }, (error: any) => {  
         if (error.status === 401) {
         }
         else if(error.status === 404){
          resolve(404);
         }else{ 
          reject(error);
         }
          //this.handleError(error);
      });          
    }); 
  }
  /**
   * DELETE request
   * @param {string} endPoint end point of the api
   * @param {IRequestOptions} options options of the request like headers, body, etc.
   * @returns {Observable<T>}
   */
  public Delete<T>(endPoint: string, options?: IRequestOptions) {
    return new Promise((resolve, reject) => {
      this.http.delete<T>(this.api + endPoint, this.getHeaders(options))
      .subscribe((data: any) => { 
              //this.ionLoadinService.hideLoader();                                                                                      
          resolve(data);
      }, (error: any) => {  
         if (error.status === 401) {
         }
         else if(error.status === 404){
          resolve(404);
         }else{
          reject(error);
         }
          //this.handleError(error);
      });          
    }); 
   // return this.http.delete<T>(this.api + endPoint, this.getHeaders(options));
  }
  private getHeaders(options?: IRequestOptions){
    options = {};
    options.headers = new HttpHeaders({
    "Content-Type": "application/json",
    "Authorization": "bearer " + sessionStorage.getItem("authToken")});
    return options;
  }
}
