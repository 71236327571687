import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import * as signalR from '@microsoft/signalr';
import { HttpClient } from '@angular/common/http';
import { URLConstants } from 'src/app/core/constants/urlConstants';
import { ToasterService } from './toaster.service';

@Injectable({
  providedIn: 'root',
})
export class SignalrService {
  connection: signalR.HubConnection;
  hubHelloMessage: BehaviorSubject<string>;
  progressPercentage: BehaviorSubject<number>;
  message: BehaviorSubject<string>;
  callRing: BehaviorSubject<boolean>;
  reload: BehaviorSubject<boolean>;
  sendSignalToProvider: BehaviorSubject<boolean>;
  rejectCallByProvider: BehaviorSubject<boolean>;

  constructor(private http: HttpClient, private toaster: ToasterService) {
    this.hubHelloMessage = new BehaviorSubject<string>('');
    this.message = new BehaviorSubject<string>('');
    this.callRing = new BehaviorSubject<boolean>(false);
    this.reload = new BehaviorSubject<boolean>(false);
    this.sendSignalToProvider = new BehaviorSubject<boolean>(false);
    this.rejectCallByProvider = new BehaviorSubject<boolean>(false);
  }

  // Establish a connection to the SignalR server hub
  userId: any;
  userType: any;
  public initiateSignalrConnection(userId: any, userType: any, meetingId?: any, receiveObservable?: boolean,  sendMessage?: boolean, message?: any,
                                   receiverUserId?: any, receiverUserType?: any): Promise<void> {
    return new Promise((resolve, reject) => {
      this.userId = userId;
      this.userType = userType;
      this.connection = new signalR.HubConnectionBuilder()
        .withUrl(environment.signalrHubUrl) // the SignalR server url as set in the .NET Project properties and Startup class
        .build();
      this.setSignalrClientMethods();
      this.connection
        .start()
        .then(() => {
          sessionStorage.setItem('connectionId', this.connection.connectionId ? this.connection.connectionId : '');
          this.http.post(environment.apiBaseURL + URLConstants.insertConnectionId,
            {
              ConnectionId: this.connection.connectionId,
              SignalRUserId: userId,
              UserType: userType,
              MeetingID: meetingId
            }
          ).subscribe((response: any) => {
            if (response){
              if(receiveObservable){
                this.sendSignalToProvider.next(true);
              }
              if (sendMessage) {
                this.http.post(environment.apiBaseURL + URLConstants.getConnectionId,
                  {
                    SignalRUserId: receiverUserId,
                    UserType: receiverUserType,
                    MeetingID: 0
                  }
                ).subscribe((res: any) => {
                  if (res.length > 0){
                    this.connection
                    .invoke('SendMessage', res[0].ConnectionId, message)
                    .then(() => {
                    })
                    .catch((error) => {
                      console.log(`SignalrDemoHub.sendMessageForDoctor() error: ${error}`);
                      // alert(
                      //   'SignalrDemoHub.sendMessageForDoctor() error!, see console for details.'
                      // );
                    });
                  }
                });
              }
            }
          });

          console.log(
            `SignalR connection success! connectionId: ${this.connection.connectionId} `
          );
          resolve();
        })
        .catch((error) => {
          console.log(`SignalR connection error: ${error}`);
          reject();
        });
    });
  }

  // This method will implement the methods defined in the ISignalrDemoHub interface in the SignalrDemo.Server .NET solution
  private setSignalrClientMethods(): void {
    this.connection.on('Message', (message: string) => {
      this.message.next(message);
      console.log(message);
      if(message == 'deny') {
        this.toaster.success('Provider has not accepted your call');
        this.rejectCallByProvider.next(true);
      }
      // this.toaster.info(message);
    });
  }


  sendMessage(receiverUserId: any, receiverUserType: number, message: string, meeetingId: any, reload?: boolean): any {
    this.http.post(environment.apiBaseURL + URLConstants.getConnectionId,
      {
        SignalRUserId: receiverUserId,
        UserType: receiverUserType,
        MeetingID: meeetingId
      }
    ).subscribe((res: any) => {
      if (res.length > 0){
        this.connection
        .invoke('SendMessage', res[0].ConnectionId, message)
        .then(() => {
          reload ? this.reload.next(true) : this.reload.next(false);
        })
        .catch((error) => {
          console.log(`SignalrDemoHub.sendMessageForDoctor() error: ${error}`);
          // alert(
          //   'SignalrDemoHub.sendMessageForDoctor() error!, see console for details.'
          // );
        });
      }
    });
  }
}
