import { URLConstants } from '../../core/constants/urlConstants';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { TokenService } from 'src/app/data/service/token.service';
import { AlexApplicationHttpClientCreator } from 'src/app/data/interface/IAlexRequestOptions';
//Added to construct the request paramaters for get and post method.
import { RequestService } from 'src/app/core/helper/request-service';
import { environment } from 'src/environments/environment';
import { ToasterService } from 'src/app/data/service/toaster.service';
@Injectable({
  providedIn: 'root'
})
export class IAlexService {
  constructor(private _httpService: HttpClient,
    private _request: RequestService,
    private toaster: ToasterService) { }
  
  //  getCommonPractice(UserId: string,PatientId: string) {
  //    return this._httpService.get(environment.apiBaseURL + URLConstants.getCommonPractice + UserId + '&PatientId=' + PatientId);
  //  }
  ///Get calls from Alex API
  public GetAppointmentSlotsByProviderHpiId(params: any) {
    return AlexApplicationHttpClientCreator(this._httpService,this.toaster).Get(URLConstants.GetAppointmentSlotsByProviderHpiId+'?'+this._request.ConstructGetRequest(params));
  }     

  public GetPractitionerDetailsByHpiId(params: any) {
    return AlexApplicationHttpClientCreator(this._httpService,this.toaster).Get(URLConstants.GetPractitionerDetailsByHpiId +'?'+ this._request.ConstructGetRequest(params));
  }   

  public GetPatientDetailsByNhiId(params: any) {
    return AlexApplicationHttpClientCreator(this._httpService,this.toaster).Get(URLConstants.GetPatientDetailsByNhiId +'?'+ this._request.ConstructGetRequest(params));
  }
  
  public GetCurrrentAppointmentDetailsBySlotId(params: any) {
    return AlexApplicationHttpClientCreator(this._httpService,this.toaster).Get(URLConstants.GetCurrrentAppointmentDetailsBySlotId +'?'+ this._request.ConstructGetRequest(params));
  }

  public GetCurrrentAppointmentDetailsByAppointmentId(params: any) {
    return AlexApplicationHttpClientCreator(this._httpService,this.toaster).Get(URLConstants.GetCurrrentAppointmentDetailsByAppointmentId +'?'+ this._request.ConstructGetRequest(params));
  }

  public GetCurrrentAppointmentDetailsBySlotIdAndPatientNhiID(params: any) {
    return AlexApplicationHttpClientCreator(this._httpService,this.toaster).Get(URLConstants.GetCurrrentAppointmentDetailsBySlotIdAndPatientNhiID +'?'+ this._request.ConstructGetRequest(params));
  }

  public GetCurrrentAppointmentDetailsByStartEndDateAndProviderHpiId(params: any) {
    return AlexApplicationHttpClientCreator(this._httpService,this.toaster).Get(URLConstants.GetCurrrentAppointmentDetailsByStartEndDateAndProviderHpiId +'?'+ this._request.ConstructGetRequest(params));  }

  public GetCurrrentAppointmentDetailsByStartEndDateAndPatiendNhiId(params: any) {
    return AlexApplicationHttpClientCreator(this._httpService,this.toaster).Get( URLConstants.GetCurrrentAppointmentDetailsByStartEndDateAndPatiendNhiId +'?'+ this._request.ConstructGetRequest(params));
  }

  public GetCurrrentAppointmentDetailsByStartEndDatePatiendNhiIdAndBookingStatus(params: any) {
    return AlexApplicationHttpClientCreator(this._httpService,this.toaster).Get(URLConstants.GetCurrrentAppointmentDetailsByStartEndDatePatiendNhiIdAndBookingStatus +'?'+ this._request.ConstructGetRequest(params));
  }
  
  public UpdateAppointmentStatusByAppointmentId(params: any, queryParams: string) {
    return AlexApplicationHttpClientCreator(this._httpService,this.toaster).Put(URLConstants.UpdateAppointmentStatusByAppointmentId +'?mt_facilityid='+queryParams, params);
  }
  
   
  public GetPractitionerDetailsByNZMC(params: any) {
    return AlexApplicationHttpClientCreator(this._httpService,this.toaster).Get(URLConstants.GetPractitionerDetailsByNZMC +'?'+ this._request.ConstructGetRequest(params));
  }
  
   
  public GetPractitionerDetailsById(params: any) {
    return AlexApplicationHttpClientCreator(this._httpService,this.toaster).Get(URLConstants.GetPractitionerDetailsById +'?'+ this._request.ConstructGetRequest(params));
  }

  public GetPatientDetailsWithoutNhiId(params: any) {
    return AlexApplicationHttpClientCreator(this._httpService,this.toaster).Get(URLConstants.GetPatientDetailsWithoutNhiId +'?'+ this._request.ConstructGetRequest(params));
  }

  public GetPatientDetailsById(params: any) {
    return AlexApplicationHttpClientCreator(this._httpService,this.toaster).Get( URLConstants.GetPatientDetailsById +'?'+ this._request.ConstructGetRequest(params));
  }
  public GetAllPractitionersByFacilityId(params: any) {        
    return AlexApplicationHttpClientCreator(this._httpService,this.toaster).Get(URLConstants.GetAllPractitionersByFacilityId+'?'+this._request.ConstructGetRequest(params));
  }

  //*************Post calls to Alex API*/

  public BookAppointmentWithProvider(params: any, queryParams:string) {
    return AlexApplicationHttpClientCreator(this._httpService,this.toaster).Post(URLConstants.BookAppointmentWithProvider+'?mt_facilityid='+queryParams, params );
  }  
 
  
 
}

