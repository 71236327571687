import {
  BrowserModule
} from '@angular/platform-browser';
import {
  ErrorHandler,
  NgModule
} from '@angular/core';
import {
  RouterModule
} from '@angular/router';
import {
  FormsModule, ReactiveFormsModule
} from '@angular/forms';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import {
  DatePipe
} from '@angular/common';
import {
  AppRoutes
} from './app.routing';
import {
  AppComponent
} from './app.component';

import {
  FlexLayoutModule
} from '@angular/flex-layout';
import {
  FullComponent
} from './layouts/full/full.component';
import {
  VerticalAppHeaderComponent
} from './layouts/full/vertical-header/vertical-header.component';
import {
  VerticalAppSidebarComponent
} from './layouts/full/vertical-sidebar/vertical-sidebar.component';
import {
  HorizontalAppHeaderComponent
} from './layouts/full/horizontal-header/horizontal-header.component';
import {
  HorizontalAppSidebarComponent
} from './layouts/full/horizontal-sidebar/horizontal-sidebar.component';

import {
  AppBreadcrumbComponent
} from './layouts/full/breadcrumb/breadcrumb.component';

import {
  BrowserAnimationsModule
} from '@angular/platform-browser/animations';
import {
  MaterialModule
} from './material-module';
import {
  NgMultiSelectDropDownModule
} from 'ng-multiselect-dropdown';

import {
  PerfectScrollbarModule
} from 'ngx-perfect-scrollbar';
import {
  PERFECT_SCROLLBAR_CONFIG
} from 'ngx-perfect-scrollbar';
import {
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';

import {
  SpinnerComponent
} from './shared/component/spinner.component';

import {
  TranslateLoader,
  TranslateModule
} from '@ngx-translate/core';
import {
  TranslateHttpLoader
} from '@ngx-translate/http-loader';

import { LoaderInterceptor } from './core/interceptor/loader.interceptor';
import { ToasterComponent } from './shared/component/toaster/toaster.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthInterceptor } from './core/interceptor/authInterceptor';
import { ApplicationHttpClient, applicationHttpClientCreator } from './data/interface/IRequestOptions';
import { ErrorInterceptor } from './core/interceptor/error.interceptor';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PopupModule } from '@progress/kendo-angular-popup';
import { AlertDialogComponent } from './shared/component/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from './shared/component/confirm-dialog/confirm-dialog.component';
import { EditorModule } from '@progress/kendo-angular-editor';
import { HomeComponent } from './modules/pages-public/home/home.component';
import { ContactUsComponent } from './modules/pages-public/contact-us/contact-us.component';
import { HowToRegisterComponent } from './modules/pages-public/how-to-register/how-to-register.component';
import { MailBoxService } from './data/service/mail-box.service';
import { ComposeComponent } from './modules/pages-inbox/compose/compose.component';
import { DetailComponent } from './modules/pages-inbox/detail/detail.component';
import { MailListComponent } from './modules/pages-inbox/mail-list/mail-list.component';
import { SentComponent } from './modules/pages-inbox/sent/sent.component';
import { DraftsComponent } from './modules/pages-inbox/drafts/drafts.component';
import { GroupsComponent } from './modules/pages-inbox/groups/groups.component';
import { ArchiveComponent } from './modules/pages-inbox/archive/archive.component';
import { DraftdetailComponent } from './modules/pages-inbox/draftdetail/draftdetail.component';
import { ArchivedetailComponent } from './modules/pages-inbox/archivedetail/archivedetail.component';
import { AttachfileDialogComponent } from './modules/pages-inbox/attachfile-dialog/attachfile-dialog.component';
import { MovetoinboxDialogComponent } from './modules/pages-inbox/movetoinbox-dialog/movetoinbox-dialog.component';
import { SettingsComponent } from './modules/pages-inbox/settings/settings.component';
import { MMHV2SharedModule } from './shared/shared.module';
import { DialogModule } from '@progress/kendo-angular-dialog';
import { CommonConstants } from 'src/app/core/constants/commonConstants';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { MatMomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';

import {
  Routes,
  PreloadingStrategy,
  PreloadAllModules
} from "@angular/router";
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { RaygunErrorHandler } from './app.raygun.setup';
import { EmptyComponent } from './layouts/full/empty.component';
import { PrimengModule } from './primeng/primeng.module';
import { VideoConsultationModule } from './modules/pages-video-consultation/pages-video-consultation.module';
import { ConfirmDialogComponentWithInnerHtml } from './shared/component/confirm-dialog-textAsHtml/confirm-dialog-textAsHtml.component';
import { RequestService } from 'src/app/core/helper/request-service';
import { AuthServer } from 'src/app/data/service/auth.server';
import { IAlexService } from 'src/app/data/service/alex.service'
import { AlexIntegrationService } from 'src/app/data/service/alex-integration'
import { ServiceDialogComponent } from './shared/component/service-dialog/service-dialog.component';
import { RecallRemainderDialogComponent } from './layouts/full/vertical-header/recall-remainder-dialog/recall-remainder-dialog.component';
import { LinkNewHealthCenterDialogComponent } from './layouts/full/vertical-header/link-new-health-center-dialog/link-new-health-center-dialog.component';
// import { PatientFutureAppointmentsComponent } from './src/app/modules/pages-appointment/patient-future-appointments/patient-future-appointments.component';
// import { PatientPastAppointmentsComponent } from './src/app/modules/pages-appointment/patient-past-appointments/patient-past-appointments.component';
// import { NavigateRoutesComponent } from './modules/pages-feature/navigate-routes/navigate-routes.component';


import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { LinkedAccountRemainderComponent } from './layouts/full/vertical-header/linked-account-remainder/linked-account-remainder.component';
import { SmsRegistrationComponent } from './modules/sms-registration/sms-registration/sms-registration.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MicroserviceInterceptor } from './core/interceptor/microservice.interceptor';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
export function loggerCallback(logLevel: LogLevel, message: string) {
}


export function HttpLoaderFactory(http: HttpClient): any {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
export function MSALInstanceFactory(): IPublicClientApplication {
  const instance = new PublicClientApplication({
    auth: {
      clientId: environment.clientIdMHA,
      authority: environment.authorityMHA,
      redirectUri: environment.redirectUriMHA,
      postLogoutRedirectUri: environment.v1URL,
      knownAuthorities: [environment.knownAuthoritiesMHA]
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: true, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });

  return instance;
}
const apiConfig: { scopes: string[]} = {
  scopes: ["openid", "profile", "email",""+environment.clientIdMHA+""]
};
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  //const protectedResourceMap = new Map(protectedResourceMap2);
  protectedResourceMap.set(environment.userInfoUriMHA, apiConfig.scopes);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Popup,
    authRequest: {
      scopes: ["openid", "profile", "email"],
    },
    loginFailedRoute: 'login-failed'
  };
}
const isIframe = window !== window.parent && !window.opener;

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};
@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    EmptyComponent,
    VerticalAppHeaderComponent,
    RecallRemainderDialogComponent,
    SpinnerComponent,
    VerticalAppSidebarComponent,
    AppBreadcrumbComponent,
    HorizontalAppHeaderComponent,
    HorizontalAppSidebarComponent,
    ComposeComponent,
    DetailComponent,
    MailListComponent,
    SentComponent,
    DraftsComponent,
    GroupsComponent,
    ArchiveComponent,
    HomeComponent,
    ToasterComponent,
    ContactUsComponent,
    HowToRegisterComponent,
    DraftdetailComponent,
    ArchivedetailComponent,
    AttachfileDialogComponent,
    MovetoinboxDialogComponent,
    AlertDialogComponent,
    ConfirmDialogComponent,
    ServiceDialogComponent,
    SettingsComponent,
    ConfirmDialogComponentWithInnerHtml,
    LinkNewHealthCenterDialogComponent,
    LinkedAccountRemainderComponent,
    SmsRegistrationComponent,
    // PatientFutureAppointmentsComponent,
    // PatientPastAppointmentsComponent,
    // NavigateRoutesComponent,
  ],
  imports: [
    EditorModule,
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    FlexLayoutModule,
    HttpClientModule,
    PerfectScrollbarModule,
    MMHV2SharedModule,
    MatIconModule,
    MatFormFieldModule,
    NgMultiSelectDropDownModule.forRoot(),
    // MatInputModule,
    // MatDatepickerModule,
    // MatMomentDateModule,
    RouterModule.forRoot(AppRoutes, {
      enableTracing:false, preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation:'reload',
	  scrollPositionRestoration: 'enabled',
    useHash: true
    }),
    HttpClientModule,
    NgxCaptchaModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    InputsModule,
     PopupModule,
    ButtonsModule,
    Ng2SearchPipeModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    DialogModule,
    NgIdleKeepaliveModule.forRoot(),
    PrimengModule,
    DropDownsModule,
    DateInputsModule,
    MsalModule,
    PdfViewerModule
  ],
  providers: [
    // { provide: ErrorHandler,
    //   useClass: RaygunErrorHandler
    // },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: MatDialogRef,
      useValue: {}
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      // provide: HTTP_INTERCEPTORS,
      // useClass:LoaderInterceptor,
      // multi: true
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MicroserviceInterceptor,
      multi: true
    },
    {
      provide: ApplicationHttpClient,
      useFactory: applicationHttpClientCreator,
      deps: [HttpClient]
    },
    {provide: DateAdapter, useClass: MomentDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: CommonConstants.MY_DATE_FORMATS },
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
    DatePipe,
    MailBoxService,
    RequestService,
    AuthServer,
    IAlexService,
    AlexIntegrationService,
    HttpClient,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
    provide: MSAL_INSTANCE,
    useFactory: MSALInstanceFactory
    },
    {
    provide: MSAL_GUARD_CONFIG,
    useFactory: MSALGuardConfigFactory
    },
    {
    provide: MSAL_INTERCEPTOR_CONFIG,
    useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  exports: [ToasterComponent],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
