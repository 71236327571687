import { Component, EventEmitter, OnDestroy, OnInit, Output, Input } from '@angular/core';
import { MailBoxService } from 'src/app/data/service/mail-box.service';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToasterService } from 'src/app/data/service/toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { AttachfileDialogComponent } from '../attachfile-dialog/attachfile-dialog.component';
// import * as xml2js from 'xml2js';
import { InBoxService } from 'src/app/data/service/inbox.service';
import { TokenService } from 'src/app/data/service/token.service';
import { environment } from 'src/environments/environment';
import { URLConstants } from 'src/app/core/constants/urlConstants';
import * as moment from 'moment';
import { AlertDialogComponent } from 'src/app/shared/component/alert-dialog/alert-dialog.component';
import { AlertType } from 'src/app/core/constants/app-enums';
import { IAlertDialog } from 'src/app/data/interface/Idialog';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { formatDate } from '@angular/common';
import { interval, observable, Subject } from 'rxjs';
import { PerfectScrollbarComponent, PerfectScrollbarConfigInterface,PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { Router } from '@angular/router';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};

@Component({
  selector: 'app-detailmailbo',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit,OnDestroy {
  detailForm: FormGroup;
  public selectedMessagesDetails: any = [];
  public Subject: any
  public bodymessage: any
  public userprofile: any
  public userId: any;
  public practiceAdmin: any;
  public locations: any = [];
  public Roles: any = [];
  public serviceTypes: any = [];
  public locationsId: number;
  public userRoleId: any;
  public files: any = [];
  public addedFiles: any[] = [];
  public Attachment: any;
  public messageId: any;
  public filelengthError: boolean = false;
  public generateXML: string;
  public filesarray: any = [];
  public getPracticeData: any = [];
  public showdetails: boolean = false;
  public xmltoJSON: any = [];
  public currentIndex = 0;
  public isSelectAll: boolean = false;
  page: number = 0;
  pagesize: number = 10;
  searchText: any = "";
  public filesData: any = [];
  alertDialog :IAlertDialog;
  draftAttachmentXML: string;
  saveIndicator: string;
  destroy = new Subject();
  draftCount:any;
  liveStatusSub: any;
  public showMailBox:boolean = false;
  public showMailMsg:boolean = false;
  public config: PerfectScrollbarConfigInterface = {};
  imgSrc: string = "../../../../assets/images/Group 5008.svg";
  @Output() onCloseClick = new EventEmitter();
  @Input() IsMMHPms: any;
  draftmessageDetails: any;
  selectedId: any;
  staffOutOfOffice: boolean = false;
  disableSubmit: boolean = false;
  toggle: any;
  toggleAccept: any = '';

  constructor(public mailboxServie: MailBoxService, public inBoxService: InBoxService, public dialog: MatDialog,
    private fb: FormBuilder, private toaster: ToasterService, private translate: TranslateService,
    private tokenService: TokenService,private router:Router) {
      this.userId = this.tokenService.userId()
     }


  ngOnInit(): void {
    // Detail Form
    this.detailForm = this.fb.group({
      Subject: ['', Validators.required],
      bodymessage: ['', Validators.required],
      inboxDraftId:['']
    })
    this.selectedId= sessionStorage.getItem('inboxSSOId');
    this.getSelectedMailDetails();
    this.liveStatusSub = interval(30000).subscribe((x:any) => {
      this.autoSaveDraft();
  });
  }

  get f() { return this.detailForm.controls };

  closeSelectedMail() {
    this.onCloseClick.emit();
  }

  statusToggle(index: any){
    if (index.value == 'Accept') {
      this.toggleAccept = 'Accept';
    } else {
      this.toggleAccept = 'Reject';
    }
  }

  showDialog(outOfOffice: any,time: any, days: any) {
    let messageContent ='';
    this.disableSubmit = false;
    if(outOfOffice == 1) {
      messageContent = `You are sending the message outside of office hours. The practice operates from ${time?.toLowerCase()}, ${days}. Our team will not be able to respond until our next business day. However, please rest assured that your message can still be sent, and we will attend to it promptly on the next business day. If you have an urgent matter that requires immediate action, please call 111 or contact the Health Centre.`;
    } else if(outOfOffice == 2) {
      messageContent = `You are sending the message outside of office hours. The practice operates from ${time?.toLowerCase()}, ${days}. Our team will not be able to respond until our next business day, and unfortunately, the message cannot be sent at this time.If you have an urgent matter that requires immediate action, please call 111 or contact the Health Centre.`;
      this.disableSubmit = true;
    } else if(outOfOffice == 3) {
      messageContent = `You are sending the message outside of office hours. The practice operates from ${time?.toLowerCase()}, ${days}. Our team will not be able to respond until our next business day. Please note that this message will be delivered during regular work hours. If you have an urgent matter that requires immediate action, please call 111 or contact the Health Centre.`;
    } else {
      return false;
    }

    this.alertDialog = {
      title: 'Information',
      message: messageContent,
      alertType: AlertType.None,
      okButtonText: this.translate.instant('OK')
    };
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: this.alertDialog
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
      }
    });

  }

  getSelectedMailDetails() {
    this.showMailMsg = true;
    this.selectedId = this.selectedId=='' ||this.selectedId== null?this.mailboxServie.messageId:this.selectedId;
    this.inBoxService.getSelectedMailDetails(this.userId,this.selectedId).subscribe(result => {
      this.selectedMessagesDetails = result;
      // console.log(this.selectedMessagesDetails, this.selectedMessagesDetails[0].ReplyContent)

      this.detailForm.patchValue({
        Subject: this.selectedMessagesDetails[0].Subject,
        bodymessage: this.selectedMessagesDetails[0].ReplyContent
      })



      if (this.selectedMessagesDetails[0].FromUserId != null) {
        this.inBoxService.getDraftPracticeDetails(this.userId, 1).subscribe(result => {
          this.getPracticeData = result;
          console.log(this.getPracticeData)
        })
      }
      // selected mail mark as read
      if (this.selectedMessagesDetails[0].IsRead == false) {
        this.markAsRead();
      }
      this.xmltoJSON = JSON.parse(this.selectedMessagesDetails[0].attachment);
      console.log(this.xmltoJSON)
      if (this.xmltoJSON.ROOT != "") {
        let singleAttachment: any = [];
        singleAttachment = this.xmltoJSON.ROOT.Inbox_Attachments;
        this.filesData = singleAttachment;
        console.log(this.filesData);
      }


      const isCurrentDayAvailable = this.inBoxService.checkCurrentDayAvilable(this.selectedMessagesDetails[0]?.WorkDaysOutOfOffice);
      const isCurrentTimeInRange = this.inBoxService.checkCurrentTimeAvailable(this.selectedMessagesDetails[0]?.StartTimeOutOfOffice, this.selectedMessagesDetails[0]?.EndTimeOutOfOffice);
      console.log(`Is current day available? ${isCurrentDayAvailable}`);
      console.log(`Is current time in range? ${isCurrentTimeInRange}`);
      const days = this.inBoxService.convertDays(this.selectedMessagesDetails[0]?.WorkDaysOutOfOffice);


          // Extracted statements
        const isWorkHour = this.selectedMessagesDetails[0]?.IsWorkHoursOutOfOffice;
        const isTimeOutOfRange = isCurrentDayAvailable ?  !isCurrentTimeInRange : true;

        // Final statement
        const isShowPopup = isWorkHour ? isTimeOutOfRange : false;

      const time = this.selectedMessagesDetails[0]?.StartTimeOutOfOffice + ' to ' +  this.selectedMessagesDetails[0]?.EndTimeOutOfOffice;
      if(isShowPopup) {
        this.showDialog(this.selectedMessagesDetails[0].MessageTypeOutOfOffice, time,days);
      } else {
          this.checkProviderAvailability(this.selectedMessagesDetails[0]);
      }


    })
  }

  markAsRead = () => {
    this.inBoxService.mailMarkAsRead(this.userId, this.mailboxServie.messageId).subscribe((res: any) => {
      if (res) {
        this.selectedMessagesDetails[0].IsRead = true;
      }
    }, (err: any) => {
      console.log(err)
      this.toaster.error(err.error.Message)
    })
  }

  // Attach Files Dialog popup and getting files from that dialog
  attachFileDialog() {
    const dialogRef = this.dialog.open(AttachfileDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      this.files = result;
      this.addedFiles.push(...this.files)
      if (this.addedFiles.length > 3) {
        this.filelengthError = true;
      } else { this.filelengthError = false }
    });
  }

  // Remove selected files
  remove(i: number) {
    this.addedFiles.splice(i, 1);
    if (this.addedFiles.length == 3) {
      this.filelengthError = false;
    }
  }

  //Generating or creating XML request
  constructAttachFiles(strFiles: string) {
    this.generateXML = ("<ROOT>") + ""
    let file = this.filesarray.split(";");
    for (var index = 0; index < file.length - 1; index++) {
      let filedetails: string[];
      filedetails = file[index].split(",");
      if (filedetails[3] === "1") {
        this.generateXML += ("<Attachment>") + "" +
          ("<DestName>" + this.setCdata(filedetails[0]) + "</DestName>") + "" +
          ("<SourceName>" + this.setCdata(filedetails[1]) + "</SourceName>") + "" +
          ("<Size>" + filedetails[2] + "</Size>") + "" +
          ("</Attachment>") + ""
      }
    }
    this.generateXML += ("</ROOT>");
    return this.generateXML;
  }

  // CDATA Format
  setCdata(strFiles: string) {
    return "<![CDATA[" + strFiles + "]]>";
  }

  composeNewMessage(attachmentXML: string) {
    var date = new Date();
    var utcTime = date.getTime() + (date.getTimezoneOffset() * 60000);
    var timeOffset = 12;
    var sentMessageDeatils = {
      "FromUserId": this.selectedMessagesDetails[0].ToUserId, // reply
      "ToUserId":  this.selectedMessagesDetails[0].FromUserId,
      "MessageTypeID": this.selectedMessagesDetails[0].MessageTypeID,
      "Subject": this.detailForm.value.Subject,
      "MessageBody": this.detailForm.value.bodymessage,
      "IsRead": false,
      "IsSent": true,
      "IsTrash": false,
      "MessageSentOn": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      //"MessageReceivedOn": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      "MessageReceivedOn":  moment(new Date((utcTime + (3650000 * timeOffset)-600000))).format("YYYY-MM-DD HH:mm:ss"), //new Date(),
      "AttachmentXML": attachmentXML ? attachmentXML : "<ROOT><Attachment></Attachment></ROOT>",
      "isRequestRepeatPresc": true,
      "isBillable": true,
      "RRPXML": "RRPXML",
      "Cost": 0,
      "isReply": true,
      "Status": "A",
      "PracticeAdmin": this.selectedMessagesDetails[0].PracticeID,
      "PaymentType": "H",
      "ServiceRefNo": this.selectedMessagesDetails[0].ServiceRefNo,
      "LocationId": this.selectedMessagesDetails[0].LocationId,
      "ParentID": 0,
      "InboxDraftMessageID": 0,
      "MessageCategoryID": this.selectedMessagesDetails[0].MessageCategoryID == 5 || this.selectedMessagesDetails[0].MessageCategoryID == 3 ? 2 : this.selectedMessagesDetails[0].MessageCategoryID,
      "PMSServiceCode": "C",
      "OnlineTransactionID": "O",
      "SentBy": "00000000-0000-0000-0000-000000000000",
      "IsApproved": this.toggleAccept ? this.toggleAccept == 'Accept' ? true : false : null,
      "RepeatPrescriptionID": this.selectedMessagesDetails[0].RepeatPrescriptionID
    }
     replay:any:'RE:'

   sentMessageDeatils.Subject = sentMessageDeatils?.isReply && !/^RE:/i.test(sentMessageDeatils.Subject) 
    ? 'RE: ' + sentMessageDeatils.Subject 
    : sentMessageDeatils.Subject;


    console.log("sentMessageDeatils",sentMessageDeatils)
    this.inBoxService.composeNewMail(sentMessageDeatils)
      .subscribe(res => {
        console.log(res, "send message")
        if (res) {
          this.toaster.success(
            this.translate.instant('Message sent successfully'));
          this.addedFiles = [];
          if(this.draftmessageDetails!=undefined){
            this.deleteDraftMsg(this.draftmessageDetails)
          }
        this.reloadCurrentRoute();
        }
      })
  }
 // here we can Delete Draft Message if auto save or manual Save
 deleteDraftMsg(draftId:any){
  console.log(draftId)
      this.inBoxService.deleteDraftMessage(draftId).subscribe(data => {
        // if (data) {
        //   this.toaster.success(
        //   this.translate.instant('Draft deleted successfully'));
        //   this.inBoxService.getCount(this.userId).subscribe((count: any[]) => {
        //     this.mailboxServie.mailstype[1].count = count[1];
        //   })
        // }
      })

}
  // Compose a new mail api call
  sendMesssage(value: any) {
    if (this.addedFiles && this.addedFiles.length > 0) {
      const frmData = new FormData();
      for (var i = 0; i < this.addedFiles.length; i++) {
        frmData.append("fileUpload", this.addedFiles[i]);
      }
      this.inBoxService.uploadFiles(frmData).subscribe((result: any) => {
        console.log("uploadstatus", result)
        this.filesarray = result
        console.log("constructdata")
        let attachmentXML = this.constructAttachFiles(this.filesarray);
        if (value !== 'Drafts') {
          this.composeNewMessage(btoa(attachmentXML)); // btoa (encode files data)
          this.mailboxServie.selectedMailBoxType = value;
          this.showMailBox = true;
          this.showMailMsg = false;
        } else {
          this.saveAsDraft(btoa(attachmentXML));
          // this.mailboxServie.selectedMailBoxType = 'Drafts';
        }
      })
    }
    else {
      if (value !== 'Drafts') {
        this.composeNewMessage("");
        //this.mailboxServie.selectedMailBoxType = value;
        this.showMailBox = true;
        this.showMailMsg = false;
      }
      else {
        this.saveAsDraft("");
      }
    }
  }

  // Download Files
  downloadFiles(filename: any) {
    let encodefilename = btoa(filename)
    window.open(`${environment.apiBaseURL}${URLConstants.downloadFile}${encodefilename}`, '_blank');
  }


  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
        console.log(currentUrl);
    });
  }

  //  Reply Save as draft
  saveAsDraft(attachmentXML: string) {
    this.draftAttachmentXML=attachmentXML;
    this.alertDialog = {
      title: 'Info',
      message: this.translate.instant("MessageSavedAsDraftOnly"),
      alertType: AlertType.None,
      okButtonText: this.translate.instant('OK')
    };
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: this.alertDialog
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result == true) {
    const draftObject={
      InboxDraftMessageID: this.detailForm.value.inboxDraftId ? this.detailForm.value.inboxDraftId : 0,
      FromUserID: this.userId,
      //ToUserID:  this.selectedMessagesDetails[0].ToUserId,
      ToUserID:  this.selectedMessagesDetails[0].FromUserId,
      MessageTypeID: this.selectedMessagesDetails[0].MessageTypeID,
      Subject: this.detailForm.value.Subject,
      MessageBody: this.detailForm.value.bodymessage,
      IsCompose: true,
      IsReply: true,
      ServiceRefNo: this.selectedMessagesDetails[0].ServiceRefNo,
      PracticeManagerId: this.selectedMessagesDetails[0].PracticeID,
      LocationId: this.selectedMessagesDetails[0].LocationId,
      InboxMessageID:  this.selectedMessagesDetails[0].InboxMessageId,
      RoleID:  "00000000-0000-0000-0000-000000000000",
      attachments:  attachmentXML ? attachmentXML : "",
      IsSent: false
    }
    this.inBoxService.SaveDraftMessage(draftObject).subscribe(res => {
        console.log(res)
        if (res) {
          this.toaster.success(
            this.translate.instant('draft saved successfully')
          );
          this.detailForm.reset();
          //this.mailboxServie.selectedMailBoxType = "Drafts"
          this.addedFiles = [];
        this.showMailBox = true;
        this.showMailMsg = false;
        this.reloadCurrentRoute();
        }
    })
    }
  })
  }

  canceldraft(){
    this.showMailBox = true;
        this.showMailMsg = false;
        //this.reloadCurrentRoute();
  }

  backToInbox = () => {
    this.mailboxServie.selectedMailBoxType = 'Inbox'
  }

  /* get inbox messages */
  getRecievedMessages() {
    this.inBoxService.getAllSearchedReceivedMessage(this.userId, 'MessageReceivedOn desc', this.page, this.pagesize, this.isSelectAll, this.searchText).subscribe((res: any) => {
      if (res && res.length > 0) {
        console.log("scorll", res.length, this.page);
        this.mailboxServie.gridView.push(...res);
      }
    })
  }
  // Previous record data
  previousRecord() {
    this.markAsRead();
    console.log(this.mailboxServie.index)
    let next = (this.currentIndex -= 1);
    if (next < 0) {
      this.currentIndex = 0;
      return;
    }
    let nextRecord = this.mailboxServie.gridView[next];
    this.selectedMessagesDetails[this.mailboxServie.index] = nextRecord;
    console.log(nextRecord, next);
  }

  //Next record data
  nextRecord() {
    let next = (this.currentIndex += 1);
    if (next > this.mailboxServie.gridView.length - 1) {
      this.currentIndex = 5;
      return;
    }
    this.markAsRead();
    let nextRecord = this.mailboxServie.gridView[next];
    this.selectedMessagesDetails[this.mailboxServie.index] = nextRecord;
    console.log(nextRecord, next);
  }

  autoSaveDraft () {
    if(this.detailForm.value.subject!=''){
    // this.detailForm.valueChanges.pipe(
    //   debounceTime(2000), // one event every 3000 milliseconds
    //   distinctUntilChanged(), // emits only when the current value is different than the last
    // )
    // this.detailForm.valueChanges.pipe(
    //   takeUntil(this.destroy),
    //   debounceTime(30000), // one event every 60000 milliseconds
    // // distinctUntilChanged(),
    // )
    // .subscribe(() => {
      if (this.detailForm.dirty) {
        const draftObject={
          InboxDraftMessageID: this.detailForm.value.inboxDraftId ? this.detailForm.value.inboxDraftId : 0,
          FromUserID: this.userId,
          ToUserID:   this.selectedMessagesDetails[0].FromUserId,
          MessageTypeID: this.selectedMessagesDetails[0].MessageTypeID,
          Subject: this.detailForm.value.Subject,
          MessageBody: this.detailForm.value.bodymessage,
          IsCompose: true,
          IsReply: true,
          ServiceRefNo: this.selectedMessagesDetails[0].ServiceRefNo,
          PracticeManagerId: this.selectedMessagesDetails[0].PracticeID,
          LocationId: this.selectedMessagesDetails[0].LocationId,
          InboxMessageID:  this.selectedMessagesDetails[0].InboxMessageId,
          RoleID:  "00000000-0000-0000-0000-000000000000",
          attachments:  this.draftAttachmentXML ? this.draftAttachmentXML : "",
          IsSent: false
        }
        this.inBoxService.SaveDraftMessage(draftObject).subscribe((res : any) => {
          this.draftmessageDetails =res;
            if (res > 1) {
              this.detailForm.get('inboxDraftId')?.setValue(res);
              this.saveIndicator='Draft saved at' + " " + `${formatDate(Date.now(), "hh:mm:ss a", 'en-US')}`

            }
        })
      }
  //  });
    }
  }

  ngOnDestroy() {
    this.liveStatusSub.unsubscribe();
    this.inBoxService.getDraftMessageCount(this.userId).subscribe((data: any) => {
      this.mailboxServie.mailstype[1].count = data;
    })
    this.destroy.next();
    sessionStorage.removeItem('inboxSSOId');
    this.destroy.complete();
  }

  checkProviderAvailability(toUserDetails: any) {
    this.staffOutOfOffice = false;

    if (toUserDetails?.IsOutOfOfficeEnabled) {
      let msg = '';
      if (toUserDetails?.ReceiveMsgWhenStaffOutOfOfficeEnabled) {
        msg = 'ComposeOutOfOfficeAllow';
      }
      else {
        this.staffOutOfOffice = true;
        msg = 'ComposeOutOfOfficeRestrict';
      }

      this.alertDialog = {
        title: 'Info',
        message: this.translate.instant(msg),
        alertType: AlertType.None,
        okButtonText: this.translate.instant('OK')
      };
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        data: this.alertDialog
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result == true) {
        }
      });
    }
  }
}






