import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  Output,
  EventEmitter,
  Input,
  OnInit,
  HostListener,
  ElementRef
} from '@angular/core';
import { Location } from '@angular/common';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MediaMatcher } from '@angular/cdk/layout';
import { MenuItems } from '../../../shared/data/interface/menu-items/menu-items';
import { HttpClient } from '@angular/common/http';
import { TokenService } from 'src/app/data/service/token.service';
import { applicationHttpClientCreator } from 'src/app/data/interface/IRequestOptions';
import { URLConstants } from 'src/app/core/constants/urlConstants';
import { ActivatedRouteSnapshot, NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { MailBoxService } from 'src/app/data/service/mail-box.service';
import { environment } from 'src/environments/environment';
import { EncryptDecrypt } from 'src/app/core/helper/encrypt-decrypt';
import { AdbutlerZonerIdsAndComponents } from 'src/app/core/constants/adbutler.const';
import { AdbutlerService } from 'src/app/data/service/adbutler.service';
import { InBoxService } from 'src/app/data/service/inbox.service';
import { PracticeModule } from 'src/app/modules/pages-practice/pages-practice.module';
import { element } from 'protractor';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-vertical-sidebar',
  templateUrl: './vertical-sidebar.component.html',
  styleUrls: []
})

export class VerticalAppSidebarComponent implements OnInit {
  public config: PerfectScrollbarConfigInterface = {};
  mobileQuery: MediaQueryList;
  public healthToolsChildren : any[]=[];
  public usageMenuChildren : any[]=[];

  @Input() showClass: boolean = false;
  @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>()


  private _mobileQueryListener: () => void;
  status = true;
  openRegUser = false;
  isMAMember = false;
  isProvider : boolean = false;
  isSuperAdmin: boolean = false;
  isPatient: boolean = false;
  isPracticeAdmin: boolean = false;
  dependantRole = false;
  isLinkedAccNavigation = false;
  itemSelect: number[] = [];
  parentIndex = 0;
  childIndex = 0;
  menu: any;
  appVersion:string;
  // role = "newPatient";//
  role="DBMenu";
  userId: any;
  medicalert: string;
 // leftHandAdbutlerZoneIds = AdbutlerZonerIdsAndComponents.LeftHandMenuComponent;
  medicalertMembersRoute: boolean=false;
  isLinkedAccChild: boolean = false;
  scrollTimeout: any;
  isEasyBooking: boolean;
  isBTBUser: boolean;
  isOpenReg: boolean;
  isMAMembers: boolean;
  isMMHPms: any;
  practiceManagerId: any;
  loggedinUser: any;
  setClickedRow(i: number, j: number): void {
    this.parentIndex = i;
    this.childIndex = j;
  }
  subclickEvent(): void {
    this.status = true;
  }
  scrollToTop(): void {
    document.querySelector('.page-wrapper')?.scroll({
      top: 0,
      left: 0
    });
  }
activeIcon: any;
  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    private mailBoxService:MailBoxService,
    private httpClient: HttpClient,
    private tokenService:TokenService,
    private router:Router,
    private adbutlerService: AdbutlerService,
    private inBoxService: InBoxService,
    private location: Location,
    private elem: ElementRef, private http: HttpClient
  ) {
    this.userId=this.tokenService.userId();
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.router.events.subscribe((val: any) => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.toString().includes('dashboard')) {
          let elements = document.querySelectorAll('.menu-header');
          let element: HTMLElement = elements[1] as HTMLElement;
          // element.click();
        }
      }
    });
  }
  ngOnInit(): void {
    this.loggedinUser = this.tokenService.userProfile();
    this.practiceManagerId = this.loggedinUser.PracticeManagerId;
    this.checkPracticeSource();
    let userRoleArr: any = this.tokenService.getUserRoles();
    let userRoleList = userRoleArr?.split(',');
    this.checkUserRoles(userRoleList);
    let patientid = userRoleList?.findIndex((x: any) => x.toLowerCase() == 'patient');
    let practiceadmin = userRoleList?.findIndex((x: any) => x.toLowerCase() == 'practiceadmin' || x.toLowerCase() == 'clinical' || x.toLowerCase() == 'reception');
    let superadmin = userRoleList?.findIndex((x: any) => x.toLowerCase() == 'superadmin' || x.toLowerCase() == '') || userRoleList.length == 0;
    if (superadmin !=  -1) {
      this.isSuperAdmin = true;
    }
    if (patientid != -1) {
      this.isProvider = false;
      this.isPatient = true;
    }
    else {
      if (practiceadmin != -1) {
        this.isPracticeAdmin = true;
      }
      this.isProvider = true;

      let openRegRole = userRoleList?.findIndex((x: any) => x.toLowerCase() == 'openreg');
      if (openRegRole != -1) {
        this.openRegUser = true;
      }
      else {
        this.openRegUser = false;
      }
    }

    let isMedicAlertRole = userRoleList?.findIndex((x: any) => x.toLowerCase() == 'mamember');
    if (isMedicAlertRole != -1) {
      this.isMAMember = true;
    }
    else {
      this.isMAMember = false;
    }

    this.dependantRole = false;
    this.isLinkedAccNavigation = false;
    let CarerEmail = sessionStorage.getItem('CarerEmail');
    if (CarerEmail == '' || CarerEmail == null || CarerEmail == undefined) {
      let dependant = userRoleList?.findIndex((x: any) => x.toLowerCase() == 'dependant');
      if (dependant != -1) {
        this.dependantRole = true;
      }
    }
    else {
      this.isLinkedAccNavigation = true;
    }

    let isMedicAlertAdminRole = userRoleList?.findIndex((x: any) => x.toLowerCase() == 'maadmin');
    if (isMedicAlertAdminRole != -1) {
      this.providerMedicAlertMemberCheck();
    }
    else {
      let isMedicAlertPatRole = userRoleList?.findIndex((x: any) => x.toLowerCase() == 'mamember');
      if (isMedicAlertPatRole != -1) {
        this.medicAlertMemberCheck();
      }
    }

    let loginType: any = sessionStorage.getItem('loginType');
    loginType = loginType ? EncryptDecrypt.decryptUsingAES256(loginType) : '';
    if (loginType == 'Child') {
      this.isLinkedAccChild = true;
    }
    else {
      this.isLinkedAccChild = false;
    }
    this.getMenuItems();
    this.appVersion = environment.appVersion;

    this.tokenService.updateMenuObservable.subscribe((data: any) => {
      if(data) {
        this.getMenuItems();
      }
  });

  }
  // ngOnInit(): void {
  //   // this.getMenuItems();
  // }
  checkUserRoles(userRoleList : any) {
    const rolesToCheck = ['mamember', 'cbtuser', 'easybooking'];
    this.isMAMembers = rolesToCheck.some((role) => userRoleList?.includes(role.toLowerCase()));
    this.isEasyBooking = rolesToCheck.some((role) => userRoleList?.includes(role.toLowerCase()));
    this.isBTBUser = rolesToCheck.some((role) => userRoleList?.includes(role.toLowerCase()));
  }

  // checkPracticeSource() {
  //   if (!this.isPatient) {
  //     applicationHttpClientCreator(this.http).Get(URLConstants.checkPracticeSorce + this.practiceManagerId, {})
  //       .pipe(first())
  //       .subscribe(
  //         (data: any) => {
  //           this.isMMHPms = data;
  //         }, (err) => {
  //           console.log(err.error.message)
  //         }
  //       )
  //   }
  // }

  checkPracticeSource() {
    if (!this.isPatient && this.practiceManagerId) {
    applicationHttpClientCreator(this.http).Get(`${URLConstants.checkPracticeSorce}/${this.practiceManagerId}`)
    .subscribe(
      (data: any) => {
          console.log('Data -------> ' + data)
          this.isMMHPms = data;
        }, (err) => {
          console.log(err.error.message)
        }
      )
    }
}

  ngOnDestroy(): void {
    this.medicalertMembersRoute=false;
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  ngAfterViewInit(): void {
    //this.adbutlerService.registerAd(this.leftHandAdbutlerZoneIds.mohMainZoneId);
  }

  // handleNotify(menuitem?:any,childitem?:any) {
  //   this.router.navigate([menuitem]);
  //   if (window.innerWidth < 1024) {
  //     this.notify.emit(!this.showClass);
  //   }
  //   // let url:any;
  //   // if(menuitem!=""&&menuitem!=undefined&&menuitem!=null&&childitem!=""&&childitem!=undefined&&childitem!=null)
  //   // url=menuitem+"/"+childitem;
  //   // else if(menuitem!=""&&menuitem!=undefined&&menuitem!=null)
  //   // url=menuitem

  //   // window.location.href = window.location.origin + url;
  // }


  isScrolling = false;
  @HostListener('scroll', ['$event'])
  onScroll(event: Event) {
    this.isScrolling = true;

    clearTimeout(this.scrollTimeout);
    this.scrollTimeout = setTimeout(() => {
      this.isScrolling = false;
    }, 200);
  }

  onTouchStart() {
    this.isScrolling = false;
  }

  onTouchMove() {
    this.isScrolling = true;
  }

  onTouchEnd(routeUrl: string) {
    const isIphone = /iPhone|iPad/i.test(navigator.userAgent);
    if (isIphone) {
    if (this.isScrolling) {
      this.isScrolling = false;
      return;
    }
    this.handleNotify(routeUrl);
  }
  }

  handleClick(routerUrl: string, routeIcon: any) {
    const isIphone = /iPhone|iPad/i.test(navigator.userAgent);
    this.activeIcon = routeIcon;
    console.log(this.activeIcon, "activeIconsssss")
    if (!isIphone) {
      this.handleNotify(routerUrl);
    }
  }

  isScrollingChild = false;
  @HostListener('scroll', ['$event'])
  onScrollChild(event: Event) {
    this.isScrollingChild = true;

    clearTimeout(this.scrollTimeout);
    this.scrollTimeout = setTimeout(() => {
      this.isScrollingChild = false;
    }, 200);
  }

  onTouchStartChild() {
    this.isScrollingChild = false;
  }

  onTouchMoveChild() {
    this.isScrollingChild = true;
  }

  onTouchEndChild(routeUrl: string) {
    const isIphone = /iPhone|iPad/i.test(navigator.userAgent);
    if (isIphone) {
    if (this.isScrollingChild) {
      this.isScrollingChild = false;
      return;
    }
    this.handleNotify(routeUrl);
  }
  }

  handleNotify(menuitem?:any,childitem?:any) {
    if(menuitem =='/myhealth/medic-alert'){
      if(this.medicalertMembersRoute==true){
        menuitem='/myhealth/medic-alert-members';
      }
    }

    if (menuitem.startsWith('https')) {
      // Open external links in a new tab
      const newTab = window.open(menuitem, '_blank');
      newTab?.focus();
      this.location.back();
    } else {
      const urlArr = menuitem.split('/');
      const modifiedUrl  = urlArr.length >= 4 ? encodeURIComponent(urlArr[3]) : '';
      let urlFinal = menuitem;
        if(modifiedUrl) {
          urlFinal = '/' + urlArr[1] + '/' + urlArr[2] + '/' + modifiedUrl;
        }
      this.router.navigateByUrl(urlFinal);
    }

    // if( menuitem.includes("Compose") || menuitem.includes("Inbox") || menuitem.includes("Drafts") ||
    //   menuitem.includes("Sent") || menuitem.includes("Archive") || menuitem.includes("Groups") )   {
    //     this.mailBoxService.selectedMailBoxType = '' ;
    //   }

    this.mailBoxService.hideMailBox();


    console.log("menuitem",menuitem);
    if (window.innerWidth <= 1024) {
      this.notify.emit(!this.showClass);
    }

  }

  medicAlertMemberCheck() {
    if(this.userId) {
      applicationHttpClientCreator(this.httpClient).Get(`${URLConstants.medicAlertNumberId}${this.userId}`, {
      }).subscribe((data: any) => {

        if (data > 0) {
          this.medicalertMembersRoute=true;
          this.medicalert = '/myhealth/medic-alert-members';
        }
      })
    }

  }

  providerMedicAlertMemberCheck() {
    if(this.userId) {
      applicationHttpClientCreator(this.httpClient).Get(`${URLConstants.getProvMedicAlertMemberID}${this.userId}`, {
      }).subscribe((data: any) => {
        if (data > 0) {
          this.medicalert = '/myhealth/medic-alert-members';
        }
      })
    }

  }

  getMenuItems() {
    switch (this.role) {
      case 'adminClinical':
        {
          this.httpClient.get("assets/i18n/adminClinicalMenu.json").subscribe(data => {
            console.log(data);
            // this.menuItems=data;
            this.menu = data;
          })
          break;
        }
      case 'clinical':
        {
          this.httpClient.get("assets/i18n/clinicalMenu.json").subscribe(data => {
            console.log(data);
            // this.menuItems=data;
            this.menu = data;
          })
          break;
        }
      case 'patient':
        {
          this.httpClient.get("assets/i18n/patientMenu.json").subscribe(data => {
            console.log(data);
            // this.menuItems=data;
            this.menu = data;
          })
          break;
        }
      case 'IHI patient':
        {
          this.httpClient.get("assets/i18n/adminClinicalMenu.json").subscribe(data => {
            console.log(data);
            // this.menuItems=data;
            this.menu = data;
          })
          break;
        }
      case 'DUMMY SMS PATIENT':
        {
          this.httpClient.get("assets/i18n/adminClinicalMenu.json").subscribe(data => {
            console.log(data);
            // this.menuItems=data;
            this.menu = data;
          })
          break;
        }
      case 'Provider':
        {
          this.httpClient.get("assets/i18n/providerNZMenu.json").subscribe(data => {
            console.log(data);
            // this.menuItems=data;
            this.menu = data;
          })
          break;
        }
      case 'Patient':
        {
          this.httpClient.get("assets/i18n/patientNZMenu.json").subscribe(data => {
            console.log(data);
            // this.menuItems=data;
            this.menu = data;
          })
          break;
        }
      case 'newPatient':
        {
          this.httpClient.get("assets/i18n/newPatient.json").subscribe(data => {
            console.log(data);
            // this.menuItems=data;
            this.menu = data;
          })
          break;
        }
         case 'DBMenu':
           {
             let userId=this.tokenService.userId();
             //Changed to service for cache
             if(this.userId) {

             this.inBoxService.getUserAndMenu(this.userId).subscribe((data:any) => {
             //applicationHttpClientCreator(this.httpClient).Get(`${URLConstants.GetUserAndMenu}${userId}`).subscribe((data:any)=>{
              console.log("raw data",data);
              if(this.isProvider == true && this.openRegUser == false) {
                data.map((s:any)=>{
                  if(s.MainMenu == 'Usage Menu'){
                    //this.healthToolsChildren = s.Children.filter((v:any)=>v.SubMenu != 'View Journal');
                    s.Children.map((v:any)=>{
                      if(v.SubMenu != 'My Payment'){
                        this.usageMenuChildren.push(v);
                      }
                    });
                    console.log("this.healthToolsChildren",this.healthToolsChildren);
                  }
                 
                  if(s.MainMenu == 'Health Tools'){
                    //this.healthToolsChildren = s.Children.filter((v:any)=>v.SubMenu != 'View Journal');
                    s.Children.map((v:any)=>{
                      if (this.isMAMember == false) {
                        if (this.isPracticeAdmin == true || this.isSuperAdmin) {
                          if (v.SubMenu == 'View Journal') {
                            this.healthToolsChildren.push(v);
                          }
                        } else {
                          if (v.SubMenu != 'View Journal') {
                            this.healthToolsChildren.push(v);
                          }
                        }
                      }
                      else {
                        this.healthToolsChildren.push(v);
                      }
                    });
                    console.log("this.healthToolsChildren",this.healthToolsChildren);
                  }
                });
               let  menudata = null;
                if (this.isMAMember == true ||  this.isEasyBooking || this.isBTBUser ) {
                  menudata = data.filter((x: any)=> x.MainMenu != 'My Health Centres' &&  x.MainMenu != 'My Health Records' && x.MainMenu != 'My Health Documents');
                }else{
                  menudata = data.filter((x: any)=> x.MainMenu != 'My Health Centres' && x.MainMenu != 'My Health Indicators' &&  x.MainMenu != 'My Health Records' && x.MainMenu != 'My Health Documents');
                }
                data = menudata;

                data.map((s:any)=>{
                  if(s.MainMenu == 'Usage Menu'){
                    s.Children = this.usageMenuChildren;
                  }
                  if(s.MainMenu == 'Health Tools') {
                    s.Children = this.healthToolsChildren;
                  }
                });
                //console.log('provider >>>', ssd);
                if(this.isMMHPms == 0 && !this.isPatient){
                  data.forEach((element: any) => {
                    if(element.MainMenu == 'Setup Modules'){
                      const index = element.Children.findIndex((ch: any) => ch.SubMenu == 'Appointment Template ');
                      if (index > -1) {
                        element.Children.splice(index, 1);
                      }
                      
                    }
                  });
                }
                this.menu = data;
                console.log('menu', this.menu);
                // this.addProfileMenu();
              } else {
                if (this.dependantRole) {
                  let linkedAccIndex = data.findIndex((x: any) => x.MainMenu == 'Linked Accounts');
                  if (linkedAccIndex != -1) {
                    let childData = data[linkedAccIndex].Children.filter((x: any) => x.SubMenu != 'Back to My Account');
                    data[linkedAccIndex].Children = childData;
                  }

                  const addChilMenu = [{
                    "SubMenu": "Change Password",
                    "sortorder": 2,
                    "routeUrl": "/myaccount/change-password",
                    "routeIcons": "icon-cent-lock-popular",
                    "IsV2FeatureEnabled": true,
                    "parentsortorder": 19,
                    "isHelpLink": false
                },
                {
                    "SubMenu": "Change Email Address",
                    "sortorder": 2,
                    "routeUrl": "/myaccount/change-email",
                    "routeIcons": "icon-cent-email-outline-messaging",
                    "IsV2FeatureEnabled": true,
                    "parentsortorder": 19,
                    "isHelpLink": false
                }];

                  const accountIndex = data.findIndex((x: any) => x.MainMenu == 'My Account');
                  if (accountIndex != -1) {

                    data[accountIndex].Children.unshift(...addChilMenu);
                  }


                console.log(addChilMenu);
                }
                else if (this.isLinkedAccNavigation) {
                  let linkedAccIndex = data.findIndex((x: any) => x.MainMenu == 'Linked Accounts');
                  if (linkedAccIndex != -1) {
                    let childData = data[linkedAccIndex].Children.filter((x: any) => x.SubMenu != 'View Primary Accounts');
                    data[linkedAccIndex].Children = childData;
                  }
                }
                if(this.medicalert){
                  //data[8].Children[2].routeUrl=this.medicalert;
                }
                console.log('datttttttttttttttttttttt???????????????????????', data);               
                
                 this.menu = data;
                 if( this.menu && this.menu.length){
                  this.menu.forEach((item:any)=>{

                    if(item.MainMenu == 'Discover Health'){
                      item.Children[0].routeUrl = "/discover-health"
                    }
                  })
                 }
                //  this.addProfileMenu();
              }
             })

            }
           }
    }
  }

 handleTouchEnd(event: TouchEvent) {
    event.preventDefault();
 }

  addProfileMenu() {
    if (this.menu) {
      let hasAccountMenu = this.menu.find((x: any) => x.MainMenu == 'My Account');
      if (hasAccountMenu) {
        return;
      }
      this.menu.push({ MainMenu: 'My Account', routeIcons: 'icon-cent-account-box-account', Children: [] });
      if (!this.isLinkedAccChild) {
        this.menu[this.menu.length - 1].Children.push({
          SubMenu: "Change Password",
          sortorder: 0,
          routeUrl: '/myaccount/change-password',
          routeIcons: 'icon-cent-lock-popular',
          IsV2FeatureEnabled: true,
          parentsortorder: 30,
          isHelpLink: false
        });
        this.menu[this.menu.length - 1].Children.push({
          SubMenu: "Change Email Address",
          sortorder: 1,
          routeUrl: '/myaccount/change-email',
          routeIcons: 'icon-cent-email-outline-messaging',
          IsV2FeatureEnabled: true,
          parentsortorder: 30,
          isHelpLink: false
        });
        this.menu[this.menu.length - 1].Children.push({
          SubMenu: "Change Address",
          sortorder: 2,
          routeUrl: '/myaccount/change-address',
          routeIcons: 'icon-cent-account-card-details-account',
          IsV2FeatureEnabled: true,
          parentsortorder: 30,
          isHelpLink: false
        });
        this.menu[this.menu.length - 1].Children.push({
          SubMenu: "Change Phone Number",
          sortorder: 3,
          routeUrl: '/myaccount/change-phone-number',
          routeIcons: 'icon-cent-cellphone-androiddevices',
          IsV2FeatureEnabled: true,
          parentsortorder: 30,
          isHelpLink: false
        });
        if (!this.isProvider) {
          this.menu[this.menu.length - 1].Children.push({
            SubMenu: "My Payments",
            sortorder: 4,
            routeUrl: '/payment/my-payment',
            routeIcons: 'icon-cent-bank-finance',
            IsV2FeatureEnabled: true,
            parentsortorder: 30,
            isHelpLink: false
          });
        }
        else {
          this.menu[this.menu.length - 1].Children.push({
            SubMenu: "Payment Report",
            sortorder: 4,
            routeUrl: '/payment/payment-report',
            routeIcons: 'icon-cent-bank-finance',
            IsV2FeatureEnabled: true,
            parentsortorder: 30,
            isHelpLink: false
          });
        }
        this.menu[this.menu.length - 1].Children.push({
          SubMenu: "Update Profile",
          sortorder: 5,
          routeUrl: '/myaccount/profile',
          routeIcons: 'icon-cent-account-edit-account',
          IsV2FeatureEnabled: true,
          parentsortorder: 30,
          isHelpLink: false
        });
        this.menu[this.menu.length - 1].Children.push({
          SubMenu: "Calendar",
          sortorder: 6,
          routeUrl: '/myaccount/calendar',
          routeIcons: 'icon-cent-calendar-range-date',
          IsV2FeatureEnabled: true,
          parentsortorder: 30,
          isHelpLink: false
        });
      }
      else {
        this.menu[this.menu.length - 1].Children.push({
          SubMenu: "Update Profile",
          sortorder: 0,
          routeUrl: '/myaccount/profile',
          routeIcons: 'icon-cent-account-edit-account',
          IsV2FeatureEnabled: true,
          parentsortorder: 30,
          isHelpLink: false
        });
        this.menu[this.menu.length - 1].Children.push({
          SubMenu: "Change Address",
          sortorder: 1,
          routeUrl: '/myaccount/change-address',
          routeIcons: 'icon-cent-account-card-details-account',
          IsV2FeatureEnabled: true,
          parentsortorder: 30,
          isHelpLink: false
        });
        this.menu[this.menu.length - 1].Children.push({
          SubMenu: "Change Phone Number",
          sortorder: 2,
          routeUrl: '/myaccount/change-phone-number',
          routeIcons: 'icon-cent-cellphone-androiddevices',
          IsV2FeatureEnabled: true,
          parentsortorder: 30,
          isHelpLink: false
        });
        this.menu[this.menu.length - 1].Children.push({
          SubMenu: "My Payments",
          sortorder: 3,
          routeUrl: '/payment/my-payment',
          routeIcons: 'icon-cent-bank-finance',
          IsV2FeatureEnabled: true,
          parentsortorder: 30,
          isHelpLink: false
        });
        this.menu[this.menu.length - 1].Children.push({
          SubMenu: "Calendar",
          sortorder: 4,
          routeUrl: '/myaccount/calendar',
          routeIcons: 'icon-cent-calendar-range-date',
          IsV2FeatureEnabled: true,
          parentsortorder: 30,
          isHelpLink: false
        });
      }
    }
  }
}






