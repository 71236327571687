export class CommonURLs {
     public static suportURL: string = "https://www.managemyhealth.co.nz/support/";
    // public static fAQURL: string = "https://www.managemyhealth.co.nz/faqs/";
     public static termsofUseURL: string = "https://www.managemyhealth.co.nz/terms-of-use/";
     public static privacyURL: string = "https://www.managemyhealth.co.nz/privacy-policy/";
     public static codeOfConductURL: string = "https://managemyhealth.co.nz/content/common/CodeOfConduct.aspx";
    // public static serviceSpecification: string =  "https://managemyhealth.co.nz/content/common/ServiceSpecification.aspx";
     public static security: string =   "https://www.managemyhealth.co.nz/security/";
     public static BTConditionsURL: string = "https://www.managemyhealth.co.nz/terms-and-conditions/";
    //public static suportURL: string = window.location.origin + "/authentication/support";
    //public static termsofUseURL: string = window.location.origin + "/authentication/terms-of-use";
   // public static privacyURL: string = window.location.origin + "/authentication/privacy-policy";
   // public static codeOfConductURL: string = window.location.origin + "/authentication/code-of-conduct";
   // public static security: string =  window.location.origin +  "/authentication/security";
   // public static BTConditionsURL: string = window.location.origin + "/authentication/business-terms-conditions";
    
    public static v1DevURL: string = "https://dev.mmh-demo.com/m/Main/login";
    // public static v1DevURL: string = "https://dev.mmh-demo.com/m/Main/login";
    // public static v1DevURL: string = "https://dev.mmh-demo.com/m/Main/login";

    // after-login 
    // public static technicalSupportURL: string = window.location.origin + "/myaccount/technical-support";
    // public static fAQURL: string = window.location.origin + "/myaccount/faq-factor";
    // public static termsofUsePlURL: string = window.location.origin + "/myaccount/terms-of-use-pl";
    // public static privacyPlURL: string = window.location.origin + "/myaccount/privacy-policy-pl";
       public static codeOfConductPlURL: string = window.location.origin + "/myaccount/code-of-conduct-pl";
    // public static securityPlURL: string =  window.location.origin +  "/myaccount/security-pl";
       public static serviceSpecification: string =  window.location.origin + "/myaccount/service-specification";
    public static technicalSupportURL: string =  "https://www.managemyhealth.co.nz/support/";
    public static fAQURL: string =  "https://www.managemyhealth.co.nz/faqs/";
    public static termsofUsePlURL: string = "https://www.managemyhealth.co.nz/terms-of-use/";
    public static privacyPlURL: string =  "https://www.managemyhealth.co.nz/privacy-policy/";
   // public static codeOfConductPlURL: string = "https://v2web.mmhnz.cloud/myaccount/code-of-conduct-pl";
    public static securityPlURL: string = "https://www.managemyhealth.co.nz/security/";
   //public static serviceSpecification: string = "https://v2web.mmhnz.cloud/myaccount/service-specification";
}