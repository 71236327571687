import { Component, HostListener, OnInit } from '@angular/core';
import { AppService } from './data/service/app.service';
import { LoaderService } from './data/service/loader.services';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Router } from '@angular/router';
import { Keepalive } from '@ng-idle/keepalive';
import { applicationHttpClientCreator } from './data/interface/IRequestOptions';
import { HttpClient } from '@angular/common/http';
import { URLConstants } from './core/constants/urlConstants';
import { EncryptDecrypt } from './core/helper/encrypt-decrypt';
import { TokenService } from './data/service/token.service';
import { AuthenticationService } from './data/service/authentication.service';
import { TimeOutService } from './data/service/timeOut.service';
import { CommonConstants } from './core/constants/commonConstants';
import { LocationStrategy } from '@angular/common';
import { Subscription } from 'rxjs';
import { environment  } from 'src/environments/environment';
import { clearInterval, clearTimeout, setInterval, setTimeout } from 'worker-timers';

declare function chatBotProvider(a :any):any;
declare function chatBotPatient(a :any):any;
declare function chatBotLoader(a :any):any;
declare function chatBotEmpty(a :any):any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  public showLoader: boolean = false;
  public showAlexLoader: boolean = false;
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: any = null;
  title = 'angular-idle-timeout';
  opened = false;
  timeInterVal: any;
  timedOutInterval = 60;
  isModelStateError = false;
  intervalSubscription: Subscription;
  idleStartSubscription: Subscription;
  configJson: any = [];
  @HostListener('window:beforeunload', ['$event'])
  onWindowClose(event: any): void {
    console.log('closed')
    this.appService.callVonagePopupClosedMethod(true);
    //event.preventDefault();
    //event.returnValue = false;
  }

  @HostListener('window:message', ['$event'])
  onMessage(event: any) {
    // console.log("Message received from the child: " + event.data); // Message received from iframe
    if (event.data == 'close') {

    } else {
      this.idle.watch();
    }
  }
  constructor(private loaderService: LoaderService,
    private appService: AppService,
    private idle: Idle,
    private router: Router,
    public keepalive: Keepalive,
    public http: HttpClient,
    private tokenService: TokenService,
    private authenticationService: AuthenticationService,
    private timeOutService: TimeOutService,
    private location: LocationStrategy) {
      // check if back or forward button is pressed.
      console.log("in app component constructor");
      this.http.get('assets/config.json').subscribe((data: any) =>{
        this.configJson= data;
      })

    this.location.onPopState(() => {
      // set isBackButtonClicked to true.
      console.log('1');
      this.appService.setBackClicked(true);
      return false;
    });

    this.setIdleConfig();

    keepalive.interval(15);

    keepalive.onPing.subscribe(() => this.lastPing = new Date());

    //Idle popup status
    this.appService.getIdlePopUpStatus().subscribe(idlePopUpStatus => {

      if (idlePopUpStatus) {
        this.opened = true;
      }
      else {
        this.opened = false;
        this.idle.stop();
        this.timeOutService.stopTimer();
        console.log('date:', new Date());
      }
    });

    this.appService.getUserLoggedIn().subscribe(userLoggedIn => {
      console.log('2');
      if (userLoggedIn) {
        let idleTime = CommonConstants.defaultIdleTime;
        console.log(" idleTime - ", idleTime)
        idle.setIdle(idleTime);
        clearInterval(this.timeInterVal);
        idle.watch()
        this.timedOut = false;
      } else {
        clearInterval(this.timeInterVal);
        //this.timeOutService.stopTimer();
        this.opened = false;
        idle.stop();
      }
    });

    // this.appService.getUserLoggedIn().subscribe(userLoggedIn => {

    //   if (userLoggedIn) {

    //     clearInterval(this.timeInterVal);
    //     if (sessionStorage.getItem('expiryTime')) {
    //       let expiredTime = sessionStorage.getItem('expiryTime') ? sessionStorage.getItem('expiryTime') : '';
    //       let expiredTimeInMunitues = expiredTime ? parseInt(expiredTime) - 10 : 0;

    //       //let time_Expiry_Inseconds = expiredTimeInMunitues * 60;

    //       idle.setIdle(expiredTimeInMunitues);
    //       console.log('idle time',expiredTimeInMunitues)
    //       clearInterval(this.timeInterVal);

    //     }
    //     idle.watch()
    //     this.timedOut = false;
    //   } else {
    //     //this.timer(0,true);
    //     clearInterval(this.timeInterVal);
    //     //this.timeOutService.stopTimer();
    //     this.opened = false;
    //     idle.stop();
    //   }
    // });

    // this.appService.setUserTimedIn().subscribe(userTimedIn => {
    //   if (userTimedIn) {

    //     if (sessionStorage.getItem('expiryTime')) {
    //       let expiredTime = sessionStorage.getItem('expiryTime') ? sessionStorage.getItem('expiryTime') : '';
    //       let expiredTimeInSeconds = expiredTime ? parseInt(expiredTime) - 2 : 0;

    //       console.log('expiredTimeInSeconds', expiredTimeInSeconds)
    //       this.timeOutService._timeoutMilliseconds = expiredTimeInSeconds * 1000;  //CommonConstants.convertMinutesToMilliSeconds(expiredTimeInSeconds);
    //       console.log('Token-User Timed in',  this.timeOutService._timeoutMilliseconds);
    //       this.timeOutService.startTimer();

    //     }
    //   }
    //   else {
    //     this.timeOutService.stopTimer()
    //     idle.stop();
    //   }
    // });

    this.appService.setUserTimedIn().subscribe(userTimedIn => {
      console.log('3');
      if (userTimedIn) {
        if (sessionStorage.getItem('expiryTime')) {
          let expiredTime = sessionStorage.getItem('expiryTime') ? sessionStorage.getItem('expiryTime') : '';
          let expiredTimeInMinutes = expiredTime ? parseInt(expiredTime) - 5 : 0;

          console.log('expiredTimeInSeconds', expiredTimeInMinutes)
          this.timeOutService._timeoutMilliseconds = CommonConstants.convertMinutesToMilliSeconds(expiredTimeInMinutes);
          console.log('Token-User Timed in', this.timeOutService._timeoutMilliseconds);
          this.timeOutService.startTimer();
        }
      }
      else {
        this.timeOutService.stopTimer()
        idle.stop();
      }
    });

    this.appService.listenToStopIdleTimeoutEvent().subscribe(stop => {
      console.log("idle stop: ", stop);
      if (stop) {
        clearInterval(this.timeInterVal);
        this.idle.stop();
      } else {
        this.stay();
      }
    });

    this.tokenService.refreshTokenObservable.subscribe((data: any) => {
      if(data) {
        this.stay();
      }
  });
  }

  setIdleConfig() {
    let idleTime = CommonConstants.defaultIdleTime;
    this.idle.setIdle(idleTime);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    console.log('4');
    this.idleStartSubscription = this.idle.onIdleStart.subscribe(() => {
      // while in video call idle popup is not needed so used this service, this value changed in video call component.
      console.log('onIdleStart');
      let userId = this.tokenService.encryptedUserId();
      if (userId != null) {
        if (this.authenticationService.dontshowidlepopup) {
          this.stay();
        } else {
          this.idleState = this.idleState = 'You will be logged off in ' + this.timedOutInterval + ' seconds.';
          this.opened = true;
        }
        //this.timeOutService.stopTimer();
        this.timer(this.timedOutInterval);
        console.log("idle started");
      }
    });
  }

  reset() {
    console.log('5');
    this.idle.watch();
    //xthis.idleState = 'Started.';
    this.opened = false;
    this.timedOut = false;

  }


  stay() {

    clearInterval(this.timeInterVal);
    if (sessionStorage.getItem('expiryTime')) {
      let expiredTime = sessionStorage.getItem('expiryTime') ? sessionStorage.getItem('expiryTime') : '';

      this.getSessionExtensionByUser(parseInt(expiredTime ? expiredTime : ''));
    }
    else {
      this.opened = false;
      this.idle.stop();
      //this.timeOutService.stopTimer();
    }
  }

  getSessionExtensionByUser = (expiryTime: number) => {
    //lert('Logout' + expiryTime)
    let userProfile = this.tokenService.userProfile();
    applicationHttpClientCreator(this.http).Post(`${URLConstants.getRefreshToken}`,
      {
        UserSessionId: this.tokenService.encryptedUserId(), //this.tokenService.userId(),
        UserEmail: EncryptDecrypt.encryptUsingAES256(userProfile?.Email), //userProfile?.Email,
        JwtTokenRefreshTime: expiryTime

      }).subscribe((data: any) => {
        this.showLoader = false;
        // this.timeOutService.stopTimer();
        if (expiryTime !== 0) {
          console.log('Opened Status -If part', this.opened)
          this.reset();
          sessionStorage.setItem('currentUser', data);
          this.appService.setUserLoggedIn(true, expiryTime);
          //this.appService.setUserTimedOut(true, expiryTime);
          this.showLoader = false;
        }
        else {
          console.log('Opened Status -Else part', this.opened)
          this.idle.stop();
          this.opened = false;
          this.timeOutService.stopTimer();
          this.authenticationService.logout();

          // this.appService.setUserLoggedIn(false, expiryTime);
          // this.appService.setUserTimedOut(true,expiryTime);
        }

      }, (err) => {
        // console.log(err);
        // this.reset();
        // this.appService.setUserLoggedIn(true, expiryTime);
      });
  }

  logout = () => {
    this.opened = false;
    this.isModelStateError = false;
    this.idle.stop();
    clearInterval(this.timeInterVal);
    this.getSessionExtensionByUser(0);
    this.timeOutService.stopTimer();
  }


  ngOnInit() {
    console.log("in app component ngOnInit Start");
    if(this.configJson.isChatBotEnable == true)
    {
      this.loadChatBot();
    }

    if(history?.state)
    {
    const previousUrl = history.state.prevPage ?? null;

    if (!previousUrl) {

      console.log('page was refreshed!');

      if (sessionStorage.getItem('expiryTime')) {

        this.appService.setUserLoggedIn(true, 10);

        this.appService.setUserTimedOut(true, 1);

      }
    }

    }
    this.appService.customModelStateObservable.subscribe((res) => {
      if (res) {
        //Model State Error.
        this.isModelStateError = true;
      }
    }
    );

    this.loaderService.status.subscribe((val: boolean) => {
      this.showLoader = val;
    });
    this.loaderService.Alexstatus.subscribe((val: boolean) => {
      this.showAlexLoader = val;
    });
    console.log("in app component ngOnInit end");
  }

  ngOnDestroy() {
    this.loaderService.status.observers.forEach(function (element: any) { element.complete(); });
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }

  timer(timeInSeconds: number) {
    //alert(timeInSeconds);
    let seconds = timeInSeconds;
    this.timeInterVal = setInterval(() => {
      seconds--;
      this.idleState = 'You will be logged off in ' + seconds + ' seconds.'
      this.appService.initiateExitVimeoFullScreen(true);
      //this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;
      if (seconds == 0) {
        console.log("finished_________________________________________________");
        this.idleState = "Your session was expired!";
        //  this.idle.stop();
        //  this.timeOutService.stopTimer();
        console.log('Opened Status -Before', this.opened)
        this.logout();
      }
    }, 1000);

    if (timeInSeconds == 0) {
      clearInterval(this.timeInterVal);
      this.logout();
    }
  }
  public close(event:any): void {
    console.log(`Dialog result`);
  }

  loadChatBot()
  {
    if(this.tokenService.userId()==null || this.tokenService.userId()==undefined)
    {
      chatBotLoader('load');
    }
    else
    {
      if (this.tokenService.checkIsProvider()) {
        chatBotProvider('load');
      }
      if (this.tokenService.checkIsPatient()) {
        chatBotPatient('load');
      }
    }
  }
}
