import {
  ViewContainerRef,
  Component,
  ElementRef,
  ViewChild,
  OnInit,
  Input,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fromEvent, Subscription } from 'rxjs';
import { MatMenuTrigger } from '@angular/material/menu';
import * as VideoExpress from '@vonage/video-express';
import { VonageClientService } from 'src/app/data/service/vonage-client.service';
import { Dialog } from 'primeng-lts/dialog';
import { SignalrService } from 'src/app/data/service/signalr.service';
import { JoinMeetingService } from 'src/app/data/service/join-meeting.service';
import { TokenService } from 'src/app/data/service/token.service';
import { LoaderService } from 'src/app/data/service/loader.services';
import { applicationHttpClientCreator } from 'src/app/data/interface/IRequestOptions';
import { URLConstants } from 'src/app/core/constants/urlConstants';
import { CommonConstants } from 'src/app/core/constants/commonConstants';
import { HttpClient } from '@angular/common/http';
import { ToasterService } from 'src/app/data/service/toaster.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/data/service/authentication.service';

@Component({
  selector: 'app-mmh-v2-videocall',
  templateUrl: './mmh-v2-videocall.component.html',
  styleUrls: ['./mmh-v2-videocall.component.scss'],
})
export class MmhV2VideocallComponent implements OnInit, OnDestroy {
  checkTimeout: any;
  elementForSpilitView: any;
  receiverName:any;
  IsWaitingRoomStatus: any;
  IsWaitingRoom: any;
  ispatientjoined:boolean=false;
  qrURL: any = sessionStorage.getItem('qrURL') || ''; 
  showDenayConfirm: boolean = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private vonageClientService: VonageClientService,
    private signalrService: SignalrService,
    private joinMeetingService: JoinMeetingService,
    private tokenService: TokenService,
    private loader: LoaderService,
    public http: HttpClient,
    public toaster: ToasterService,
    public fb: FormBuilder,
    public authenticationService: AuthenticationService
  ) {
    this.http.get('assets/config.json').subscribe((data: any) =>{
      this.enableVideoConsultaiton= data.VideoConsultaiton;
    })
    history.pushState(null, 'null', location.href);
    this.subscription = fromEvent(window, 'popstate').subscribe((_) => {
      history.pushState(null, 'null', location.href);
    });

    this.route.params.subscribe((params) => {
      if (params.roomId) {
        // getting value from url
        this.WRoomId = params.roomId;
        this.participantName = params.name;
      }
    });
    this.signalrService.message.subscribe((val: string) => {
      if (val.includes('patient joined the waiting room.') ) {
      this.IsWaitingRoomStatus =true;
      }else if(val.includes('patient left from the waiting room.')){
        this.IsWaitingRoomStatus = false;
      }else if(val.includes('patient joined the video call')){
          this.ispatientjoined =true;
      }
    });
    this.signalrService.message.subscribe((val: string) => {
      if (val == this.meetingIdForStatus + ' declined your call.') {
        this.showToaster('Patient declined the call.');
        this.setCompletedStatus(1);
        clearTimeout(this.checkTimeout);
        this.signalrService.sendMessage(
          this.patientGuid,
          2,
          'Doctor leaves the call',
          this.meetingIdForStatus
        );
      }
    });

    // this.signalrService.message.subscribe((val: string) => {
    //   if (
    //     val ==
    //     this.meetingIdForStatus + " didn't pick your call. Please call later."
    //   ) {
    //     if (this.count == 0) {
    //       this.showToaster("Patient didn't pick your call.");
    //       this.count++;
    //     }
    //   }
    // });

    this.signalrService.message.subscribe((val: string) => {
      if (val == 'Invited patient did not accept your call') {
        this.toaster.success(
          "Invited patient didn't accept the call",
          'Information'
        );
      }
    });

    this.signalrService.message.subscribe((val: string) => {
      if (val.includes('PATIENTGUID:')) {
        this.patientGuid = val.replace(/PATIENTGUID:/g, '');
      }
    });
  }
  @ViewChild('publisherDiv', { static: false }) publisherDiv: ElementRef;
  @ViewChild('subscriberHost', { read: ViewContainerRef, static: true })
  subscriberHost: ViewContainerRef;
  @ViewChild('subscriberDiv', { static: false }) subscriberDiv: ElementRef;
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
  publishing: any;
  apiKey: string;
  token: string;
  sessionId: string;
  public room: any;
  countDown: Subscription;
  counter = 0;
  tick = 1000;
  displayMaximizable = true;
  subscription: any;
  @Input() dialog: Dialog;
  public selectedTabIndex = 0;
  providerCallEnd = false;
  WRoomId: any;
  participantName: any;
  audioStatus = true;
  videoStatus = true;
  shareStatus = false;
  iam: string;
  patientGuid: any;
  docDetails: any;
  docName: string;
  profilePictureURL: string;
  userIdForVonage: string;
  onload = false;
  providerGuid: any;
  patientDetails: any;
  messaging: FormGroup;
  chatContainer: any;
  @ViewChild('screen', { static: true }) screen: any;
  callingcode: string = '+64';
  meetingIdForStatus: any;
  patientInfo: any;
  patientDetailsForProfile: any;
  senderProfilePic: any;
  senderName: any;
  isDoctor = false;
  outPatient = false;
  role: string;
  maxChars = 200;
  showErrorComment = false;
  valueUpdated = false;
  tokenSplit: any;
  vonageSessionId: any;
  vonageToken: any;
  mics: any = [];
  videoInputs: any = [];
  selectedVideoCamera = 'Default';
  currentAudioId: any;
  audioId: any;
  selectedAudioDevice: any;
  currentAudioOutputDevice: any;
  speakers: any;
  speakerPhoneIndex: any = [];
  onSettings = false;
  defaultSpeakerIndex: any;
  headSet = true;
  speakerText = 'Audio Off';
  // rotate camera for mobile
  overAllDevices: any = [];
  videoDevices: any = [];
  videoId: any;
  currentVideoId: any;
  arrayCount: number;
  // start timer
  time = 0;
  display: any;
  interval: any;
  Videostartime: any;
  minMaxState = true;
  maximized = false;
  minimized = false;
  idElement: any;
  isVideoCallEnd = false;
  openInviteDialog = false;
  selectCountry = [{ id: 1, country: 'Australia', code: '+614', icon: 'au' }];
  showMinLenPhone = false;
  showEmailError = false;
  showError = true;
  patientQueueData = [];
  isErrorPopup = false;
  erroMessage: any;
  iPhoneWiredHeadSet: any = [];
  InvitationId:any;
  enableVideoConsultaiton:boolean = false
  ngOnInit(): void {
    
    this.minimized = true;
    this.setMessigingForm();
    window.onbeforeunload = () => this.ngOnDestroy();
    setTimeout(() => {
      this.chatContainer = document.getElementById(
        'fc_frame'
      ) as HTMLElement;
      if (this.chatContainer) {
        this.chatContainer.style.display = 'none';
      }
      this.init();
    }, 2300);

    // this.qrURL = sessionStorage.getItem('QRURL') || '';
  }

  optionSelect(event: any): void {
    this.callingcode = event;
  }

  eventCheck() {
    this.messaging.patchValue({
      phoneNumber: '',
    });
  }

  eventCheckEmail(val: any): void {
    this.messaging.patchValue({
      emailAddress: '',
    });
  }

  setMessigingForm(): void {
    this.messaging = this.fb.group({
      sms: false,
      email: true,
      patientName: ['', Validators.required],
      emailAddress: ['', Validators.required],
      phoneNumber: '',
      comment: '',
      SelectCountry: [''],
    });

    this.messaging.patchValue({
      SelectCountry: '+614',
    });

    if (this.messaging.value.sms == true) {
      this.messaging.get('phoneNumber')?.setValidators(Validators.required);
      this.messaging.get('phoneNumber')?.updateValueAndValidity();
    }

    if (this.messaging.value.email == true) {
      this.messaging.get('emailAddress')?.setValidators(Validators.required);
      this.messaging.get('emailAddress')?.updateValueAndValidity();
    }

    this.messaging.controls.sms.valueChanges.subscribe((bool: boolean) => {
      if (bool) {
        this.messaging.controls.phoneNumber.setValue('');
        this.messaging.get('phoneNumber')?.setValidators(Validators.required);
      } else {
        this.messaging.get('phoneNumber')?.clearValidators();
      }
      this.messaging.get('phoneNumber')?.updateValueAndValidity();
    });

    this.messaging.controls.email.valueChanges.subscribe((bool: boolean) => {
      if (bool) {
        this.messaging.controls.emailAddress.setValue('');
        this.messaging.get('emailAddress')?.setValidators(Validators.required);
      } else {
        this.messaging.get('emailAddress')?.clearValidators();
      }
      this.messaging.get('emailAddress')?.updateValueAndValidity();
    });
  }

  showToaster(val: any): void {
    this.toaster.success(val, 'Information');
    this.tempClose();
  }

  init(): void {
    const message = sessionStorage.getItem('message');

    this.patientInfo = JSON.parse(
      sessionStorage.getItem('patientInfo') || '[]'
    );
    const meetingId = sessionStorage.getItem('meetingid');  
   
    this.InvitationId = sessionStorage.getItem('InvitationId');
    if (message == 'doctor-video-call') {
      this.iam = 'doctor';
      this.isDoctor = true;
      this.patientGuid = this.patientInfo?.PatientUserId;
      this.docDetails = this.tokenService.userProfile();
      this.receiverName=this.patientInfo?.patientName;
      this.IsWaitingRoom = this.patientInfo?.IsWaitingRoom; 
      this.IsWaitingRoomStatus = this.patientInfo?.IsWaitingRoom;    
      this.senderName =
        this.docDetails.FirstName + ' ' + this.docDetails.LastName;
      this.docName = this.docDetails.FirstName + ' ' + this.docDetails.LastName;
      this.role =
        'Dear, Dr. ' +
        this.docName +
        ' has invited you to a secure video call.';
      this.senderProfilePic = this.docDetails.PhotoURL;
      this.participantName = this.docName;
      this.profilePictureURL = this.patientInfo?.ProfilePicture;
      this.userIdForVonage = this.docDetails.UserId;
      this.meetingIdForStatus = meetingId;

      this.onSubmit(meetingId);
      this.setCompletedStatus(0);
    } else if (message == 'patient-video-call') {
      this.iam = 'patient';
     this.getOnlineStatus(meetingId);
      this.patientDetailsForProfile = this.tokenService.userProfile();
      if (this.patientDetailsForProfile) {
        this.senderProfilePic = this.patientDetailsForProfile.PhotoURL;
        this.senderName =
          this.patientDetailsForProfile.FirstName +
          ' ' +
          this.patientDetailsForProfile.LastName;
        this.participantName =
          this.patientDetailsForProfile.FirstName +
          ' ' +
          this.patientDetailsForProfile.LastName;
      } else {
        this.senderProfilePic = 'assets/Videoimages/nophoto_male.gif';
        this.senderName = this.patientInfo.PrefferedName;
        this.participantName = this.patientInfo.PrefferedName;
      }
      this.userIdForVonage = this.patientInfo.PatientUserId;
      this.meetingIdForStatus = meetingId;
      this.onSubmit(meetingId);
      this.getCompletedStatus();
    } else if (message == 'out-patient-video-call') {
      this.iam = 'out-patient';
      this.outPatient = true;
      this.senderProfilePic = 'assets/Videoimages/nophoto_male.gif';
      this.senderName = sessionStorage.getItem('patientName');
      this.participantName = this.senderName;
      this.meetingIdForStatus = meetingId;
      this.onSubmit(meetingId);
      this.getCompletedStatus();
    }
  }

  setCompletedStatus(val: number): void {
    const payLoad = {
      MeetingID: this.meetingIdForStatus,
      CompletedStatus: val,
    };
    this.joinMeetingService
      .setCompletedStatus(payLoad)
      .subscribe((data: any) => {
        console.log(data);
      });
  }

  getCompletedStatus(): void {
    this.joinMeetingService
      .getCompletedStatus(this.meetingIdForStatus)
      .subscribe((val: any) => {
        if (val.length > 0) {
          if (val[0].CompletedStatus > 0) {
            this.room.leave();
            this.room = null;
            this.providerCallEnd = true;
            this.setWaitingRoomStatus(this.meetingIdForStatus, 0);
            this.close();
          }
        }
      });
  }

  async onSubmit(meetingId: any): Promise<void> {
    await this.setVonageToken(meetingId);
    this.onload = true;
  }

  setWaitingRoomStatus(meetingId: any, status: any): void {
    const payload = {
      MeetingID: meetingId.toString(),
      IsWaitingRoom: status,
      ProviderUserId:"00000000-0000-0000-0000-000000000000"
    };
    this.joinMeetingService.setIsWaitingRoom(payload).subscribe(() => {});
  }

  setOnlineStatus(meetingId: any, status: any): void {
    const payload = {
      MeetingID: meetingId,
      IsOnline: status,
    };
    this.joinMeetingService.setIsOnline(payload).subscribe((data: any) => {
      if (data) {
        this.valueUpdated = true;
      }
    });
  }

  getOnlineStatus(meetingId: any): void {
    const payload = {
      MeetingID: meetingId,
    };
    this.joinMeetingService.getIsOnline(payload).subscribe((data: any) => {
      if (data.length > 0) {
        if (data[0].IsOnline) {
          console.log(data);
        } else {
          this.setOnlineStatus(meetingId, 1);
        }
      } else {
        this.setOnlineStatus(meetingId, 1);
      }
    });
  }

  setVonageToken(meetingId: any): void {
    this.loader.show();
    applicationHttpClientCreator(this.http)
      .Post(`${URLConstants.getVonageToken}`, {
        ApptId: meetingId,
      })
      .subscribe(
        (data: any) => {
          this.tokenSplit = data.split('|');
          this.vonageSessionId = this.tokenSplit[0];
          this.vonageToken = this.tokenSplit[1];
          // this.vonageClientService.setData(this.vonageToken, this.vonageSessionId);
          this.vonageClientService.redirect =
            CommonConstants.vidyo_consultation_route_url_provider;
          this.conferece();
          this.loader.hide();
        },
        (err: any) => {
          console.log(err);
          this.loader.hide();
        }
      );
  }

  ngOnDestroy() {
    this.authenticationService.dontshowidlepopup = false;
    sessionStorage.removeItem('call-started');
    if (this.iam == 'patient' && this.valueUpdated) {
      sessionStorage.setItem('pageRefreshed', 'true');
      this.setOnlineStatus(this.meetingIdForStatus, 0);
    }
    this.subscription.unsubscribe();
    sessionStorage.removeItem('message');
    sessionStorage.removeItem('patientInfo');
    sessionStorage.removeItem('meetingid');
    if(this.chatContainer){
    this.chatContainer.style.display = 'block';
  }
  }

  onClickSettings(): void {
    this.onSettings = true;
  }

  async getDeviceList(): Promise<void> {
    const devices = await VideoExpress.getDevices();
    if (devices) {
      this.overAllDevices = devices;
      this.videoInputs = this.overAllDevices.filter(
        (k: { kind: string }) => k.kind == 'videoInput'
      );
      this.sendSignal();
      this.mics = this.overAllDevices.filter(
        (k: any) => k.kind == 'audioInput' && k.label != 'Default'
      );
      if (this.mics.length > 0) {
        this.mics.forEach((element: any) => {
          element.label =
            element.label == 'iPhone Microphone'
              ? 'iPhone Speaker'
              : element.label;
        });
        this.iPhoneWiredHeadSet = this.mics.filter(
          (k: any) => k.label == 'Headset Microphone'
        );
        if (this.iPhoneWiredHeadSet.length > 0) {
          this.mics = this.mics.filter((k: any) => k.label != 'iPhone Speaker');
        }
      }
      this.speakerPhoneIndex = this.mics.filter(
        (k: { kind: string; label: string }) =>
          k.kind == 'audioInput' &&
          (k.label == 'Speakerphone' || k.label == 'iPhone Speaker')
      );
      setTimeout(async () => {
        if (this.speakerPhoneIndex.length > 0) {
          this.room.camera.setAudioDevice(this.speakerPhoneIndex[0].deviceId);
          this.currentVideoId = this.room.camera.getVideoDevice();
          this.videoId = this.videoInputs.filter(
            (v: { deviceId: string }) =>
              v.deviceId == this.currentVideoId.deviceId
          );
          if (this.videoId.length > 0) {
            this.selectedVideoCamera = this.videoId[0].label;
          }
          const currentAudioDevice = await this.room.camera.getAudioDevice();
          if (currentAudioDevice) {
            this.selectedAudioDevice =
              currentAudioDevice.label == 'iPhone Microphone'
                ? 'iPhone Speaker'
                : currentAudioDevice.label;
          }
          const audioOutputs = await VideoExpress.getAudioOutputDevices();
          this.speakers = audioOutputs;
          this.speakers = audioOutputs;
          if (this.speakers.length > 0) {
            this.speakers.forEach((element: any) => {
              element.label =
                element.label == 'iPhone Microphone'
                  ? 'iPhone Speaker'
                  : element.label;
            });
            this.iPhoneWiredHeadSet = this.speakers.filter(
              (k: any) => k.label == 'Headset Microphone'
            );
            if (this.iPhoneWiredHeadSet.length > 0) {
              this.speakers = this.speakers.filter(
                (k: any) => k.label != 'iPhone Speaker'
              );
            }
          }
          const currentAudioOutputDevice =
            await VideoExpress.getActiveAudioOutputDevice();
          this.currentAudioOutputDevice =
            currentAudioOutputDevice.label == 'iPhone Microphone'
              ? 'iPhone Speaker'
              : currentAudioOutputDevice.label;
        }
      }, 2000);
    }
  }

  async getDeviceListForMobile(): Promise<void> {
    const devices = await VideoExpress.getDevices();
    if (devices) {
      this.overAllDevices = devices;
      this.videoInputs = this.overAllDevices.filter(
        (k: { kind: string }) => k.kind == 'videoInput'
      );
      this.sendSignal();
      this.mics = this.overAllDevices.filter(
        (k: any) => k.kind == 'audioInput' && k.label != 'Default'
      );
      if (this.mics.length > 0) {
        this.mics.forEach((element: any) => {
          element.label =
            element.label == 'iPhone Microphone'
              ? 'iPhone Speaker'
              : element.label;
        });
        this.iPhoneWiredHeadSet = this.mics.filter(
          (k: any) => k.label == 'Headset Microphone'
        );
        if (this.iPhoneWiredHeadSet.length > 0) {
          this.mics = this.mics.filter((k: any) => k.label != 'iPhone Speaker');
        }
      }
      setTimeout(async () => {
        this.currentVideoId = this.room.camera.getVideoDevice();
        this.videoId = this.videoInputs.filter(
          (v: { deviceId: string }) =>
            v.deviceId == this.currentVideoId.deviceId
        );
        if (this.videoId.length > 0) {
          this.selectedVideoCamera = this.videoId[0].label;
        }
        const currentAudioDevice = await this.room.camera.getAudioDevice();
        if (currentAudioDevice) {
          this.selectedAudioDevice =
            currentAudioDevice.label == 'iPhone Microphone'
              ? 'iPhone Speaker'
              : currentAudioDevice.label;
        }
        const audioOutputs = await VideoExpress.getAudioOutputDevices();
        this.speakers = audioOutputs;
        if (this.speakers.length > 0) {
          this.speakers.forEach((element: any) => {
            element.label =
              element.label == 'iPhone Microphone'
                ? 'iPhone Speaker'
                : element.label;
          });
          this.iPhoneWiredHeadSet = this.speakers.filter(
            (k: any) => k.label == 'Headset Microphone'
          );
          if (this.iPhoneWiredHeadSet.length > 0) {
            this.speakers = this.speakers.filter(
              (k: any) => k.label != 'iPhone Speaker'
            );
          }
        }
        const currentAudioOutputDevice =
          await VideoExpress.getActiveAudioOutputDevice();
        this.currentAudioOutputDevice =
          currentAudioOutputDevice.label == 'iPhone Microphone'
            ? 'iPhone Speaker'
            : currentAudioOutputDevice.label;
      }, 0);
    }
  }

  changeMicroPhone(val: any): void {
    this.selectedAudioDevice = val.label;
    this.room.camera.setAudioDevice(val.deviceId);
  }

  onSettingsClose(): void {
    this.onSettings = false;
  }

  changeVideoDevice(val: any): void {
    this.selectedVideoCamera = val.label;
    this.room.camera.setVideoDevice(val.deviceId);
  }

  async changeSpeaker(val: any): Promise<void> {
    this.currentAudioOutputDevice = val.label;
    await VideoExpress.setAudioOutputDevice(val.deviceId);
  }

  speakerMute(): void {
    this.headSet = !this.headSet;
    if (!this.headSet) {
      this.speakerText = 'Audio On';
    } else {
      this.speakerText = 'Audio Off';
    }
    const ele: HTMLElement = document.getElementsByClassName(
      'subscriber-audio-button'
    )[0] as HTMLElement;
    setTimeout(() => {
      if (ele) {
        ele.click();
      }
    }, 0);
  }

  // start video call
  conferece(): void {
    this.vonageClientService.setDataForVonage(
      this.vonageToken,
      this.vonageSessionId
    );
    this.room = null;
    setTimeout(() => {
      this.room = new VideoExpress.Room({
        apiKey: environment.vonageApiKey,
        sessionId: this.vonageSessionId,
        token: this.vonageToken,
        roomContainer: 'screen',
        participantName: this.participantName,
        managedLayoutOptions: {
          layoutMode: 'grid',
          cameraPublisherContainer: 'publisherDiv',
          screenPublisherContainer: 'subscriberHost',
        },
      });
      let pubOptions = {
        resolution: '1280x720',
        publishAudio: true,
        publishVideo: true,
        mirror: false,
        audioBitrate: 15,
        audioFallbackEnabled: true,
        facingMode: 'user',
        style: {
          backgroundImageURI: this.senderProfilePic,
        },
      };
      if (this.iam == 'patient') {
        pubOptions = {
          resolution: '1280x720',
          publishAudio: this.joinMeetingService.getAudio(),
          publishVideo: this.joinMeetingService.getVideo(),
          mirror: false,
          audioBitrate: 15,
          audioFallbackEnabled: true,
          facingMode: 'user',
          style: {
            backgroundImageURI: this.senderProfilePic,
          },
        };
        this.audioStatus = this.joinMeetingService.getAudio();
        this.videoStatus = this.joinMeetingService.getVideo();
      }
      this.room
        .join({ publisherProperties: pubOptions }, { buttonDisplayMode: 'on' })
        .then(() => this.callInitiated())
        .catch((e: any) => {
          this.isErrorPopup = true;
          this.erroMessage = e.message;
        });
      this.startTimerCount();
    }, 1000);
  }

  closePopup(): void {
    this.vonageClientService.logout();
    setTimeout(() => {
      const ele: HTMLElement = document.getElementsByClassName(
        'pi-times'
      )[0] as HTMLElement;
      setTimeout(() => {
        ele.click();
      }, 0);
    }, 0);
  }

  profilePicSplit: any = [];
  callInitiated(): void {
    this.authenticationService.dontshowidlepopup = true;
    if (this.room) {
      this.sendSignal();
      this.room.on('signal:assignProfilePic', (event: any) => {
        this.profilePicSplit = event.data.split('|');
        if (this.profilePicSplit[1] != this.room.participantId) {
          setTimeout(() => {
            console.log(this.room.participants[this.profilePicSplit[1]].camera);
            if (this.room.participants[this.profilePicSplit[1]].camera) {
              this.room.participants[
                this.profilePicSplit[1]
              ].camera.setDisabledImageURI(this.profilePicSplit[0]);
            }
          }, 2500);
        }
      });
      const { screen } = this.room; // getting the screen share is enable or not
      screen.on('started', () => {
        console.log('The screen sharing has started!');
        this.shareStatus = true;
      });
      screen.on('stopped', () => {
        console.log('The screen stopped sharing because: ');
        this.shareStatus = false;
      });
      this.room.on('participantJoined', (participant: any) => {
        if (this.iam == 'doctor') {
          clearTimeout(this.checkTimeout);
        }
        this.ispatientjoined = true;
        this.toaster.success(
          participant.name + ' joined the call',
          'Information'
        );
        this.loader.Alexhide();
        setTimeout(() => {
          this.sendSignal();
        }, 5000);
      });

      this.room.on('participantLeft', (participant: any) => {
        if (!this.isDoctor) {
          setTimeout(() => {
            this.getCompletedStatus();
          }, 2000);
        }
        this.sendSignal();
        setTimeout(() => {
          this.IsWaitingRoomStatus = false;
        this.ispatientjoined = false;
        }, 2000);
        
        this.toaster.success(
          participant.name + ' left from the call',
          'Information'
        );
      });
    }
    if (this.iam == 'doctor') {

      this.signalrService.initiateSignalrConnection(
        this.tokenService.userId(),
        1
      );

      // this.checkTimeout = setTimeout(() => {
      //   this.toaster.success("Patient didn't pick your call.");
      //   this.tempClose();
      //   this.signalrService.sendMessage(
      //     this.patientGuid,
      //     2,
      //     'Doctor leaves the call',
      //     this.meetingIdForStatus
      //   );
      // }, 57000);
      setTimeout(() => {
        this.elementForSpilitView = document.getElementsByClassName('OT_root');
        console.log(this.elementForSpilitView, 'ROOT');

        if (this.elementForSpilitView.length > 1) {
          clearTimeout(this.checkTimeout);
        }
      }, 5000);
      var dataLoad = {
        MeetingID: this.meetingIdForStatus,
        ProviderUserId: this.userIdForVonage,
      };
      this.joinMeetingService.insertProviderName(dataLoad).subscribe(
        (data: any) => {
          if (data) {
            setTimeout(() => {
              console.log("VC STARTED PROVIDER")
              if(this.enableVideoConsultaiton ==  false)
              this.signalrService.sendMessage(
                this.patientGuid,
                2,
                'Video call started',
                this.meetingIdForStatus
              );
            }, 2500);
          }
        },
        (err: any) => {
          console.log(err);
        }
      );
    } else {
      sessionStorage.setItem('call-started', 'true');
      sessionStorage.removeItem('reJoinCall');
      this.getCompletedStatus();
    }
    this.getDeviceList();
  }

  sendSignal() {
    if (this.room) {
      let profilePic = this.senderProfilePic;
      if (this.iam == 'out-patient') {
        profilePic = '';
      }
      this.room.signal(
        {
          type: 'assignProfilePic',
          data: profilePic + '|' + this.room.participantId,
        },
        (error: any) => {
          console.log(error);
        }
      );
    }
  }

  // un mute
  audioOn(): void {
    this.audioStatus = true;
    const { camera } = this.room;
    camera.enableAudio();
  }

  // mute
  audioOff(): void {
    this.audioStatus = false;
    const { camera } = this.room;
    camera.disableAudio();
  }

  // video on
  videoOn(): void {
    this.videoStatus = true;
    const { camera } = this.room;
    camera.enableVideo();
  }

  // video off
  videoOff(): void {
    this.videoStatus = false;
    const { camera } = this.room;
    this.sendSignal();
    camera.disableVideo();
  }

  // close the video call
  close(reson?: string): void {
    this.joinMeetingService.patientVideoCallStarted.next(false);

    this.authenticationService.dontshowidlepopup = false;
    if (this.iam == 'doctor') {
      this.setCompletedStatus(1);
      const response = reson ? reson : 'video call ended';
      this.signalrService.sendMessage(
        this.patientGuid,
        2,
        response,
        this.meetingIdForStatus
      );
      clearTimeout(this.checkTimeout);
    }
    if (this.room) {
      this.room.leave();
    }
    this.room = null;
    if (this.iam == 'patient') {
      if (this.providerCallEnd) {
        sessionStorage.setItem('call-ended-byPatient', 'false');
      } else {
        sessionStorage.setItem('call-ended-byPatient', 'true');
      }

      if (this.joinMeetingService.userLoggedIn) {
        this.router.navigate(['/meetingUser/call-ended']);
      } else {
        this.router.navigate(['/meeting/call-ended']);
      }
      setTimeout(() => {
        const ele: HTMLElement = document.getElementsByClassName(
          'pi-times'
        )[0] as HTMLElement;
        setTimeout(() => {
          if (ele) {
            ele.click();
          }
        }, 0);
      }, 0);
      this.setWaitingRoomStatus(this.meetingIdForStatus, 0);
    }
    if (this.iam == 'out-patient') {
      sessionStorage.setItem('out-patient-end-call', 'true');
      this.router.navigate(['/meeting/call-ended']);
    }
    if (this.iam == 'doctor') {
      setTimeout(() => {
        const ele: HTMLElement = document.getElementsByClassName(
          'pi-times'
        )[0] as HTMLElement;
        setTimeout(() => {
          ele.click();
        }, 0);
      }, 0);
    }
    this.vonageClientService.logout();
    this.isVideoCallEnd = false;
    if (this.iam == 'patient' || this.iam == 'out-patient') {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }
    
  }

  tempClose(): void {
    if (this.room) {
      this.room.leave();
    }
    setTimeout(() => {
      const ele: HTMLElement = document.getElementsByClassName(
        'pi-times'
      )[0] as HTMLElement;
      if (ele) {
        setTimeout(() => { 
          ele.click();
        }, 0);
      }
    }, 0);
  }

  // start screen sharing
  Sharing(): void {
    this.room.startScreensharing('screen-preview');
  }

  // stop screen sharing
  StopSharing(): void {
    this.shareStatus = false;
    this.room.stopScreensharing();
  }

  async rotateCamera(): Promise<void> {
    const devices = await VideoExpress.getDevices();
    if (devices) {
      this.overAllDevices = devices;
      this.videoDevices = this.overAllDevices.filter(
        (k: { kind: string; label: string }) =>
          k.kind == 'videoInput' && k.label != 'Desk View Camera'
      );
      this.currentVideoId = this.room.camera.getVideoDevice();
      if (this.videoDevices.length > 0) {
        this.arrayCount = this.videoDevices.length - 1;
        if (
          this.videoDevices[this.arrayCount].deviceId !=
          this.currentVideoId.deviceId
        ) {
          this.room.camera.setVideoDevice(
            this.videoDevices[this.arrayCount].deviceId
          );
        } else {
          this.room.camera.setVideoDevice(this.videoDevices[0].deviceId);
        }
        return;
      }
    }
  }

  startTimerCount(): void {
    this.interval = setInterval(() => {
      if (this.time === 0) {
        this.time++;
        const today = new Date();
        this.Videostartime =
          today.getHours() +
          ':' +
          today.getMinutes() +
          ':' +
          today.getSeconds();
      } else {
        this.time++;
      }
      this.display = this.transform(this.time);
    }, 1000);
  }

  transform(value: number): string {
    const hours: number = Math.floor(value / 3600);
    const minutes: number = Math.floor((value % 3600) / 60);
    return (
      ('00' + hours).slice(-2) +
      ' : ' +
      ('00' + minutes).slice(-2) +
      ' : ' +
      ('00' + Math.floor(value - minutes * 60)).slice(-2)
    );
  }

  minimize(): void {
    this.minMaxState = false;
    this.maximized = true;
    this.minimized = false;
    setTimeout(() => {
      this.idElement = document.getElementById('layoutContainerWrapper')
        ? document.getElementById('layoutContainerWrapper')
        : '';
      this.idElement.style.height = '30vh';
      const ele: HTMLElement = document.getElementsByClassName(
        'pi-window-minimize'
      )[0] as HTMLElement;
      setTimeout(() => {
        ele.click();
      }, 0);
    }, 0);
  }

  maximize(): void {
    this.minMaxState = true;
    this.minimized = true;
    this.maximized = false;
    setTimeout(() => {
      this.idElement = document.getElementById('layoutContainerWrapper')
        ? document.getElementById('layoutContainerWrapper')
        : '';
      this.idElement.style.height = '100vh';
      const ele: HTMLElement = document.getElementsByClassName(
        'pi-window-maximize'
      )[0] as HTMLElement;
      this.chatContainer = document.getElementById(
        'fc_frame'
      ) as HTMLElement;
      setTimeout(() => {
        ele.click();
        if (this.chatContainer) {
          this.chatContainer.style.display = 'none';
        }
      }, 0);
    }, 0);
  }

  endCall(): void {
    this.isVideoCallEnd = true;
  }

  onClickAddPatient(): void {
    this.getQueueList();
    this.openInviteDialog = true;
  }

  validateEmail(email: any): boolean {
    if (email.trim() == '') {
      return false;
    }
    const regularExpression =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regularExpression.test(String(email).toLowerCase());
  }

  onEmailText(): void {
    this.showEmailError = false;
  }

  onParticipantText(): void {
    this.showError = true;
  }

  onClickSend(): void {
    const data = this.messaging.value;
    let mobileNumber = '';
    if (data.phoneNumber) {
      if (data.phoneNumber?.toString().length < 8) {
        this.showMinLenPhone = true;
        return;
      }
      mobileNumber = this.callingcode + '' + data.phoneNumber;
    }

    if (data.emailAddress) {
      if (!this.validateEmail(data.emailAddress)) {
        this.showEmailError = true;
        return;
      }
    }

    if (data.patientName.trim() == '') {
      this.showError = data.patientName.trim() == '' ? false : true;
      this.messaging.patchValue({
        patientName:
          data.patientName.trim() == '' ? '' : data.patientName.trim(),
      });
      return;
    }

    if (data.comment.trim() == '') {
      this.showErrorComment = data.comment.trim() == '' ? true : false;
      this.messaging.patchValue({
        comment: data.comment.trim() == '' ? '' : data.comment.trim(),
      });
      return;
    }

    if (!data.sms) {
      mobileNumber = '';
    }
    let comments = data.comment;
    const hello = 'Dear ' + data.patientName;
    comments = comments.replace('Dear', hello);
    const re = / /g;
    const str = data.patientName;
    const patientNameForlink = str.replace(re, '>');
    const payLoad = {
      Name: data.patientName,
      Email: data.emailAddress,
      MobileNumber: mobileNumber,
      Comments: comments,
      Link:
        environment.outPatientUrl +
        this.meetingIdForStatus +
        '/' +
        patientNameForlink,
    };
    this.joinMeetingService
      .sendInviteParticipantLink(payLoad)
      .subscribe((val: any) => {
        if (val) {
          this.toaster.success('Message sent succesfully');
          this.messaging.reset();
          this.messaging.patchValue({
            SelectCountry: '+614',
            email: true,
            comment:
              'Dear, Dr. ' +
              this.docName +
              ' has invited you to a secure video call.',
          });
        }
        this.openInviteDialog = false;
        this.role =
          'Dear, Dr. ' +
          this.docName +
          ' has invited you to a secure video call.';
      });
  }

  onClickCancel(): void {
    this.openInviteDialog = false;
    this.messaging.reset();
    this.messaging.patchValue({
      SelectCountry: '+614',
      email: true,
      comment:
        'Dear, Dr. ' +
        this.docName +
        ' has invited you to a secure video call.',
    });
    this.role =
      'Dear, Dr. ' + this.docName + ' has invited you to a secure video call.';
  }

  getQueueList(): void {
    const payLoad = {
      ProviderUserId: this.userIdForVonage,
    };
    this.joinMeetingService
      .getVideoConsultationQueuePatients(payLoad)
      .subscribe((data: any) => {
        this.patientQueueData = data;
        this.patientQueueData = this.patientQueueData.filter(
          (val: any) => val.RequestedAppSlotId != this.meetingIdForStatus
        );
      });
  }

  onClickVideoButton(item: any): void {
    const payLoad = {
      MeetingID: item.RequestedAppSlotId,
      ExternalRoomGuid: this.meetingIdForStatus,
    };
    this.joinMeetingService
      .insertExternalRoomGuid(payLoad)
      .subscribe((data: any) => {
        if (data) {
          this.signalrService.sendMessage(
            this.patientGuid,
            2,
            'Doctor invited as a out-patient',
            item.RequestedAppSlotId
          );
          this.toaster.success('Patient invited.');
          this.openInviteDialog = false;
        }
      });
  }

  selectedTabIndexChange(evt: any): void {
    if (evt == 0) {
      this.getQueueList();
    }
  }

  sendemailNotifcation(){
   
    applicationHttpClientCreator(this.http)
      .Post(`${URLConstants.sendVCNotificationEmail}${this.patientInfo.PatientUserId}&ProviderUserID=${this.userIdForVonage}&InvitationId=${this.InvitationId}`,{})
      .subscribe(
        (data: any) => {
          this.toaster.success("Notification has been sent.")
        },
        (err: any) => {
          console.log(err);
          this.loader.hide();
        }
      );
  }
  isadmit(){
    this.loader.Alexshow();
    this.signalrService.sendMessage(
      this.patientGuid,
      2,
      'Video call started',
      this.meetingIdForStatus
    );
  }
  isdeny(){
    this.showDenayConfirm = true;
  }

  confirmDeny() {
    this.close('deny');
  }

  closeErrPopup() {
    this.tempClose();
  }

  copyTxt() {
    navigator.clipboard.writeText(this.qrURL).then(() => {
      console.log('Text copied to clipboard');
      this.toaster.success('URL Copied.');
    }).catch((err) => {
      console.error('Unable to copy text to clipboard', err);
    });
  }
}
