import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appSpecialCharacterFilter]'
})
export class SpecialCharacterFilterDirective {
  @HostListener('input', ['$event'])
  onInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const sanitizedValue = inputElement.value.replace(/[^A-Za-z0-9]/g, '');
    inputElement.value = sanitizedValue;
  }
}
