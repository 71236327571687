 <div class="content-panel p-0 dialog-box text-header-alerts dialog-box-minWidth">
   <div class="alert-header-content">
    <h3 class="font-900">{{data.title}}</h3>
    
    
    <!-- <mat-icon matSuffix (click)="onNoClick()" class="close-alert">close</mat-icon>
    -->

   </div>
    
    <div [ngClass]="{'alert alert-info': data.alertType === enumAlertType.Info , 'alert alert-success': data.alertType === enumAlertType.Success, 'alert alert-warning': data.alertType === enumAlertType.Warning, 'alert alert-error': data.alertType === enumAlertType.Error}">
      <span *ngIf="data.alertType != enumAlertType.None"><b>{{data.alertType}}</b></span>
      <!-- <p>{{data.message}}</p> -->
      <div class="alert-data" [innerHTML]="data.message"></div>
    </div>
    <div class="dialog-footer actions-reduce-bts">
      <div fxLayout="row wrap">
        <button mat-button [mat-dialog-close]="true" class=" btn btn-primary-rect m-t-20 ">
          {{data.okButtonText}}
        </button>
      </div>
    </div>
  </div>