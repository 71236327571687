import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { error } from 'console';
import moment from 'moment';
import { CustomValidators } from 'ngx-custom-validators';
import { Subscription, fromEvent, timer } from 'rxjs';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { AlertType } from 'src/app/core/constants/app-enums';
import { URLConstants } from 'src/app/core/constants/urlConstants';
import { EncryptDecrypt } from 'src/app/core/helper/encrypt-decrypt';
import { applicationHttpClientCreator } from 'src/app/data/interface/IRequestOptions';
import { CommonService } from 'src/app/data/service/common.service';
import { ToasterService } from 'src/app/data/service/toaster.service';
import { AlertDialogComponent } from 'src/app/shared/component/alert-dialog/alert-dialog.component';

@Component({
  selector: 'app-sms-registration',
  templateUrl: './sms-registration.component.html',
  styleUrls: ['./sms-registration.component.scss']
})
export class SmsRegistrationComponent implements OnInit {

  configJson:any = {}
  thisIsMe = [];
  thisIsNotMe = [];
  maxBirthdayDate = new Date();
  minBirthdayDate = new Date(1900, 0, 1);
  Message:String;
  @ViewChild('stepper') stepper :MatStepper;
  constructor(
    private fb: FormBuilder,
    public http: HttpClient,
    private toaster: ToasterService,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private dialog: MatDialog,
    private router: Router,
    private commonService: CommonService
  ) {
    this.http.get('assets/config.json').subscribe(
      (data: any) =>{
        this.configJson= data;
        this.thisIsMe = data.smsRegistraionThisIsMe;
        this.thisIsNotMe = data.smsRegistraionThisIsNotMe;
      })
    }
  isProceed:boolean = false
  hidePassword:boolean = true
  hideConfirmPassword:boolean = true;
  registrationForm: FormGroup = Object.create(null);
  validationForm: FormGroup = Object.create(null);
  feedbackForm: FormGroup = Object.create(null);
  password:String="";
  confirmPassword:String="";
  itsMe:Boolean;
  emailExistVal:Boolean = false
  patientDetails:any;
  isNumber: boolean = false;
  isCapital: boolean = false;
  isSmall: boolean = false;
  isSpecial: boolean = false;
  isReasonSelected:boolean ;
  invalidURL:boolean ;
  passwordstrenth: string;
  smsreason:string;
  smsfeedback:string;
  countDown: Subscription;
  counter = 180;
  tick = 1000;
  resendEnable:boolean =false;
  token:String|null
  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap;
    this.token = routeParams.get('token');
    this.initializeForm();
    this.initalizedValidation();
    this.getPatDetails();
    
  }
  initializeForm(){
    this.registrationForm = this.fb.group({
      email: ['', Validators.compose([Validators.required, CustomValidators.email])],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      dob: ['', Validators.compose([Validators.required, CustomValidators.date])],
      readAccept: [false, Validators.requiredTrue],
    }); 
  }

  getPatDetails(){
    applicationHttpClientCreator(this.http).Post(`${URLConstants.getTinycodebyPatDetails}${this.token}`,{})
      .subscribe((data: any) => {
        if( data.length == 0){
          this.invalidURL = true
        }
        else if (data) {
          this.invalidURL = false
          this.patientDetails =  data[0];
        }
        // if(data?.Status === 'Error') {
        //   this.showAlertMessage('Warning', data?.Message);
        // } else if(data?.Status === 'Success') {
        //   this.showAlertMessage(data?.Status, data?.Message);
        // } else if(data?.Status === 'ActivateAccount') {
        //   this.showAlertMessage('ActivateAccount', data?.Message);
        //   this.router.navigate(['/authentication/activate-account']);
        // } else if(data?.Status === 'ActivationConfirmation') {
        //   this.showAlertMessage('ActivationConfirmation', data?.Message);
        // }
      },
    (err: any) => {
      this.invalidURL = true
      this.Message = err.error.message || err.error.Message
      console.log(err.error.message || err.error.Message);
      this.toaster.error(err.error.message|| err.error.Message);
    });
  }
  initalizedValidation(){
    this.validationForm = this.fb.group({
      validation: ['', Validators.required],
    })
  }
  initalizedFeedback(){
    this.feedbackForm = this.fb.group({
      reason: ['', Validators.required],
      feedback: [''],
    })
  }
  proceed(){
    this.isProceed=true
    this.stepper.next()
  }
  decline(){
    this.isProceed=false
    this.stepper.next()
  }
  
  togglePasswordVisibility(): void {
    this.hidePassword = !this.hidePassword;
  }
  selfRegester(self:Boolean){
    this.itsMe= self
    this.stepper.next()
  }
  emailExist(event: Event): any {
    if (this.registrationForm.controls['email'].valid) {
      const inputElement = event.target as HTMLInputElement;
      const inputObservable = fromEvent(inputElement, 'keyup');
      // To check if the user has done typing , to avoid unnecessary calls
      inputObservable.pipe(
        debounceTime(1000),
        distinctUntilChanged()
      ).subscribe(() => {
        this.emailExistAPIcall()
      });
    }
    // return false
  }
  emailExistAPIcall() {
    if (this.registrationForm.controls['email'].valid) {
      let encryptedEmail = EncryptDecrypt.encryptUsingAES256(this.registrationForm.value.email)
      applicationHttpClientCreator(this.http).Post(`${URLConstants.isUserRegistered}`, {
        Email: encryptedEmail
      }).subscribe((data: any) => {
        this.emailExistVal = data
      }, (error: any) => {
        return error;
      })
    }
  }
  
  submitForm(){
    if(this.registrationForm.valid){
      let encryptedEmail = EncryptDecrypt.encryptUsingAES256(this.registrationForm.value.email)
        applicationHttpClientCreator(this.http).Post(`${URLConstants.isUserRegistered}`,{
          Email:encryptedEmail
        }).subscribe((data: any) => {
          if(data == false){
            this.createAccount()
          }
          else{
            this.toaster.error("Email already exist")
          }
      })
    }
  }

  createAccount(){
    let encryptedEmail = EncryptDecrypt.encryptUsingAES256(this.registrationForm.value.email)
    let encUserName = EncryptDecrypt.encryptUsingAES256(this.patientDetails.patientName)

    console.log(moment(this.registrationForm.value.dob).format('MM/DD/YYYY'))
    if(moment(this.registrationForm.value.dob).format('MM/DD/YYYY') == moment(this.patientDetails.Dob).format('MM/DD/YYYY')){

    applicationHttpClientCreator(this.http).Post(`${URLConstants.sendSMSEmailOTP}`,{
      Email: encryptedEmail,
      Password: encryptedEmail,
      patientUserID: this.patientDetails.patientUserID,
      patientName: encUserName
    }).subscribe((data: any) => { 
        // if(data){
          if(this.stepper.selectedIndex == 1){
            this.stepper.next();  
          }
          this.resendEnable = false
          this.counter = this.configJson.OTPResendSeconds;
          this.setTimer();
          this.toaster.success("Verification code has been sent to the email.")
        // }
        
       },(err)=>{
        this.toaster.error(err.error.Message || err.error.message)
    })
    
    // this.toaster.success("User Created");
    // this.stepper.next();
    }else{
      this.toaster.error("Invalid Date of Birth")
    }
  }
  sendFeedback(){
    if(this.smsreason){
      let obj = {
        Feedback: this.smsfeedback,
        Reason: this.smsreason,
        TinyCode: this.token
      }
      applicationHttpClientCreator(this.http).Post(`${URLConstants.SMSInviteReasonAndFeedback}`,obj).
      subscribe((data: any) => {
        this.isReasonSelected = true;
        this.toaster.success("Feedback Sent");
        this.stepper.next();
      },(error:any)=>{
        this.toaster.error( error.messsge || error.messsge );
      })
    }
    else{
      this.toaster.error("Please select one of the reasons");
      this.isReasonSelected = false;
    }
  }
  goToLast(){
    // this.isProceed = true
    this.stepper.selectedIndex = 3;
  }
  showAlertMessage(title: any, message: any) {
    const alertDialog = {
      title: title,
      message: message,
      alertType: AlertType.None,
      okButtonText: this.translate.instant('CLOSE')
    };
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: alertDialog
    });
    dialogRef.afterClosed().subscribe((result: any) => {
         sessionStorage.removeItem('userActivationStatus');
        this.commonService.reloadCurrentRoute(this.router.url);
    });
  }
  
  checkCase(ch: any) {
    const newpasslength = this.registrationForm.value.password.length;
    const newPassword = this.registrationForm.value.password;
    // this.registrationForm.value.newPassword.match('^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{10,}$');
    if (newpasslength < 5) {
      this.passwordstrenth = "poor";
    }
    if (newpasslength > 5 && newpasslength <= 8) {
      this.passwordstrenth = "average";
    }
    if (/[*@$!#%&()^~{}~`]+/.test(ch.key)) {
      this.isSpecial = true;
    }
    if (newpasslength >= 10 && this.isSmall === true && this.isCapital === true && this.isNumber === true  && this.isSpecial == true) {
      this.passwordstrenth = "excelent";
    }
    if (!isNaN(ch.key * 1)) {
      return this.isNumber = true;
    }
    else if (/[A-Z]/.test(ch.key)) {
      return this.isCapital = true;
    }
    else if (/[a-z]/.test(ch.key)) {
      return this.isSmall = true;
    }
  }

  
  falseCase(falcase: any) {
    var newpasslength = this.registrationForm.value.password.length;
    if (falcase.value === null || falcase.value === "")
    {
      this.isCapital = false;
      this.isSmall = false;
      this.isNumber = false;
      this.isSpecial = false;
      return;
    }
    if(newpasslength < 5) {
      this.passwordstrenth = "poor";
    }
    if(newpasslength > 5 && newpasslength <= 8) {
      this.passwordstrenth = "average";
    }
    if(newpasslength >= 10 && this.isSmall === true && this.isCapital === true && this.isNumber === true  && this.isSpecial == true) {
      this.passwordstrenth = "excelent";
    }
    var theAnswer = "";
  if (/[a-z]/.test(falcase.value)) {
    theAnswer = "It's a lower case letter."
  } 
  else {
    return this.isSmall = false;
  }
  if (/[A-Z]/.test(falcase.value)) {
    theAnswer = "It's an upper case letter.";
  } 
  else {
    return this.isCapital = false;
  }
  if(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~`]/.test(falcase.value)) {
    theAnswer = "It's an Special Character";
   }
   else {
    return this.isSpecial = false;
   }
  if(/[0-9]+/g.test(falcase.value)) {
    theAnswer = "number.";
   }
   else {
    return this.isNumber = false;
   }
  }
  
  checkCase1(val: any): void {
   
    
    const newpasslength = this.registrationForm.value.password?.length;
    if (val.value == null || val.value == '')
      {
      this.isCapital = false;
      this.isSmall = false;
      this.isNumber = false;
      this.isSpecial = false;
      }
      if (/[a-z]/.test(val.value)) {
        this.isSmall = true;
      }
      if (/[A-Z]/.test(val.value)) {
        this.isCapital = true;
      }
      if (/[0-9]+/g.test(val.value)) {
        this.isNumber = true;
      }
      if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~`]/.test(val.value)) {
        this.isSpecial = true;
      }
  }

  verifyOTP(){
    let obj = {
      VerificationCode:this.validationForm.value.validation,
      ConfirmPassword: this.registrationForm.value.confirmPassword,
      DateOfBirth: this.registrationForm.value.dob,
      EmailAddress: this.registrationForm.value.email,
      Password: this.registrationForm.value.password,
      TermsAndConditions:  this.registrationForm.value.readAccept,
      UserRedirectedFromActivationPage: true,
      Btb: null,
      EmailRedirect: false,
      IsOptedOut: false,
      patientUserID: this.patientDetails.patientUserID
  }
  applicationHttpClientCreator(this.http).Post(URLConstants.SMSVerifyActivationConfirmation,obj).
    subscribe((data: any) => {
      if(data){
        this.stepper.next();
      }
    },(error:any)=>{
      this.toaster.error( error.messsge || error.messsge );
    })
  }

  setTimer(){
    this.countDown = timer(0, this.tick)
      .pipe(take(this.counter))
      .subscribe(() => {
        --this.counter;
        // console.log(this.counter);
        if (this.counter == 0) {
          this.countDown.unsubscribe();
          this.resendEnable = true
        }
      });
  }
  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return (
      ('00' + minutes).slice(-2) +
      ':' +
      ('00' + Math.floor(value - minutes * 60)).slice(-2)
    );
  }
  goToSignIn(){
    this.router.navigateByUrl('/authentication/login')
  }
}
