<mat-horizontal-stepper>
    <mat-step *ngIf="paymentPage">
        <div class="payment content-panel">
            <!-- <h4 class="d-flex"> <i class="icon-cent-chevron-left-arrows color-dark cursor-pointer mobile-view" (click)="backToAppointment()"></i>
            Please pay to confirm your appointment.</h4> -->
            <h4 class="d-flex" *ngIf="paymentObject?.Cost !='0.00' && activePaymentOption == 1 "> <i
                    class="icon-cent-chevron-left-arrows color-dark cursor-pointer mobile-view"
                    (click)="backToAppointment()"></i>
                Please pay to confirm your appointment.</h4>
            <h4 class="d-flex"
                *ngIf="(paymentObject?.Cost =='0.00' && activePaymentOption == 1 ) || activePaymentOption == 2 "> <i
                    class="icon-cent-chevron-left-arrows color-dark cursor-pointer mobile-view"
                    (click)="backToAppointment()"></i>
                Please confirm your appointment.</h4>
            <mat-card>
                <mat-card-content>
                    <div class="payment-profile">
                        <mat-card id="no-bg">
                            <mat-card-header>
                                <div mat-card-avatar>
                                    <!-- <img src="assets/images/users/1.jpg" class="img-fluid img-circle" /> -->
                                    <!-- <p>{{appointmentObject?.ProviderImage}}</p> -->
                                    <!-- <img src="{{appointmentObject?.ProviderImage}}" class="img-fluid img-circle" /> -->
                                    <img [src]="profileImgURL !=='' ? profileImgURL  : 'assets/images/nophoto_unknown.gif'"  onError="this.src='assets/images/nophoto_unknown.gif'" class="img-fluid img-circle" />
                                    

                                    <mat-card-title>
                                        <!-- <p class="payment-date">{{ paymentObject?.selectedDate | date: 'dd/MM/yyyy | hh:mm a'}}</p> -->
                                        <p class="payment-date" *ngIf="!appointmentObject?.isAlexEnabled">{{
                                            paymentObject?.selectedDate | date: 'dd MMM yyyy | hh:mm a'}}</p>
                                        <p class="payment-date" *ngIf="appointmentObject?.isAlexEnabled">{{
                                            paymentObject?.selectedDate | date: 'dd MMM yyyy | hh:mm a'}}</p>
                                        <!-- {{ journal?.EventDate | date: "dd MMM yyyy hh:mm a" }} -->
                                        <!-- T1<p class="payment-date"> {{ appoitmentDate }}</p> -->
                                        <!-- T2<p class="payment-date"> {{ paymentObject.paymentObject | date: "dd MMM yyyy hh:mm a" }}</p>
                                        T3<p class="payment-date"> {{ paymentObject.AppSelectedDate | date: "dd- MM-yyyy" }}</p>
                                        T4<p class="payment-date"> {{ paymentObject.AppSelectedDate | date: "dd- MM-yyyy" }}</p> -->
                                        <!-- T5<p class="payment-date"> {{ paymentObject.AppSelectedDate}}</p> -->
                                        <!-- T2 <p class="payment-date"> {{paymentObject.AppSelectedDate | date:'dd MMM yyyy'}},
                                        {{paymentObject.AppSelectedDate | date:'shortTime'}}</p>
                                       T3 - {{ paymentObject.AppSelectedDate }} <br/>
                                       T4-{{ paymentObject.AppSelectedDate | date: "dd- MM-yyyy"  }}<br/>
                                       T5-{{ paymentObject.AppSelectedDate | date: "MMM d, yyyy" }}<br/> -->
                                        <!-- <p class="payment-date"> {{paymentObject.AppSelectedDate | date:'dd MMM yyyy'}}, {{paymentObject.AppSelectedDate | date:'shortTime'}}</p> -->
                                        <!-- {{ paymentObject.AppSelectedDate | date: 'MMM d, y'}} | {{paymentObject.AppSelectedDate | date:'shortTime'}}</p> -->
                                        <p class="profile-name">{{ appointmentObject?.ProviderName}}</p>
                                    </mat-card-title>
                                </div>
                            </mat-card-header>
                            <mat-card-actions>
                                <p>
                                    <i class="icon-cent-practice-medical"></i> {{appointmentObject?.LocationName}}

                                </p>
                            </mat-card-actions>

                            <hr>
                            <mat-card-actions>
                                <!-- <p><img src="assets/images/video.png"> Video Call</p>
                <p> <img src="assets/images/Time.png">20 Minutes</p> -->
                                <!-- <p><img src="assets/images/video.png"> Video Call</p> -->


                                <p><i class="{{consultTypeClass}}"></i> {{consultationType}}</p>
                                <p>
                                    <i class="icon-cent-timer-date"></i>{{paymentObject?.SlotDuration}} Minutes
                                </p>
                            </mat-card-actions>
                            <hr>
                        </mat-card>
                    </div>
                    <div class="payment-detail">
                        <!-- <h6>Total to pay: $ {{ paymentObject.Cost }}</h6>
                        <p>Adult, non-member, incl GST</p> -->
                        <h6 *ngIf="paymentObject?.Cost !='0.00'">{{'Fee (Incl. GST)' | translate}}: ${{ paymentObject?.Cost }}</h6>
                    </div>
                </mat-card-content>
            </mat-card>
            <mat-card class="radius-10 h-overflow">
                <mat-card-content>
                    <div class="payment-method" *ngIf="paymentObject?.Cost !='0.00'">
                        <p>{{"I will pay for this via"|translate}}</p>
                        <div class="method-detail">

                            <div fxLayout="row wrap">
                                <div fxFlex.gt-sm="50%" fxFlex="100" *ngIf="trHealthCentre">
                                    <button mat-button class="box"
                                        [ngClass]="{'payment-active': activePaymentOption === 2}"
                                        (click)="tabToggle(2)">{{'Pay At Health Centre' | translate}}</button>
                                </div>
                                <div fxFlex.gt-sm="50%" fxFlex="100" *ngIf="trOnlinePayment && hidePayonline">
                                    <button mat-button class="box"
                                        [ngClass]="{'payment-active': activePaymentOption === 1}"
                                        (click)="tabToggle(1)">PAY ONLINE</button>
                                </div>

                                <!-- <div fxFlex.gt-sm="50%" fxFlex="100">
                                      <button mat-button class="box" 
                                            [ngClass]="{'payment-active': activePaymentOption === 3}" 
                                                  (click)="tabToggle(3)">Confirm your booking now</button>
                                               </div> -->
                            </div>

                            <div *ngIf="activePaymentOption == 2" class="box-content">
                                <h6>{{"How to pay via at clinic"|translate}}</h6>
                                <p>{{"You can pay for this service when you visit the health centre"|translate}}</p>
                            </div>

                            <div *ngIf="activePaymentOption == 1" class="box-content">
                                <h6>{{"How to pay via credit card"|translate}}</h6>
                                <p>{{"You will be redirected to Windcave payment gateway to process the credit card
                                    payment"|translate}}</p>
                            </div>
                        </div>
                    </div>
                    <!-- Term and condition -->
                    <div class="checkbox-inflex">
                        <mat-checkbox [(ngModel)]="chkTerms" (click)="acceptCheckBox($event)"></mat-checkbox>

                        <span> I accept the <a class="terms" (click)="onOpenTermsAndConditions()">&nbsp;Terms &
                                Conditions
                            </a> &nbsp;for booking this appointment
                        </span>

                    </div>
                    <!-- Term and condition -->
                    <!-- <div fxLayout="row wrap" class="checkbox" *ngIf="paymentObject?.ConsultationType === 'MMHVC'">
                      <mat-checkbox [(ngModel)] ="vcSettigsTerms" ></mat-checkbox>I accept the <a class="terms" (click)="onOpenVCTermsAndConditions()">&nbsp;Video Term & Conditions
                      </a> &nbsp;for booking this appointment                     

                  </div> -->
                    <div class="tab-actions tabs-inside m-t-30 text-center" id="desktop-view">
                        <!-- <button mat-button class="m-r-20 back-button" [routerLink]="['/appointment/bookappointment']">< Go Back</button> -->
                        <!-- <button mat-button class="m-r-20 back-button" [routerLink]="['/appointment/mypayments']">Go Back</button>  -->
                        <button mat-button class="btn-secondary m-r-20"
                            [routerLink]="['/appointment/myappointments/bookappointment']">Cancel</button>
                        <!-- <button mat-button  class="btn btn-primary with-green" disabled = "{{activePaymentOption === 0 && !chkTerms ? true : false}}" (click)="submitAppointmentPaymentRequest()">{{paymentBtnText}}</button>-->
                        <button mat-button class="btn-primary with-green" *ngIf="paymentObject?.Cost !='0.00'"
                            disabled="{{activePaymentOption > 0 && chkTerms ? false : true }}"
                            (click)="submitAppointmentPaymentRequest()">{{paymentBtnText}}</button>
                        <button mat-button class="btn-primary with-green" *ngIf="paymentObject?.Cost ==='0.00'"
                            disabled="{{chkTerms ? false : true }}" (click)="submitAppointmentPaymentRequest()">Confirm your
                            booking now</button>
                    </div>
                </mat-card-content>
            </mat-card>
            <!-- payment detail -->

            <div class="tab-actions tabs-inside m-t-30" id="mobile-view">
                <!-- <button mat-button matStepperPrevious class="btn-back" [routerLink]="['/appointment/myappointments/bookappointment']"> < Back</button> -->
                <!-- <button mat-button matStepperPrevious class="btn btn-primary without-green"> Cancel</button> -->
                <!-- <p class="fee-info" *ngIf="paymentObject?.Cost ==='0.00'"> {{"Fee"|translate}} <span>${{ paymentObject?.Cost }}</span></p> -->
                <div fxLayout="row wrap">
                    <div fxFlex="100" fxLayoutAlign="center center">
                        <!-- <h3 *ngIf="paymentObject?.Cost ==='0.00'"> {{"Fee"|translate}} <span>${{ paymentObject?.Cost
                                }}</span></h3> -->
                    </div>
                </div>
                <!-- <button mat-button disabled = "{{activePaymentOption > 0 && chkTerms ? false : true}}" (click)="submitAppointmentPaymentRequest()" class="btn btn-primary with-green">
          <img src="assets/images/right-arrow.svg" alt=""></button> -->
                <button mat-button *ngIf="paymentObject?.Cost !='0.00'"
                    disabled="{{activePaymentOption > 0 && chkTerms ? false : true}}"
                    (click)="submitAppointmentPaymentRequest()" class="btn-primary with-green m-0">
                    {{"Confirm"|translate}}</button>

                <button mat-button class="btn-primary with-green" *ngIf="paymentObject?.Cost ==='0.00'"
                    disabled="{{chkTerms ? false : true }}" (click)="submitAppointmentPaymentRequest()">Confirm your booking
                    now</button>
            </div>
        </div>




    </mat-step>
    <!-- <mat-step>
        <div class="payment content-panel">
            <h4>One moment, we’are redirecting to payment page..</h4>
            <p>This process should only take second or two. Thank you for your patience.</p>
            <div class="fixed">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                <p matStepperNext>Booking in progress...</p>
                <button mat-button mat-button matStepperNext class="btn btn-primary with-green">
          <img src="assets/images/right-arrow.svg" alt=""></button>
            </div>
        </div>

    </mat-step>
    <mat-step>
        <div class="payment content-panel">
            <mat-card>
                <mat-card-content>
                    <div class="payment-profile">
                        <mat-card>
                            <mat-card-header>
                                <div mat-card-avatar>
                                    <img src="assets/images/users/1.jpg" class="img-fluid img-circle" />

                                    <mat-card-title>
                                        <p> {{ paymentObject.AppSelectedDate | date: 'MMM d, y'}} | {{paymentObject.AppSelectedDate | date:'shortTime'}}</p>
                                        <p>{{appointmentObject.name}}</p>
                                    </mat-card-title>
                                </div>
                            </mat-card-header>
                            <mat-card-actions>
                                <p>
                                    <i class="icon-cent-practice-medical"></i>
                                   
                                    {{appointmentObject.LocationName}}</p>
                            </mat-card-actions>

                            <hr>
                            <mat-card-actions>
                                <p><img src="assets/images/video.png"> Video Call</p>
                                <p> <img src="assets/images/Time.png">20 Minutes</p>

                            </mat-card-actions>
                            <hr>
                            <mat-card-content>
                                <div class="note">
                                    <p> Note: Overall Checkout, but primarily to do a repeat prescription of some pills I usually take for a skin rush I have on my on my face.</p>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <div class="payment-detail">
                        <h6>Total to pay: ${{ paymentObject.Cost }}</h6>
                        <p>Adult, non-member/ ${{ paymentObject.Cost }} incl. </p>
                        <p>Credit Card</p>
                        <p>GST•••• •••• •••• 5047</p>
                    </div>
                    <div class="email">
                        <mat-icon matSuffix>email</mat-icon>
                        <p>Email RECEIPT</p>
                    </div>
                    <button mat-button class="btn btn-primary btn-block">GO TO MY DASHBOARD</button>
                </mat-card-content>
            </mat-card>
        </div>
    </mat-step> -->
    <mat-step *ngIf="!paymentPage && !iframeContent" autofocus>
        <div class="container" style="width:100%;overflow:hidden;">
            <div *ngIf="!showPaymentResponse" id="embed-target"></div>
            <app-payment-failure-response *ngIf="showPaymentResponse" [txnId]="orderId"></app-payment-failure-response>
                <mat-card-content class="button-layout">
                    <div class="tab-actions tabs-inside m-t-30 text-center" id="desktop-view">
                        <button mat-button class="btn-secondary-pill-gray" (click)="cancelCallback()">Cancel</button>
                    </div>
                </mat-card-content>
            <!-- <iframe name="hosted-payment" #iframe class="responsive-iframe"
                frameBorder="0" [src]="urlSafe"></iframe> -->
        </div>
    </mat-step>

</mat-horizontal-stepper>
<div class="session-dialog">
    <kendo-dialog title="Confirm" [minWidth]="250" [width]="450" *ngIf="opened" (close)="close($event)"
        class="res-sm hide-close-icon">
        <h4>Do you want to close this payment page?</h4>
        <kendo-dialog-actions class="btn-container">
            <button class="btn-secondary-rect m-r-10" (click)="closePayment()">Yes</button>
            <button class="btn-primary-rect" (click)="stay()">Continue payment</button>
        </kendo-dialog-actions>
    </kendo-dialog>
</div>