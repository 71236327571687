<div *ngIf="!isPostDownload">
  <button *ngIf="ppReport && isPaymentButtonEnable" mat-button class="btn btn-primary-hra" [ngClass]="btn_class" #pdf
    (click)="onDownLoadFileForPatient()" disabled="{{ url === '' || isDisabled}}">
    <i class="icon-cent-file-export-files"></i><span>{{btnText}}</span>
  </button>

  <div *ngIf="!ppReport && !isPaymentButtonEnable">
    <div fxLayout="row wrap" fxLayoutAlign="center center" *ngIf="!linkFile">
      <button *ngIf="!isPatient" mat-button [ngClass]="btn_class"  class="btn-primary-hra"  #pdf (click)="onDownLoadFile()"
        disabled="{{ (content=== '' || guiId === '') || isDisabled}}">
        <i class="icon-cent-file-export-files"></i><span>{{btnText}}</span>
        <!-- {{"Save & Preview PDF"|translate}} -->
      </button>

      <button *ngIf="isPatient" mat-button [ngClass]="btn_class" #pdf  class="btn-primary-hra" (click)="onDownLoadFileForPatient()"
        disabled="{{ url === '' || isDisabled}}">
        <i class="icon-cent-file-export-files"></i><span>{{btnText}}</span>
        <!-- {{"Save & Preview PDF"|translate}} -->
      </button>

    </div>
    <div fxLayout="row wrap" fxLayoutAlign="center center" *ngIf="linkFile">

      <a class="download-file" (click)="onDownLoadFileForPatient()"
        disabled="{{ url === '' || isDisabled}}">{{btnText}}</a>
    </div>
  </div>
</div>
<div *ngIf="isPostDownload">
  <a class="download-file mail-download-file" (click)="downloadPostCallFiles()">{{btnText}}</a>
</div>