import { MediaMatcher } from '@angular/cdk/layout';
import { NavigationEnd, Router, Scroll } from '@angular/router';
import {
  ChangeDetectorRef,
  Component,
  ElementRef, Input,
  OnDestroy, ViewChild,
  OnInit, AfterViewInit, Renderer2, HostListener
} from '@angular/core';
import { MenuItems } from '../../shared/data/interface/menu-items/menu-items';


import { PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { TokenService } from 'src/app/data/service/token.service';
import { URLConstants } from 'src/app/core/constants/urlConstants';
import { applicationHttpClientCreator } from 'src/app/data/interface/IRequestOptions';
import { ToasterService } from 'src/app/data/service/toaster.service';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { MatSidenav } from '@angular/material/sidenav';
import { CommonURLs } from 'src/app/core/constants/commonURLs';
import { MailBoxService } from 'src/app/data/service/mail-box.service';
import { InBoxService } from 'src/app/data/service/inbox.service';
import { LoaderService } from 'src/app/data/service/loader.services';
import { PaymentService } from 'src/app/data/service/payment.service';

/** @title Responsive sidenav */
@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnDestroy {
  mobileQuery: MediaQueryList;
  isVisible = false;

  dir = 'ltr';
  dark = false;
  minisidebar = false;
  boxed = false;
  horizontal = false;

  green = false;
  blue = false;
  danger = false;
  showHide = false;
  url = '';
  sidebarOpened = false;
  status = false;
  disableLeftMenu = false;
  public showSearch = false;
  commonURL: any;
  public searchtext = false;
  public messagedropdown = true;
  public messagestype: any;
  public mailstype: any = [];
  selectedActionName: any;
  public filtertype: any;
  searchOption: any = 'GP';
  public config: PerfectScrollbarConfigInterface = {};
  // tslint:disable-next-line - Disables all
  private _mobileQueryListener: () => void;
  selCategory: string = 'Website feedback';
  userValue: string;
  opened: boolean;
  userId: string = '';
  showFooter=true;
  profileName: any;
	disablePointerEvents: boolean = false;
  navLinks: any[];
  @ViewChild('snav', { static: true }) snav: MatSidenav;
  roles: any;
  userRoles: any;
  @Input() isPatient: boolean = false;
  openRegUser: boolean = false;
  menu: any = [];
  healthCenterList: any;
  mobilePhone: string;
  iframeSignout: boolean = false;
  dailogOpen: boolean = false;

	constructor(
		public router: Router,
		changeDetectorRef: ChangeDetectorRef, private inBoxService: InBoxService,
		media: MediaMatcher,
		public mailboxServie: MailBoxService,
		public service: PaymentService,
		public inboxService: InBoxService,
		private el: ElementRef,
		public menuItems: MenuItems,public tokenService: TokenService,private toaster:ToasterService,
		private http: HttpClient, private translate: TranslateService,public loaderService : LoaderService,
		public r2 : Renderer2
	) {
		// this.mobileQuery = media.matchMedia('(min-width: 800px)');
		this.mobileQuery = media.matchMedia('(min-width: 1025px)');
		this._mobileQueryListener = () => changeDetectorRef.detectChanges();
		// tslint:disable-next-line: deprecation
		this.mobileQuery.addListener(this._mobileQueryListener);
		// this is for dark theme
		// const body = document.getElementsByTagName('body')[0];
		// body.classList.toggle('dark');
		this.dark = false;
		this.commonURL = CommonURLs;

		//disable the cursor event property when the interstitial popup is active
		el.nativeElement.addEventListener('mousemove', () => {
			if((document.querySelector('.interstitial-container')as HTMLElement)){
					if((document.querySelector('.interstitial-container')as HTMLElement)?.style?.display? true : false){
							this.disablePointerEvents = false;
					}else{
							this.disablePointerEvents = true;
					}
			}
	})
}

	public getScreenWidth: any;
  	public getScreenHeight: any;

  	@HostListener('window:resize', ['$event'])
		onWindowResize() {
		this.getScreenWidth = window.innerWidth;
		this.getScreenHeight = window.innerHeight;
  	}
	@HostListener('window:popstate', ['$event'])
  	onPopState(event:any) {
    console.log('Back button pressed');
	if(event){
		this.mailboxServie.selectedMailBoxType = '';
	}
  }

  handleUrlChange(url: string): void {
    // Perform actions based on the URL
    if (url.includes('/reach')) {
		this.showFooter = false;
      // Do something specific for '/some-path'
    } else {
		this.showFooter = true;
      // Handle other URLs
    }
  }


	ngOnDestroy(): void {
		// tslint:disable-next-line: deprecation
		this.mobileQuery.removeListener(this._mobileQueryListener);
	}
	toggleSideNav() {
		if (this.tokenService.hideSideBarMenu == true) {
			if (this.snav) {
				if (this.mobileQuery.matches) {
          			console.log('mobile query matches');
					this.snav.toggle();
					this.snav.mode = 'side';
				} else {
          			console.log('mobile query un matches');
					this.snav.toggle();
					this.snav.mode = 'over';
				}
			} else {
				this.sidebarOpened = true;
				console.log('mobile query matches for side bar');
			}
		}
	}

	@ViewChild('pageContent') 'pageContent' : ElementRef;
	ngOnInit() {
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
			  // Logic based on URL change
			  const currentUrl = event.url; // Get the current URL
			  this.handleUrlChange(currentUrl); // Call a function based on the URL
			}
		  });
		  if(this.router.url.includes('/reach')){
			this.showFooter = false;
		  }
		this.hideinbox();
		this.getScreenWidth = window.innerWidth;
      	this.getScreenHeight = window.innerHeight;
		this.userId = this.tokenService.userId();
		if(this.userId!=null){
		this.inBoxService.getHealthCenterDetails(this.userId).subscribe(data => {
			this.healthCenterList = data;
		});
    this.profileName = this.tokenService.userProfile();

	this.getCount();
	this.mailInboxCount();
	}
	// this.reloadCurrentRoute();

	this.roles= this.tokenService.getUserRoles();
	this.userRoles=this.roles.split(',');
	console.log("this.roles",this.roles);
	this.gettingRoutes();
	let patientid = this.userRoles.find((x: any) => x.toLowerCase() == 'patient');
	if (patientid != undefined) {
		this.isPatient = true;
	}
	else {
		this.isPatient = false;

		let openRegRole = this.userRoles.findIndex((x: any) => x.toLowerCase() == 'openreg');
		if (openRegRole != -1) {
			this.openRegUser = true;
		}
		else {
			this.openRegUser = false;
		}
	}
		this.loaderService.scrollBs.subscribe((data:any)=>{
			console.log('loaderService.scrollBs',data);
			var main = document.querySelector('.main-container');
			this.r2.removeClass(main, 'page-has-fixed-bottom-bar');
			switch (data) {
				case 'fixed_bottom_whole_page':
					//let main = document.querySelector('.main-container');
					this.r2.addClass(main, 'page-has-fixed-bottom-bar');
					break;
				case 'remove_fixed_bottom_whole_page':
					//let main = document.querySelector('.main-container');
					this.r2.removeClass(main, 'page-has-fixed-bottom-bar');
					break;
				case 'hide_scroll':
					let pageWrapper = document.querySelector('.page-wrapper');
					this.r2.addClass(pageWrapper, 'hide-vertical-scroll');
					break;
				case 'hide_scroll_without_bottom_padding':
					let pageWrapper2 = document.querySelector('.page-wrapper');
					this.r2.addClass(pageWrapper2, 'hide-vertical-scroll-without-bottom-padding');
					break;
				case 'remove_hide_scroll':
					let pageWrapper3 = document.querySelector('.page-wrapper');
					this.r2.removeClass(pageWrapper3, 'hide-vertical-scroll');
					this.r2.removeClass(pageWrapper3, 'hide-vertical-scroll-without-bottom-padding');
					break;
				case 'body_scroll_to_top':
					setTimeout(() => {
						let body = document.querySelector('body');
						if(body){
							body.scrollTo(0,0);
						}
					},3);
					break;
				case 'header_component_scroll_to_top':
					setTimeout(() => {
						let header_comp = document.querySelector('.header-component');
						if(header_comp){
							header_comp.scrollTo(0,0);
						}
					},3);
					break;
				case 'html_scroll_to_top':
					setTimeout(() => {
						let html = document.querySelector('html');
						if(html){
							html.scrollTo(0,0);
						}
					},3);
					break;
				default:
					setTimeout(() => {
						let pageWrapper = document.querySelector('.page-wrapper');
						if(pageWrapper){
							pageWrapper.scrollTo(0,0);
						}
					},3);
					break;
			}
		})
	// this.toggleSideNav();
		//const body = document.getElementsByTagName('body')[0];
		// body.classList.add('dark');
		if (this.userRoles.find((x: any) => x.toLowerCase() == 'patient')) {
			this.navLinks = [
				// { path: '/pages/navigate-routes/Compose', label: 'Compose' },
				{ path: '/pages/navigate-routes/Inbox', label: 'Inbox', count: 0 },
				{ path: '/pages/navigate-routes/Drafts', label: 'Draft', count: 0 },
				{ path: '/pages/navigate-routes/Sent', label: 'Sent Messages' },
				{ path: '/pages/navigate-routes/Archive', label: 'Archive' },
				{ path: '/pages/navigate-routes/Groups', label: 'Group Messages', count: 0 },
				{ path: '/pages/navigate-routes/Settings', label: 'Settings'},
			];
		}
		else {
			this.navLinks = [
				// { path: '/pages/navigate-routes/Compose', label: 'Compose' },
				{ path: '/pages/navigate-routes/Inbox', label: 'Inbox', count: 0 },
				{ path: '/pages/navigate-routes/Drafts', label: 'Draft', count: 0 },
				{ path: '/pages/navigate-routes/Sent', label: 'Sent Messages' },
				{ path: '/pages/navigate-routes/Archive', label: 'Archive' },
				// { path: '/pages/navigate-routes/Groups', label: 'Group Messages',count:0 },
				{ path: '/pages/navigate-routes/Settings', label: 'Settings'},
			];
		}

	}

	hideinbox(): void {
		this.mailboxServie.hideMailBox();
	}

	gettingRoutes() {
		// applicationHttpClientCreator(this.http).Get(`${URLConstants.GetUserAndMenu}${this.userId}`).subscribe(data => {
    // 	console.log(data);
    // 	// this.menuItems=data;
    // 	this.menu = data;
    // })
	//Changed to service for cache
  if(this.userId) {
    this.inBoxService.getUserAndMenu(this.userId).subscribe((res) => {
      this.menu = res;
      });
  }

	}
	openFeedBackForm(){
		this.userValue="";
		this.selCategory="Website feedback";
		this.opened=true;
	  }
	  submitFeedBackForm(){
	  this.userValue = this.userValue.replace("/'/g", "");
	  this.userValue = this.userValue.replace("/>/g", "");
	  this.userValue = this.userValue.replace("/</g", "");
	  this.userValue = this.userValue.replace('/"/g', '');
	  this.userValue = this.userValue.replace('/:/g', '');
	   let xmlData= "<?xml version=\'1.0\' encoding=\'UTF-8\'?><FEEDBACK><CATEGORY>" + this.selCategory + "</CATEGORY><TEXT><![CDATA[" + this.userValue + "]]></TEXT></FEEDBACK>";
	  applicationHttpClientCreator(this.http).Post(`${URLConstants.saveUserSiteFeedback}`, {
		Feedback:xmlData,
		URL :window.location.href,
		UserID:this.userId,
		Email:this.profileName?.Email,
		MobileNumber:  this.mobilePhone,
		ScreenWidth: this.getScreenWidth,
		ScreenHeight: this.getScreenHeight
	  }).subscribe((data: any) => {
		this.toaster.success(this.translate.instant("FeedBack Sent Successfully"));
		 this.opened=false;
		 this.mobilePhone= '';
	  })
	}

	clickEvent(): void {
		this.status = !this.status;
	}
	onSearchClick(val:any){
		console.log("&&&&&&&&&&",val,this.searchOption);
		if(this.searchOption=='GP'){
			window.location.href = window.location.origin + "/pages/search-gp-hc" + '?s=B&x=G&wd=' + val + '&sm=providersearch';

		}else if(this.searchOption=='HealthCentre'){
			window.location.href = window.location.origin + "/pages/search-gp-hc" + '?s=B&x=P&wd=' + val;
		}
	}

	gotoSearch(){
		this.router.navigate(['/pages/search-gp-hc'],{
			queryParams: { s: 'B',x: 'A',wd:'' },
		});
	}

	darkClick() {
		// const body = document.getElementsByTagName('body')[0];
		// this.dark = this.dark;
		const body = document.getElementsByTagName('body')[0];
		body?.classList.toggle('dark');
		// if (this.dark)
		// else
		// 	body.classList.remove('dark');
		// this.dark = this.dark;
		// body.classList.toggle('dark');
		// this.dark = this.dark;
	}
	options = ['GP', 'Health Centre']

	navToRoute() {
		// this.userId = this.tokenService.userId();
		if (this.healthCenterList.length >= 0 || (!this.isPatient && !this.openRegUser)) {
			this.router.navigate(['/dashboards/dashboard']);
		}
		else {
			this.router.navigate(['/pages/home']);
		}
	}

	home(): void {
		this.messagedropdown = true;
		this.searchtext = false;
		this.mailboxServie.selectedMailBoxType = 'Inbox';
		this.mailboxServie.isShowSearchBox = false;
		this.messagestype = '';
	}

	getCount(): void {
		this.inboxService.getCount(this.userId).subscribe((count: any[]) => {
		this.healthCenterList = count[0]
		this.mailboxServie.mailstype[1].count = count[1];
		this.navLinks[1].count=count[1];
		if (this.isPatient) {
			this.mailboxServie.mailstype[4].count = count[2];
			this.navLinks[4].count = count[2];
		}
		})
	}

	  // search value

    Searchkey(searchkeyvalue: any): void {
        console.log(searchkeyvalue, "searchvalue")
        this.mailboxServie.sendMessage(searchkeyvalue);
    }



  navigatetoInbox(type: any): void {
    if (type) {
    this.mailboxServie.showHealthCenter = false;
    this.mailboxServie.selectedMailBoxType = type;
    }
}
mailInboxCount(): void {
this.inboxService.inboxCount(this.userId).subscribe((count: any) => {
this.mailboxServie.mailstype[0].count = count;
this.navLinks[0].count=this.mailboxServie.mailstype[0].count;
});
}

reloadCurrentRoute() {
let currentUrl = this.router.url;
this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
  this.router.navigate([currentUrl]);
  console.log(currentUrl);
});
}

setSelectedMailType(mailType: any): void {
this.mailboxServie.showHealthCenter = false;
this.mailboxServie.isShowSearchBox = true;
if (mailType !== 'undefined') {
this.searchtext = true
this.messagedropdown = false;
}
this.selectedActionName = '';
this.filtertype = mailType
this.mailboxServie.selectedMailBoxType = mailType;
}
}









