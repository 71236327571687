<div class="home-page">
  <div class="header">
    <div fxLayout="row wrap">
      <div fxFlex.gt-sm="30" fxFlex="20">
        <img src="assets/images/MMH-mobile-logo.png" alt="Manage My Health™">
      </div>
      <div fxFlex.gt-sm="70" fxFlex="80" class="btn-align text-right">
        <!-- <a mat-raised-button class="m-l-20 m-r-20"
      [routerLink]=" ['/authentication/activate-account']">Activate Account</a> -->
      <!-- <a mat-button class="m-r-20 btn-primary-rect" [routerLink]="['/authentication/easy-booking']">Easy Booking</a> -->
      <!-- Commented For Sprint 6 EasyBooking Hiding 
      Note: When Enable class="btn-align-3 text-right" on Class -->
        <a mat-button class="m-r-20 btn-primary-rect" [routerLink]="['/authentication/login']">Login</a>
        <!-- <a mat-button class="m-r-20 home-button" [routerLink]="['/authentication/register']">Register</a> -->
      </div>
    </div>
  </div>
  <div class="content-panel">
    <mat-card>
      <mat-card-content>
        <h4 class="text-center">Freedom to Manage your Health and Wellness</h4>
        <div id="regSection" class="text-center">
          <button mat-raised-button class="btn btn-primary-pill m-t-25"
            [routerLink]="['/authentication/activate-account']">Activate Account</button>
          <p class="m-t-30">Manage My Health™ is available to you right now. All you need to do is ask your medical
            centre.</p>
          <p>The activation process is quick and easy.</p>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>