<div class="content-panel">
    <mat-card>
        <mat-card-content>
            <h3>+ Manage My Health™ is available to you right now</h3>
            <p>All you need to do is ask your medical centre. The registration process is quick and easy</p>
            <h3>
                How it works
            </h3>
            <p>
                + Registering for Manage My Health™ is simple, just ask the receptionist at your medical centre
            </p>
            <p>
                + You will need to provide an email address and proof of identity
            </p>
            <p>
                + The practice will provide you with an activation code and instructions on how to complete your registration online
            </p>
            <h3>
                Protecting your information
            </h3>
            <p>
                Manage MyHealth™ is hosted in a secure environment in New Zealand. Similar to internet banking, Manage My Health™ uses Secure Socket Layer (SSL) technology to ensure your information is completely safe and secure. We also rely on you to keep your password a secret and to take all reasonable measures to protect your information.
            </p>
            <h3>
                So why not take control of your health and join today?
            </h3>
        </mat-card-content>
    </mat-card>
</div>
