import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/data/service/app.service';
import { TimeOutService } from 'src/app/data/service/timeOut.service';
import { TokenService } from 'src/app/data/service/token.service';
import { InBoxService } from 'src/app/data/service/inbox.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  userId: any;
  healthCenterList:any =[];

  constructor(private router: Router, private tokenService: TokenService,
    private appService: AppService, private inBoxService: InBoxService,
    private timedOutService: TimeOutService) { }

  ngOnInit(): void {
    this.userId = this.tokenService.userId()
    if (this.tokenService.userId() && this.tokenService.token()) {

      this.getHealthCenterDetails();
    }
    else {
      // this.appService.setUserLoggedIn(false, 0);
      // this.timedOutService.stopTimer();
    }
  }
  getHealthCenterDetails() {
    // this.userId = this.tokenService.userId();
    this.inBoxService.getHealthCenterDetails(this.userId).subscribe(data => {
      this.healthCenterList = data;
      if (this.healthCenterList.length >= 0) {
        this.router.navigate(['/dashboards/dashboard']);
      }
      else {
        this.router.navigate(['/pages/home']);
      }

    })
  }

}
