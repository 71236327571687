import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { base64ToFile, Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { first } from 'rxjs/operators';
import { AlertType } from 'src/app/core/constants/app-enums';
import { URLConstants } from 'src/app/core/constants/urlConstants';
import {IAlertDialog, IConfirmDialog } from 'src/app/data/interface/Idialog';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from 'src/app/shared/component/alert-dialog/alert-dialog.component';
import { ConfirmDialogComponent } from 'src/app/shared/component/confirm-dialog/confirm-dialog.component';
import { applicationHttpClientCreator } from 'src/app/data/interface/IRequestOptions';
import { ToasterService } from 'src/app/data/service/toaster.service';
import { TokenService } from 'src/app/data/service/token.service';
import { FileToUpload } from '../file-upload/file-upload.component';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent implements OnInit {

  // @Input() imageObject: any; // 0 is single, 1 is multiple
  @Input() maxSize = 1048576;
  @Input() fileTypes = 'gif,jpg,png,jpeg,tif,pdf,doc,docx';

  @Output() uploadFile = new EventEmitter();
  /* Image crop parameters*/
  imageChangedEvent: any = '';
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  file = new FileToUpload();
  isValid=false;
  validateMsg='';
  public confirmDialog: IConfirmDialog;
  public alertDialog: IAlertDialog;

  showchangepic: boolean;
  selectedPhoto: any;
  /* End */
  constructor(private http: HttpClient,
    public toaster: ToasterService, private tokenService: TokenService, private router: Router,public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  resetAll(): void {
    this.file = new FileToUpload();
    this.imageChangedEvent = null;
  }

  /* Image crop functions */
  // fileChangeEvent(event: any): void {
  //   this.imageChangedEvent = event;
  // }

  imageCropped = (event: ImageCroppedEvent) => {
    this.croppedImage = event.base64;
    this.file.fileAsBase64 = event.base64 ? event.base64 : '';
    this.uploadFile.emit(this.file);
  }

  imageLoaded = () => {
    this.showCropper = true;
    console.log('Image loaded');
  }

  cropperReady = (sourceImageDimensions: Dimensions) => {
    console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed = () => {
    console.log('Load failed');
  }

  rotateLeft = () => {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight = () => {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  private flipAfterRotate = () => {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }


  flipHorizontal = () => {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  flipVertical = () => {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  resetImage = () => {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut = () => {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn = () => {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  toggleContainWithinAspectRatio = () => {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation = () => {
    this.transform = {
      ...this.transform,
      rotate: this.rotation
    };
  }

  onSelectPhoto = (event:any) => {
    console.log(event);
    console.log(event.target.files[0].name);

   const fileName=event.target.files[0].name;
      if (fileName !== '') {
        let extenstion = fileName.substring(fileName.indexOf('.') + 1, fileName.length)
        if (this.fileTypes.toLowerCase().indexOf(extenstion.toLowerCase()) !== -1) {
          this.isValid = true;
          if (event.target.files.length > 0) {
            const file = event.target.files[0];
            this.selectedPhoto = file;
            this.file.fileName = file.name;
            this.file.fileSize = file.size;
            this.file.fileType = file.type;
            this.imageChangedEvent = event;
          }
          return true;
        } else {
          this.validateMsg = fileName + '.' + 'Cannot accept files of this type';
          this.fileTypeDialog();
          this.isValid = false;
          return false;
        }
      } else {
        this.validateMsg = 'File name should not be empty';
        this.isValid = false;
        return false;
      }
    
    
   
  }




  fileTypeDialog() {
     this.alertDialog = {
      title: 'Note',
      message: this.validateMsg,
      alertType: AlertType.None,
      okButtonText: 'OK'
    };
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: this.alertDialog
    });
    dialogRef.afterClosed().subscribe((result: any) => {
    });
  
  }

  









  // uploadPhoto = () => {
  //   applicationHttpClientCreator(this.http).Post(URLConstants.uploadProfilePhoto, {
  //     ProfileId: this.imageObject.profileId,
  //     UserId: this.imageObject.userId,
  //     file: {
  //       fileName: this.file.fileName,
  //       fileSize: this.file.fileSize.toString(),
  //       fileType: this.file.fileType,
  //       fileAsBase64: this.file.fileAsBase64,
  //       fileAsByteArray: this.file.fileAsByteArray
  //     }
  //   }).pipe(first())
  //     .subscribe((data: any) => {
  //       //this.savePhoto(data);
  //       this.showchangepic = false;
  //     }, (err) => {
  //       this.toaster.error(err.error.Message);
  //     });
  // }
  /* End */


}
