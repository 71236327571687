
<div class="main-container header-component" [dir]="dir" [ngClass]="{'minisidebar': minisidebar, 'boxed': boxed, 'danger': danger, 'blue': blue, 'green': green, 'horizontal' : horizontal }">

    <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 0 : 0" [ngClass]="{'minisidebar': minisidebar}">
     
        <mat-sidenav-content class="page-wrapper page-empty-wrapper" [ngClass]="status ? 'minitoggle' : ''" style="border-radius: unset !important;">
            <div class="corner-left"></div>
            <div class="corner-right"></div>
            <app-breadcrumb></app-breadcrumb>
            <div class="page-content">
                <router-outlet>
                    <app-spinner></app-spinner>
                </router-outlet>

                <!-- <div class="btn-feedback">
                    <a (click)="openFeedBackForm()">feedback</a>
                </div> -->

            </div>
        </mat-sidenav-content>
     
    </mat-sidenav-container>
    <!-- <div class="feedback-dialog" *ngIf="opened==true">
        <kendo-dialog title="Please enter your feedback" *ngIf="opened==true" (close)="opened=false" [minWidth]="250" [width]="450" class="res-sm hide-close-icon"  >
            <p>
                This form should only be used to discuss matters of a technical nature. If you have any questions relating to the use of this website or experience any technical issues, please contact us using the form below.
            </p>
            <p>If your question or enquiry is related to your health or your health records, please contact your doctor or medical centre directly.</p>
            <mat-form-field appearance="outline" class="has-success">
                <mat-label>Select Category</mat-label>
                <mat-select [(ngModel)]="selCategory">
                    <mat-option value="Website feedback">Website feedback</mat-option>
                    <mat-option value="Suggestions">Suggestions</mat-option>
                </mat-select>
                <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
            </mat-form-field>
            <mat-form-field appearance="outline" class="has-success">
                <mat-label>Feedback</mat-label>
                <textarea [(ngModel)]="userValue" matInput></textarea>
            </mat-form-field>

            <kendo-dialog-actions class="btn-group-right">               
                <button mat-button class="btn-secondary-rect" (click)="opened=false">Close</button>
                <button mat-button class="btn-primary-rect" [disabled]="userValue==''" (click)="submitFeedBackForm()">Send Feedback</button>      
            </kendo-dialog-actions>
        </kendo-dialog>
    </div> -->

</div>