import { Value } from './../../pages-practice/sp-goal-analysis/sp-goal-analysis.component';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MailBoxService } from 'src/app/data/service/mail-box.service';
import { InBoxService } from 'src/app/data/service/inbox.service';
import { MatDialog } from '@angular/material/dialog';
import { ToasterService } from 'src/app/data/service/toaster.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AttachfileDialogComponent } from '../attachfile-dialog/attachfile-dialog.component';
import { TokenService } from 'src/app/data/service/token.service';
import * as moment from 'moment';
import { AlertType } from 'src/app/core/constants/app-enums';
import { AlertDialogComponent } from 'src/app/shared/component/alert-dialog/alert-dialog.component';
import { IAlertDialog } from 'src/app/data/interface/Idialog';
import { PerfectScrollbarComponent, PerfectScrollbarConfigInterface,PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};

@Component({
  selector: 'app-archivedetail',
  templateUrl: './archivedetail.component.html',
  styleUrls: ['./archivedetail.component.scss']
})
export class ArchivedetailComponent implements OnInit {
  public selectedMessagesDetails: any = [];
  detailForm: FormGroup;
  public replydetail: any
  public Subject: any
  public bodymessage: any
  public messageId: string;
  public userId: any;
  public page: number = 0;
  public pagesize: number = 10;
  public files: any = [];
  public addedFiles: any = [];
  public filesarray: any = [];
  public generateXML: any;
  public filelengthError: boolean = false;
  xmltoJSON: any;
  public showMailBox:boolean = false;
  public showMailMsg:boolean = false;
  public config: PerfectScrollbarConfigInterface = {};
  imgSrc: string = "../../../../assets/images/Group 5008.svg";
  @Output() onCloseClick = new EventEmitter();
  filesData: any = [{}];
  alertDialog:IAlertDialog;
  constructor(public mailboxService: MailBoxService, public inBoxService: InBoxService, private _snackBar: MatSnackBar,
    public dialog: MatDialog, private toaster: ToasterService, private fb: FormBuilder, private translate: TranslateService,
    public router: Router, public tokenService: TokenService) { }

  ngOnInit(): void {
    this.userId = this.tokenService.userId();
    // Detail Form
    this.detailForm = this.fb.group({
      Subject: ['', Validators.required],
      bodymessage: ['', Validators.required]
    })
    this.getSelectedArchiveMailDetails();
  }

  get f() { return this.detailForm.controls }

  closeSelectedMail() {
    this.onCloseClick.emit();
  }


  getSelectedArchiveMailDetails() {
    console.log(this.mailboxService.messageId)
    this.showMailMsg=true;
    // this.mailboxService.messageId = "97615";
    this.inBoxService.getSelectedsentMailDetails(this.userId, false, true, 0, this.mailboxService.messageId).subscribe((data: any) => {
      this.selectedMessagesDetails = data;
      console.log(data);
      this.detailForm.patchValue({
        Subject: this.selectedMessagesDetails[0].Subject,
        bodymessage: this.selectedMessagesDetails[0].MessageBody
      })
      this.xmltoJSON = JSON.parse(this.selectedMessagesDetails[0].attachment);
      if (this.xmltoJSON.ROOT != "") {
        let singleAttachment: any = [];
        singleAttachment = this.xmltoJSON.ROOT.Inbox_Attachments;
        this.filesData = singleAttachment;
        console.log(this.filesData);
      }
    })
  }


  backToArchive = () => {
    this.mailboxService.selectedMailBoxType = 'Archive'
  }

  // Attach Files Dialog popup and getting files from that dialog
  attachFileDialog() {
    const dialogRef = this.dialog.open(AttachfileDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      this.files = result;
      this.addedFiles.push(...this.files)
      if (this.addedFiles.length > 3) {
        this.filelengthError = true;
      } else { this.filelengthError = false }
    });
  }

  // Remove selected files
  remove(i: number) {
    this.addedFiles.splice(i, 1);
    if (this.addedFiles.length == 3) {
      this.filelengthError = false;
    }
  }

  //Generating or creating XML request
  constructAttachFiles(strFiles: string) {
    this.generateXML = ("<ROOT>") + ""
    let file = this.filesarray.split(";");
    for (var index = 0; index < file.length - 1; index++) {
      let filedetails: string[];
      filedetails = file[index].split(",");
      if (filedetails[3] === "1") {
        this.generateXML += ("<Attachment>") + "" +
          ("<DestName>" + this.setCdata(filedetails[0]) + "</DestName>") + "" +
          ("<SourceName>" + this.setCdata(filedetails[1]) + "</SourceName>") + "" +
          ("<Size>" + filedetails[2] + "</Size>") + "" +
          ("</Attachment>") + ""
      }
    }
    this.generateXML += ("</ROOT>");
    return this.generateXML;
  }

  // CDATA Format
  setCdata(strFiles: string) {
    return "<![CDATA[" + strFiles + "]]>";
  }

  // Reply a message
  composeNewMessage(attachmentXML: string) {
    var sentMessageDeatils = {
      "FromUserId": this.selectedMessagesDetails[0].ToUserId,
      "ToUserId": this.selectedMessagesDetails[0].FromUserId,
      "MessageTypeID": this.selectedMessagesDetails[0].MessageTypeID,
      "Subject": this.detailForm.value.Subject,
      "MessageBody": this.detailForm.value.bodymessage,
      "IsRead": false,
      "IsSent": false,
      "IsTrash": false,
      "MessageSentOn": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      "MessageReceivedOn": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      "AttachmentXML": attachmentXML ? attachmentXML : "<ROOT><Attachment></Attachment></ROOT>",
      "isRequestRepeatPresc": true,
      "isBillable": true,
      "RRPXML": "RRpxml",
      "Cost": 0,
      "isReply": true,
      "Status": "A",
      "PracticeAdmin": this.selectedMessagesDetails[0].PracticeID,
      "PaymentType": "H",
      "ServiceRefNo": this.selectedMessagesDetails[0].ServiceRefNo ? this.selectedMessagesDetails[0].ServiceRefNo : "",
      "LocationId": this.selectedMessagesDetails[0].LocationId,
      "ParentID": 0,
      "InboxDraftMessageID": 0,
      "MessageCategoryID": this.selectedMessagesDetails[0].MessageCategoryID == 5 || this.selectedMessagesDetails[0].MessageCategoryID == 3 ? 2 : this.selectedMessagesDetails[0].MessageCategoryID,
      "PMSServiceCode": "C",
      "OnlineTransactionID": "O",
      "SentBy": "00000000-0000-0000-0000-000000000000"
    }

    console.log(sentMessageDeatils)
    this.inBoxService.composeNewMail(sentMessageDeatils)
      .subscribe(res => {
        if (res) {
          this.toaster.success(
          this.translate.instant('Message sent successfully'));
          this.addedFiles = [];
          this.showMailMsg=false;
          // this.mailboxService.selectedMailBoxType = 'Inbox';
          this.reloadCurrentRoute();
          
        }
      })
  }

  //  Reply Save as draft
  saveAsDraft(attchmentXML: string) {
    this.alertDialog = {
      title: 'Info',
      message: this.translate.instant("MessageSavedAsDraftOnly"),
      alertType: AlertType.None,
      okButtonText: this.translate.instant('OK')
    };
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: this.alertDialog
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result == true) {
    const draftObject = {
      InboxDraftMessageID: this.selectedMessagesDetails[0].InboxMessageId,
      FromUserID: this.userId,
      ToUserID: this.selectedMessagesDetails[0].ToUserId,
      MessageTypeID: this.selectedMessagesDetails[0].MessageTypeID,
      Subject: this.detailForm.value.Subject,
      MessageBody: this.detailForm.value.bodymessage,
      IsCompose: true,
      IsReply: false,
      ServiceRefNo: this.selectedMessagesDetails[0].ServiceRefNo,
      PracticeManagerId: this.selectedMessagesDetails[0].PracticeID,
      LocationId: this.selectedMessagesDetails[0].LocationId,
      InboxMessageID: this.selectedMessagesDetails[0].InboxMessageId,
      RoleID: "00000000-0000-0000-0000-000000000000",
      attachments: attchmentXML ? attchmentXML : "",
      IsSent: false
    }
    this.inBoxService.SaveDraftMessage(draftObject).subscribe(res => {
      console.log(res)
      if (res) {
        this.toaster.success(
          this.translate.instant('draft saved successfully')
        );
        this.detailForm.reset();
        this.addedFiles = [];
        this.mailboxService.selectedMailBoxType = 'Drafts';
        this.showMailBox = true;
        this.showMailMsg = false;
        this.reloadCurrentRoute();
      }
    })
  }
})
  }

  // Compose a new mail api call
  sendMesssage(Value: any) {
    if (this.addedFiles && this.addedFiles.length > 0) {
      const frmData = new FormData();
      for (var i = 0; i < this.addedFiles.length; i++) {
        frmData.append("fileUpload", this.addedFiles[i]);
      }
      this.inBoxService.uploadFiles(frmData).subscribe((result: any) => {
        console.log("uploadstatus", result)
        this.filesarray = result
        console.log("constructdata")
        let attachmentXML = this.constructAttachFiles(this.filesarray);
        if (Value !== 'Drafts') {
          this.composeNewMessage(btoa(attachmentXML)); // btoa (encode files data)
          this.mailboxService.selectedMailBoxType = 'Archive';
          this.showMailBox = true;
          this.showMailMsg = false;
        } else {
          this.saveAsDraft(btoa(attachmentXML));
          this.mailboxService.selectedMailBoxType = 'Drafts';
        }
      })
    }
    else {
      if (Value !== 'Drafts') {
        this.composeNewMessage("");
        //this.mailboxService.selectedMailBoxType = value;
        this.showMailBox = true;
        this.showMailMsg = false;
      }
      else {
        this.saveAsDraft("");
      }
    }
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
        console.log(currentUrl);
    });
  }

}





