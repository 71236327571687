import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { URLConstants } from '../../core/constants/urlConstants';
import { applicationHttpClientCreator } from 'src/app/data/interface/IRequestOptions';
@Injectable()
export class AuthServer {
  constructor(private _httpService: HttpClient) {}

  public GetToken(params: any) {
    return applicationHttpClientCreator(this._httpService).Post(`${URLConstants.GetWebToken}`, { });    
  }
}
