import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MailBoxService } from 'src/app/data/service/mail-box.service';
import { InBoxService } from 'src/app/data/service/inbox.service';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TokenService } from 'src/app/data/service/token.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MovetoinboxDialogComponent } from '../movetoinbox-dialog/movetoinbox-dialog.component';
import { ToasterService } from 'src/app/data/service/toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { URLConstants } from 'src/app/core/constants/urlConstants';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { CommonConstants } from 'src/app/core/constants/commonConstants';
import { DashboardServiceData } from 'src/app/core/constants/app-enums';
import { CommonService } from 'src/app/data/service/common.service';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};
@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit {
  imgSrc3: string = "../../../../assets/images/Group 5026.svg";
  public groupmessage:boolean =false;
  public info = true;
  // public refresh = true;
  public buttonCount = 5;
  public type = 'numeric';
  public pageSizes = true;
  public previousNext = true;
  public position = 'bottom';
  public gridView: any = [];
  public pageSize = 10;
  public skip = 0;
  private items: any[] = [];
  public allowUnsort = true;
  public multiple = false;
  public GroupMessages: any = [];
  openSidebar: boolean = true;
  searchText: any;
  subscription: Subscription;
  public searchkeyvalue: any;
  selected = 'compose';
  public openedArchiveDialouge: boolean = false;
  selectedMailType: any;
  public groupMessages: any = [];
  public userId: any;
  messageId: any;
  page: number = 1;
  pagesize: number = 20;
  checkboxForm: FormGroup;
  public profileObject:any=[];
  selectedMessageDetails:boolean=false;
  public sentselectedMessagesDetails:any=[];
  public selectedRowNumber: number;
  public getRefGroupMessages: any = [];
  public maxCount: number;
  public filterGroupData: any = [];
  public selectedRowId: number;
  public refGroupMessage: any = [];
  public disable: boolean;
  public prevrefGroupMessage: any = [];
  public prevgetRefGroupMessages: any = [];
  public prevdisable: boolean;
  public defaultMessageId: number;
  public mailGroupMessage: any = {};
  public nextGroupMessage: any = {};
  public filesData: any = [];
  searchTexts:any;
  public config: PerfectScrollbarConfigInterface = {};
  public SelectedMail:any;
  selectedcheckbox: any = [];
  pageNumber: number=0;
  public nomessages:any;
  public title:any = "thank you every one";
  isLoading = true;
  xmltoJSON: any;
  postObject: { FileName: string; };
  url= URLConstants.downloadFile_Post;
  selectedId: any;
  public displayNewWindow: boolean = false;

  constructor(
    private router: Router,
    public mailboxServie: MailBoxService,
    public deletedialog: MatDialog,
    private _snackBar: MatSnackBar,
    public inBoxService: InBoxService,
    public tokenService:TokenService,
    public sanitizer: DomSanitizer,
    public fb: FormBuilder,
    public http:HttpClient, public toaster:ToasterService, public translate:TranslateService,
    public dialog:MatDialog,
    private commonService: CommonService) {
    this.subscription = this.mailboxServie.onMessage().subscribe(message => {
      if (message) {
        this.searchkeyvalue = message.text
        this.searchText = this.searchkeyvalue
      } else {
        // clear messages when empty message received
        this.searchkeyvalue = "";
        this.searchText = '';
      }
      this.groupmessage = false;
      this.SelectedMail = false;
      this.refresh();
    });
  }
  ngOnInit(): void {
    this.userId = this.tokenService.userId();
    this.profileObject = this.tokenService.userProfile();
    this.searchText = '';
    this.selectedId = this.mailboxServie.getParamsData();
    this.getGroupSentMessages();
    this.mailboxServie.groupMessages =[];

    this.checkboxForm = this.fb.group({
      checkboxChecked: [''],
      selectedCheckbox: ['']
    })
    this.SelectedMail = false;

    
  }
  
  activationCode(): void {
    this.router.navigate(['/myaccount/profile']);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  // opensettings()
  // {
  //   this.router.navigate(['/pages/navigate-routes/Settings']);
  // }

  // hideinbox():void {

  //   if( this.mailboxServie.selectedMailBoxType.includes("Compose") ||
  //   this.mailboxServie.selectedMailBoxType.includes("Inbox") ||
  //   this.mailboxServie.selectedMailBoxType.includes("Drafts") ||
  //   this.mailboxServie.selectedMailBoxType.includes("Sent") ||
  //   this.mailboxServie.selectedMailBoxType.includes("Archive") ||
  //   this.mailboxServie.selectedMailBoxType.includes("Groups") ||
  //   this.mailboxServie.selectedMailBoxType.includes("Detail") ||
  //   this.mailboxServie.selectedMailBoxType.includes("DraftDetail")) 
  //   {
  //     this.mailboxServie.selectedMailBoxType = '';
  //   }
  // }

  getGroupSentMessages() {
    this.nomessages=false;
    this.isLoading = true;
    this.inBoxService.GetGroupMessages(this.userId, this.searchText, this.selectedId).subscribe((data: any) => {
    //   for(var i=0; i<data.length ; i++){
    //     let datediff=this.calculateDateDiff(data[i].InsertOn);
    //     let diffResult= datediff[0] != 0 ? datediff[0]+" year" : datediff[1] != 0 ? datediff[1]+ " month" : datediff[2] != 0 ? datediff[2]+ " day" : datediff[3] != 0 ? datediff[3] + " hour" : datediff[4] != 0 ? datediff[4] + " min" : datediff[5] + " sec";
    //     data[i].InsertOn= diffResult;
    // }
    this.isLoading = false;
    this.mailboxServie.groupMessages = data;
      console.log(this.mailboxServie.groupMessages)
      this.GroupMessages= this.mailboxServie.groupMessages;
      if (this.mailboxServie.groupMessages.length==0){
        this.nomessages=true;
      }
      else {
        if (this.selectedId != undefined && this.selectedId != null && this.selectedId != '') {
          let index = this.mailboxServie.groupMessages.findIndex((x: any) => x.ID == this.selectedId);
          if (index != -1) {
            this.setSelectedMailType(this.selectedId, index);
          }
        }
      }
    }, (err: any) => {
      this.isLoading = false;
    })

    // if (this.mailboxServie.groupMessages.length==0){
    //   this.nomessages=true;
    // }
  }
  calculateDateDiff =(dateOfReceived: any) => {
    
    var startDate = new Date(dateOfReceived);
    // user not pass endingDate then set current date as end date.
    let endingDate = new Date();
    
    let endDate = new Date(endingDate);
    // chack start date and end date and base on condication alter date.
    if (startDate > endDate) {
      var swap = startDate;
      startDate = endDate;
      endDate = swap;
    }
    // This is for leap year.
    const startYear = startDate.getFullYear();
    const february =
      (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0
        ? 29
        : 28;
    const daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    let yearDiff = endDate.getFullYear() - startYear;
    let monthDiff = endDate.getMonth() - startDate.getMonth();
    if (monthDiff < 0) {
      yearDiff--;
      monthDiff += 12;
    }
    let dayDiff = endDate.getDate() - startDate.getDate();
    let hourDiff = endDate.getHours() - startDate.getHours();
    let minuteDiff = endDate.getMinutes() - startDate.getMinutes();
    let secondDiff = endDate.getSeconds() - startDate.getSeconds();
    if (dayDiff < 0) {
      if (monthDiff > 0) {
        monthDiff--;
      } else {
        yearDiff--;
        monthDiff = 11;
      }
      dayDiff += daysInMonth[startDate.getMonth()];
    }

    return ([
      yearDiff,monthDiff,dayDiff,hourDiff,minuteDiff,secondDiff
    ]);
  
}
  // When scroll down the screen
  onScroll() {
    console.log("Scrolled");
    this.page = this.page + 1;
    this.pagesize = this.pagesize;
    this.getGroupSentMessages();
  }

    // Check device to display message in full screen window
    checkDevice(){
      if (window.innerWidth > 1900) { 
        this.displayNewWindow = true;         
        } else {    
        this.displayNewWindow = false;
      }
    };

  /* here get the group messageId */
  
  setSelectedMailType(messageId: string, index: number = 0) {
    this.groupmessage=true;
    this.selectedId = null;
    this.SelectedMail= index;
    this.checkDevice();
    this.selectedMessageDetails = true;
    this.mailboxServie.messageId = messageId;
    this.mailboxServie.index = index;
    this.getGroupSelectedMessages();
    
  }



  // Deleting Selected Group Message
  deleteGroupMessage = (groupMessageID:any) =>{
    console.log(groupMessageID)
    let data: any = {
      "messageId":groupMessageID,
      "userID": this.userId,
    }
    const dialogRef = this.dialog.open(MovetoinboxDialogComponent, {
      data: {
        alldata: data,
        id: 'You want to delete the message'
      }
    });
    //Call move to Archive API based on the confirm Dialod Result
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        
        this.inBoxService.deleteGroupMessage(groupMessageID,this.userId).subscribe(res => {
          console.log(res)
          if (res) {
            this.toaster.success(
              this.translate.instant('Deleted successfully'));
            this.getGroupSentMessages();
            this.groupmessage=false;
            this.SelectedMail=false;
            this.selectedMessageDetails=false;
            this.getGroupMessagesCount();
          }
        })
      }
    })
  }
  
  // Selected Group Message Details
  getGroupSelectedMessages() {
    this.inBoxService.GetGroupMessageDetail(this.mailboxServie.messageId).subscribe(result => {
      this.sentselectedMessagesDetails = result;
      if (this.sentselectedMessagesDetails.length > 0) {
        this.sentselectedMessagesDetails[0].MessageBody = this.sentselectedMessagesDetails[0]?.MessageBody != null ? this.sanitizer.bypassSecurityTrustHtml(this.sentselectedMessagesDetails[0]?.MessageBody) : '';
      
      // this.defaultMessageId = this.sentselectedMessagesDetails[0].MessageId;
        if (this.mailboxServie.groupMessages?.length > 0) {
          let groupData = this.mailboxServie.groupMessages?.find((x: any) => x.ID == this.mailboxServie.messageId);
          if (groupData != undefined && groupData?.isread == false) {
            groupData.isread = true;
            this.markasRead();
          }
        }
      }
      console.log(result);
      this.xmltoJSON = JSON.parse(this.sentselectedMessagesDetails[0].attachment);
      if (this.xmltoJSON != "") {
        let singleAttachment: any = [];
        singleAttachment = this.xmltoJSON.ROOT.GroupMessage_Attachments;
        this.filesData = singleAttachment;
      }
    })
  }

  //Get Group Message Count
  getGroupMessagesCount() {
    this.inBoxService.groupMessageUnreadCount(this.userId).subscribe((count: any) => {
      this.mailboxServie.mailstype[4].count = count;
    })
  }

  // Selected record mark as read or mark as unread
  markasRead() {
    this.inBoxService.GetGroupMessageUpdateReadStatus(this.mailboxServie.messageId, this.userId).subscribe((res: any) => {
      this.getGroupMessagesCount();
    }, (err: any) => {
      console.log(err)
      this.toaster.error(err.error.Message)
    })
  }

  close () {
    this.selectedMessageDetails=false;
    this.SelectedMail = false;
    this.displayNewWindow = false;
  }

    // search value
    Searchkey(searchkeyvalue: any): void {
      console.log(searchkeyvalue, "searchvalue")
      this.mailboxServie.sendMessage(searchkeyvalue);
    }
  
    clearSearchData() {
      this.searchTexts = "";
      this.selectedId = null;
      this.mailboxServie.groupMessages = [];
      this.page=0;
      this.pageNumber=0;
      this.searchText='';
      this.groupmessage=false;
      this.SelectedMail=false;
      this.getGroupSentMessages();
    }

    openCompose()
    {
      let serviceData = this.commonService.getServiceDetail(DashboardServiceData.SendMessage);
      if (serviceData && serviceData?.result?.toLowerCase() == CommonConstants.success) {
        this.router.navigate(['/pages/navigate-routes/Compose']);
      }
      else {
        this.commonService.openServiceDialog(serviceData);
      }
    }

    downloadFiles(filesDetails:any,filename : any) {
      let encodefilename = btoa(filesDetails)
      // window.open(`${environment.apiBaseURL}${URLConstants.downloadFile}${encodefilename}`, '_blank');
      const headerOptions = new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/octet-stream',
      });
      //let encodefilename = btoa(this.pdfName)
      const requestOptions = { headers: headerOptions, responseType: 'blob' as 'blob' };
      this.postObject = {FileName: encodefilename}
      this.http.post(`${environment.apiBaseURL}${this.url}`, this.postObject, requestOptions).pipe(map((data: any) => {
        const blob = new Blob([data], {
          type: 'application/octet-stream'
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${filename}`;
        link.click();
        window.URL.revokeObjectURL(link.href);
  
      })
      ).subscribe(
        (data) => {
          //console.log(data);
          this.toaster.success(this.translate.instant('File downloaded successfully'));
        },
        (err) => {
          this.toaster.warning(err.error.Message || err.error.message || "File doesn't exist");
        },
      );
    }
    
    refresh() {
      this.mailboxServie.groupMessages =[];
      this.page=0;
      this.pageNumber=0;
      this.selectedId = null;
      this.getGroupSentMessages();
  
      // window.location.reload();
    }
  // next() {
  //   // this.getGroupSentMessages();
  //   this.refGroupMessage = this.mailboxServie.groupMessages;
  //   this.getRefGroupMessages = this.mailboxServie.groupMessages;
  //   this.mailGroupMessage = this.getRefGroupMessages.filter((e: any) => this.defaultMessageId == e.ID)
  //   this.selectedRowId = this.mailGroupMessage[0].RowNumber
  //   this.maxCount = this.mailboxServie.groupMessages.length;
  //   if (this.selectedRowId != this.maxCount) {
  //     this.nextGroupMessage = this.refGroupMessage.filter((e: any) => e.RowNumber == this.selectedRowId + 1)
  //   }
  //   this.mailboxServie.messageId = this.nextGroupMessage[0].ID;
  //   if (this.selectedRowId == this.maxCount) {
  //     this.disable = true;
  //   }
  //   if (this.disable) {
  //     this.prevdisable = false;
  //   }
  //   if (this.prevdisable) {
  //     this.disable = false;
  //   }
  //   this.getGroupSelectedMessages();
  //   // this.groupMessageUpdateReadStatus(this.mailboxServie.messageId);
  // }
  // previous() {
  //   this.prevrefGroupMessage = this.mailboxServie.groupMessages;
  //   this.prevgetRefGroupMessages = this.mailboxServie.groupMessages;
  //   this.mailGroupMessage = this.prevgetRefGroupMessages.filter((e: any) => this.defaultMessageId == e.ID)
  //   this.selectedRowId = this.mailGroupMessage[0].RowNumber
  //   this.maxCount = this.mailboxServie.groupMessages.length;
  //   if (this.selectedRowId != 1) {
  //     this.nextGroupMessage = this.prevrefGroupMessage.filter((e: any) => e.RowNumber == this.selectedRowId - 1)
  //   }
  //   this.mailboxServie.messageId = this.nextGroupMessage[0].ID;
  //   if (this.selectedRowId == 1) {
  //     this.prevdisable = true;
  //   }
  //   // if(this.disable) {
  //   //   this.prevdisable = false;
  //   // }
  //   if (this.prevdisable) {
  //     this.disable = false;
  //   }
  //   this.getGroupSelectedMessages();
  //   // this.groupMessageUpdateReadStatus(this.mailboxServie.messageId);
  // }
}













