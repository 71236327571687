import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MailBoxService } from 'src/app/data/service/mail-box.service';
import { InBoxService } from 'src/app/data/service/inbox.service';
import { Subscription } from 'rxjs';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ToasterService } from 'src/app/data/service/toaster.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MovetoinboxDialogComponent } from '../movetoinbox-dialog/movetoinbox-dialog.component';
import { applicationHttpClientCreator } from 'src/app/data/interface/IRequestOptions';
import { URLConstants } from 'src/app/core/constants/urlConstants';
import { HttpClient } from '@angular/common/http';
import { TokenService } from 'src/app/data/service/token.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { PerfectScrollbarComponent, PerfectScrollbarConfigInterface, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { map } from 'rxjs/operators';
import { CommonService } from 'src/app/data/service/common.service';
import { CommonConstants } from 'src/app/core/constants/commonConstants';
import { DashboardServiceData } from 'src/app/core/constants/app-enums';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};

@Component({
  selector: 'app-sent',
  templateUrl: './sent.component.html',
  styleUrls: ['./sent.component.scss']
})
export class SentComponent implements OnInit {
  public pagerTypes = ['numeric', 'input'];
  public type = 'numeric';
  public buttonCount = 5;
  public info = true;
  //public refresh = true;
  public pageSizes = true;
  public previousNext = true;
  public position = 'bottom';
  //public page: number = 0;
  //public pageSize: number = 5;
  public pageCovid: number = 0;
  public pageSizeCovid: number = 5;
  public skip = 0;
  public gridViewPrescription: any;
  public gridViewAllergies: any;
  public recallPage = 0;
  public recallpageSize = 5;
  public sortDirection?: 'asc' | 'desc';
  searchText: any;
  subscription: Subscription;
  public searchkeyvalue: any;
  checkboxForm: FormGroup;
  searchTexts: any;
  openSidebar: boolean = true;
  selected = 'compose';
  public openedArchiveDialouge: boolean = false;
  selectedMailType: string;
  public sentData: any = [];
  public userId: any;
  public messageId: string;
  allComplete: boolean = false;
  public sentMessageDetails: boolean = false;
  public page: number = 0;
  public pagesize: number = 20;
  selectedAction: any;
  selectedcheckbox: any = [];
  public profileObject: any = [];
  public sentselectedMessagesDetails: any = [];
  public xmltoJSON: any;
  public filesData: any = [];
  listInboxId: any = [];
  pageNumber: number = 0;
  downloadURL = URLConstants.downloadFile_Post;
  isPostDownload = true;
  public SentMessages: any = [];
  public showDetail: boolean = false;
  public SelectedMail: any;
  public nomessages:any;
  public searchemptymsg:boolean=false;
  //filterByIcons: string;
  //sentForm: FormGroup;
  public title:any = "thank you every on";
  showPrintPage: boolean = false;
  public config: PerfectScrollbarConfigInterface = {};
  imgSrc2: string = "assets/images/Group 5027.svg";
  imgSrc3: string = "assets/images/Group 5026.svg";
  imgSrc4: string = "assets/images/icons-text/Group 5076.svg";
  imgSrc7: string = "assets/images/icons-text/Group 5080.svg";
  imgSrc5: string = "assets/images/ReadUnread4.svg"
  imgSrc6: string = "assets/images/ReadUnread2.svg"
  isLoading = true;
  isSearch: boolean = false;
  isScrolled: boolean = false;
  messageBody: any = '';
  ismailSelected: boolean = false;
  constructor(private router: Router,
    public http: HttpClient,
    public mailboxService: MailBoxService,
    public deletedialog: MatDialog,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    public fb: FormBuilder,
    public toaster: ToasterService,
    public tokenService: TokenService,
    public translate: TranslateService,
    public commonService: CommonService,
    public inBoxService: InBoxService) {
      console.log("===================== Constructor Called ===================");
    this.subscription = this.mailboxService.onMessage().subscribe(message => {
      console.log("=========== Message Subscribed =====================");
      if (message) {
        this.searchkeyvalue = message.text;
        this.searchText = this.searchkeyvalue;
        // this.searchemptymsg =true;
      } else {
        // clear messages when empty message received
        this.searchkeyvalue = "";
        this.searchText = '';
        this.searchemptymsg =false;
      }
      this.sentMessageDetails = false;
      this.SelectedMail = false;
      this.refresh();
    });
  }
  ngOnInit(): void {
    console.log('=================== Ng On Init ===================');
    this.getSentMessages();
    this.loadProfileData();
    // this.mailboxService.sentData=[];
    // this.checkboxForm = this.fb.group({
    //   sentToArchive: new FormControl('')
    // })

    this.mailboxService.sentData = [];
    this.checkboxForm = this.fb.group({
      checkboxChecked: [''],
      selectedCheckbox: ['']
    })
  }

  ngOnDestroy() {
    console.log("================ Ng On Destroy Called =================");
    this.subscription.unsubscribe();
    // console.log(this.mailboxService.onMessage().)
  }

  activationCode(): void {
    this.router.navigate(['/myaccount/profile']);
  }

  openCompose()
  {
    let serviceData = this.commonService.getServiceDetail(DashboardServiceData.SendMessage);
    if (serviceData && serviceData?.result?.toLowerCase() == CommonConstants.success) {
      this.router.navigate(['/pages/navigate-routes/Compose']);
    }
    else {
      this.commonService.openServiceDialog(serviceData);
    }
  }

  // opensettings()
  // {
  //   this.router.navigate(['/pages/navigate-routes/Settings']);
  // }

  // hideinbox(): void {

  //   if (this.mailboxService.selectedMailBoxType.includes("Compose") ||
  //     this.mailboxService.selectedMailBoxType.includes("Inbox") ||
  //     this.mailboxService.selectedMailBoxType.includes("Drafts") ||
  //     this.mailboxService.selectedMailBoxType.includes("Sent") ||
  //     this.mailboxService.selectedMailBoxType.includes("Archive") ||
  //     this.mailboxService.selectedMailBoxType.includes("Groups") ||
  //     this.mailboxService.selectedMailBoxType.includes("Detail") ||
  //     this.mailboxService.selectedMailBoxType.includes("DraftDetail")) {
  //     this.mailboxService.selectedMailBoxType = '';
  //   }
  // }

  /* get sent messages */
  getSentMessages() {
    console.log('Sent Message Search Called')
    this.nomessages=false;
    this.isLoading = true;
    this.userId = this.tokenService.userId();
    // 'CDA16090-57DD-481C-94A0-54330D17B5DA'
    this.inBoxService.getSentMessage(this.userId, 'MessageSentOn desc', this.page, this.pagesize, true, this.searchText).subscribe((res: any) => {
      if (res){
        this.searchemptymsg =false;
        if (this.searchTexts?.length || this.searchkeyvalue?.length) {
         if(this.isScrolled){
          this.mailboxService.sentData.push(...res);
         }else{
          this.mailboxService.sentData = [];
          this.mailboxService.sentData = res;
         }
        } else {
          this.mailboxService.sentData.push(...res);
        }
      console.log(this.mailboxService.sentData);
      }
      this.isLoading = false;
      if (this.mailboxService.sentData.length == 0){
        this.nomessages=true;
         this.searchemptymsg =true;
      }else{
        this.searchemptymsg =false;
      }
      //   for(var i=0; i<res.length ; i++){
      //     let datediff=this.calculateDateDiff(res[i].MessageSentOn);
      //     let diffResult= datediff[0] != 0 ? datediff[0]+" year" : datediff[1] != 0 ? datediff[1]+ " month" : datediff[2] != 0 ? datediff[2]+ " day" : datediff[3] != 0 ? datediff[3] + " hour" : datediff[4] != 0 ? datediff[4] + " min" : datediff[5] + " sec";
      //     res[i].MessageSentOn= diffResult;
      // }
      // this.mailboxService.sentData.push(...res)
      // console.log(this.mailboxService.sentData)
      //this.SentMessages = res;
    }, (err: any) => {
      this.isLoading = false;
    })
  }
  calculateDateDiff = (dateOfReceived: any) => {

    var startDate = new Date(dateOfReceived);
    // user not pass endingDate then set current date as end date.
    let endingDate = new Date();

    let endDate = new Date(endingDate);
    // chack start date and end date and base on condication alter date.
    if (startDate > endDate) {
      var swap = startDate;
      startDate = endDate;
      endDate = swap;
    }
    // This is for leap year.
    const startYear = startDate.getFullYear();
    const february =
      (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0
        ? 29
        : 28;
    const daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    let yearDiff = endDate.getFullYear() - startYear;
    let monthDiff = endDate.getMonth() - startDate.getMonth();
    if (monthDiff < 0) {
      yearDiff--;
      monthDiff += 12;
    }
    let dayDiff = endDate.getDate() - startDate.getDate();
    let hourDiff = endDate.getHours() - startDate.getHours();
    let minuteDiff = endDate.getMinutes() - startDate.getMinutes();
    let secondDiff = endDate.getSeconds() - startDate.getSeconds();
    if (dayDiff < 0) {
      if (monthDiff > 0) {
        monthDiff--;
      } else {
        yearDiff--;
        monthDiff = 11;
      }
      dayDiff += daysInMonth[startDate.getMonth()];
    }

    return ([
      yearDiff, monthDiff, dayDiff, hourDiff, minuteDiff, secondDiff
    ]);

  }
  // Profile Photo
  loadProfileData(): void {
    this.userId = this.tokenService.userId();
    applicationHttpClientCreator(this.http).Get(`${URLConstants.viewProfile}/${this.userId}`, {
    }).subscribe((data: any) => {
      console.log('View Profile', data);
      this.profileObject = data;
    })
  }

  // When scroll down the screen
  onScroll() {
    console.log("Scrolled");
    this.pageNumber = this.pageNumber + 1;
    this.page = this.pageNumber * this.pagesize;
    this.isScrolled = true;
    console.log('=================== Scroll ===================');
    this.getSentMessages();
  }

  // one of the checkbox is unchecked then show indeterminate
  isIndeterminate() {
    return (this.selectedcheckbox.length > 0 && !this.isChecked());
  };

  isChecked() {
    return this.selectedcheckbox.length === this.mailboxService.sentData.length;
  };


  toggle(item: any, event: MatCheckboxChange) {
    if (event.checked) {
      this.selectedcheckbox.push(item);
    } else {
      const index = this.selectedcheckbox.indexOf(item);
      if (index >= 0) {
        //  this.selectedcheckbox.splice(index, 1);
        this.selectedcheckbox.forEach((element: any, index: any) => {
          if (element.SentMessageId == item.SentMessageId) {
            this.selectedcheckbox.splice(index, 1);
          }
        });
      }
    }
    console.log(item + "<>", event.checked);
  }

  exists(item: any) {
    return this.selectedcheckbox.indexOf(item) > -1;
  };

  toggleAll(event: MatCheckboxChange) {
    if (event.checked) {
      this.mailboxService.sentData.forEach((row: any) => {
        this.selectedcheckbox.push(row)
      });
    } else {
      this.selectedcheckbox.length = 0;
    }
  }

  // toggleAll(event: any) {
  //   if (event==true) {
  //     this.mailboxService.sentData.forEach((row: any) => {
  //       this.selectedcheckbox.push(row)
  //     });
  //   } else {
  //     this.selectedcheckbox.length = 0;
  //   }
  // }


  // Refresh
  refresh() {
    this.mailboxService.sentData = [];
    this.selectedcheckbox.length = 0;
    this.page = 0;
    this.pageNumber = 0;
    this.searchemptymsg =false;
    console.log('=================== Refresh ===================');
    this.getSentMessages();

    // window.location.reload();
  }

  // search value
  Searchkey(searchkeyvalue: any): void {
    console.log(searchkeyvalue, "searchvalue")
    this.isSearch = true;
    this.mailboxService.sendMessage(searchkeyvalue);
  }

  clearSearchData() {
    this.searchTexts = "";
    this.mailboxService.sentData = [];
    this.page = 0;
    this.pageNumber = 0;
    this.searchText = '';
    this.sentMessageDetails = false;
    this.SelectedMail = false;
    // setTimeout(() => {
    console.log('=================== Clear Search Data ===================');
    this.getSentMessages();
    // }, 1000)
  }

  // sentMoveToDelete(SentMessageId: any) {
  //   console.log(SentMessageId)
  //   let data: any = {
  //     "SentMessageId": SentMessageId,
  //   }
  //   const dialogRef = this.deletedialog.open(MovetoinboxDialogComponent, {
  //     data: {
  //       alldata: data,
  //       id: 'You want to Delete.'
  //     }
  //   });
    //Call move to inbox API based on the confirm Dialod Result
    // dialogRef.afterClosed().subscribe(dialogResult => {
    //   if (dialogResult) {
    //     this.inBoxService.deleteDraftMessage(SentMessageId).subscribe(data => {
    //       if (data) {
    //         this.toaster.success(
    //           this.translate.instant('Draft deleted successfully'));
    //         this.mailboxService.sentData =[];
    //         this.page=0;
    //         this.pageNumber=0;
    //         this.getSentMessages();
    //       }
    //     })
    //   }
    // })
  // }


  sentMoveToDelete(messageId: any) {
    console.log(messageId)
    let data: any = {
      "SentMessageId": messageId,
      "IsSentItem": true
    }
    const dialogRef = this.deletedialog.open(MovetoinboxDialogComponent, {
      data: {
        alldata: data,
        id: 'You want to delete the message'
      }
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {

        this.inBoxService.deleteToTrash(messageId,true).subscribe(res => {
          console.log(res)
          if (res) {
            this.toaster.success(
              this.translate.instant('Deleted successfully'));
              this.mailboxService.sentData =[];
            this.page=0;
            this.pageNumber=0;
            console.log('=================== Sent Move To Delete ===================');
            this.getSentMessages();
            this.sentMessageDetails = false;
            this.SelectedMail=false;
          }
        })
      }
    })
  }

  sentMessageMovetoArchive(SentMessageId: any) {
    let data: any = {
      "userID": this.userId,
      "inboxId": SentMessageId,
      "IsSentItem": true
    }

    const dialogRef = this.dialog.open(MovetoinboxDialogComponent, {
      data: {
        alldata: data,
        id: 'You want to move to archive.'
      }
    });
    //Call move to Archive API based on the confirm Dialod Result
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.inBoxService.moveToArchive(this.userId, SentMessageId, true).subscribe(res => {
          if (res == 1) {
            this.toaster.success(
              this.translate.instant('Moved to archive list successfully'));
            this.mailboxService.sentData = [];
            console.log('=================== Sent Message Move To Archieve ===================');
            this.getSentMessages();
            this.checkboxForm.controls['checkboxChecked']?.setValue(false);
            this.checkboxForm.controls['selectedCheckbox']?.setValue(false);
            this.selectedcheckbox.length = 0;

            this.SelectedMail = false;
            this.sentMessageDetails = false;
          }
        })
      }
    })
  }

  // All mails move to archive
  archiveAll() {
    this.imgSrc4 = "assets/images/icons-text/Group 5076.svg";
    console.log("archiveall");
    this.listInboxId = [];
    //this.toggleAll(true);
    this.selectedcheckbox.filter((value: any) => {
      this.listInboxId.push(value.SentMessageId);
    });
    // const listId = this.selectedcheckbox.length > 9 ? 'All' : this.listInboxId;
    const listId = this.listInboxId;
    console.log(listId);
    this.inBoxService.moveToArchive(this.userId, listId, true).subscribe((res: any) => {
      if (res == 1) {
        console.log(res);
        this.mailboxService.sentData = [];
        console.log('=================== Archieve All ===================');
        this.getSentMessages();
        this.checkboxForm.controls['checkboxChecked']?.setValue(false);
        this.checkboxForm.controls['selectedCheckbox']?.setValue(false);
        this.selectedcheckbox.length = 0;

        this.SelectedMail = false;
        this.sentMessageDetails = false;
      }
      this.selectedcheckbox = [];
    }, (err: any) => {
      console.log(err)
      this.toaster.error(err.error.Message)
    })

    // for (let i = 0; i < this.selectedcheckbox.length; i++) {
    //   if (this.checkboxForm.value.checkboxChecked || this.checkboxForm.value.selectedCheckbox) {
    //     this.inBoxService.moveToArchive(this.userId, this.selectedcheckbox[i].SentMessageId, true).subscribe((res: any) => {
    //       if (res == 1) {
    //         console.log(res);
    //         this.mailboxService.sentData = [];
    //         this.getSentMessages();
    //         this.checkboxForm.controls['checkboxChecked']?.setValue(false);
    //         this.checkboxForm.controls['selectedCheckbox']?.setValue(false);
    //         this.selectedcheckbox.length = 0;
    //       }
    //     }, (err: any) => {
    //       console.log(err)
    //       this.toaster.error(err.error.Message)
    //     })
    //   }
    // }
    this.toaster.success(this.translate.instant('Moved to archive list successfully'));
  }

  changeMailbox(event: any) {
    this.mailboxService.selectedMailBoxType = event.value;
  }

  loadComponent(value: any) {
    this.router.navigate(['/mailbox/', value]);
  }
  /* here get the sent message Id */
  setSelectedMailType(messageId: string, index: number) {
    this.SelectedMail = index;
    this.ismailSelected = true
    //setSelectedMailType(dataItem: any, index: number) {
    this.sentMessageDetails = true;
    this.mailboxService.messageId = messageId;
    //this.mailboxService.messageId = dataItem.SentMessageId;
    this.mailboxService.index = index;
    console.log(this.mailboxService.messageId);
    this.getSelectedSentDetails();
  }

  //#region Selected Sent Details
  getSelectedSentDetails() {
    this.inBoxService.getSelectedSentMailData(true, this.mailboxService.messageId).subscribe((result: any) => {
      if (result) {
        // console.log("resulttttt",result)
        this.sentselectedMessagesDetails = result;
        console.log((this.sentselectedMessagesDetails))
        this.xmltoJSON = JSON.parse(this.sentselectedMessagesDetails[0].attachment);
        console.log(this.xmltoJSON);
        this.messageBody =  this.sentselectedMessagesDetails?.[0]?.MessageBody ? this.commonService.sanitizeHtml(this.inBoxService.replaceImgTags(this.sentselectedMessagesDetails?.[0]?.MessageBody)) : '';

        if (this.xmltoJSON != "") {
          let singleAttachment: any = [];
          singleAttachment = this.xmltoJSON.ROOT.Inbox_Attachments;
          this.filesData = singleAttachment;
          console.log(this.filesData);
        }
      }
    })
  }




  // Download Files
  downloadFiles(filename: any) {
    let encodefilename = btoa(filename)
    console.log(encodefilename)
    window.open(`${environment.apiBaseURL}${URLConstants.downloadFile}${encodefilename}`, '_blank');
  }

  close() {
    this.sentMessageDetails = false;
    this.SelectedMail = false;
    this.ismailSelected = false
  }

  // previousRecord(){
  //   console.log(this.mailboxService.index)
  //   let next = (this.currentIndex -= 1);
  //   if (next < 0) {
  //     this.currentIndex = 0;
  //     return;
  //   }
  //   let nextRecord = this.sentData[next];
  //   this.sentselectedMessagesDetails[this.mailboxService.index] = nextRecord;
  //   console.log(nextRecord, next);
  // }

  // nextRecord(){
  //   let next = (this.currentIndex += 1);
  //   if (next > this.sentData.length - 1) {
  //     this.currentIndex = 5;
  //     return;
  //   }
  //   let nextRecord = this.sentData[next];
  //   this.sentselectedMessagesDetails[this.mailboxService.index]=nextRecord;
  //    console.log(nextRecord, next);
  // }

  // Print Data of message body
  printAnalysisData() {
    this.showPrintPage = true;
    this.imgSrc7="assets/images/icons-text/Group 5080.svg";
    setTimeout(() => {
      let printContents: any, popupWin: any;
      let printData: any;
      if (document.getElementById('sectionToPrint') !== null && document.getElementById('sectionToPrint') !== undefined) {
        printContents = document.getElementById('sectionToPrint');
        printData = printContents.innerHTML
      }
      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
      popupWin.document.write(`
      <html>
        <head>
          <title>ManageMyHealth: Empowering for Health & Wellness</title>
          <style>//........Cu
          stomized style.......
          </style>
        </head>
        <body onload="window.print(); window.close(

        )">
        <div>
        ${printData}
        </div>
        </body>
      </html>`
      );
      popupWin.document.close(
        this.showPrintPage = false
      );

    }, 500)
  }
}












