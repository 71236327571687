<!-- ------------------------------ header desktop view --------------------------------- -->
<div class="desktop-view-1024 m-b-20">
    <div class="filter-by-index page-header header-buttons m-b-sm-0 p-lt-xs-20">
        <h1>{{'Inbox'| translate}}</h1>
        <div class="icon-container justify-content-end">
            <mat-form-field appearance="outline" class="search-text-icon searchbox-maillist has-success">
                <mat-label>{{'Search Messages'|translate}}</mat-label>
                <input matInput placeholder="{{'Search Messages'|translate}}" [(ngModel)]="searchTexts"
                    name="searchTexts" (change)="Searchkey(searchTexts)" style="height:26px !important">
                <!-- <button mat-button *ngIf="searchTexts" matSuffix mat-icon-button aria-label="Clear"
                    (click)="clearSearchData(searchTexts)" class="clear-icon-close">
                    <mat-icon class="icon-subfld">close</mat-icon>
                </button>
            <button mat-button  mat-icon-button *ngIf="searchTexts" class="search-icon-close">
                    <i class="icon-cent-search-popular icon-subfld" matSuffix></i>
                </button> -->
                <button mat-button mat-icon-button *ngIf="searchTexts">
                    <i class="icon-cent-search-popular icon-subfld" matSuffix></i>
                </button>
                <mat-icon class="icon-subfld" *ngIf="searchTexts" class="clear-icon-close"
                    (click)="clearSearchData(searchTexts)">close</mat-icon>
            </mat-form-field>
            <button mat-button (click)="openCompose()" class="btn-primary-rect m-l-15" style="top:2px">
                <i class="icon-cent-pencil-box-outline-text icon-color-grid"></i>
                <span class="m-r-10">{{'Compose'|translate}}</span>
            </button>
            <!-- <span><img src="../../../../assets/images/icons-text/settings.svg" (click)="opensettings()" style="position: relative;top:2px;left:8px;" matTooltip="Message settings"></span> -->
            <!-- <i class="icon-cent-pencil-box-outline-text icon-color-grid"></i> -->
        </div>
    </div>
</div>

<!-- ------------------------------header mobile-view-1024----------------------------------->
<div class="mobile-view-1024">
    <div class="filter-by-index page-header header-buttons m-b-sm-0 p-lt-xs-20">
        <h1>{{'Inbox'| translate}}</h1>
        <div class="icon-container">
            <button (click)="openCompose()" mat-button class="mobile-view-1024 txt-color"><i
                    class="icon-cent-pencil-box-outline-text"></i>COMPOSE</button>
        </div>
    </div>
    <div class="icon-container">
        <mat-form-field appearance="outline" class="search-text-icon p-lt-xs-20 searchbox-mobile-maillist has-success">
            <mat-label>{{'Search Messages'|translate}}</mat-label>
            <input matInput placeholder="{{'Search Messages'|translate}}" [(ngModel)]="searchTexts" name="searchTexts"
                (change)="Searchkey(searchTexts)" style="height:26px !important">
            <!-- <button mat-button *ngIf="searchTexts" matSuffix mat-icon-button aria-label="Clear"
                (click)="clearSearchData(searchTexts)" class="clear-icon-close">
                <mat-icon class="icon-subfld">close</mat-icon>
            </button>
                <i class="icon-cent-search-popular icon-subfld search-icon-close-mobile" *ngIf="searchTexts" matSuffix></i> -->
            <button mat-button *ngIf="searchTexts" matSuffix mat-icon-button aria-label="Clear" class="clear-icon-close"
                (click)="clearSearchData(searchTexts)">
                <mat-icon class="icon-subfld1">close</mat-icon>
            </button>
            <i class="icon-cent-search-popular icon-subfld1 search-icon-close-mobile" *ngIf="searchTexts" matSuffix></i>
        </mat-form-field>
        <!-- <hr>
        <div>
        </div> -->
    </div>
</div>



<!-- ------------------------------Messages list view------------------------------------>

<div class="bg-white" *ngIf="mailboxService.gridView.length == 0 && !isLoading">
    <div fxLayout="column wrap" fxLayoutAlign="center center" class="no-msg">
        <div class="text-center">
            <img src="assets/images/message123.svg" alt="mail-box">
            <p>Sorry! No messages found.</p>
        </div>
    </div>
</div>

<div fxlayout="row wrap" class="bg-white maillist-flexbox" *ngIf="mailboxService.gridView.length > 0">

    <div fxflex.lg="40" fxflex.gt-lg="30" fxflex.sm="100" fxflex="100" class="b-r flexbox1-maillist">
        <!-- <div *ngIf="nomessages" class="desktop-view-1024 text-center d-md-none nomessage-top">
            <div>
                <div class="connect-health-centre text-center">
                    <img src="assets/images/Appointments.svg" alt="" />
                    <h3>Start talking with doctors</h3>
                    <p>
                        Connect a Health Centre to book <br /> and view your first appointment.
                    </p>
                    <button mat-button class="btn-primary-pill-l-icon" routerLink="/pages/navigate-routes/Compose"
                        [disabled]="healthCenterList?.length == 0">

                        <i class="icon-cent-lan-connect-network"></i>
                        <i class="icon-cent-pencil-box-outline-text icon-color-grid"></i>Compose Message
        </button>
                    <p class="m-b-0">
                        <a (click)="activationCode()" (click)="hideinbox()">I have an activation code</a>
                    </p>
                </div>
            </div>
        </div> -->
        <div class="emailInbox k-slide-tray" *ngIf="mailboxService.gridView.length>0">
            <div class="list-wrapper">
                <form [formGroup]="checkboxForm">
                    <div class="m-t-20 m-b-10 mail-icons">
                        <mat-checkbox class="select-all" matTooltip="Select all message" aria-label="Select All"
                            [checked]="isChecked()" [indeterminate]="isIndeterminate()"
                            (change)="toggleAll($event.checked)">
                        </mat-checkbox>
                        <span *ngIf="(selectedcheckbox.length==0)">
                            <img src="assets/images/icons-text/Group 5057.svg" class="m-l-15 m-l-6">
                            <img src="assets/images/icons-text/Group 5064.svg" class="m-l-15 m-l-6">
                            <img src="assets/images/icons-text/Group 5065.svg" class="m-l-15 m-l-6">
                        </span>
                        <span *ngIf="(selectedcheckbox.length>0)">
                            <a (click)="allMailsMarkAsRead()" class="m-l-15" matTooltip="Mark as read">
                                <img [src]="imgSrc4"
                                    (mouseover)="imgSrc4 = 'assets/images/icons-text/Group 5059.svg'"
                                    (mouseout)="imgSrc4 = 'assets/images/icons-text/Group 5033.svg'"
                                    alt="Archive">
                            </a>

                            <a (click)="allMailsMarkAsUnRead()" class="m-l-15" matTooltip="Mark as unread">
                                <img [src]="imgSrc5"
                                    (mouseover)="imgSrc5 = 'assets/images/icons-text/Group 5022 (1).svg'"
                                    (mouseout)="imgSrc5 = 'assets/images/icons-text/Group 5034.svg'"
                                    alt="Archive">
                            </a>

                            <a (click)="archiveAll()" class="m-l-15" matTooltip="Archive">
                                <!-- <img src= '../../../../assets/images/Group 5021.svg' alt="reply"> -->
                                <img [src]="imgSrc6"
                                    (mouseover)="imgSrc6 = 'assets/images/icons-text/Group 5067.svg'"
                                    (mouseout)="imgSrc6 = 'assets/images/icons-text/Group 5068.svg'"
                                    alt="Archive">
                            </a>

                        </span>
                    </div>
                    <div class="mails-list-wrapper maillist-listview-desktop" infinite-scroll
                        [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()"
                        [scrollWindow]="false">
                        <div class="mails-list-wrapper">
                            <div class="mail-list-content">
                                <div class="messages">
                                    <div class="emailInbox">
                                        <mat-card class="m-0" *ngFor="let i=index ;let grid of mailboxService.gridView">
                                            <div [ngClass]="{'mail-selected': SelectedMail === i}">
                                                <mat-card-content class="mat-card-spacer mat-card-contentmail"
                                                    style="padding: 10px;">
                                                    <mat-checkbox class="select-all" matTooltip="Select message"
                                                        (click)="$event.stopPropagation()"
                                                        (change)="toggle(grid, $event)" [(ngModel)]="grid.IsChecked"
                                                        [checked]="exists(grid)" [disabled]="grid.disabled"
                                                        [labelPosition]="grid.labelPosition" name="clinicalUser"
                                                        ngDefaultControl [ngModelOptions]="{standalone: true}"
                                                        class="m-t-6">
                                                    </mat-checkbox>

                                                    <mat-card-header
                                                        (click)="setSelectedMailType(grid.InboxMessageId,i)"
                                                        (change)="toggleAll($event.checked)"
                                                        class="mat-card-header mail-header-wrapper">
                                                        <div mat-card-avatar class="mat-card-avatar m-t-6">
                                                            <!-- <img src="{{grid.FromUserImage}}" alt="" class="img-fluid img-circle"> -->
                                                            <img class="img-fluid img-circle"
                                                                [src]="grid.FromUserImage !=='' ? grid.FromUserImage  : 'assets/images/nophoto_unregistered.svg'"
                                                                onError="this.src='assets/images/nophoto_unregistered.svg'">
                                                        </div>

                                                        <div class="desktop-view-1024">
                                                            <mat-card-title *ngIf="grid.IsRead"
                                                                class="header-text header-sub header-width" >{{grid.FromName}}
                                                            </mat-card-title>
                                                            <!-- <mat-card-title *ngIf="grid.IsRead"
                                                                class="header-text header-sub header-width"
                                                                style="display: flex; margin-left: 15px;">
                                                                {{grid.FromName}}
                                                            </mat-card-title> -->


                                                            <mat-card-title *ngIf="!grid.IsRead"
                                                                class="header-text-unread header-sub header-width">{{grid.FromName}}
                                                            </mat-card-title>
                                                            <mat-card-title class="m-t-8">
                                                                <span *ngIf="grid.IsRead"
                                                                    class="header-text-sub">{{grid.Subject}}</span>
                                                                <span *ngIf="!grid.IsRead"
                                                                    class="header-text-read-sub"><b>{{grid.Subject}}</b></span>
                                                            </mat-card-title>

                                                            <mat-card-title class="m-t-8">

                                                                    <span *ngIf="grid.IsRead"
                                                                    class="header-text-sub">Service Name: </span>
                                                                <span *ngIf="grid.IsRead"
                                                                    class="header-text-sub">{{grid.MessageCode}}</span>

                                                                
                                                                <span *ngIf="!grid.IsRead"
                                                                    class="header-text-read-sub"><b>Service Name:</b> </span>
                                                                <span *ngIf="!grid.IsRead"
                                                                    class="header-text-read-sub"><b>{{grid.MessageCode}}</b></span>
                                                            </mat-card-title>








                                                        </div>

                                                        <div class="mobile-view-1024">
                                                            <mat-card-title *ngIf="grid.IsRead"
                                                                class="header-text text-width">{{grid.FromName}}
                                                            </mat-card-title>
                                                            <mat-card-title *ngIf="!grid.IsRead"
                                                                class="header-text-unread text-width">{{grid.FromName}}
                                                            </mat-card-title>

                                                            <mat-card-title class="m-t-8 subject-width">
                                                                <span *ngIf="grid.IsRead"
                                                                    class="header-text-sub">{{grid.Subject}}</span>
                                                                <span *ngIf="!grid.IsRead"
                                                                    class="header-text-read-sub"><b>{{grid.Subject}}</b></span>
                                                            </mat-card-title>
                                                        </div>

                                                        <p class="date-time m-t-2">{{grid.MessageReceivedOn | date:
                                                            'dd/MM/yyyy h:mm a'}}</p>

                                                        <button (click)="$event.stopPropagation()" mat-icon-button
                                                            [matMenuTriggerFor]="menu" class="mailinbox-menu-btn">
                                                            <mat-icon>more_vert</mat-icon>
                                                        </button>

                                                        <mat-menu #menu="matMenu" class="mail-list-more-icons">
                                                            <button mat-menu-item
                                                                (click)="movetoArchive(grid.InboxMessageId)"
                                                                class="svg-btn-matmenu">
                                                                <!-- <mat-icon>inventory_2</mat-icon> -->
                                                                <img src="assets/images/Group 50488.svg"
                                                                    class="m-t-15 m-r-10">
                                                                <span class="m-l-10">Archive</span>
                                                            </button>
                                                            <button mat-menu-item
                                                                (click)="markasReadorUnread(grid.InboxMessageId,i)"
                                                                class="svg-btn-matmenu">
                                                                <!-- <mat-icon>mark_as_unread</mat-icon> -->
                                                                <img src="assets/images/Group 50466.svg"
                                                                    class="m-t-10 m-r-10">
                                                                <span>{{'Mark as read/unread'|translate}}</span>
                                                            </button>

                                                        </mat-menu>
                                                    </mat-card-header>

                                                </mat-card-content>
                                            </div>
                                        </mat-card>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="desktop-view-1024">
                            <div *ngIf="(searchemptymsg  || !mailboxService.gridView.length>0) " class="desktop-view-1024 text-center d-md-none nomessage-top">
                                <div>
                                    <div class="connect-health-centre text-center">
                                        <img src="assets/images/Appointments.svg" alt="" />
                                        <h3>There are no records available.</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                       <div  class="mobile-view-1024">
                        <div *ngIf="(searchemptymsg  || !mailboxService.gridView.length>0) " class="mobile-view-1024 text-center d-md-none nomessage-top">
                            <div>
                                <div class="connect-health-centre text-center">
                                    <img src="assets/images/Appointments.svg" alt="" />
                                    <h3>There are no records available.</h3>
                                </div>
                            </div>
                        </div>
                       </div> -->



                    </div>
                </form>
            </div>
        </div>
    </div>

    <div fxflex.lg="60" fxflex.gt-lg="70" fxflex.sm="100" fxflex="100"
        class="desktop-view-1024 align-items-center justify-content-center detail-part movetodetail flexbox2-maillist"
        style="overflow: auto;" [ngClass]="ismailSelected == false ? 'notvisible' : 'visible'">
        <div *ngIf="replyMessage==false && mailboxService.gridView.length>0 "
            class="desktop-view-1024 text-center d-md-none no-selected-msg">
            <!-- <mat-icon  role="img" class="mat-icon notranslate display-5 m-r-15 material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">mail_outline</mat-icon> -->
            <img src="assets/images/message123.svg" alt="mail-box">
            <p>Select an item to read</p>

        </div>

        <!-- <div *ngIf="mailboxService.gridView.length==0 && replyMessage==false"
            class="desktop-view-1024 text-center d-md-none no-msg">
            <img src="../../../../assets/images/message123.svg" alt="mail-box">
            <p>Sorry! No new messages found.</p>
        </div> -->

        <!------------------------------ Reply Message Data -------------------------------->
        <div *ngIf="replyMessage==true && ShowDetail==false" class="desktop-view-1024 reply-content-maillist">
            <perfect-scrollbar class="perfect-scrollbar-maillist" [config]="config">
                <div class="email-view-content">
                    <div class="mail-content-wrapper">

                        <div class="mail-info-sub">
                            <div class="grid-normal-tbl">
                                <div class="tbl-row">
                                    <div class="tbl-th">{{'From'|translate}}:</div>
                                    <div class="tbl-td">{{selectedMessagesDetails[0]?.FromName}}</div>
                                </div>
                                <div class="tbl-row">
                                    <div class="tbl-th">{{'To'|translate}}:</div>
                                    <div class="tbl-td">{{selectedMessagesDetails[0]?.ToName}}</div>
                                </div>
                                <div class="tbl-row">
                                    <div class="tbl-th">{{'Service Type'|translate}}:</div>
                                    <div class="tbl-td">{{selectedMessagesDetails[0]?.MessageCode}}</div>
                                </div>
                                <div class="tbl-row">
                                    <div class="tbl-th">{{'Date'|translate}}:</div>
                                    <div class="tbl-td">{{selectedMessagesDetails[0]?.date | date: 'dd MMM yyyy hh:mm
                                        a'}}</div>
                                </div>
                                <div class="tbl-row">
                                    <div class="tbl-th">{{'Subject'|translate}}:</div>
                                    <div class="tbl-td">{{selectedMessagesDetails[0]?.Subject}}</div>
                                </div>
                                <div class="tbl-row" *ngIf="selectedMessagesDetails[0]?.ServiceRefNo">
                                    <div class="tbl-th">{{'Request Number'|translate}}:</div>
                                    <div class="tbl-td">{{selectedMessagesDetails[0]?.ServiceRefNo}}</div>
                                </div>
                            </div>
                        </div>
                        <div>
                        </div>


                        <div
                            class="filter-by-index page-header header-buttons m-b-sm-0 p-lt-xs-20 m-t-20 reply-content-icons">

                            <!-- <div class="attachements-header">
            <div>
                <mat-icon class="attach-icon">attach_file</mat-icon>
            </div>
                
                <div *ngIf="filesData">
                    <div class="attachments-list">
                        <app-download-file [url] = "downloadURL" [btnText] = "filesData?.OriginalFileName" [pdfName] = "filesData?.FileName" [isPostDownload] = "isPostDownload"></app-download-file>
                    </div>
                </div>
                <div *ngIf="filesData && filesData.length >1">
                    <div class="attachments-list" *ngFor="let data of filesData">
                        <app-download-file [url] = "downloadURL" [btnText] = "data?.OriginalFileName" [pdfName] = "data?.FileName" [isPostDownload] = "isPostDownload"></app-download-file>
                    </div>
                    </div>
        </div> -->

                            <div class="attachments-header">
                                <div *ngIf="filesData && filesData.length >=1;then file else nofile">here is ignored
                                </div>
                                <ng-template #file>
                                    <div class="attachments-list" *ngFor="let data of filesData">
                                        <mat-icon class="type-icon">attach_file</mat-icon>
                                        <app-download-file [url]="downloadURL" [btnText]="data?.OriginalFileName"
                                            [pdfName]="data?.FileName" [isPostDownload]="isPostDownload" class="m-t-5">
                                        </app-download-file>
                                    </div>
                                </ng-template>
                                <ng-template #nofile>
                                    <div class="attachments-list" *ngIf="filesData">
                                        <mat-icon class="type-icon">attach_file</mat-icon>
                                        <app-download-file [url]="downloadURL" [btnText]="filesData?.OriginalFileName"
                                            [pdfName]="filesData?.FileName" [isPostDownload]="isPostDownload"
                                            class="m-t-5">
                                        </app-download-file>
                                    </div>
                                </ng-template>
                            </div>

                            <div class="icon-container svg-btn-matmenu">
                                <!-- <button mat-button (click)="replyMessageShow('Detail')" matTooltip="Reply"
                                    class="btn-primary-rect m-l-15"
                                    disabled="{{selectedMessagesDetails[0]?.MessageCode == 'Repeat Prescription'  || !selectedMessagesDetails[0]?.IsStaffActive ? true : false }}"> -->
                                    <!-- <i class="icon-cent-pencil-box-outline-text icon-color-grid"></i> -->
                                    <button mat-button (click)="replyMessageShow('Detail')" matTooltip="Reply"
                                    class="btn-primary-rect m-l-15" *ngIf="selectedMessagesDetails[0]?.MessageCode != 'Repeat Prescription' || selectedMessagesDetails[0]?.IsMMHPms">
                                    <mat-icon> drive_file_rename_outline</mat-icon>
                                    <span class="m-l-10 m-r-5">{{'Reply'|translate}}</span>
                                </button>

                                <button mat-button (click)="replyMessageShow('Detail')" matTooltip="Reply"
                                class="btn-primary-rect m-l-15" *ngIf="!isPatient && selectedMessagesDetails[0]?.Subject.includes('RE:') == false && (selectedMessagesDetails[0]?.MessageCode == 'Repeat Prescription')">
                                <mat-icon> drive_file_rename_outline</mat-icon>
                                <span class="m-l-10 m-r-5">{{'Reply'|translate}}</span>
                            </button>

                                <a class="m-l-10" matTooltip="Print-message" (click)="printAnalysisData()">
                                    <img [src]="imgSrc7"
                                        (mouseover)="imgSrc7 = 'assets/images/icons-text/Group 5081.svg'"
                                        (mouseout)="imgSrc7 = 'assets/images/icons-text/Group 5080.svg'"
                                        alt="print">
                                </a>
                                <a (click)="movetoArchive(selectedMessagesDetails[0].InboxMessageId)" class="m-l-10"
                                    matTooltip="Archive message">
                                    <img [src]="imgSrc2"
                                        (mouseover)="imgSrc2 = 'assets/images/Group 5041.svg'"
                                        (mouseout)="imgSrc2 = 'assets/images/Group 5027.svg'" alt="Archive">
                                    <!-- <img src= '../../../../assets/images/Group 5027.svg' alt="reply"> -->
                                </a>
                                <a (click)="Inboxmovetodelete(selectedMessagesDetails[0].InboxMessageId)" class="m-l-10"
                                    matTooltip="Delete message">
                                    <img [src]="imgSrc3"
                                        (mouseover)="imgSrc3 = 'assets/images/Group 5043.svg'"
                                        (mouseout)="imgSrc3 = 'assets/images/Group 5026.svg'" alt="Delete">
                                    <!-- <img src= '../../../../assets/images/Group 5026.svg' alt="reply"> -->
                                </a>
                            </div>
                        </div>

                        <div class="mail-spacer-line"></div>
                        <div class="subject-email-content">
                            <div [innerHTML]="selectedMessagesDetails[0]?.MessageBody"></div>
                        </div>
                    </div>
                </div>
            </perfect-scrollbar>
        </div>


        <div *ngIf="replyMessage !== false && ShowDetail==true" class="desktop-view-1024" style="width: 100%;">
            <app-detailmailbo id="SelectedMail" [ngClass]="SelectedMail ? 'active' : ''"
                (onCloseClick)="SelectedMail = false" [IsMMHPms]="selectedMessagesDetails[0]?.IsMMHPms"></app-detailmailbo>
        </div>



        <!---------------------------------reply-content mobile-view-1024--------------------------------- -->
        <div *ngIf="replyMessage" class="mobile-view-1024 reply-message-content"
            style="width:100% !important ; right:0 !important;z-index: 5 !important;">
            <div class="view-mail-header close-btn-align">
                <button mat-button class="view-mail-close" (click)="close()">
                    <span class="cls-txt">{{'Close'|translate}}</span>
                    <!-- <i class="icon-cent-chevron-right-chevrons arrow-rightmail"></i> -->
                    <i class="icon-cent-close-cancel"></i>
                </button>
            </div>

            <div class="email-view-content maillist-listview-mobile">

                <div class="mail-content-wrapper">
                    <div class="mail-info-sub">

                        <div class="grid-normal-tbl">
                            <div class="tbl-row">
                                <div class="tbl-th">{{'From'|translate}}:</div>
                                <div class="tbl-td">{{selectedMessagesDetails[0]?.FromName}}</div>
                            </div>
                            <div class="tbl-row">
                                <div class="tbl-th">{{'To'|translate}}:</div>
                                <div class="tbl-td">{{selectedMessagesDetails[0]?.ToName}}</div>
                            </div>
                            <div class="tbl-row">
                                <div class="tbl-th">{{'Service Type'|translate}}:</div>
                                <div class="tbl-td">{{selectedMessagesDetails[0]?.MessageCode}}</div>
                            </div>
                            <div class="tbl-row">
                                <div class="tbl-th">{{'Date'|translate}}:</div>
                                <div class="tbl-td">{{selectedMessagesDetails[0]?.date | date: 'dd MMM yyyy hh:mm a'}}
                                </div>
                            </div>
                            <div class="tbl-row">
                                <div class="tbl-th">{{'Subject'|translate}}:</div>
                                <div class="tbl-td">{{selectedMessagesDetails[0]?.Subject}}</div>
                            </div>
                            <div class="tbl-row" *ngIf="selectedMessagesDetails[0]?.ServiceRefNo">
                                <div class="tbl-th">{{'Request Number'|translate}}:</div>
                                <div class="tbl-td">{{selectedMessagesDetails[0]?.ServiceRefNo}}</div>
                            </div>
                        </div>

                    </div>
                    <div>
                    </div>

                    <div class="filter-by-index page-header header-buttons m-b-sm-0 m-t-20" style="padding:0px;">

                        <div class="attachments-header">
                            <div *ngIf="filesData && filesData.length >=1;then file else nofile">here is ignored</div>
                            <ng-template #file>
                                <div class="attachments-list" *ngFor="let data of filesData">
                                    <mat-icon class="type-icon">attach_file</mat-icon>
                                    <app-download-file [url]="downloadURL" [btnText]="data?.OriginalFileName"
                                        [pdfName]="data?.FileName" [isPostDownload]="isPostDownload" class="m-t-5">
                                    </app-download-file>
                                </div>
                            </ng-template>
                            <ng-template #nofile>
                                <div class="attachments-list" *ngIf="filesData">
                                    <mat-icon class="type-icon">attach_file</mat-icon>
                                    <app-download-file [url]="downloadURL" [btnText]="filesData?.OriginalFileName"
                                        [pdfName]="filesData?.FileName" [isPostDownload]="isPostDownload" class="m-t-5">
                                    </app-download-file>
                                </div>
                            </ng-template>
                        </div>
                    </div>

                    <div class="filter-by-index page-header header-buttons m-b-sm-0 m-t-20" style="padding:0px;">

                        <!-- <div class="attachments-header">
                <div *ngIf="filesData && filesData.length >=1;then file else nofile">here is ignored</div>
                <ng-template #file>
                  <div class="attachments-list" *ngFor="let data of filesData">
                    <mat-icon class="type-icon">attach_file</mat-icon>
                    <app-download-file [url]="downloadURL" [btnText]="data?.OriginalFileName" [pdfName]="data?.FileName"
                    [isPostDownload]="isPostDownload" class="m-t-5"></app-download-file>
                </div>
                </ng-template>
                <ng-template #nofile>
                  <div class="attachments-list" *ngIf="filesData">
                    <mat-icon class="type-icon">attach_file</mat-icon>
                    <app-download-file  [url]="downloadURL" [btnText]="filesData?.OriginalFileName"
                    [pdfName]="filesData?.FileName" [isPostDownload]="isPostDownload" class="m-t-5">
                    </app-download-file>
                </div>
                </ng-template>
            </div> -->

                        <button mat-button (click)="replyMessageNavigate('Detail')" matTooltip="Reply"
                            class="btn-primary-rect"
                            disabled="{{selectedMessagesDetails[0]?.MessageCode == 'Repeat Prescription' || !selectedMessagesDetails[0]?.IsStaffActive ? true : false }}">
                            <mat-icon> drive_file_rename_outline</mat-icon>
                            <span class="m-l-10 m-r-5">{{'Reply'|translate}}</span>
                        </button>

                        <div class="icon-container svg-btn-matmenu">
                            <a class="m-l-10" matTooltip="Print-message" (click)="printAnalysisData()">
                                <img [src]="imgSrc7"
                                    (mouseover)="imgSrc7 = 'assets/images/icons-text/Group 5081.svg'"
                                    (mouseout)="imgSrc7 = 'assets/images/icons-text/Group 5080.svg'"
                                    alt="print">
                            </a>
                            <a (click)="movetoArchive(selectedMessagesDetails[0].InboxMessageId)" class="m-l-10"
                                matTooltip="Archive"><img [src]="imgSrc2"
                                    (mouseover)="imgSrc2 = 'assets/images/Group 5005.svg'"
                                    (mouseout)="imgSrc2 = 'assets/images/Group 4992.svg'" alt="Archive">
                            </a>
                            <a (click)="Inboxmovetodelete(selectedMessagesDetails[0].InboxMessageId)" class="m-l-10"
                                matTooltip="Delete"><img [src]="imgSrc3"
                                    (mouseover)="imgSrc3 = 'assets/images/Group 5043.svg'"
                                    (mouseout)="imgSrc3 = 'assets/images/Group 4991.svg'" alt="Delete">
                            </a>
                        </div>
                    </div>

                    <div class="mail-spacer-line"></div>
                    <div class="subject-email-content">
                        <div [innerHTML]="selectedMessagesDetails[0]?.MessageBody"></div>
                    </div>
                </div>
            </div>
            <!-- <div class="email-view-footers">
        <button mat-button class="btn-primary-pill-l-icon" type="submit" [disabled]="selectedMessagesDetails[0]?.MessageCode ==='Repeat Prescription'" (click)="replyMessageNavigate('Detail')">
            <i class="icon-cent-pen-business"></i> {{'Reply Message'|translate}} </button>
    </div> -->

        </div>

        <!-------------------------------- Popup for From and To Details ---------------------------->
        <kendo-popup *ngIf="showdetails" [offset]="tooltipOffset">
            <div class="p-l-20">
                <h3><span> {{'Health Centre'|translate}} :</span>{{getPracticeData[0]?.BusinessName }}</h3>
                <h3><span> {{'Address'|translate}} :</span>
                    <h4 [innerHTML]="getPracticeData[0]?.Address"></h4>
                </h3>
                <h3><span>{{'PhoneNumber'|translate}} :</span>{{getPracticeData[0]?.PhoneNumber }}</h3>
            </div>
        </kendo-popup>


        <kendo-dialog title="Messages Details" *ngIf="moreInfo" (close)="moreInfo=false" [minWidth]="300" [width]="600">
            <div class="grid-normal-tbl">
                <div class="tbl-row">
                    <div class="tbl-th">{{'From'| translate}}</div>
                    <div class="tbl-td">{{ messageinfo?.FromName }}</div>
                </div>
                <div class="tbl-row">
                    <div class="tbl-th">{{'Subject' | translate}}</div>
                    <div class="tbl-td">{{ messageinfo?.Subject }}</div>
                </div>
                <div class="tbl-row">
                    <div class="tbl-th">{{'Service Type' | translate}}</div>
                    <div class="tbl-td">{{ messageinfo?.MessageCode }}</div>
                </div>

                <div class="tbl-row">
                    <div class="tbl-th">{{'Received on'| translate}}</div>
                    <div class="tbl-td">{{ messageinfo?.MessageReceivedOn | date: 'dd/MM/yyyy hh:mm:ss a'}}</div>
                </div>
                <div class="tbl-row" *ngIf="selectedMessagesDetails[0]?.ServiceRefNo">
                    <div class="tbl-th">{{'Request Number'|translate}}:</div>
                    <div class="tbl-td">{{selectedMessagesDetails[0]?.ServiceRefNo}}</div>
                </div>
            </div>
            <kendo-dialog-actions>
                <button kendoButton (click)="moreInfo=false" primary="true"
                    class="btn btn-primary responsive-button">Close</button>
            </kendo-dialog-actions>
        </kendo-dialog>

    </div>
</div>

<!-------------------------------- Print Preview Details ---------------------------->
<div *ngIf="showPrintPage == true">
    <div id="sectionToPrint">

        <div class="mail-info-sub">
            <div class="grid-normal-tbl">
                <div class="tbl-row">
                    <div class="tbl-th">{{'From'|translate}} : {{selectedMessagesDetails[0]?.FromName}}</div>
                    <!-- <div class="tbl-td">{{selectedMessagesDetails[0]?.FromName}}</div> -->
                </div>
                <div class="mail-spacer-line"></div>
                <div class="tbl-row">
                    <div class="tbl-th">{{'Date'|translate}} : {{selectedMessagesDetails[0]?.date | date: 'dd MMM yyyy
                        hh:mm a'}}</div>
                    <!-- <div class="tbl-td">{{selectedMessagesDetails[0]?.date | date: 'dd MMM yyyy hh:mm a'}}</div> -->
                </div>
                <div class="mail-spacer-line"></div>
                <div class="tbl-row">
                    <div class="tbl-th">{{'Subject'|translate}} : {{selectedMessagesDetails[0]?.Subject}}</div>
                    <!-- <div class="tbl-td">{{selectedMessagesDetails[0]?.Subject}}</div> -->
                </div>
                <div class="mail-spacer-line"></div>
            </div>
        </div>
        <div class="mail-spacer-line"></div>
        <div class="subject-email-content">
            <div class="tbl-th">{{'Message body'}} :</div>
            <div [innerHTML]="selectedMessagesDetails[0]?.MessageBody"></div>
        </div>
    </div>
</div>

<!-- Icons Commentedfor overlaping issue -->
<!--  <div class="mb-flo-wrp compose-btn-float mobile-view-1024">
           <button mat-button class="btn-circle" matTooltip="Attach Document"
                aria-label="Attach Document" (click)="openCompose()"> -->
<!-- <i class="icon-cent-add-plus-add icon-white"></i> -->
<!-- <i class="icon-cent-pencil-box-outline-text icon-color-grid color-bl-icon"></i>
            </button>
        </div>
        <div class="mb-flo-wrp settings-btn-float mobile-view-1024">
            <span><img src="../../../../assets/images/icons-text/hoversettings.svg" (click)="opensettings()"></span>
        </div> -->