<!-- ------------------------------ header desktop-view --------------------------------- -->
<div class="desktop-view-1024 m-b-20">
  <div class="filter-by-index page-header header-buttons m-b-sm-0 p-lt-xs-20">
    <h1>{{ "Sent Messages" | translate }}</h1>
    <div class="icon-container justify-content-end">
      <div class="has-success m-t-10">
        <div class="icon-cent-list-0027 g-clr">
          <div class="n-wrap">
            {{"READ STATUS BY RECIPIENT"}}
          </div>
        </div>
      </div>
      <mat-form-field appearance="outline" class="search-text-icon m-l-15 searchbox-sent has-success">
        <mat-label>{{'Search Messages'|translate}}</mat-label>
        <input matInput placeholder="Search Messages" [(ngModel)]="searchTexts" name="searchTexts"
          (change)="Searchkey(searchTexts)" style="height: 26px !important;">
        <!-- <button mat-button  mat-icon-button *ngIf="searchTexts" class="search-icon-close">
            <i class="icon-cent-search-popular icon-subfld" matSuffix></i>
        </button>
        <button mat-button *ngIf="searchTexts" matSuffix mat-icon-button aria-label="Clear" class="clear-icon-close"
          (click)="clearSearchData(searchTexts)">
          <mat-icon class="icon-subfld">close</mat-icon>
        </button> -->
        <button mat-button mat-icon-button *ngIf="searchTexts">
          <i class="icon-cent-search-popular icon-subfld" matSuffix></i>
        </button>
        <mat-icon class="icon-subfld" *ngIf="searchTexts" class="clear-icon-close"
          (click)="clearSearchData(searchTexts)">close</mat-icon>
      </mat-form-field>
      <button mat-button (click)="openCompose()" class="btn-primary-rect m-l-15" style="top:2px">
        <i class="icon-cent-pencil-box-outline-text icon-color-grid"></i>
        <span class="m-r-10">{{'Compose'|translate}}</span>
      </button>
      <!-- <span><img src="../../../../assets/images/icons-text/settings.svg" (click)="opensettings()" style="position: relative;top:2px;left:8px;" matTooltip="Message settings"></span> -->
    </div>
  </div>
</div>

<!-- ------------------------------header mobile-view----------------------------------->
<div class="mobile-view-1024">
  <div class="filter-by-index page-header header-buttons m-b-sm-0 p-lt-xs-20">
    <h1>{{ "Sent Messages" | translate }}</h1>
    <div class="icon-container">
      <button (click)="openCompose()" mat-button class="mobile-view-1024 txt-color"><i
          class="icon-cent-pencil-box-outline-text"></i>COMPOSE</button>
    </div>
  </div>
  <div class="icon-container">
    <mat-form-field appearance="outline" class="search-text-icon p-lt-xs-20 searchbox-mobile-sent has-success">
      <mat-label>{{'Search Messages'|translate}}</mat-label>
      <input matInput placeholder="{{'Search Messages'|translate}}" [(ngModel)]="searchTexts" name="searchTexts"
        (change)="Searchkey(searchTexts)" style="height:26px !important">
      <button mat-button *ngIf="searchTexts" matSuffix mat-icon-button aria-label="Clear" class="clear-icon-close"
        (click)="clearSearchData(searchTexts)">
        <mat-icon class="icon-subfld1">close</mat-icon>
      </button>
      <i class="icon-cent-search-popular icon-subfld1 search-icon-close-mobile" *ngIf="searchTexts" matSuffix></i>
    </mat-form-field>
  </div>
  <hr>
  <div>
    <div class="icon-cent-list-0027 g-clr" style="width: 478px !important;">
      <div class="n-wrap">
        {{"READ STATUS BY RECIPIENT"}}
      </div>
    </div>
  </div>
</div>

<!-- ------------------------------ message-list --------------------------------- -->

<div class="bg-white" *ngIf="mailboxService.sentData.length == 0 && !isLoading">
  <div fxLayout="column wrap" fxLayoutAlign="center center" class="no-msg">
    <div class="text-center">
      <img src="assets/images/message123.svg" alt="mail-box">
      <p>Sorry! No messages found.</p>
    </div>
  </div>
</div>

<div fxlayout="row wrap" class="bg-white sent-flexbox" *ngIf="mailboxService.sentData.length > 0">
  <div fxflex.lg="40" fxflex.gt-lg="30" fxflex.sm="100" fxflex="100" class="b-r flexbox1-sent">

    <!-- <div *ngIf="nomessages" class="text-center d-md-none nomessage-top">
      <div>
        <div class="connect-health-centre text-center">
          <img src="assets/images/Appointments.svg" alt="" />
          <h3>Start talking with doctors</h3>
          <button mat-button class="btn-primary-pill-l-icon" routerLink="/pages/navigate-routes/Compose"
            [disabled]="healthCenterList?.length == 0">
            <i class="icon-cent-pencil-box-outline-text icon-color-grid"></i>
            Compose Message</button>
        </div>
      </div>
    </div> -->

    <div class="emailInbox relative ps ps--active-y k-slide-tray" *ngIf="mailboxService.sentData.length > 0">
      <form [formGroup]="checkboxForm">
        <div class="m-t-20 m-b-10 mail-icons">
          <mat-checkbox class="select-all" matTooltip="Select all message" formControlName="checkboxChecked"
            [checked]="isChecked()" [indeterminate]="isIndeterminate()" (change)="$event ? toggleAll($event) : null">
          </mat-checkbox>
          <span *ngIf="selectedcheckbox.length==0">
            <img src="assets/images/icons-text/Group 5073.svg" class="m-l-15">
          </span>
          <!-- <span *ngIf="checkboxForm.value.checkboxChecked || checkboxForm.value.selectedCheckbox|| selectedcheckbox.length>0"> -->
          <span *ngIf="selectedcheckbox.length>0">
            <!-- <mat-icon matTooltip="move to Archive" class="m-r-5" (click)="archiveAll()">inventory_2</mat-icon>-->
            <a (click)="archiveAll()" class="m-l-15" matTooltip="Move to Archive">
              <!-- <img src= '../../../../assets/images/Group 5021.svg' alt="reply"> -->
              <img [src]="imgSrc4" (mouseover)="imgSrc4 = 'assets/images/icons-text/Group 5075.svg'"
                (mouseout)="imgSrc4 = 'assets/images/icons-text/Group 5076.svg'" alt="Archive">
            </a>
          </span>
        </div>

        <div infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()"
          [scrollWindow]="false" class="sent-listview-desktop">
          <div class="mail-list-content">
            <div class="messages">
              <div class="emailInbox">
                <mat-card class="m-0" *ngFor="let i=index; let sent of mailboxService.sentData">
                  <div [ngClass]="{'mail-selected': SelectedMail === i}">
                    <mat-card-content class="mat-card-spacer">
                      <mat-checkbox (click)="$event.stopPropagation()" matTooltip="Select message"
                        formControlName="selectedCheckbox" (change)="$event ? toggle(sent, $event) : null"
                        [checked]="exists(sent)" [disabled]="sent.disabled" [labelPosition]="sent.labelPosition"
                        class="m-t-6">
                      </mat-checkbox>
                      <mat-card-header (click)="setSelectedMailType(sent.SentMessageId,i)">

                        <div mat-card-avatar class="mat-card-avatar m-t-6">
                          <!-- <img [src]="profileObject.PhotoURL !=='' ? profileObject.PhotoURL  : 'assets/images/nophoto_unknown.gif'"
              alt="" class="img-fluid img-circle"> -->
                          <img class="img-fluid img-circle"
                            [src]="profileObject.PhotoURL !=='' ? profileObject.PhotoURL  : 'assets/images/nophoto_unregistered.svg'"
                            onError="this.src='assets/images/nophoto_unregistered.svg'">

                        </div>
                        <div class="desktop-view-1024">
                          <mat-card-title class="header-sub header-width m-l-a">{{sent.ToName}}</mat-card-title>
                          <mat-card-title class="text-sub m-t-8 m-l-15">

                            <!-- <mat-icon class="m-r-5 sent-icons" *ngIf="sent.isRecipientRead">drafts</mat-icon> -->
                            <span *ngIf="sent.isRecipientRead"><img src="assets/images/Group 50666.svg"
                                class="m-r-10 sent-icon"></span>
                            <span *ngIf="sent.isRecipientRead"><img src="assets/images/Group 50466.svg"
                                class="m-r-10 sent-icon"></span>
                            {{sent.Subject}}
                          </mat-card-title>
                          <mat-card-title class="text-sub m-t-8 m-l-15 subject-width">
                            <span><b>Service Type</b>: {{sent.MessageCode}}</span>
                          </mat-card-title>
                        </div>

                        <div class="mobile-view-1024">
                          <mat-card-title class="text-width sentdata">{{sent.ToName}}</mat-card-title>

                          <mat-card-title class="text-sub m-t-8 m-l-15 subject-width">
                            <span *ngIf="sent.isRecipientRead"><img src="assets/images/Group 50666.svg"
                                class="m-r-10 sent-icon"></span>
                            <span *ngIf="sent.isRecipientRead"><img src="assets/images/Group 50466.svg"
                                class="m-r-10 sent-icon"></span>
                            {{sent.Subject}}
                          </mat-card-title>
                          <!-- <mat-card-title class="text subject-width">
                            <span *ngIf="sent.isRecipientRead"><img src="../../../../assets/images/Group 50666.svg" class="m-r-10 sent-icon"></span>
                            <span *ngIf="!sent.isRecipientRead"><img src="../../../../assets/images/Group 50466.svg" class="m-r-10 sent-icon"></span>
                            Service Type:Consultation
                          </mat-card-title> -->

                          <mat-card-title class="text-sub m-t-8 m-l-15 subject-width">
                            <span><b>Service Type</b> {{sent.MessageCode}}</span>
                          </mat-card-title>





                        </div>

                        <p class="date-time m-t-2">{{sent.MessageSentOn | localDate }}</p>
                        <!-- <button class="date-time m-t-27" (click)="$event.stopPropagation()" matTooltip="Archive message" mat-icon-button (click)="sentMessageMovetoArchive(sent.SentMessageId)"> -->
                        <!-- <mat-icon>delete_outline</mat-icon> -->
                        <!-- <img src="../../../../assets/images/Group 50488.svg">
                        </button> -->
                        <div class="date-time m-t-27" (click)="$event.stopPropagation()">
                          <div class="icon-cent-list-0027 g-clr " matTooltip="Recipient Read Status"
                            *ngIf="sent.isRecipientRead"></div>
                          <div class="icon-cent-email-outline-messaging" matTooltip="Recipient Read Status"
                            *ngIf="!sent.isRecipientRead"></div>
                        </div>
                      </mat-card-header>
                    </mat-card-content>
                  </div>
                </mat-card>
              </div>
            </div>
          </div>
          <!-- <div class="desktop-view-1024">
            <div class="desktop-view-1024 text-center d-md-none nomessage-top" *ngIf="(searchemptymsg  || !mailboxService.sentData.length > 0) " >
              <div>
                  <div class="connect-health-centre text-center">
                      <img src="assets/images/Appointments.svg" alt="" />
                      <h3>There are no records available.</h3>
                  </div>
              </div>
          </div>
          </div>
            <div class="mobile-view-1024">
              <div  class="mobile-view-1024 text-center d-md-none nomessage-top" *ngIf="(searchemptymsg  || !mailboxService.sentData.length > 0) ">
                <div>
                    <div class="connect-health-centre text-center">
                        <img src="assets/images/Appointments.svg" alt="" />
                        <h3>There are no records available.</h3>
                    </div>
                </div>
            </div>
         </div> -->


        </div>
      </form>
    </div>

  </div>

  <!-- ------------------------------ sent-message-data desktop-view --------------------------------- -->
  <div fxflex.lg="60" fxflex.gt-lg="70" fxflex.sm="100" fxflex="100"
    class="desktop-view-1024 align-items-center justify-content-center detail-part movetodetail flexbox2-sent"
    style="overflow:auto" [ngClass]="ismailSelected == false ? 'notvisible' : 'visible'">
    <div *ngIf="sentMessageDetails==false  && mailboxService.sentData.length>0"
      class="desktop-view-1024 text-center d-md-none no-selected-msg">
      <img src="assets/images/message123.svg" alt="mail-box">
      <p>Select an item to read</p>

    </div>

    <!-- <div *ngIf="mailboxService.sentData.length==0" class="desktop-view-1024 text-center d-md-none no-msg">
      <img src="../../../../assets/images/message123.svg" alt="mail-box">
      <p>Sorry! No new messages found.</p>
  </div> -->

    <div *ngIf="sentMessageDetails==true" class="desktop-view-1024 w-100">
      <perfect-scrollbar class="perfect-scrollbar-sent" [config]="config">
        <div class="email-view-content">
          <div class="mail-content-wrapper">
            <div class="mail-info-sub">

              <div class="grid-normal-tbl">
                <div class="tbl-row">
                  <div class="tbl-th">{{'From'|translate}}:</div>
                  <div class="tbl-td">{{sentselectedMessagesDetails[0]?.FromName}}</div>
                </div>
                <div class="tbl-row">
                  <div class="tbl-th">{{'To'|translate}}:</div>
                  <div class="tbl-td">{{sentselectedMessagesDetails[0]?.ToName}}</div>
                </div>
                <div class="tbl-row">
                  <div class="tbl-th">{{'Service Type'|translate}}:</div>
                  <div class="tbl-td">{{sentselectedMessagesDetails[0]?.MessageCode}}</div>
                </div>
                <div class="tbl-row">
                  <div class="tbl-th">{{'Date'|translate}}:</div>
                  <div class="tbl-td">{{sentselectedMessagesDetails[0]?.date | date:'dd MMM yyyy hh:mm a'}}</div>
                </div>
                <div class="tbl-row">
                  <div class="tbl-th">{{'Subject'|translate}}:</div>
                  <div class="tbl-td">{{sentselectedMessagesDetails[0]?.Subject}}</div>
                </div>
                <div class="tbl-row" *ngIf="sentselectedMessagesDetails[0]?.ServiceRefNo">
                  <div class="tbl-th">{{'Request Number'|translate}}:</div>
                  <div class="tbl-td">{{sentselectedMessagesDetails[0]?.ServiceRefNo}}</div>
                </div>
              </div>
            </div>

            <div class="filter-by-index page-header header-buttons m-b-sm-0 m-t-20" style="padding: 0px">

              <div class="attachments-header">
                <div *ngIf="filesData && filesData.length >=1;then file else nofile">here is ignored</div>
                <ng-template #file>
                  <div class="attachments-list" *ngFor="let data of filesData">
                    <mat-icon class="type-icon">attach_file</mat-icon>
                    <app-download-file [url]="downloadURL" [btnText]="data?.OriginalFileName" [pdfName]="data?.FileName"
                      [isPostDownload]="isPostDownload" class="m-t-5"></app-download-file>
                  </div>
                </ng-template>
                <ng-template #nofile>
                  <div class="attachments-list" *ngIf="filesData">
                    <mat-icon class="type-icon">attach_file</mat-icon>
                    <app-download-file [url]="downloadURL" [btnText]="filesData?.OriginalFileName"
                      [pdfName]="filesData?.FileName" [isPostDownload]="isPostDownload" class="m-t-5">
                    </app-download-file>
                  </div>
                </ng-template>
              </div>

              <div class="icon-container">
                <!-- <button mat-button class="btn-primary-rect m-l-15" (click)="replyMessageShow('Detail')">
                  <mat-icon> drive_file_rename_outline</mat-icon>
                  <span class="m-l-10 m-r-5">{{'Reply'|translate}}</span>
                </button>  -->

                <a matTooltip="Print-message" (click)="printAnalysisData()" class="m-l-15">
                  <img [src]="imgSrc7" (mouseover)="imgSrc7 = 'assets/images/icons-text/Group 5081.svg'"
                    (mouseout)="imgSrc7 = 'assets/images/icons-text/Group 5080.svg'" alt="print">
                </a>

                <a (click)="sentMessageMovetoArchive(mailboxService.messageId)" class="m-l-10"
                  matTooltip="Archive message">
                  <img [src]="imgSrc2" (mouseover)="imgSrc2 = 'assets/images/Group 5041.svg'"
                    (mouseout)="imgSrc2 = 'assets/images/Group 5027.svg'">
                </a>
                <a class="m-l-10" matTooltip="Recipient Read Status" *ngIf="sentselectedMessagesDetails[0]?.IsRead">
                  <!-- <img src= '../../../../assets/images/Group 5026.svg' alt="reply"> -->
                  <img [src]="imgSrc5" (mouseover)="imgSrc5 = 'assets/images/ReadUnread3.svg'"
                    (mouseout)="imgSrc5 = 'assets/images/ReadUnread4.svg'" alt="Delete">
                </a>
                <a class="m-l-10" matTooltip="Recipient Read Status" *ngIf="!sentselectedMessagesDetails[0]?.IsRead">
                  <!-- <img src= '../../../../assets/images/Group 5026.svg' alt="reply"> -->
                  <img [src]="imgSrc6" (mouseover)="imgSrc6 = 'assets/images/ReadUnread1.svg'"
                    (mouseout)="imgSrc6 = 'assets/images/ReadUnread2.svg'" alt="Delete">
                </a>
                <a (click)="sentMoveToDelete(mailboxService.messageId)" class="m-l-10" matTooltip="Delete message">
                  <!-- <img src= '../../../../assets/images/Group 5026.svg' alt="reply"> -->
                  <img [src]="imgSrc3" (mouseover)="imgSrc3 = 'assets/images/Group 5043.svg'"
                    (mouseout)="imgSrc3 = 'assets/images/Group 5026.svg'" alt="Delete">
                </a>
              </div>
            </div>
            <div class="mail-spacer-line"></div>
            <div class="subject-email-content">
              <!-- <div [innerHTML]="sentselectedMessagesDetails[0]?.MessageBody"></div> -->
              <div [innerHTML]="messageBody"></div>
            </div>
          </div>
        </div>
      </perfect-scrollbar>
    </div>

    <!-- ------------------------------ sent-message-data mobile-view --------------------------------- -->

    <div *ngIf="sentMessageDetails" class="mobile-view-1024 reply-message-content"
      style="width:100% !important;right:0 !important;z-index: 5 !important;">

      <div class="email-view-content sent-listview-mobile">

        <div class="view-mail-header close-btn-align">
          <button mat-button class="view-mail-close" (click)="close()">
            <span class="cls-txt">{{'Close'|translate}}</span>
            <!-- <i class="icon-cent-chevron-right-chevrons arrow-rightmail"></i> -->
            <i class="icon-cent-close-cancel"></i>
          </button>
        </div>

        <div class="mail-content-wrapper">
          <div class="mail-info-sub">
            <div class="grid-normal-tbl">
              <div class="tbl-row">
                <div class="tbl-th">{{'From'|translate}}:</div>
                <div class="tbl-td">{{sentselectedMessagesDetails[0]?.FromName}}</div>
              </div>
              <div class="tbl-row">
                <div class="tbl-th">{{'To'|translate}}:</div>
                <div class="tbl-td">{{sentselectedMessagesDetails[0]?.ToName}}</div>
              </div>
              <div class="tbl-row">
                <div class="tbl-th">{{'Service Type'|translate}}:</div>
                <div class="tbl-td">{{sentselectedMessagesDetails[0]?.MessageCode}}</div>
              </div>
              <div class="tbl-row">
                <div class="tbl-th">{{'Date'|translate}}:</div>
                <div class="tbl-td">{{sentselectedMessagesDetails[0]?.date | date:'dd MMM yyyy hh:mm a'}}</div>
              </div>
              <div class="tbl-row">
                <div class="tbl-th">{{'Subject'|translate}}:</div>
                <div class="tbl-td">{{sentselectedMessagesDetails[0]?.Subject}}</div>
              </div>
              <div class="tbl-row" *ngIf="sentselectedMessagesDetails[0]?.ServiceRefNo">
                <div class="tbl-th">{{'Request Number'|translate}}:</div>
                <div class="tbl-td">{{sentselectedMessagesDetails[0]?.ServiceRefNo}}</div>
              </div>
            </div>

          </div>

          <div class="filter-by-index page-header header-buttons m-b-sm-0 m-t-20" style="padding: 0px">
            <!-- <div class="attachements-header">

              <mat-icon class="attach-icon"> attach_file</mat-icon>

              <div *ngIf="filesData && filesData.length > 1">
                <div class="attachments-list" *ngFor="let data of filesData">

                  <app-download-file [url]="downloadURL" [btnText]="data?.OriginalFileName" [pdfName]="data?.FileName"
                    [isPostDownload]="isPostDownload"></app-download-file>
                </div>
              </div>
              <div *ngIf="filesData">
                <div class="attachments-list">

                  <app-download-file [url]="downloadURL" [btnText]="filesData?.OriginalFileName"
                    [pdfName]="filesData?.FileName" [isPostDownload]="isPostDownload"></app-download-file>
                </div>
              </div>
            </div> -->

            <div class="attachments-header">
              <div *ngIf="filesData && filesData.length >=1;then file else nofile">here is ignored</div>
              <ng-template #file>
                <div class="attachments-list" *ngFor="let data of filesData">
                  <mat-icon class="type-icon">attach_file</mat-icon>
                  <app-download-file [url]="downloadURL" [btnText]="data?.OriginalFileName" [pdfName]="data?.FileName"
                    [isPostDownload]="isPostDownload" class="m-t-5"></app-download-file>
                </div>
              </ng-template>
              <ng-template #nofile>
                <div class="attachments-list" *ngIf="filesData">
                  <mat-icon class="type-icon">attach_file</mat-icon>
                  <app-download-file [url]="downloadURL" [btnText]="filesData?.OriginalFileName"
                    [pdfName]="filesData?.FileName" [isPostDownload]="isPostDownload" class="m-t-5">
                  </app-download-file>
                </div>
              </ng-template>
            </div>
            <div class="icon-container svg-btn-matmenu">
              <a matTooltip="Print-message" (click)="printAnalysisData()">
                <img [src]="imgSrc7" (mouseover)="imgSrc7 = 'assets/images/icons-text/Group 5081.svg'"
                  (mouseout)="imgSrc7 = 'assets/images/icons-text/Group 5080.svg'" alt="print">
              </a>

              <a (click)="sentMessageMovetoArchive(mailboxService.messageId)" class="m-l-10" matTooltip="Archive">

                <img src='assets/images/Group 5027.svg' alt="reply">
              </a>
              <a class="m-l-10" matTooltip="Recipient Read Status" *ngIf="sentselectedMessagesDetails[0]?.IsRead">
                <!-- <img src= '../../../../assets/images/Group 5026.svg' alt="reply"> -->
                <img [src]="imgSrc5" (mouseover)="imgSrc5 = 'assets/images/ReadUnread3.svg'"
                  (mouseout)="imgSrc5 = 'assets/images/ReadUnread4.svg'" alt="Delete">
              </a>
              <a class="m-l-10" matTooltip="Recipient Read Status" *ngIf="!sentselectedMessagesDetails[0]?.IsRead">
                <!-- <img src= '../../../../assets/images/Group 5026.svg' alt="reply"> -->
                <img [src]="imgSrc6" (mouseover)="imgSrc6 = 'assets/images/ReadUnread1.svg'"
                  (mouseout)="imgSrc6 = 'assets/images/ReadUnread2.svg'" alt="Delete">
              </a>
              <a (click)="sentMoveToDelete(mailboxService.messageId)" class="m-l-10" matTooltip="Delete">
                <img src='assets/images/Group 5026.svg' alt="reply">
              </a>
            </div>
          </div>

          <div class="mail-spacer-line"></div>
          <div class="subject-email-content">
            <div [innerHTML]="messageBody"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-------------------------------- Print Preview Details ---------------------------->
<div *ngIf="showPrintPage == true">
  <div id="sectionToPrint">

    <div class="mail-info-sub">
      <div class="grid-normal-tbl">
        <div class="tbl-row">
          <div class="tbl-th">{{'To'|translate}} : {{sentselectedMessagesDetails[0]?.ToName}}</div>
        </div>
        <div class="mail-spacer-line"></div>
        <div class="tbl-row">
          <div class="tbl-th">{{'Date'|translate}} : {{sentselectedMessagesDetails[0]?.date | date: 'dd MMM yyyy hh:mm
            a'}}</div>
        </div>
        <div class="mail-spacer-line"></div>
        <div class="tbl-row">
          <div class="tbl-th">{{'Subject'|translate}} : {{sentselectedMessagesDetails[0]?.Subject}}</div>
        </div>
        <div class="mail-spacer-line"></div>
        <div class="tbl-row" *ngIf="sentselectedMessagesDetails[0]?.ServiceRefNo">
          <div class="tbl-th">{{'Request Number'|translate}} : {{sentselectedMessagesDetails[0]?.ServiceRefNo}}</div>
        </div>
        <div class="mail-spacer-line"></div>
      </div>
    </div>
    <div class="mail-spacer-line"></div>
    <div class="subject-email-content">
      <div class="tbl-th">{{'Message body'}} :</div>
      <div [innerHTML]="messageBody"></div>
    </div>
  </div>
</div>
<!-- <div class="mb-flo-wrp compose-btn-float mobile-view-1024">
        <button mat-button class="btn-circle" matTooltip="Attach Document" aria-label="Attach Document" (click)="openCompose()"> -->
<!-- <i class="icon-cent-add-plus-add icon-white"></i> -->
<!-- <i class="icon-cent-pencil-box-outline-text icon-color-grid color-bl-icon"></i>
        </button>
    </div>
    <div class="mb-flo-wrp settings-btn-float mobile-view-1024">
      <span><img src="../../../../assets/images/icons-text/hoversettings.svg" (click)="opensettings()"></span>
  </div> -->