import { Component, OnInit } from '@angular/core';
import { TokenService } from 'src/app/data/service/token.service';
import { CommonURLs } from 'src/app/core/constants/commonURLs';
import moment from 'moment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  commonURL : any;
  roles : any;
  userRoles : any;
  isPatient : boolean;
  public currentYear = moment().year();
  constructor(private tokenService : TokenService) { 
    this.commonURL = CommonURLs;
  }

  ngOnInit(): void {
    this.roles = this.tokenService.getUserRoles();
		this.userRoles = this.roles.split(',');
    let patientid = this.userRoles.findIndex((x: any) => x.toLowerCase() == 'patient');
    if (patientid != -1) {
      this.isPatient = true;
    }
  }

}
