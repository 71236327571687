import { AfterViewInit, Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { NgModel } from '@angular/forms';

@Directive({
  selector: '[appDisableAutoFill]'
})
export class DisableAutoFillDirective implements AfterViewInit {

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    this.renderer.setAttribute(this.el.nativeElement, 'type', 'text');
  }   

  @HostListener('input', ['$event']) onInput(event: any) {
   this.typeChange();
  }

  typeChange() {
    const type = this.el.nativeElement.attributes.eyemask.value == 'true' ? 'password' : 'text'
    this.renderer.setAttribute(this.el.nativeElement, 'type', type);
  }

}
 