import { Router } from '@angular/router';
import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { InBoxService } from 'src/app/data/service/inbox.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AttachfileDialogComponent } from '../attachfile-dialog/attachfile-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MailBoxService } from 'src/app/data/service/mail-box.service';
import { ToasterService } from 'src/app/data/service/toaster.service';
import { TranslateService } from '@ngx-translate/core';
import { TokenService } from 'src/app/data/service/token.service';
import * as moment from 'moment';
import { URLConstants } from 'src/app/core/constants/urlConstants';
import { applicationHttpClientCreator } from 'src/app/data/interface/IRequestOptions';
import { HttpClient } from '@angular/common/http';
import { debounceTime, distinctUntilChanged, map, startWith, takeUntil } from 'rxjs/operators';
import { defer, interval, Observable, of, Subject, Subscription } from 'rxjs';
import { IAlertDialog } from 'src/app/data/interface/Idialog';
import { AlertType } from 'src/app/core/constants/app-enums';
import { AlertDialogComponent } from 'src/app/shared/component/alert-dialog/alert-dialog.component';
import 'rxjs/add/operator/takeWhile';
import { formatDate, Location } from '@angular/common';
import { Console } from 'console';
import { MatSelect } from '@angular/material/select';
import { EditorComponent } from '@progress/kendo-angular-editor';
import { UtcDatePipe } from 'src/app/shared/pipe/utc-date.pipe';

@Component({
  selector: 'app-compose',
  templateUrl: './compose.component.html',
  styleUrls: ['./compose.component.scss']
})
export class ComposeComponent implements OnInit, OnDestroy {
  showHelpIcon: boolean = false;
  userSearch: any;
  public userId: any;
  nameListControl = new FormControl();
  public healthCenterList: any = [];
  public locations: any = [];
  public serviceTypes: any = [];
  public Roles: any = [];
  public toUser: any = [];
  addedFiles: any[] = [];
  public locationsId: any;
  public practiceAdmin: string;
  composeForm: FormGroup;
  public refHealthCenterList: any = [];
  public userSettings: any = [];
  public userRoleId: string = '00000000-0000-0000-0000-000000000000';
  files: any[] = [];
  Attachment: any;
  filelengthError: boolean = false;
  servicerefno: any;
  locationname: any;
  tousername: any;
  filesarray: any = [];
  generateXML: any
  suggestedText: any = "";
  public messageTypeId: any;
  public patientList: any = [];
  public page: number = 0;
  public pagesize: number = 10;
  public isSelectAll = false;
  public draftCount: any;
  public inboxCount: any;
  public draftselectedMessagesDetails: any = [];
  public userData: any = [];
  filteredOptions: Observable<string[]>;
  serviceTypelocationDetails: any = [];
  selectedServiceTypeDataterms: any = [];
  selectedServiceTypeDataMessageDescription: any = [];
  public alertDialog: IAlertDialog;
  draftAttachmentXML: string;
  saveIndicator: string;
  destroy = new Subject();
  isLoadComplete = false;
  isStaffRemoved: boolean;
  selectedServiceTypeData: any = [];
  liveStatusSub: any;
  selectedRole: any = [];
  public nomessages: boolean = false;
  showTerms = false;
  messageBoxValue = '';
  userSelectedCheck = false;
  selectedUserId = '';
  userDetails: any;
  selectedNamecheck: boolean = false;
  receiveMsgWhenStaffOutOfOfficeEnabled: boolean;
  staffOutOfOffice: boolean = false;
  showInformation: boolean = false;
  isAcknowledge: boolean = false;
  disableSubmit: boolean = false;
  isBPPms: boolean = false;
  isPatient: boolean = false;
  paymentMethod: String = ""
  @ViewChild('serviceName') serviceName: MatSelect;
  @ViewChild('locationMatSelect') locationMatSelect: MatSelect;
  @ViewChild('kendoEditor') kendoEditor: EditorComponent;
  RoleIDforservice: string;

  utcDatePipe: any = new UtcDatePipe();

  // @HostListener('window:beforeunload', ['$event'])
  //   beforeUnloadHander(event:any) {
  //    return false;
  //   }
  constructor(public location: Location, public mailboxServie: MailBoxService, public inBoxService: InBoxService, private fb: FormBuilder,
    private dialog: MatDialog, public toaster: ToasterService, private translate: TranslateService,
    private http: HttpClient,
    private inboxService: InBoxService, private router: Router, public tokenService: TokenService, public mailBoxService: MailBoxService) {

  }
  ngOnInit(): void {
    this.userId = this.tokenService.userId();
    this.composeForm = this.fb.group({
      healthCenter: new FormControl(null),
      location: new FormControl(null),
      serviceName: new FormControl(null),
      role: new FormControl(null),
      toUser: new FormControl(null, Validators.required),
      toUserDefault: new FormControl(null),
      termsconditions: new FormControl(false, Validators.requiredTrue),
      subject: new FormControl(null, Validators.required),
      message: new FormControl(null, Validators.required),
      patientdata: new FormControl(null),
      Attachment: [''],
      inboxDraftMessageID: ['']
    })
    this.isPatient = this.tokenService.checkIsPatient();
    this.GetHealthCenterDetails();
    //this.getAllUsersList();
    // this.getPatientListByLocality();
    this.getUserSettings();
    this.liveStatusSub = interval(30000).subscribe((x: any) => {
      this.autoSaveDraft();
    });


    this.filteredOptions = this.nameListControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );


    console.log('----------------- Date Time ---------------------------')
    console.log(new Date());
    let utcDate = this.utcDatePipe.transform(new Date());
    console.log(utcDate);

  }


  handlePaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;

    if (clipboardData) {
      const items = Array.from(clipboardData.items);

      for (const item of items) {
        if (item.type.indexOf('image') !== -1) {
          const file = item.getAsFile();

          if (file) {
            // Check the size of the image
            if (file.size > 1 * 1024 * 1024) { // 1MB limit
              event.preventDefault();
              console.error('Image size exceeds 1MB');
              this.toaster.error('Image size exceeds 1MB');
              // Display an error message in your application
              return;
            }

            // Create an image element to check its dimensions
            const img = new Image();

            img.onload = () => {
              // Check the width and height of the image
              if (img.width < 200 || img.height < 100) { // Adjust the size limit as needed
                event.preventDefault();
                console.error('Image dimensions less or equel 200px width or 100px height. Image is  pasted.');
                // Display an error message in your application
              } else {
                // Resize the image to a maximum of 100x100 pixels (adjust as needed)
                const canvas = document.createElement('canvas');
                canvas.width = 200;
                canvas.height = 100;
                const ctx = canvas.getContext('2d');
                ctx?.drawImage(img, 0, 0, 200, 100);

                // Convert the resized image to a data URL
                const resizedImageDataUrl = canvas.toDataURL(file.type);

                // Clear the editor content before inserting the resized image
                // this.kendoEditor.value = '';

                // Insert the resized image into the editor
                this.kendoEditor.exec('insertImage', {
                  src: resizedImageDataUrl,
                  alt: 'Resized Image',
                });

                this.removeLargeImages();
              }
            };

            // Set the image source to the file URL
            img.src = URL.createObjectURL(file);
          }
        }
      }
    }
  }



  removeLargeImages() {

    setTimeout(() => {
      // Parse the editor content to find and remove large images
      const parser = new DOMParser();
      const doc = parser.parseFromString(this.composeForm?.value?.message, 'text/html');
      // Update the message field with the modified HTML
      const images = doc.querySelectorAll('img');

      images.forEach((img) => {
        this.checkImageSizeAndRemove(img);
      });
      setTimeout(() => {
        this.composeForm.patchValue({ message: doc.body.innerHTML });
      }, 140);
    }, 400);

  }

  checkImageSizeAndRemove(img: HTMLImageElement) {
    const reader = new FileReader();
    const imageSrc = img.src;

    if (imageSrc && (imageSrc.startsWith('https://') || imageSrc.startsWith('http://'))) {
      img.remove();
    } else {
      reader.onload = (e) => {
        const dataURL = e.target?.result as string;
        // Create a new Image object to get the image dimensions
        const tempImage = new Image();
        tempImage.src = imageSrc;
        tempImage.onload = () => {
          const fileSize = this.getDataURLSize(dataURL); // Get size of data URL in bytes
          const imageWidth = tempImage.width; // Image width in pixels
          const imageHeight = tempImage.height; // Image height in pixels 
          if ((fileSize > 1000000 || fileSize == 0 || imageWidth > 200 || imageHeight > 100)) {
            // Image is larger than 1MB or has width/height greater than 100px, remove it
            img.remove();
          }
        };
      };

      reader.readAsDataURL(this.dataURLtoBlob(imageSrc));
    }
  }


  getDataURLSize(dataURL: string): number {
    const base64String = dataURL.split(',')[1];
    const padding = (base64String.length % 4 === 0) ? 0 : (4 - base64String.length % 4);
    const fileSize = (base64String.length + padding) / 4 * 3;
    return fileSize;
  }

  dataURLtoBlob(dataURL: string): any {
    const parts = dataURL.split(',');
    const mime = parts?.[0]?.match(/:(.*?);/)?.[1];
    const b64Data = parts[1];
    if (b64Data) {
      const byteCharacters = atob(b64Data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: mime });
    } else {
      return new Blob();
    }



  }


  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.patientList.filter((names: any) => names.toLowerCase().includes(filterValue));
  }

  get f() { return this.composeForm.controls }

  setSelectedMailType(inbox: any) {
    this.mailboxServie.selectedMailBoxType = inbox;
  }
  calculateDateDiff = (dateOfReceived: any) => {

    var startDate = new Date(dateOfReceived);
    // user not pass endingDate then set current date as end date.
    let endingDate = new Date();

    let endDate = new Date(endingDate);
    // chack start date and end date and base on condication alter date.
    if (startDate > endDate) {
      var swap = startDate;
      startDate = endDate;
      endDate = swap;
    }
    // This is for leap year.
    const startYear = startDate.getFullYear();
    const february =
      (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0
        ? 29
        : 28;
    const daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    let yearDiff = endDate.getFullYear() - startYear;
    let monthDiff = endDate.getMonth() - startDate.getMonth();
    if (monthDiff < 0) {
      yearDiff--;
      monthDiff += 12;
    }
    let dayDiff = endDate.getDate() - startDate.getDate();
    let hourDiff = endDate.getHours() - startDate.getHours();
    let minuteDiff = endDate.getMinutes() - startDate.getMinutes();
    let secondDiff = endDate.getSeconds() - startDate.getSeconds();
    if (dayDiff < 0) {
      if (monthDiff > 0) {
        monthDiff--;
      } else {
        yearDiff--;
        monthDiff = 11;
      }
      dayDiff += daysInMonth[startDate.getMonth()];
    }

    return ([
      yearDiff, monthDiff, dayDiff, hourDiff, minuteDiff, secondDiff
    ]);

  }
  getRecievedMessages() {
    console.log(this.userId)
    this.inBoxService.getAllSearchedReceivedMessage(this.userId, 'MessageReceivedOn desc', this.page, this.pagesize, this.isSelectAll, this.suggestedText).subscribe((res: any) => {

      //   for(var i=0; i<res.length ; i++){
      //     let datediff=this.calculateDateDiff(res[i].MessageReceivedOn);
      //     let diffResult= datediff[0] != 0 ? datediff[0]+" year" : datediff[1] != 0 ? datediff[1]+ " month" : datediff[2] != 0 ? datediff[2]+ " day" : datediff[3] != 0 ? datediff[3] + " hour" : datediff[4] != 0 ? datediff[4] + " min" : datediff[5] + " sec";
      //     res[i].MessageReceivedOn= diffResult;
      // }
      this.mailboxServie.gridView.push(...res);
    })
  }

  /* get sent messages */
  getSentMessages() {
    this.inboxService.getSentMessage(this.userId, 'MessageSentOn desc', this.page, this.pagesize, this.isSelectAll, this.suggestedText).subscribe((res: any) => {
      this.mailBoxService.sentData = res;
    })
  }

  backToInbox = () => {
    //this.mailBoxService.selectedMailBoxType = 'Draft'
    this.router.navigate(['/pages/navigate-routes/Inbox']);
    //this.reloadCurrentRoute();
  }

  // Inbox count
  inboxMessageCount = () => {
    this.inboxService.GetAllReceivedMessageCount(this.userId).subscribe((data: any) => {
      this.inboxCount = data;
      console.log(this.inboxCount)
    })
  }

  // Getting Health center Data
  GetHealthCenterDetails() {
    this.userId = this.tokenService.userId();
    this.inBoxService.getHealthCenterDetails(this.userId).subscribe(data => {
      this.healthCenterList = data;
      console.log(this.healthCenterList, "health center")
      if (this.healthCenterList.length > 0) {
        this.composeForm.get('healthCenter')?.setValue(this.healthCenterList[0].PracticeManagerId)
        this.isBPPms = this.healthCenterList[0].IsBPPms;

        if (this.isBPPms) {
          const attachmentControl = this.composeForm.get('Attachment');
          attachmentControl?.setValidators([Validators.required]);
        }

        this.getLocations();
        // this.getLocations(this.healthCenterList[0]);
        // this.getPatientListByLocality();
        this.getAllUsersList();
      }
      if (this.healthCenterList.length === 0) {
        this.isLoadComplete = true;
        this.nomessages = true;
      }
    })
  }

  // Getting Locations Data
  getLocations() {
    let selectedHealthCenter: any = this.healthCenterList.find((center: any) => center.PracticeManagerId == this.composeForm.value.healthCenter)
    this.practiceAdmin = selectedHealthCenter.PracticeManagerId;
    this.receiveMsgWhenStaffOutOfOfficeEnabled = selectedHealthCenter.ReceiveMsgWhenStaffOutOfOfficeEnabled;
    this.inBoxService.GetPracticeLocations(this.practiceAdmin).subscribe(res => {
      this.locations = res;
      console.log(this.locations, "locations", this.locations[0].LocationName);
      if (this.locations.length > 0) {
        this.composeForm.get('location')?.setValue(this.locations[0]?.LocationId)
        this.getServiceTypes();
        // this.getServiceTypes(this.locations[0].LocationId);
      }
    })
  }

  // Getting Service types
  getServiceTypes() {
    let locationId: number = this.composeForm.value.location
    this.inBoxService.GetServiceType(this.practiceAdmin, locationId, this.userId).subscribe(res => {
      this.serviceTypes = res;
      this.isLoadComplete = true;
      console.log(this.serviceTypes, "servicetype")
      if (this.serviceTypes.length > 0) {
        this.composeForm.get('serviceName')?.setValue(this.serviceTypes[0]?.MessageTypeID);
        this.composeForm.get('subject')?.setValue(this.serviceTypes[0]?.MessageCode);
        //this.getAllRolesByPractice(this.practiceAdmin);
        this.checkingIsRRP(this.serviceTypes[0]);
        this.getServiceTypeByBillable(this.serviceTypes[0]?.MessageTypeID);
      }
    })
    this.locationsId = locationId;
  }

  subjectBinding = (args: any) => {
    this.composeForm.get('toUser')?.setValue('');
    this.composeForm.get('role')?.setValue(this.Roles[0]?.RoleId);
    this.composeForm.get('toUserDefault')?.setValue('');
    // this.composeForm.get('subject')?.setValue('');

    //  this.getServiceTypeByBillable(args.MessageTypeID);
    //console.log(args.MessageCode);
    this.composeForm.get('subject')?.setValue(args.MessageCode);
    //this.getServiceTypeByLocation(args.MessageTypeID,this.locationsId);

  }

  getServiceTypeByBillable(messageTypeId: any) {
    //getService type by message Id
    this.inboxService.getServiceTypeByBillable(messageTypeId).subscribe(res => {
      this.selectedServiceTypeData = res;
    });

  }

  // Is RRP
  checkingIsRRP = (service: any) => {
    console.log(service)
    this.composeForm.patchValue({ "termsconditions": false })
    this.messageTypeId = service?.value || service?.MessageTypeID;
    // this.getToUserDetails(service.RoleId)
    applicationHttpClientCreator(this.http).Post(`${URLConstants.isRRP}${this.messageTypeId}`, {
    }).subscribe((rrp: any) => {
      console.log(rrp);

      if (rrp == true) {
        this.getPatientMedicationList();
      } else {
        this.getServiceTypeByLocation(this.messageTypeId, this.locationsId);
        // this.getAllRolesByPractice(this.practiceAdmin);
      }
      this.subjectBinding(service);
    })
  }

  // Get Patient Medication List
  getPatientMedicationList = () => {
    applicationHttpClientCreator(this.http).Get(`${URLConstants.rrpMedicationList}${this.userId}&PracticeManagerId=${this.practiceAdmin}&LocationId=${this.locationsId}`, {
    }).subscribe((data: any) => {
      console.log(data)
    })
  }

  //Getting service type by locations when rrp is false
  getServiceTypeByLocation = (messageTypeId: any, locationsId: any) => {
    applicationHttpClientCreator(this.http).Get(`${URLConstants.getServiceTypeByLocation}${messageTypeId}&LocationId=${locationsId}&UserID=${this.userId}`, {
    }).subscribe((data: any) => {
      console.log(data)
      if (data && data.length > 0) {
        this.serviceTypelocationDetails = data;
        this.showInformation = (data[0]?.IsBillable && data[0]?.BillableCost) ? true : false;
        if (data[0]?.PaymentOption == 'P') {
          this.paymentMethod = "Pay at Heath Center"
        }
        else if (data[0]?.PaymentOption == 'H') {
          this.paymentMethod = "Pay at Heath Center or Pay Online"
        }
        else if (data[0]?.PaymentOption == 'O') {
          this.paymentMethod = "Pay Online"
        }
        this.selectedServiceTypeDataterms = this.serviceTypelocationDetails[0].terms;
        this.selectedServiceTypeDataMessageDescription = this.serviceTypelocationDetails[0].MessageDescription;
        this.RoleIDforservice = data[0].RoleId;
        // if (this.serviceTypelocationDetails[0].RoleId == '00000000-0000-0000-0000-000000000000' && this.serviceTypelocationDetails[0].recipientid != '00000000-0000-0000-0000-000000000000') {

        if (this.serviceTypelocationDetails[0].recipientid != '00000000-0000-0000-0000-000000000000') {
          this.composeForm.get('toUser')?.setValue(data[0].recipientid);
          if (data[0].recipientid != '00000000-0000-0000-0000-000000000000')
            this.composeForm.get('toUserDefault')?.setValue(data[0].Recipient);
          else {
            this.composeForm.get('toUserDefault')?.setValue('');
            // this.composeForm.get('')
          }

          this.composeForm.get('role')?.setValue(data[0].RoleId);

          this.getStaffRoleRemoved(data[0].recipientid, data[0].MessageTypeID);
        }
        else {
          // this.getToUserDetails(this.serviceTypelocationDetails[0].RoleId);
          this.getAllRolesByPractice(this.practiceAdmin);
        }

      }
      if (this.serviceTypelocationDetails[0].isExtCommService == 1) {
        // var sIsReqAcc = getQSParamByName('ram'); //  Need to add Query Params
        this.getPatientPracticeForExtComm();
      }
    })
  }
  getStaffRoleRemoved(messageTypeID: any, recipientid: any) {
    applicationHttpClientCreator(this.http).Get(`${URLConstants.GetStaffRoleRemoved}${messageTypeID}&MessageTypeId=${recipientid}`, {
    }).subscribe((data: any) => {
      if (data) {
        this.isStaffRemoved = data;
      }
      // var sIsReqAcc = getQSParamByName('ram'); //  Need to add Query Params
      // if (sIsReqAcc != '' && sIsReqAcc != null && sIsReqAcc != 'null') {
      // }
    })
  }
  //Getting Patient Practice data for External Communication
  getPatientPracticeForExtComm = () => {
    applicationHttpClientCreator(this.http).Get(`${URLConstants.getPatientPracticeForExtComm}${this.userId}`, {
    }).subscribe((data: any) => {
      console.log(data)
      // var sIsReqAcc = getQSParamByName('ram'); //  Need to add Query Params
      // if (sIsReqAcc != '' && sIsReqAcc != null && sIsReqAcc != 'null') {
      // }
    })
  }

  // Getting roles
  getAllRolesByPractice(practiceManagerId: any) {
    this.inBoxService.GetAllRolesByPractice(practiceManagerId, this.userId).subscribe(response => {
      this.Roles = response;
      console.log(this.Roles, "roles")
      this.composeForm.get('role')?.setValue(this.RoleIDforservice);
      if (this.serviceTypelocationDetails[0].RoleId != '00000000-0000-0000-0000-000000000000') {
        this.getToUserDetails('intial')
        // this.getToUserDetails(this.serviceTypelocationDetails[0].RoleId,'intial')
      }
      else {
        this.getToUserDetails('intial');
        // this.getToUserDetails(this.Roles[0]?.RoleId,'intial');
      }
      if (this.Roles.length > 2) {
        if (this.Roles[2].RoleName) {
          this.getAllUsersList();
        }
      }

    })
  }

  showDialog(outOfOffice: any, time: any, days: any) {
    let messageContent = '';
    this.disableSubmit = false;
    if (outOfOffice == 1) {
      messageContent = `You are sending the message outside of office hours. The practice operates from ${time?.toLowerCase()}, ${days}. Our team will not be able to respond until our next business day. However, please rest assured that your message can still be sent, and we will attend to it promptly on the next business day. If you have an urgent matter that requires immediate action, please call 111 or contact the Health Centre.`;
    } else if (outOfOffice == 2) {
      messageContent = `You are sending the message outside of office hours. The practice operates from ${time?.toLowerCase()}, ${days}. Our team will not be able to respond until our next business day, and unfortunately, the message cannot be sent at this time.If you have an urgent matter that requires immediate action, please call 111 or contact the Health Centre.`;
      this.disableSubmit = true;
    } else if (outOfOffice == 3) {
      messageContent = `You are sending the message outside of office hours. The practice operates from ${time?.toLowerCase()}, ${days}. Our team will not be able to respond until our next business day. Please note that this message will be delivered during regular work hours. If you have an urgent matter that requires immediate action, please call 111 or contact the Health Centre.`;
    } else {
      return false;
    }

    this.alertDialog = {
      title: 'Information',
      message: messageContent,
      alertType: AlertType.None,
      okButtonText: this.translate.instant('OK')
    };
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: this.alertDialog
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
      }
    });

  }

  //Getting To User Details
  getToUserDetails(value: string) {
    let roleId: string = this.composeForm.value.role;
    this.toUser = [];
    this.selectedNamecheck = false;
    this.patientList = [];
    this.selectedRole = this.Roles.filter((x: any) => x.RoleId == roleId);
    this.userRoleId = roleId;
    if (this.selectedRole[0]?.RoleName != 'Patient') {
      this.inboxService.GetAllStaff(this.practiceAdmin, this.locationsId, roleId).subscribe(res => {
        this.toUser = res;
        setTimeout(() => {
          if (this.toUser.length <= 0) {
            this.mailBoxService.showDialogWhenNoStaffAvailable(this.locationMatSelect);
            this.composeForm.controls['location'].setErrors({ 'valid': false });
            this.composeForm.controls['location'].markAsDirty();
          }
        }, 500)
      })
    }
    if (value == 'change') {
      // this.UserSearch='';
      this.composeForm.get('toUser')?.setValue('');
    }
  }

  // Getting Users List by selected role as Patient
  getAllUsersList = () => {
    this.inBoxService.getAllUsersList(this.userId).subscribe(res => {
      this.userData = res;
      console.log(this.userData, "userData list");
      if (this.userData.length > 0) {
        for (var i = 0; i < this.userData.length; i++) {
          if (this.userData[i]["PracticeManagerId"].toString() == this.composeForm.value.healthCenter) {
            this.getAutoSuggestNameList();
          }
        }
      }
    })
  }

  getAutoSuggestNameList = () => {
    let nameList = this.composeForm.value.toUser;
    let practiceManagerId = this.composeForm.value.healthCenter;
    if (nameList) {
      applicationHttpClientCreator(this.http).Get(`${URLConstants.toUserAutosuggestList}?nameType=${"RU"}&practiceId=${practiceManagerId}&suggestedText=${nameList}`, {
      }).subscribe((data: any) => {
        this.patientList = data;
        console.log(this.patientList, "patient list")
      })
    }
    else {
      this.patientList = [];
    }
  }

  selectedName = (list: any) => {
    this.userDetails = this.patientList.filter((x: any) => x.names == list);
    if (this.userDetails.length != 0) {
      this.userSelectedCheck = true;
      this.composeForm.get('toUser')?.setValue(this.userDetails[0].names);
      this.selectedNamecheck = true;
      this.selectedUserId = this.userDetails[0].UserID;
    } else {
      this.userSelectedCheck = false;
      this.composeForm.get('toUser')?.setValue(null)
    }
  }

  // Attach Files Dialog popup and getting files from that dialog
  attachFileDialog() {
    const dialogRef = this.dialog.open(AttachfileDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
      this.files = result;
      this.addedFiles.push(...this.files)
      if (this.addedFiles.length > 3) {
        this.filelengthError = true;
      } else 
      { this.filelengthError = false 
        this.composeForm.get('Attachment')?.setValue(this.addedFiles)

      }
    });
  }


  // Remove selected files
  remove(i: number) {
    this.addedFiles.splice(i, 1);
    if (this.addedFiles.length == 3) {
      this.filelengthError = false;
    }
    this.composeForm.get('Attachment')?.setValue(this.addedFiles)
  }

  // Create or generate XML request
  constructAttachFiles(strFiles: string) {
    this.generateXML = ("<ROOT>") + ""
    let file = this.filesarray.split(";");
    for (var index = 0; index < file.length - 1; index++) {
      let filedetails: string[];
      filedetails = file[index].split(",");
      if (filedetails[3] === "1") {
        this.generateXML += ("<Attachment>") + "" +
          ("<DestName>" + this.setCdata(filedetails[0]) + "</DestName>") + "" +
          ("<SourceName>" + this.setCdata(filedetails[1]) + "</SourceName>") + "" +
          ("<Size>" + filedetails[2] + "</Size>") + "" +
          ("</Attachment>") + ""
      }
    }
    this.generateXML += ("</ROOT>");
    return this.generateXML;

  }

  setCdata(strFiles: string) {
    return "<![CDATA[" + strFiles + "]]>";
  }


  composeNewMessage(attachmentXML: string) {
    var date = new Date();
    var utcTime = date.getTime() + (date.getTimezoneOffset() * 60000);
    var timeOffset = 12;
    let userProfile = this.tokenService.userProfile();
    console.log(attachmentXML)
    let toUserId = ''
    if (this.selectedNamecheck) {
      toUserId = this.selectedUserId;
    } else {
      toUserId = this.composeForm.value.toUser ? this.composeForm.value.toUser : '';
    }
    var sentMessageDeatils = {
      FromUserId: this.userId,
      // ToUserId:  this.composeForm.value.toUser ? this.composeForm.value.toUser : "",//this.toUser[0].UserID //this.tousername
      "ToUserID": toUserId,
      MessageTypeID: this.composeForm.value.serviceName ? this.composeForm.value.serviceName : 1,//this.serviceTypes[0].MessageTypeID
      Subject: this.composeForm.value.subject,
      MessageBody: this.composeForm.value.message == null ? "" : this.composeForm.value.message,
      IsRead: false,
      IsSent: true,
      IsTrash: false,
      MessageSentOn: moment(this.utcDatePipe.transform(new Date())).format("YYYY-MM-DD HH:mm:ss"),//new Date()
      MessageReceivedOn: moment(this.utcDatePipe.transform(new Date())).format("YYYY-MM-DD HH:mm:ss"), // moment(new Date((utcTime + (3650000 * timeOffset)-600000))).format("YYYY-MM-DD HH:mm:ss"), //new Date(),
      //MessageReceivedOn: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), //new Date(),
      AttachmentXML: attachmentXML ? attachmentXML : "<ROOT><Attachment></Attachment></ROOT>",
      isRequestRepeatPresc: false,
      isBillable: true,
      RRPXML: "RRPXML",
      Cost: this.serviceTypelocationDetails[0]?.BillableCost || 0,
      isReply: false,
      Status: "A", // we are sending default, but this not using in compose email
      PracticeAdmin: this.practiceAdmin ? this.practiceAdmin : "",
      PaymentType: "H",// we are sending default, but this not using in compose email
      ServiceRefNo: this.composeForm.value.serviceName != null && this.composeForm.value.serviceName != undefined ? this.composeForm.value.serviceName : "",
      LocationId: this.locationsId ? this.locationsId : "", //this.locationname
      ParentID: 0,
      InboxDraftMessageID: this.composeForm.value.inboxDraftMessageID ? this.composeForm.value.inboxDraftMessageID : 0,
      MessageCategoryID: 1,
      PMSServiceCode: "",
      OnlineTransactionID: "",
      SentBy: this.userId,
      Email: userProfile.Email
    }

    console.log(sentMessageDeatils);
    this.inBoxService.composeNewMail(sentMessageDeatils)
      .subscribe(res => {
        console.log(res, "send message")
        if (res) {
          this.composeForm.reset();
          // this.destroy.next();
          // this.destroy.complete();
          // this.mailboxServie.selectedMailBoxType='';
          // this.getRecievedMessages();
          // this.getSentMessages();
          // this.inboxMessageCount();
          // this.mailboxServie.inboxMsgCount = this.inboxCount;
          this.toaster.success(
            this.translate.instant('Message sent successfully'));

          this.addedFiles = [];
          this.mailboxServie.selectedMailBoxType = 'Inbox';
        }
      }, (err: any) => {
        this.toaster.warning(err.error.Message || err.error.message)
      })
    this.router.navigate(['/pages/navigate-routes/Inbox']);
  }

  saveAsDraft(attachmentXML: string) {
    this.draftAttachmentXML = attachmentXML;
    this.alertDialog = {
      title: 'Information',
      message: this.translate.instant('MessageSavedAsDraftOnly'),
      alertType: AlertType.None,
      okButtonText: this.translate.instant('OK')
    };
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: this.alertDialog
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result == true) {
        let toUserId = ''
        if (this.selectedNamecheck) {
          toUserId = this.selectedUserId;
        } else {
          toUserId = this.composeForm.value.toUser ? this.composeForm.value.toUser : '';
        }
        const draftObject = {
          InboxDraftMessageID: this.composeForm.value.inboxDraftMessageID ? this.composeForm.value.inboxDraftMessageID : 0,
          FromUserID: this.userId,
          //ToUserID: this.composeForm.value.toUser ? this.composeForm.value.toUser : '',
          ToUserID: toUserId,
          MessageTypeID: this.composeForm.value.serviceName ? this.composeForm.value.serviceName : 1,
          Subject: this.composeForm.value.subject,
          MessageBody: this.composeForm.value.message != null && this.composeForm.value.message != undefined && this.composeForm.value.message != "" ? this.composeForm.value.message : "",
          IsCompose: true,
          IsReply: false,
          ServiceRefNo: this.composeForm.value.serviceName != null && this.composeForm.value.serviceName != undefined ? this.composeForm.value.serviceName : 1,
          PracticeManagerId: this.practiceAdmin ? this.practiceAdmin : '',
          LocationId: this.locationsId ? this.locationsId : '',
          InboxMessageID: 0,
          RoleID: this.userRoleId ? this.userRoleId : '00000000-0000-0000-0000-000000000000',
          attachments: attachmentXML ? attachmentXML : "",
          IsSent: false,
          // TermsAndCondition: this.composeForm.value.termsconditions
        }
        console.log(draftObject, "*************************draftObject**********************************");
        console.log(this.composeForm);
        this.inBoxService.SaveDraftMessage(draftObject).subscribe((res: any) => {
          console.log(res)
          if (res > 1) {
            this.toaster.success(
              this.translate.instant('draft saved successfully'));
            this.composeForm.reset();
            this.mailboxServie.selectedMailBoxType = 'Drafts';
            this.router.navigate(['/pages/navigate-routes/Drafts']);
            this.addedFiles = [];
            this.inboxService.getDraftMessageCount(this.userId).subscribe((data: any) => {
              this.mailBoxService.draftMessageCount = data;
              this.draftCount = data;
              console.log(this.draftCount)
              this.reloadCurrentRoute();
            })

            // this.mailboxServie.selectedMailBoxType = 'Drafts';
            // this.router.navigate(['/pages/navigate-routes/Drafts']);
          }
        }, (err: any) => {
          this.toaster.warning(err.error.Message || err.error.message)
        })
      }
    });
  }

  public valueChange(value: any): void {

    this.messageBoxValue = value.replace(/<[^>]*>/g, '');
    this.messageBoxValue = this.messageBoxValue.trim();
  }
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
      console.log(currentUrl);
    });
  }

  // Compose a new mail api call
  sendMesssage(value: any) {
    if (this.addedFiles && this.addedFiles.length <= 3) {
      const frmData = new FormData();
      for (var i = 0; i < this.addedFiles.length; i++) {
        frmData.append("fileUpload", this.addedFiles[i]);
      }
      this.inBoxService.uploadFiles(frmData).subscribe((result: any) => {
        console.log("uploadstatus", result)
        this.filesarray = result
        console.log("files", this.filesarray)
        let attachmentXML = this.constructAttachFiles(this.filesarray);
        console.log(attachmentXML);
        if (value !== 'Drafts') {
          this.composeNewMessage(btoa(attachmentXML));
          this.getRecievedMessages();
          this.mailboxServie.selectedMailBoxType = value;
          console.log(this.mailboxServie.selectedMailBoxType)
        } // btoa (encode files data)
        else {
          this.saveAsDraft(btoa(attachmentXML));
          // this.router.navigate(['/pages/navigate-routes/Inbox']);
          // this.mailboxServie.selectedMailBoxType = value;
        }
      })
    }
    else {
      if (value !== 'Drafts') {
        this.composeNewMessage("");
      }
      else if (value == 'Drafts' && this.addedFiles.length <= 3) {
        this.saveAsDraft("")
      }
    }
  }



  getUserSettings = () => {
    const userId = this.tokenService.userId();
    this.userData = this.tokenService.userProfile();
    console.log('Email', this.userData.Email)
    applicationHttpClientCreator(this.http).Get(`${URLConstants.getHomeUserSettings}${userId}&email=` + this.userData.Email, {
    }).subscribe((data: any) => {
      this.userSettings = data;
      console.log('Settings', this.userSettings);
      if (this.userSettings.length > 0) {
        let bodyContent = this.userSettings[0].Signature;
        this.messageBoxValue = this.userSettings[0].Signature;
        if (this.userSettings[0].LinkedMessage && this.userSettings[0].LinkedMessage != '') {
          if (bodyContent && bodyContent != '') {
            bodyContent = this.userSettings[0].LinkedMessage + '<br/>' + bodyContent;
          }
          else {
            bodyContent = this.userSettings[0].LinkedMessage;
          }
        }
        this.composeForm.patchValue({
          message: bodyContent
        });
      }
    }, (err) => {
      console.log(err);
      return null;
    });
  }


  autoSaveDraft() {
    if (this.composeForm.value.subject != '' || this.composeForm.value.toUser != null) {
      // this.composeForm.valueChanges.pipe(
      //  takeUntil(this.destroy),
      //   //debounceTime(10000), // one event every 60000 milliseconds
      //   distinctUntilChanged(),
      // )
      // .subscribe(() => {
      if (this.composeForm.dirty && this.addedFiles.length <= 3) {
        let toUserId = ''
        if (this.selectedNamecheck) {
          toUserId = this.selectedUserId;
        } else {
          toUserId = this.composeForm.value.toUser ? this.composeForm.value.toUser : '';
        }
        const draftObject = {
          InboxDraftMessageID: this.composeForm.value.inboxDraftMessageID ? this.composeForm.value.inboxDraftMessageID : 0,
          FromUserID: this.userId,
          // ToUserID: this.composeForm.value.toUser ? this.composeForm.value.toUser : '',
          ToUserId: toUserId,
          MessageTypeID: this.composeForm.value.serviceName ? this.composeForm.value.serviceName : 1,
          Subject: this.composeForm.value.subject,
          MessageBody: this.composeForm.value.message == null ? "" : this.composeForm.value.message,
          IsCompose: true,
          IsReply: false,
          ServiceRefNo: this.composeForm.value.serviceName != null && this.composeForm.value.serviceName != undefined ? this.composeForm.value.serviceName : 1,
          PracticeManagerId: this.practiceAdmin ? this.practiceAdmin : '',
          LocationId: this.locationsId ? this.locationsId : '',
          InboxMessageID: 0,
          RoleID: this.userRoleId ? this.userRoleId : '',
          attachments: this.draftAttachmentXML ? this.draftAttachmentXML : "",
          IsSent: false,
          // TermsAndCondition: this.composeForm.value.termsconditions
        }
        this.inBoxService.SaveDraftMessage(draftObject).subscribe((res: any) => {
          console.log(res)
          if (res > 1) {
            this.composeForm.get('inboxDraftMessageID')?.setValue(res);
            this.saveIndicator = 'Draft saved at' + " " + `${formatDate(new Date().toLocaleString("en-US", { timeZone: 'Australia/Sydnet' }), "hh:mm:ss a", 'en-US', 'Australia/Sydney')}`;

          }

        }, (err: any) => {
          this.toaster.warning(err.error.Message || err.error.message)
        })
      }
      //  });
    }
  }
  clear() {
    this.selectedUserId = '';
    this.composeForm.get('toUser')?.setValue(null)
  }


  backspaceCheck(event: KeyboardEvent) {
    const key = event.keyCode || event.charCode;
    if (this.userSelectedCheck) {
      if (key === 8 || key === 46) {
        this.clear();
        this.userSelectedCheck = false;
      }
    }
  }
  handleFilter(event: any) {
    let practiceManagerId = this.composeForm.value.healthCenter;
    if (event != '') {
      applicationHttpClientCreator(this.http).Get(`${URLConstants.toUserAutosuggestList}?nameType=${"RU"}&practiceId=${practiceManagerId}&suggestedText=${event}`, {
      }).subscribe((data: any) => {
        this.patientList = data;
        console.log(this.patientList, "patient list")
      })
    }

  }
  ngOnDestroy() {
    // if(this.liveStatusSub!=null&&this.liveStatusSub!=undefined&&this.liveStatusSub!="")
    this.liveStatusSub.unsubscribe();
    this.inboxService.getDraftMessageCount(this.userId).subscribe((data: any) => {
      this.mailboxServie.mailstype[1].count = data;
    })
    this.destroy.next();
    this.destroy.complete();
  }

  activationCode(): void {
    this.router.navigate(['/myaccount/profile']);
  }

  hideinbox(): void {
    this.mailboxServie.hideMailBox();
  }

  checkProviderAvailability() {
    let toUserDetails = this.composeForm.value.toUser;
    this.staffOutOfOffice = false;
    console.log(toUserDetails)
    const isCurrentDayAvailable = this.inboxService.checkCurrentDayAvilable(toUserDetails?.WorkDaysOutOfOffice);
    const isCurrentTimeInRange = this.inboxService.checkCurrentTimeAvailable(toUserDetails?.StartTimeOutOfOffice, toUserDetails?.EndTimeOutOfOffice);
    console.log(`Is current day available? ${isCurrentDayAvailable}`);
    console.log(`Is current time in range? ${isCurrentTimeInRange}`);
    const days = this.inboxService.convertDays(toUserDetails?.WorkDaysOutOfOffice);

    // Extracted statements
    const isWorkHour = toUserDetails?.IsWorkHoursOutOfOffice;
    const isTimeOutOfRange = isCurrentDayAvailable ? !isCurrentTimeInRange : true;

    // Final statement
    const isShowPopup = isWorkHour ? isTimeOutOfRange : false;

    const time = toUserDetails?.StartTimeOutOfOffice + ' to ' + toUserDetails?.EndTimeOutOfOffice;
    if (isShowPopup) {
      this.showDialog(this.toUser[0].MessageTypeOutOfOffice, time, days);
    } else {
      if (toUserDetails?.isOutOfOfficeEnabled) {
        let msg = '';
        if (this.receiveMsgWhenStaffOutOfOfficeEnabled) {
          msg = 'ComposeOutOfOfficeAllow';
        }
        else {
          this.staffOutOfOffice = true;
          msg = 'ComposeOutOfOfficeRestrict';
        }

        this.alertDialog = {
          title: 'Info',
          message: this.translate.instant(msg),
          alertType: AlertType.None,
          okButtonText: this.translate.instant('OK')
        };
        const dialogRef = this.dialog.open(AlertDialogComponent, {
          data: this.alertDialog
        });
        dialogRef.afterClosed().subscribe((result: any) => {
          if (result == true) {
          }
        });
      }
    }


  }
  acceptCheckBox(event: Event) {
    if (this.composeForm.value.termsconditions == false && this.isPatient) {
      event.preventDefault();
      this.showTerms = true;
    }
  }
  agreeTerms() {
    this.composeForm.patchValue({ termsconditions: true })
    this.showTerms = false;
  }
  cancelTerms() {
    this.composeForm.patchValue({ termsconditions: false })
    this.showTerms = false;
  }
  closeSelectedMail() { }

  acknowledge() {
    this.showInformation = false;
    this.isAcknowledge = true;
    this.serviceName.focus();
  }

}





