<!-- ------------------------------ header desktop view --------------------------------- -->
<div class="desktop-view-1024 m-b-20">
  <div class="filter-by-index page-header header-buttons m-b-sm-0 p-lt-xs-20">
      <h1>{{'Draft'| translate}}</h1>
      <div class="icon-container justify-content-end">
        <mat-form-field appearance="outline" class="search-text-icon searchbox-drafts has-success">
          <mat-label>{{'Search Messages'|translate}}</mat-label>
          <input matInput placeholder="{{'Search Messages'|translate}}" [(ngModel)]="searchTexts"
              name="searchTexts" (change)="Searchkey(searchTexts)" style="height:26px !important">
              <button mat-button  mat-icon-button *ngIf="searchTexts">
                <i class="icon-cent-search-popular icon-subfld" matSuffix></i>
            </button>
             <mat-icon class="icon-subfld"  *ngIf="searchTexts" class="clear-icon-close"
                  (click)="clearSearchData(searchTexts)" >close</mat-icon>
      </mat-form-field>

          <button mat-button (click)="deleteAllDrafts()" class="btn-primary-rect m-l-15" style="top:2px">
            <!-- <mat-icon matTooltip="Delete All" class="m-r-5">delete</mat-icon> -->
            <mat-icon matTooltip="Delete All" class="m-r-5">delete_outline</mat-icon>
              <span>{{'Delete All'|translate}}</span>
          </button>
          <!-- <span><img src="../../../../assets/images/icons-text/settings.svg" (click)="opensettings()" style="position: relative;top:2px;left:8px;" matTooltip="Message settings"></span> -->
      </div>
  </div>
</div>

<!-- ------------------------------header mobile-view-1024--------------------------------- -->
<div class="mobile-view-1024" *ngIf="draftMessage == false">
  <div class="filter-by-index page-header header-buttons m-b-sm-0 p-lt-xs-20">
    <h1>{{'Draft'| translate}}</h1>
  <div class="icon-container">
    <button (click)="openCompose()" mat-button class="mobile-view-1024 txt-color"><i
      class="icon-cent-pencil-box-outline-text"></i>COMPOSE</button>
      <button (click)="deleteAllDrafts()" mat-button class="mobile-view-1024 txt-color"><mat-icon>delete_outline</mat-icon><span class="m-l-5">{{'DELETE ALL'|translate}}</span></button>
  </div>
</div>
<div class="icon-container">
  <mat-form-field appearance="outline" class="search-text-icon p-lt-xs-20 searchbox-mobile-drafts has-success">
      <mat-label>{{'Search Messages'|translate}}</mat-label>
      <input matInput placeholder="{{'Search Messages'|translate}}" [(ngModel)]="searchTexts"
          name="searchTexts" (change)="Searchkey(searchTexts)" style="height:26px !important">
      <!-- <button mat-button *ngIf="searchTexts" matSuffix mat-icon-button aria-label="Clear" class="clear-icon-close"
          (click)="clearSearchData(searchTexts)">
          <mat-icon class="icon-subfld">close</mat-icon>
      </button>
      <i class="icon-cent-search-popular icon-subfld search-icon-close-mobile" *ngIf="searchTexts" matSuffix></i> -->
      <button mat-button *ngIf="searchTexts" matSuffix mat-icon-button aria-label="Clear" class="clear-icon-close"
      (click)="clearSearchData(searchTexts)">
      <mat-icon class="icon-subfld1">close</mat-icon>
  </button>
  <i class="icon-cent-search-popular icon-subfld1 search-icon-close-mobile" *ngIf="searchTexts" matSuffix></i>
  </mat-form-field>
</div>
</div>

<!-- ------------------------------Messages list view------------------------------------>

<div class="bg-white" *ngIf="draftsDataDetails.length == 0 && !isLoading">
  <div fxLayout="column wrap" fxLayoutAlign="center center" class="no-msg">
      <div class="text-center">
          <img src="assets/images/message123.svg" alt="mail-box">
          <p>Sorry! No messages found.</p>
      </div>
  </div>
</div>

<!-- ------------------------------content desktop-view-1024--------------------------------- -->
<div fxlayout="row wrap"  class="bg-white drafts-flexbox desktop-view-1024" *ngIf="draftsDataDetails.length>0">
  <div fxflex.lg="40" fxflex.gt-lg="30" fxflex.sm="100" fxflex="100" class="b-r flexbox1-drafts">
    <div class="emailInbox k-slide-tray" *ngIf="draftsDataDetails.length>0">
      <div class="list-wrapper">
        
      <form [formGroup]="checkboxForm">

        <!-- <div class="m-t-20 m-b-10 mail-icons">
          <mat-checkbox class="select-all" matTooltip="Select all message" aria-label="Select All" formControlName="checkboxChecked"
            [checked]="isChecked()" [indeterminate]="isIndeterminate()" (change)="$event ? toggleAll($event) : null">
          </mat-checkbox>
          <span *ngIf="selectedcheckbox.length==0">
            <img src="assets/images/icons-text/Group 5073.svg" class="m-l-15">
          </span> -->
          <!-- <span *ngIf="checkboxForm.value.checkboxChecked || checkboxForm.value.selectedCheckbox|| selectedcheckbox.length>0"> -->
          <!-- <span *ngIf="selectedcheckbox.length>0"> -->
            <!-- <mat-icon matTooltip="move to Archive" class="m-r-5" (click)="archiveAll()">inventory_2</mat-icon>-->
            <!-- <a (click)="archiveAll()" class="m-l-15" matTooltip="Move to Archive"> -->
              <!-- <img src= '../../../../assets/images/Group 5021.svg' alt="reply"> -->
              <!-- <img [src]="imgSrc4" (mouseover)="imgSrc4 = 'assets/images/icons-text/Group 5075.svg'"
                (mouseout)="imgSrc4 = 'assets/images/icons-text/Group 5076.svg'" alt="Archive">
            </a>
          </span> -->
        <!-- </div> -->

        <div class="mails-list-wrapper drafts-listview-desktop"  infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()"
        [scrollWindow]="false">
        <div class="mails-list-wrapper">
        <div class="mail-list-content">
          <div class="messages">
            <div class="emailInbox">
        <mat-card class="m-0" *ngFor="let i=index;let draft of draftsDataDetails">
          <div [ngClass]="{'mail-selected': SelectedMail === i}">
          <mat-card-content class="mat-card-spacer mat-card-contentmail">
            <!-- <mat-checkbox (click)="$event.stopPropagation()" matTooltip="Select message"
            formControlName="selectedCheckbox" (change)="$event ? toggle(draft, $event) : null"
            [checked]="exists(sent)" [disabled]="draft.disabled" [labelPosition]="draft.labelPosition"
            class="m-t-6">
          </mat-checkbox> -->
          <mat-card-header
            (click)="setSelectedMailType(draft.InboxDraftMessageID,i)">
            <div mat-card-avatar class="mat-card-avatar m-t-6">
              <img class="img-fluid img-circle" [src]="profileObject.PhotoURL !=='' ? profileObject.PhotoURL  : 'assets/images/nophoto_unregistered.svg'" alt="circle" onError="this.src='assets/images/nophoto_unregistered.svg'">
            </div>
            <div>
              <mat-card-title class="header-sub header-width">{{draft.ToName}} </mat-card-title>
              <mat-card-title class="text-sub m-t-8">
                {{draft.Subject}}
              </mat-card-title>
              <mat-card-title class="text-sub m-t-8 subject-width">
                <span><b>Service Type</b>: {{draft.MessageCode}}</span>
              </mat-card-title>
            </div>
            <!-- <p class="date-time m-t-2">{{draft.InsertOn | date: 'dd/MM/yyyy  h:mm a'}}</p> -->
            <p class="date-time m-t-2">{{draft.UpdateTimeStamp | localDate}}</p>
            <button class="date-time m-t-27" (click)="$event.stopPropagation()" matTooltip="Delete message" mat-icon-button (click)="draftmovetoArchive(draft.InboxDraftMessageID)">
              <mat-icon>delete_outline</mat-icon>
            </button>
          </mat-card-header>
        </mat-card-content>

      </div>
        </mat-card>
        </div>
        </div>
      </div>
      </div>
      </div>
      </form>
      </div>
    </div>

  </div>



<div fxflex.lg="60" fxflex.gt-lg="70" fxflex.sm="100" fxflex="100"
 class=" d-flex desktop-view-1024 align-items-center justify-content-center detail-part movetodetail flexbox2-drafts">
<div  *ngIf="draftMessage==false && draftsDataDetails.length>0" class=" text-center d-md-none no-selected-msg">
    <!-- <mat-icon  role="img" class="mat-icon notranslate display-5 m-r-15 material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">mail_outline</mat-icon> -->
    <img src="assets/images/message123.svg" alt="mail-box">
    <p>Select an item to read</p>

</div>

  <div *ngIf="draftMessage == true" class="desktop-view-1024" style="width:100%;">
    <app-draftdetail *ngIf="!isMobile && mailboxServie?.messageId" [draftId]="mailboxServie.messageId" id="SelectedMail" [ngClass]="SelectedMail ? 'active' : ''" (onCloseClick)="SelectedMail = false"></app-draftdetail>
  </div>

</div>

</div>


<!-- ------------------------------content mobile-view-1024--------------------------------- -->
<div fxlayout="row wrap"  class="bg-white drafts-flexbox mobile-view-1024" *ngIf="draftsDataDetails.length>0 && draftMessage==false">
  <div fxflex.lg="40" fxflex.gt-lg="30" fxflex.sm="100" fxflex="100" class="b-r flexbox1-drafts">
    <div class="emailInbox k-slide-tray" *ngIf="draftsDataDetails.length>0">
      <div class="list-wrapper">
      <form [formGroup]="checkboxForm">


        <div class="m-t-20 m-b-10 mail-icons">
          <mat-checkbox class="select-all" matTooltip="Select all message" formControlName="checkboxChecked"
            [checked]="isChecked()" [indeterminate]="isIndeterminate()" (change)="$event ? toggleAll($event) : null">
          </mat-checkbox>
          <span *ngIf="selectedcheckbox.length==0">
            <img src="assets/images/icons-text/Group 5073.svg" class="m-l-15">
          </span>
          <!-- <span *ngIf="checkboxForm.value.checkboxChecked || checkboxForm.value.selectedCheckbox|| selectedcheckbox.length>0"> -->
          <span *ngIf="selectedcheckbox.length>0">
            <!-- <mat-icon matTooltip="move to Archive" class="m-r-5" (click)="archiveAll()">inventory_2</mat-icon>-->
            <a (click)="archiveAll()" class="m-l-15" matTooltip="Move to Archive">
              <!-- <img src= '../../../../assets/images/Group 5021.svg' alt="reply"> -->
              <img [src]="imgSrc4" (mouseover)="imgSrc4 = 'assets/images/icons-text/Group 5075.svg'"
                (mouseout)="imgSrc4 = 'assets/images/icons-text/Group 5076.svg'" alt="Archive">
            </a>
          </span>
        </div>

        <div class="mails-list-wrapper drafts-listview-desktop"  infinite-scroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50" (scrolled)="onScroll()"
        [scrollWindow]="false">
        <div class="mails-list-wrapper">
        <div class="mail-list-content">
          <div class="messages">
            <div class="emailInbox">
        <mat-card class="m-0" *ngFor="let i=index;let draft of draftsDataDetails">
          <div [ngClass]="{'mail-selected': SelectedMail === i}">
          <mat-card-content class="mat-card-spacer drafts-avatar">
          <mat-card-header
            (click)="setSelectedMailType(draft.InboxDraftMessageID,i)">
            <div mat-card-avatar class="mat-card-avatar m-t-6">
              <img class="img-fluid img-circle" [src]="profileObject.PhotoURL !=='' ? profileObject.PhotoURL  : 'assets/images/nophoto_unregistered.svg'" onError="this.src='assets/images/nophoto_unregistered.svg'">
            </div>
            <div>
              <mat-card-title class="text-width">{{draft.ToName}}</mat-card-title>
              <mat-card-title class="text-sub m-t-20 m-t-8 subject-width">
                {{draft.Subject}}
              </mat-card-title>
              <mat-card-title class="text-sub m-t-8 m-l-15 subject-width">
              <span><b>Service Type</b>: {{draft.MessageCode}}</span>
            </mat-card-title>
            </div>
            <!-- <p class="date-time m-t-2">{{draft.InsertOn | date: 'dd/MM/yyyy  h:mm a'}}</p> -->
            <p class="date-time m-t-2">{{draft.UpdateTimeStamp | localDate}}</p>
            <button class="date-time m-t-27" (click)="$event.stopPropagation()" matTooltip="Delete message" mat-icon-button (click)="draftmovetoArchive(draft.InboxDraftMessageID)">
              <mat-icon>delete_outline</mat-icon>
            </button>
          </mat-card-header>
        </mat-card-content>

      </div>
        </mat-card>
        </div>
        </div>
      </div>
      </div>
      </div>
      </form>
      </div>
    </div>
  </div>
</div>
<div class="mobile-view-1024" *ngIf="draftMessage == true">
  <app-draftdetail *ngIf="isMobile" [draftId]="mailboxServie.messageId" id="SelectedMail" [ngClass]="SelectedMail ? 'active' : ''" (onCloseClick)="SelectedMail = false"></app-draftdetail>
</div>
