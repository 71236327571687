<div class="success content-panel">
  <div class="alert alert-success m-t-40">
    <b>Success</b><br />
    <p>
      {{paymentRespose?.TextResponse}}
    </p>
    <!-- <p class="text-right">{{paymentRespose?.Ref}}</p> -->
  </div>
  <div class="left-button m-t-20 header-buttons" *ngIf="!isSmsRequest">
    <button mat-button class="btn btn-primary" (click)="backButton()">
      {{backText}}
    </button>
  </div>
  <div class="left-button m-t-20 header-buttons" *ngIf="isSmsRequest">
    <button mat-button class="btn btn-primary" [routerLink]="['/smsfacility/dashboard']">
      Back to Dashboard
    </button>
  </div>
  <mat-card class="m-t-40 m-0">
    <mat-card-content>
      <div fxLayout="row wrap" class="m-b-10" class="centre" *ngIf="paymentRespose?.BusinessName">
        <div fxFlex.gt-sm="25" fxFlex.gt-xs="25" fxFlex="100">
          <p><b>Health Centre</b></p>
        </div>
        <div fxFlex.gt-sm="25" fxFlex.gt-xs="25" fxFlex="100">
          <p>{{paymentRespose?.BusinessName}}</p>
        </div>
      </div>
      <div fxLayout="row wrap" class="m-b-10" class="centre" *ngIf="paymentRespose?.Providername">
        <div fxFlex.gt-sm="25" fxFlex.gt-xs="25" fxFlex="100">
          <p><b>Doctor</b></p>
        </div>
        <div fxFlex.gt-sm="25" fxFlex.gt-xs="25" fxFlex="100">
          <p>{{paymentRespose?.Providername}}</p>
        </div>
      </div>
      <div fxLayout="row wrap" class="m-b-10" class="centre" *ngIf="paymentRespose?.AppSlotId && paymentRespose?.AppFromTimeSlot">
        <div fxFlex.gt-sm="25" fxFlex.gt-xs="25" fxFlex="100">
          <p><b>Appointment Time</b></p>
        </div>
        <div fxFlex.gt-sm="25" fxFlex.gt-xs="25" fxFlex="100">
          <p>{{paymentRespose?.AppFromTimeSlot | date:'EEEE, MMMM d, y, h:mm a'}}</p>
        </div>
      </div>
      <div fxLayout="row wrap" class="m-b-10" class="centre">
        <div fxFlex.gt-sm="25" fxFlex.gt-xs="25" fxFlex="100">
          <p><b>MMH Transcation Ref</b> </p>
        </div>
        <div fxFlex.gt-sm="25" fxFlex.gt-xs="25" fxFlex="100">
          <p>{{paymentRespose?.MMHRefNo}}</p>
        </div>
      </div>
      <div fxLayout="row wrap" class="m-b-10" class="centre">
        <div fxFlex.gt-sm="25" fxFlex.gt-xs="25" fxFlex="100">
          <p><b>Payment Status</b> </p>
        </div>
        <div fxFlex.gt-sm="25" fxFlex.gt-xs="25" fxFlex="100">
          <p>{{paymentRespose?.PaymentStatus}}</p>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>