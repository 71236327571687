import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { LoaderService } from '../../data/service/loader.services';
import { error } from 'console';
import { environment } from 'src/environments/environment';
import { URLConstants } from '../constants/urlConstants';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(public loaderService: LoaderService) { }
  // intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   this.loaderService.show();
  //   return next.handle(req).pipe(finalize(() => this.loaderService.hide()));
  // }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let userPayment = req.url.includes(environment.apiBaseURL + URLConstants.userPaymentResponseView);
    let userSelectType= req.url.includes(environment.apiBaseURL + URLConstants.getSelectActivityType);
    if(req.url!=environment.apiBaseURL + URLConstants.saveDraftMessage){
      if(userPayment==true || !userSelectType) {
        this.loaderService.show();
      }
     
    }
    
    return next.handle(req).pipe(map((event:any) => {
        if (event instanceof HttpResponse) {
           this.loaderService.hide();
        }         
        return event;
    }));
}
}
