import { Router } from '@angular/router';
import { Component, OnDestroy, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToasterService } from 'src/app/data/service/toaster.service';
import { InBoxService } from 'src/app/data/service/inbox.service';
import { AttachfileDialogComponent } from '../attachfile-dialog/attachfile-dialog.component';
import { MailBoxService } from 'src/app/data/service/mail-box.service';
import { TokenService } from 'src/app/data/service/token.service';
import { AlertDialogComponent } from 'src/app/shared/component/alert-dialog/alert-dialog.component';
import { AlertType } from 'src/app/core/constants/app-enums';
import { IAlertDialog } from 'src/app/data/interface/Idialog';
import { environment } from 'src/environments/environment';
import { URLConstants } from 'src/app/core/constants/urlConstants';
import { interval, Observable, of, Subject } from "rxjs";
import {debounceTime, distinctUntilChanged, map, startWith, takeUntil,
  delay,
} from "rxjs/operators";
import { formatDate } from '@angular/common';
import * as moment from 'moment';
import { applicationHttpClientCreator } from 'src/app/data/interface/IRequestOptions';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { EncryptDecrypt } from 'src/app/core/helper/encrypt-decrypt';
import { ConfirmDialogComponent } from 'src/app/shared/component/confirm-dialog/confirm-dialog.component';
import { MatSelect } from '@angular/material/select';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true
};


@Component({
  selector: 'app-draftdetail',
  templateUrl: './draftdetail.component.html',
  styleUrls: ['./draftdetail.component.scss']

})
export class DraftdetailComponent implements OnInit,OnDestroy,OnChanges, AfterViewInit {

  @Input() draftId = "";
  navLinks:any[];
  draftselectedMessagesDetails: any = [];
  addedFiles: any[] = [];
  files: any = [];
  getPracticeData: any = [];
  replydata: any;
  draftDetailsForm: FormGroup;
  userId: any;
  showdetails: boolean = false;
  downloadURL = URLConstants.downloadFile_Post;
  isPostDownload = true;
  Attachment: any;
  generateXML: any;
  filelengthError: boolean = false;
  public selectedMessagesDetails: any = [];
  filesarray: any = [];
  currentIndex: number = 0;
  patientList: any = [];
  userRoleId: any;
  practiceAdmin: any;
  locationsId: any;
  locations: any = [];
  toUser: any = [];
  Roles: any = [];
  serviceTypes: any = [];
  healthCenterList: any = [];
  composeForm: FormGroup;
  page: number = 0;
  pagesize: number = 10;
  searchText: string;
  isSelectAll: boolean = false;
  public inboxCount: any;
  alertDialog: IAlertDialog;
  public xmltoJSON: any;
  public filesData: any = [];
  saveIndicator: any;
  saveCount = 0;
  draftAttachmentXml: string;
  destroy = new Subject();
  selectedRole: any=[];
  serviceName: any;
  toUserName: any;
  toServiceName:any;
  MessageCode: string;
  liveStatusSub: any;
  public userData: any = [];
  healthCenterListValue: any  = [] ;
  public messageTypeId: any;
  public showMailBox:boolean = false;
  public showMailMsg:boolean = false;
  isStaffRemoved: boolean;
  serviceTypelocationDetails: any = [];
  public config: PerfectScrollbarConfigInterface = {};
  public configDraft: PerfectScrollbarConfigInterface = {};
  @Output() onCloseClick = new EventEmitter();
  postObject: any;
  url= URLConstants.downloadFile_Post;
  showTerms=false;
  selectedServiceTypeDataterms: any;
  BtnHide: boolean = true;
  selectLocation: boolean;
  messageBoxValue: any;
  userSelectedCheck: any;
  selectedUserId: string;
  userDetails: any;
  UserSearch: any;
  selectedNamecheck: boolean =false;
  isServiceActive: boolean = true;
  isPatient:boolean = false;
  isServiceActiveReply: any;
  receiveMsgWhenStaffOutOfOfficeEnabled: boolean = false;
  confirmDialog: { title: string; message: any; alertType: AlertType; yesButtonText: string; noButtonText: string; };
  showInformation: boolean = false;
  isAcknowledge: boolean = false;
  billableCost: any;
  disableSubmit: boolean = false;
  paymentMethod: string = ""
  @ViewChild('locationMatSelect') locationMatSelect: MatSelect;
  UserSearchId: any;
  RoleIDforservice:string;
  constructor(private mailBoxService: MailBoxService, private inboxService: InBoxService, public fb: FormBuilder,
    public dialog: MatDialog, public toaster: ToasterService, public translate: TranslateService,
    public tokenService: TokenService,private http:HttpClient,private router:Router) {
      this.userId = this.tokenService.userId();
    }
  // ngOnChanges(changes: SimpleChanges): void {
  //   if(changes.draftId.currentValue != "" && changes.draftId.currentValue != undefined ) {
  //     this.loadDraftData(changes.draftId.currentValue);
  //   }
  // }

  ngAfterViewInit() {


  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.draftId.previousValue !== changes.draftId.currentValue) {
      this.loadDraftData(changes.draftId.currentValue);
    }
  }


  ngOnInit(): void {

    // this.inboxService.getCount(this.userId).subscribe((count: any[]) => {
    //   this.mailBoxService.mailstype[1].count = count[1];
    // })
    this.GetHealthCenterDetails();
    // this.loadDraftData();
    this.draftDetailsForm = this.fb.group({
      MessageBody: new FormControl('')
    })

    // Compose Form
    this.composeForm = this.fb.group({
      healthCenter: new FormControl(null),
      location: new FormControl(null),
      serviceName: new FormControl(null),
      role: new FormControl(null, Validators.required),
      toUser: new FormControl(null, Validators.required),
      termsconditions: new FormControl(false, Validators.requiredTrue),
      subject: new FormControl(null, Validators.required),
      message: new FormControl(null, Validators.required),
      patientdata: new FormControl(null),
      Attachment: [''],
      toUserDefault:new FormControl(null),
    })
    this.isPatient = this.tokenService.checkIsPatient();
    this.GetHealthCenterDetails();
    this.getPatientListByLocality();
    // this.liveStatusSub = Observable.interval(60000)
    // .subscribe((val: any) => {
      this.liveStatusSub = interval(30000).subscribe((x:any) => {
        this.autoSaveDraft();
    });
    // });
  }

  get r() { return this.draftDetailsForm.controls }
  get f() { return this.composeForm.controls }

  closeSelectedMail() {
    this.onCloseClick.emit();
  }

  saveChanges(value: string) {
    // this.sendMesssage('drafts');
    return of(value).pipe(delay(1500));

  }

  //Draft practice details
  onToggle(): void {
    this.showdetails = !this.showdetails;
  }

  loadDraftData(draftmessageId:any) {
    this.inboxService.getDraftMessages(draftmessageId).subscribe((result: any) => {
      this.draftselectedMessagesDetails = result;
      let toName= result[0].ToName;
      this.UserSearch =toName;
      this.UserSearchId =result[0]?.ToUserID;
       //this.composeForm.get('toUser')?.setValue(toName);
      this.messageBoxValue= this.draftselectedMessagesDetails[0].MessageBody;
      this.showMailMsg=true;
      this.filesData=[];
      if (this.draftselectedMessagesDetails[0].Attachments != "" && this.draftselectedMessagesDetails[0].Attachments != null && this.draftselectedMessagesDetails[0].Attachments != undefined) {
        this.xmltoJSON = JSON.parse(this.draftselectedMessagesDetails[0].Attachments);
        console.log(this.xmltoJSON);
        if (this.xmltoJSON.ROOT != "") {
          let singleAttachment: any = [];
          singleAttachment = this.xmltoJSON.ROOT.Attachment;
          this.filesData = singleAttachment;
          console.log("$$$$$$$$$$$$$$$$$$$",this.filesData.length);
        }
      else{
        this.filesData=[];
        this.filesData
      }
    }
      this.composeForm.get('healthCenter')?.setValue(this.draftselectedMessagesDetails[0].PracticeManagerId);
      this.composeForm.patchValue({
        message: this.draftselectedMessagesDetails[0].MessageBody,
        subject: this.draftselectedMessagesDetails[0].Subject
      })
      this.locationsId = this.draftselectedMessagesDetails[0].LocationId;
      this.practiceAdmin=this.draftselectedMessagesDetails[0].PracticeManagerId;
      this.getLocations(this.draftselectedMessagesDetails[0].PracticeManagerId, false);
      setTimeout(()=>{
      this.composeForm.get('location')?.setValue(this.draftselectedMessagesDetails[0].LocationId);
      this.composeForm.get('serviceName')?.setValue(this.draftselectedMessagesDetails[0].MessageTypeID);
      this.composeForm.get('role')?.setValue(this.draftselectedMessagesDetails[0].RoleID);
      // this.composeForm.get('termsconditions')?.setValue(this.draftselectedMessagesDetails[0].TermsAndCondition);
      //this.getToUserDetails(this.draftselectedMessagesDetails[0].RoleID);
        this.draftselectedMessagesDetails[0].ToUserID =='00000000-0000-0000-0000-000000000000'?this.composeForm.get('toUser')?.setValue('') :this.composeForm.get('toUser')?.setValue(this.draftselectedMessagesDetails[0].ToUserID);

     // if (this.draftselectedMessagesDetails[0]?.RoleID != '00000000-0000-0000-0000-000000000000') {
        // this.composeForm.get('toUser')?.setValue(this.draftselectedMessagesDetails[0].recipientid);
        // if(this.draftselectedMessagesDetails[0].recipientid!='00000000-0000-0000-0000-000000000000')
        if(this.draftselectedMessagesDetails[0].IsReply==true){
          // if(this.serviceTypes.length>0){
          //   let serviceType=this.serviceTypes.filer((x:any)=>x.MessageTypeID==this.draftselectedMessagesDetails[0].MessageTypeID);
          //   this.serviceName=serviceType[0]?.MessageCode;
          //   }
            if(this.draftselectedMessagesDetails[0].LocationId!=null&&this.draftselectedMessagesDetails[0].LocationId!=""){
              applicationHttpClientCreator(this.http).Get(`${URLConstants.getServiceTypeByLocation}${this.draftselectedMessagesDetails[0].MessageTypeID}&LocationId=${this.draftselectedMessagesDetails[0].LocationId}&UserID=${this.userId}`, {
              }).subscribe((data: any) => {
                this.showInformation = (data[0]?.IsBillable && data[0]?.BillableCost) ? true : false;
                if(data[0]?.PaymentOption == 'P'){
                  this.paymentMethod = "Pay at Heath Center"
                }
                else if(data[0]?.PaymentOption == 'H'){
                  this.paymentMethod = "Pay at Heath Center or Pay Online"
                }
                else if(data[0]?.PaymentOption == 'O'){
                  this.paymentMethod = "Pay Online"
                }
                this.billableCost = data[0]?.BillableCost || 0;
                this.MessageCode=data[0]?.MessageCode;
                this.serviceName=data[0]?.MessageCode;
                this.isServiceActiveReply = data[0]?.IsActive.toString();
                console.error(this.isServiceActiveReply);
              })
            }else{
              let encryptedTypeId = EncryptDecrypt.encryptUsingAES256(this.draftselectedMessagesDetails[0].MessageTypeID);
              applicationHttpClientCreator(this.http).Get(`${URLConstants.getServiceTypeByBillable}${encryptedTypeId}`, {
              }).subscribe((data: any) => {
                this.MessageCode=data[0]?.MessageCode;
                this.serviceName=data[0]?.MessageCode;
                this.isServiceActiveReply = data[0]?.IsActive.toString();
              })
            }
          this.inboxService.getSelectedMailDetails(this.userId,this.draftselectedMessagesDetails[0].InboxMessageID).subscribe((result: any) => {

            this.selectedMessagesDetails = result;
            console.log("/*/*/*/*/*/**/*/***/*/*/*/*/*/*/*/*/*//");
            console.log( this.selectedMessagesDetails[0]);
            this.toUserName=result[0].FromName;
            this.toServiceName=result[0].MessageCode;
            this.isServiceActiveReply = result[0]?.IsActive.toString();

          });
        // this.composeForm.get('toUser')?.setValue(this.draftselectedMessagesDetails[0].FromUserId);

       // }

        }
        this.getServiceTypeByLocation(this.draftselectedMessagesDetails[0].MessageTypeID,this.draftselectedMessagesDetails[0].LocationId)

        this.composeForm.get('role')?.setValue(this.draftselectedMessagesDetails[0]?.RoleID);
    // }
      // this.getStaffRoleRemoved(this.draftselectedMessagesDetails[0].recipientid, this.draftselectedMessagesDetails[0]..MessageTypeID);
    },500)

  })
  }

  /* get inbox messages */
  getRecievedMessages() {
    this.inboxService.getAllSearchedReceivedMessage(this.userId, 'MessageReceivedOn desc', this.page, this.pagesize, this.isSelectAll, this.searchText).subscribe((res: any) => {
      this.mailBoxService.gridView = res;
    })
  }

  /* get sent messages */
  getSentMessages() {
    this.inboxService.getSentMessage(this.userId, 'MessageSentOn desc', this.page, this.pagesize, false, this.searchText).subscribe((res: any) => {
      this.mailBoxService.sentData = res;
    })
  }

  // Inbox count
  inboxMessageCount = () => {
    this.inboxService.GetAllReceivedMessageCount(this.userId).subscribe((data: any) => {
      this.inboxCount = data;
    })
  }

  // Getting Health center Data
  GetHealthCenterDetails() {
    this.inboxService.getHealthCenterDetails(this.userId).subscribe(data => {
      this.healthCenterList = data;
      console.log("-----------------healthCenterList-----------------------------", this.healthCenterList);

      // if (this.healthCenterList.length > 0) {
      //   this.healthCenterListValue;
      // }
      // if (this.mailBoxService.selectedMailBoxType == 'Drafts') {
      //   this.loadDraftData();
      // }
    })
  }

  // Getting Locations Data
  getLocations(practiceManagerId: string, type: boolean) {
    this.selectLocation = type;
    this.practiceAdmin = practiceManagerId;
   console.log(this.healthCenterList);
    console.log(practiceManagerId);
   this.inboxService.GetPracticeLocations(practiceManagerId).subscribe(res => {
      this.locations = res;
      if (this.selectLocation == false) {
        this.composeForm.get('location')?.setValue(this.draftselectedMessagesDetails[0]?.LocationId);
        if (this.locations.length > 0) {
          if (this.draftselectedMessagesDetails[0]?.LocationId != null && this.draftselectedMessagesDetails[0]?.LocationId != "" && this.draftselectedMessagesDetails[0]?.LocationId != undefined) {
            this.getServiceTypes(this.draftselectedMessagesDetails[0]?.LocationId, false);
            this.GetAllRolesByPractice();
          }
        }
      } else {
        this.composeForm.get('location')?.setValue(this.locations[0]?.LocationId);
        if (this.locations.length > 0) {
          this.getServiceTypes(this.locations[0]?.LocationId, true);
          this.GetAllRolesByPractice();
        }
      }
    })
  }

  // Getting Service types
  getServiceTypes(locationId: number, type: boolean) {
    let selectServiceType = type;
    this.inboxService.GetServiceType(this.practiceAdmin, locationId, this.userId).subscribe(res => {
      this.serviceTypes = res;
      this.locationsId = locationId;
      if (selectServiceType == false) {
        if (this.draftselectedMessagesDetails.length > 0) {
          let serviceType = this.serviceTypes.filter((x: any) => x.MessageTypeID == this.draftselectedMessagesDetails[0].MessageTypeID);
          // if(this.draftselectedMessagesDetails[0]?.IsReply==false&& this.MessageCode!=null&&this.MessageCode!=="")
          this.serviceName = this.MessageCode != null && this.MessageCode !== "" && this.MessageCode != undefined ? this.MessageCode : serviceType[0]?.MessageCode != null && serviceType[0]?.MessageCode != "" && serviceType[0]?.MessageCode != undefined ? serviceType[0]?.MessageCode : this.draftselectedMessagesDetails[0].MessageCode;

          if (this.draftselectedMessagesDetails[0]?.LocationId != null && this.draftselectedMessagesDetails[0]?.LocationId != "") {
            applicationHttpClientCreator(this.http).Get(`${URLConstants.getServiceTypeByLocation}${this.draftselectedMessagesDetails[0].MessageTypeID}&LocationId=${this.draftselectedMessagesDetails[0]?.LocationId}&UserID=${this.userId}`, {
            }).subscribe((data: any) => {
              this.selectedServiceTypeDataterms = data[0]?.terms;
              this.showInformation = (data[0]?.IsBillable && data[0]?.BillableCost) ? true : false;
              if(data[0]?.PaymentOption == 'P'){
                this.paymentMethod = "Pay at Heath Center"
              }
              else if(data[0]?.PaymentOption == 'H'){
                this.paymentMethod = "Pay at Heath Center or Pay Online"
              }
              else if(data[0]?.PaymentOption == 'O'){
                this.paymentMethod = "Pay Online"
              }
              this.billableCost = data[0]?.BillableCost || 0;
              this.MessageCode = data[0]?.MessageCode;
              this.serviceName = data[0]?.MessageCode;
              this.isServiceActiveReply = data[0]?.IsActive.toString();
              console.error(this.isServiceActiveReply);
            })
          }
          // }
        }

      } else {
        this.composeForm.get('serviceName')?.setValue(this.serviceTypes[0]?.MessageTypeID);
        this.composeForm.get('subject')?.setValue(this.serviceTypes[0]?.MessageCode);
         // this.checkingIsRRP(this.serviceTypes[0]);
      }
      if (this.serviceTypes.length > 0) {
        this.getToUserDetails(this.Roles[0].RoleId,'intial')
      }
    })
  }

  // Getting roles
  GetAllRolesByPractice() {
    this.inboxService.GetAllRolesByPractice(this.practiceAdmin, this.userId).subscribe(response => {
      this.Roles = response;
      if (this.selectLocation == false) {
        if (this.draftselectedMessagesDetails[0]?.RoleID != null && this.draftselectedMessagesDetails[0]?.RoleID != "" && this.draftselectedMessagesDetails[0]?.RoleID != undefined) {
          this.composeForm.get('role')?.setValue(this.RoleIDforservice);
          this.getToUserDetails(this.RoleIDforservice,'intial');
        }
      } else {
        this.composeForm.get('role')?.setValue(this.Roles[0].RoleId);
        this.getToUserDetails(this.Roles[0].RoleId,'intial');
      }
      // if(this.Roles){
      if (this.Roles[2].RoleName) {
        this.getPatientListByLocality();
        // }
      }
    })
  }

  
  //Getting To User Details
  getToUserDetails(roleId: string,value:string) {
    if (roleId == '00000000-0000-0000-0000-000000000000') {
      return;
    }
    this.toUser=[];
    this.selectedNamecheck=false;
    this.patientList = [];
    this.selectedRole = this.Roles.filter((x: any) => x.RoleId == roleId);
    this.userRoleId = roleId;
    if (this.selectedRole[0]?.RoleName != 'Patient') {

        this.inboxService.GetAllStaff(this.practiceAdmin, this.locationsId, roleId).subscribe(res => {
        this.toUser = res;
        setTimeout(()=>{
          if(!this.toUser?.length || this.toUser?.length <= 0)
          {
            this.mailBoxService.showDialogWhenNoStaffAvailable(this.locationMatSelect);
            this.composeForm.controls['location'].setErrors({'valid': false});
            this.composeForm.controls['location'].markAsDirty();
          }
        },500)
        if(this.draftselectedMessagesDetails?.[0]?.ToUserID) {
          const filterUser = this.toUser.filter((res: any)=> {
            return res.UserID == this.draftselectedMessagesDetails[0].ToUserID
           });
           this.checkProviderAvailability(filterUser[0])
        }
    })
    }
    if(value=='change'){
      this.UserSearch='';
      this.composeForm.get('toUser')?.setValue('');
    }
  }

  getPatientListByLocality = () => {
    let staffID = "EE1ACEC4-27B9-4A04-B86F-250B6DB00E9A"
    let FacilityID = "768f7740-f8d1-4ce4-b3b2-50236b530814"
    this.inboxService.getPatientListByLocality('RU', "EE1ACEC4-27B9-4A04-B86F-250B6DB00E9A", "768f7740-f8d1-4ce4-b3b2-50236b530814", "").subscribe(res => {
      this.patientList = res;
    })
  }

  getAllUsersList = () => {
    this.inboxService.getAllUsersList(this.userId).subscribe((res: any) => {
      this.userData = res;
      console.log(this.userData, "userData list");
      if (this.userData.length > 0) {
        for (var i = 0; i < this.userData.length; i++) {
          if (this.userData[i]["PracticeManagerId"].toString() == this.composeForm.value.healthCenter) {
            this.getAutoSuggestNameList();
          }
        }
      }
    })
  }

  getAutoSuggestNameList = () => {
    let nameList = this.composeForm.value.toUser;
    let practiceManagerId = this.composeForm.value.healthCenter;
    if (nameList) {
      applicationHttpClientCreator(this.http).Get(`${URLConstants.toUserAutosuggestList}?nameType=${"RU"}&practiceId=${practiceManagerId}&suggestedText=${nameList}`, {
      }).subscribe((data: any) => {
        this.patientList = data;
        console.log(this.patientList, "patient list")
      })
    }
    else {
      this.patientList = [];
    }
  }

  handleFilter(event:any){
    let practiceManagerId = this.composeForm.value.healthCenter;
    if(event!=''){
      applicationHttpClientCreator(this.http).Get(`${URLConstants.toUserAutosuggestList}?nameType=${"RU"}&practiceId=${practiceManagerId}&suggestedText=${event}`, {
      }).subscribe((data: any) => {
        this.patientList = data;
        console.log(this.patientList, "patient list")
      })
    }

  }
  backspaceCheck(event: KeyboardEvent) {
    const key = event.keyCode || event.charCode;
    if(this.userSelectedCheck){
  if (key === 8 || key === 46) {
      this.clear();
      this.userSelectedCheck=false;
    }
  }
  }
  clear(){
    this.selectedUserId='';
   this.composeForm.get('toUser')?.setValue(null)
  }


  selectedName = (list: any) => {

    this.userDetails = this.patientList.filter((x: any) => x.names == list);
    if(this.userDetails.length!=0){
     this.userSelectedCheck=true;
     this.composeForm.get('toUser')?.setValue(this.userDetails[0].names);
     this.selectedNamecheck=true;
     this.selectedUserId= this.userDetails[0].UserID;
    }else{
     this.userSelectedCheck=false;
     this.composeForm.get('toUser')?.setValue(null)
    }
   }



  // Attach Files Dialog popup and getting files from that dialog
    fileCount = 0;
    attachFileDialog() {
      this.fileCount = this.filesData.length >= 0 ? this.filesData.length : 1;
      const dialogRef = this.dialog.open(AttachfileDialogComponent, {
        data: this.fileCount + this.addedFiles.length
      });
        dialogRef.afterClosed().subscribe(result => {
          this.files = result;
      this.addedFiles.push(...this.files);
      // this.addedFiles.push(...this.filesData);
      if ((Number(this.addedFiles.length) + Number(this.filesData.length)) > 3) {
        this.filelengthError = true;
        this.BtnHide = false;
      } else { this.filelengthError = false
        this.BtnHide = true; }
    });
    // if((Number(this.addedFiles.length) + Number(this.filesData.length)) > 3)
    // {
    //   this.BtnHide = true;
    // }
    // else{
    //   this.BtnHide = false;
    // }
    console.log('added files *******',this.addedFiles)
  }


  // Remove selected files
  remove(i: number) {
    this.addedFiles.splice(i, 1);
    this.BtnHide = true;
    // this.filesData.splice(i, 1);
    if (Number(this.filesData.length) + Number(this.addedFiles.length) == 3) {
      this.filelengthError = false;
      this.BtnHide = true;
    }
  }

  removeSaved(i: number) {
    this.filesData.splice(i, 1);
    this.BtnHide = true;
    if (Number(this.filesData.length) + Number(this.addedFiles.length) == 3) {
      this.filelengthError = false;
      this.BtnHide = true;
    }
  }
  removeSingleFile(){
    // delete this.filesData.DestName['cdata-section'],this.filesData.SourceName['cdata-section'],this.filesData.Size['cdata-section'];
    // this.filesData;
    this.emptyObject(this.filesData);
    //this.emptyObject(this.xmltoJSON);
    this.filesData = [];
    this.xmltoJSON = this.xmltoJSON.ROOT == "";
    // this.emptyObject(this.xmltoJSON.ROOT == "");
    //console.log(this.xmltoJSON);
    // console.log(this.xmltoJSON.ROOT == "");

  }

   emptyObject(obj : any) {
    Object.keys(obj).forEach(k => delete obj[k])
  }

  // Create or generate XML request
  savedFile: any = [];
  constructAttachFiles(strFiles: string) {
    this.savedFile = [];
    this.generateXML = ("<ROOT>") + ""
    if(this.filesarray.length > 0){
      let file = this.filesarray.split(";");
      for (var index = 0; index < file.length - 1; index++) {
        let filedetails: string[];
        filedetails = file[index].split(",");
        if (filedetails[3] === "1") {
          this.generateXML += ("<Attachment>") + "" +
            ("<DestName>" + this.setCdata(filedetails[0]) + "</DestName>") + "" +
            ("<SourceName>" + this.setCdata(filedetails[1]) + "</SourceName>") + "" +
            ("<Size>" + filedetails[2] + "</Size>") + "" +
            ("</Attachment>") + ""
        }
      }
    }
    if(this.xmltoJSON && this.xmltoJSON?.ROOT != ''){
      if(!this.xmltoJSON.ROOT.Attachment.length) {
        this.savedFile.push(this.xmltoJSON.ROOT.Attachment);
      } else {
        this.savedFile = this.xmltoJSON.ROOT.Attachment;
      }
      if (this.savedFile.length > 0) {
        this.generateXML += this.constructDraftFiles();
      }
    }
    this.generateXML += ("</ROOT>");
    return this.generateXML;

  }

  constructDraftFiles() {
    //  le = ("<ROOT>") + ""
    let xml: any = "";
    this.savedFile.forEach((element: any) => {
      if(element.length != 0)
      {
        xml += ("<Attachment>") + "" +
        ("<DestName>" + this.setCdata(element.DestName['cdata-section']) + "</DestName>") + "" +
        ("<SourceName>" + this.setCdata(element.SourceName['cdata-section']) + "</SourceName>") + "" +
        ("<Size>" + element.Size + "</Size>") + "" +
        ("</Attachment>") + ""
      }
    })
    // this.generateXML +=("</ROOT>");
    return xml;
  }


  setCdata(strFiles: string) {
    return "<![CDATA[" + strFiles + "]]>";
  }

  // Drafted mail sent api
  composeNewMessage(attachmentXML: string) {
    let toUserId=''
    if(this.selectedNamecheck){
      toUserId=this.selectedUserId;
    }else{
      toUserId =this.composeForm.value.toUser ? this.composeForm.value.toUser : '';
    }
    if(this.selectedRole[0]?.RoleName == 'Patient' && !this.patientList.length) {
      toUserId =  this.UserSearchId;
    }
    var sentMessageDeatils = {
      "FromUserId": this.draftselectedMessagesDetails[0].FromUserID,
      // "ToUserId": this.draftselectedMessagesDetails[0].ToUserID,
      "ToUserID": toUserId,
      "MessageTypeID": this.composeForm.value.serviceName ? this.composeForm.value.serviceName : 1,
      // "Subject": this.draftselectedMessagesDetails[0].Subject,
      "Subject": this.composeForm.value.subject,
      "MessageBody": this.composeForm.value.message!=null && this.composeForm.value.message!=undefined && this.composeForm.value.message!=""?this.composeForm.value.message:"",
      "IsRead": false,
      "IsSent": true,
      "IsTrash": this.draftselectedMessagesDetails[0].IsTrash,
      "MessageSentOn": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      "MessageReceivedOn": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      "AttachmentXML": attachmentXML ? attachmentXML : "<ROOT><Attachment></Attachment></ROOT>",
      "isRequestRepeatPresc": false,
      "isBillable": true,
      "RRPXML": "RRPXML",
      "Cost": this.billableCost,
      "isReply": this.draftselectedMessagesDetails[0].IsReply,
      "Status": "A",//this.draftselectedMessagesDetails[0].IsActive,
      "PracticeAdmin": this.draftselectedMessagesDetails[0].PracticeManagerId,
      "PaymentType": "P",
      "ServiceRefNo": this.draftselectedMessagesDetails[0].ServiceRefNo!=null&&this.draftselectedMessagesDetails[0].ServiceRefNo!=undefined?this.draftselectedMessagesDetails[0].ServiceRefNo:"",
      "LocationId": this.draftselectedMessagesDetails[0].LocationId,
      "ParentID": 0,
      "InboxDraftMessageID": this.draftselectedMessagesDetails[0].InboxDraftMessageID,
      // "MessageCategoryID": this.draftselectedMessagesDetails[0].MessageTypeID,
      "MessageCategoryID": 1,
      "PMSServiceCode": "C",
      "OnlineTransactionID": "O",
      "SentBy": "00000000-0000-0000-0000-000000000000"
    }

    this.inboxService.composeNewMail(sentMessageDeatils)
      .subscribe(res => {
        if (res) {
         // this.inboxMessageCount();
          this.getSentMessages();
          this.getRecievedMessages();
          // this.inboxService.getDraftMessageCount(this.userId).subscribe((data: any) => {
          //   this.mailBoxService.draftMessageCount=data;
          // })
          this.toaster.success(
            this.translate.instant('Message sent successfully'));
          //this.composeForm.reset();
          this.addedFiles = [];
          this.showMailMsg=false;
          this.inboxService.getCount(this.userId).subscribe((count: any[]) => {
            this.mailBoxService.mailstype[1].count = count[1];
          })
          this.mailBoxService.selectedMailBoxType = 'Sent';
          this.router.navigate(['/pages/navigate-routes/Sent']);
        }
      }, (err: any) => {
        this.toaster.error(err.error.Message || err.error.message)
      })
  }

  // Svae as draft
  saveAsDraft(attachmentXML: string) {
    this.draftAttachmentXml=attachmentXML
    this.alertDialog = {
      title: 'Info',
      message: this.translate.instant("MessageSavedAsDraftOnly"),
      alertType: AlertType.None,
      okButtonText: this.translate.instant('OK')
    };
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: this.alertDialog
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      let toUserId=''
      if(this.selectedNamecheck){
        toUserId=this.selectedUserId;
      }else{
        toUserId =this.composeForm.value.toUser ? this.composeForm.value.toUser : '';
      }
      if (result == true) {
        var data = {
          InboxDraftMessageID: this.draftselectedMessagesDetails[0].InboxDraftMessageID, // need to confirm inboxdraftmessageid
          FromUserID: this.userId,
          // ToUserID: this.draftselectedMessagesDetails[0].ToUserID,
          ToUserID: this.draftselectedMessagesDetails?.[0]?.ToUserID || toUserId,
          MessageTypeID: this.composeForm.value.serviceName ? this.composeForm.value.serviceName : 1,
          // Subject: this.draftselectedMessagesDetails[0].Subject,
          Subject: this.composeForm.value.subject,
          MessageBody: this.composeForm.value.message!=null && this.composeForm.value.message!=undefined && this.composeForm.value.message!=""?this.composeForm.value.message:"",
          IsCompose: false,
          IsReply: this.draftselectedMessagesDetails[0].IsReply,
          ServiceRefNo: this.draftselectedMessagesDetails[0].ServiceRefNo!=null&&this.draftselectedMessagesDetails[0].ServiceRefNo!=undefined?this.draftselectedMessagesDetails[0].ServiceRefNo:"",
          PracticeManagerId: this.draftselectedMessagesDetails[0].PracticeManagerId,
          // LocationId: this.draftselectedMessagesDetails[0].LocationId,
          // PracticeManagerId: this.practiceAdmin ? this.practiceAdmin : '',
          LocationId: this.locationsId ? this.locationsId : '',
          InboxMessageID: this.draftselectedMessagesDetails[0].InboxMessageID,
          // RoleID: this.draftselectedMessagesDetails[0].RoleID,
          RoleID: this.userRoleId ? this.userRoleId : '00000000-0000-0000-0000-000000000000',
          attachments: attachmentXML ? attachmentXML : "",
          IsSent: false
        }
        this.inboxService.SaveDraftMessage(data).subscribe((res:any) => {
          if (res > 1) {
            // alert('save draft success fully');
            this.toaster.success(
              this.translate.instant('draft saved successfully'));
            this.draftDetailsForm.reset();
            this.addedFiles = [];
            this.mailBoxService.selectedMailBoxType = 'Drafts';
            this.showMailBox = true;
            this.showMailMsg = false;
            this.reloadCurrentRoute();
          }
        }, (err: any) => {
          this.toaster.error(err.error.Message)
        })
      }
    })
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
        console.log(currentUrl);
    });
  }

 // Compose a new mail api call
 sendMesssage(value: any) {
  if (this.addedFiles && this.addedFiles.length > 0) {
    const frmData = new FormData();
    for (var i = 0; i < this.addedFiles.length; i++) {
      frmData.append("fileUpload", this.addedFiles[i]);
    }
    this.inboxService.uploadFiles(frmData).subscribe((result: any) => {
      this.filesarray = result
      let attachmentXML = this.constructAttachFiles(this.filesarray);
      if (value !== 'Drafts') {
        this.composeNewMessage(btoa(attachmentXML));
        this.mailBoxService.selectedMailBoxType = value;
      } // btoa (encode files data)
      else {
        this.saveAsDraft(btoa(attachmentXML));
        this.mailBoxService.selectedMailBoxType = value;
      }
    })
  } else {
    let attachmentXML = this.constructAttachFiles(this.filesarray);
    if (value !== 'Drafts') {
      this.composeNewMessage(btoa(attachmentXML));
      this.showMailBox = true;
      this.showMailMsg = false;
    }
    else {
      this.saveAsDraft(btoa(attachmentXML));
    }
  }
}

sendMessageActive(value : any){
  if(this.draftselectedMessagesDetails[0].IsReply==true){ // check if is a reply message
    let { ReceiveMsgWhenStaffOutOfOfficeEnabled, IsOutOfOfficeEnabled } = this.selectedMessagesDetails[0];
    console.log("-----------------------------From Reply------1------------------------------------------");
    console.log("", ReceiveMsgWhenStaffOutOfOfficeEnabled, IsOutOfOfficeEnabled);
    if (IsOutOfOfficeEnabled) {
      let msg = '';
      if (ReceiveMsgWhenStaffOutOfOfficeEnabled) {
        msg = 'The selected provider is out of office.<br>There may be a delay in getting response.<br> Do you want to continue?';
        this. confirmDialog = {
          title: 'Info',
          message: this.translate.instant(msg),
          alertType: AlertType.None,
          yesButtonText: 'Yes',
          noButtonText: 'No'
        };
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: this.confirmDialog,
          disableClose : true
        });
        dialogRef.afterClosed().subscribe((result: any) => {
          if (result == true) {
            this.serviceValidate(value);
          }
        });
      }
      else {
        console.log("-----------------------------From Reply------2------------------------------------------");
        msg = 'ComposeOutOfOfficeRestrict';
        this.alertDialog = {
          title: 'Info',
          message: this.translate.instant(msg),
          alertType: AlertType.None,
          okButtonText: this.translate.instant('OK')
        };
        const dialogRef = this.dialog.open(AlertDialogComponent, {
          data: this.alertDialog,
          disableClose : true
        });
        dialogRef.afterClosed().subscribe((result: any) => {
          if (result == true) {

          }
        });
        return false;
      }
    } else {
      this.serviceValidate(value);
    }
  }else{
    console.log("-----------------------------From Normal Draft------1------------------------------------------");

    let user = this.toUser.find((x: any) => x.UserID === this.composeForm.value.toUser);
    this.receiveMsgWhenStaffOutOfOfficeEnabled = this.healthCenterList.find((x: any) => x.PracticeManagerId == this.composeForm.value.healthCenter)?.ReceiveMsgWhenStaffOutOfOfficeEnabled;
    console.log("", this.receiveMsgWhenStaffOutOfOfficeEnabled, user?.isOutOfOfficeEnabled);
    if (user?.isOutOfOfficeEnabled) {
      let msg = '';
      if (this.receiveMsgWhenStaffOutOfOfficeEnabled) {
        msg = 'The selected provider is out of office.<br>There may be a delay in getting response.<br> Do you want to continue?';
        this.confirmDialog = {
          title: 'Info',
          message: this.translate.instant(msg),
          alertType: AlertType.None,
          yesButtonText: 'Yes',
          noButtonText: 'No'
        };
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: this.confirmDialog,
          disableClose : true
        });
        dialogRef.afterClosed().subscribe((result: any) => {
          if (result == true) {
            this.serviceValidate(value);
          }
        });
      }
      else {
        console.log("-----------------------------From Normal Draft------2------------------------------------------");
        console.log("", this.receiveMsgWhenStaffOutOfOfficeEnabled, user?.isOutOfOfficeEnabled);
        msg = 'ComposeOutOfOfficeRestrict';
        this.alertDialog = {
          title: 'Info',
          message: this.translate.instant(msg),
          alertType: AlertType.None,
          okButtonText: this.translate.instant('OK')
        };
        const dialogRef = this.dialog.open(AlertDialogComponent, {
          data: this.alertDialog,
          disableClose : true
        });
        dialogRef.afterClosed().subscribe((result: any) => {
          if (result == true) {

          }
        });
        return false;
      }
    } else {
      this.serviceValidate(value);
    }
  }
  }

serviceValidate(value: any) {
  if(this.draftselectedMessagesDetails[0]?.IsReply == true){

    if(this.isServiceActiveReply == "0"){// check if selecetd service is inactive, show msg popup while reply message is save as draft
      this.serviceInActivePopup();
    }else{
      this.sendMesssage(value);
    }
  }else{
    let serviceTypeValue = this.serviceTypes.filter((x: any) => x.MessageTypeID ==  this.composeForm.value.serviceName);
    if(serviceTypeValue?.length > 0 ){
      this.isServiceActive = true;
      this.sendMesssage(value);
    }else{
      this.isServiceActive = false;
      this.serviceInActivePopup();
    }
  }
}

  backToDraft = () => {
    //this.mailBoxService.selectedMailBoxType = 'Draft'
    this.router.navigate(['/pages/navigate-routes/Draft']);
    this.reloadCurrentRoute();
  }

  backToInbox = () => {
    this.mailBoxService.selectedMailBoxType = 'Inbox'
  }

  // Previous record data
  previousRecord() {
    let next = (this.currentIndex -= 1);
    if (next < 0) {
      this.currentIndex = 0;
      return;
    }
    let nextRecord = this.mailBoxService.gridView[next];
    this.draftselectedMessagesDetails[this.mailBoxService.index] = nextRecord;
  }

  //Next record data
  nextRecord() {
    let next = (this.currentIndex += 1);
    if (next > this.mailBoxService.gridView.length - 1) {
      this.currentIndex = 5;
      return;
    }
    let nextRecord = this.mailBoxService.gridView[next];
    this.draftselectedMessagesDetails[this.mailBoxService.index] = nextRecord;
  }

  downloadFiles(filename: any,filesDetails:any) {
    let encodefilename = btoa(filename)
    // window.open(`${environment.apiBaseURL}${URLConstants.downloadFile}${encodefilename}`, '_blank');
    const headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/octet-stream',
    });
    //let encodefilename = btoa(this.pdfName)
    const requestOptions = { headers: headerOptions, responseType: 'blob' as 'blob' };
    this.postObject = {FileName: encodefilename}
    this.http.post(`${environment.apiBaseURL}${this.url}`, this.postObject, requestOptions).pipe(map((data: any) => {
      const blob = new Blob([data], {
        type: 'application/octet-stream'
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${filesDetails}`;
      link.click();
      window.URL.revokeObjectURL(link.href);

    })
    ).subscribe(
      (data) => {
        //console.log(data);
        this.toaster.success(this.translate.instant('File downloaded successfully'));
      },
      (err) => {
        this.toaster.warning(err.error.Message || err.error.message || "File doesn't exist");
      },
    );
  }

  getServiceTypeByLocation = (messageTypeId: any, locationsId: any) => {
    applicationHttpClientCreator(this.http).Get(`${URLConstants.getServiceTypeByLocation}${messageTypeId}&LocationId=${locationsId}&UserID=${this.userId}`, {
    }).subscribe((data: any) => {
      //console.log(data)
      if (data && data.length > 0) {
        this.serviceTypelocationDetails = data;
        this.showInformation = (data[0]?.IsBillable && data[0]?.BillableCost) ? true : false;
        if(data[0]?.PaymentOption == 'P'){
          this.paymentMethod = "Pay at Heath Center"
        }
        else if(data[0]?.PaymentOption == 'H'){
          this.paymentMethod = "Pay at Heath Center or Pay Online"
        }
        else if(data[0]?.PaymentOption == 'O'){
          this.paymentMethod = "Pay Online"
        }
        this.RoleIDforservice=data[0].RoleId;
        this.billableCost = data[0]?.BillableCost || 0;
        this.selectedServiceTypeDataterms = data[0].terms;
        this.isServiceActiveReply = data[0]?.IsActive;
        // if (this.serviceTypelocationDetails[0].RoleId == '00000000-0000-0000-0000-000000000000' && this.serviceTypelocationDetails[0].recipientid != '00000000-0000-0000-0000-000000000000') {
      if (this.serviceTypelocationDetails[0].recipientid != '00000000-0000-0000-0000-000000000000') {
          this.composeForm.get('toUser')?.setValue(data[0].recipientid);
          if(data[0].recipientid!='00000000-0000-0000-0000-000000000000')
          this.composeForm.get('toUserDefault')?.setValue(data[0].Recipient);
          else{
          this.composeForm.get('toUserDefault')?.setValue('');
          // this.composeForm.get('')
          }

          this.composeForm.get('role')?.setValue(data[0].RoleId);

          this.getStaffRoleRemoved(data[0].recipientid, data[0].MessageTypeID);
        }
        else{
         // this.getToUserDetails(this.serviceTypelocationDetails[0].RoleId);
         this.GetAllRolesByPractice();
        }

      }
      if (this.serviceTypelocationDetails[0].isExtCommService == 1) {
        // var sIsReqAcc = getQSParamByName('ram'); //  Need to add Query Params
        this.getPatientPracticeForExtComm();
      }
    })
  }

  getStaffRoleRemoved(messageTypeID:any,recipientid:any){
    applicationHttpClientCreator(this.http).Get(`${URLConstants.GetStaffRoleRemoved}${messageTypeID}&MessageTypeId=${recipientid}`, {
    }).subscribe((data: any) => {
      if(data){
      this.isStaffRemoved=data;
      }
      // var sIsReqAcc = getQSParamByName('ram'); //  Need to add Query Params
      // if (sIsReqAcc != '' && sIsReqAcc != null && sIsReqAcc != 'null') {
      // }
    })
  }

  getPatientPracticeForExtComm = () => {
    applicationHttpClientCreator(this.http).Get(`${URLConstants.getPatientPracticeForExtComm}${this.userId}`, {
    }).subscribe((data: any) => {
      //console.log(data)
      // var sIsReqAcc = getQSParamByName('ram'); //  Need to add Query Params
      // if (sIsReqAcc != '' && sIsReqAcc != null && sIsReqAcc != 'null') {
      // }
    })
  }


  autoSaveDraft () {
    if(this.composeForm.value.subject!=''){
    // this.composeForm.valueChanges.pipe(
    //   takeUntil(this.destroy),
    //   debounceTime(60000), // one event every 60000 milliseconds
    // // // distinctUntilChanged(),
    // )
    // .subscribe(() => {
      if (this.composeForm.dirty) {
        let toUserId=''
        if(this.selectedNamecheck){
          toUserId=this.selectedUserId;
        }else{
          toUserId =this.composeForm.value.toUser ? this.composeForm.value.toUser : '';
        }
        var data = {
          InboxDraftMessageID: this.draftselectedMessagesDetails[0].InboxDraftMessageID, // need to confirm inboxdraftmessageid
          FromUserID: this.userId,
          ToUserID: toUserId,
          MessageTypeID: this.composeForm.value.serviceName ? this.composeForm.value.serviceName : 1,
          Subject: this.composeForm.value.subject ? this.composeForm.value.subject : this.draftselectedMessagesDetails[0].Subject,
          MessageBody: this.composeForm.value.message ? this.composeForm.value.message : this.draftselectedMessagesDetails[0].MessageBody,
          IsCompose: false,
          IsReply:  this.draftselectedMessagesDetails[0].IsReply,
          ServiceRefNo: this.composeForm.value.serviceName!=null&&this.composeForm.value.serviceName!=undefined ? this.composeForm.value.serviceName : this.draftselectedMessagesDetails[0].ServiceRefNo!=null?this.draftselectedMessagesDetails[0].ServiceRefNo:"",
          PracticeManagerId: this.composeForm.value.healthCenter ? this.composeForm.value.healthCenter : this.draftselectedMessagesDetails[0].PracticeManagerId,
          LocationId: this.composeForm.value.location ? this.composeForm.value.location : this.draftselectedMessagesDetails[0].LocationId,
          InboxMessageID: this.draftselectedMessagesDetails[0].InboxMessageID,
          RoleID: this.composeForm.value.role ? this.composeForm.value.role : this.draftselectedMessagesDetails[0].RoleID,
          attachments: this.draftAttachmentXml ? this.draftAttachmentXml : "",
          IsSent: false
        }
        this.inboxService.SaveDraftMessage(data).subscribe((res : any) => {
          if (res > 1) {
            this.composeForm.get('inboxDraftMessageID')?.setValue(res);
            this.saveIndicator='Draft saved at' + " " + `${formatDate(Date.now(), "hh:mm:ss a", 'en-US')}`
          }
        })
        // this.inboxService.getDraftMessageCount(this.userId).subscribe((data: any) => {
        //   this.mailBoxService.mailstype[1].count = data;
        // })
      }
  // });
    }
  }

  checkingIsRRP = (service: any) => {
    this.messageTypeId = service?.value || service?.MessageTypeID;
   // this.getToUserDetails(service.RoleId)
    applicationHttpClientCreator(this.http).Post(`${URLConstants.isRRP}${this.messageTypeId}`, {
    }).subscribe((rrp: any) => {
     // console.log(rrp);

      if (rrp == true) {
        this.getPatientMedicationList();
      } else {
        this.getServiceTypeByLocation(this.messageTypeId,this.locationsId);
        //this.getAllRolesByPractice(this.practiceAdmin);
      }
      this.subjectBinding(service);
    })
  }

  subjectBinding = (args: any) => {
    this.composeForm.get('toUser')?.setValue('');
    this.composeForm.get('role')?.setValue(this.Roles[0]?.RoleId);
    this.composeForm.get('toUserDefault')?.setValue('');
    // this.composeForm.get('subject')?.setValue('');

  //  this.getServiceTypeByBillable(args.MessageTypeID);
    //console.log(args.MessageCode);
    this.composeForm.get('subject')?.setValue(args.MessageCode);
    //this.getServiceTypeByLocation(args.MessageTypeID,this.locationsId);

  }

  getPatientMedicationList = () => {
    applicationHttpClientCreator(this.http).Get(`${URLConstants.rrpMedicationList}${this.userId}&PracticeManagerId=${this.practiceAdmin}&LocationId=${this.locationsId}`, {
    }).subscribe((data: any) => {
      //console.log(data)
    })
  }
  public valueChange(value: any): void {

    this.messageBoxValue = value.replace(/<[^>]*>/g, '');
    this.messageBoxValue = this.messageBoxValue.trim();
  }

  serviceInActivePopup(){
    this.alertDialog = {
      title: 'Info',
      message: this.translate.instant("Your Health Centre has deactivated this service. You have the option to choose a different service and continue, or contact your Health Centre for assistance."),
      alertType: AlertType.None,
      okButtonText: this.translate.instant('OK')
    };
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: this.alertDialog
    });
    dialogRef.afterClosed().subscribe((result: any) => {

    })
  }

  acknowledge() {
    this.showInformation = false;
    this.isAcknowledge = true;
  }

  ngOnDestroy() {
    // if(this.liveStatusSub!=null&&this.liveStatusSub!=undefined&&this.liveStatusSub!="")
    this.liveStatusSub.unsubscribe();
    this.inboxService.getDraftMessageCount(this.userId).subscribe((data: any) => {
      this.mailBoxService.mailstype[1].count = data;
    })
    this.destroy.next();
    this.destroy.complete();
  }

  checkProviderAvailability(toUserDetails: any) {
    const isCurrentDayAvailable = this.inboxService.checkCurrentDayAvilable(toUserDetails?.WorkDaysOutOfOffice);
    const isCurrentTimeInRange = this.inboxService.checkCurrentTimeAvailable(toUserDetails?.StartTimeOutOfOffice, toUserDetails?.EndTimeOutOfOffice);
    console.log(`Is current day available? ${isCurrentDayAvailable}`);
    console.log(`Is current time in range? ${isCurrentTimeInRange}`);
    const days = this.inboxService.convertDays(toUserDetails?.WorkDaysOutOfOffice);
    // Extracted statements
    const isWorkHour = toUserDetails?.IsWorkHoursOutOfOffice;
    const isTimeOutOfRange = isCurrentDayAvailable ?  !isCurrentTimeInRange : true;

    // Final statement
    const isShowPopup = isWorkHour ? isTimeOutOfRange : false;
    const time = toUserDetails?.StartTimeOutOfOffice + ' to ' +  toUserDetails?.EndTimeOutOfOffice;

    if(isShowPopup) {
      this.showDialog(this.toUser[0].MessageTypeOutOfOffice, time, days);
    }
  }

  showDialog(outOfOffice: any, time: any, days: any) {
    let messageContent ='';
    this.disableSubmit = false;
    if(outOfOffice == 1) {
      messageContent = `You are sending the message outside of office hours. The practice operates from ${time?.toLowerCase()}, ${days}. Our team will not be able to respond until our next business day. However, please rest assured that your message can still be sent, and we will attend to it promptly on the next business day. If you have an urgent matter that requires immediate action, please call 111 or contact the Health Centre.`;
    } else if(outOfOffice == 2) {
      messageContent = `You are sending the message outside of office hours. The practice operates from ${time?.toLowerCase()}, ${days}. Our team will not be able to respond until our next business day, and unfortunately, the message cannot be sent at this time.If you have an urgent matter that requires immediate action, please call 111 or contact the Health Centre.`;
      this.disableSubmit = true;
    } else if(outOfOffice == 3) {
      messageContent = `You are sending the message outside of office hours. The practice operates from ${time?.toLowerCase()}, ${days}. Our team will not be able to respond until our next business day. Please note that this message will be delivered during regular work hours. If you have an urgent matter that requires immediate action, please call 111 or contact the Health Centre.`;
    } else {
      return false;
    }


      this.alertDialog = {
        title: 'Information',
        message: messageContent,
        alertType: AlertType.None,
        okButtonText: this.translate.instant('OK')
      };
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        data: this.alertDialog
      });



      dialogRef.afterClosed().subscribe((result: any) => {
        if(result) {
          this.dialog.closeAll();
        }


      });



  }
  acceptCheckBox(event:Event){
    if(this.composeForm.value.termsconditions==false && this.isPatient){
      event.preventDefault();
      this.showTerms=true;
    }
  }
  agreeTerms(){
    this.composeForm.patchValue({termsconditions:true})
    this.showTerms=false;
  }
  cancelTerms(){
    this.composeForm.patchValue({termsconditions:false})
    this.showTerms=false;
  }

  // checkProviderAvailability(toUserDetails: any) {
  //   this.receiveMsgWhenStaffOutOfOfficeEnabled = this.healthCenterList.find((x: any) => x.PracticeManagerId == this.composeForm.value.healthCenter)?.ReceiveMsgWhenStaffOutOfOfficeEnabled;
  //   console.log('------------------ is Check 2 -------------', this.receiveMsgWhenStaffOutOfOfficeEnabled);

  //   if (toUserDetails?.isOutOfOfficeEnabled) {
  //     console.log('--------------- Out of Office ----------------------', this.receiveMsgWhenStaffOutOfOfficeEnabled);
  //     let msg = '';
  //     if (this.receiveMsgWhenStaffOutOfOfficeEnabled) {
  //       msg = 'ComposeOutOfOfficeAllow';

  //     this.alertDialog = {
  //       title: 'Info',
  //       message: this.translate.instant(msg),
  //       alertType: AlertType.None,
  //       okButtonText: this.translate.instant('OK')
  //     };
  //     const dialogRef = this.dialog.open(AlertDialogComponent, {
  //       data: this.alertDialog
  //     });
  //     dialogRef.afterClosed().subscribe((result: any) => {
  //       if (result == true) {
  //         return true;
  //       }
  //     });
  //       // return true;
  //     }
  //     else {
  //       msg = 'ComposeOutOfOfficeRestrict';


  //     this.alertDialog = {
  //       title: 'Info',
  //       message: this.translate.instant(msg),
  //       alertType: AlertType.None,
  //       okButtonText: this.translate.instant('OK')
  //     };
  //     const dialogRef = this.dialog.open(AlertDialogComponent, {
  //       data: this.alertDialog
  //     });
  //     dialogRef.afterClosed().subscribe((result: any) => {
  //       if (result == true) {

  //       }
  //     });
  //       return false;
  //     }
  //   } else {
  //     return true;
  //   }
  // }
}







// quill editor old code

//  <quill-editor formControlName="MessageBody"
// [modules]="quillConfig" [styles]="{'height': '140px','overflow':'auto'}">
// </quill-editor>

// // qull editor configs
// quillConfig={
//   //toolbar: '.toolbar',
//    toolbar: {
//      container: [
//        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
//        ['code-block'],
//        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
//        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
//        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
//        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
//        [{ 'direction': 'rtl' }],                         // text direction

//        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
//        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

//        [{ 'font': [] }],
//        [{ 'align': [] }],

//        ['clean'],                                         // remove formatting button

//        ['link'],
//        //['link', 'image', 'video']
//      ],
//   //   handlers: {'emoji': function() {}}
//    },
// }
