import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-file-upload-without-ext',
  templateUrl: './file-upload-without-ext.component.html',
  styleUrls: ['./file-upload-without-ext.component.scss']
})
export class FileUploadWithoutExtComponent implements OnInit {
  @Output() uploadFile = new EventEmitter();
  @Output() isTouched = new EventEmitter();
  @Input() resetFile: any;
  @ViewChild('fileInput', {static: false}) fileInput: ElementRef;
  theFile: any = null;
  arrFiles: FileToUpload[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  setIsTouch = () => {
    this.isTouched.emit(true);
  }

  resetAll(): void {
     this.arrFiles = [];
     this.theFile = null;
     this.fileInput.nativeElement.value = '';
  }
  
  onFileChange = (event: any) => {
    // this.isTouched.emit(true);
    this.theFile = null;

    if (event.target.files && event.target.files.length > 0) {
      // Set theFile property
      this.theFile = event.target.files[0];
      // this.theFiles.push(this.theFile);
      this.readAndUploadFile(this.theFile);
      this.uploadFile.emit(this.arrFiles);
    }
   
  }

  private readAndUploadFile = (theFile: any) => {

    const file = new FileToUpload();

    // Set File Information
    file.fileName = theFile.name;
    file.fileSize = theFile.size;
    file.fileType = theFile.type;

    // Use FileReader() object to get file to upload
    // NOTE: FileReader only works with newer browsers
    const reader = new FileReader();

    // Setup onload event for reader
    reader.onloadend = () => {
      // Store base64 encoded representation of file
      file.fileAsBase64 = reader.result ? reader.result.toString() : '';
    }

    // Read the file
    reader.readAsDataURL(theFile);
    this.arrFiles.push(file);
    if(this.arrFiles.length > 1) {
      const index = this.arrFiles.indexOf(this.arrFiles[0]);
      this.arrFiles.splice(index, 1);
      console.log('Result Files', this.arrFiles);
    }
  }

}

export class FileToUpload {
  fileName: string = "";
  fileSize: number = 0;
  fileType: string = "";
  fileAsBase64: string = "";
  fileAsByteArray: string = "";
}
