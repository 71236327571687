import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sliceArray'
})
export class SliceArrayPipe implements PipeTransform {
  transform(array: any[], start: number, end: number): any[] {
    if (!Array.isArray(array)) {
      return array;
    }

    // Use the slice method to get a portion of the array.
    return array.slice(start, end);
  }
}
