import { AfterViewInit, Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
// import { AlertDialogComponent } from '../../shared/component/alert-dialog/alert-dialog.component';
import { ToasterService } from 'src/app/data/service/toaster.service';
import { TokenService } from 'src/app/data/service/token.service';
// import { PaymentFor, AlertType } from 'src/core/constants/app-enums';
import { IAlertDialog, IConfirmDialog } from 'src/app/data/interface/Idialog';
import { IAppointmentPayment, IPaymentUrl } from 'src/app/data/interface/Ipayment';
import { PaymentService } from 'src/app/data/service/payment.service';
import Helper from 'src/app/core/helper/utility-helper';
import { AlertDialogComponent } from 'src/app/shared/component/alert-dialog/alert-dialog.component';
import { PaymentFor, AlertType } from 'src/app/core/constants/app-enums';
import { IAppointment } from 'src/app/data/interface/IAppointmentDateSlot';
import { applicationHttpClientCreator } from 'src/app/data/interface/IRequestOptions';
import { URLConstants } from 'src/app/core/constants/urlConstants';
import { HttpClient } from '@angular/common/http';
import { AppointmentTermsAndConditionsComponent } from '../../pages-appointment/book-appointment/appointment-terms-and-conditions/appointment-terms-and-conditions.component';
import { PaymentRefreshComponent } from './payment-refresh/payment-refresh.component';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Observable, interval, fromEvent, Subject, from } from 'rxjs';
import { skip } from 'rxjs/operators';
import { ConfirmDialogComponent } from 'src/app/shared/component/confirm-dialog/confirm-dialog.component';
import { CanComponentDeactivate } from 'src/app/core/guard/can-deactivate.guard';

//*************Alex Service******** */
import { AuthServer } from 'src/app/data/service/auth.server';
import { IAlexService } from 'src/app/data/service/alex.service';
import { AlexIntegrationService } from 'src/app/data/service/alex-integration';
import { AdbutlerService } from 'src/app/data/service/adbutler.service';
import { url } from 'inspector';

declare const Checkout: any; // Declare Checkout as a global variable

@Component({
  selector: 'app-patient-my-payment',
  templateUrl: './patient-my-payments.component.html',
  styleUrls: ['./patient-my-payments.component.scss']
})
export class PatientMyPaymentsComponent implements OnInit, AfterViewInit {
  showTab!: number;
  consultationType: any='Face to Face';
  consultTypeClass: any='icon-cent-map-marker-outline-location';
  content: any;
  appointmentStatus: any;
  iframeContent = false;
  dataDetails: any;
  paymentUrl: string;
  userId: any;
  confirmDialog: IConfirmDialog;
  opened: boolean = false;
  deactiveMethod: boolean = false;
  transactionStatus: boolean =false;
  EasyBooking:any;
  tabToggle(index: number): void {
    this.activePaymentOption = index;
    if (index === 1) {
      this.paymentBtnText = "Pay & Continue";
    }
    else if (index === 2) {
      this.paymentBtnText = "Confirm & Continue";
      this.showTab = index;
    }
  }

  cards = ['First', 'Second', 'Third'];
  paymentObject: IAppointmentPayment;
  appointmentObject: IAppointment
  dialogRef: any;
  paymentBtnText = 'Confirm & Pay';
  activePaymentOption = 0;
  alertDialog: IAlertDialog;
  pmsLocalServiceCode = '';
  payOption = 'P';
  trHealthCentreOnly = false;
  trHealthCentre = false;
  trOnlinePayment = false;
  hidePayonline = true;
  trCost = false;
  userData: any;
  appointmentReason = '';
  AppointmentTerms: any = '';
  chkTerms = false;
  vcSettigsTerms = false;
  vcTerms: any = '';
  vcOverrideVCTimeSlot = false;
  payoptionForFTF = "P";
  payoptionForPhone = "P";
  payoptionForVideo = "P";
  payoption = "P";
  isFeeIsEnabled = true;
  appoitmentDate = '';
  urlSafe: SafeResourceUrl;
  isProvider: boolean = false;
  paymentPage = true;
  sessionId: any;
  orderId: any;
  showPaymentResponse: boolean = false;
  isWaitingPaymentResponse: boolean = false;
  
  @ViewChild('iframe', { static: false }) iframe: ElementRef;
//   @HostListener("window:beforeunload", ["$event"]) handleClose(event: Event) {
//     console.log('event:', event);
//     if(this.paymentPage==true && this.iframeContent==false){
//       return true
//     }
//     this.closeAlert()
//     event.returnValue = false;

// }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: BeforeUnloadEvent) {
    if (this.isWaitingPaymentResponse) {
      const confirmationMessage = 'Are you sure you want to leave?';
      $event.returnValue = confirmationMessage; // Standard for most browsers
      this.updatePaymentStatus();
      return confirmationMessage; // For some older browsers
    }
  }

  @HostListener('document:payment-success', ['$event'])
  paymentComplete(event: any){
    this.completeCallback(event);
  }

  @HostListener('document:payment-error', ['$event'])
  paymentError(event: any){
    this.errorCallback(event);
  }
  
  @HostListener('document:payment-cancel', ['$event'])
  paymentCancel(event: any){
    this.cancelCallback();
  }

  constructor(public dialog: MatDialog,
    private router: Router,
    private service: PaymentService,
    private toaster: ToasterService,
    private translate: TranslateService,
    private tokenService: TokenService, public sanitizer: DomSanitizer,
    private http: HttpClient,
    private authServer: AuthServer,
    private alexService: IAlexService,
    private alexIntegrationService: AlexIntegrationService,
    private adbutlerService: AdbutlerService,
    private route: ActivatedRoute) {
    this.userId = this.tokenService.userId();
    route.params.subscribe((val: any) => {
      this.EasyBooking = val?.EasyBooking;
    })
  }

  submitAppointmentPaymentRequest(): void {
    const appointmentHolderName = this.appointmentObject.name ? this.appointmentObject.name : '';
    const appointmentPersonType = this.appointmentObject.personType ? this.appointmentObject.personType : '';
    const appointmentHolderReason = this.appointmentObject.AppReason ? this.appointmentObject.AppReason : '';
    const appointmentHolderRelation = this.appointmentObject.relation ? this.appointmentObject.relation : ''
    // this.activePaymentOption = 1;
    if (this.activePaymentOption === 2 || this.isProvider) {
      console.log('Pay at health center', this.activePaymentOption);
      this.submitNewAppointmentRequest();
    }
    else {
      if (!this.paymentObject.Cost) {
        return;
      }
      console.log('Pay at online', this.activePaymentOption);
      this.alertDialog = {
        title: 'Payment',
        message: 'All payments made are collected by ' + this.appointmentObject.tradingName + '. All amounts are in AUD. For any payment or refund related enquiries please contact ' + this.appointmentObject.tradingName + '.',
        alertType: AlertType.None,
        okButtonText: 'Ok'
      };
      const dialogRef = this.dialog.open(AlertDialogComponent, {
        data: this.alertDialog
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result === true) {
          this.paymentPage = false;
          console.log('Final', this.paymentObject);
          this.service.ProcessAppointmentPayment(this.paymentObject).subscribe(
            (data: IPaymentUrl) => {
            console.log(data);
            // alert(data.Url)
            if (data.Url) {
              this.paymentUrl = data.Url;
              this.deactiveMethod=false;
              this.sessionId = data.Url;
              this.orderId = data.ResponseText;
              this.configureCheckout();
              setTimeout(() => {
                Checkout.showEmbeddedPage('#embed-target');
                this.isWaitingPaymentResponse = true;
              }, 1000);
              //(window as any).Checkout.showPaymentPage();
              //this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(data.Url); // this.sanitizer.bypassSecurityTrustResourceUrl(data.Url);
              // window.open(data.Url, '_blank');
              //window.location.href = data.Url; // Redirect in the same page
              return;
              /*this.service.signInChecking(this.iframeContent);
              this.opened=false
              this.onClickpayOnline();

              //Scroll to Top
              window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
              });
              // window.location.href = data.Url;*/
            } else {
              this.alertDialog = {
                title: 'Payment',
                message: data.ResponseText,
                alertType: AlertType.Warning,
                okButtonText: 'Ok'
              }
              const dialogRef = this.dialog.open(AlertDialogComponent, {
                data: this.alertDialog
              });
              dialogRef.afterClosed().subscribe((result: any) => {
                if (result === true) {
                  this.opened=false
                  //   window.location.href = window.location.origin + "/pages/payment";
                }
              });
            }
          }, (err: any) => {
            console.log(err)
            this.toaster.error(err.error.Message);
          })
        }
      });
    }

  }
  ngOnInit(): void {
    this.service.dailogStatusOpen.subscribe(data => {
      this.opened = data;
    });

    if(!this.EasyBooking)
    {
    this.userData = this.tokenService.userProfile();
    let userRoleArr: any = this.tokenService.getUserRoles();
    this.isProvider = false;
    let userRoleList = userRoleArr?.split(',');
    
    if(userRoleList.includes('clinical')) {
      this.isProvider = true;
    }
  }
    if (this.service.appointmentObject) {

      this.appointmentObject = this.service.appointmentObject;
      const objPayment: any = this.appointmentObject.PaymentObject ? this.appointmentObject.PaymentObject : new Object();

      this.paymentObject = objPayment;
      this.paymentObject.ConsultationType = (this.paymentObject.ConsultationType === 'none') ? '' : this.paymentObject.ConsultationType
      this.getPracticeSettings(this.paymentObject.PracticeID);
      console.log("Payment object", JSON.stringify(this.appointmentObject));
      this.consultationType = "Phone";
      //(this.paymentObject.ConsultationType === ConsultationTypeCode)
      
      this.consultationType = (this.paymentObject.ConsultationType === 'MMHVC') ?
        'Video' : (this.paymentObject.source === 'MMHT' ?'Telehealth':'Face to Face' );

      this.consultTypeClass = (this.paymentObject.ConsultationType === 'MMHVC') ?
        'icon-cent-camera-medical' : (this.paymentObject.source === 'MMHT' ?  'icon-video':'icon-cent-map-marker-outline-location' );

        
      //Video Consultation
      if (this.paymentObject.ConsultationType === 'MMHVC') {
        this.getPracticeVCSettings(this.paymentObject?.PracticeID);
      }
      else {
        this.vcSettigsTerms = true;
      }
      this.setPaymentOption(this.paymentObject?.PracticeID);
      //End
      //this.appoitmentDate = this.paymentObject.AppSelectedDate;
      //this.appoitmentDate = moment(new Date(this.paymentObject.AppSelectedDate)).format('MMM DD, YYYY HH:mm A');
      let selectedDate = new Date(this.paymentObject.AppSelectedDate);
      let formatedDate = selectedDate.toLocaleString('default', { month: 'short' })
        + ' ' + selectedDate.getDate() + ',' + selectedDate.getFullYear()
        + ' ' + this.getFormatTime(selectedDate);

      this.appoitmentDate = formatedDate;
      console.log('Formated date', this.appoitmentDate);
    }
    else {
      const dialogRef = this.dialog.open(PaymentRefreshComponent, {
        data: { terms: 'Payment session is expired. Please re-book appointment.' },
        disableClose:true
      });
      dialogRef.afterClosed().subscribe((result: any) => {
        console.log('Finish Terms Info');
        this.closePayment();
        this.router.navigate(['/appointment/myappointments/bookappointment']);
      });
    }

    this.clearTyroSession();
  }

  getFormatTime = (selectedDate: Date) => {

    let hours = selectedDate.getHours() > 12 ? selectedDate.getHours() - 12 : selectedDate.getHours();
    let am_pm = selectedDate.getHours() >= 12 ? "PM" : "AM";
    hours = hours < 10 ? 0 + hours : hours;
    var minutes = selectedDate.getMinutes() < 10 ? "0" + selectedDate.getMinutes() : selectedDate.getMinutes();

    return hours + ":" + minutes + " " + am_pm;
  }


  disabledAllPaymentOptions(): void {
    this.trHealthCentreOnly = false;
    this.trOnlinePayment = false;
    this.trHealthCentre = false;
    this.trCost = false;
    this.showTab = 0;
  }

  getPracticeSettings(practiceId: string): void {
    applicationHttpClientCreator(this.http).Get(`${URLConstants.getAppointmentSettings}${practiceId}`, {
    }).subscribe((data: any) => {
      console.log(data);

      this.disabledAllPaymentOptions();
      if (data != null && data.length > 0) {

        this.payoptionForFTF = data[0].AppPaymentOption;
        this.payoptionForPhone = data[0].AppPaymentOptionForPhone;
        this.payoptionForVideo = data[0].AppPaymentOptionForVideo;

        if (this.paymentObject.ConsultationType === "MMHVC") {
          this.payOption = this.payoptionForVideo;
        }
        else if (this.paymentObject.ConsultationType === "MMHPA") {
          this.payOption = this.payoptionForPhone;
        }
        else {
          this.payOption = this.payoptionForFTF;
        }

        this.AppointmentTerms = this.tokenService.decoder(data[0].AppointmentTerms);
        console.log('Terms **************************************', this.AppointmentTerms);

        this.pmsLocalServiceCode = data[0].AppLocalServiceCode;
        // this.payOption = data[0].AppPaymentOption;

        // if (data[0].AppPaymentOption === "P") {

        //   this.trHealthCentre = true;
        //   this.trCost = true;
        // }
        // else if (data[0].AppPaymentOption === "O") {
        //   this.trOnlinePayment = true;
        //   this.trCost = true;
        // }
        // else if (data[0].AppPaymentOption === "H") {
        //   this.trHealthCentre = true;
        //   this.trOnlinePayment = true;
        //   this.trCost = true;
        // }

        if (this.payOption === "P") {
          this.isFeeIsEnabled = false;
          this.trHealthCentre = true;
          this.activePaymentOption = 2;
          this.tabToggle(2)
        }
        else  if (this.payOption === 'O') {
          this.isFeeIsEnabled = false;
          if (this.appointmentObject?.isAlexEnabled) {
            this.trOnlinePayment = false;
            this.trHealthCentre = true;
          } else {
            this.trOnlinePayment = true;
          }
          this.activePaymentOption = 1;
          this.tabToggle(1);
        }
        else if (this.payOption === 'H') {
          this.trHealthCentre = true;
          if (this.appointmentObject?.isAlexEnabled) {
            this.trOnlinePayment = false;
          } else {
            this.trOnlinePayment = true;
          }
          this.isFeeIsEnabled = true;
          this.activePaymentOption = 2;
          this.tabToggle(2);
        }
         this.setPaymentOption(practiceId);
          // if(data[0].AppPaymentOption === "H" || data[0].AppPaymentOption === "O"|| data[0].AppPaymentOption === "P"){
        }
    }, (err: any) => {
      console.log(err);
    });
  }
  setPaymentOption(practiceId :string){
    applicationHttpClientCreator(this.http).Get(`${URLConstants.getAppointmentLocationPaymentOption}${practiceId}&LocationCode=${this.appointmentObject.LocationCode}&ConsultationType=${this.paymentObject.ConsultationType == null || this.paymentObject.ConsultationType == ""? 'none': this.paymentObject.ConsultationType}`, {
    }).subscribe((val: any) => {
      if(val == false){
        this.trHealthCentre = true;
        this.hidePayonline = false;
        this.tabToggle(2);
      }
      else{
        this.hidePayonline = true;
      }
    })
  }
   addEventListener(event: any) {
    //this.iframe.nativeElement.focus()
    fromEvent(this.iframe.nativeElement, 'load')
      // Skip the initial load event and only capture subsequent events.
      //.pipe(skip(1))
      .subscribe((event: any) => {
        this.appointmentStatus = event.target;
        let type = "1";
        this.userId = this.tokenService.userId();
        applicationHttpClientCreator(this.http).Get(`${URLConstants.userPaymentResponseView}${type}&PatientUserID=${this.userId}&PID=${this.paymentUrl}`, {
        }).subscribe((data: any) => {
          this.appointmentStatus = data;
          console.log("this.appointmentStatus", this.appointmentStatus)
          if (this.appointmentStatus.PaymentStatus != '') {
            //  this.iframeContent=true;
            this.service.changeStatus(this.appointmentStatus, type);
            if (data.PaymentStatus != "APPROVED") {
              this.transactionStatus=true;
              this.router.navigate(['/payment/failure-info']);
            } else {
              this.transactionStatus=true;
              this.router.navigate(['/payment/success-info']);
            }
          }
        });
      });

   }
  changeApptStatus() {
    let type = "1";
    applicationHttpClientCreator(this.http).Get(`${URLConstants.userPaymentResponseView}${type}&PatientUserID=${this.userId}&PID=${this.paymentUrl}`, {
    }).subscribe((data: any) => {
      this.appointmentStatus = data;
      this.service.changeStatus(this.appointmentStatus, type);
      if (data.PaymentStatus != "APPROVED") {
        this.router.navigate(['/payment/failure-info']);
      } else {
        this.router.navigate(['/payment/success-info']);
      }
    });
  }
  onClickpayOnline(){
    this.service.newStyle({ 'display': 'none' });
  }
  submitNewAppointmentRequest(): void {
    if (this.appointmentObject?.isAlexEnabled) {
      this.BookAppointmentWithProviderUsingAlex();
    }else{
    this.service.SubmitAppointmentPaymentRequest(this.paymentObject, this.appointmentObject).subscribe((data) => {
      console.log(data);
      if (data && data === -1) {
        this.alertDialog = {
          title: 'Appointment Booking',
          message: '<span>Sorry! This appointment was just booked by another member<br/>Please choose a different time.</span>',
          alertType: AlertType.Info,
          okButtonText: 'Ok'
        };
        const dialogRef = this.dialog.open(AlertDialogComponent, {
          data: this.alertDialog
        });
        dialogRef.afterClosed().subscribe((result: any) => {

          this.deactiveMethod=true;
          window.location.href = window.location.origin + "/appointment/myappointments/bookappointment";

        });
      }
      else {

        if (this.paymentObject.ConsultationType === 'MMHPA') {
          this.service.UpdatePatientPAContactNumberForAppointment(this.paymentObject).subscribe((data) => {
            console.log(data);
            this.toaster.success(this.translate.instant('New appointment request submitted successfully.'));
            this.deactiveMethod=true;

            this.adbutlerService.appointmentStatus=true;
            if (this.isProvider) {
              this.router.navigate(['/appointment/appointment-provider']); 
            } else {
              this.router.navigate(['/appointment/future']); 
            }

          }, (err) => {
            console.log(err);
            this.toaster.error(err.error.Message);
          });
        }
        else {
          this.toaster.success(this.translate.instant('New appointment request submitted successfully.'));
          this.deactiveMethod=true;// Phase_2 AD-Engine after booking appointment successfully making the change of value
          this.adbutlerService.appointmentStatus=true;
          // this.router.navigate(['/appointment/future']);
          if(this.EasyBooking) {
            console.log(">>>>>>>>>>>")
            this.router.navigate(['/authentication/bookappointment/' + this.EasyBooking]);
          }
          else {
            if (this.isProvider) {
              this.router.navigate(['/appointment/appointment-provider']); 
            } else {
              this.router.navigate(['/appointment/future']); 
            }
          }
        }
      }
    }, (err) => {
      console.log(err);
      this.toaster.error(err.error.Message);
    });
  }
  }

  acceptCheckBox(event:Event){
    if(this.chkTerms==false){
      event.preventDefault();
      this.onOpenTermsAndConditions();
    }
  }
  //Terms & Conditions for all consulatation type
  onOpenTermsAndConditions(): void {
    let allTermsAndConditions = '';
    if ((this.paymentObject.ConsultationType === 'MMHVC')) {
      allTermsAndConditions = '<b>General Terms & Conditions</b><br/>'
        + this.AppointmentTerms
        + ((this.paymentObject.ConsultationType === 'MMHVC') ? "<br/><hr/><br/> <b>Video Terms & Conditions</b> <br/><br/>" + this.vcTerms : '');
    }
    else {
      allTermsAndConditions = this.AppointmentTerms;
    }
    const dialogRef = this.dialog.open(AppointmentTermsAndConditionsComponent, {
      data: { 
        terms: allTermsAndConditions,
        isAppointmentScreen: true,
        showCancel: true
      },
      width:'600px',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      console.log('Finish Terms Info');
      if (result == false) {
        this.chkTerms = false;
      } else {
        this.chkTerms = true;
      }
    });
  }

  //Terms & condition for video consultation
  onOpenVCTermsAndConditions(): void {
    const dialogRef = this.dialog.open(AppointmentTermsAndConditionsComponent, {
      data: { terms: this.sanitizer.bypassSecurityTrustHtml(this.vcTerms) }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      console.log('Finish Terms Info');
    });
  }

  //Video settings - terms and override option
  getPracticeVCSettings = (practiceId: string) => {
    applicationHttpClientCreator(this.http).Get(`${URLConstants.getPracticeVCSettings}${practiceId}`, {
    }).subscribe((data: any) => {
      console.log('data', data);
      if (data.length > 0) {

        let AboutVC = (data[0]?.AboutVC) ? '<b>About Video Consultation?</b><br/>' + data[0]?.AboutVC + '<br/>' : '';
        let WhenToUseVC = (data[0]?.WhenToUseVC) ? '<hr/><b>When to use Video Consultation?</b><br/>' + data[0]?.WhenToUseVC + '<br/>' : '';
        let WhenNotToUseVC = (data[0]?.WhenNotToUseVC) ? '<hr/><b>When not to use Video Consultation?</b><br/>' + data[0]?.WhenNotToUseVC + '<br/>' : '';

        let VCTermsAndConditions = (data[0]?.VCTerms) ? '<hr/><b>Terms & Conditions</b><br/>' + data[0]?.VCTerms : '';
        this.vcTerms = AboutVC + WhenToUseVC + WhenNotToUseVC + VCTermsAndConditions;
        this.vcOverrideVCTimeSlot = data[0]?.IsEnabledPatientOverrideVCTimeSlot;
      }

    }, (err: any) => {
      console.log(err);
    });
  }

  //back to appointment page
  backToAppointment = () => {
    this.router.navigate(['/appointment/myappointments/bookappointment']);
  }
  ngAfterViewInit() {
    // this.iframe.nativeElement.focus();
  }

  configureCheckout(): void {
    Checkout.configure({
      session: {
        id: this.sessionId
      }
    });
  }

  clearTyroSession() {    
    sessionStorage.removeItem('HostedCheckout_embedContainer');
    sessionStorage.removeItem('HostedCheckout_merchantState');
    sessionStorage.removeItem('HostedCheckout_sessionId');
  }

  completeCallback(response: any): void {
    let data = {
      orderId: this.orderId,
      practiceId: this.paymentObject.PracticeID,
      locationId: this.appointmentObject.LocationCode
    };
    if(!this.EasyBooking) {
    this.router.navigate(['/payment/payment-response/' + this.orderId], { queryParams: data });
    } else {
      this.router.navigate(['/payment-response/' + this.orderId], { queryParams: data });
    }

  }

  errorCallback(error: any): void {
    console.log(error);
  }
  
  cancelCallback(): void {
    console.log('Payment cancelled');
    this.showPaymentResponse = true;
    //this.router.navigate(['/payment/payment-failure-response/' + this.orderId]);
  }

  updatePaymentStatus() {
    let url = URLConstants.checkPaymentResponse + this.orderId;
    applicationHttpClientCreator(this.http).Get(url, {
    }).subscribe((data: any) => {
      console.log(data);
    }, (err: any) => {
      console.log(err);
    });
  }


  canDeactivate() {
    if (!this.deactiveMethod) {
      if (this.urlSafe != undefined || '') {
        if (this.appointmentStatus?.PaymentStatus != undefined || null) {
          return true;
        } else {
          this.opened = true;
          return false;
        }
      } else {
        return true;
      }
    }
    return true;
  }
  closePayment() {
    this.service.signInChecking(true);
    let type = "1";
    applicationHttpClientCreator(this.http).Post(`${URLConstants.userabandontransaction}${type}&PatientUserID=${this.userId}&PID=${this.paymentUrl}`, {
    }).subscribe((res:any) => {
      this.opened = false;
      this.paymentPage= true;
      this.iframeContent= false;
      this.deactiveMethod=true;
      this.service.newStyle({ 'display': 'block' });
   });
}
closeAlert() {
  this.service.signInChecking(false);
  let type = "1";
  applicationHttpClientCreator(this.http).Post(`${URLConstants.userabandontransaction}${type}&PatientUserID=${this.userId}&PID=${this.paymentUrl}`, {
  }).subscribe((res:any) => {
    this.opened = false;
    // this.paymentPage= false;
    // this.iframeContent= false;
    //this.deactiveMethod=true;
    this.service.newStyle({ 'display': 'block' });
 });
}
  stay() {
    this.opened = false;
  }
  ngOnDestroy() {
    console.log('Destroy called =================================> ');
    this.opened=false;
    this.service.dailogStatus(this.opened);
    this.service.signInChecking(true);
    if(this.transactionStatus==false){
    if(!this.paymentPage && !this.iframeContent){
     this.closePayment();
     this.updatePaymentStatus();
    }
  }
}
BookAppointmentWithProviderUsingAlex() {
  this.alexService
    .GetPatientDetailsByNhiId({
      NHI_id: localStorage.getItem('alexpat'),
      mt_facilityid: this.appointmentObject.FacilityId,
      WithEnrolmentAndRegistrationDetails: true,
    })
    .then(async(PatientDetails: any) => {
      this.alexService
        .BookAppointmentWithProvider(
         await  this.GetRequestObject(PatientDetails),
          this.appointmentObject.FacilityId != undefined
            ? this.appointmentObject.FacilityId
            : ''
        )
        .then(async(bookInfo: any) => {
          let response: any =await  JSON.parse(bookInfo);

          if (response == 404) {

              this.toaster.error("Appointment slot is already booked, unable to process the request. Please book another date and time");
              this.router.navigate(['/appointment/future']);//"Appointment slot is already booked, unable to process the request. Please book another date and time.
          } else
          {

          if (response.status === 'booked') {


            let Patient: any = JSON.parse(PatientDetails);
            let request: any = {
            AlexRequest: JSON.stringify( await  this.GetRequestObject(PatientDetails)),
            AlexResponse: JSON.stringify(response),
            PatientUserID: this.userId?this.userId:'00000000-0000-0000-0000-000000000000',
            ResponseID: response.id,
            AppointmentTime: this.appointmentObject.AppSlotStartTime,
            AppointmentEndTime: this.appointmentObject.AppSlotEndTime,
            PatientAlexID: Patient.entry[0].resource.id,
            ProviderAlexID:this.appointmentObject.DoctorId,
            PatientName : Patient.entry[0].resource.name[0].given[0] +' '+ Patient.entry[0].resource.name[0].family,
            ProviderName:this.appointmentObject.DoctorName,
            FacilityId: this.appointmentObject.FacilityId ,
            SPC: this.appointmentObject.SpcCode
            };

                applicationHttpClientCreator(this.http)
                  .Post(`${URLConstants.SaveAlexRequestResponse}`, request)
                  .subscribe((data: any) => {
                    if(data){
                      let AppointmentBookingRequest ={
                        AppFromTimeSlot: this.appointmentObject.AppSlotStartTime,
                        AppSelectedFromTime:  this.appointmentObject.AppSlotStartTime,
                        reasontovisit: this.appointmentObject.AppReason ,
                        PatientUserId: this.userId?this.userId:'00000000-0000-0000-0000-000000000000' ,
                        PracticeId: this.appointmentObject.PracticeID ,
                        spc: this.appointmentObject.SpcCode,
                        Providername:this.appointmentObject.DoctorName,
                        //PmsUserid: ,
                        Location: this.appointmentObject.LocationCode,
                        ConsultType: this.appointmentObject.ConsultationType ,
                        mobile: this.appointmentObject?.PaymentObject?.PAContactNumber,
                        HPINumber: this.appointmentObject.HPINumber,
                        SlotDuration :this.appointmentObject?.PaymentObject?.SlotDuration,
                        VonageVideoSession: ""
                      }
                        applicationHttpClientCreator(this.http)
                        .Post(`${URLConstants.AlexNewAppointmentBookingRequest}`, AppointmentBookingRequest)
                      .subscribe((data: any) => {
                        this.router.navigate(['/appointment/future']);
                        });
                  }
                  });

                  this.toaster.success(
                    this.translate.instant(
                      'New appointment booked successfully.'
                    )
                  );
          }
        }
        });
    });
}
diff_minutes(dt2: any, dt1: any) {
  var diff = (new Date(dt1).getTime() - new Date(dt2).getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
}

GetRequestObject(PatientDetails: any) {
  let Patient: any = JSON.parse(PatientDetails);

  if (Patient != undefined && Patient.entry.length > 0) {
    return {
      id: null,
      patient: {
        id: Patient.entry[0].resource.id,
        name:
          Patient.entry[0].resource.name[0].given[0] +
          Patient.entry[0].resource.name[0].family,
      },
      practitioners: [
        {
          id: this.appointmentObject.DoctorId,
          name: this.appointmentObject.DoctorName,
        },
      ],
      status: 2, // --booked
      description:
        this.appointmentObject.ConsultationType == 'MMHVC'
          ? 'MMH: VC: ' + this.appointmentObject.AppReason
          : this.appointmentObject.ConsultationType == 'MMHPA'
          ? 'MMH: PA: ' + this.appointmentObject.AppReason
          : 'MMH: ' + this.appointmentObject.AppReason,
      comment:
        this.appointmentObject.ConsultationType == 'MMHVC'
          ? 'MMH: VC: ' + this.appointmentObject.AppReason
          : this.appointmentObject.ConsultationType == 'MMHPA'
          ? 'MMH: PA: ' + this.appointmentObject.AppReason
          : 'MMH: ' + this.appointmentObject.AppReason,
      start: this.appointmentObject.AppSlotStartTime,
      end: this.appointmentObject.AppSlotEndTime,
    };
  }
}
}
