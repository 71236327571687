<div *ngIf="design == '1'">
  <mat-label><b>Your password must contain:</b></mat-label>
<div class="password-contain-info"  fxLayout.lt-lg="row wrap" fxLayout="row">
  <mat-label
    fxFlex.lt-sm="45"
    [ngStyle]="{ color: Password.length >= 10 ? 'green' : 'red' }"
  >
    <span *ngIf="Password.length >= 10">
      <mat-icon>done</mat-icon>
    </span>
    Minimum 10 characters,
  </mat-label>

  <mat-label
    fxFlex.lt-sm="45"
    [ngStyle]="{ color: isCapital ? 'green' : 'red' }"
  >
    <span *ngIf="isCapital">
      <mat-icon>done</mat-icon>
    </span>
    1 Uppercase letter,
  </mat-label>

  <mat-label fxFlex.lt-sm="45" [ngStyle]="{ color: isSmall ? 'green' : 'red' }">
    <span *ngIf="isSmall">
      <mat-icon>done</mat-icon>
    </span>
    1 Lowercase letter,
  </mat-label>

  <mat-label
    fxFlex.lt-sm="45"
    [ngStyle]="{ color: isSpecial ? 'green' : 'red' }"
  >
    <span *ngIf="isSpecial">
      <mat-icon>done</mat-icon>
    </span>
    1 Special Character,
  </mat-label>

  <mat-label
    fxFlex.lt-sm="45"
    [ngStyle]="{ color: isNumber ? 'green' : 'red' }"
  >
    <span *ngIf="isNumber">
      <mat-icon>done</mat-icon>
    </span>
    1 Number
  </mat-label>
  <mat-label
    fxFlex.lt-sm="45"
    [ngStyle]="{ color: !isSpaceChk ? 'green' : 'red' }"
  >
    <span *ngIf="!isSpaceChk">
      <mat-icon>done</mat-icon>
    </span>
    No Spaces
  </mat-label>
</div>
</div>


<div *ngIf="design == '2'" class="fxFlexOffset-responsive" fxFlexOffset="0" fxFlex="100">
  <mat-label><b>{{'Your password must contain:' | translate}}</b></mat-label>
  <!-- {{changePasswordForm.controls['newPassword'].value.toUpperCase()}} -->
  <div class="password-contain-info" fxLayout="row wrap">
    <mat-label fxFlex="48"
      [ngStyle]="{'color': Password.length < 10 ? 'red': 'green'}">
      <mat-icon *ngIf="Password.length >= 10">done</mat-icon>
      {{'Minimum 10 characters' | translate}}
    </mat-label>
    <mat-label fxFlex="48" [ngClass]="{'done': isCapital, 'not-done': !isCapital}">
      <mat-icon *ngIf="isCapital">done</mat-icon>1 {{'Uppercase letter' | translate}}
    </mat-label>
    <mat-label fxFlex="48" [ngClass]="{'done': isSmall, 'not-done': !isSmall}">
      <mat-icon *ngIf="isSmall">done</mat-icon>1 {{'Lowercase letter' | translate}}
    </mat-label>
    <mat-label fxFlex.lt-sm="45" [ngClass]="{'done': isSpecial, 'not-done': !isSpecial}">
      <mat-icon *ngIf="isSpecial">done</mat-icon>1 {{'Special Character' | translate}}
    </mat-label>
    <mat-label fxFlex="48" [ngClass]="{'done': isNumber, 'not-done': !isNumber}">
      <mat-icon *ngIf="isNumber">done</mat-icon>1 {{'Number' | translate}}
    </mat-label>
    <mat-label fxFlex="48" [ngClass]="{'done': !isSpaceChk, 'not-done': isSpaceChk}">
      <mat-icon *ngIf="!isSpaceChk">done</mat-icon>{{'No Spaces' | translate}}
    </mat-label>
  </div>

</div>
